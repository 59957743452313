import React from "react";
import { Link } from "react-router-dom";
import MaintenanceBreakdownContainer from "./maintenanceBreakdown.module.css";
import SwapHorizontalCircleIcon from "@material-ui/icons/SwapHorizontalCircle";
import SmsFailedIcon from "@material-ui/icons/SmsFailed";
import { StarOutlineSharp } from "@material-ui/icons";

const MaintenanceBreakdown = () => {
  return (
    <section style={{ height: "90vh" }}>
      <div className="divflex" style={{ fontSize: "24px" }}>
        MAINTENANCE BREAKDOWN
      </div>
      <main
        className={`${MaintenanceBreakdownContainer.container} ${MaintenanceBreakdownContainer.main}`}
      >
        <div>
          <Link to="/maintenance_breakdown/maintenance" className="panel">
            <SwapHorizontalCircleIcon
              style={{ fontSize: "3rem", color: "maroon" }}
            />
          </Link>
          <p className="card-btn-para">
            PREVENTIVE MAINTENANCE
            <span className="card-btn-span">PLAN</span>
          </p>
        </div>
        <div>
          <Link to="/checklist_actions_list" className="panel">
            <StarOutlineSharp style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            MAINTENANCE
            <span className="card-btn-span">STATUS</span>
          </p>
        </div>
        <div>
          <Link to="/breakdown_logs/maintenance" className="panel">
            <SmsFailedIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            BREAKDOWN <span className="card-btn-span">Register</span>
          </p>
        </div>
      </main>
    </section>
  );
};

export default MaintenanceBreakdown;
