import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../components/Control/useDebounce";
import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import formatDate from "../../components/Control/formatDate";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import CheckIcon from "@material-ui/icons/Check";
const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});

const SelectSupplier = ({
  handleSupplierDetails,
  resetSupplierDetails,
  processes,
}) => {
  const classes = useStyles();
  //   const [filter, setFilter] = useState("desc_4m");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [perPage, setPerPage] = useState();

  const fetchData = async () => {
    axios
      .get(`/api/get/supplier?page=${page}&search=${search}&perPage=10`)
      .then((res) => {
        setData(res.data?.result);
        setPerPage(res.data?.perPage);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //   console.log(data);
  useDebounce(fetchData, 300, [page, search]);
  return (
    <main>
      <div
      // style={{ flex: "0.8" }}
      >
        <Input
          placeholder="Search Supplier Name"
          variant="filled"
          style={{
            width: "100%",
            marginLeft: "1.2rem",
          }}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            {["#", "Supplier Name", "Action"].map((item, index) => (
              <TableCell
                style={{ fontWeight: "bold", textTransform: "capitalize" }}
                align="center"
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            {data.map((item, idx) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">
                    {perPage * (page - 1) + idx + 1}
                  </TableCell>
                  <TableCell align="center">{item?.supplier_name}</TableCell>
                  <TableCell align="center">
                    {item?._id === processes?.supplier_id ? (
                      <>
                        {" "}
                        <Tooltip title="Status" arrow>
                          <IconButton size="small">
                            <CheckIcon
                              style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove" arrow>
                          <IconButton
                            size="small"
                            style={{ marginLeft: "0.4rem" }}
                            onClick={() => {
                              resetSupplierDetails();
                            }}
                          >
                            <RemoveIcon
                              style={{ fontSize: "1.4rem", color: "#c80202" }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    ) : (
                      <Tooltip title="Add" arrow>
                        <IconButton
                          size="small"
                          onClick={() => {
                            handleSupplierDetails(item._id, item.supplier_name);
                          }}
                        >
                          <AddIcon
                            style={{ fontSize: "1.4rem", color: "#003566" }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "4rem",
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, number) => setPage(number)}
        />
      </div>
    </main>
  );
};

export default SelectSupplier;
