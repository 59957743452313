export const processes = [
  {
    s_no: "1",
    look_at:
      "Is there is any Customer Complaints since last One Year for this Component. - Customer Complaint Register  - Documents Revision History  - Control Plan ,   -FMEA ,  Inspection Standards   & Work Instruction , and at Inspection Tables etc.",
    look_for:
      "Customer Complaints of Part In Past .Check action Plan onsite and in Documents. Are They are Live or Not ",
  },
  {
    s_no: "2",
    look_at:
      "Verify The Poka - Yoke in Machines , Tools , Die  & Fixture  & Understanding of Poka -  Yoke to Operator   Inspectors and Supervisior ",
    look_for:
      "Poka Yoke or Fool Proofing also check checking and recording of Poka Yoke as per Checking Frequancy ",
  },
  {
    s_no: "3",
    look_at: "Process  & Product Audit Previous History Documents",
    look_for:
      "All Non Conformity are closed properly or not ( Check 8D   CAPA ) raised during last audits ",
  },
  {
    s_no: "4",
    look_at:
      "Actual Coherence between Documents ( Drawings Control Plan FMEA PDF Inspection standards and Records as per Inspection Standards, Using of Instruments for Checking of Components as per Control Plans ",
    look_for: "Coherence Between Documents for parts which to be audited ",
  },
  {
    s_no: "Man",
    look_at: "Operator inspector Competency",
    look_for:
      "Is Operator is Working as per implemented Work Instruction Or Not ? \n Is Operator is Using Safety Equipment during Operation or Not \n Is Operator Has Knowledge about different Tags Or Not ?\n Is Operator is Applying Red Color on Rejected Parts or Not .?\n Is opertor has knowledge of Daily Machine Check point or Not and he is Updating the records Accordingly ?\n Is Operator Knows about Abnormalities  & 4M?\n Is Skill Matrix Of Operator is Displayed On Machines Boards?\n Are OK Master Samples and First Off Samples avilable  on Machine or not ?\nAre Operator is aware about defects and latest defect from customer or not ?\nAre operator well trained to use the Checking equipments which are applicable or not?",
  },
  {
    look_at: "Process Analysis Sheet ",
    look_for:
      "1) Operation standard is specified and operator work as per standard\n 2) Awareness  & Implementation of QIP   CP   Drawing   WI related to ( Operation Name ) process.",
  },
  {
    s_no: "Machine",
    look_at: "Preventive Maintenance of Machine",
    look_for:
      "1) Are process & product wise machine being used\n2) Annual plan for PM is available and it is implemented.\n3) Daily checking of machine is specified and is implemented\n4) Pokayoke and pokayoke sample etc is checked as per rule for control\n5) Countermeasure is taken against NG found during checking.",
  },
  {
    look_at: "Process Parameters monitoring of ( Operation Name ) process",
    look_for: "Verify as per WI CP (Use separate sheet for observation,",
  },
  {
    look_at: "Machine Condion",
    look_for:
      "No loose wire and no loose conection in machine\nNo Leakage Oil Water Coolent in Machines\n No Abnormal Sound in Machines\n Safety Guards are Avilable in Machines or Not\nLux Level On Shop Floor  & Machines \nSafety Sensor in machine is working or Not ?\nAvalibility of Red bin for rejection On Machine ( Should Be Neat and Clean )",
  },
  {
    s_no: "Material",
    look_at: "Material Grade",
    look_for:
      "1) Verify material grade as per Drawing   CP  & Job card\n2) Verify the Tag of material for FIFO  & Tracibility from Raw material To Dispatched Invoice ( Is it ok or Not )?\n3) Verify Inspection report of material and MTC report of Supplier and reports as per 3rd Party MTC for both ( Mechanical  & Chemicaly ) ",
  },
  {
    look_at: "Material Handling  & Keeping",
    look_for:
      "1) Procedure is specified for handling  & movement of part or untraceable part and it is implemented \n2) Safe Transport  & damage free keeping, how to move the material in shop floor.\n3) To be check the overflow in tankies bins.\n4) To be check the identidfication tag with all required tracability contents. \n5) No wrong part is mixed in tankies   trays\n6) There is no dropping part from container",
  },
  {
    s_no: "Method",
    look_at: "Tooling  Management",
    look_for:
      "1) Order of die material and inventory is controlled\n2) Inventory of die is controlled\n3) There is inspection standard for die\n4) Die   Fixture   Jigs  inspection is implemented certainly and result is recorded\n5) There is standard for judging die life\n6) Die   Fixture   Jigs  is maintained well and keeping condition is also good. There is arrangement considering keeping place, rust, dust, water.\n7) There is arrangement of preventing from misusing of die which is not reflected by design change. ",
  },
  {
    look_at: "Non Conformity Records",
    look_for:
      "1) There is rule for handling Non Conforming Parts\n 2) Place for keeping NC parts is specified and kept parts are identified.\n3) Defect is analyzed timely and contents is communicated to concerned person dep\n4) Finding root cause and taking countermeasure against\n 5) Effectiveness  of countermeasure is checked certainly\n6) Horizontal deployment is done and document is revised as per requirement.",
  },
  {
    s_no: "Measurement",
    look_at: "Inspection Data ",
    look_for:
      "1)  Inspection procedure is matching inspection standard agreed by customer \n2) Inspection method is specified(Sampling nos. specification etc.) and it is inspected as per specified method \n3) Specified inspection instrument is being used and method of using instrument is appropriate. \n4)  First product after set up change etc. is checked and record is kept\n 5)  Education and leveling of skill level is implemented for sensory inspection.",
  },
  {
    look_at: "Production  Gauges  & Instruments",
    look_for:
      "1)  Necessary instruments are kept at machine  \n 2) Keeping condition is good and not mixed with jig  & hand tool etc. \n  3) There is no abnormal instrument as like damaged one. \n2)  Condition of Scale,\n 3)  Identification no. on instrument avaliable or not,4)  Storage ,\n 5)  Calibration",
  },
  {
    look_at: "Deviation (If Any)",
    look_for: "If, required deviation of any non conformity.",
  },
  {
    s_no: "Mother Nature",
    look_at: "Safety Conditions",
    look_for:
      "1) Safety Guard on the M C ,\n2) Are Safety  & PPE equipments using by Operator, Casuals  & Inspectors. ,\n3) Working environment is good",
  },
];
