import React, { useEffect, useState, useRef } from "react";
import "../../style/product.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { DivStyled } from "../EducationTraining/Container.styled";

const AddEditDefect = ({ id, setIsModal }) => {
  const [moduleData, setModuleData] = useState({});
  const inputElement = useRef(null);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "Defect",
    `Are you sure you want to ${id ? "update" : "save"} this?`
  );

  function handleModuleData(e) {
    setModuleData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getOneDefect/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async () => {
    // e.preventDefault();
    if (!moduleData.defect_name) {
      alert("Department Name is required");
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/updateDefect/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/createDefect", moduleData)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <section className="root-admin">
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handleSumbit}
        >
          {id ? "UPDATE" : "SUBMIT"}
        </Button>
      </div>
      <DivStyled>
        <label htmlFor="defect_name">Defect Name:</label>
        <input
          type="text"
          id="defect_name"
          name="defect_name"
          ref={inputElement}
          // required
          defaultValue={moduleData?.defect_name}
          onBlur={handleModuleData}
        />
      </DivStyled>

      <DialogSubmit isSubmitContent={false} />
    </section>
  );
};

export default AddEditDefect;
