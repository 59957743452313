import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker } from "@material-ui/pickers";
import UpdateIcon from "@material-ui/icons/Update";

import { useAuth } from "../../context/GlobalContext";
import formatDate from "../../components/Control/formatDate";
import useConfirm from "../../components/Control/useConfirm";
import ApprovalSelect from "./ApprovalSelect";
import ApprovalSupervisor from "./ApprovalSupervisor";
import { Cloud } from "@material-ui/icons";
import { Wrapper } from "../../components/Control/Flexbox";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};

const IRMResitersList = () => {
  const classes = useStyles();
  const { state } = useAuth();
  const [irmResisterData, setIRMResistersData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [render, setRender] = useState(false);
  const [filter, setFilter] = useState("");
  const [departments, setDepartments] = useState([]);
  const [DialogDelete, confirmDelete] = useConfirm(
    "SAVE",
    "Are you sure you want to Delete this report?",
  );

  useEffect(() => {
    axios
      .get(
        `/api/getYearlyAndMonthlyWiseIRMResister/${selectedDate}?department=${filter}`,
      )
      .then((res) => setIRMResistersData(res.data));
  }, [selectedDate, render, filter]);
  useEffect(() => {
    axios
      .get("/api/getAllDepartment")
      .then((res) => {
        if (res.data) {
          setDepartments(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handleDelete = async (id) => {
    // const isConfirm = window.confirm(
    //   "Are you sure you want to delete this document"
    // );
    const isConfirm = await confirmDelete();
    if (isConfirm) {
      axios
        .delete(`/api/deleteIRMResisterById/${id}`)
        .then((res) => {
          alert("success deleted");
          setRender((prev) => !prev);
        })
        .catch((err) => console.error(err));
    }
  };
  const uploadFile = async (e, id) => {
    if (!window.confirm("Are you sure you want upload this Files")) return;
    const file = e.target.files;
    if (!file) return;
    let formData = new FormData();
    formData.append("files", file);
    axios
      .patch(`/api/uploadIRMResisterFile/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert("document upload successly");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteFile = async (id) => {
    if (!window.confirm("Are you sure you want delete this Files")) return;
    axios
      .delete(`/api/deleteIRMResisterFile/${id}`)
      .then((res) => {
        if (res.data) {
          alert("document deleted successly");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeStatus = (e, index) => {
    const values = [...irmResisterData];
    values[index].is_admin_request = null;
    values[index].is_admin_request_no = e.target.value;
    setIRMResistersData(values);
  };

  const updateStatus = async (id, index) => {
    let isConfirm = window.confirm("Are you sure you want to update status");
    if (!isConfirm) return;

    axios
      .patch(`/api/updateStatusIRMResisterById/${id}`, {
        is_admin_request_no: irmResisterData[index].is_admin_request_no,
        report_approved_by: state.user?.name,
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert("Status Change successfully");
        }
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <section>
      <div style={{}}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
          }}
        >
          INCOMING RAW MATERIAL REPORT
          <div>
            {(state.user.userType === 3 || state.user.userType === 2) && (
              <Link
                to={`/addEditIRMResister`}
                style={{ color: "white", textDecoration: "none" }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#054a29",
                  }}
                  startIcon={<AddCircleIcon />}
                >
                  New Daily Entry
                </Button>
              </Link>
            )}
          </div>
        </div>

        <div className="subheading-container">
          <p className="subheading-primary">
            ACTIVE <span className="subheading-secondary">REPORTS</span>
          </p>
        </div>
      </div>
      <Wrapper justify="space-between">
        <div style={{ display: "flex", margin: "1rem" }}>
          <DatePicker
            views={["month", "year"]}
            label="Month & Year"
            className="date-field"
            inputVariant="filled"
            value={selectedDate || null}
            onChange={setSelectedDate}
          />
        </div>
        <FormControl
          variant="filled"
          className="select-field"
          style={{
            marginRight: "1.5rem",
            marginTop: "0.8rem",
            flex: "0.2",
            textTransform: "uppercase",
          }}
        >
          <InputLabel style={{ textTransform: "capitalize" }}>
            Select Department
          </InputLabel>
          <Select
            name="department"
            onChange={(e) => {
              // setting page no. before setting filter value
              setFilter(e.target.value);
            }}
            value={filter}
          >
            <MenuItem value={""}>NONE</MenuItem>
            {departments.map((department, index) => (
              <MenuItem
                value={department._id}
                style={{ textTransform: "uppercase" }}
              >
                {department?.department_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Wrapper>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            {[
              "#",
              "RECEIVED DATE ",
              "DEPARTMENT",
              "STATUS",
              "ATTACH MTC",
              "ATTACH 3RD PARTY",
              "ACTION",
            ].map((item, index) => (
              <TableCell style={{ fontWeight: "bold" }} align="center">
                {item}
              </TableCell>
            ))}
            {state.user?.userType === 2 && (
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Update
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        {irmResisterData && (
          <TableBody>
            {irmResisterData.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.irmResister_date)}
                  </TableCell>
                  <TableCell align="center">
                    {item?.department_id?.department_name}
                  </TableCell>

                  {/* approval */}
                  {state.user?.userType === 2 && (
                    <TableCell align="center">
                      <ApprovalSelect
                        {...{
                          handleChangeStatus,
                          item,
                          index,
                          statusObj,
                        }}
                      />
                    </TableCell>
                  )}

                  {state.user?.userType === 3 && (
                    <TableCell align="center">
                      <ApprovalSupervisor
                        {...{
                          item,
                        }}
                      />
                    </TableCell>
                  )}

                  {/* ---x--- */}

                  <TableCell align="center">
                    <Link to={`/irmViewFile/${item._id}?documentType=files`}>
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Cloud style={{ fontSize: "1.4rem" }} />}
                      >
                        UPLOAD
                      </Button>
                    </Link>
                  </TableCell>

                  {/*  */}
                  {/* ---x--- */}

                  <TableCell align="center">
                    <Link
                      to={`/irmViewFile/${item._id}?documentType=thirdFiles`}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<Cloud style={{ fontSize: "1.4rem" }} />}
                      >
                        UPLOAD
                      </Button>
                    </Link>
                  </TableCell>

                  {/*  */}
                  <TableCell align="center">
                    <Tooltip title="View Incoming RM Resister" arrow>
                      <Link to={`/addEditIRMResister/${item._id}?isView=true`}>
                        <IconButton size="small">
                          <VisibilityIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "#003566",
                            }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>

                    {state.user.userType !== 3 && (
                      <>
                        <Tooltip title="Edit Incoming RM Resister" arrow>
                          <Link
                            to={`/addEditIRMResister/${item._id}?isView=false`}
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <EditIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Delete Incoming RM Resister" arrow>
                          <IconButton
                            size="small"
                            onClick={() => handleDelete(item._id)}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}

                    {/* for supervisor */}
                    {state.user.userType == 3 && !item.isSubmitted && (
                      <>
                        <Tooltip title="Edit Incoming RM Resister" arrow>
                          <Link
                            to={`/addEditIRMResister/${item._id}?isView=false`}
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <EditIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Delete Incoming RM Resister" arrow>
                          <IconButton
                            size="small"
                            onClick={() => handleDelete(item._id)}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>

                  {state.user?.userType === 2 && (
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        color="white"
                        style={{
                          color: "#fafafa",
                          backgroundColor: "#161A1D",
                        }}
                        onClick={(e) => {
                          updateStatus(item._id, index);
                        }}
                        startIcon={
                          <UpdateIcon style={{ fontSize: "1.4rem" }} />
                        }
                      >
                        Update
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      <DialogDelete isSubmitContent={true} />
    </section>
  );
};

export default IRMResitersList;
