import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import Styled from "styled-components";
import { Wrapper } from "../../Control/Flexbox";
import PrintIcon from "@material-ui/icons/Print";
import formatDate from "../../Control/formatDate";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;


    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
        font-weight: 400;
    }
`;
const InspectionStandard = ({ ppapData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <section>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          CHECKING AIDS
        </Typography>
        <Wrapper>
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  borderRadius: "0",
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: "Inspection Standard.xlsx",
                      sheet: {
                        name: "FINAL INSPECTION REPORT",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      </div>
      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="8, 14, 20, 25, 15, 16, 16, 31, 20,6, 6"
        >
          <tr
          // data-height="80"
          // style={{ height: "80px" }}
          >
            <th
              colSpan="6"
              align="left"
              rowSpan={3}
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
              }}
              data-a-v="middle"
            >
              <CenteredLogoImg>
                <img
                  src={images.arcLogo}
                  alt={textInputs.companyName}
                  height="46"
                  width="120"
                />
                <span>FINAL INSPECTION STANDARD</span>
              </CenteredLogoImg>
            </th>
            <th align="left" style={{ border: "1px solid black" }}>
              Part Number:
            </th>
            <td align="left" style={{ border: "1px solid black" }}>
              {ppapData.part_id.part_number}
            </td>
            <td align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Doc. Number: ARC/F/QAD/45
            </td>
          </tr>
          <tr>
            <th align="left" style={{ border: "1px solid black" }}>
              Part Name:
            </th>
            <td align="left" style={{ border: "1px solid black" }}>
              {ppapData.part_id.part_name}
            </td>
            <td align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Rev.No./Date: 00/01-04-2017
            </td>
          </tr>
          <tr>
            <th align="left" style={{ border: "1px solid black" }}>
              Supplier:
            </th>
            <td align="left" style={{ border: "1px solid black" }}>
              {ppapData.supplier_name}
            </td>
            <td align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Page:
            </td>
          </tr>

          <tbody>
            <tr>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                S NO.
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                PARAMETER
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                SPECIFICATION
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                INSPECTION METHOD
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                FREQUENCY
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                SAMPLE SIZE
              </th>
              <th
                align="center"
                colSpan={5}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                SAMPLE SIZE
              </th>
            </tr>
            <tr>
              <th
                align="center"
                colSpan={2}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                Dimensions
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                }}
              ></th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                }}
              ></th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                }}
              ></th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                }}
              ></th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                Lot Size
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                Sample
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                Accept
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              ></th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              ></th>
            </tr>
            {ppapData.ins_standard.map((item, index) => (
              <tr key={item._id}>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.s_no}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.parameter}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.specification}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.ins_method}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.freq}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.sample_size}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.lot_size}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.sample}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.accept}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                ></td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                ></td>
              </tr>
            ))}
          </tbody>
          <tr>
            <td
              colSpan={6}
              style={{
                border: "1px solid black",
              }}
            >
              Prepared By: {ppapData?.ins_prepared_by}
            </td>
            <td
              colSpan={5}
              style={{
                border: "1px solid black",
              }}
            >
              Approved By: {ppapData?.ins_approved_by}
            </td>
          </tr>
        </Table>
      </div>
    </section>
  );
};

export default InspectionStandard;
