import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Styled from "styled-components";
import formatDate from "../../../components/Control/formatDate";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import { Wrapper } from "../../../components/Control/Flexbox";
import PrintIcon from "@material-ui/icons/Print";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableReadEditCell from "../../../components/TableEditCell";
import TableInput from "../../../components/Control/TableInput";
import SaveIcon from "@material-ui/icons/Save";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:98%;
  margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    span{
      font-weight:500;
    }

`;

const ViewSetupPiece = () => {
  const [pirData, setPirData] = useState({});
  const [render, setRender] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    async function fetchPir() {
      try {
        const res = await axios.get(`/api/getPirById/${id}`);
        let setupdata = res.data;
        let changedsetup = setupdata.pir.filter(
          (item) => item.setups.length > 0
        );
        setupdata.pir = changedsetup;
        setPirData(setupdata);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPir();
    // eslint-disable-next-line
  }, []);

  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  const handleInsertData = (tools_parameter, e, pir, index) => {
    if (typeof pir[tools_parameter][index] !== "object") {
      pir[tools_parameter][index] = {};
    }
    pir[tools_parameter][index][e.target.name] = e.target.value;
    setRender((prev) => !prev);
  };

  const handleUpdate = async () => {
    const ans = window.confirm("Are you sure you want to upadate");
    if (!ans) return;

    axios
      .patch(`/api/update/pir/${id}`, pirData)
      .then((res) => {
        alert("updated successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          PROCESS INSPECTION REPORT
        </Typography>
        <Wrapper>
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ backgroundColor: "#054a29", marginRight: "2rem" }}
            startIcon={<SaveIcon />}
            onClick={(e) => {
              handleUpdate();
            }}
          >
            Update
          </Button>

          <Tooltip title="Print table" arrow>
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip title="Export as spreadsheet" arrow>
            <div>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="btn1"
                table="table-to-xls"
                filename={`Setup Report - ${
                  pirData?.part_id?.part_name
                } - ${formatDate(pirData?.pir_date)}`}
                sheet="spctable"
                buttonText={
                  <Button
                    style={{
                      backgroundColor: "#161a1d",
                      borderColor: "#161a1d",
                      color: "white",
                      width: "fit-content",
                      borderRadius: "0",
                      paddingTop: "9px",
                    }}
                  >
                    <Icon path={mdiFileExcel} size={1.2} />
                  </Button>
                }
              />
            </div>
          </Tooltip>
        </Wrapper>
      </div>
      <div style={{ paddingBottom: "4rem", marginTop: "2rem" }}>
        <Table id="table-to-xls" cellSpacing="0">
          <tr>
            <th
              colSpan={8}
              rowSpan="2"
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
                height: "100px",
              }}
            >
              <CenteredLogoImg>
                <img src={images.arcLogo} alt={textInputs.companyName} />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              DOCUMENT NO:
            </th>
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              ARC/F/QAD/45
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              Rev.No./Date:
            </th>
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              00/01-04-2017
            </th>
          </tr>

          {pirData?.pir?.map((pir, pidx) => (
            <>
              <tr>
                <td
                  colSpan={12}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                >
                  FIRST/LAST APPROVAL REPORT - {pir.process_name}
                </td>
              </tr>
              <tr>
                <th
                  align="center"
                  colSpan={12}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  Job set -ups when performed:
                </th>
              </tr>
              <tr>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={4}
                  align="left"
                >
                  PART NAME: {pirData.part_id?.part_name}
                </th>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={4}
                  align="left"
                >
                  PART NO.: {pirData.part_id?.part_number}
                </th>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={4}
                  align="left"
                >
                  DATE:{formatDate(pirData.pir_date)}
                </th>
              </tr>
              <tr>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={4}
                  align="left"
                >
                  OPERATION NO: {pir.process_name}
                </th>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={4}
                  align="left"
                >
                  MACHINE NO.: {pir.machine}
                </th>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={4}
                  align="left"
                >
                  OPERATOR NAME:{pir.operator}
                </th>
              </tr>
              <tr>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={3}
                  align="left"
                >
                  SHIFT: {pir.shift_time}
                </th>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={3}
                  align="left"
                >
                  LOT NO.: {pir.lot_no}
                </th>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={3}
                  align="left"
                >
                  LOT QTY.(To be Prod.):
                </th>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={3}
                  align="left"
                >
                  HEAT NO.: {pir.heat_no}
                </th>
              </tr>
              <tr>
                <th
                  align="center"
                  colSpan={12}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    fontSize: "1.2rem",
                  }}
                >
                  FIRST 5 PIECE OBSERVATION
                </th>
              </tr>
              <tr>
                <th
                  align="center"
                  colSpan={6}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  PRODUCT PARAMETER
                </th>
                <th
                  align="center"
                  colSpan={6}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  OBSERVATION
                </th>
              </tr>
              <tr>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  S. NO.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  PARAMETER
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  SPECIFICATION
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  UPPER LIMIT
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  LOWER LIMIT
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  INST. USED
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  colSpan={5}
                >
                  OBSERVATION
                </th>

                <th
                  style={{
                    border: "1px solid black",
                    width: "80px",

                    textAlign: "center",
                  }}
                  align="center"
                  rowSpan={2}
                >
                  JUDGEMENT (OK/NG)
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    width: "80px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  1
                </th>
                <th
                  style={{
                    width: "80px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  2
                </th>
                <th
                  style={{
                    width: "80px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  3
                </th>
                <th
                  style={{
                    width: "80px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  4
                </th>
                <th
                  style={{
                    width: "80px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  5
                </th>
              </tr>

              {pir.setups?.map((spec, sIdx) => (
                <>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {sIdx + 1}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.parameter}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.specification}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.appearance === "check1" && spec.utl}
                      {spec.appearance === "check2" &&
                        parseFloat(spec.mean) + parseFloat(spec.utolerance)}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.appearance === "check1" && spec.ltl}
                      {spec.appearance === "check2" &&
                        parseFloat(spec.mean) - parseFloat(spec.ltolerance)}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.instrument_used}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.setup_piece_first}{" "}
                      {spec.setup_piece_first && spec.unit}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.setup_piece_second}{" "}
                      {spec.setup_piece_second && spec.unit}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.setup_piece_third}{" "}
                      {spec.setup_piece_third && spec.unit}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.setup_piece_fourth}{" "}
                      {spec.setup_piece_fourth && spec.unit}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.setup_piece_fifth}{" "}
                      {spec.setup_piece_fifth && spec.unit}
                    </td>

                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.setuppiececheck_result}
                    </td>
                  </tr>
                </>
              ))}
              <tr>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  S. NO.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  colSpan={2}
                >
                  TOOL/INSERT
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  RPM REQ.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  RPM OBS.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  CHUCK/T.S. PRE REQ.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  CHUCK/T.S. PRE OBS.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  CUTTING SPEED REQ.
                </th>

                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  CUTTING SPEED OBS.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  FEED REQ.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  FEED OBS.
                </th>

                <th
                  style={{
                    border: "1px solid black",
                    width: "80px",

                    textAlign: "center",
                  }}
                  align="center"
                >
                  JUDGEMENT (OK/NG)
                </th>
              </tr>
              {Array(5)
                .fill(0)
                .map((item, index) => (
                  <tr key={index}>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td colSpan={2}>
                      <TableInput
                        isView={false}
                        name="tool_insert"
                        type="text"
                        value={pir?.tools_parameter_pir?.[index]?.tool_insert}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_pir",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="rpm_req"
                        type="text"
                        value={pir?.tools_parameter_pir?.[index]?.rpm_req}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_pir",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="rpm_obs"
                        type="text"
                        value={pir?.tools_parameter_pir?.[index]?.rpm_obs}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_pir",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="chuck_ts_pre_req"
                        type="text"
                        value={
                          pir?.tools_parameter_pir?.[index]?.chuck_ts_pre_req
                        }
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_pir",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="chuck_ts_pre_obs"
                        type="text"
                        value={
                          pir?.tools_parameter_pir?.[index]?.chuck_ts_pre_obs
                        }
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_pir",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="cutting_speed_req"
                        type="text"
                        value={
                          pir?.tools_parameter_pir?.[index]?.cutting_speed_req
                        }
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_pir",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="cutting_speed_obs"
                        type="text"
                        value={
                          pir?.tools_parameter_pir?.[index]?.cutting_speed_obs
                        }
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_pir",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="feed_req"
                        type="text"
                        value={pir?.tools_parameter_pir?.[index]?.feed_req}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_pir",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="feed_obs"
                        type="text"
                        value={pir?.tools_parameter_pir?.[index]?.feed_obs}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_pir",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="judgement"
                        type="text"
                        value={pir?.tools_parameter_pir?.[index]?.judgement}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_pir",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))}
              <tr>
                <th
                  rowSpan={2}
                  colSpan={8}
                  style={{ border: "1px solid black" }}
                >
                  Legend:- Inspection method of RPM, Cutting Speed, Feed Should
                  be visible from CNC Display Monitor Chunck/T.S. pressure
                  should be cheacked by Pressure Switch.
                </th>
                <th colSpan={4} style={{ border: "1px solid black" }}>
                  ACCEPTED:-
                </th>
              </tr>
              <tr>
                <th colSpan={4} style={{ border: "1px solid black" }}>
                  REJECTED:-
                </th>
              </tr>
              <tr>
                <th colSpan={12} style={{ border: "1px solid black" }}>
                  REASON FOR REJECTION:-
                </th>
              </tr>
              <tr>
                <th colSpan={6} style={{ border: "1px solid black" }}>
                  INSPECTION BY(operator):-
                </th>
                <th colSpan={6} style={{ border: "1px solid black" }}>
                  APPROVED BY(operator):-
                </th>
              </tr>

              <tr>
                <th
                  align="center"
                  colSpan={12}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    fontSize: "1.2rem",
                  }}
                >
                  LAST 5 PIECE OBSERVATION
                </th>
              </tr>
              <tr>
                <th
                  align="center"
                  colSpan={6}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  PRODUCT PARAMETER
                </th>
                <th
                  align="center"
                  colSpan={6}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  OBSERVATION
                </th>
              </tr>
              <tr>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  S. NO.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  PARAMETER
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  SPECIFICATION
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  UPPER LIMIT
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  LOWER LIMIT
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={2}
                >
                  INST. USED
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  colSpan={5}
                >
                  OBSERVATION
                </th>

                <th
                  style={{
                    border: "1px solid black",
                    width: "80px",

                    textAlign: "center",
                  }}
                  align="center"
                  rowSpan={2}
                >
                  JUDGEMENT (OK/NG)
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    width: "80px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  1
                </th>
                <th
                  style={{
                    width: "80px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  2
                </th>
                <th
                  style={{
                    width: "80px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  3
                </th>
                <th
                  style={{
                    width: "80px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  4
                </th>
                <th
                  style={{
                    width: "80px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  5
                </th>
              </tr>

              {pir.setups?.map((spec, index) => (
                <>
                  <tr>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.parameter}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.specification}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.appearance === "check1" && spec.utl}
                      {spec.appearance === "check2" &&
                        parseFloat(spec.mean) + parseFloat(spec.utolerance)}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.appearance === "check1" && spec.ltl}
                      {spec.appearance === "check2" &&
                        parseFloat(spec.mean) - parseFloat(spec.ltolerance)}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.instrument_used}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.last_piece_first}{" "}
                      {spec.last_piece_first && spec.unit}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.last_piece_second}{" "}
                      {spec.last_piece_second && spec.unit}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.last_piece_third}{" "}
                      {spec.last_piece_third && spec.unit}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.last_piece_fourth}{" "}
                      {spec.last_piece_fourth && spec.unit}
                    </td>
                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.last_piece_fifth}{" "}
                      {spec.last_piece_fifth && spec.unit}
                    </td>

                    <td
                      style={{
                        border: "1px solid black",
                        textAlign: "center",
                      }}
                      align="center"
                    >
                      {spec.lastpiececheck_result}
                    </td>
                  </tr>
                </>
              ))}
              <tr>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  S. NO.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  colSpan={2}
                >
                  TOOL/INSERT
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  RPM REQ.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  RPM OBS.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  CHUCK/T.S. PRE REQ.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  CHUCK/T.S. PRE OBS.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  CUTTING SPEED REQ.
                </th>

                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  CUTTING SPEED OBS.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  FEED REQ.
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                >
                  FEED OBS.
                </th>

                <th
                  style={{
                    border: "1px solid black",
                    width: "80px",

                    textAlign: "center",
                  }}
                  align="center"
                >
                  JUDGEMENT (OK/NG)
                </th>
              </tr>
              {Array(5)
                .fill(0)
                .map((item, index) => (
                  <tr key={index}>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td colSpan={2}>
                      <TableInput
                        isView={false}
                        name="tool_insert"
                        type="text"
                        value={pir?.tools_parameter_setup?.[index]?.tool_insert}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_setup",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="rpm_req"
                        type="text"
                        value={pir?.tools_parameter_setup?.[index]?.rpm_req}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_setup",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="rpm_obs"
                        type="text"
                        value={pir?.tools_parameter_setup?.[index]?.rpm_obs}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_setup",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="chuck_ts_pre_req"
                        type="text"
                        value={
                          pir?.tools_parameter_setup?.[index]?.chuck_ts_pre_req
                        }
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_setup",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="chuck_ts_pre_obs"
                        type="text"
                        value={
                          pir?.tools_parameter_setup?.[index]?.chuck_ts_pre_obs
                        }
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_setup",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="cutting_speed_req"
                        type="text"
                        value={
                          pir?.tools_parameter_setup?.[index]?.cutting_speed_req
                        }
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_setup",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="cutting_speed_obs"
                        type="text"
                        value={
                          pir?.tools_parameter_setup?.[index]?.cutting_speed_obs
                        }
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_setup",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="feed_req"
                        type="text"
                        value={pir?.tools_parameter_setup?.[index]?.feed_req}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_setup",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="feed_obs"
                        type="text"
                        value={pir?.tools_parameter_setup?.[index]?.feed_obs}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_setup",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                    <td>
                      <TableInput
                        isView={false}
                        name="judgement"
                        type="text"
                        value={pir?.tools_parameter_setup?.[index]?.judgement}
                        onChange={(e) => {
                          handleInsertData(
                            "tools_parameter_setup",
                            e,
                            pir,
                            index
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))}
              <tr>
                <th
                  rowSpan={2}
                  colSpan={8}
                  style={{ border: "1px solid black" }}
                >
                  Legend:- Inspection method of RPM, Cutting Speed, Feed Should
                  be visible from CNC Display Monitor Chunck/T.S. pressure
                  should be cheacked by Pressure Switch.
                </th>
                <th colSpan={4} style={{ border: "1px solid black" }}>
                  ACCEPTED:-
                </th>
              </tr>
              <tr>
                <th colSpan={4} style={{ border: "1px solid black" }}>
                  REJECTED:-
                </th>
              </tr>
              <tr>
                <th colSpan={12} style={{ border: "1px solid black" }}>
                  REASON FOR REJECTION:-
                </th>
              </tr>
              <tr>
                <th colSpan={6} style={{ border: "1px solid black" }}>
                  INSPECTION BY(operator):-
                </th>
                <th colSpan={6} style={{ border: "1px solid black" }}>
                  APPROVED BY(operator):-
                </th>
              </tr>
            </>
          ))}
        </Table>
      </div>
    </>
  );
};

export default ViewSetupPiece;
