import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Add, Delete } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import formatDate from "../../../components/Control/formatDate";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { DatePicker } from "@material-ui/pickers";
import SaveIcon from "@material-ui/icons/Save";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { Wrapper } from "../../../components/Control/Flexbox";

function adminRequestForm(item) {
    if (item.is_admin_request === "accepted") {
        return (
            <>
                <Tooltip title="Approved" arrow>
                    <IconButton size="small">
                        <CheckIcon style={{ fontSize: "1.4rem", color: "green" }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="View" arrow>
                    <Link
                        to={`/abnormalityReportEditRequestForm/${item._id}?isView=true`}
                    >
                        <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                            <VisibilityIcon
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
            </>
        );
    }
    if (item.is_admin_request === "pending") {
        return (
            <>
                <Tooltip title="Pending" arrow>
                    <IconButton size="small">
                        <AccessTimeIcon style={{ fontSize: "1.4rem", color: "maroon" }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="View" arrow>
                    <Link
                        to={`/abnormalityReportEditRequestForm/${item._id}?isView=true`}
                    >
                        <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                            <VisibilityIcon
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                        </IconButton>
                    </Link>
                </Tooltip>
            </>
        );
    }
    if (item.is_admin_request === "rejected") {
        return (
            <>
                <Tooltip title="Rejected" arrow>
                    <IconButton size="small">
                        <ClearIcon style={{ fontSize: "1.4rem", color: "red" }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit" arrow>
                    <Link
                        to={`/abnormalityReportEditRequestForm/${item._id}?isView=false`}
                    >
                        <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                            <EditIcon style={{ fontSize: "1.4rem", color: "#003566" }} />
                        </IconButton>
                    </Link>
                </Tooltip>
            </>
        );
    }
}

const SupervisorView4MTable = () => {
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [render, setRender] = useState(false);

    useEffect(() => {
        axios
            .get(`/api/get/ab/all/supervisor/request_form/${selectedDate}`)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => console.log(err));
    }, [selectedDate, render]);

    const deleteForm = (id) => {
        if (!window.confirm("Are you sure you want to delete this document?")) {
            return;
        }
        axios
            .delete(`/api/ab/remove/request_form/${id}`)
            .then(() => {
                setRender((prev) => !prev);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <main style={{ paddingBottom: "4rem" }}>
            <div className="divflex" style={{ fontSize: "24px" }}>
                ABNORMALITY REQUEST FORM
                {/* <Wrapper style={{ gap: "1rem" }}>
          <Button
            type="button"
            variant="contained"
            color="primary"
            size="large"
            onClick={() => history.push("/supervisor/viewparttable/4m")}
            style={{
              backgroundColor: "#054a29",
            }}
            startIcon={<Add />}
          >
            NEW Form
          </Button>
        </Wrapper> */}
            </div>
            <div className="subheading-container">
                <p className="subheading-primary">
                    ACTIVE <span className="subheading-secondary">FORM</span>
                </p>
            </div>
            <div style={{ marginTop: "2rem", marginLeft: "1rem" }}>
                <DatePicker
                    views={["month", "year"]}
                    label="Month & Year"
                    className="date-field"
                    inputVariant="filled"
                    value={selectedDate}
                    onChange={setSelectedDate}
                />
            </div>
            <Table size="small">
                <TableHead>
                    <TableRow style={{ padding: "6px" }}>
                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            #
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            Item Number
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            Type
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            Description
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            Request Date
                        </TableCell>

                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            Request Form
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="center">
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                {data && (
                    <TableBody>
                        {data.map((item, index) => (
                            <React.Fragment key={item._id}>
                                <TableRow>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">
                                        {item.part_id && item.part_id?.part_number}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {item.change_item}
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        style={{ textTransform: "capitalize" }}
                                    >
                                        {item.change_details}
                                    </TableCell>
                                    <TableCell align="center">
                                        {formatDate(item.request_date)}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.is_request_submitted ? (
                                            adminRequestForm(item)
                                        ) : (
                                            <>
                                                <Tooltip title="Saved" arrow>
                                                    <IconButton size="small">
                                                        <SaveIcon
                                                            style={{ fontSize: "1.4rem", color: "maroon" }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit" arrow>
                                                    <Link
                                                        to={`/abnormalityReportEditRequestForm/${item._id}?isView=false`}
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            style={{ marginLeft: "0.5rem" }}
                                                        >
                                                            <EditIcon
                                                                style={{ fontSize: "1.4rem", color: "#003566" }}
                                                            />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                            </>
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip title="Delete" arrow>
                                            <IconButton
                                                size="small"
                                                style={{ marginLeft: "0.5rem" }}
                                                onClick={() => deleteForm(item._id)}
                                            >
                                                <Delete style={{ fontSize: "1.4rem", color: "red" }} />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                )}
            </Table>
        </main>
    );
};

export default SupervisorView4MTable;
