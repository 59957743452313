import React, { useEffect, useState, useRef } from "react";
import "../../../style/product.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
// import Input from "../../components/Control/Input";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import SaveIcon from "@material-ui/icons/Save";
// import ClearAllIcon from "@material-ui/icons/ClearAll";
import useConfirm from "../../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { DivStyled } from "../../EducationTraining/Container.styled";

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const AddEditCustomerModal = ({ setIsNewCustomerModal, id }) => {
  const classes = dropdownStyles();
  const [customerData, setCustomerData] = useState({});
  const inputElement = useRef(null);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "MACHINE",
    `Are you sure you want to ${id ? "update" : "save"} this part?`
  );

  function handleCustomerData(e) {
    setCustomerData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getCustomerById/${id}`)
        .then((res) => setCustomerData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async () => {
    // e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/update_customer/${id}`, customerData)
        .then((res) => {
          setIsNewCustomerModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/createCustomer/new", customerData)
        .then((res) => {
          setIsNewCustomerModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <section className="root-admin">
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handleSumbit}
        >
          {id ? "UPDATE" : "SUBMIT"}
        </Button>
      </div>
      <DivStyled>
        <label htmlFor="customer_name">Customer Name:</label>
        <input
          type="text"
          id="customer_name"
          name="customer_name"
          ref={inputElement}
          defaultValue={customerData?.customer_name}
          onBlur={handleCustomerData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="item_supplied">Item Supplied:</label>
        <input
          type="text"
          id="item_supplied"
          name="item_supplied"
          defaultValue={customerData?.item_supplied}
          onBlur={handleCustomerData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="address">Address:</label>
        <input
          type="text"
          id="address"
          name="address"
          defaultValue={customerData?.address}
          onBlur={handleCustomerData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="contact_person">Contact Person: </label>
        <input
          type="text"
          id="contact_person"
          name="contact_person"
          defaultValue={customerData?.contact_person}
          onBlur={handleCustomerData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="contact_number">Contact Number</label>
        <input
          type="text"
          id="contact_number"
          name="contact_number"
          defaultValue={customerData?.contact_number}
          onBlur={handleCustomerData}
        />
      </DivStyled>

      <DialogSubmit isSubmitContent={false} />
    </section>
  );
};

export default AddEditCustomerModal;
