import React, { useEffect, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import axios from "axios";
import PublishIcon from "@material-ui/icons/Publish";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import "../../style/product.css";
import useConfirm from "../../components/Control/useConfirm";
import ComplainList from "./ComplainList";
import SelectPart from "./SelectPart";

const ComplainListInputsModal = ({
  id,
  setIsNewCustomerComplaint,
  setModalCloseBtn,
  isOnlyViewModal,
}) => {
  const [customerTrackerData, setCustomerTrackerData] = useState({
    date_of_complaint: new Date(),
    tdc: new Date(),
  });
  const [render, setRender] = useState(false);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const [allcustomerData, setAllCustomerData] = useState("");
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "MACHINE",
    `Are you sure you want to ${id ? "update" : "save"} this part?`
  );

  useEffect(() => {
    axios
      .get(`/api/getCustomer/all`)
      .then((res) => {
        const data = [];
        res.data?.results.forEach((customer) => {
          data.push({
            id: customer._id,
            customer_name: customer.customer_name,
          });
          setAllCustomerData(data);
        });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getCustomerComplaintTrackerById/${id}`)
        .then((res) => {
          setCustomerTrackerData(res.data);
          setAutoCompleteValue({
            customer_name: res.data.customer_id.customer_name,
            id: res.data.customer_id._id,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handlePartDetails = (partId, value) => {
    customerTrackerData.part_id = partId;
    customerTrackerData.part_number = value;
    setRender((prev) => !prev);
  };

  const resetPartDetails = () => {
    customerTrackerData.part_id = null;
    customerTrackerData.part_number = null;
    setRender((prev) => !prev);
  };

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/updateCustomerComplaintTracker/${id}`, customerTrackerData)
        .then((res) => {
          setIsNewCustomerComplaint(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/createCustomerComplaintTracker/new", customerTrackerData)
        .then((res) => {
          setIsNewCustomerComplaint(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        {customerTrackerData?.openAddPartModal ? (
          <Button
            type="submit"
            variant="outlined"
            color="white"
            size="large"
            style={{ color: "#e9e9e9" }}
            startIcon={<ArrowBackIosIcon />}
            onClick={(e) => {
              customerTrackerData.openAddPartModal = false;
              setModalCloseBtn(false);
              setRender((prev) => !prev);
            }}
          >
            BACK
          </Button>
        ) : (
          <Button
            type="submit"
            variant="outlined"
            color="white"
            size="large"
            style={{ color: "#e9e9e9" }}
            startIcon={<PublishIcon />}
            onClick={handleSumbit}
          >
            {id ? "UPDATE" : "SUBMIT"}
          </Button>
        )}
      </div>

      {customerTrackerData.openAddPartModal ? (
        <SelectPart
          {...{
            handlePartDetails,
            resetPartDetails,
            setModalCloseBtn,
          }}
          processes={customerTrackerData?.part_id}
        />
      ) : (
        <ComplainList
          {...{
            isOnlyViewModal,
            customerTrackerData,
            setCustomerTrackerData,
            autoCompleteValue,
            allcustomerData,
            setRender,
            setAutoCompleteValue,
            setModalCloseBtn,
          }}
        />
      )}
      <DialogSubmit isSubmitContent={false} />
    </>
  );
};

export default ComplainListInputsModal;
