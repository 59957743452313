import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SelectDate from "./SelectDate";
import ProcessWiseTable from "./ProcessWiseTable";
import ProcessCardDetails from "./ProcessCardDetails";
import ProcessWiseParetoGraph from "./ProcessWiseParetoGraph";
import { Wrapper } from "../Control/Flexbox";
import ProcessWiseBarGraph from "./ProcessWiseBarGraph";
const PartWiseAnalysis = () => {
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [processRejection, setProcessRejection] = useState(null);
  const [totalProcessRejection, setTotalProcessRejection] = useState(0);
  const { partName } = useParams();
  useEffect(() => {
    if (from && to && partName) {
      axios
        .get(`/api/get/processWiseRejection/${partName}/${from}/${to}`)
        .then((res) => {
          if (res.data.status === "failed") {
            return setProcessRejection(null);
          }
          setProcessRejection(res.data.processRejected);
          setTotalProcessRejection(res.data.totalProcessRejected);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [from, to, partName]);

  return (
    <div style={{ paddingBottom: "5rem" }}>
      <div className="divflex">
        <p
          style={{
            fontSize: "24px",
          }}
        >
          PART ANALYSIS
        </p>
        <div className="text">
          <p style={{ marginBottom: "0px" }}>
            <span style={{ fontWeight: "600" }}> Part Number : </span>
            {partName}
          </p>
        </div>
      </div>
      <SelectDate {...{ from, setFrom, to, setTo, partName }} />
      {processRejection && totalProcessRejection && (
        <Wrapper>
          <ProcessWiseTable {...{ processRejection }} />
        </Wrapper>
      )}
      {processRejection && totalProcessRejection && (
        <Wrapper style={{ marginTop: "2rem", marginLeft: "1rem", gap: "1rem" }}>
          <ProcessWiseBarGraph
            {...{ processRejection, totalProcessRejection }}
          />
          <ProcessCardDetails
            {...{ processRejection, totalProcessRejection }}
          />
        </Wrapper>
      )}
      {processRejection && totalProcessRejection && (
        <Wrapper style={{ marginLeft: "1rem", marginTop: "2rem" }}>
          <ProcessWiseParetoGraph
            {...{ processRejection, totalProcessRejection }}
          />
        </Wrapper>
      )}
    </div>
  );
};

export default PartWiseAnalysis;
