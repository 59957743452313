import React from "react";

const ApprovalSupervisor = ({ item }) => {
  return (
    <>
      {item.isSubmitted ? (
        <div
          style={{
            fontSize: "1rem",
            outline: "none",
            padding: "5px",
            textAlign: "center",
            width: "200px",
            margin: "auto",
            textTransform: "uppercase",
            ...(item.is_admin_request === "pending" && {
              backgroundColor: "#FDD835",
            }),
            ...(item.is_admin_request === "accepted" && {
              backgroundColor: "#2b8a3e",
              color: "#fff",
            }),
            ...(item.is_admin_request === "rejected" && {
              backgroundColor: "#f03e3e",
              color: "#fff",
            }),
          }}
        >
          {item.is_admin_request === "pending" && "pending for approval"}
          {item.is_admin_request === "accepted" && "accepted"}
          {item.is_admin_request === "rejected" && "rejected"}
        </div>
      ) : (
        <div
          style={{
            backgroundColor: "#FDD835",
            fontSize: "1rem",
            outline: "none",
            padding: "6px",
            textAlign: "center",
            width: "200px",
            margin: "auto",
          }}
        >
          {item.isSubmitted ? "SUBMITTED" : "DRAFT"}
        </div>
      )}
    </>
  );
};

export default ApprovalSupervisor;
