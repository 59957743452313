import { Box } from "@mui/material";
import RenderTable from "../../../../../common/RenderTable";
import TBody from "./TBody";
import TFoot from "./TFoot";
import THead from "./THead";

const TController = ({ moduleData, isView, operation /*getProcess */ }) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            // getProcess,
            isView,
            operation,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
          }}
        />
        <TFoot
          {...{
            moduleData,
            isView,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
