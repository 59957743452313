import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";

const PCDSpecs = ({ filterProcess }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell align="center" style={{ fontWeight: "bold" }}>
            #
          </TableCell>
          <TableCell align="center" style={{ fontWeight: "bold" }}>
            Parameter
          </TableCell>
          <TableCell align="center" style={{ fontWeight: "bold" }}>
            Specification
          </TableCell>
          <TableCell align="center" style={{ fontWeight: "bold" }}>
            Method
          </TableCell>
          <TableCell align="center" style={{ fontWeight: "bold" }}>
            Frequency
          </TableCell>
          <TableCell align="center" style={{ fontWeight: "bold" }}>
            Recording
          </TableCell>
          <TableCell align="center" style={{ fontWeight: "bold" }}>
            Response
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filterProcess[0].specs.map((spec, index) => (
          <TableRow key={spec._id}>
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell align="center">
              {spec.char_product || spec.char_process}
            </TableCell>
            <TableCell align="center">{spec.control_product}</TableCell>
            <TableCell align="center">{spec.methods_measure}</TableCell>
            <TableCell align="center">{spec.methods_size}</TableCell>
            <TableCell align="center">{spec.methods_rec}</TableCell>
            <TableCell align="center">{spec.corrective_action}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PCDSpecs;
