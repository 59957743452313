import React, { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";

import { v4 as uuidv4 } from "uuid";
import { ExpandLess, ExpandMore, Remove, Add } from "@material-ui/icons";
import Input from "../../../components/Control/Input";
import Modal from "../../../components/Control/Modal";
import { Wrapper } from "../../../components/Control/Flexbox";
import Fmea from "./Fmea";
import DeleteIcon from "@material-ui/icons/Delete";
import useConfirm from "../../../components/Control/useConfirm";

const compare = (a, b) => {
  return parseInt(a.s_no) - parseInt(b.s_no);
};
const FmeaProcess = ({
  process,
  removeProcess,
  fmeaData,
  handleChangeProcess,
  index,
  expandState,
  setExpandState,
}) => {
  const [render, setRender] = useState(false);
  const [fmeaModalData, setFmeaModalData] = useState(null);
  const [isFmeaModal, setIsFmeaModal] = useState(false);

  const [DialogDeleteFailure, confirmDeleteFailure] = useConfirm(
    "DELETE FAILURE",
    "Are you sure you want to delete this failures?"
  );
  const handleFmeaModalData = (e) => {
    fmeaModalData[e.target.name] = e.target.value;
  };

  const sortProcess = () => {
    fmeaData.processes[index].fmeas.sort(compare);
    setRender((prev) => !prev);
  };

  const handleSnoAndSorting = (e, fmea) => {
    fmea[e.target.name] = e.target.value;
    sortProcess();
  };

  return (
    <section>
      <div
        style={{
          marginBottom: "0.3rem",
          marginTop: "0.6rem",
          padding: "0rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#fafafa",
            alignItems: "center",
          }}
          className="spec-header"
        >
          <div
            style={{
              display: "flex",
              gap: "4rem",
              alignItems: "center",
            }}
          >
            <Wrapper>
              <Input
                type="Number"
                name="s_no"
                onBlur={(e) => handleChangeProcess(e, index)}
                defaultValue={process.s_no}
                style={{ width: "80px" }}
              />
            </Wrapper>
            <div
              style={{
                marginLeft: "1rem",
                fontSize: "0.9rem",
                textTransform: "uppercase",
              }}
            >
              {process?.process_name}
            </div>
          </div>
          <Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "175px",
                backgroundColor: "#003566",
                color: "white",
                cursor: "pointer",
                display: "flex",
                gap: "0.5rem",
                marginRight: "0.2rem",
              }}
              onClick={() => {
                fmeaData.processes[index].fmeas.push({
                  s_no: process?.fmeas?.length + 1,
                  key: uuidv4(),
                  eof: [],
                  potential_cause: [],
                });

                setExpandState((prev) => {
                  prev[index] = true;
                  return [...prev];
                });
              }}
              justify="center"
            >
              <Add />
              <span>Add Failures</span>
            </Wrapper>
            {expandState[index] ? (
              <Wrapper
                style={{
                  height: "45px",
                  width: "130px",
                  backgroundColor: "#003566",
                  cursor: "pointer",
                  color: "white",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
                onClick={() =>
                  setExpandState((prev) => {
                    prev[index] = false;
                    return [...prev];
                  })
                }
              >
                <ExpandLess style={{ marginLeft: "0.5rem" }} />
                <span>Collapse</span>
              </Wrapper>
            ) : (
              <Wrapper
                style={{
                  height: "45px",
                  width: "130px",
                  backgroundColor: "#003566",
                  cursor: "pointer",
                  color: "white",
                  gap: "0.5rem",
                }}
                onClick={() =>
                  setExpandState((prev) => {
                    prev[index] = true;
                    return [...prev];
                  })
                }
              >
                <ExpandMore style={{ marginLeft: "0.5rem" }} />
                <span>Expand</span>
              </Wrapper>
            )}
            <Wrapper
              style={{
                height: "45px",
                width: "45px",
                color: "#f1f3f5",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                gap: "0.5rem",
                backgroundColor: "#D70012",
              }}
              justify="center"
              onClick={() => removeProcess(index)}
            >
              <DeleteIcon style={{ color: "#f8f9fa" }} />
            </Wrapper>
          </Wrapper>
        </div>
        {expandState[index] && (
          <Wrapper
            style={{
              borderTop: "1px solid #e9ecef",
              borderLeft: "1px solid #e9ecef",
              borderRight: "1px solid #e9ecef",
              borderBottom: "1px solid #e9ecef",
              marginTop: "5px",
              alignItems: "center",
              marginBottom: "5px",
            }}
          >
            <Input
              className="input_field_width"
              type="text"
              name="process_name"
              defaultValue={process?.process_name}
              style={{
                fontSize: "1rem",
                textTransform: "uppercase",
              }}
              onBlur={(e) => handleChangeProcess(e, index)}
              placeholder="Operation Number / Description"
            />
          </Wrapper>
        )}
        {expandState[index] &&
          process.fmeas.map((fmea, fmeaIndex) => (
            <>
              <div
                key={fmea.key}
                style={{
                  marginBottom: "0.3rem",
                  marginTop: "0.6rem",
                  padding: "0rem",
                }}
              >
                {/* process input field  */}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#fafafa",
                    alignItems: "center",
                  }}
                  className="spec-header"
                >
                  <Wrapper>
                    <Input
                      type="Number"
                      name="s_no"
                      // onBlur={(e) => sortProcess(e)}
                      onBlur={(e) => handleSnoAndSorting(e, fmea, fmeaIndex)}
                      defaultValue={fmea.s_no}
                      style={{ width: "80px" }}
                    />
                    <div style={{ marginLeft: "3rem" }}>
                      {fmea.potential_mode}
                    </div>
                  </Wrapper>
                  <div style={{ display: "flex" }}>
                    <Wrapper
                      style={{
                        display: "flex",
                        color: "#fafafa",
                        backgroundColor: "#161A1D",
                        height: "45px",
                        width: "130px",
                        minWidth: "130px",
                        borderRadius: "0",
                        alignItems: "center",
                        justify: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFmeaModalData(fmea);
                        setIsFmeaModal(true);
                      }}
                    >
                      <EditIcon
                        style={{
                          margin: "0 0.4rem 0 0.6rem ",
                          fontSize: "1.2rem",
                        }}
                      />
                      <span style={{ marginRight: "0.5rem", fontSize: "1rem" }}>
                        Edit Failure
                      </span>
                    </Wrapper>

                    <Wrapper
                      style={{
                        height: "45px",
                        width: "45px",
                        minWidth: "45px",
                        color: "#f1f3f5",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        gap: "0.5rem",
                        backgroundColor: "#D70012",
                      }}
                      justify="center"
                      onClick={async () => {
                        const ans = await confirmDeleteFailure();
                        if (ans) {
                          process.fmeas.splice(fmeaIndex, 1);
                          setRender((prev) => !prev);
                        }
                      }}
                    >
                      <DeleteIcon style={{ color: "#f8f9fa" }} />
                    </Wrapper>
                  </div>
                </div>
              </div>
            </>
          ))}
      </div>
      {fmeaModalData && (
        <Modal
          title={"Failure Description"}
          openPopup={isFmeaModal}
          closeModal={() => setIsFmeaModal(false)}
        >
          <Fmea {...{ fmeaModalData, handleFmeaModalData }} />
        </Modal>
      )}
      <DialogDeleteFailure isSubmitContent={false} />
    </section>
  );
};

export default FmeaProcess;
