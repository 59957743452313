import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import TableCustom from "../../../common/TableCustom";
import {
  DeleteIconButton,
  ViewIconButton,
} from "../../../common/CustomIconButton";

const Header = [
  [
    {
      text: "#",
      colspan: 1,
      rowspan: 1,
    },
    {
      text: "NAME",
      colspan: 1,
      rowspan: 1,
    },
    {
      text: "ACTION",
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const ViewFile = ({ id }) => {
  const [render, setRender] = useState(false);
  const [files, setFiles] = useState(null);
  useEffect(() => {
    axios
      .get(`/api/trainingRecordRoute/${id}`)
      .then((res) => {
        if (res?.data) {
          setFiles(res?.data?.files);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [render]);
  const deleteFile = async (index) => {
    if (!window.confirm("Are you sure you want to delete this file?")) {
      return;
    }
    axios
      .delete(`/api/trainingRecordRoute/deleteFile/${id}/${index}`)
      .then((res) => {
        if (res.data) {
          alert("document deleted successfully");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box sx={{ width: 500 }}>
      <TableCustom tableHeaderObj={Header}>
        {files && (
          <TableBody>
            {files.map((file, index) => (
              <React.Fragment key={file._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{file.name}</TableCell>
                  <TableCell align="center">
                    <>
                      <a target={"_blank"} href={file.url}>
                        <ViewIconButton
                          tooltipTitle="view"
                          sx={{ marginLeft: "1.5rem" }}
                        />
                      </a>
                      <DeleteIconButton
                        tooltipTitle="delete"
                        onClick={() => deleteFile(index)}
                      />
                    </>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </TableCustom>
    </Box>
  );
};

export default ViewFile;
