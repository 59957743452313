import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import formatDate from "../../../components/Control/formatDate";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../../components/Control/useDebounce";
import { useRef } from "react";
import PlanMaintenance from "./PlanMaintenance";
import { useParams, Link, useHistory } from "react-router-dom";
import useConfirm from "../../../components/Control/useConfirm";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const Maintenance = () => {
  const [data, setData] = useState([]);
  const [isMaintenanceModal, setIsMaintenanceModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const history = useHistory();

  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this report?"
  );

  const maintenanceId = useRef();
  const fetchData = async () => {
    const res = await axios
      .get(`/api/getAllPreventive?page=${page}&search=${search}`)
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [page, search, render, isMaintenanceModal]);

  async function deletePreventive(id) {
    const isConfirm = await confirmDelete();
    if (isConfirm) {
      await axios.delete(`/api/deletePreventive/${id}`);
      setRender((prev) => !prev);
    }
  }

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          PREVENTIVE MAINTENANCE LIST
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                maintenanceId.current = null;
                setIsMaintenanceModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Plan
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "1" }}>
              <Input
                placeholder="Search Machine Name"
                variant="filled"
                style={{
                  width: "97%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Title
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Date Range{" "}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          maxWidth: "15rem",
                          width: "15rem",
                        }}
                      >
                        {item.title}
                      </TableCell>
                      <TableCell align="center">
                        {getYearRange(item.date_range)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <Tooltip title="View" arrow>
                          <Link to={`/preventiveMiantainanceView/${item._id}`}>
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <VisibilityIcon
                                style={{ fontSize: "1.4rem", color: "#003566" }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>

                        <Tooltip title="Edit Preventive Maintenace Plan" arrow>
                          <Link
                            to={`/createEditPreventive/maintenanceTable/${item._id}`}
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <EditIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip
                          title="Delete Preventive Maintenace Plan"
                          arrow
                        >
                          <IconButton
                            size="small"
                            onClick={() => deletePreventive(item._id)}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <Modal
          title={
            maintenanceId.current ? "update machine" : "create new machine"
          }
          openPopup={isMaintenanceModal}
          closeModal={() => {
            setIsMaintenanceModal(false);
            maintenanceId.current = null;
          }}
          backgroundColor="white"
        >
          <PlanMaintenance
            id={maintenanceId.current}
            {...{ setIsMaintenanceModal }}
          />
        </Modal>
        <DialogDelete isSubmitContent={true} />
      </main>
    </>
  );
};

export default Maintenance;
