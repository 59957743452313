import { Button, Paper, Tab, Tabs } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { React, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Wrapper } from "../../../components/Control/Flexbox";
import useConfirm from "../../../components/Control/useConfirm";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import { apqpConstant } from "./constant";
import ApqpTimePlanTable from "./ApqpTimePlanTable";
import axios from "axios";
import lodash from "lodash";
import { useQuery } from "../../../components/Control/useQuery";

const tabData = [
  { phase: "phase_1", title: "Phase – 1 : Plan & Define" },
  { phase: "phase_2", title: "Phase – 2 : Product Development" },
  { phase: "phase_3", title: "Phase – 3 : Process Design & Development" },
  { phase: "phase_4", title: "Phase – 4 : Product & Process Validation" },
  {
    phase: "phase_5",
    title: "Phase – 5 : Feedback Assessment & Corrective Action",
  },
];
const matrixActivity = [
  // {
  //     param: "development_a",
  //     name: "A",
  // },
  // {
  //     param: "development_b",
  //     name: "B",
  // },
  // {
  //     param: "development_c",
  //     name: "C",
  // },
  // {
  //     param: "development_d",
  //     name: "D",
  // },
  {
    param: "npd_head",
    name: "NPD HEAD",
  },
  {
    param: "qa",
    name: "QA",
  },
  {
    param: "cft",
    name: "CFT",
  },
  {
    param: "pr",
    name: "PR",
  },
  {
    param: "mn",
    name: "MN",
  },
  {
    param: "mkt",
    name: "MKT",
  },
  {
    param: "hr_head",
    name: "HR HEAD",
  },
];
const syncActivity = async (apqpData, partId) => {
  let res = await axios
    .get(`/api/getApqpMatrixById/${partId}`)
    .catch((err) => console.log(err));
  if (res.data) {
    const apqpMatrix = res.data;

    ["phase_1", "phase_2", "phase_3", "phase_4", "phase_5"].map((phase) => {
      apqpMatrix[phase].forEach((item, index) => {
        apqpData[phase][index].resp = "";
        matrixActivity.forEach((matrix) => {
          if (!item[matrix.param]) {
            return;
          }
          if (item[matrix.param] && item[matrix.param]) {
            apqpData[phase][index].resp += ` ${matrix.name} `;
          }
        });
      });
    });
  }
  return apqpData;
};
const CreareEditApqpTimeplan = () => {
  const { partId } = useParams();
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [render, setRender] = useState(false);
  const query = useQuery();
  const isView = query.get("isView") === "false" ? false : true;
  const [apqpData, setApqpData] = useState({
    phase_1: [],
    phase_2: [],
    phase_3: [],
    phase_4: [],
    phase_5: [],
  });

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );

  const [DialogSubmit, confirmSubmit] = useConfirm(
    "SUBMIT",
    `Are you sure you want to ${apqpData._id ? "update" : "save"}?`
  );
  const [employeeAutocomplete, setEmployeeAutocomplete] = useState([]);
  const [employeeValue, setEmployeeValue] = useState({
    phase_1_employees_id: [null, null, null],
    phase_2_employees_id: [null, null, null],
    phase_3_employees_id: [null, null, null],
    phase_4_employees_id: [null, null, null],
    phase_5_employees_id: [null, null, null],
  });

  useEffect(() => {
    axios
      .get(`/api/getAllEmployeeData/`)
      .then((res) => {
        setEmployeeAutocomplete(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios.get(`/api/getApqpTimePlanById/${partId}`).then(async (res) => {
      if (res.data?.msg === "not_found") {
        const rawData = {
          phase_months: [],
          ...lodash.cloneDeep(apqpConstant),
          phase_1_employees_id: [],
          phase_2_employees_id: [],
          phase_3_employees_id: [],
          phase_4_employees_id: [],
          phase_5_employees_id: [],
          phase_1_remarks: [],
          phase_2_remarks: [],
          phase_3_remarks: [],
          phase_4_remarks: [],
          phase_5_remarks: [],
        };
        const convertedApqpTimePlan = await syncActivity(rawData, partId);
        setApqpData(convertedApqpTimePlan);
        return;
      }
      const convertedApqpTimePlan = await syncActivity(res.data, partId);
      setEmployeeValue({
        phase_1_employees_id: convertedApqpTimePlan.phase_1_employees_id,
        phase_2_employees_id: convertedApqpTimePlan.phase_2_employees_id,
        phase_3_employees_id: convertedApqpTimePlan.phase_3_employees_id,
        phase_4_employees_id: convertedApqpTimePlan.phase_4_employees_id,
        phase_5_employees_id: convertedApqpTimePlan.phase_5_employees_id,
      });
      setApqpData(convertedApqpTimePlan);
    });
  }, [render]);
  const onSubmit = async () => {
    return new Promise(async (resolve) => {
      let isSubmit = await confirmSubmit();
      if (!isSubmit) {
        return resolve();
      }
      apqpData.part_id = partId;

      if (apqpData._id) {
        axios
          .put(`/api/UpdateApqpTimeplan/${apqpData._id}`, apqpData)
          .then((res) => {
            if (res.data) alert("updated successfully");
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        axios
          .post(`/api/CreateApqpTimeplan`, apqpData)
          .then((res) => {
            if (res.data) {
              alert("updated successfully");
              setRender((prev) => !prev);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      resolve();
    });
  };
  const handleChange = (e, phase, index) => {
    const { value, name } = e.target;
    const values = { ...apqpData };
    values[phase][index][name] = value;
    setApqpData(values);
  };

  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", justifyContent: "space-between" }}
      >
        APQP TIMEPLAN
        <Wrapper>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Wrapper
              style={{
                gap: "1rem",
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#064a29",
                  marginRight: "0.5rem",
                }}
                startIcon={<CloudUploadIcon />}
                onClick={() => onSubmit()}
              >
                Submit
              </Button>
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (ans) {
                    history.goBack();
                  }
                }}
              />
            </Wrapper>
          </div>
        </Wrapper>
      </div>
      <div>
        <DialogSubmit isSubmitContent={true} />
      </div>
      <Paper elevation={2} style={{ margin: "1rem 0.5rem 0rem 0.5rem" }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          textColor="secondary"
        >
          <Tab label={"Phase – 1"} style={{ alignItems: "flex-start" }} />
          <Tab label={"Phase – 2"} style={{ alignItems: "flex-start" }} />
          <Tab label={"Phase – 3"} style={{ alignItems: "flex-start" }} />
          <Tab label={"Phase – 4"} style={{ alignItems: "flex-start" }} />
          <Tab label={"Phase – 5"} style={{ alignItems: "flex-start" }} />
        </Tabs>
      </Paper>

      <ApqpTimePlanTable
        {...{
          apqpData,
          handleChange,
          isView,
          employeeAutocomplete,
          employeeValue,
          setEmployeeValue,
          partId,
          onSubmit,
        }}
        phase={tabData[tabValue].phase}
        title={tabData[tabValue].title}
      />

      <DialogClose isSubmitContent={false} />
    </>
  );
};

export default CreareEditApqpTimeplan;
