import { Table } from "./Table";
import TableScroll from "./TableScroll";
const RenderTable = ({ children, ...rest }) => {
  return (
    <TableScroll {...rest}>
      <Table id={"tableDownloadId"} cellSpacing={0}>
        {children}
      </Table>
    </TableScroll>
  );
};

export default RenderTable;
