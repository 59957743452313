import React, { useEffect, useState } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import TableCustom from "../../../common/TableCustom";
import { EditIconButton } from "../../../common/CustomIconButton";
import { getDailyProductionYearly } from "./helpers/utils";
import ChildHeader from "../../../common/ChildHeader";
import { useQuery } from "../../../components/Control/useQuery";
import { useHistory } from "react-router-dom";
import "../../../../index.css";
import { ContainerForNewMui } from "../../../common/ContainerForNewMui";
import DatePickerMui from "../../../common/DatePickerMui";

const Header = [
  [
    {
      text: "#",
      colspan: 1,
      rowspan: 1,
    },

    {
      text: "MONTH & YEAR",
      colspan: 1,
      rowspan: 1,
    },
    {
      text: "ACTION",
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const TableList = () => {
  const [monitoringList, setMoniteringList] = useState([]);
  const history = useHistory();
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    let data = getDailyProductionYearly(date);
    setMoniteringList(data);
  }, [date]);
  return (
    <ContainerForNewMui>
      <ChildHeader text="4M SUMMARY" />
      <div style={{ display: "flex", margin: "0.5rem" }}>
        <Box sx={{ margin: 2 }}>
          <DatePickerMui
            label="Year"
            views={["year"]}
            format={"yyyy"}
            value={date}
            onChange={(date) => setDate(date)}
          />
        </Box>
      </div>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {monitoringList.map((item, index) => (
            <React.Fragment>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item.month_year}</TableCell>
                <TableCell align="center">
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      history.push(
                        `/common/create4mSummary?isView=false&annual_date=${item.month_year}`,
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </TableCustom>
    </ContainerForNewMui>
  );
};

export default TableList;
