import React from "react";
import { Wrapper } from "../Control/Flexbox";
import styled from "styled-components";

const Div = styled(Wrapper)`
  height: 70vh;
  width: 100vw;
  justify-content: center;
  h1 {
    text-transform: uppercase;
  }
`;
function PageNotFound() {
  return (
    <Div>
      <h1>Not Found</h1>
    </Div>
  );
}

export default PageNotFound;
