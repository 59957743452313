import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableReadEditCell from "../../../components/TableEditCell";
import TableScroll from "../../../components/TableScroll";
import { useQuery } from "../../../components/Control/useQuery";
import { useAuth } from "../../../context/GlobalContext";
import { Save } from "@material-ui/icons";
import formatDate from "../../../components/Control/formatDate";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:96%;
  margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    .input {
        outline: none;
        width: 100px;
        border: none;
        height: 50px;
        text-align:center;
    }

    .middleText  > th {
      text-align: center;

    }
    .tablebody-row td{
      text-align:center;
    }

`;

function ViewSetupForm() {
  const { id } = useParams();
  const [data, setData] = useState({});
  const { state } = useAuth();
  const history = useHistory();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  useEffect(() => {
    async function fetchPir() {
      try {
        const res = await axios.get(`/api/get/request_form/${id}`);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPir();
    // eslint-disable-next-line
  }, []);

  const handleChange = (e, index, arrayName) => {
    if (index || index === 0) {
      const values = { ...data };
      values[arrayName][index][e.target.name] = e.target.value;
      setData(values);
    } else {
      const values = { ...data };
      values[e.target.name] = e.target.value;
      setData(values);
    }
  };

  const onSubmit = (isSubmitted) => {
    const isSubmit = window.confirm(
      "Are you sure you want to submit this Request Form?"
    );
    if (!isSubmit) {
      return;
    }

    data.is_setup_submitted = isSubmitted;
    if (isSubmitted) {
      data.is_admin_setup = "pending";
      data.admin_setup_no = 1;
    } else {
      data.is_admin_setup = "nothing";
    }
    data.setup_prepared_by = state.user.name;
    axios
      .put(`/api/edit/request_form/${id}`, data)
      .then((res) => {
        if (res.data.status === "success") {
          history.push("/supervisor/view/4mTable");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div style={{ paddingBottom: "4rem" }}>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          4M SETUP FORM
        </Typography>
        <Wrapper>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={() => {
              onSubmit(false);
            }}
            style={{
              color: "#e9e9e9",
              backgroundColor: "#003566",
              marginLeft: "0.7rem",
            }}
            startIcon={<Save />}
          >
            Save & Continue
          </Button>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={() => {
              onSubmit(true);
            }}
            style={{
              color: "#e9e9e9",
              backgroundColor: "#064a29",
              marginLeft: "0.7rem",
            }}
            startIcon={<Save />}
          >
            Submit
          </Button>
        </Wrapper>
      </div>
      <TableScroll>
        <Table
          cellSpacing="0"
          style={{ marginTop: "0.6rem" }}
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="15, 23, 24, 23, 23, 30, 30, 15, 15, 13"
        >
          <tr data-height="80">
            <th
              colSpan={14}
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
                height: "100px",
              }}
            >
              <CenteredLogoImg>
                <img src={images.arcLogo} alt={textInputs.companyName} />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>

            <th align="left">FORMAT NO:</th>
            <th align="left">MSC/QA/02A/00</th>
          </tr>
          <tr data-height="40">
            <th
              colSpan={16}
              align="center"
              style={{
                border: "1px solid black",
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                textTransform: "uppercase",
                textAlign: "center",
              }}
              data-fill-color="FF000000"
            >
              FIRST / LAST PIECE APPROVAL
            </th>
          </tr>
          <tr>
            <th colSpan={3}>PART NAME: {data.part_id?.part_name}</th>
            <th colSpan={5}>
              MATERIAL & GRADE:
              <TableReadEditCell
                name="material_grade"
                type="text"
                key="material_grade"
                style={{ width: "100%" }}
                value={data.material_grade}
                {...{ handleChange, isView }}
              />
            </th>
            <th colSpan={5}>
              COLOUR:
              <TableReadEditCell
                name="colour"
                type="text"
                key="colour"
                style={{ width: "100%" }}
                value={data.colour}
                {...{ handleChange, isView }}
              />
            </th>
            <th colSpan={3}>DATE: {formatDate(data.request_date)}</th>
          </tr>
          <tr>
            <th colSpan={3}>CUSTOMER: {data.customer}</th>
            <th colSpan={5}>M/C: {data.machine_id?.machine_name}</th>
            <th colSpan={5}>
              CAVITY:
              <TableReadEditCell
                name="cavity"
                type="text"
                key="cavity"
                value={data.cavity}
                style={{ width: "100%" }}
                {...{ handleChange, isView }}
              />
            </th>
            <th colSpan={3}>SHIFT: {data.shift}</th>
          </tr>
          <tr>
            <th colSpan={4}>
              PART WT.:
              <TableReadEditCell
                name="part_wt"
                type="text"
                key="part_wt"
                value={data.part_wt}
                style={{ width: "100%" }}
                {...{ handleChange, isView }}
              />
            </th>
            <th colSpan={4}>
              RUNNER WT.:
              <TableReadEditCell
                name="runner_wt"
                type="text"
                key="runner_wt"
                value={data.runner_wt}
                style={{ width: "100%" }}
                {...{ handleChange, isView }}
              />
            </th>
            <th colSpan={4}>
              INSERT WT.
              <TableReadEditCell
                name="insert_wt"
                type="text"
                key="insert_wt"
                value={data.insert_wt}
                style={{ width: "100%" }}
                {...{ handleChange, isView }}
              />
            </th>
            <th colSpan={4}></th>
          </tr>
          <tbody className="tablebody-row">
            <tr className="middleText">
              <th></th>
              <th>PARAMETERS</th>
              <th>TIME</th>
              {data.times?.map((time, index) => (
                <th>
                  <TableReadEditCell
                    name="time"
                    type="text"
                    key="time"
                    value={time}
                    handleChange={(e, index) => {
                      const values = { ...data };
                      values.times[index] = e.target.value;
                      setData(values);
                    }}
                    {...{ index, isView }}
                  />
                </th>
              ))}
              <th></th>
            </tr>
            <tr className="middleText">
              <th>#</th>
              <th>ASTHETICAL</th>
              <th>STATUS</th>
              <th colSpan={12}>CAVITYWISE OBSERVATION</th>
              <th>REMARKS</th>
            </tr>
            {data.productInspections?.map((product, index) => (
              <tr>
                {index === 0 && (
                  <td rowSpan={data.productInspections.length}>
                    PRODUCT INSPECTION
                  </td>
                )}
                <td style={{ textTransform: "uppercase" }}>
                  {product.parameter}
                </td>
                {index === 0 && (
                  <td rowSpan={data.productInspections.length}>
                    SHOULD NOT BE APPEARED
                  </td>
                )}
                {product.obs.map((value, obsIndex) => (
                  <td>
                    <TableReadEditCell
                      name="obs"
                      type="number"
                      key="product_obs"
                      value={value}
                      handleChange={(e, index) => {
                        const values = { ...data };
                        values.productInspections[index].obs[obsIndex] =
                          e.target.value;
                        setData(values);
                      }}
                      {...{ index, isView }}
                    />
                  </td>
                ))}
                <td>
                  <TableReadEditCell
                    name="remarks"
                    handleChange={(e, index) =>
                      handleChange(e, index, "productInspections")
                    }
                    type="text"
                    key="remarks"
                    value={product.remarks}
                    {...{ index, isView }}
                  />
                </td>
              </tr>
            ))}
            <tr style={{ height: "45px" }}>
              <td colSpan={16}></td>
            </tr>
            <tr className="middleText">
              <th>#</th>
              <th>SPECIFICATION</th>
              <th>INSP. METHODS</th>
              <th colSpan={12}>CAVITYWISE OBSERVATION</th>
              <th>REMARKS</th>
            </tr>
            {data.dimensions?.map((dimension, index) => (
              <tr>
                <td>DIMENSIONS</td>
                <td>{dimension.specification}</td>
                <td>{dimension.methods}</td>
                {dimension.obs.map((value, obsIndex) => (
                  <th>
                    <TableReadEditCell
                      name="obs"
                      type="number"
                      key="dimension_obs"
                      handleChange={(e, index) => {
                        const values = { ...data };
                        values.dimensions[index].obs[obsIndex] = e.target.value;
                        setData(values);
                      }}
                      value={value}
                      {...{ index, isView }}
                    />
                  </th>
                ))}
                <td>
                  <TableReadEditCell
                    name="remarks"
                    type="text"
                    key="remarks"
                    value={dimension.remarks}
                    handleChange={(e, index) =>
                      handleChange(e, index, "dimensions")
                    }
                    {...{ index, isView }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
          <tr style={{ height: "60px" }} data-height="35">
            <td
              align="left"
              colSpan={16}
              style={{ fontWeight: 600, border: "1px solid black" }}
            >
              SPECIAL REMARKS:
              <TableReadEditCell
                name="special_remarks"
                style={{ width: "100%" }}
                type="text"
                value={data.special_remarks}
                {...{ handleChange, isView }}
              />
            </td>
          </tr>
          <tr style={{ height: "60px" }} data-height="35">
            <td align="left" colSpan={4} style={{ fontWeight: 500 }}>
              Prepared By:
            </td>
            <td
              align="left"
              colSpan={4}
              style={{ textTransform: "capitalize" }}
            >
              {data.setup_prepared_by}
            </td>

            <td align="left" colSpan={4} style={{ fontWeight: 500 }}>
              Approved By:
            </td>
            <td
              align="left"
              style={{ textTransform: "capitalize" }}
              colSpan={4}
            >
              {data.is_admin_setup === "accepted" && data.setup_approved_by}
            </td>
          </tr>
        </Table>
      </TableScroll>
    </div>
  );
}

export default ViewSetupForm;
