import Modal from "../../components/Control/Modal";
import React, { useEffect, useState } from "react";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import { TableContainer, TextField, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

const checkArrayStrCondition = (currentValue, prevValue) => {
  const convertedCurrentValue =
    typeof currentValue === "object" ? currentValue.join(",") : currentValue;
  const convertedPrevValue =
    typeof currentValue === "object" ? prevValue.join(",") : prevValue;
  return convertedCurrentValue !== convertedPrevValue;
};

const ECNModal = (props) => {
  const {
    openPopup,
    closeModal,
    partData,
    partDataForRevision,
    documentType,
    submit,
    ecnTitle,
  } = props;
  const pfdSymbols = {
    "": "None",
    1: "◯ - Operation",
    2: "▭ - Inspection",
    3: "⧇ - Operation Inspection",
    4: "△ - Storage",
    5: "📦- Packing",
    6: "⇨ - Transportation",
  };
  const pfdParams = [
    { param: "machine_pfd", placeholder: "Machine" },
    { param: "spec_to_achieve_pfd", placeholder: "Specification to Achieve" },
    { param: "variation", placeholder: "Process Control Parameters" },
    { param: "product_char", placeholder: "Key Control Characteristics" },
    { param: "symbol", placeholder: "Symbol" },
    { param: "key_control", placeholder: "Key Control Characteristics" },
  ];
  const specParams = [
    { param: "char_process", placeholder: "Process Char." },
    { param: "char_product", placeholder: "Product Char." },
    { param: "special_character", placeholder: "Special Character" },
    { param: "control_product", placeholder: "Spec./Tolerance" },
    { param: "methods_measure", placeholder: "Evaluation" },
    { param: "methods_size", placeholder: "Sample Size" },
    { param: "methods_by", placeholder: "Sample Frequency" },
    { param: "recording", placeholder: "Recording" },
    { param: "responsibility", placeholder: "Responsibility" },
    { param: "methods_process", placeholder: "Control Method" },
    { param: "reaction_plan", placeholder: "Reaction Plan & Action" },
    { param: "remarks", placeholder: "Remarks" },
    { param: "control_product_remark", placeholder: "Tolerance Remarks" },
    { param: "unit", placeholder: "Unit" },
    { param: "machine", placeholder: "Machine" },
  ];

  const fmeaParams = [
    { param: "requirement", placeholder: "Requirement" },
    { param: "potential_mode", placeholder: "Potential Failure Mode" },
    { param: "eof", placeholder: "Potential Effect(s) of  Failure" },
    { param: "severity", placeholder: "Severity" },
    { param: "failure_class", placeholder: "Class" },
    { param: "potential_cause", placeholder: "Potential Cause(s) of  Failure" },
    {
      param: "control_prevention",
      placeholder: "Current Process Control Prevention",
    },
    {
      param: "control_detection",
      placeholder: "Current Process Control Detection",
    },
    { param: "recommendation_action", placeholder: "Recommended Actions" },
    { param: "det", placeholder: "Detection Rating (DET)" },
  ];
  const [controlData, setControlData] = useState([]);
  const [pfdData, setPfdData] = useState([]);
  const [processData, setProcessData] = useState([]);
  const [fmeaData, setFmeaData] = useState([]);
  const [revisionData, setRevisionData] = useState({});
  const [render, setRender] = useState(false);
  useEffect(() => {
    partData.processes.map((process, index1) => {
      let prevProcess;
      if (!process._id) {
        revisionData[index1 + "_added"] = {
          index1: index1,
          value: `Process ${process.process_name} Created`,
          reason: "",
          addToRevisionSummary: true,
          type: "create",
          section: "process",
        };
      } else {
        prevProcess = partDataForRevision.processes.find(
          (item) => item._id === process._id
        );
      }
      if (!prevProcess) return;
      if (documentType === "pfd") {
        pfdParams.map((pfdParam) => {
          if (process[pfdParam.param] !== prevProcess[pfdParam.param]) {
            revisionData[index1 + "_" + pfdParam.param] = {
              index1: index1,
              value: `${process.process_name}, ${
                pfdParam.placeholder
              } Changed 𝗙𝗥𝗢𝗠 ${
                pfdParam.param !== "symbol"
                  ? prevProcess[pfdParam.param]
                  : pfdSymbols[prevProcess[pfdParam.param]]
              } 𝗧𝗢 ${
                pfdParam.param !== "symbol"
                  ? process[pfdParam.param]
                  : pfdSymbols[process[pfdParam.param]]
              }`,
              reason: "",
              addToRevisionSummary: true,
              type: "change",
              section: "pfd",
            };
          }
        });
      }
      if (documentType === "controlplan") {
        process.specs.map((spec, index2) => {
          if (!spec._id) {
            revisionData[index1 + "_" + index2 + "_added"] = {
              index1: index1,
              index2: index2,
              value: `${process.process_name}, ${spec.char_product} Created`,
              reason: "",
              addToRevisionSummary: true,
              type: "create",
              section: "control_plan",
            };
            return;
          }
          const prevSpec = prevProcess.specs.find(
            (item) => item._id === spec._id
          );
          if (!prevSpec) return;
          specParams.map((specParam) => {
            if (
              spec &&
              prevSpec &&
              spec[specParam.param] !== prevSpec[specParam.param]
            ) {
              let key = process._id + "_" + spec._id + "_" + specParam.param;
              if (revisionData[key]) {
                revisionData[key].value = `${process.process_name}, ${
                  spec.char_product
                } ${spec.char_process}, ${specParam.placeholder} Changed 𝗙𝗥𝗢𝗠 ${
                  prevSpec[specParam.param] ? prevSpec[specParam.param] : '" "'
                } 𝗧𝗢 ${spec[specParam.param]}`;
              } else {
                revisionData[key] = {
                  index1: process._id,
                  index2: spec._id,
                  field: specParam.param,
                  value: `${process.process_name}, ${spec.char_product}, ${
                    specParam.placeholder
                  } Changed 𝗙𝗥𝗢𝗠 ${
                    prevSpec[specParam.param]
                      ? prevSpec[specParam.param]
                      : '" "'
                  } 𝗧𝗢 ${spec[specParam.param]}`,
                  reason: "",
                  addToRevisionSummary: true,
                  type: "change",
                  section: "control_plan",
                };
              }
            } else {
              let key = process._id + "_" + spec._id + "_" + specParam.param;
              if (revisionData[key]) {
                delete revisionData[key];
              }
            }
          });
        });
        prevProcess.specs.map((prevSpec, index2) => {
          const spec = process.specs.find((item) => item._id === prevSpec._id);
          if (!spec) {
            revisionData[index1 + "+" + index2 + "_deleted"] = {
              index1: index1,
              index2: index2,
              value: `${prevProcess.process_name}, ${prevSpec.char_product} Deleted`,
              reason: "",
              addToRevisionSummary: true,
              type: "delete",
              section: "control_plan",
            };
          }
        });
      }
      if (documentType === "fmea") {
        process.fmeas.map((fmea, index2) => {
          if (!fmea._id) {
            revisionData[index1 + "_" + index2 + "_added"] = {
              index1: index1,
              index2: index2,
              value: `${process.process_name}, ${fmea.potential_mode} Created`,
              reason: "",
              addToRevisionSummary: true,
              type: "create",
              section: "fmea",
            };
            return;
          }
          const prevFailure = prevProcess.fmeas.find(
            (item) => item._id === fmea._id
          );
          if (!prevFailure) return;
          fmeaParams.map((failureParam) => {
            if (
              fmea &&
              prevFailure &&
              checkArrayStrCondition(
                fmea[failureParam.param],
                prevFailure[failureParam.param]
              )
            ) {
              let key = process._id + "_" + fmea._id + "_" + failureParam.param;
              if (revisionData[key]) {
                revisionData[key].value = `${process.process_name}, ${
                  fmea.potential_mode
                }, ${failureParam.placeholder} Changed 𝗙𝗥𝗢𝗠 ${
                  prevFailure[failureParam.param]
                    ? prevFailure[failureParam.param]
                    : '" "'
                } 𝗧𝗢 ${fmea[failureParam.param]}`;
              } else {
                revisionData[key] = {
                  index1: process._id,
                  index2: fmea._id,
                  field: failureParam.param,
                  value: `${process.process_name}, ${fmea.potential_mode}, ${
                    failureParam.placeholder
                  } Changed 𝗙𝗥𝗢𝗠 ${
                    prevFailure[failureParam.param]
                      ? prevFailure[failureParam.param]
                      : '" "'
                  } 𝗧𝗢 ${fmea[failureParam.param]}`,
                  reason: "",
                  addToRevisionSummary: true,
                  type: "change",
                  section: "fmea",
                };
              }
            } else {
              let key = process._id + "_" + fmea._id + "_" + failureParam.param;
              if (revisionData[key]) {
                delete revisionData[key];
              }
            }
          });
        });
        prevProcess.fmeas.map((prevFailure, index2) => {
          const fmeas = process.fmeas.find(
            (item) => item._id === prevFailure._id
          );
          if (!fmeas) {
            revisionData[index1 + "+" + index2 + "_deleted"] = {
              index1: index1,
              index2: index2,
              value: `${prevProcess.process_name}, ${prevFailure.potential_mode} Deleted`,
              reason: "",
              addToRevisionSummary: true,
              type: "delete",
              section: "fmea",
            };
          }
        });
      }
    });

    partDataForRevision.processes.map((prevProcess, index1) => {
      const process = partData.processes.find(
        (item) => item._id === prevProcess._id
      );
      if (!process) {
        revisionData[index1 + "_deleted"] = {
          index1: index1,
          value: `Process ${prevProcess.process_name} Deleted`,
          reason: "",
          addToRevisionSummary: true,
          type: "delete",
          section: "process",
        };
      }
    });
    setRevisionData(revisionData);
  }, [partData]);

  function SetupUI(revisionData, section, setData) {
    const data = Object.keys(revisionData).map((key, index) => {
      if (revisionData[key].section !== section) return;
      return (
        <>
          <TableBody>
            <TableCell>{index + 1}</TableCell>
            <TableCell>
              <TextField
                readOnly
                multiline
                style={{ width: "300px" }}
                value={revisionData[key]?.value}
                InputProps={{ style: { fontSize: 14 } }}
              />
            </TableCell>
            <TableCell>
              <TextField
                multiline
                value={revisionData[key]?.reason}
                onChange={(e) =>
                  setRevisionData({
                    ...revisionData,
                    [key]: { ...revisionData[key], reason: e.target.value },
                  })
                }
                InputProps={{ style: { fontSize: 14 } }}
              />
            </TableCell>
            <TableCell align="center">
              <Tooltip title="Add to revision summary" placement="left" arrow>
                <Checkbox
                  name="addToRevisionSummary"
                  defaultChecked={revisionData[key]?.addToRevisionSummary}
                  onChange={(e) =>
                    (revisionData[key].addToRevisionSummary = e.target.checked)
                  }
                  style={{ color: "#064a29" }}
                />
              </Tooltip>
            </TableCell>
            <TableCell align="center">
              <Tooltip title="Revert Changes" placement="left" arrow>
                <Button
                  variant="primary"
                  size="large"
                  style={{ color: "#c80202" }}
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    if (revisionData[key].type === "change") {
                      if (revisionData[key].section === "control_plan") {
                        partData.processes
                          .find((item) => item._id === revisionData[key].index1)
                          .specs.find(
                            (item) => item._id === revisionData[key].index2
                          )[revisionData[key].field] =
                          partDataForRevision.processes
                            .find(
                              (item) => item._id === revisionData[key].index1
                            )
                            .specs.find(
                              (item) => item._id === revisionData[key].index2
                            )[revisionData[key].field];
                      }
                      if (revisionData[key].section === "fmea") {
                        partData.processes
                          .find((item) => item._id === revisionData[key].index1)
                          .fmeas.find(
                            (item) => item._id === revisionData[key].index2
                          )[revisionData[key].field] =
                          partDataForRevision.processes
                            .find(
                              (item) => item._id === revisionData[key].index1
                            )
                            .fmeas.find(
                              (item) => item._id === revisionData[key].index2
                            )[revisionData[key].field];
                      }
                    } else if (revisionData[key].type === "create") {
                      if (!revisionData[key].index2) {
                        partData.processes.splice(revisionData[key].index1, 1);
                      } else {
                        if (revisionData[key].section === "control_plan") {
                          partData.processes[
                            revisionData[key].index1
                          ].specs.splice(revisionData[key].index2, 1);
                        }
                        if (revisionData[key].section === "fmea") {
                          partData.processes[
                            revisionData[key].index1
                          ].specs.splice(revisionData[key].index2, 1);
                        }
                      }
                    } else if (revisionData[key].type === "delete") {
                      if (!revisionData[key].index2) {
                        partData.processes.splice(
                          revisionData[key].index1,
                          0,
                          partDataForRevision.processes[
                            revisionData[key].index1
                          ]
                        );
                      } else {
                        if (revisionData[key].section === "control_plan") {
                          partData.processes[
                            revisionData[key].index1
                          ].specs.splice(
                            revisionData[key].index2,
                            0,
                            partDataForRevision.processes[
                              revisionData[key].index1
                            ].specs[revisionData[key].index2]
                          );
                        }
                        if (revisionData[key].section === "fmea") {
                          partData.processes[
                            revisionData[key].index1
                          ].fmeas.splice(
                            revisionData[key].index2,
                            0,
                            partDataForRevision.processes[
                              revisionData[key].index1
                            ].specs[revisionData[key].index2]
                          );
                        }
                      }
                    }
                    delete revisionData[key];
                    setRevisionData(revisionData);
                    setRender((prev) => !prev);
                  }}
                ></Button>
              </Tooltip>
            </TableCell>
          </TableBody>
        </>
      );
    });
    setData(data);
  }

  useEffect(() => {
    SetupUI(revisionData, "process", setProcessData);
  }, [revisionData, render]);
  useEffect(() => {
    SetupUI(revisionData, "control_plan", setControlData);
  }, [revisionData, render]);
  useEffect(() => {
    SetupUI(revisionData, "pfd", setPfdData);
  }, [revisionData, render]);
  useEffect(() => {
    SetupUI(revisionData, "fmea", setFmeaData);
  }, [revisionData, render]);

  const typeOfSections = [
    { title: "PROCESS CHANGES", data: processData },
    { title: "PARAMETERS CHANGES", data: pfdData },
    { title: "PARAMETERS CHANGES", data: controlData },
    { title: "PARAMETERS CHANGES", data: fmeaData },
  ];

  return (
    <Modal title={ecnTitle} openPopup={openPopup} closeModal={closeModal}>
      {typeOfSections.map((item) => {
        if (item.data.every((value) => value === undefined)) return;
        return (
          <>
            <div
              style={{
                fontWeight: "600",
                padding: "5px",
                margin: "auto",
                position: "relative",
                justifyContent: "center",
                textAlign: "center",
                textTransform: "uppercase",
                marginTop: "1rem",
              }}
            >
              {item.title}
            </div>
            <TableContainer
              component={Paper}
              style={{
                width: "97%",
                margin: "0rem auto",
                marginTop: "0.6rem",
              }}
            >
              <Table aria-label="collapsible table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="center">CHANGES</TableCell>
                    <TableCell align="center">INPUT REASON</TableCell>
                    <TableCell align="center">
                      ADD TO REVISION SUMMARY
                    </TableCell>
                    <TableCell align="center">REVERT</TableCell>
                  </TableRow>
                </TableHead>
                {item.data}
              </Table>
            </TableContainer>
          </>
        );
      })}

      <div className="btn_div_flex" style={{ right: "10rem", top: "-0.4rem" }}>
        <Button
          variant="contained"
          size="large"
          color="primary"
          style={{ color: "#fafafa", backgroundColor: "#064a29" }}
          startIcon={<SaveIcon />}
          onClick={() => submit(revisionData)}
        >
          SAVE
        </Button>
      </div>
    </Modal>
  );
};

export default ECNModal;
