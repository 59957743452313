import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Button,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker } from "@material-ui/pickers";

import { getDailyProductionYearly } from "./util";
import { Edit } from "@material-ui/icons";

const useStyles = makeStyles({
    table: {
        width: "%",
        marginTop: "1.5rem",
        backgroundColor: "white",
    },
});

const AbnormalityMonitoringList = () => {
    const classes = useStyles();
    const [monitoringList, setMoniteringList] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        let data = getDailyProductionYearly(selectedDate);
        setMoniteringList(data);
    }, [selectedDate]);
    return (
        <section style={{ paddingBottom: "3rem" }}>
            <div>
                <div
                    className="divflex"
                    style={{
                        fontSize: "24px",
                    }}
                >
                    ABNORMALITY MONITORING SHEET
                </div>

                <div className="subheading-container">
                    <p className="subheading-primary">
                        ACTIVE <span className="subheading-secondary">REPORTS</span>
                    </p>
                </div>
            </div>
            <div style={{ display: "flex", margin: "1rem" }}>
                <DatePicker
                    views={["year"]}
                    label="Year"
                    className="date-field"
                    inputVariant="filled"
                    value={selectedDate || null}
                    onChange={setSelectedDate}
                />
            </div>
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        {["#", "Month & Year", "Action"].map((item, index) => (
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                {item}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>{" "}
                <TableBody>
                    {monitoringList.map((item, index) => (
                        <React.Fragment key={item._id}>
                            <TableRow>
                                <TableCell align="center">{index + 1}</TableCell>
                                <TableCell align="center">{item.month_year}</TableCell>
                                <TableCell align="center">
                                    <Tooltip title="View Monitoring Sheet" arrow>
                                        <Link
                                            to={`/abnormalityMonitoringTable?annual_date=${item.month_year}&isView=true`}
                                        >
                                            <IconButton size="small">
                                                <VisibilityIcon
                                                    style={{
                                                        fontSize: "1.4rem",
                                                        color: "#003566",
                                                    }}
                                                />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>

                                    <Tooltip title="Edit Monitoring Sheet" arrow>
                                        <Link
                                            to={`/abnormalityMonitoringTable?annual_date=${item.month_year}&isView=false`}
                                        >
                                            <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
                                                <Edit
                                                    style={{
                                                        fontSize: "1.4rem",
                                                        color: "#003566",
                                                    }}
                                                />
                                            </IconButton>
                                        </Link>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </section>
    );
};

export default AbnormalityMonitoringList;
