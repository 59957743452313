import React, { useState } from "react";
import Styled from "styled-components";
import { images, textInputs } from "../../../../../constants/constants";
import { Wrapper } from "../../../../../components/Control/Flexbox";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { CenteredLogoImg } from "../../../../../golbalStyles/global.styled";
import MaintenanceTableFooter from "./MaintenanceTableFooter";
import formatDate from "../../../../../components/Control/formatDate";
import { formatDateForTime } from "../../../../../components/Control/formatDateForTime";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;
  th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
        
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
        b{
          font-size: 13px;
		      font-family:"Roboto",sans-serif;
        }
        strong{
          font-size: 13px;
		      font-family:"Roboto",sans-serif;
        }
    }
    tbody th {
    text-align: center;
    }

    .input {
      outline: none;
      width: 100px;
      border: none;
      height: 50px;
      text-align:center;
  }
    
`;

const MaintenanceTableForm = ({
  checkListMachineData,
  changeLanguage,
  handleChecklist,
  checklistType,
  isView,
}) => {
  const [render, setRender] = useState(false);

  return (
    <div style={{ paddingBottom: "4rem" }}>
      <Table>
        <thead>
          <tr>
            <th
              colSpan="8"
              rowSpan={2}
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
              }}
            >
              <CenteredLogoImg>
                <img
                  src={images.arcLogo}
                  alt={textInputs.companyName}
                  height="46"
                  width="120"
                />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
            <th colSpan={2}>Doc.No.:-ARC/F/MAINT/02 D</th>
          </tr>
          <tr>
            <th colSpan={2}>Rev.No./Date:-04/02-09-2022</th>
          </tr>
          <tr>
            <th colSpan={3}>
              Machine Name :- {checkListMachineData.machine_name}
            </th>
            <th colSpan={3}>Machine No:-{checkListMachineData.machine_no}</th>
            <th colSpan={1}>Date:-</th>
            <th colSpan={1}>
              {isView ? (
                formatDate(checkListMachineData.last_maintenance_date)
              ) : (
                <Wrapper style={{ gap: "5px" }}>
                  <DatePicker
                    name="last_maintenance_date"
                    format="dd/MM/yyyy"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    style={{
                      backgroundColor: "#f8f9fa",
                    }}
                    value={checkListMachineData.last_maintenance_date || null}
                    onChange={(date) => {
                      checkListMachineData.last_maintenance_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                </Wrapper>
              )}
            </th>
            <th colSpan={1}>Time:-</th>
            <th colSpan={1}>
              {isView ? (
                formatDateForTime(checkListMachineData.maintenance_time)
              ) : (
                <TimePicker
                  name="maintenance_time"
                  style={{
                    backgroundColor: "#f8f9fa",
                    padding: "8px 5px",
                  }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={checkListMachineData.maintenance_time || null}
                  onChange={(date) => {
                    checkListMachineData.maintenance_time = date;

                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th rowSpan={2}>S NO</th>
            <th rowSpan={2}>CHECK POINT AREA AND CHECK ITEMS</th>
            <th rowSpan={2}>CHECKING METHOD & CORRECTION METHOD</th>
            <th rowSpan={2}>JUDGEMENT CRITERIA</th>
            <th colSpan={3}> OBSERVATIONS</th>

            <th rowSpan={2}>ACTION TAKEN</th>
            <th rowSpan={2}>REMARKS (SPARES CONSUMED USED)</th>
            <th rowSpan={2}>COST OF CONSUMABLE</th>
          </tr>
          <tr>
            <th>OK</th>
            <th>NG</th>
            <th>DETAILS OF ABNORMALITY (IF NOT OK)</th>
          </tr>
          {checkListMachineData?.checkListMachineData[checklistType]?.map(
            (item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{index + 1}</td>
                  <td>{item[`check_point_area_${changeLanguage}`]}</td>
                  <td>{item.checking_method}</td>
                  <td>{item[`judgement_criteria_${changeLanguage}`]}</td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",

                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView ? (
                      item.ok
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="ok"
                        defaultValue={item.ok}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView ? (
                      item.ng
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="ng"
                        defaultValue={item.ng}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )}
                  </td>

                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView ? (
                      item.details_of_abnormality
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="details_of_abnormality"
                        defaultValue={item.details_of_abnormality}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView ? (
                      item.action_taken
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="action_taken"
                        defaultValue={item.action_taken}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView ? (
                      item.remarks
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="remarks"
                        defaultValue={item.remarks}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )}
                  </td>
                  <td
                    align="center"
                    style={{
                      border: "1px solid black",
                      backgroundColor: isView ? "" : "#f9e0e0",
                    }}
                  >
                    {isView ? (
                      item.consumable
                    ) : (
                      <input
                        autoComplete="off"
                        className="input"
                        name="consumable"
                        defaultValue={item.consumable}
                        onChange={(e) => handleChecklist(e, index)}
                      />
                    )}
                  </td>
                </tr>
              </React.Fragment>
            )
          )}
        </tbody>
        <MaintenanceTableFooter {...{ checklistType }} />
      </Table>
    </div>
  );
};

export default MaintenanceTableForm;
