import React, { useState, useEffect } from "react";
import { Wrapper } from "../../components/Control/Flexbox";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import PCDSpecs from "./PCDSpecs";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { useAuth } from "../../context/GlobalContext";
import DeleteIcon from "@material-ui/icons/Delete";
import PdfViewer from "../../components/PdfViewer/PdfViewer";

import { CloseButton } from "../../GlobalUtils/ButtonsGlobal";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-input": {
      padding: theme.spacing(2),
    },
  },
}));

const PCD = ({
  filterProcess,
  processName,
  partId,
  preview,
  setPreview,
  render,
  setRender,
  image,
  setImage,
}) => {
  const { state } = useAuth();
  const [pcdData, setPcdData] = useState("");
  const classes = useStyles();

  function handleImagePdf(e) {
    setImage({
      file: e.target.files[0],
    });
  }

  useEffect(() => {
    if (!image.file) {
      setPreview(undefined);
      return;
    }

    const file = new Blob([image], { type: "application/pdf" });
    let fileUrl = URL.createObjectURL(file);

    setPreview(URL.createObjectURL(image.file));
    return () => {
      URL.revokeObjectURL(image.file);
    };
  }, [image]);

  console.log(image);
  const removeImage = () => {
    axios
      .delete(`/api/remove/pcd/${pcdData._id}`)
      .then((res) => {
        alert("Image/ PDF remove successfully");
        setPreview(null);
        setRender((prev) => !prev);
      })
      .catch((error) => {
        console.log(error.res);
      });
  };

  const findExt = (url) => {
    if (!url) return;
    return url.slice(((url.lastIndexOf(".") - 1) >>> 0) + 2);
  };
  let typeofEXT = findExt(pcdData?.pcd_imageOrPdf);
  if (!typeofEXT && image.file) {
    typeofEXT = findExt(image.file.name);
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("part_id", partId);
    formData.append("process_name", processName);
    formData.append("pcd_imageOrPdf", image.file);

    if (pcdData._id) {
      axios
        .put(`/api/update/pcd/${pcdData._id}`, formData)
        .then((res) => {
          alert("The file updated successfully");
          setPreview(null);
        })
        .catch((error) => {
          console.log(error.res);
        });
    } else {
      axios
        .post("/api/add/pcd", formData)
        .then((res) => {
          alert("The file save successfully");
          setPreview("");
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  };

  useEffect(() => {
    axios
      .get(`/api/get/pcd/${processName}/${partId}`)
      .then((res) => {
        if (res.data) {
          setPcdData(res.data);
        } else {
          setPcdData(null);
        }
      })
      .catch((err) => console.error(err));
    // eslint-disable-next-line
  }, [processName, preview, render]);
  return (
    <form encType="multipart/form-data" onSubmit={onSubmit}>
      <Wrapper justify="center" column style={{ padding: "3rem 0rem" }}>
        {pcdData ? (
          typeofEXT === "pdf" ? (
            <PdfViewer url={pcdData.pcd_imageOrPdf} />
          ) : (
            <div className="img">
              <img
                src={preview ? preview : pcdData.pcd_imageOrPdf}
                alt=""
                height="100%"
                width="100%"
              />
            </div>
          )
        ) : (
          <div className="img">
            <img
              src={preview ? preview : ""}
              alt=""
              height="100%"
              width="100%"
            />
          </div>
        )}
        {state.user.userType == 2 ? (
          <div className={classes.textField}>
            <input
              id="contained-button-file"
              type="file"
              name="pcd_imageOrPdf"
              accept="image/png,image/jpeg,image/jpg/,application/pdf"
              onChange={handleImagePdf}
              hidden
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                component="span"
                color="secondary"
                style={{ marginTop: "1rem" }}
              >
                <PhotoCameraIcon style={{ marginRight: "1rem" }} />
                Upload Picture / Pdf
              </Button>
            </label>
            {pcdData._id && (
              <Button
                variant="outlined"
                type="button"
                color="primary"
                style={{
                  marginTop: "1rem",
                  marginLeft: "0.6rem",
                }}
                onClick={removeImage}
                startIcon={<DeleteIcon />}
              >
                REMOVE
              </Button>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </Wrapper>
      <PCDSpecs {...{ filterProcess }} />
      {state.user.userType == 2 ? (
        <Wrapper
          justify="center"
          style={{
            position: "absolute",
            top: "4.8rem",
            right: "0rem",
            gap: "1rem",
            width: "20rem",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            style={{
              display: "inline-flex",
              marginBottom: "5rem",
              backgroundColor: "#003566",
            }}
            startIcon={<SaveIcon />}
          >
            {pcdData._id ? "Update" : "Save"}
          </Button>
          {/* <CloseButton /> */}
        </Wrapper>
      ) : (
        <div></div>
      )}
    </form>
  );
};

export default PCD;
