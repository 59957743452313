import {
  Button,
  Table,
  TableBody,
  TableHead,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { CloudUpload, Delete, Visibility } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Wrapper } from "../../components/Control/Flexbox";
import { useQuery } from "../../components/Control/useQuery";

const ViewFile = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const [files, setFiles] = useState(null);
  const query = useQuery();
  const documentType = query.get("documentType");

  useEffect(() => {
    axios
      .get(`/api/viewUploadIrmRegister/${id}?documentType=${documentType}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id, render]);

  const handleDelete = (id, imageName, imgIndex) => {
    if (window.confirm("Are you sure you want to delete this file ?")) {
      axios
        .delete(
          `/api/deleteRmRegisterFile/${id}/${imgIndex}?documentType=${documentType}`,
        )
        .then((res) => {
          if (res.data) {
            alert(`${imageName} deleted successfully`);
            setRender((prev) => !prev);
          }
        });
    }
  };
  const uploadFiles = async (id) => {
    if (!files) return;

    const formData = new FormData();
    for (const key in files) {
      formData.append("files", files[key]);
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to upload these files?",
    );
    if (!isConfirmed) return;
    if (files) {
      await axios
        .put(
          `/api/uploadIRMResisterFile/${id}?documentType=${documentType}`,
          formData,
        )
        .then((res) => {
          if (res.data) {
            alert("uploaded successfully");
            setFiles("");
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      {data && (
        <Wrapper
          justify="space-between"
          style={{
            border: "2px solid #ced4da",
            margin: "1rem",
            padding: "1rem",
          }}
        >
          <input
            id="contained-button-file"
            type="file"
            multiple
            accept="image/png,image/jpeg,image/jpg/,application/pdf"
            onChange={(e) => setFiles(e.target.files)}
            hidden
          />
          <label
            htmlFor="contained-button-file"
            style={{
              marginBottom: "0",
            }}
          >
            <Button component="span">
              <CloudUpload style={{ marginRight: "1rem" }} />
              Upload Picture / Pdf
            </Button>
          </label>

          <Wrapper
            justify="center"
            style={{
              borderLeft: "2px solid #ced4da",
            }}
          >
            <div
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              {files ? `${files[0]?.name}...` : ""}
            </div>
          </Wrapper>
          <Wrapper
            style={{ borderLeft: "2px solid #ced4da" }}
            justify="space-between"
          >
            {files && (
              <>
                <Button
                  component="span"
                  type="button"
                  style={{
                    marginLeft: "0.6rem",
                  }}
                  onClick={() => setFiles(null)}
                  startIcon={<Delete />}
                >
                  REMOVE
                </Button>
                <Button
                  component="span"
                  type="button"
                  style={{
                    marginLeft: "0.6rem",
                  }}
                  onClick={() => uploadFiles(id)}
                  startIcon={<CloudUpload />}
                >
                  Upload
                </Button>
              </>
            )}
          </Wrapper>
        </Wrapper>
      )}

      {data && (
        <div style={{ paddingBottom: "4rem", padding: "3rem 2rem" }}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  File Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="View" arrow>
                        <IconButton
                          size="small"
                          onClick={() => {
                            window.open(item?.url, "_blank");
                          }}
                        >
                          <Visibility
                            style={{
                              fontSize: "1.2rem",
                              color: "#1864ab",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          style={{ marginLeft: "0.5rem" }}
                          size="small"
                          onClick={() => {
                            handleDelete(id, item.name, index);
                          }}
                        >
                          <Delete
                            style={{
                              fontSize: "1.2rem",
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default ViewFile;
