import { React, useEffect, useRef, useState } from "react";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import axios from "axios";
import PublishIcon from "@material-ui/icons/Publish";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { DivStyled } from "./Container.styled.js";
import useConfirm from "../../components/Control/useConfirm";
// import { areaOfWork } from "./Constant.js";

const dropdownStyles = makeStyles({
    root: {
        backgroundColor: "#fff",
        height: "0",
    },
    select: {
        "&:hover": {
            backgroundColor: "#fff",
        },
        "&:focus": {
            backgroundColor: "#fff",
        },
    },
    underline: {
        borderBottom: "0px solid red !important",
        "&:hover": {
            borderBottom: "0px solid rgba(0,0,0,0)",
        },
    },
});

const induction_file_url = {
    "Ball Press":
        "https://mushin-labs.s3.ap-south-1.amazonaws.com/ARC-Machine-V2/EducationModule/BALL_PRESS.pdf",

    Cnc: "https://mushin-labs.s3.ap-south-1.amazonaws.com/ARC-Machine-V2/EducationModule/CNC.pdf",

    Cri: "https://mushin-labs.s3.ap-south-1.amazonaws.com/ARC-Machine-V2/EducationModule/CRI.pdf",

    Grindin:
        "https://mushin-labs.s3.ap-south-1.amazonaws.com/ARC-Machine-V2/EducationModule/GRINDING.pdf",

    Parting:
        "https://mushin-labs.s3.ap-south-1.amazonaws.com/ARC-Machine-V2/EducationModule/PARTING.pdf",

    sffe: "https://mushin-labs.s3.ap-south-1.amazonaws.com/ARC-Machine-V2/EducationModule/Staff.pdf",

    Vmc: "https://mushin-labs.s3.ap-south-1.amazonaws.com/ARC-Machine-V2/EducationModule/TRUB_AND_DRILLING.pdf",

    Vmc: "https://mushin-labs.s3.ap-south-1.amazonaws.com/ARC-Machine-V2/EducationModule/VMC.pdf",
};

const AddNewEmployeeModal = ({ setAddNewEmployeeModal, id, setEmployeeId }) => {
    const classes = dropdownStyles();
    const inputElement = useRef(null);
    const [render, setRender] = useState(false);
    const [DialogSubmit, confirmSubmit] = useConfirm(
        "DRAFT PART",
        `Are you sure you want to ${id ? "update" : "save"} this part?`,
    );
    //htmlFor area of work
    const [departmentData, setDepartmentData] = useState([]);
    const [autoCompleteValue, setAutoCompleteValue] = useState("");

    const [addEmployeeData, setAddNewEmployeeData] = useState({
        data_of_joining: new Date(),
    });

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus();
        }
    }, []);

    useEffect(() => {
        axios
            .get(`/api/getAllDepartments`)
            .then((res) => {
                setDepartmentData(res?.data?.result);
                // console.log(res?.data);
            })
            .catch((err) => console.log(err));
    }, []);

    useEffect(() => {
        if (id) {
            axios
                .get(`/api/getOneEmployee/data/${id}`)
                .then((res) => {
                    setAddNewEmployeeData(res.data);
                    setAutoCompleteValue(res?.data?.area_of_work);
                })
                .catch((err) => console.log(err));
        }
    }, [id]);

    const handelSumbit = async (e) => {
        if (!addEmployeeData.area_of_work)
            return alert("Please select the area of work");
        addEmployeeData.area_of_work_url =
            induction_file_url[addEmployeeData.area_of_work];
        const ans = await confirmSubmit();
        if (!ans) {
            return;
        }
        if (id) {
            await axios
                .put(`/api/updateEmployee/data/${id}`, addEmployeeData)
                .then((res) => {
                    setAddNewEmployeeModal(false);
                    setEmployeeId.current = null;
                })
                .catch((err) => console.log(err));
        } else {
            await axios
                .post("/api/addNewEmployee/data", addEmployeeData)
                .then((res) => {
                    setAddNewEmployeeModal(false);
                    setEmployeeId.current = null;
                })
                .catch((err) => console.log(err));
        }
    };

    const handleInput = (e) => {
        addEmployeeData[e.target.name] = e.target.value;
    };

    return (
        <section style={{ padding: "0.3rem 0rem 0.3rem 0rem" }}>
            <div
                className="btn_save"
                style={{
                    position: "absolute",
                    right: "150px",
                    top: "9px",
                }}
            >
                <Button
                    type="submit"
                    variant="outlined"
                    color="white"
                    size="large"
                    style={{ color: "#e9e9e9" }}
                    startIcon={<PublishIcon />}
                    onClick={handelSumbit}
                >
                    {id ? "UPDATE" : "SUBMIT"}
                </Button>
            </div>
            <DivStyled>
                <label htmlFor="employee_name">Name Of The Employee:</label>
                <input
                    type="text"
                    id="employee_name"
                    name="employee_name"
                    ref={inputElement}
                    defaultValue={addEmployeeData?.employee_name}
                    onBlur={handleInput}
                />
            </DivStyled>
            <DivStyled>
                <label htmlFor="designation">Designation:</label>
                <input
                    type="text"
                    id="designation"
                    name="designation"
                    defaultValue={addEmployeeData?.designation}
                    onBlur={handleInput}
                />
            </DivStyled>
            <DivStyled>
                <label htmlFor="qualification">Qualification:</label>
                <input
                    type="text"
                    id="qualification"
                    name="qualification"
                    defaultValue={addEmployeeData?.qualification}
                    onBlur={handleInput}
                />
            </DivStyled>
            <DivStyled>
                <label htmlFor="data_of_joining">Date Of Joining:</label>
                <DatePicker
                    name="data_of_joining"
                    format="dd/MM/yyyy"
                    InputProps={{
                        disableUnderline: true,
                    }}
                    style={{
                        borderLeft: "2px solid #ced4da",
                        paddingLeft: "0.5rem",
                    }}
                    value={addEmployeeData?.data_of_joining}
                    onChange={(date) => {
                        addEmployeeData.data_of_joining = date;
                        setRender((prev) => !prev);
                    }}
                />
            </DivStyled>
            <DivStyled>
                <label htmlFor="exp_years">Exp.years:</label>
                <input
                    type="text"
                    id="exp_years"
                    name="exp_years"
                    defaultValue={addEmployeeData?.exp_years}
                    onBlur={handleInput}
                />
            </DivStyled>
            <DivStyled>
                <label htmlFor="current_level">Current Level</label>
                <div
                    style={{
                        borderLeft: "2px solid #ced4da",
                        paddingLeft: "0.5rem",
                        width: "100%",
                        marginRight: addEmployeeData?.current_level ? "7.2rem" : "8.9rem",
                    }}
                >
                    <Select
                        defaultValue={addEmployeeData?.current_level || ""}
                        key={addEmployeeData?.current_level}
                        classes={{
                            root: classes.root,
                            select: classes.select,
                        }}
                        style={{ fontSize: "0.8rem", width: "100%" }}
                        disableUnderline
                        onChange={(e) => {
                            addEmployeeData.current_level = e.target.value;
                            setRender((prev) => !prev);
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="Level_1">Level 1</MenuItem>
                        <MenuItem value="Level_2">Level 2</MenuItem>
                        <MenuItem value="Level_3">Level 3</MenuItem>
                        <MenuItem value="Level_4">Level 4</MenuItem>
                    </Select>
                </div>
            </DivStyled>
            <DivStyled>
                <label htmlFor="area_of_work">Area Of Work</label>
                <Autocomplete
                    id="area_of_work"
                    options={departmentData}
                    getOptionLabel={((option) => option.department_name) || []}
                    value={autoCompleteValue}
                    style={{
                        borderLeft: "2px solid #ced4da",
                        paddingLeft: "0.5rem",
                        width: "100%",
                        marginRight: "6rem",
                    }}
                    onChange={(e, value) => {
                        addEmployeeData.area_of_work = value?._id;
                        setAutoCompleteValue(value);
                        setRender((prev) => !prev);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{ ...params.InputProps, disableUnderline: true }}
                        />
                    )}
                />
            </DivStyled>
            <DivStyled>
                <label htmlFor="emp_photo">Employee Photo</label>
                <input
                    type="text"
                    id="emp_photo"
                    name="emp_photo"
                // defaultValue={addEmployeeData?.emp_photo}
                // onBlur={handleInput}
                />
            </DivStyled>

            <DialogSubmit isSubmitContent={false} />
        </section>
    );
};

export default AddNewEmployeeModal;
