import React, { useState, useEffect } from "react";
import axios from "axios";
import MSAForm from "./MSAForm";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "../../components/Control/useQuery";

const EditMSA = () => {
  const { id } = useParams();
  const history = useHistory();
  const query = useQuery();
  const instrumentName = query.get("instrumentName");
  const idNo = query.get("idNo");
  const lc = query.get("lc");
  const [MSAData, setMSAData] = useState({
    rev_no: null,
    part_no: null,
    part_name: "",
    characteristic: "",
    spec: "",
    gage_no: "",
    gage_name: "",
    gage_type: "",
    data: [...Array(3)].map((item) => ({
      name: "",
      values: Array.from(Array(3), () => Array.from(Array(10), () => "")),
    })),
    least_count: "",
    date_performance: "",
    isSubmitted: null,
  });

  useEffect(() => {
    axios.get(`/api/oneMsa/data/${id}`).then((response) => {
      setMSAData(response.data);
    });
  }, []);

  const submit = () => {
    axios.post(`/api/update/msa/${id}`, MSAData).then((res) => {
      history.push(
        `/view/msaTable/${MSAData.instrument_id?._id}?instrumentName=${instrumentName}&idNo=${idNo}&lc=${lc}`
      );
    });
  };

  return (
    <>
      <MSAForm {...{ MSAData, submit, setMSAData }} />
    </>
  );
};

export default EditMSA;
