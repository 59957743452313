import React, { useEffect, useState } from "react";
import { Button, Paper, Tab, Tabs } from "@material-ui/core";
import { useParams, useHistory, link } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

import QuestionPaperHtmlTable from "./QuestionPaperHtmlTable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const QuickViewQuestionPaper = () => {
  const classes = useStyles();
  const { id, levelType } = useParams();
  const [questionPaperData, setQuestionPaperData] = useState();
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getOneQuestionPaper/${id}`)
        .then((res) => {
          setTabValue(+levelType?.split("_")[1] - 1 || 0);
          setQuestionPaperData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  return (
    <div className={classes.root} style={{ position: "relative" }}>
      <AppBar
        position="static"
        style={{ backgroundColor: "#161a1d", borderTop: "1px solid #494949" }}
      >
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          {questionPaperData &&
            questionPaperData.levels.map((level) => (
              <Tab
                label={level.levels_type.split("_").join(" ")}
                disabled={
                  levelType && (levelType === level.levels_type ? false : true)
                }
              />
            ))}
        </Tabs>
      </AppBar>
      <QuestionPaperHtmlTable
        questionPaperData={questionPaperData?.levels[tabValue]}
      />
    </div>
  );
};

export default QuickViewQuestionPaper;
