import React, { useEffect, useState, useRef } from "react";
import "../../style/product.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { DatePicker } from "@material-ui/pickers";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { DivStyled } from "../EducationTraining/Container.styled";
import { toSuperScript } from "../../components/Control/toSuperScript";

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const AddEditInstrument = ({ setIsNewInstrumentModal, id }) => {
  const classes = dropdownStyles();
  const [instrumentData, setInstrumentData] = useState({});
  const inputElement = useRef(null);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "instrument",
    `Are you sure you want to ${id ? "update" : "save"} this part?`
  );

  function handleInstrumentData(e) {
    setInstrumentData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/get_instrumentById/${id}`)
        .then((res) => setInstrumentData(res.data))
        .catch((err) => console.log(err));
    }
    // else {
    //   setInstrumentData({
    //     date_orig: new Date(),
    //   });
    // }
  }, [id]);

  const handleSumbit = async () => {
    // e.preventDefault();
    if (instrumentData?.instrument_type === undefined) {
      alert("please select the instrument type");
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/update_instrument/${id}`, instrumentData)
        .then((res) => {
          setIsNewInstrumentModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/create/instrument", instrumentData)
        .then((res) => {
          setIsNewInstrumentModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <section className="root-admin">
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handleSumbit}
        >
          {id ? "UPDATE" : "SUBMIT"}
        </Button>
      </div>
      <DivStyled>
        <label htmlFor="instrument_type">INSTRUMENT TYPE</label>
        <FormControl
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            // width: "100%",
            marginRight: "6rem",
          }}
          fullWidth={true}
          variant="filled"
          classes={{
            root: classes.root,
          }}
        >
          <Select
            defaultValue={instrumentData?.instrument_type || ""}
            name="instrument_type"
            id="instrument_type"
            key={instrumentData?.instrument_type}
            classes={{
              root: classes.root,
              select: classes.select,
            }}
            style={{ fontSize: "0.8rem" }}
            disableUnderline
            onChange={handleInstrumentData}
          >
            {/* <MenuItem value=" ">
              <em>None</em>
            </MenuItem> */}
            {[
              "HEIGHT GAUGE",
              "MAGNETIC V-BLOCK",
              "SURFACE PLATE",
              "COMPARATOR STAND",
              "VERNIER CALIPER",
              "LEVER DIAL",
              "PLUNGER DIAL",
              "MICROMETER",
              "COORDINATE MEASURING MACHINE",
              "HARDNESS TESTER",
              "COATING THICKNESS TESTER",
              "PROFILE PROJECTOR",
              "SURFACE ROUGHNESS TESTER",
              "EYE PIECE",
              "BENCH CENTER",
              "WEIGHING MACHINE",
              "TECHOMETER",
              "SLIP GAUGE BLOCK",
              "PLUG GAUGE",
              "WEAR CHECK RING",
              "WEAR CHECK PLUG",
              "PLAIN RING GAUGE",
              "THREAD RING GAUGE",
              "THREAD PLUG GAUGE",
              "SNAP GAUGE",
              "SPECIAL GUAGE",
              "PRESSURE GAUGE",
              "CONTOUR",
              "ROUNDNESS MACHINE TESTER",
              "BORE GAUGE",
              "MICROSCOPIC TESTER",
              "CONTOUR",
              "HEX PLUG GAUGE",
              "AIR PLUG  GAUGE / AIR GAUGE UNIT",
              "THICKNESS PLATING TESTER",
              "RADIUS GAUGE",
              "LUX METER",
              "MASTER RING GAUGE",
              "MASTER PLUG GAUGE",
              "AIR PLUG GAUGE",
            ].map((type, index) => (
              <MenuItem value={type} key={index + 200.0202}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DivStyled>
      <DivStyled>
        <label htmlFor="description">DESCRIPTION:</label>
        <input
          type="text"
          id="description"
          name="description"
          ref={inputElement}
          // required
          defaultValue={instrumentData?.description}
          onBlur={handleInstrumentData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="id_no">ID NO.:</label>
        <input
          type="text"
          id="id_no"
          name="id_no"
          defaultValue={instrumentData?.id_no}
          onBlur={handleInstrumentData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="range">RANGE :</label>
        <input
          type="text"
          id="range"
          name="range"
          defaultValue={instrumentData?.range}
          onBlur={handleInstrumentData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="units">UNITS :</label>
        <input
          type="text"
          id="units"
          name="units"
          value={instrumentData?.units}
          onChange={(e) => {
            let convertedValue = toSuperScript(e.target.value);
            instrumentData["units"] = convertedValue;
            setRender((prev) => !prev);
          }}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="make">MAKE :</label>
        <input
          type="text"
          id="make"
          name="make"
          defaultValue={instrumentData?.make}
          onBlur={handleInstrumentData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="l_c">L.C. :</label>
        <input
          type="text"
          id="l_c"
          name="l_c"
          defaultValue={instrumentData?.l_c}
          onBlur={handleInstrumentData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="frequency_in_year">FREQUENCY (In Year):</label>
        <input
          type="number"
          id="frequency_in_year"
          name="frequency_in_year"
          defaultValue={instrumentData?.frequency_in_year}
          onBlur={handleInstrumentData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="agency">AGENCY: </label>
        <FormControl
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: "6rem",
          }}
          fullWidth={true}
          variant="filled"
          classes={{
            root: classes.root,
          }}
        >
          <Select
            defaultValue={instrumentData?.agency || ""}
            name="agency"
            key={instrumentData?.agency}
            id="agency"
            classes={{
              root: classes.root,
              select: classes.select,
            }}
            style={{ fontSize: "0.8rem" }}
            disableUnderline
            onChange={handleInstrumentData}
          >
            <MenuItem value=" ">
              <em>NONE</em>
            </MenuItem>
            <MenuItem value="EXTERNAL">EXTERNAL</MenuItem>
            <MenuItem value="INTERNAL">INTERNAL</MenuItem>
          </Select>
        </FormControl>
      </DivStyled>
      <DivStyled>
        <label htmlFor="calibration_done_date">CALIBRATION DONE DATE</label>
        <DatePicker
          id="calibration_done_date"
          name="calibration_done_date"
          format="dd/MM/yyyy"
          InputProps={{
            disableUnderline: true,
          }}
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
          }}
          value={instrumentData?.calibration_done_date}
          onChange={(date) => {
            instrumentData.calibration_done_date = date;
            setRender((prev) => !prev);
          }}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="acceptence_criteria">ACCEPTENCE CRITERIA</label>
        <input
          type="text"
          id="acceptence_criteria"
          name="acceptence_criteria"
          defaultValue={instrumentData?.acceptence_criteria}
          onBlur={handleInstrumentData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="judgement">JUDGEMENT</label>
        <FormControl
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: "6rem",
          }}
          fullWidth={true}
          variant="filled"
          classes={{
            root: classes.root,
          }}
        >
          <Select
            id="judgement"
            defaultValue={instrumentData?.judgement || ""}
            name="judgement"
            key={instrumentData?.judgement}
            classes={{
              root: classes.root,
              select: classes.select,
            }}
            style={{ fontSize: "0.8rem" }}
            disableUnderline
            onChange={handleInstrumentData}
          >
            <MenuItem value="OK">OK</MenuItem>
            <MenuItem value="NOT OK">NOT OK</MenuItem>
          </Select>
        </FormControl>
      </DivStyled>
      <DivStyled>
        <label htmlFor="location">LOCATION</label>
        <FormControl
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: "6rem",
          }}
          fullWidth={true}
          variant="filled"
          classes={{
            root: classes.root,
          }}
        >
          <Select
            id="location"
            defaultValue={instrumentData?.location || ""}
            name="location"
            key={instrumentData?.location}
            classes={{
              root: classes.root,
              select: classes.select,
            }}
            style={{ fontSize: "0.8rem" }}
            disableUnderline
            onChange={handleInstrumentData}
          >
            {[
              "CUTTING SHOP",
              "CNC SHOP",
              "CNC SHOP 2",
              "GRINDING SHOP",
              "HARDENING SHOP",
              "PLATING PLANT",
              "QUALITY",
              "RM AREA",
              "STORE",
              "STD",
              "STD ROOM",
              "VMC",
              "VMC SHOP",
              "OTHER",
            ].map((type, index) => (
              <MenuItem value={type} key={index + 420.42}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DivStyled>
      <DialogSubmit isSubmitContent={false} />
    </section>
  );
};

export default AddEditInstrument;
