//change to superscript cm²
const DIGITS = {
  0: "⁰",
  1: "¹",
  2: "²",
  3: "³",
  4: "⁴",
  5: "⁵",
  6: "⁶",
  7: "⁷",
  8: "⁸",
  9: "⁹",
};
function toSuperScript(string) {
  if (isNaN(Number(string))) {
    let stringArr = string.split("");
    let lastValue = stringArr[stringArr.length - 1];
    if (lastValue in DIGITS) {
      stringArr[stringArr.length - 1] = DIGITS[lastValue];
    }
    return stringArr.join("");
  }
  return string;
}

export { toSuperScript };
