import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useParams, Link, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SyncIcon from "@material-ui/icons/Sync";

import Loader from "../../../components/Control/Loader";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import useConfirm from "../../../components/Control/useConfirm";
import { TableContainerStyled, Table } from "../Container.styled";
import DragableButton from "../../../components/Control/Dragable";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const CreateEditSkillEvaluationPlan = () => {
  const classes = dropdownStyles();
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [renderSync, setRenderSync] = useState(false);
  const [render, setRender] = useState(false);
  const [evalutaionPlanData, setEvaluationPlanData] = useState({
    processes: [],
  });
  const [getformatedYear, setFromatedYear] = useState({
    currentYear: "",
    nextYear: "",
  });

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "CLOSE",
    "Are you sure you want to save and modify this document?"
  );

  const [DialogAddTask, confirmDialogAddTask] = useConfirm(
    "Add Task",
    "Are you sure you want to add a Task?"
  );
  const [DialogRemoveTask, confirmDialogRemoveTask] = useConfirm(
    "Remove Task",
    "Are you sure you want to remove a Task?"
  );
  const [DialogRemoveRow, confirmDialogRemoveRow] = useConfirm(
    "Remove Row",
    "Are you sure you want to remove this row"
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getSkillEvaluationPlan/${id}`)
        .then((res) => {
          setEvaluationPlanData(res.data);
          const year = getYearRange(res.data.created_year);
          setFromatedYear((prev) => ({
            ...prev,
            currentYear: year.split("-")[0].trim().slice(2),
            nextYear: year.split("-")[1].trim().slice(2),
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [id, renderSync]);

  const handleDoubleClick = async (event, month, idx) => {
    if (event.detail === 2) {
      if (evalutaionPlanData.processes[idx].training_months[month].isChecked) {
        let ans = await confirmDialogRemoveTask();
        if (ans) {
          evalutaionPlanData.processes[idx].training_months[
            month
          ].isChecked = false;
          setRender((prev) => !prev);
        }
      } else {
        let ans = await confirmDialogAddTask();
        if (ans) {
          evalutaionPlanData.processes[idx].training_months[
            month
          ].isChecked = true;
          setRender((prev) => !prev);
        }
      }
    }
  };

  function MouseOver(event) {
    event.target.style.transform = "scale(1.2)";
    event.target.style.transition = "200ms";
    event.target.style.border = "1px solid #ced4da";
    event.target.style.borderRadius = "5px";
    event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.2)";
  }
  function MouseOut(event) {
    event.target.style.transform = "scale(1)";
    event.target.style.border = "none";
    event.target.style.boxShadow = "none";
    event.target.style.transition = "200ms";
  }
  function MouseDown(event) {
    event.target.style.transform = "scale(1.1)";
    event.target.style.transition = "300ms";
    event.target.style.border = "1px solid #868e96";
    event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.3)";
  }

  const handleSaveAndContinue = async () => {
    const ans = await confirmSave();
    if (!ans) return;
    axios
      .put(`/api/updateSkillEvaluationPlan/${id}`, evalutaionPlanData)
      .then((res) => {
        window.alert("success");
        history.goBack();
      })
      .catch((err) => console.log(err));
  };

  const handleSyncTniReport = async () => {
    setIsloading(true);
    axios
      .get(
        `/api/syncEvaluationPlanReport/${id}/${evalutaionPlanData?.departments._id}`
      )
      .then((res) => {
        // setEvaluationPlanData((prev) => ({ ...prev, ...res.data.processes }));
        setRenderSync((prev) => !prev);
        const timer = setTimeout(() => {
          setIsloading(false);
          window.alert("successfully updated");
        }, 1000);
      })
      .catch((err) => {
        const timer = setTimeout(() => {
          setIsloading(false);
        }, 1000);
        console.log(err);
      });
  };

  return (
    <div
      style={{
        paddingBottom: "4rem",
        position: "relative",
      }}
    >
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            zIndex: "100",
            top: "50%",
            right: "50%",
            transform: "translate(50%,-50%)",
          }}
        >
          {<Loader height={"100vh"} />}
        </div>
      ) : (
        ""
      )}
      <DragableButton left>
        <ArrowBackIosIcon style={{ color: "#fff" }} />
      </DragableButton>
      <DragableButton right>
        <ArrowForwardIosIcon style={{ color: "#fff" }} />
      </DragableButton>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          SKILL EVALUATION PLAN
        </Typography>
        <div style={{ display: "flex" }}>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#1864ab",
                marginRight: "0.5rem",
              }}
              startIcon={<SyncIcon />}
              onClick={() => {
                handleSyncTniReport(true);
              }}
            >
              Synch Employee
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#064a29",
                marginRight: "0.5rem",
              }}
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                handleSaveAndContinue();
              }}
            >
              Save And Continue
            </Button>
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </Wrapper>
        </div>
      </div>
      <TableContainerStyled id="machinetable">
        <Table>
          <thead>
            <tr data-height="80" style={{ height: "70px" }}>
              <th
                colSpan="14"
                rowSpan={2}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  position: "relative",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="120"
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th
                colSpan={3}
                style={{
                  border: "1px solid black",
                }}
              >
                Doc.No. ARC/F/HRD/11
              </th>
            </tr>
            <tr>
              <th
                colSpan={3}
                style={{ bo5er: "1px solid black" }}
                data-f-sz="10"
              >
                REV. NO.:- 2
              </th>
            </tr>
            <tr>
              <th
                colSpan="17"
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="18"
                data-a-h="center"
                data-f-bold="true"
                data-fill-color="#1d1d1d"
                data-f-color="FFFFFF"
              >
                {`SKILL EVALUATION PLAN  ${getYearRange(
                  evalutaionPlanData.created_year
                )}`}
              </th>
            </tr>

            <tr>
              <th colSpan="7">DEPARTMENT</th>
              <th colSpan="10">
                {evalutaionPlanData?.departments?.department_name}
              </th>
            </tr>

            <tr>
              {[
                "Sr. No.",
                "Department/Name",
                "Frequency",
                `APR-${getformatedYear.currentYear}`,
                `MAY-${getformatedYear.currentYear}`,
                `JUN-${getformatedYear.currentYear}`,
                `JUL-${getformatedYear.currentYear}`,
                `AUG-${getformatedYear.currentYear}`,
                `SEP-${getformatedYear.currentYear}`,
                `OCT-${getformatedYear.currentYear}`,
                `NOV-${getformatedYear.currentYear}`,
                `DEC-${getformatedYear.currentYear}`,
                `JAN-${getformatedYear.nextYear}`,
                `FEB-${getformatedYear.nextYear}`,
                `MAR-${getformatedYear.nextYear}`,
                `Remarks`,
              ].map((el, index) => (
                <th
                  colSpan={el === "Remarks" ? 2 : 1}
                  align="center"
                  style={{ textAlign: "center" }}
                >
                  {el}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {evalutaionPlanData.processes &&
              evalutaionPlanData.processes.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td align="center">{index + 1}</td>
                    <td align="center" width={"200px"}>
                      {item?.employee_id?.employee_name}
                    </td>

                    <td width="120px">
                      <input
                        autoComplete="off"
                        className="input"
                        name="frequency"
                        style={{
                          border: "1px solid #f9e0e0",
                          height: "50px",
                        }}
                        type="textfield"
                        defaultValue={item.frequency}
                        onChange={(e) => {
                          item.frequency = e.target.value;
                        }}
                      />
                    </td>
                    {[
                      "april",
                      "may",
                      "june",
                      "july",
                      "august",
                      "september",
                      "october",
                      "november",
                      "december",
                      "january",
                      "february",
                      "march",
                    ].map((month) => (
                      <td
                        style={{
                          padding: "0px",
                          margin: "0px",
                          backgroundColor: "#f1f3f5",
                        }}
                        onClick={(e) => handleDoubleClick(e, month, index)}
                      >
                        <span
                          onMouseOver={MouseOver}
                          onMouseOut={MouseOut}
                          onMouseDown={MouseDown}
                          style={{
                            fontSize: "3rem",
                            display: "flex",
                            outline: "none",
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#495057",

                            backgroundColor: "#f1f3f5",
                            boxShadow: "0 30px 40px rgb(255, 227, 227,.2)",
                            cursor: "pointer",
                            textShadow: "1px 1px #b0b0b0",
                          }}
                        >
                          {item.training_months[month]?.isChecked && "○"}
                        </span>
                      </td>
                    ))}

                    <td width="100px">
                      <input
                        autoComplete="off"
                        className="input"
                        name="remarks"
                        style={{
                          border: "1px solid #f9e0e0",
                          height: "50px",
                        }}
                        type="textfield"
                        defaultValue={item.remarks}
                        onChange={(e) => {
                          item.remarks = e.target.value;
                        }}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </Table>
        <DialogAddTask isSubmitContent={false} />
        <DialogRemoveTask isSubmitContent={false} />
        <DialogClose isSubmitContent={false} />
        <DialogSave isSubmitContent={false} />
        <DialogRemoveRow isSubmitContent={false} />
      </TableContainerStyled>
    </div>
  );
};

export default CreateEditSkillEvaluationPlan;
