import React from "react";
import { Link } from "react-router-dom";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DateRangeIcon from "@material-ui/icons/DateRange";
import WorkIcon from "@material-ui/icons/Work";
// import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import tilesStyle from "./tilesStyle.module.css";

import { GridContainer } from "./Container.styled";

const EducationAndTraining = () => {
  return (
    <section style={{ height: "90vh" }}>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        Training
      </div>
      <main className={`${tilesStyle.container} ${tilesStyle.main}`}>
        <div>
          <Link to="/questionPaper/list" className="panel">
            <ReceiptIcon style={{ fontSize: "3rem", color: "#1864ab" }} />
          </Link>
          <p className="card-btn-para">
            Effectiveness <span className="card-btn-span">Test Papers</span>
          </p>
        </div>
        <div>
          <Link to="/tniList/data" className="panel">
            <WorkIcon style={{ fontSize: "3rem", color: "#a61e4d" }} />
          </Link>
          <p className="card-btn-para">
            Training Need Identification{" "}
            <span className="card-btn-span">(TNI)Report</span>
          </p>
        </div>
        {/* <div>
          <div className="panel">
            <CalendarTodayIcon style={{ fontSize: "3rem", color: "green" }} />
          </div>
          <p className="card-btn-para">
            Training
            <span className="card-btn-span">Plan</span>
          </p>
        </div> */}

        <div>
          <Link to="/annualTrainingList/" className="panel">
            <DateRangeIcon style={{ fontSize: "3rem", color: "#495057" }} />
          </Link>
          <p className="card-btn-para">
            {/* ANNUAL  */}
            TRAINING
            <span className="card-btn-span">
              PLAN
              {/* FOR OPERATOR */}
            </span>
          </p>
        </div>
      </main>
    </section>
  );
};

export default EducationAndTraining;
