import React from "react";
import { Link } from "react-router-dom";
import clausePage from "./ClausePage.module.css";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
//icons
import ListIcon from "@material-ui/icons/List";
// end icons

const RmTiles = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        RM DEPARTMENT
      </div>
      <main className={`${clausePage.container} ${clausePage.main}`}>
        <div>
          <Link to="/incomingRMResisterList" className="panel">
            <FeaturedPlayListIcon
              style={{ fontSize: "3rem", color: "#003566" }}
            />
          </Link>
          <p className="card-btn-para">
            Incoming RM <span className="card-btn-span">Resister</span>
          </p>
        </div>
      </main>
    </>
  );
};

export default RmTiles;
