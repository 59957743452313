import Input from "../../../components/Control/Input";
import useConfirm from "../../../components/Control/useConfirm";
import { Wrapper } from "../../../components/Control/Flexbox";
import { v4 as uuidv4 } from "uuid";
import React, { useState } from "react";
import { ExpandLess, ExpandMore, Add } from "@material-ui/icons";
import EditIcon from "@material-ui/icons/Edit";
import { toSuperScript } from "../../../components/Control/toSuperScript";
import ControlPlanInput from "./ControlPlanInput";
import DeleteIcon from "@material-ui/icons/Delete";

const compare = (a, b) => {
  return parseInt(a.s_no) - parseInt(b.s_no);
};
const ControlPlanPart = ({
  controlPlanData,
  removeProcess,
  process,
  index,
  expandState,
  setExpandState,
  handleChangeProcess,
}) => {
  const [render, setRender] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [specModalData, setSpecModalData] = useState(null);
  const [DialogDeleteSpec, confirmDeleteSpec] = useConfirm(
    "DELETE SPECS",
    "Are you sure you want to delete this specs?"
  );

  const sortProcess = () => {
    controlPlanData.processes[index].specs.sort(compare);
    setRender((prev) => !prev);
  };

  const handleSnoAndSorting = (e, specs) => {
    specs[e.target.name] = e.target.value;
    sortProcess();
  };

  const handleSpec = (e, specs) => {
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    specs[e.target.name] = value;
    if (e.target.name === "unit") {
      let convertedValue = toSuperScript(value);
      specs[e.target.name] = convertedValue;
      setRender((prev) => !prev);
      return;
    }
    specs[e.target.name] = value;

    const spec = specs;

    if (
      (spec.utl || spec.ltl || spec.utl == 0 || spec.ltl == 0) &&
      spec.appearance === "check1"
    ) {
      spec.control_product = `${spec.ltl} ~ ${spec.utl} ${
        spec.unit ? spec.unit : ""
      } ${spec.control_product_remark ? spec.control_product_remark : ""}`;
      spec.ltolerance = null;
      spec.utolerance = null;
      spec.mean = null;
      spec.checkbox4_num = null;
      spec.check4_symbol = null;
    }
    if (
      (spec.mean ||
        spec.utolerance ||
        spec.ltolerance ||
        spec.mean === 0 ||
        spec.ltolerance === 0 ||
        spec.utolerance === 0) &&
      spec.appearance === "check2"
    ) {
      const mean = spec.mean;
      const ut = spec.utolerance;
      const lt = spec.ltolerance;
      spec.utl = null;
      spec.ltl = null;
      spec.checkbox4_num = null;
      spec.check4_symbol = null;
      if (ut === lt)
        spec.control_product = `${mean} ± ${ut === null ? "" : ut} ${
          spec.unit ? spec.unit : ""
        } ${spec.control_product_remark ? spec.control_product_remark : ""}`;
      else
        spec.control_product = `${mean} - ${lt === null ? "" : lt} ${
          spec.unit ? spec.unit : ""
        } : ${mean} + ${ut === null ? "" : ut} ${spec.unit ? spec.unit : ""} ${
          spec.control_product_remark ? spec.control_product_remark : ""
        }`;
    }
    if (spec.appearance === "check3") {
      spec.ltolerance = null;
      spec.utolerance = null;
      spec.mean = null;
      spec.utl = null;
      spec.ltl = null;
      spec.control_product_remarks = null;
      spec.checkbox4_num = null;
      spec.check4_symbol = null;
    }

    if (spec.appearance === "check4") {
      spec.utl = null;
      spec.ltl = null;
      spec.ltolerance = null;
      spec.utolerance = null;
      spec.mean = null;
      spec.checkbox4_num = spec.checkbox4_num;
      spec.check4_symbol = spec.check4_symbol;
      //less than equal to
      if (spec.checkbox4_num && spec.check4_symbol === "<=") {
        spec.control_product = `Max ${spec.checkbox4_num}`;
      }
      //greater than equal to
      if (spec.checkbox4_num && spec.check4_symbol === ">=") {
        spec.control_product = `Min ${spec.checkbox4_num}`;
      }
    }
    setRender((prev) => !prev);
  };

  function handleAppearance(e, specs) {
    specs.appearance = e.target.name;
    setRender((prev) => !prev);
  }

  const setSpecsAndModal = (spec) => {
    setSpecModalData(spec);
    setOpenPopup(true);
  };

  const handleAllCheck = (e) => {
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    controlPlanData.processes[index].specs.forEach((specs, index) => {
      specs[e.target.name] = value;
    });
    setRender((prev) => !prev);
  };

  return (
    <>
      <div>
        <div
          style={{
            marginBottom: "0.3rem",
            marginTop: "0.6rem",
            padding: "0rem",
            border: "1px solid #e9ecef",
            marginBottom: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#fafafa",
              alignItems: "center",
            }}
            className="spec-header"
          >
            <div
              style={{
                // width: "70%",
                display: "flex",
                gap: "5rem",
                // display: "grid",
                // gridTemplateColumns: "repeat( auto-fit, minmax(100px, 1fr) )",
                alignItems: "center",
              }}
            >
              <Wrapper>
                <Input
                  type="Number"
                  name="s_no"
                  onBlur={(e) => handleChangeProcess(e, index)}
                  defaultValue={process.s_no}
                  style={{ width: "80px" }}
                />
              </Wrapper>
              <div
                style={{
                  // marginLeft: "1rem",
                  fontSize: "1rem",
                  width: "8rem",
                  // fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {process?.process_no}
              </div>
              <div
                style={{
                  // marginLeft: "1rem",
                  fontSize: "1rem",
                  // fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                {process?.process_name}
              </div>
            </div>

            <Wrapper>
              <Wrapper
                style={{
                  height: "45px",
                  width: "175px",
                  backgroundColor: "#003566",
                  color: "white",
                  cursor: "pointer",
                  display: "flex",
                  gap: "0.5rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  controlPlanData.processes[index].specs.push({
                    s_no: process?.specs.length + 1,
                    key: uuidv4(),
                  });

                  setExpandState((prev) => {
                    prev[index] = true;
                    return [...prev];
                  });
                }}
                justify="center"
              >
                <Add />
                <span>Add Specs</span>
              </Wrapper>
              {expandState[index] ? (
                <Wrapper
                  style={{
                    height: "45px",
                    width: "130px",
                    backgroundColor: "#003566",
                    cursor: "pointer",
                    color: "white",
                    alignItems: "center",
                    gap: "0.5rem",
                    // justifyContent: "center",
                  }}
                  onClick={() =>
                    setExpandState((prev) => {
                      prev[index] = false;
                      return [...prev];
                    })
                  }
                >
                  <ExpandLess style={{ marginLeft: "0.5rem" }} />
                  <span>Collapse</span>
                </Wrapper>
              ) : (
                <Wrapper
                  style={{
                    height: "45px",
                    width: "130px",
                    backgroundColor: "#003566",
                    cursor: "pointer",
                    color: "white",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                  onClick={() =>
                    setExpandState((prev) => {
                      prev[index] = true;
                      return [...prev];
                    })
                  }
                >
                  <ExpandMore style={{ marginLeft: "0.5rem" }} />
                  <span>Expand</span>
                </Wrapper>
              )}
              <Wrapper
                style={{
                  height: "45px",
                  width: "45px",
                  color: "#f1f3f5",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  gap: "0.5rem",
                  backgroundColor: "#D70012",
                }}
                justify="center"
                onClick={() => removeProcess(index)}
              >
                <DeleteIcon style={{ color: "#f8f9fa" }} />
              </Wrapper>
            </Wrapper>
          </div>
          {expandState[index] && (
            <Wrapper
              style={{
                borderTop: "1px solid #e9ecef",
                borderLeft: "1px solid #e9ecef",
                borderRight: "1px solid #e9ecef",
                // borderBottom: "1px solid #e9ecef",
                marginTop: "5px",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <Input
                className="input_field_width"
                type="text"
                name="process_no"
                defaultValue={process?.process_no}
                style={{
                  fontSize: "1rem",
                  textTransform: "uppercase",
                }}
                placeholder="Operation Number"
                onBlur={(e) => handleChangeProcess(e, index)}
              />
              <Input
                className="input_field_width"
                type="text"
                name="process_name"
                defaultValue={process?.process_name}
                style={{
                  fontSize: "1rem",
                  textTransform: "uppercase",
                }}
                placeholder="Process Description"
                onBlur={(e) => handleChangeProcess(e, index)}
              />
            </Wrapper>
          )}
          {expandState[index] &&
          controlPlanData.processes[index]?.specs?.length ? (
            <>
              <div
                style={{
                  marginBottom: "0.3rem",
                  marginTop: "0.6rem",
                  padding: "0rem",
                }}
              >
                {/* <div style={{ width: "15.5%" }}></div>
                <div style={{ width: "15.5%" }}></div> */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    background: "#fafafa",
                    alignItems: "center",
                  }}
                  className="spec-header"
                >
                  {/* <Wrapper style={{ display: "none" }}>
                    <Input
                      type="Number"
                      name="s_no"
                      style={{ width: "80px", visibility: "hidden" }}
                    />
                    <Wrapper
                      justify="center"
                      style={{
                        marginLeft: "5rem",
                        fontSize: "0.9rem",
                        textTransform: "uppercase",
                        visibility: "hidden",
                      }}
                    ></Wrapper>
                  </Wrapper> */}
                  <div style={{ display: "flex", height: "45px" }}>
                    <div className="add_to_pir_spec">
                      <input
                        type="checkbox"
                        className="spec-checkbox"
                        name="spec_to_pir"
                        onChange={(e) => handleAllCheck(e)}
                      />
                      <span
                        style={{
                          fontWeight: "500",
                        }}
                      >
                        Add All to PIR
                      </span>
                    </div>
                    <div className="add_to_pir_spec">
                      <input
                        type="checkbox"
                        className="spec-checkbox"
                        name="spec_to_pdir"
                        onChange={(e) => handleAllCheck(e)}
                      />
                      <span
                        style={{
                          fontWeight: "500",
                        }}
                      >
                        Add All to PDIR
                      </span>
                    </div>
                    <div className="add_to_pir_spec">
                      <input
                        type="checkbox"
                        className="spec-checkbox"
                        name="spec_to_setup"
                        onChange={(e) => handleAllCheck(e)}
                      />
                      <span
                        style={{
                          fontWeight: "500",
                        }}
                      >
                        Add All to Setup
                      </span>
                    </div>
                    <div className="add_to_pir_spec">
                      <input
                        type="checkbox"
                        className="spec-checkbox"
                        name="spec_to_Incoming_Report"
                        onChange={(e) => handleAllCheck(e)}
                      />
                      <span
                        style={{
                          fontWeight: "500",
                        }}
                      >
                        Add All to Incoming Report
                      </span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <Wrapper
                        style={{
                          color: "#fafafa",
                          backgroundColor: "#161A1D",
                          height: "45px",
                          width: "130px",
                          minWidth: "130px",
                          gap: "0.5",
                          alignItems: "center",
                          visibility: "hidden",
                        }}
                      >
                        <EditIcon
                          style={{
                            margin: "0 0.4rem 0 0.6rem ",
                            fontSize: "1rem",
                          }}
                        />
                        <span>Edit Specs</span>
                      </Wrapper>

                      <Wrapper
                        style={{
                          height: "45px",
                          width: "45px",
                          minWidth: "45px",
                          visibility: "hidden",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <DeleteIcon style={{ color: "#f8f9fa" }} />
                      </Wrapper>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {expandState[index] &&
            process?.specs.map((spec, specsIndex) => (
              <div
                key={spec.key}
                style={{
                  marginBottom: "0.3rem",
                  marginTop: "0.6rem",
                  padding: "0rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#fafafa",
                    alignItems: "center",
                  }}
                  className="spec-header"
                >
                  <Wrapper>
                    <Input
                      type="Number"
                      name="s_no"
                      // onBlur={(e) => sortProcess(e)}
                      onBlur={(e) => handleSnoAndSorting(e, spec, specsIndex)}
                      defaultValue={spec.s_no}
                      style={{ width: "80px" }}
                    />
                    <Wrapper
                      justify="center"
                      style={{
                        marginLeft: "5rem",
                        fontSize: "0.9rem",
                        textTransform: "uppercase",
                      }}
                    >
                      {spec.char_product || spec.char_process}
                    </Wrapper>
                  </Wrapper>
                  <div style={{ display: "flex", gap: "27px" }}>
                    <div className="add_to_pir_spec">
                      <input
                        type="checkbox"
                        className="spec-checkbox"
                        name="spec_to_pir"
                        onChange={(e) => handleSpec(e, spec)}
                        checked={spec.spec_to_pir}
                      />
                      Add to PIR
                    </div>

                    <div className="add_to_pir_spec">
                      <input
                        type="checkbox"
                        className="spec-checkbox"
                        name="spec_to_pdir"
                        onChange={(e) => handleSpec(e, spec)}
                        checked={spec.spec_to_pdir}
                      />
                      Add to PDIR
                    </div>

                    <div className="add_to_pir_spec">
                      <input
                        type="checkbox"
                        className="spec-checkbox"
                        name="spec_to_setup"
                        onChange={(e) => handleSpec(e, spec)}
                        checked={spec.spec_to_setup}
                      />
                      Add to Setup
                    </div>

                    <div className="add_to_pir_spec">
                      <input
                        type="checkbox"
                        className="spec-checkbox"
                        name="spec_to_Incoming_Report"
                        onChange={(e) => handleSpec(e, spec)}
                        checked={spec.spec_to_Incoming_Report}
                      />
                      Add to Incoming Report
                    </div>

                    <div style={{ display: "flex" }}>
                      <Wrapper
                        style={{
                          color: "#fafafa",
                          backgroundColor: "#161A1D",
                          height: "45px",
                          width: "130px",
                          minWidth: "130px",
                          gap: "0.5",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setSpecsAndModal(spec);
                        }}
                      >
                        <EditIcon
                          style={{
                            margin: "0 0.4rem 0 0.6rem ",
                            fontSize: "1.2rem",
                          }}
                        />
                        <span>Edit Specs</span>
                      </Wrapper>

                      <Wrapper
                        style={{
                          height: "45px",
                          width: "45px",
                          minWidth: "45px",
                          color: "#f1f3f5",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          backgroundColor: "#D70012",
                        }}
                        onClick={async () => {
                          const isDelete = await confirmDeleteSpec();
                          if (isDelete) {
                            process.specs.splice(specsIndex, 1);
                            setRender((prev) => !prev);
                          }
                        }}
                      >
                        <DeleteIcon style={{ color: "#f8f9fa" }} />
                      </Wrapper>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <DialogDeleteSpec isSubmitContent={false} />

        {specModalData && (
          <ControlPlanInput
            {...{
              specModalData,
              openPopup,
              setOpenPopup,
              handleSpec,
              handleAppearance,
            }}
          />
        )}
      </div>
    </>
  );
};

export default ControlPlanPart;
