import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

const Div = styled.div`
  flex: 0.986;
  border: 1px solid black;
  p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    letter-spacing: 1.6px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #1d1d1d;
    color: white;
  }
`;

const sortObject = (obj) => {
  let sortedObj = Object.entries(obj).sort((a, b) => b[1] - a[1]);
  let rejectedArr = [];
  let failureLabels = [];
  sortedObj.forEach((value) => {
    failureLabels.push(value[0]);
    rejectedArr.push(value[1]);
  });
  return { rejectedArr, failureLabels };
};

const FailureWiseParetoGraph = ({ producedQty, failureRejection }) => {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([
    {
      name: "Rejection",
      type: "column",
      data: [],
    },
    {
      name: "Percentage %",
      type: "line",
      data: [],
    },
  ]);
  function generateDefectGraph(rejectedData, failureLabels, producedQty) {
    const ppmArr = [];
    let totalPpm = 0;
    for (let i = 0; i < rejectedData.length; i++) {
      const ppm = Number(
        ((rejectedData[i] / (producedQty || 1)) * 1000000).toFixed(2)
      );
      totalPpm += ppm;
      ppmArr.push(ppm);
    }
    const percentage = [];
    for (let i = 0; i < ppmArr.length; i++) {
      const ppmPercentage = Number(((ppmArr[i] / totalPpm) * 100).toFixed(2));
      percentage.push(ppmPercentage);
    }

    const cummulativePercentage = [];
    for (let i = 0; i < percentage.length; i++) {
      if (i === 0) {
        cummulativePercentage[i] = percentage[i];
      } else
        cummulativePercentage[i] = Number(
          (cummulativePercentage[i - 1] + percentage[i]).toFixed(2)
        );
    }
    setOptions({
      chart: {
        id: "failure-wise",
        type: "line",
        // dropShadow: {
        //   enabled: true,
        //   color: "#000",
        //   top: 18,
        //   left: 7,
        //   blur: 10,
        //   opacity: 0.3,
        // },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: [0, 3],
      },
      markers: {
        size: 4,
      },

      legend: {
        position: "top",
      },
      fill: {
        colors: ["#003566", "#006616", "#003566"],
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
        },
      },
      xaxis: { categories: failureLabels },
      // title: { text: "FAILURE WISE", align: "left" },
      yaxis: [
        {
          title: {
            text: "PERCENTAGE",
          },
          max: 100,
        },
        {
          opposite: true,
          title: {
            text: "CUMMULATIVE PERCENTAGE",
          },
          max: 100,
        },
      ],
    });
    setSeries([
      {
        name: "Percentage %",
        type: "column",
        data: percentage,
      },
      {
        name: "Cummulative Percentage %",
        type: "line",
        data: cummulativePercentage,
      },
    ]);
  }

  useEffect(() => {
    const { rejectedArr, failureLabels } = sortObject(failureRejection);
    generateDefectGraph(rejectedArr, failureLabels, producedQty);
  }, [failureRejection, producedQty]);
  return (
    <Div>
      <p>PPM REJECTIONS %</p>
      <Chart options={options} series={series} height={550} className="col" />
    </Div>
  );
};

export default FailureWiseParetoGraph;
