import React from "react";
import Styled from "styled-components";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
export const ButtonStyled = Styled.button`
  position: fixed;
    top: 55%;
    left: ${(props) => (props.left ? "5px" : "none")};
    right: ${(props) => (props.right ? "5px" : "none")};
    background-color: #343a40;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    Z-Index: 1000;
    opacity: 0.2;
  :hover {
    opacity: 1;
    transform: scale(1.2);
    transition: 200ms;

  }
    div { 
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
`;

export const TableContainerStyled = Styled.div`
          margin:0.1rem 0 0.1rem 0.1rem;
          paddingBottom: 4rem;
          max-height: 100vh;
          max-width: 100vw;
          overflow-x: scroll;
          overflow-y: auto;
          position: relative;
          scroll-behavior: smooth;
          -ms-overflow-style: none;
          scrollbar-width: none; 
          ::-webkit-scrollbar
          {
            width: 20px;
            height: 0;
          }

          
          ::-webkit-scrollbar-track {
  background-color: transparent;
}
        
}
          ::-webkit-scrollbar-thumb {
          background-color: #d6dee1;
          border-radius: 20px;
          border: 6px solid transparent;
          background-clip: content-box;
        }

          ::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
`;

const TableScroll = ({ children, ...rest }) => {
    const handleScroll = (scrolltype) => {
        if (scrolltype === "right") {
            document.getElementById("machinetable").scrollLeft += 80;
        } else {
            document.getElementById("machinetable").scrollLeft -= 80;
        }
    };
    return (
        <main {...rest}>
            <ButtonStyled
                left
                onClick={(e) => {
                    handleScroll("left");
                }}
            >
                <div>
                    <ArrowBackIosIcon style={{ color: "#fff" }} />
                </div>
            </ButtonStyled>
            <ButtonStyled
                right
                onClick={(e) => {
                    handleScroll("right");
                }}
            >
                <div>
                    <ArrowForwardIosIcon style={{ color: "#fff" }} />
                </div>
            </ButtonStyled>
            <TableContainerStyled id="machinetable">{children}</TableContainerStyled>
        </main>
    );
};

export default TableScroll;
