import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import PdirListForm from "./PdirListForm";

const ProcessPdir = ({ pdirData }) => {
  const [isPdir, setIsPdir] = useState([]);

  const hidePir = (index) => {
    setIsPdir((prev) => [...prev, index]);
  };

  const openPir = (index) => {
    const values = [...isPdir];
    const idx = values.indexOf(index);
    values.splice(idx, 1);
    setIsPdir(values);
  };

  return (
    <div style={{ margin: "1rem 0.6rem", paddingBottom: "4rem" }}>
      {pdirData.pdir_list.map((pdir, index) => (
        <div key={index} style={{ marginTop: "0.1rem" }}>
          <main style={{ border: "1px solid #f0f2f0" }}>
            <Wrapper
              style={{
                background: "#fafafa",
              }}
              justify="space-between"
            >
              <Wrapper>
                <Wrapper
                  style={{
                    fontSize: "0.9rem",
                    height: "45px",
                    width: "45px",
                    fontWeight: 500,
                    color: "white",
                    backgroundColor: "#1d1d1d",
                  }}
                  justify="center"
                >
                  {index + 1}
                </Wrapper>
                <Wrapper
                  justify="start"
                  style={{
                    marginLeft: "1.3rem",
                    fontSize: "0.9rem",
                    textTransform: "uppercase",
                    width: "300px",
                  }}
                >
                  {pdir.parameter}
                </Wrapper>
                <Wrapper
                  justify="center"
                  style={{
                    marginLeft: "1.3rem",
                    fontSize: "0.9rem",
                    textTransform: "uppercase",
                  }}
                >
                  {pdir.specification}
                </Wrapper>
              </Wrapper>
              <Wrapper>
                {isPdir.includes(index) ? (
                  <Wrapper
                    style={{
                      height: "45px",
                      width: "50px",
                      backgroundColor: "#3f51b5",
                      cursor: "pointer",
                      color: "white",
                    }}
                    justify="center"
                    onClick={() => openPir(index)}
                  >
                    <ExpandLess />
                  </Wrapper>
                ) : (
                  <Wrapper
                    style={{
                      height: "45px",
                      width: "50px",
                      backgroundColor: "#3f51b5",
                      cursor: "pointer",
                      color: "white",
                    }}
                    justify="center"
                    onClick={() => hidePir(index)}
                  >
                    <ExpandMore />
                  </Wrapper>
                )}
              </Wrapper>
            </Wrapper>
            {isPdir.includes(index) && (
              <section>
                <PdirListForm {...{ index, pdirData, pdir }} />
              </section>
            )}
          </main>
        </div>
      ))}
    </div>
  );
};

export default ProcessPdir;
