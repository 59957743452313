import React, { useState, useEffect } from "react";
import Modal from "../../../components/Control/Modal";
import Input from "../../../components/Control/Input";
import Button from "@material-ui/core/Button";
import axios from "axios";
import RichTextEditor from "react-rte";
import { Wrapper } from "../../../components/Control/Flexbox";
import SaveIcon from "@material-ui/icons/Save";
import { useAuth } from "../../../context/GlobalContext";

function NoteModal(props) {
  const { openModal, closeModal, passNote, id, isView } = props;
  const { state } = useAuth();
  const userId = state.user._id;

  const [note, setNote] = useState({
    note_number: "",
    note_name: "",
    content: "",
    editorState: RichTextEditor.createEmptyValue(),
  });

  // const [editorState, setEditorState] = useState(RichTextEditor.createEmptyValue());
  const fetchData = async () => {
    if (id) {
      try {
        const res = await axios.get(`/api/get/note?id=${id}&userId=${userId}`, {
          headers: { "Cache-Control": "no-cache" },
        });
        if (res.data.status === "not found") {
          closeModal();
        }
        setNote({
          note_number: res.data.note_number,
          note_name: res.data.note_name,
          content: res.data.content,

          editorState: RichTextEditor.createValueFromString(
            res.data.content,
            "html"
          ),
        });
        // setEditorState(RichTextEditor.createValueFromString(res.data.content, 'html'));
      } catch (err) {
        console.log(err);
      }
    } else {
      setNote({
        note_number: "",
        note_name: "",
        content: "",
        editorState: RichTextEditor.createEmptyValue(),
      });
      // setEditorState(RichTextEditor.createValueFromString('', 'html'));
    }
  };

  useEffect(() => fetchData(), [id, openModal]);

  // useEffect(() => {
  //   setEditorState(RichTextEditor.createValueFromString(note.content, 'html'));
  // }, [note.content]);

  const handleChange = (event) => {
    setNote({ ...note, [event.target.name]: event.target.value });
  };

  const submitContent = () => {
    setNote({ ...note, content: note.editorState.toString("html") });
  };

  const toolbar = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
      { label: "Code", style: "CODE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  return (
    <Modal
      title={
        isView
          ? `${note.note_number} - ${note.note_name}`
          : id
          ? "EDIT NOTE"
          : "ADD NOTE"
      }
      openPopup={openModal}
      closeModal={() => {
        closeModal();
      }}
      style={{ width: "500px" }}
    >
      <section style={{ padding: "1rem", paddingBottom: "4rem" }}>
        <Wrapper>
          {" "}
          {!isView && (
            <>
              <Input
                placeholder="Note ID"
                name="note_number"
                value={note.note_number}
                type="text"
                onChange={handleChange}
              />
              <Input
                placeholder="Note Name"
                name="note_name"
                value={note.note_name}
                type="text"
                onChange={handleChange}
                style={{ width: "250px" }}
              />
            </>
          )}
        </Wrapper>
        {note.editorState && (
          <div
            style={{
              padding: "5px",
              minWidth: "500px",
            }}
          >
            <RichTextEditor
              readOnly={isView}
              toolbarHidden={isView}
              value={note.editorState}
              onChange={(editorState) =>
                setNote({ ...note, editorState: editorState })
              }
              // onBlur={submitContent}
              toolbarConfig={toolbar}
            />
          </div>
        )}
        {!isView ? (
          <div
            className="btn_save"
            style={{
              position: "absolute",
              right: "150px",
              top: "9px",
            }}
          >
            <Button
              type="submit"
              variant="outlined"
              color="white"
              size="large"
              style={{ color: "#e9e9e9" }}
              onClick={() => {
                passNote({
                  ...note,
                  content: note.editorState.toString("html"),
                });
                setNote({
                  note_number: "",
                  note_name: "",
                  content: "",
                });
              }}
              startIcon={<SaveIcon />}
            >
              SAVE
            </Button>
          </div>
        ) : null}
      </section>
    </Modal>
  );
}

export default NoteModal;
