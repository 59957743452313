import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { DatePicker } from "@material-ui/pickers";
import ClearAllIcon from "@material-ui/icons/ClearAll";
import { Wrapper } from "../../../components/Control/Flexbox";
import useConfirm from "../../../components/Control/useConfirm";
import Input from "../../../components/Control/Input";
import PublishIcon from "@material-ui/icons/Publish";

const AddEditPart = ({ setIsNewPartModal, id }) => {
  const [partData, setPartData] = useState({
    date_orig: new Date(),
  });

  const [customerData, setCustomerData] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState({});
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    "DRAFT PART",
    `Are you sure you want to ${id ? "update" : "save"} this part?`
  );

  function handlePartData(e) {
    setPartData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/get/partById/${id}`)
        .then((res) => {
          setPartData(res.data);
          setAutoCompleteValue({
            customer_name: res.data.customer_id.customer_name,
            id: res.data.customer_id._id,
          });
        })
        .catch((err) => console.log(err));
    } else {
      setPartData({
        date_orig: new Date(),
      });
    }
  }, [id]);

  useEffect(() => {
    axios
      .get("/api/getCustomer/all")
      .then((res) => {
        setCustomerData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  // console.log(customerData);
  const onSubmit = async (e) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    partData.isNpd = true;
    if (id) {
      await axios
        .put(`/api/update/part/${id}`, partData)
        .then((res) => {
          setIsNewPartModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/create/part", partData)
        .then((res) => {
          setIsNewPartModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div className="root-admin">
      <form
        style={{
          margin: "1.8rem",
        }}
        onSubmit={(e) => onSubmit(e)}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "4fr 1fr",
            width: "100%",
          }}
        >
          <div className="supplier-section">
            <section className="supplier-section-part">
              <Input
                type="text"
                name="part_number"
                required
                placeholder="Part Number"
                onChange={handlePartData}
                value={partData.part_number}
              />
              <Input
                type="text"
                name="part_name"
                placeholder="Customer Part Name"
                required
                onChange={handlePartData}
                value={partData.part_name}
              />
              {console.log(partData?.customer)}
              <Autocomplete
                id="customer_id"
                options={customerData}
                // className="select-field"
                getOptionLabel={((option) => option.customer_name) || []}
                value={autoCompleteValue}
                style={{
                  border: "1px solid #ced4da",
                  // backgroundColor: "#ced4da",
                  paddingLeft: "0.5rem",
                  marginLeft: "0.5rem",
                  width: "90%",
                  height: "63px",
                  // marginRight: "6rem",
                }}
                onChange={(e, value) => {
                  partData.customer_id = value?._id;
                  setAutoCompleteValue(value);
                  setRender((prev) => !prev);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    // style={{ border: "1px solid #ced4da" }}
                  />
                )}
              />
            </section>
            <section className="supplier-section-part">
              <Input
                type="text"
                name="key_contact"
                placeholder="Key Contact / Phone"
                onChange={handlePartData}
                value={partData.key_contact}
              />
              <Input
                type="text"
                name="core_team"
                placeholder="Core Team"
                onChange={handlePartData}
                value={partData.core_team}
              />
              <Input
                type="text"
                name="supplier"
                placeholder="Supplier"
                onChange={handlePartData}
                value={partData.supplier}
              />
            </section>

            <section
              className="supplier-section-part"
              style={{ marginTop: "0.5rem" }}
            >
              <DatePicker
                inputVariant="filled"
                name="supplier_approval_date"
                style={{ paddingLeft: "0.6rem" }}
                label="Customer Engg Approval date"
                value={partData.supplier_approval_date || null}
                onChange={(date) =>
                  setPartData((prev) => ({
                    ...prev,
                    supplier_approval_date: date,
                  }))
                }
                format="dd/MM/yyyy"
                className="date-field"
              />

              <DatePicker
                inputVariant="filled"
                name="Other_approval_date"
                style={{ paddingLeft: "0.6rem" }}
                label="Customer Quality Approval date"
                value={partData.Other_approval_date || null}
                onChange={(date) =>
                  setPartData((prev) => ({
                    ...prev,
                    Other_approval_date: date,
                  }))
                }
                format="dd/MM/yyyy"
                className="date-field"
              />

              <DatePicker
                inputVariant="filled"
                name="date_orig"
                style={{ paddingLeft: "0.6rem" }}
                required
                label="DATE (Original)"
                value={partData.date_orig || null}
                onChange={(date) =>
                  setPartData((prev) => ({ ...prev, date_orig: date }))
                }
                format="dd/MM/yyyy"
                className="date-field"
              />
            </section>
            <section
              // className="supplier-section-part"
              style={{ width: "20rem" }}
            >
              <Input
                type="text"
                name="supplier_code"
                placeholder="Supplier Code"
                onChange={handlePartData}
                value={partData.supplier_code}
              />
            </section>
            <Wrapper
              style={{
                marginLeft: "0.76rem",
                marginTop: "1rem",
                gap: "1rem",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={() =>
                  setPartData((prev) => ({
                    ...prev,
                    Other_approval_date: null,
                    supplier_approval_date: null,
                    date_orig: null,
                    date_rev: null,
                  }))
                }
                startIcon={<ClearAllIcon />}
              >
                RESET DATE
              </Button>
              <p style={{ fontSize: "0.8rem", margin: 0 }}>
                Click to clear/reset all dates
              </p>
            </Wrapper>

            <div
              className="btn_save"
              style={{
                position: "absolute",
                right: "150px",
                top: "9px",
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                color="white"
                size="large"
                style={{ color: "#e9e9e9" }}
                startIcon={<PublishIcon />}
              >
                {id ? "UPDATE" : "SUBMIT"}
              </Button>
            </div>
          </div>
          <div>
            <TextField
              type="text"
              variant="filled"
              name="notes"
              multiline={true}
              rows={18}
              maxRows={20}
              className="textarea-field"
              label="Notes"
              style={{
                margin: "1.3rem 0.7rem 0rem 0.5rem",
                paddingRight: "1.2rem",
                width: "100%",
              }}
              onChange={handlePartData}
              value={partData.notes}
            />
          </div>
        </div>
      </form>
      <DialogSubmit isSubmitContent={false} />
    </div>
  );
};

export default AddEditPart;
