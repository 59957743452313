import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PfmeaTableSheet from "../../AddEditDuplicatePart/quickView/partPlanQuickView/PfmeaTableSheet";
import Loader from "../../../components/Control/Loader";

const PfmeaIndex = () => {
  const { id, partId } = useParams();
  const [fmeaData, setFmeaData] = useState({
    processes: [],
    revisionData: [],
  });
  useEffect(() => {
    axios
      .get(`/api/get/fmeaPart/${partId}/${id}`)
      .then((res) => setFmeaData(res.data))
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      {fmeaData ? (
        <PfmeaTableSheet fmeaData={fmeaData} fmeaId={id} viewHeader={true} />
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default PfmeaIndex;
