import React, { useState } from "react";
import { Button } from "@material-ui/core";
import axios from "axios";
import { Wrapper } from "../../../components/Control/Flexbox";
import { DatePicker } from "@material-ui/pickers";
import SaveIcon from "@material-ui/icons/Save";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SpecsPdir from "./SpecsPdir";
import PdirHeadForm from "./PdirHeadForm";
import Loader from "../../../components/Control/Loader";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../context/GlobalContext";
import { useQuery } from "../../../components/Control/useQuery";
import ItemsDetailsHeader from "../../../GlobalUtils/ItemsDetailsHeader";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  SaveAndContinueButton,
  CloseButton,
} from "../../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../../components/Control/useConfirm";

const PdirForm = ({ pdirData }) => {
  const [image, setImage] = useState(null);
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const [isLoading, setIsloading] = useState(false);
  const [render, setRender] = useState(false);
  const { state } = useAuth();
  const history = useHistory();
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save this PDIR?"
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "SUBMIT",
    "Are you sure you want to submit this PDIR?"
  );
  const handleSubmit = async (e) => {
    e.preventDefault();
    let ans = await confirmSubmit();
    if (ans) {
      setIsloading(true);
      pdirData.isSubmitted = true;
      pdirData.report_created_by = state.user.name;
      let formdata = new FormData();
      formdata.append("sketch", image);
      formdata.append("data", JSON.stringify(pdirData));
      await axios
        .post("/api/add/pdir", formdata)
        .then((res) => {
          if (res.data?.status === "success") {
            history.push(
              `/viewpdir/monthly/${pdirData.part_id}?partNumber=${partNumber}&partName=${partName}`
            );
          }
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
        });
    }
  };

  const saveLater = async () => {
    let ans = await confirmSave();
    if (ans) {
      setIsloading(true);
      pdirData.isSubmitted = false;
      pdirData.report_created_by = state.user.name;
      let formdata = new FormData();
      if (image) {
        formdata.append("sketch", image);
      }
      formdata.append("data", JSON.stringify(pdirData));
      await axios
        .post("/api/add/pdir", formdata)
        .then((res) => {
          if (res.data?.status === "success") {
            history.push(
              `/viewpdir/monthly/${pdirData.part_id}?partNumber=${partNumber}&partName=${partName}`
            );
          }
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
        });
    }
  };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
      <div
        className="divflex "
        style={{
          fontSize: "24px",
        }}
      >
        PRE-DISPATCH INSPECTION REPORT
      </div>
      <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />

      <form
        style={{
          margin: "0.6rem",
          marginTop: "1rem",
        }}
        onSubmit={(e) => handleSubmit(e)}
      >
        <Wrapper>
          <div style={{ marginLeft: "0.7rem" }}>
            <DatePicker
              label="Date"
              className="date-field"
              onChange={(date) => {
                pdirData.pdir_date = date;
                setRender((prev) => !prev);
              }}
              inputVariant="filled"
              format="dd/MM/yyyy"
              value={pdirData.pdir_date}
            />
          </div>
        </Wrapper>
        <PdirHeadForm {...{ pdirData, image, setImage }} />
        <SpecsPdir {...{ pdirData }} />
        <Wrapper
          style={{
            position: "absolute",
            top: "4.7rem",
            right: "1.6rem",
            gap: "1rem",
          }}
        >
          <SaveAndContinueButton onClick={saveLater} />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            style={{ backgroundColor: "#054a29", marginRight: "0.5rem" }}
            startIcon={<CloudUploadIcon />}
          >
            Submit
          </Button>
          <CloseButton
            onClick={async () => {
              let ans = await confirmClose();
              if (ans) {
                history.goBack();
              }
            }}
          />
        </Wrapper>
      </form>
      <DialogSave isSubmitContent={true} />
      <DialogSubmit isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
    </main>
  );
};

export default PdirForm;
