import React from "react";
import TableInput from "../../../../../common/TableInput";
import handleChangeSpec from "../../helpers/handleChangeSpec";

const ObservationInput = ({
  spec,
  isView,
  checkType,
  obsIndex,
  obsNumber,
  setRender,
}) => {
  if (checkType === "check3") {
    return isView ? (
      spec[`check_${obsNumber}_values`][obsIndex] || ""
    ) : (
      <TableInput
        name="observation"
        type="text"
        value={spec[`check_${obsNumber}_values`][obsIndex] || ""}
        onChange={(e) => {
          handleChangeSpec(e, spec, obsIndex, obsNumber);
          setRender((prev) => !prev);
        }}
        style={{
          textAlign: "center",
          minWidth: "90px",
        }}
        isView={isView}
      />
    );
  }
  if (
    checkType === "check1" ||
    checkType === "check2" ||
    checkType === "check4"
  ) {
    return (
      <TableInput
        name="observation"
        type="number"
        value={spec[`check_${obsNumber}_values`][obsIndex] || ""}
        onChange={(e) => {
          handleChangeSpec(e, spec, obsIndex, obsNumber);
          setRender((prev) => !prev);
        }}
        style={{
          backgroundColor: spec[`check_${obsNumber}_colors`][obsIndex],
          textAlign: "center",
          minWidth: "90px",
        }}
        isView={isView}
      />
    );
  }
  return <React.Fragment></React.Fragment>;
};
export default ObservationInput;
