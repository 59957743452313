import React from "react";
import Styled from "styled-components";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import PrintIcon from "@material-ui/icons/Print";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

import { useLocation, useHistory } from "react-router-dom";

import formatDate from "../../../../components/Control/formatDate";
import { Wrapper } from "../../../../components/Control/Flexbox";
import { useAuth } from "../../../../context/GlobalContext";
import { images, textInputs } from "../../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";
import { CenteredLogoImg } from "../../../../golbalStyles/global.styled";
import { mergerRowsFmea } from "./MergerRows.utils";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    

`;

const style = { border: "1px solid black", textAlign: "center" };

const PfmeaTableSheet = ({ fmeaData, partId, viewHeader }) => {
  // changing the fmea data
  mergerRowsFmea(fmeaData);

  const location = useLocation();
  const history = useHistory();
  const { state: states } = useAuth();
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  function deleteFmea(id) {
    if (window.confirm("Are you sure you want to delete this PFMEA?")) {
      axios
        .delete(`/api/delete/fmea/${id}`)
        .then(() => {
          history.goBack();
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <div className="divflex">
        {viewHeader ? (
          <>
            <Typography
              variant="inherit"
              style={{
                fontSize: "24px",
              }}
            >
              FAILURE MODE & EFFECT ANALYSIS
            </Typography>
            <Wrapper>
              <Tooltip arrow title="Print table">
                <PrintIcon
                  style={{
                    color: "white",
                    cursor: "pointer",
                    fontSize: "1.9rem",
                    marginRight: "0.6rem",
                  }}
                  onClick={printTable}
                />
              </Tooltip>
              <Tooltip arrow title="Export as spreadsheet">
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: `PFMEA - ${fmeaData?.part_id?.part_number}.xlsx`,
                        sheet: {
                          name: "fmeaSheet",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.3} />
                </Button>
              </Tooltip>
            </Wrapper>
          </>
        ) : (
          <>
            <Wrapper
              style={{
                position: "absolute",
                top: "0.2rem",
                right: "1rem",
                zIndex: "100000",
              }}
            >
              <Tooltip arrow title="Print table">
                <PrintIcon
                  style={{
                    color: "white",
                    cursor: "pointer",
                    fontSize: "1.9rem",
                    marginRight: "0.6rem",
                  }}
                  onClick={printTable}
                />
              </Tooltip>
              <Tooltip arrow title="Export as spreadsheet">
                <Button
                  style={{
                    color: "white",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: `PFMEA - ${fmeaData?.part_id?.part_number}.xlsx`,
                        sheet: {
                          name: "fmeaSheet",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </Tooltip>
            </Wrapper>
          </>
        )}
      </div>

      <div
        style={{
          margin: "0.1rem 0.5rem",
          paddingBottom: "4rem",
        }}
      >
        <section style={{ overflow: "scroll" }}>
          <Table
            cellSpacing="0"
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "100%",
            }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 15, 17, 19, 5, 7, 18, 18, 5, 18, 5, 5, 15, 15, 15, 4, 4, 4, 4"
          >
            <thead>
              <tr data-height="80" style={{ height: "100px" }}>
                <th
                  colSpan="19"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
              </tr>
              <tr>
                <th
                  colSpan={14}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  POTENTIAL FAILURE MODE AND EFFECTS ANALYSIS
                </th>
                <td
                  colSpan={5}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  ARC/F/QAD/13
                </td>
              </tr>
              <tr>
                <th
                  colSpan={14}
                  rowSpan={2}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  PROCESS FMEA
                </th>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Rev.No./Date:
                </th>
                <td
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  00/01-10-2017
                </td>
              </tr>
              <tr>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  PFMEA No.
                </th>
                <td
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {fmeaData?.pfmea_no}
                </td>
              </tr>
              <tr>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Item :-
                </th>
                <td
                  colSpan={4}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {fmeaData?.part_id?.part_name}
                </td>

                <th
                  colSpan={4}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Process Responsibility -
                </th>
                <td
                  colSpan={3}
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  <span>{fmeaData?.process_resp}</span>
                </td>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Rev. No.:
                </th>
                <td
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  01
                </td>
              </tr>
              <tr>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Operation :-
                </th>
                <td
                  colSpan={4}
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  <span>{fmeaData?.fmea_operation}</span>
                </td>

                <th
                  colSpan={4}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Part No.:
                </th>
                <td
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {fmeaData?.part_id?.part_number}
                </td>

                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Prep. By :
                </th>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                ></th>
              </tr>

              <tr>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Core Team :-
                </th>
                <td
                  colSpan={4}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {fmeaData?.part_id?.core_team}
                </td>
                <th
                  colSpan={4}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Key Date -
                </th>
                <th
                  colSpan={3}
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  <span>{fmeaData?.key_date}</span>
                </th>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  FMEA Rev.Date:
                </th>
                <td
                  colSpan={3}
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  {formatDate(fmeaData.revision_date)}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr style={{ fontWeight: "500" }} data-height="65">
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  PROCESS STEP / FUNCTION
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    // minWidth: "10rem",
                  }}
                  data-f-sz="10"
                >
                  REQUIREMENT
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "10rem",
                  }}
                  data-f-sz="10"
                >
                  POTENTIAL FAILURE MODE
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  POTENTIAL EFFECT(s) OF FAILURE
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Severity
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Classification
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  POTENTIAL CAUSE(S) OF FAILURE
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  CURRENT PROCESS CONTROLS (PREVENTION)
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Occurance
                </th>

                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    minWidth: "12rem",
                  }}
                  data-f-sz="10"
                >
                  CURRENT PROCESS CONTROLS (DETECTION)
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Detection
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  RPN
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  RECOMMENDED ACTION(S)
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  RESP. & TARGET COMPLETION DATE
                </th>
                <th
                  align="center"
                  colSpan={5}
                  style={{ border: "1px solid black", textAlign: "center" }}
                  data-f-sz="10"
                >
                  ACTION RESULTS
                </th>
              </tr>
              <tr>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    textAlign: "center",
                  }}
                  data-f-sz="10"
                >
                  Action(s) Taken & completion date
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Severity
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Occurance
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  Detection
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    transform: "rotate(-90deg)",
                  }}
                  data-f-sz="10"
                  data-a-text-rotation="90"
                >
                  RPN
                </th>
              </tr>
              {fmeaData.processes?.map((process) =>
                process?.fmeas?.map((fail, failInd) => (
                  <tr data-height="65">
                    {failInd === 0 && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        rowSpan={process.fmeas.length}
                        data-f-sz="10"
                      >
                        {process?.process_name}
                      </td>
                    )}
                    {fail.requirement_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.requirement_rowSpan}
                      >
                        {fail?.requirement}
                      </td>
                    )}
                    {fail.potential_mode_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.potential_mode_rowSpan}
                      >
                        {fail?.potential_mode}
                      </td>
                    )}
                    {fail.eof_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.eof_rowSpan}
                      >
                        {Array.isArray(fail?.eof) ? fail.eof[0] : fail.eof}
                      </td>
                    )}

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.severity}
                    </td>

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                      height={fail?.failure_class ? "auto" : "30px"}
                    >
                      {fail?.failure_class}
                    </td>
                    {fail.potential_cause_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail?.potential_cause_rowSpan}
                      >
                        {Array.isArray(fail?.potential_cause)
                          ? fail.potential_cause[0]
                          : fail.potential_cause}
                      </td>
                    )}
                    {fail.control_prevention_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail?.control_prevention_rowSpan}
                      >
                        {fail?.control_prevention}
                      </td>
                    )}
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.occ}
                    </td>

                    {fail.control_detection_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.control_detection_rowSpan}
                      >
                        {fail?.control_detection}
                      </td>
                    )}

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.det}
                    </td>

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.rpn}
                    </td>
                    {fail.recommendation_action_include && (
                      <td
                        align="center"
                        style={{ border: "1px solid black" }}
                        data-f-sz="10"
                        rowSpan={fail.recommendation_action_rowSpan}
                      >
                        {fail?.recommendation_action}
                      </td>
                    )}
                    <td align="center" data-f-sz="10">
                      {fail?.resp_target}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.action_taken}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_sev}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_occ}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_det}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {fail?.last_rpn}
                    </td>
                  </tr>
                ))
              )}
              <tr data-height="30">
                <th
                  align="center"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Criteria
                </th>
                <th
                  align="center"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Symbol
                </th>
                <th
                  align="center"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Rev.#
                </th>
                <th
                  align="center"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Description
                </th>
                <th
                  align="center"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Action
                </th>
                <th
                  align="center"
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Root Cause
                </th>
                <th
                  align="center"
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Rev. By.
                </th>
                <th
                  align="center"
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Date
                </th>
              </tr>
              <tr data-height="30">
                <td
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Critical
                </td>
                <td
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  C
                </td>
                <td
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {fmeaData.rev_critical}
                </td>
                <td
                  colSpan={7}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {fmeaData.desc_action_rootcause_critical}
                </td>
                <td
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {fmeaData.rev_by_critical}
                </td>
                <td
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {formatDate(fmeaData.date_critical)}
                </td>
              </tr>
              {/* Major */}
              <tr data-height="30">
                <td
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Major
                </td>
                <td
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  M
                </td>
                <td
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>{fmeaData.rev_major}</span>
                </td>
                <td
                  colSpan={7}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>{fmeaData.desc_action_rootcause_major}</span>
                </td>
                <td
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>{fmeaData.rev_by_major}</span>
                </td>
                <td
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>{formatDate(fmeaData.date_major)}</span>
                </td>
              </tr>
              {/* minor */}
              <tr data-height="25">
                <td
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Minor
                </td>
                <td
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  m
                </td>
                <td
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>{fmeaData.rev_minor}</span>
                </td>
                <td
                  colSpan={7}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>{fmeaData.desc_action_rootcause_minor}</span>
                </td>
                <td
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>{fmeaData.rev_by_minor}</span>
                </td>
                <td
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>{formatDate(fmeaData.date_minor)}</span>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr style={{ fontWeight: "bold" }}>
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision No.
                </td>
                <td
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision Date
                </td>
                <td
                  colSpan="9"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision Summary
                </td>
                <td
                  colSpan="5"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  Revision Reason
                </td>
              </tr>
              <tr style={{ height: "60px" }}></tr>
              {fmeaData?.revisionData.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr data-height="150">
                      <td
                        colSpan="2"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.rev_no}
                      </td>
                      <td
                        colSpan="3"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {formatDate(item.rev_date)}
                      </td>
                      <td
                        colSpan="9"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.summary}
                      </td>
                      <td
                        colSpan="5"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.reason}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
            <tbody>
              <tr style={{ height: "60px" }} data-height="30">
                <td
                  colSpan="4"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                  data-f-sz="10"
                >
                  Prepared By:
                </td>
                <td
                  colSpan="5"
                  align="left"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                  data-f-sz="10"
                >
                  {states.user.name}
                </td>

                <td
                  colSpan="4"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                  data-f-sz="10"
                >
                  Approved By:
                </td>
                <td
                  colSpan="6"
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                ></td>
              </tr>
            </tbody>
          </Table>
        </section>
      </div>
    </>
  );
};

export default PfmeaTableSheet;
