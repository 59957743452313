import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PfdTableSheet from "../../Pages/AddEditDuplicatePart/quickView/partPlanQuickView/PfdTableSheet";
import Loader from "../Control/Loader";
import axios from "axios";

function PfdDiagram() {
  const { id, partId } = useParams();
  // console.log(partId);
  const [data, setData] = useState("");
  useEffect(() => {
    axios
      .get(`/api/get/pfdPart/${partId}/${id}`)
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <div>{data ? <PfdTableSheet data={data} pfdId={id} /> : <Loader />}</div>
  );
}

export default PfdDiagram;
