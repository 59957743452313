const reasons = {
  man: [
    { name: "नये ऑपरेटर के लिए", value: "level1" },
    {
      name: "ऑपरेटर 30 दिन की छुट्टियों के बाद काम पर आता है",
      value: "level2",
    },

    { name: "ऑपरेटर जानकारी के साथ छुट्टी पर", value: "level3" },
    {
      name: "ऑपरेटर बिना जानकारी के छुट्टी पर (शिफ्ट की शुरुआत के दौरान)",
      value: "level4",
    },
    {
      name: "दुर्घटना / बीमारी के कारण ऑपरेटर अचानक कार्यस्थल छोड़ देता है",
      value: "level5",
    },
    {
      name: "ऑपरेटर लगातार डिफेक्टिव पार्ट का उत्पादन कर रहा है",
      value: "level6",
    },
  ],
  material: [
    {
      name: "मटेरियल किसी अन्य अनएप्रूव्ड सोर्स से आना जिस से पहले मटेरियल नहीं लिया गया है (ईसीएन के माध्यम से)",
      value: "level7",
    },
    {
      name: "मटेरियल अनएप्रूव्ड सोर्स से आना (ईसीएन के माध्यम से)",
      value: "level8",
    },
    {
      name: "निर्धारित मैटेरियल ग्रेड या साइज की जगह किसी दूसरे ग्रेड या साइज के मैटेरियल प्रयोग करना (ईसीएन के माध्यम से)",
      value: "level9",
    },
    {
      name: "निर्धारित मैटेरियल सप्लायर की जगह दुसरा सप्लायर या नया सप्लायर",
      value: "level10",
    },
    {
      name: "सप्लायर द्वारा मैटेरियल सप्लाई करने की जगह चेंज होने पर (ईसीएन के माध्यम से)",
      value: "level11",
    },
    {
      name: "शीट में क्रैक होना / जंग होना  तथा पिटेड होना",
      value: "level12",
    },
    {
      name: "रॉ मटेरियल का आकार परिवर्तन (ईसीएन के माध्यम से)",
      value: "level13",
    },
    {
      name: "रॉ मटेरियल ग्रेड परिवर्तन (ईसीएन के माध्यम से)",
      value: "level14",
    },
  ],
  method: [
    {
      name: "प्रक्रिया (Process) में परिवर्तन (ईसीएन के माध्यम से)",
      value: "level15",
    },
    {
      name: "डायमेंशनल परिवर्तन (ईसीएन के माध्यम से)",
      value: "level16",
    },
    {
      name: "ऑपरेटर वर्क प्रक्रिया (WI) के अनुसार काम नहीं कर रहा है",
      value: "level17",
    },
    {
      name: "मेजरिंग इक्यूपमेंट ब्रेकडाउन होने पर",
      value: "level18",
    },
    {
      name: "मशीन के प्रोसेस पैरामीटर चेंज होने पर (ईसीएन के माध्यम से)",
      value: "level19",
    },
    {
      name: "प्रोसेस का क्रम बदलने या नया प्रोसेस जोड़ने पर (ईसीएन के माध्यम से)",
      value: "level20",
    },
    {
      name: "मशीन की लोकेशन बदलने पर (ईसीएन के माध्यम से)",
      value: "level21",
    },
    {
      name: "इंस्पेक्शन मेथड या इंस्पेक्शन फ्रीक्वेंसी में बदलाब किया हो (हाइट गेज, माइक्रोमीटर, वर्नियर और गेज)",
      value: "level22",
    },
    {
      name: "पैकेजिंग स्टैण्डर्ड (बिन चेंज, बॉक्स चेंज & पॉली बैग चेंज) में बदलाब किया हो (ईसीएन के माध्यम से)",
      value: "level23",
    },
  ],
  machine: [
    {
      name: "मशीन प्रिवेंटिव मैंटीनैंस/ रिपेयर",
      value: "level24",
    },
    {
      name: "टूल प्रिवेंटिव मैंटीनैंस/ रिपेयर",
      value: "level25",
    },
    {
      name: "नई मशीन से प्रोडक्शन चलाने पर",
      value: "level26",
    },
    {
      name: "नए टूल से प्रोडक्शन चलाने पर",
      value: "level27",
    },
    {
      name: "टूल का ब्रेकडाउन होने पर",
      value: "level28",
    },
    {
      name: "मशीन का ब्रेकडाउन होने पर",
      value: "level29",
    },
    {
      name: "अचानक पावर कट होना",
      value: "level30",
    },
    {
      name: "मशीन लगातार डिफेक्टिव पार्ट बना रही है",
      value: "level31",
    },
    {
      name: "पोके योके डिफेक्टिव पार्ट नहीं पकड़ रहा है ",
      value: "level32",
    },
    ,
  ],
};

const actionTakens = {
  level1: [
    {
      name: "ऑपरेटर को ऑन जॉब ट्रेनिंग ऑपरेटर को WI  के अनुसार काम करने के लिए समझाना क्वालिटी इंजीनियर को ऑपरेटर चेंज के बारे में जानकारी देना चेंज अप्रूवल के बाद प्रोडक्शन स्टार्ट करना ऑपरेटर को सुपरवाइज़ करना",
      value: "action1",
    },
  ],
  level2: [
    {
      name: "ऑपरेटर को WI  के अनुसार काम करने के लिए समझाना क्वालिटी इंजीनियर को ऑपरेटर चेंज के बारे में जानकारी देना चेंज अप्रूवल के बाद प्रोडक्शन स्टार्ट करना ऑपरेटर को सुपरवाइज़ करना",
      value: "action1",
    },
  ],
  level3: [
    {
      name: "उसी कौशल (L 3 - L3) के ऑपरेटर को तैनात किया गया है जो मशीन पर काम कर रहा है",
      value: "action1",
    },
    {
      name: "कम कौशल (L3 - L2) के ऑपरेटर को तैनात किया गया",
      value: "action2",
    },
  ],
  level4: [
    {
      name: "ससमान स्किल लेबल वाले (L 3 - L3) के ऑपरेटर को तैनात किया गया है जो मशीन पर काम कर रहा है",
      value: "action1",
    },
    {
      name: "कम स्किल लेवल वाले (L3 - L2) के ऑपरेटर को तैनात किया गया",
      value: "action2",
    },
  ],
  level5: [
    {
      name: "समान स्किल लेबल वाले (L 3 - L3) के ऑपरेटर को तैनात किया गया है जो मशीन पर काम कर रहा है",
      value: "action1",
    },
    {
      name: "कम स्किल लेबल वाले (L3 - L2) के ऑपरेटर को तैनात किया गया",
      value: "action2",
    },
  ],
  level6: [
    {
      name: "ऑपरेटर को ट्रेनिंग दे | और प्रोडक्शन शुरू करें |",
      value: "action1",
    },
  ],

  level7: [
    {
      name: "इनकमिंग  निरीक्षण के बाद उत्पादन शुरू करें",
      value: "action1",
    },
  ],
  level8: [
    {
      name: "क्वालिटी हैड के द्वारा कस्टमर को अप्रूवल रिक्वेस्ट भेजना कस्टमर से अप्रूवल लेने के बाद ट्रायल्स करना इनिशियल सप्लाई प्रोसीजर को फॉलो करना ",
      value: "action1",
    },
  ],
  level9: [
    {
      name: "क्वालिटी हैड के द्वारा कस्टमर को अप्रूवल रिक्वेस्ट भेजना कस्टमर से अप्रूवल लेने के बाद ट्रायल्स करना इनिशियल सप्लाई प्रोसीजर को फॉलो करना",
      value: "action1",
    },
  ],
  level10: [
    {
      name: "क्वालिटी हैड के द्वारा कस्टमर को अप्रूवल रिक्वेस्ट भेजना कस्टमर से अप्रूवल लेने के बाद ट्रायल्स करना इनिशियल सप्लाई प्रोसीजर को फॉलो करना",
      value: "action1",
    },
  ],
  level11: [
    {
      name: "शीट को होल्ड करके रिजेक्ट का टैग लगाकर रेड टैग एरिया में भेज दें तथा सप्लायर को सूचित करें| रॉ मटेरियल स्टोर से नया मटेरियल लायें  और उत्पादन को पुनरारंभ करें",
      value: "action1",
    },
  ],
  level12: [
    {
      name: "इनकमिंग  निरीक्षण के बाद उत्पादन शुरू करें",
      value: "action1",
    },
  ],
  level13: [
    {
      name: "इनकमिंग  निरीक्षण के बाद उत्पादन शुरू करें",
      value: "action1",
    },
  ],
  level14: [
    {
      name: "शीट को होल्ड करके रिजेक्ट का टैग लगाकर रेड टैग एरिया में भेज दें तथा सप्लायर को सूचित करें| रॉ मटेरियल स्टोर से नया मटेरियल लायें  और उत्पादन को पुनरारंभ करें",
      value: "action1",
    },
  ],
  level15: [
    {
      name: "सभी संबंधित विभागों को सूचित करें",
      value: "action1",
    },
  ],
  level16: [
    {
      name: "सभी संबंधित विभागों को सूचित करें",
      value: "action1",
    },
  ],
  level17: [
    {
      name: "ऑपरेटर को वर्क इंस्ट्रक्शन के निर्देशानुसार काम को सीखना.",
      value: "action1",
    },
  ],
  level18: [
    {
      name: "क्वालिटी हैड को मेजरिंग इक्यूपमेंट ब्रेकडाउन की जानकारी देना अप्रूवल के बाद उसी प्रकार के कैलिब्रेटेड इक्यूपमेंट का प्रयोग करें थर्ड पार्टी से इक्यूपमेंट को ठीक कराएं और कैलिब्रेशन कराने के बाद उपयोग करें ",
      value: "action1",
    },
  ],
  level19: [
    {
      name: "क्वालिटी हैड के द्वारा कस्टमर को अप्रूवल रिक्वेस्ट भेजना कस्टमर से अप्रूवल लेने के बाद ट्रायल्स करना इनिशियल सप्लाई प्रोसीजर को फॉलो करना ",
      value: "action1",
    },
  ],
  level20: [
    {
      name: "क्वालिटी हैड के द्वारा कस्टमर को अप्रूवल रिक्वेस्ट भेजना कस्टमर से अप्रूवल लेने के बाद ट्रायल्स करना इनिशियल सप्लाई प्रोसीजर को फॉलो करना ",
      value: "action1",
    },
  ],
  level21: [
    {
      name: "क्वालिटी हैड के द्वारा कस्टमर को अप्रूवल रिक्वेस्ट भेजना कस्टमर से अप्रूवल लेने के बाद ट्रायल्स करना इनिशियल सप्लाई प्रोसीजर को फॉलो करना ",
      value: "action1",
    },
  ],
  level22: [
    {
      name: "क्वालिटी हैड के द्वारा कस्टमर को अप्रूवल रिक्वेस्ट भेजना कस्टमर अप्रूवल के बाद नए इंस्पेक्शन मेथड से चैक करें ",
      value: "action1",
    },
  ],
  level23: [
    {
      name: "क्वालिटी हैड के द्वारा कस्टमर को अप्रूवल रिक्वेस्ट भेजना कस्टमर अप्रूवल के बाद नए इंस्पेक्शन मेथड से चैक करें ",
      value: "action1",
    },
  ],
  level24: [
    {
      name: "प्रिवेंटिव मेंटेनन्स के बाद उसी मशीन पर उत्पादन करना",
      value: "action1",
    },
  ],
  level25: [
    {
      name: "सुधारात्मक कार्रवाई के बाद उसी टूल पर उत्पादन चलाना",
      value: "action1",
    },
  ],
  level26: [
    {
      name: "कस्टमर को अप्रूवल रिक्वेस्ट भेजना कस्टमर से अप्रूवल लेने के बाद ट्रायल्स करना",
      value: "action1",
    },
  ],
  level27: [
    {
      name: "कस्टमर को अप्रूवल रिक्वेस्ट भेजना कस्टमर से अप्रूवल लेने के बाद ट्रायल्स करना",
      value: "action1",
    },
  ],
  level28: [
    {
      name: "सुधारात्मक कार्रवाई के बाद उसी टूल्स से उत्पादन चलाना",
      value: "action1",
    },
  ],
  level29: [
    {
      name: "सुधारात्मक कार्रवाई के बाद उसी मशीन पर उत्पादन चलाना",
      value: "action1",
    },
  ],
  level30: [
    {
      name: "रीस्टार्ट मशीन",
      value: "action1",
    },
  ],
  level31: [
    {
      name: "सुधारात्मक कार्रवाई करें और मशीन पुनरारंभ करें",
      value: "action1",
    },
  ],
  level32: [
    {
      name: "सुधारात्मक कार्रवाई करें और मशीन पुनरारंभ करें",
      value: "action1",
    },
  ],
};

const actionTakenTickMarks = {
  level1action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेटअप अप्रूवल लेना है 
    (निरीक्षण 10 PCS).                          
    2. (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.                                                                                                                       
    3. ऑपरेटर को आन जॉब ट्रेनिंग देना है.`,
  },
  level2action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेटअप अप्रूवल लेना है 
    (निरीक्षण 10 PCS).                          
    2. (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.         
    3. ऑपरेटर को आन जॉब ट्रेनिंग देना है.`,
  },
  level3action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेटअप अप्रूवल लेना है 
    (निरीक्षण 10 PCS).`,
  },
  level3action2: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेटअप अप्रूवल लेना है 
    (निरीक्षण 10 PCS).
    2. (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level4action1: {
    unexpected: "अनप्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: ` 1. सेटअप अप्रूवल लेना है 
    (निरीक्षण 10 PCS).
    2. ऑपरेटर को आन जॉब ट्रेनिंग देना है.`,
  },
  level4action2: {
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेटअप अप्रूवल लेना है 
    (निरीक्षण 10 PCS).
    2. (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.
    3.  ऑपरेटर को आन जॉब ट्रेनिंग देना है.`,
  },
  level5action1: {
    unexpected: "असामान्य परिस्थिति",
    setup: true,
    change4m: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `1.  (Retro)  पिछली इंस्पेक्शन के बाद के मटेरियल का निरीक्षण 10 PCS & 100% विजुअल
    2. सेटअप अप्रूवल लेना है 
    (निरीक्षण 10 PCS).
    3.ऑपरेटर को आन जाब ट्रेनिंग देना है .`,
  },
  level5action2: {
    setup: true,
    change4m: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `1 . आन जॉब ट्रेनिंग देना
    2.  (Retro)  पिछली इंस्पेक्शन के बाद के मटेरियल का निरीक्षण 10 PCS & 100% विजुअल 
    3.  सेट-अप अप्रूवल लेना है (निरीक्षण 10 PCS)
    4.  (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level6action1: {
    unexpected: "असामान्य परिस्थिति",
    setup: true,
    change4m: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `1.  (Retro)  पिछली इंस्पेक्शन के बाद के मटेरियल का निरीक्षण 10 PCS & 100% विजुअल 
    2.  सेट-अप अप्रूवल लेना है (निरीक्षण 10 PCS)
    3.  (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level7action1: {
    unexpected: "अनप्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `1. इनकमिंग निरीक्षण आवश्यक है
    2. सेट-अप अप्रूवल लेना है 
    (निरीक्षण 10 PCS & रिकॉर्डिंग 10 PCS)`,
  },
  level8action1: {
    unexpected: "असामान्य परिस्थिति",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `1.  इनकमिंग निरीक्षण आवश्यक है
    2. सेट-अप अप्रूवल लेना है 
    (निरीक्षण 10 PCS & रिकॉर्डिंग 10 PCS)
    3. (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level9action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेट-अप अप्रूवल लेना है 
    (निरीक्षण 10 PCS & रिकॉर्डिंग 10 PCS)
     2 PPAP करना और इनिशियल सप्लाई
     प्रोसइजर को फॉलो करना `,
  },
  level10action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेट-अप अप्रूवल लेना है 
    (निरीक्षण 10 PCS & रिकॉर्डिंग 10 PCS)
     2 PPAP करना और इनिशियल सप्लाई
     प्रोसइजर को फॉलो करना `,
  },
  level11action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेट-अप अप्रूवल लेना है 
    (निरीक्षण 10 PCS & रिकॉर्डिंग 10 PCS)
     2 PPAP करना और इनिशियल सप्लाई
     प्रोसइजर को फॉलो करना `,
  },
  level12action1: {
    expected: "असामान्य परिस्थिति",
    setup: true,
    change4m: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `1.  (Retro)  पिछली इंस्पेक्शन के बाद के मटेरियल का निरीक्षण 10 PCS & 100% विजुअल (रिकॉर्डिंग 5 pcs) 
    2.  सेट-अप अप्रूवल लेना है (निरीक्षण 10 PCS)
    3.  (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level13action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `1. कस्टमर से अप्रूवल  आवश्यक है
    2. सेट-अप अप्रूवल लेना है 
    (निरीक्षण 10 PCS & रिकॉर्डिंग 10 PCS)`,
  },
  level14action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `1. कस्टमर से अप्रूवल  आवश्यक है
    2. सेट-अप अप्रूवल लेना है 
    (निरीक्षण 10 PCS & रिकॉर्डिंग 10 PCS)`,
  },
  level15action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `इनिशियल सप्लाई प्रोसइजर को फॉलो करना`,
  },
  level16action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `इनिशियल सप्लाई प्रोसइजर को फॉलो करना`,
  },
  level17action1: {
    unexpected: "असामान्य परिस्थिति",
    setup: true,
    change4m: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `1.  (Retro)  पिछली इंस्पेक्शन के बाद के मटेरियल का निरीक्षण 10 PCS & 100% विजुअल
    2.  सेट-अप अप्रूवल लेना है (निरीक्षण 10 PCS)
    3.  (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level18action1: {
    unexpected: "अनप्लांड",
    setup: false,
    training: false,
    retro: true,
    suspected: true,
    procedure: `1. उस इंस्ट्रूमेंट से चेक किये गये सभी पार्ट को दूसरे कैलिब्रेटेड इंस्ट्रूमेंट से चेक करे (निरीक्षण 10 PCS)
    2. यदि कोई पार्ट रिजेक्ट नहीं मिलता है तो बाकी पार्टों को चैक करने की जरुरत नहीं है! 
    3. यदि कोई पार्ट रिजेक्ट मिलता है तो 100%  इंस्पेक्शन कराएं `,
  },
  level19action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `इनिशियल सप्लाई प्रोसइजर को फॉलो करना`,
  },
  level20action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `इनिशियल सप्लाई प्रोसइजर को फॉलो करना`,
  },
  level21action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `इनिशियल सप्लाई प्रोसइजर को फॉलो करना`,
  },
  level22action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `इनिशियल सप्लाई प्रोसइजर को फॉलो करना`,
  },
  level23action1: {
    expected: "प्लांड",
    setup: false,
    training: false,
    retro: false,
    suspected: true,
    procedure: `इनिशियल सप्लाई प्रोसइजर को फॉलो करना`,
  },
  level24action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेटअप अप्रूवल लेना है (निरीक्षण 10 PCS).
    2. (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level25action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेटअप अप्रूवल लेना है (निरीक्षण 10 PCS).
    2. (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level26action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेटअप अप्रूवल लेना है (निरीक्षण 10 PCS).
    2. (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level27action1: {
    expected: "प्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. सेटअप अप्रूवल लेना है (निरीक्षण 10 PCS).
    2. (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level28action1: {
    unexpected: "अनप्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `1.  (Retro)  पिछली इंस्पेक्शन के बाद के मटेरियल का निरीक्षण 10 PCS & 100% विजुअल 
    2.  सेट-अप अप्रूवल लेना है (निरीक्षण 10 PCS)
    3.  (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level29action1: {
    unexpected: "अनप्लांड",
    setup: true,
    change4m: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `1.  (Retro)  पिछली इंस्पेक्शन के बाद के मटेरियल का निरीक्षण 10 PCS & 100% विजुअल 
    2.  सेट-अप अप्रूवल लेना है (निरीक्षण 10 PCS)
    3.  (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level30action1: {
    unexpected: "असामान्य परिस्थिति",
    setup: true,
    change4m: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `1. मशीन पर लोड हुआ पार्ट चेक करना है | यदि पार्ट रिजेक्ट हो तो Scrap करना है |`,
  },
  level31action1: {
    unexpected: "असामान्य परिस्थिति",
    setup: true,
    change4m: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `1.  (Retro)  पिछली इंस्पेक्शन के बाद के मटेरियल का निरीक्षण 10 PCS & 100% विजुअल  
    2.  सेट-अप अप्रूवल लेना है (निरीक्षण 10 PCS)
    3.  (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में.`,
  },
  level32action1: {
    unexpected: "असामान्य परिस्थिति",
    setup: true,
    change4m: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `1.  (Retro)  पिछली इंस्पेक्शन के बाद के मटेरियल का निरीक्षण 10 PCS & 100% विजुअल  
    2.  सेट-अप अप्रूवल लेना है (निरीक्षण 10 PCS)
    3.  (Suspected) निरीक्षण 4 PCS  मध्य एवं 4 PCS एंड में`,
  },
};
export { reasons, actionTakenTickMarks, actionTakens };
