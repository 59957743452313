import { Button, Paper, Tab, Tabs } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Wrapper } from "../../../components/Control/Flexbox";
import useConfirm from "../../../components/Control/useConfirm";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import { apqpConstant } from "./constant";
import ApqpMatrixTable from "./ApqpMatrixTable";
import axios from "axios";
import lodash from "lodash";
import { useQuery } from "../../../components/Control/useQuery";

const tabData = [
  { phase: "phase_1", title: "Phase – 1 : Plan & Define" },
  { phase: "phase_2", title: "Phase – 2 : Product Development" },
  { phase: "phase_3", title: "Phase – 3 : Process Design & Development" },
  { phase: "phase_4", title: "Phase – 4 : Product & Process Validation" },
  {
    phase: "phase_5",
    title: "Phase – 5 : Feedback Assessment & Corrective Action",
  },
];

const CreateEditApqpMatrix = () => {
  const { partId } = useParams();
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [render, setRender] = useState(false);
  const query = useQuery();
  const isView = query.get("isView") === "false" ? false : true;
  const [apqpData, setApqpData] = useState({
    phase_1: [],
    phase_2: [],
    phase_3: [],
    phase_4: [],
    phase_5: [],
  });
  useEffect(() => {
    axios.get(`/api/getApqpMatrixById/${partId}`).then((res) => {
      if (res.data?.msg === "not_found") {
        setApqpData(lodash.cloneDeep(apqpConstant));
        return;
      }
      setApqpData(res.data);
    });
  }, [render]);

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );

  const [DialogSubmit, confirmSubmit] = useConfirm(
    "SUBMIT",
    `Are you sure you want to ${apqpData._id ? "update" : "save"}?`
  );

  const onSubmit = async () => {
    let isSubmit = await confirmSubmit();
    if (!isSubmit) return;
    apqpData.part_id = partId;

    if (apqpData._id) {
      axios
        .put(`/api/UpdateApqpMatrix/${apqpData._id}`, apqpData)
        .then((res) => {
          if (res.data) alert("updated successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(`/api/CreateApqpMatrix`, apqpData)
        .then((res) => {
          if (res.data) {
            alert("updated successfully");
            setRender((prev) => !prev);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChange = (e, phase, index) => {
    const { value, name } = e.target;
    const values = { ...apqpData };
    values[phase][index][name] = value;
    setApqpData(values);
  };
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", justifyContent: "space-between" }}
      >
        APQP MATRIX
        <Wrapper>
          <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            <Wrapper
              style={{
                gap: "1rem",
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#064a29",
                  marginRight: "0.5rem",
                }}
                startIcon={<CloudUploadIcon />}
                onClick={() => onSubmit()}
              >
                Submit
              </Button>
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (ans) {
                    history.goBack();
                  }
                }}
              />
            </Wrapper>
          </div>
        </Wrapper>
      </div>
      <div>
        <DialogSubmit isSubmitContent={true} />
      </div>
      <Paper elevation={2} style={{ margin: "1rem 0.5rem 0rem 0.5rem" }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          textColor="secondary"
        >
          <Tab label={"Phase – 1"} style={{ alignItems: "flex-start" }} />
          <Tab label={"Phase – 2"} style={{ alignItems: "flex-start" }} />
          <Tab label={"Phase – 3"} style={{ alignItems: "flex-start" }} />
          <Tab label={"Phase – 4"} style={{ alignItems: "flex-start" }} />
          <Tab label={"Phase – 5"} style={{ alignItems: "flex-start" }} />
        </Tabs>
      </Paper>

      <ApqpMatrixTable
        {...{ apqpData, handleChange, isView }}
        phase={tabData[tabValue].phase}
        title={tabData[tabValue].title}
      />

      <DialogClose isSubmitContent={false} />
    </>
  );
};

export default CreateEditApqpMatrix;
