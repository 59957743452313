import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Wrapper } from "../../../components/Control/Flexbox";
import { images, textInputs } from "../../../constants/constants";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../../components/Control/useConfirm";
import {
  ButtonStyled,
  TableContainerStyled,
} from "../../DailyProduction/styles";
import { useQuery } from "../../../components/Control/useQuery";
import formatDate from "../../../components/Control/formatDate";
import { processs } from "./checklistSupplierAudit";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        height: 50px;
        text-align:center;
    }

`;
const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const allTotal = {
  "HR -Department": {
    points: 0,
    scores: 0,
  },
  "Purchase - Department": {
    points: 0,
    scores: 0,
  },
  "Parts Storage Incoming & Outgoing": {
    points: 0,
    scores: 0,
  },
  Production: {
    points: 0,
    scores: 0,
  },
  "Maintenance-Department": {
    points: 0,
    scores: 0,
  },
  "QC-Department": {
    points: 0,
    scores: 0,
  },
  totalPoints: 0,
  totalScores: 0,
  TotalPoints: function () {
    return (
      +this["HR -Department"].points ||
      0 + +this["Purchase - Department"].points ||
      0 + +this["Parts Storage Incoming & Outgoing"].points ||
      0 + +this["Production"].points ||
      0 + +this["Maintenance-Department"].points ||
      0 + +this["QC-Department"].points ||
      0
    );
  },
  TotalScores: function () {
    return (
      +this["HR -Department"].scores ||
      0 + +this["Purchase - Department"].scores ||
      0 + +this["Parts Storage Incoming & Outgoing"].scores ||
      0 + +this["Production"].scores ||
      0 + +this["Maintenance-Department"].scores ||
      0 + +this["QC-Department"].scores ||
      0
    );
  },

  passOrFailForQualityDepartment: function () {
    const percentage =
      (this["QC-Department"].scores / (+this["QC-Department"].points || 1)) *
      100;
    if (percentage > 65.1) {
      return "PASS ( ✓ )    FAIL(  )";
    }
    return "PASS ( )     FAIL( ✓ )";
  },
  passOrFailForAll: function () {
    const percentage =
      (this["totalScores"] / (+this["totalPoints"] || 1)) * 100;
    if (percentage > 60.1) {
      return "PASS ( ✓ )    FAIL(  )";
    }
    return "PASS ( )     FAIL( ✓ )";
  },
};
const getScoreTotal = (array, departmentKey) => {
  if (!departmentKey) return;
  let scores = 0;
  array.map((value) => {
    scores += +value?.score || 0;
  });
  allTotal[departmentKey].scores = scores;
  return scores;
};

const EditViewChecklistSupplierAudit = () => {
  const classes = dropdownStyles();
  const { auditId, id } = useParams();
  const [render, setRender] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const supplier = query.get("supplier");
  const [checklistData, setChecklistdata] = useState({
    // processs: new Array(6).fill().map((_) => []),
    processs: processs,
    isCompleted: false,
  });
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to Submit?"
  );
  const handleAllC_NCValue = (checklistData) => {
    // console.log("call");
    const isCChecked = [];
    checklistData?.processs?.map(({ department_wise_data }) => {
      const isC = department_wise_data?.every(
        (item) => item?.status_c_nc === "c"
      );
      isCChecked.push(isC);
    });
    if (isCChecked.every((el) => el === true)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/getOneSupplierAuditChecklist/${id}`)
      .then((res) => {
        setChecklistdata(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmUpdate();
      if (ans) {
        checklistData.isSubmitted = bool;
        axios
          .put(`/api/updateSupplierAuditChecklist/${id}`, checklistData)
          .then((res) => {
            if (res.data.status === "success") {
              alert("updated successfully");
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        checklistData.isSubmitted = bool;
        axios
          .post(`/api/createSupplierAuditChecklist/${auditId}`, checklistData)
          .then((res) => {
            if (res.data.status === "success") {
              alert("created successfully");
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 80;
    } else {
      document.getElementById("machinetable").scrollLeft -= 80;
    }
  };
  const handleChangeChecklistAudit = (
    e,
    processesIndex,
    departmentWiseIndex
  ) => {
    const { name, value } = e.target;
    checklistData.processs[processesIndex].department_wise_data[
      departmentWiseIndex
    ][name] = value;
    setRender((prev) => !prev);
  };

  return (
    <section>
      <div
        style={{
          paddingBottom: "4rem",
        }}
      >
        <ButtonStyled
          left
          onClick={(e) => {
            handleScroll("left");
          }}
        >
          <div>
            <ArrowBackIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <ButtonStyled
          right
          onClick={(e) => {
            handleScroll("right");
          }}
        >
          <div>
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
              textTransform: "uppercase",
            }}
          >
            Supplier Audit Check Sheet
          </Typography>
          {!isView && (
            <div style={{ display: "flex" }}>
              <Wrapper
                style={{
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  style={{
                    border: "1px solid black",
                    marginTop: "0.5rem",
                    width: "7rem",
                    backgroundColor: "#064a29",
                  }}
                  control={
                    <Checkbox
                      checked={checklistData?.isCompleted}
                      // key="isCompleted"
                      name="isCompleted"
                      onChange={(e) => {
                        setChecklistdata((prev) => ({
                          ...prev,
                          isCompleted: e.target.checked,
                        }));
                      }}
                      disabled={!handleAllC_NCValue(checklistData)}
                      color="primary"
                    />
                  }
                  label="Done"
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#064a29",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    handleSubmitAndSave(true);
                  }}
                >
                  Save
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            </div>
          )}
        </div>
        <TableContainerStyled id="machinetable">
          <Table
            cellSpacing="0"
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "100%",
            }}
          >
            <thead>
              <tr style={{ height: "70px" }}>
                <th
                  colSpan="2"
                  rowSpan={2}
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <div>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                  </div>
                </th>
                <th
                  colSpan="4"
                  rowSpan={2}
                  align="center"
                  style={{
                    fontSize: "1rem",
                    fontWeight: 600,
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Supplier Audit Check Sheet
                </th>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  DOC NO :
                </th>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  ARC/F/PUR/09
                </th>
              </tr>
              <tr>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Rev. No./Date:
                </th>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  01/02-07-2019
                </th>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  Supplier Name:
                </th>
                <th colSpan={2}>{supplier}</th>
                <th>Location : </th>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  {isView ? (
                    checklistData.location
                  ) : (
                    <input
                      type="text"
                      name="location"
                      value={checklistData.location}
                      autoComplete="off"
                      className="input"
                      style={{ border: "2px solid #f9e0e0" }}
                      onChange={(e) => {
                        setChecklistdata((prev) => ({
                          ...prev,
                          location: e.target.value,
                        }));
                      }}
                    />
                  )}
                </th>
                <th>Date :</th>
                <th>
                  {isView ? (
                    formatDate(checklistData.checklist_date)
                  ) : (
                    <DatePicker
                      name="date_performance"
                      format="dd/MM/yyyy"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        backgroundColor: "#F8DE7E",
                        paddingLeft: "25px",
                        width: "150px",
                      }}
                      value={checklistData.checklist_date}
                      onChange={(date) => {
                        checklistData.checklist_date = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "Department",
                  " ",
                  "Check–Point",
                  "Point",
                  "Score",
                  "Audit Comments",
                  "C / NC",
                  "Remarks",
                ].map((heading, index) => (
                  <th
                    key={index + 1.0001}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
              {checklistData &&
                checklistData?.processs?.map((process, index) => {
                  return process.department_wise_data.map((item, idx) => (
                    <React.Fragment key={idx + index + "data"}>
                      <tr>
                        {idx === 0 && (
                          <th
                            rowSpan={process.department_wise_data.length}
                            style={{ textAlign: "center" }}
                          >
                            {process.department_name}
                          </th>
                        )}
                        <th style={{ textAlign: "center" }}>{idx + 1}</th>
                        <td style={{ width: "20rem", minWidth: "20rem" }}>
                          {item.check_point}
                        </td>
                        <td style={{ width: "10rem", textAlign: "center" }}>
                          {isView ? (
                            item?.point || 2
                          ) : (
                            <input
                              type="number"
                              name="point"
                              value={item?.point || 2}
                              disabled={true}
                              autoComplete="off"
                              className="input"
                              style={{ border: "2px solid #f9e0e0" }}
                              onChange={(e) => {
                                handleChangeChecklistAudit(e, index, idx);
                              }}
                            />
                          )}
                        </td>
                        <td style={{ width: "10rem", textAlign: "center" }}>
                          {isView ? (
                            item?.score
                          ) : (
                            <input
                              type="number"
                              name="score"
                              value={item?.score}
                              autoComplete="off"
                              className="input"
                              style={{ border: "2px solid #f9e0e0" }}
                              onChange={(e) => {
                                handleChangeChecklistAudit(e, index, idx);
                              }}
                              onWheel={() => document.activeElement.blur()}
                            />
                          )}
                        </td>

                        <td style={{ width: "25rem", minWidth: "25rem" }}>
                          {isView ? (
                            item?.audit_comment
                          ) : (
                            <input
                              type="text"
                              name="audit_comment"
                              value={item?.audit_comment}
                              autoComplete="off"
                              className="input"
                              style={{ border: "2px solid #f9e0e0" }}
                              onChange={(e) => {
                                handleChangeChecklistAudit(e, index, idx);
                              }}
                            />
                          )}
                        </td>
                        <td
                          style={{ width: "5rem", textTransform: "uppercase" }}
                        >
                          {isView ? (
                            item?.status_c_nc
                          ) : (
                            <FormControl
                              style={{
                                width: "150px",
                                border: "none",
                              }}
                              fullWidth={true}
                              variant="filled"
                              classes={{
                                root: classes.root,
                              }}
                            >
                              <InputLabel>Select C / NC</InputLabel>
                              <Select
                                value={item?.status_c_nc || ""}
                                name="status_c_nc"
                                classes={{
                                  root: classes.root,
                                  select: classes.select,
                                }}
                                // key={autocompleteData.department_id}
                                style={{ fontSize: "0.8rem" }}
                                disableUnderline
                                onChange={(e) => {
                                  handleChangeChecklistAudit(e, index, idx);
                                  // const isCheckboxAbled =
                                  //   handleAllC_NCValue(checklistData);
                                  // setCheckboxButtonDisabled(isCheckboxAbled);
                                }}
                              >
                                <MenuItem value="">
                                  <em>None</em>
                                </MenuItem>
                                <MenuItem value="c">C</MenuItem>
                                <MenuItem value="nc">NC</MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </td>
                        <td style={{ width: "10rem" }}>
                          {isView ? (
                            item?.remarks
                          ) : (
                            <input
                              type="text"
                              name="remarks"
                              value={item?.remarks}
                              autoComplete="off"
                              className="input"
                              style={{ border: "2px solid #f9e0e0" }}
                              onChange={(e) => {
                                handleChangeChecklistAudit(e, index, idx);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                      {idx === process.department_wise_data.length - 1 && (
                        <tr style={{ textAlign: "center" }}>
                          <td></td>
                          <td></td>
                          <th style={{ textAlign: "center" }}>Total Points</th>
                          <td>
                            {/* {process.department_wise_data.length * 2} */}
                            {
                              (allTotal[process.department_name].points =
                                process.department_wise_data.length * 2)
                            }
                          </td>
                          <td>
                            {getScoreTotal(
                              process.department_wise_data,
                              process.department_name
                            )}
                            {/* {process.department_name} */}
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </React.Fragment>
                  ));
                })}
            </tbody>
            <tfoot>
              <tr style={{ height: "50px" }}>{/* <td colSpan={8}></td> */}</tr>
              <tr>
                <th rowSpan={4} colSpan={2}>
                  NOTE :- Audit marks should be given as per below table:
                </th>
                <th colSpan={3} style={{ textAlign: "center" }}>
                  Score Obtained
                </th>
                <th colSpan={3} style={{ textAlign: "center" }}>
                  Net Result
                </th>
              </tr>
              <tr>
                <th>HR -Department</th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["HR -Department"].points}
                </th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["HR -Department"].scores}
                </th>

                <th colSpan={3} rowSpan={7} style={{ textAlign: "center" }}>
                  {isView ? (
                    checklistData?.net_result
                  ) : (
                    <input
                      type="text"
                      name="net_result"
                      value={checklistData?.net_result}
                      autoComplete="off"
                      className="input"
                      style={{ border: "2px solid #f9e0e0", height: "200px" }}
                      onChange={(e) => {
                        setChecklistdata((prev) => ({
                          ...prev,
                          net_result: e.target.value,
                        }));
                      }}
                    />
                  )}
                </th>
              </tr>
              <tr>
                <th>Purchase - Department</th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["Purchase - Department"].points}
                </th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["Purchase - Department"].scores}
                </th>
              </tr>
              <tr>
                <th>Parts Storage Incoming & Outgoing</th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["Parts Storage Incoming & Outgoing"].points}
                </th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["Parts Storage Incoming & Outgoing"].scores}
                </th>
              </tr>
              <tr>
                <th>Does not Exists </th>
                <th style={{ textAlign: "center" }}>0</th>
                <th>Production</th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["Production"].points}
                </th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["Production"].scores}
                </th>
              </tr>
              <tr>
                <th>Exists but not followed</th>
                <th style={{ textAlign: "center" }}>1</th>
                <th>Maintenance-Department</th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["Maintenance-Department"].points}
                </th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["Maintenance-Department"].scores}
                </th>
              </tr>
              <tr>
                <th>Followed</th>
                <th style={{ textAlign: "center" }}>2</th>
                <th>QC-Department</th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["QC-Department"].points}
                </th>
                <th style={{ textAlign: "center" }}>
                  {allTotal["QC-Department"].scores}
                </th>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <th style={{ textAlign: "center" }}>Total Points</th>
                <th style={{ textAlign: "center" }}>
                  {(allTotal.totalPoints = allTotal?.TotalPoints())}
                </th>
                <th style={{ textAlign: "center" }}>
                  {(allTotal.totalScores = allTotal?.TotalScores())}
                </th>
              </tr>
              <tr style={{ height: "20px" }}></tr>
              <tr>
                <th style={{ textAlign: "center" }} colSpan={3}>
                  Quality Passing Criteria as per below table:
                </th>
                <th style={{ textAlign: "center" }} colSpan={5}>
                  Result for Quality Department
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>0% – 40%</th>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  Not Approved
                </td>
                <td
                  colSpan={2}
                  rowSpan={3}
                  style={{ textAlign: "center" }}
                ></td>
                <th colSpan={3} rowSpan={3} style={{ textAlign: "center" }}>
                  {allTotal?.passOrFailForQualityDepartment()}
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>40.1% – 65%</th>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  Follow–up
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>{">"}65.1%</th>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  Approved
                </td>
              </tr>
              <tr style={{ height: "20px" }}></tr>
              <tr>
                <th style={{ textAlign: "center" }} colSpan={3}>
                  Overall Passing Criteria as per below table:
                </th>
                <th style={{ textAlign: "center" }} colSpan={5}>
                  Overall Result
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>0% – 40%</th>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  Not Approved
                </td>
                <td
                  colSpan={2}
                  rowSpan={3}
                  style={{ textAlign: "center" }}
                ></td>
                <th colSpan={3} rowSpan={3} style={{ textAlign: "center" }}>
                  {allTotal?.passOrFailForAll()}
                </th>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>40.1% – 60%</th>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  Follow–up
                </td>
              </tr>
              <tr>
                <th style={{ textAlign: "center" }}>{">"}60.1%</th>
                <td colSpan={2} style={{ textAlign: "center" }}>
                  Approved
                </td>
              </tr>
            </tfoot>
          </Table>
        </TableContainerStyled>
      </div>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </section>
  );
};

export default EditViewChecklistSupplierAudit;
