import React, { useState } from "react";
import { Wrapper } from "../../components/Control/Flexbox";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import IncomingListForm from "./IncomingListForm";

const IncomingSpecs = ({ incomingData }) => {
  const [isIncoming, setIsIncoming] = useState([]);

  const hideIncoming = (index) => {
    setIsIncoming((prev) => [...prev, index]);
  };

  const openIncoming = (index) => {
    const values = [...isIncoming];
    const idx = values.indexOf(index);
    values.splice(idx, 1);
    setIsIncoming(values);
  };

  return (
    <div style={{ margin: "1rem 0.6rem", paddingBottom: "4rem" }}>
      {incomingData.incomingList.map((incoming, index) => (
        <div key={index} style={{ marginTop: "0.1rem" }}>
          <main style={{ border: "1px solid #f0f2f0" }}>
            <Wrapper
              style={{
                background: "#fafafa",
              }}
              justify="space-between"
            >
              <Wrapper>
                <Wrapper
                  style={{
                    fontSize: "0.9rem",
                    height: "45px",
                    width: "45px",
                    fontWeight: 500,
                    color: "white",
                    backgroundColor: "#1d1d1d",
                  }}
                  justify="center"
                >
                  {index + 1}
                </Wrapper>
                <Wrapper
                  justify="start"
                  style={{
                    marginLeft: "1.3rem",
                    fontSize: "0.9rem",
                    textTransform: "uppercase",
                    width: "300px",
                  }}
                >
                  {incoming.incoming_parameter}
                </Wrapper>
                <Wrapper
                  justify="center"
                  style={{
                    marginLeft: "1.3rem",
                    fontSize: "0.9rem",
                    textTransform: "uppercase",
                  }}
                >
                  {incoming.incoming_specification}
                </Wrapper>
              </Wrapper>
              <Wrapper>
                {isIncoming.includes(index) ? (
                  <Wrapper
                    style={{
                      height: "45px",
                      width: "50px",
                      backgroundColor: "#3f51b5",
                      cursor: "pointer",
                      color: "white",
                    }}
                    justify="center"
                    onClick={() => openIncoming(index)}
                  >
                    <ExpandLess />
                  </Wrapper>
                ) : (
                  <Wrapper
                    style={{
                      height: "45px",
                      width: "50px",
                      backgroundColor: "#3f51b5",
                      cursor: "pointer",
                      color: "white",
                    }}
                    justify="center"
                    onClick={() => hideIncoming(index)}
                  >
                    <ExpandMore />
                  </Wrapper>
                )}
              </Wrapper>
            </Wrapper>
            {isIncoming.includes(index) && (
              <section>
                <IncomingListForm {...{ index, incomingData, incoming }} />
              </section>
            )}
          </main>
        </div>
      ))}
    </div>
  );
};

export default IncomingSpecs;
