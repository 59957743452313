import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SelectDepartment = ({ checklistId, auditId, auditDate, shift }) => {
  const [department, setDepartment] = useState("None");
  const history = useHistory();
  const departmentList = [
    "None",
    "sales_and_marketing",
    "NPD",
    "MR",
    "MNT_machine",
    "purch",
    "HR",
    "production",
    "QA",
    "CSR",
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <FormControl
        style={{
          width: "250px",
          marginRight: "0.5rem",
          marginTop: "0.6rem",
          marginLeft: "0.6rem",
          textTransform: "uppercase",
        }}
        variant="filled"
        className="select-field"
      >
        <InputLabel>Select Department</InputLabel>
        <Select
          name="department"
          key={department}
          defaultValue={department}
          onChange={(e) => {
            setDepartment(e.target.value);
          }}
          className="spec-select-character"
        >
          {departmentList.map((department, index) => {
            return (
              <MenuItem
                key={index}
                value={department}
                style={{ textTransform: "uppercase" }}
              >
                {department.replaceAll("_", " ")}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button
        type="submit"
        variant="outlined"
        // color="white"
        size="large"
        style={{
          backgroundColor: "green",
          height: "50px",
          marginTop: "0.5rem",
        }}
        // startIcon={<PublishIcon />}
        onClick={() => {
          history.push(
            `/addEditViewInternalAuditChecklist/${auditId}${checklistId}?departmentType=${department}?auditDate=${auditDate}&shift=${shift}`
          );
        }}
      >
        OK
      </Button>
    </div>
  );
};

export default SelectDepartment;
