import Styled from "styled-components";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Wrapper } from "../Control/Flexbox";
import Button from "@material-ui/core/Button";
import "../../style/fmea.css";
import Input from "../Control/Input.jsx";
import { DatePicker } from "@material-ui/pickers";

function SPC() {
  const [Data, setData] = useState("");
  const [specName, setSpecName] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [processName, setProcessName] = useState("");
  const [spec, setSpec] = useState([]);
  const { partId } = useParams();
  // console.log(partNumber);
  const [error, setError] = useState(null);

  useEffect(() => {
    // const token = localStorage.getItem("token");
    axios.get(`/api/get/controlPlanPart/${partId}`).then((data) => {
      setData(data.data);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // const token = localStorage.getItem("token");
    // let newFromDate = new Date(fromDate).toISOString();
    // let newToDate = new Date(fromDate).toISOString();

    axios
      .get(
        `/api/get/spcGoogleapi?partName=${partId}&processName=${processName}&spec=${spec[specName]}&fromDate=${fromDate}&toDate=${toDate}`
      )
      .then((res) => {
        if (res.data.kind) {
          setError("Please select a valid date.");
          return;
        }

        if (res.data.message)
          setError("Minimum 100 readings required to generate SPC");
        else setError(null);
      })
      .catch(function (error) {
        if (error.response) {
          setError(
            "Select a valid date/process/characteristic to generate SPC"
          );
        }
      });
  }, [fromDate, toDate, specName]);

  const handleProcessName = (e) => {
    const process_name = e.target.value;
    setProcessName(process_name);
  };

  const handleSpecName = (e) => {
    const spec_name = e.target.value;
    setSpecName(spec_name);
  };

  useEffect(() => {
    const temp = [];
    var filterProc;

    if (processName) {
      filterProc = Data.processes.filter(
        (process) => process.process_name === processName
      );
      filterProc[0].specs.forEach((val) => {
        temp.push(val.char_product);
      });
    }
    setSpec(temp);
  }, [processName]);

  return (
    <>
      {Data && (
        <div>
          <>
            <div>
              <div
                className="divflex"
                style={{
                  fontSize: "24px",
                }}
              >
                STATISTICAL PROCESS CONTROL STUDY
              </div>
              <div className="subheading-container">
                <p className="subheading-primary">
                  Generate{" "}
                  <span className="subheading-secondary">SPC Reports</span>
                </p>
              </div>
              <Wrapper style={{ gap: "1rem", margin: "1rem" }}>
                <div>
                  <DatePicker
                    value={fromDate || null}
                    label="From"
                    className="date-field"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    onChange={setFromDate}
                  />
                </div>
                <div>
                  <DatePicker
                    value={toDate || null}
                    label="To"
                    className="date-field"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    onChange={setToDate}
                  />
                </div>

                <div>
                  <FormControl
                    variant="filled"
                    className="select-field"
                    label="Process Name"
                    style={{ width: "300px" }}
                  >
                    <InputLabel>Select process</InputLabel>
                    <Select
                      value={processName}
                      onChange={(e) => handleProcessName(e)}
                    >
                      {Data.processes.map((proc) => (
                        <MenuItem value={proc.process_name} key={proc._id}>
                          {proc.process_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div>
                  <FormControl
                    variant="filled"
                    style={{ width: "300px", marginLeft: "1rem" }}
                    className="select-field"
                  >
                    <InputLabel>Select Charactersistic</InputLabel>
                    <Select
                      value={specName}
                      onChange={(e) => handleSpecName(e)}
                    >
                      {spec.map((val, index) => (
                        <MenuItem value={index}>{val}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Wrapper>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "4rem",
                fontSize: "16px",
              }}
            >
              {error && (
                <>
                  <div style={{ color: "red", fontSize: "16px" }}>{error}</div>
                </>
              )}
              {!error && (
                <div>
                  <Button
                    style={{ backgroundColor: "#054a29", color: "white" }}
                    variant="contained"
                    color="primary"
                    size="large"
                    target="_blank"
                    href="https://docs.google.com/spreadsheets/d/1KWvCP1YL7K0ENY1xtGrimoJIF9gNuSLFw1YPpAdtPmI/edit#gid=1468362261"
                  >
                    View SPC
                  </Button>
                </div>
              )}
            </div>
          </>
        </div>
      )}
    </>
  );
}

export default SPC;
