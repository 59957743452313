import React, { useEffect, useState, useRef } from "react";
import "../../style/product.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
// import Input from "../../components/Control/Input";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import SaveIcon from "@material-ui/icons/Save";
// import ClearAllIcon from "@material-ui/icons/ClearAll";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { DivStyled } from "../EducationTraining/Container.styled";

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const AddEditSupplier = ({ setIsNewSupplierModal, id }) => {
  const classes = dropdownStyles();
  const [supplierData, setSupplierData] = useState({});
  const inputElement = useRef(null);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "Supplier",
    `Are you sure you want to ${id ? "update" : "save"} this part?`
  );

  function handleSupplierData(e) {
    setSupplierData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }, []);
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/get_supplierById/${id}`)
        .then((res) => setSupplierData(res.data))
        .catch((err) => console.log(err));
    }
    // else {
    //   setSupplierData({
    //     date_orig: new Date(),
    //   });
    // }
  }, [id]);

  const handleSumbit = async () => {
    // e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/update_supplier/${id}`, supplierData)
        .then((res) => {
          setIsNewSupplierModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/create/supplier", supplierData)
        .then((res) => {
          setIsNewSupplierModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <section className="root-admin">
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handleSumbit}
        >
          {id ? "UPDATE" : "SUBMIT"}
        </Button>
      </div>
      <DivStyled>
        <label htmlFor="supplier_name">Supplier Name:</label>
        <input
          type="text"
          id="supplier_name"
          name="supplier_name"
          ref={inputElement}
          // required
          defaultValue={supplierData?.supplier_name}
          onBlur={handleSupplierData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="item_supplied">Item Supplied:</label>
        <input
          type="text"
          id="item_supplied"
          name="item_supplied"
          defaultValue={supplierData?.item_supplied}
          onBlur={handleSupplierData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="rm_grade">RM Grade :</label>
        <input
          type="text"
          id="rm_grade"
          name="rm_grade"
          defaultValue={supplierData?.rm_grade}
          onBlur={handleSupplierData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="adress">Address:</label>
        <input
          type="text"
          id="adress"
          name="adress"
          defaultValue={supplierData?.adress}
          onBlur={handleSupplierData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="person">Contact Person: </label>
        <input
          type="text"
          id="person"
          name="person"
          defaultValue={supplierData?.person}
          onBlur={handleSupplierData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="contact">Contact Number</label>
        <input
          type="text"
          id="contact"
          name="contact"
          defaultValue={supplierData?.contact}
          onBlur={handleSupplierData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="iso_certified_trader">ISO Certified/Trader</label>
        <div
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: supplierData?.iso_certified_trader
              ? "8.5rem"
              : "8.9rem",
          }}
        >
          <Select
            defaultValue={supplierData?.iso_certified_trader || ""}
            name="iso_certified_trader"
            key={supplierData?.iso_certified_trader}
            classes={{
              root: classes.root,
              select: classes.select,
            }}
            style={{ fontSize: "0.8rem", width: "100%" }}
            disableUnderline
            onChange={handleSupplierData}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="Yes">Yes</MenuItem>
            <MenuItem value="No">No</MenuItem>
          </Select>
        </div>
      </DivStyled>
      <DivStyled>
        <label htmlFor="component_material_name">
          Component / Material Name :
        </label>
        <input
          type="text"
          id="component_material_name"
          name="component_material_name"
          defaultValue={supplierData?.component_material_name}
          onBlur={handleSupplierData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="category">Category:</label>
        <input
          type="text"
          id="category"
          name="category"
          defaultValue={supplierData?.category}
          onBlur={handleSupplierData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="remark">Remark:</label>
        <input
          type="text"
          id="remark"
          name="remark"
          defaultValue={supplierData?.remark}
          onBlur={handleSupplierData}
        />
      </DivStyled>

      <DialogSubmit isSubmitContent={false} />
    </section>
  );
};

export default AddEditSupplier;
