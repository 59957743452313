import { Button, Typography } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Styled from "styled-components";
import lodash from "lodash";

import { Wrapper } from "../../../components/Control/Flexbox";
import formatDate from "../../../components/Control/formatDate";
import { useQuery } from "../../../components/Control/useQuery";
import TableReadEditCell from "../../../components/TableEditCell";

import TableScroll from "../../../components/TableScroll";
import { images, textInputs } from "../../../constants/constants";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { MoniteringSheetTableHead } from "./util";
const Table = Styled.table`
    width:98%;
    margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 100px;
        border: none;
        height: 50px;
        text-align:center;
    }

    .middleText  > th {
      text-align: center;

    }
    .tablebody-row td{
      text-align:center;
    }

`;
const AbnormalityMonitoringAddEditView = () => {
    const query = useQuery();
    const isView = query.get("isView") === "false" ? false : true;
    const queryAnnualDate = query.get("annual_date");
    const annualDate = new Date(queryAnnualDate);
    const [monitoringData, setMonitoringData] = useState({
        annual_date: annualDate,
        requestFormList: [],
    });
    const [render, setRender] = useState(false);
    useEffect(() => {
        (async () => {
            const requestForms = {};
            let requestFormRes = await axios
                .get(`/api/ab/getRequestFormByMonthly/${annualDate}`)
                .catch((err) => console.log(err));
            if (requestFormRes?.data) {
                requestFormRes.data.map((request) => {
                    requestForms[request.requestform_id] = request;
                });
            }
            let monitoringRes = await axios
                .get(`/api/ab/get4mMonitoringById/${queryAnnualDate}`)
                .catch((err) => console.log(err));
            if (monitoringRes) {
                const monitoringData = lodash.cloneDeep(monitoringRes.data);

                monitoringRes.data.requestFormList.forEach((request, index) => {
                    if (request.requestform_id in requestForms) {
                        // remove id from requestForms
                        delete requestForms[request.requestform_id];
                    } else {
                        // remove request form
                        monitoringData.requestFormList.splice(index, 1);
                    }
                });
                for (let key in requestForms) {
                    monitoringData.requestFormList.push(requestForms[key]);
                }
                setMonitoringData(lodash.cloneDeep(monitoringData));
                return;
            }
            setMonitoringData({
                annual_date: queryAnnualDate,
                requestFormList: requestFormRes.data,
            });
        })();
    }, [render]);
    const handleChange = (e, index) => {
        const { name, value } = e.target;
        if (index === 0 || index) {
            const values = { ...monitoringData };
            values.requestFormList[index][name] = value;
            setMonitoringData(values);
        }
    };

    const onSubmit = () => {
        let isSubmit = window.confirm(
            `Are you sure you want to ${monitoringData._id ? "update" : "submit"
            } this document?`
        );
        if (!isSubmit) return;
        if (monitoringData._id) {
            axios
                .put(`/api/ab/edit4mMonitoring/${monitoringData._id}`, monitoringData)
                .then((res) => {
                    setRender((prev) => !prev);
                    if (res.data) alert("updated successfully");
                })
                .catch((err) => console.log(err));
        } else {
            axios
                .post("/api/ab/create4mMonitoring", monitoringData)
                .then(async (res) => {
                    if (res.data) {
                        alert("submitted successfully");
                        setRender((prev) => !prev);
                    }
                })
                .catch((err) => console.log(err));
        }
    };
    return (
        <main>
            <div className="divflex">
                <Typography
                    style={{
                        fontSize: "24px",
                        fontFamily: "Nunito",
                    }}
                >
                    ABNORMALITY MONITORING SHEET
                </Typography>
                <Wrapper>
                    <Button
                        type="button"
                        variant="outlined"
                        color="white"
                        size="large"
                        onClick={() => {
                            onSubmit(true);
                        }}
                        style={{
                            color: "#e9e9e9",
                            backgroundColor: "#064a29",
                            marginLeft: "0.7rem",
                        }}
                        startIcon={<Save />}
                    >
                        {monitoringData._id ? "save" : "Submit"}
                    </Button>
                </Wrapper>
            </div>
            <TableScroll style={{ marginTop: "2rem", margin: "1rem" }}>
                <Table>
                    <thead>
                        <tr data-height="80" style={{ height: "70px" }}>
                            <th
                                colSpan="8"
                                rowSpan={2}
                                align="left"
                                style={{
                                    border: "1px solid black",
                                    fontSize: "1.6rem",
                                    textAlign: "center",
                                    position: "relative",
                                }}
                                data-a-v="middle"
                            >
                                <CenteredLogoImg>
                                    <img
                                        src={images.arcLogo}
                                        alt={textInputs.companyName}
                                        height="46"
                                        width="120"
                                    />
                                    <span>{textInputs.companyName}</span>
                                </CenteredLogoImg>
                            </th>
                            <th>FORMAT NO:</th>
                            <td>MSC/HA/02/00</td>
                        </tr>
                        <tr>
                            <th>DATE:</th>
                            <td>01/06/2021</td>
                        </tr>
                        <tr>
                            <th
                                colSpan="10"
                                align="center"
                                style={{
                                    fontSize: "1.2rem",
                                    fontWeight: 600,
                                    backgroundColor: "#1d1d1d",
                                    color: "white",
                                    border: "1px solid black",
                                    textAlign: "center",
                                }}
                            >
                                असामान्यता रिकॉर्ड
                            </th>
                        </tr>
                    </thead>
                    <tr className="middleText">
                        {MoniteringSheetTableHead.map((head) => (
                            <th
                                rowSpan={head.rowspan}
                                colSpan={head.colspan}
                                style={{ textTransform: "uppercase" }}
                            >
                                {head.name}
                            </th>
                        ))}
                    </tr>
                    <tr className="middleText">
                        <th>जांच की गई मात्रा</th>
                        <th>रिजेक्ट मात्रा</th>
                        <th>जांच की गई मात्रा</th>
                        <th>रिजेक्ट मात्रा</th>
                    </tr>
                    <tbody className="tablebody-row">
                        {monitoringData.requestFormList.map((requestForm, index) => (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{formatDate(requestForm.request_date)}</td>
                                <td>{requestForm.change_type}</td>
                                <td>
                                    <TableReadEditCell
                                        type="text"
                                        name="setup_approval"
                                        key="setup_approval"
                                        value={requestForm.setup_approval}
                                        {...{ handleChange, index, isView }}
                                    />
                                </td>

                                <td>
                                    <TableReadEditCell
                                        type="number"
                                        name="retro_qty_ok"
                                        key="retro_qty_ok"
                                        value={requestForm.retro_qty_ok}
                                        {...{ handleChange, index, isView }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        type="number"
                                        name="retro_qty_rej"
                                        key="retro_qty_rej"
                                        value={requestForm.retro_qty_rej}
                                        {...{ handleChange, index, isView }}
                                    />
                                </td>

                                <td>
                                    <TableReadEditCell
                                        type="number"
                                        name="suspect_qty_ok"
                                        key="suspect_qty_ok"
                                        value={requestForm.suspect_qty_ok}
                                        {...{ handleChange, index, isView }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        type="number"
                                        name="suspect_qty_rej"
                                        key="suspect_qty_rej"
                                        value={requestForm.suspect_qty_rej}
                                        {...{ handleChange, index, isView }}
                                    />
                                </td>
                                <td>
                                    <TableReadEditCell
                                        type="text"
                                        name="invoice_no"
                                        key="invoice_no"
                                        value={requestForm.invoice_no}
                                        {...{ handleChange, index, isView }}
                                    />
                                </td>
                                <td>{requestForm.remark}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </TableScroll>
        </main>
    );
};

export default AbnormalityMonitoringAddEditView;
