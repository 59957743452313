import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import Styled from "styled-components";
import { Wrapper } from "../../Control/Flexbox";
import PrintIcon from "@material-ui/icons/Print";
import formatDate from "../../Control/formatDate";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";
const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;


    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
        font-weight: 400;
    }
`;
const DimensionReport = ({ ppapData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <section>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          DIMENSION INSPECTION REPORT
        </Typography>
        <Wrapper>
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  borderRadius: "0",
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: "Dimension Inspection Report.xlsx",
                      sheet: {
                        name: "DIMENSION INSPECTION REPORT",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      </div>
      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="15, 35, 35, 15, 15"
        >
          <thead>
            <tr data-height="100">
              <th
                colSpan="5"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  height: "100px",
                }}
              >
                <CenteredLogoImg>
                  <img src={images.arcLogo} alt={textInputs.companyName} />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
            </tr>

            <tr>
              <td
                colSpan="5"
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                }}
                data-font-sz="18"
                data-a-h="center"
                data-f-bold="true"
                data-fill-color="#1d1d1d"
                data-f-color="FFFFFF"
              >
                DIMENSION INSPECTION REPORT
              </td>
            </tr>
            <tr>
              <td
                align="left"
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                Date: {formatDate(ppapData.dim_date)}
              </td>
              <td
                style={{
                  border: "1px solid black",
                }}
              ></td>
              <td
                align="left"
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                Page: 1 of 1
              </td>
            </tr>
            <tr>
              <td
                align="left"
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                Supplier: {ppapData.supplier_name}
              </td>
              <td
                style={{
                  border: "1px solid black",
                }}
              ></td>
              <td
                align="left"
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                Part No.: {ppapData.part_name}
              </td>
            </tr>
            <tr>
              <td
                align="left"
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                Lab: Quality control lab
              </td>
              <td
                style={{
                  border: "1px solid black",
                }}
              ></td>
              <td
                align="left"
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                Part Name.: {ppapData.part_number}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  width: "10rem",
                }}
              >
                S NO.
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  width: "20rem",
                }}
              >
                DIMENSION/SPECIFICATION
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  width: "20rem",
                }}
              >
                SUPPLIER MEASUREMENT RESULT
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  width: "8rem",
                }}
              >
                OK
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  width: "6rem",
                }}
              >
                NOT OK
              </th>
            </tr>
            {ppapData.dimensions.map((item, index) => (
              <tr key={item._id}>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.s_no}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.dimension}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.supplier_measure}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.dim_status === "OK" ? "OK" : ""}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.dim_status === "NOT OKAY" ? "NOT OK" : ""}
                </td>
              </tr>
            ))}
          </tbody>
          <tr>
            <td
              colSpan={5}
              style={{
                border: "1px solid black",
              }}
            >
              Signature: <span>{ppapData.dim_sign}</span>
            </td>
          </tr>
          <tr>
            <td
              colSpan={5}
              style={{
                border: "1px solid black",
              }}
            >
              Date: <span>{formatDate(ppapData.dim_approval_date)}</span>
            </td>
          </tr>
        </Table>
      </div>
    </section>
  );
};

export default DimensionReport;
