import React from "react";

const MaintenanceTableFooter = ({ checklistType }) => {
  if (
    checklistType === "Air_Compressor" ||
    checklistType === "Centr_Grinding" ||
    checklistType === "Centreless_Gr" ||
    checklistType === "CTR" ||
    checklistType === "Drill_Machine" ||
    checklistType === "HP" ||
    checklistType === "Hydrau" ||
    checklistType === "IH"
  ) {
    return (
      <tfoot>
        <tr>
          <th colSpan={3}>Done By:-</th>
          <th colSpan={4}>Prod Sup:-</th>
          <th colSpan={3}>Verified By:-</th>
        </tr>
      </tfoot>
    );
  } else {
    return (
      <tfoot>
        <tr>
          <th colSpan={3}>Done By:-</th>
          <th colSpan={3} rowSpan={3}>
            FULL NAME AND SIGNATURES
          </th>
          <th colSpan={4}></th>
        </tr>
        <tr>
          <th colSpan={3}>IN PRESENCE OF:-</th>
          <th colSpan={4}></th>
        </tr>
        <tr>
          <th colSpan={3}>VERIFIED BY:-</th>
          <th colSpan={4}></th>
        </tr>
      </tfoot>
    );
  }
};

export default MaintenanceTableFooter;
