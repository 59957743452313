import { React, useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import SpecsList from "./SpecsList";
import PirProcessDetail from "./PirProcessDetail";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import DoneIcon from "@material-ui/icons/Done";

function ProcessPir({ pirData }) {
  const [isPir, setIsPir] = useState([]);
  const [render, setRender] = useState(false);

  const hidePir = (index) => {
    setIsPir((prev) => [...prev, index]);
  };

  const openPir = (index) => {
    const values = [...isPir];
    const idx = values.indexOf(index);
    values.splice(idx, 1);
    setIsPir(values);
  };

  function handleChangePir(e, index) {
    pirData.pir[index][e.target.name] = e.target.value;
    setRender((prev) => !prev);
  }

  const handleDate = (date, index) => {
    pirData.pir[index].processDate = date;
    setRender((prev) => !prev);
  };
  return (
    <div style={{ margin: "1rem 0.6rem", paddingBottom: "4rem" }}>
      {pirData.pir.map((process, index) => (
        <div key={index} style={{ marginTop: "0.1rem" }}>
          {/* start header */}
          <main style={{ border: "1px solid #f0f2f0" }}>
            <Wrapper
              style={{
                background: "#fafafa",
              }}
              justify="space-between"
            >
              <Wrapper>
                <Wrapper
                  style={{
                    fontSize: "0.9rem",
                    height: "45px",
                    width: "45px",
                    fontWeight: 500,
                    color: "white",
                    backgroundColor: "#1d1d1d",
                  }}
                  justify="center"
                >
                  {index + 1}
                </Wrapper>
                <Wrapper
                  justify="center"
                  style={{
                    marginLeft: "1.3rem",
                    fontSize: "0.9rem",
                    textTransform: "uppercase",
                  }}
                >
                  {process.process_name}
                </Wrapper>
              </Wrapper>
              <Wrapper style={{ gap: "0.2rem" }}>
                <Wrapper
                  style={{
                    height: "45px",
                    width: "50px",
                    backgroundColor: process.isProcessChecked
                      ? "#064a29"
                      : "#c80202",
                    cursor: "pointer",
                    color: "white",
                  }}
                  justify="center"
                  onClick={() => {
                    if (process.isProcessChecked) {
                      process.isProcessChecked = false;
                    } else {
                      process.isProcessChecked = true;
                    }
                    setRender((prev) => !prev);
                  }}
                >
                  {process.isProcessChecked ? <DoneIcon /> : <AccessTimeIcon />}
                </Wrapper>
                {isPir.includes(index) ? (
                  <Wrapper
                    style={{
                      height: "45px",
                      width: "50px",
                      backgroundColor: "#3f51b5",
                      cursor: "pointer",
                      color: "white",
                    }}
                    justify="center"
                    onClick={() => openPir(index)}
                  >
                    <ExpandLess />
                  </Wrapper>
                ) : (
                  <Wrapper
                    style={{
                      height: "45px",
                      width: "50px",
                      backgroundColor: "#3f51b5",
                      cursor: "pointer",
                      color: "white",
                    }}
                    justify="center"
                    onClick={() => hidePir(index)}
                  >
                    <ExpandMore />
                  </Wrapper>
                )}
              </Wrapper>
            </Wrapper>
            {isPir.includes(index) && (
              <section>
                {" "}
                <PirProcessDetail
                  {...{ process, pirData, index, handleChangePir, handleDate }}
                />
                <SpecsList {...{ process, pirData, index }} />
              </section>
            )}
          </main>

          {/* end header */}
        </div>
      ))}
    </div>
  );
}

export default ProcessPir;
