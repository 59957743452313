import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& .MuiDialog-paper": {
      margin: "5px",
    },
    "& .MuiTypography-root ": {
      paddingBottom: "20px",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "1500px",
      minWidth: "50vh",
      minHeight: "30vh",
      // backgroundColor: "#f0f2f0",
      backgroundColor: "#fff",
      fontSize: "1.2rem",
    },
  },
}));

const ConfirmDialogueForInput = (props) => {
  const classes = useStyles();

  const {
    title,
    children,
    open,
    setOpen,
    onConfirm,
    isSubmitContent, // is submitContent is for classes for styling button blue or red depend on situation
    itemPartName,
    itemId,
    textFieldValue,
    setTextFieldValue,
  } = props;
  if (itemPartName) {
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
        className={classes.root}
      >
        <DialogTitle
          id="confirm-dialog"
          style={{
            backgroundColor: "#212529",
            height: "60px",
            padding: "0.6rem 1rem",
            textTransform: "uppercase",
          }}
        >
          <div
            style={{
              fontSize: "1.5rem",
              fontWeight: 400,
              fontFamily: "Nunito",
              letterSpacing: "0.1em",
              color: "#fff",
              marginLeft: "0.6rem",
            }}
          >
            {title}
          </div>
        </DialogTitle>
        <DialogContent style={{ marginTop: "1rem" }}>{children}</DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            style={{
              backgroundColor: isSubmitContent ? "#003566" : "#c80202",
              color: "#fafafa",
            }}
            onClick={() => {
              if (itemPartName === textFieldValue) {
                console.log(itemPartName, itemId);
                if (itemPartName && itemId) {
                  onConfirm(itemId);
                  setTextFieldValue("");
                  setOpen(false);
                  return;
                }
                if (itemPartName) {
                  onConfirm(itemPartName);
                  setTextFieldValue("");
                  setOpen(false);
                  return;
                }
              }
              // setOpen(false);
            }}
            // color="default"
          >
            Yes
          </Button>

          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            style={{ backgroundColor: "#fafafa" }}
            // color="secondary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return null;
};

export default ConfirmDialogueForInput;
