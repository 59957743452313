import { React, useEffect, useRef, useState } from "react";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import { DivStyled } from "../Container.styled";

const TnaCreateModal = ({ setIsTnaModal, id }) => {
  const [annualTrainingData, setAnnualTraining] = useState({
    created_year: new Date(),
  });
  const [autocompleteData, setAutocompleteData] = useState("");
  const [allDepartment, setAllDepartment] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getAnnualTrainingById/${id}?onlyFields=${true}`)
        .then((res) => {
          setAnnualTraining(res.data);
          setAutocompleteData({
            department_id: res.data?.departments?._id,
            department_name: res.data?.departments?.department_name,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/getAllDepartments`)
      .then((res) => {
        const data = res.data.result.map((item) => {
          return {
            department_id: item._id,
            department_name: item.department_name,
          };
        });
        console.log(data);
        setAllDepartment(data);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleSumbit = () => {
    if (id) {
      axios
        .put(`/api/updateAnnualTraining/${id}`, annualTrainingData)
        .then((res) => {
          window.alert("success");
          setIsTnaModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post(`/api/createAnnualTraining`, annualTrainingData)
        .then((res) => {
          window.alert("success");
          setIsTnaModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <section style={{ padding: "0.3rem 0rem 0.3rem 0rem" }}>
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handleSumbit}
        >
          SUBMIT
        </Button>
      </div>
      <DivStyled>
        <label for="title">Title:</label>
        <input
          type="text"
          id="title"
          name="title"
          defaultValue={annualTrainingData?.title}
          onBlur={(e) => {
            setAnnualTraining((prev) => ({ ...prev, title: e.target.value }));
          }}
        />
      </DivStyled>
      <DivStyled>
        <label for="title">Select Department</label>
        <Autocomplete
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: "6rem",
          }}
          id="training_topics"
          options={allDepartment}
          getOptionLabel={(option) => option.department_name}
          getOptionSelected={(option, value) =>
            option.department_name === value.department_name
          }
          value={autocompleteData}
          onChange={(e, value) => {
            annualTrainingData.departments = value.department_id;
            setAutocompleteData(value);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
        />
      </DivStyled>
      <DivStyled>
        <label>Select Year:</label>
        <DatePicker
          views={["year"]}
          className="date-field"
          InputProps={{
            disableUnderline: true,
          }}
          clearable
          style={{
            borderLeft: "2px solid #ced4da",
            backgroundColor: "#fff",
            paddingLeft: "0.5rem",
            width: "100%",
          }}
          value={annualTrainingData.created_year || null}
          onChange={(date) => {
            setAnnualTraining((prev) => ({ ...prev, created_year: date }));
          }}
        />
      </DivStyled>
    </section>
  );
};

export default TnaCreateModal;
