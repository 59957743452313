import React, { useState } from "react";
import Styled from "styled-components";
import TableReadEditCell from "../../../../components/TableEditCell";
import { images, textInputs } from "../../../../constants/constants";
import { CenteredLogoImg } from "../../../../golbalStyles/global.styled";

const Table = Styled.table`
    width:97%;
    margin:auto;
    th{
        font-size: 12px;
            font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 200px;
        border: none;
        height: 50px;
        text-align:center;
    }

`;

const DeliveryReport = ({ isView, annualDate, supplierRatingData }) => {
  const [render, setRender] = useState(false);
  return (
    <div>
      <Table
        cellSpacing="0"
        style={{ marginTop: "0.6rem", backgroundColor: "white" }}
      >
        <thead>
          <tr style={{ height: "70px" }}>
            <th
              colSpan="2"
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
                position: "relative",
              }}
            >
              <CenteredLogoImg>
                <img
                  src={images.arcLogo}
                  alt={textInputs.companyName}
                  height="46"
                  width="120"
                />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
            <th
              style={{
                border: "1px solid black",
              }}
            >
              FORMAT NO:- ARC/F/PUR/09C
            </th>
          </tr>

          <tr>
            <th
              colSpan="3"
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                textTransform: "uppercase",
                border: "1px solid black",
                textAlign: "center",
              }}
            >
              DELIVERY PERFORMANCE {annualDate}
            </th>
          </tr>
          <tr>
            {["supplier name", "Percentage %", "Total Percentage %"].map(
              (heading) => (
                <th style={{ textAlign: "center", textTransform: "uppercase" }}>
                  {heading}
                </th>
              )
            )}
          </tr>
        </thead>
        {supplierRatingData.deliveryReports.map((supplier) => (
          <tr>
            <td style={{ textAlign: "center", textTransform: "uppercase" }}>
              {supplier.supplier_name || supplier.supplier_id?.supplier_name}
            </td>
            <td style={{ textAlign: "center", textTransform: "uppercase" }}>
              <TableReadEditCell
                name="percentage"
                type="number"
                value={supplier.percentage}
                style={{ width: "100%" }}
                handleChange={(e) => {
                  supplier.percentage = e.target.valueAsNumber;
                  setRender((prev) => !prev);
                }}
                {...{ isView }}
              />
            </td>
            <td style={{ textAlign: "center", textTransform: "uppercase" }}>
              <TableReadEditCell
                name="total_percentage"
                type="number"
                value={supplier.total_percentage}
                style={{ width: "100%" }}
                handleChange={(e) => {
                  supplier.total_percentage = e.target.valueAsNumber;
                  setRender((prev) => !prev);
                }}
                {...{ isView }}
              />
            </td>
          </tr>
        ))}
        <tr>
          <td colSpan={2}>Prepared By:</td>
          <td colSpan={1}>Approved By:</td>
        </tr>
      </Table>
    </div>
  );
};

export default DeliveryReport;
