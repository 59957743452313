function formatDateForTime(date) {
  const newDate = new Date(date);
  if (isNaN(newDate)) {
    return "";
  }
  let hour = newDate.getHours();
  const min = newDate.getMinutes().toString().padEnd(2, 0);
  let meridian = "AM";
  if (hour > 12) {
    hour = hour - 12;
    meridian = "PM";
  }
  if (hour === 12) {
    meridian = "PM";
  }
  if (hour === 0) {
    hour = 12;
    meridian = "AM";
  }
  return `${hour}:${min} ${meridian}`;
}

export { formatDateForTime };
