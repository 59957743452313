import { useEffect } from "react";
import useTimeOut from "./useTimeOut";

const useDebounce = (callback, delay, dependencies) => {
  const { reset, clear } = useTimeOut(callback, delay);
  const [page] = dependencies;
  useEffect(reset, [...dependencies, reset]);
  useEffect(() => {
    clear();
    callback();
    // eslint-disable-next-line
  }, [clear, page]);
};

export default useDebounce;
