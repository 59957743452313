import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
const Div = styled.div`
  flex: 0.5;
  border: 1px solid black;
  height: 400px;

  p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    letter-spacing: 1.6px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #1d1d1d;
    color: white;
  }
`;

const sortObject = (obj) => {
  let sortedObj = Object.entries(obj).sort((a, b) => b[1] - a[1]);
  let rejectedArr = [];
  let potentialArr = [];
  sortedObj.forEach((value) => {
    potentialArr.push(value[0]);
    rejectedArr.push(value[1]);
  });
  return { rejectedArr, potentialArr };
};

const FailureWisePieGraph = ({ failureRejection }) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  function generateDefectGraph(rejectedData, potentialLabels) {
    setOptions({
      chart: {
        type: "pie",
      },
      labels: potentialLabels,
      responsive: [
        {
          options: {
            chart: {},
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    });
    setSeries([...rejectedData]);
  }

  useEffect(() => {
    const { rejectedArr, potentialArr } = sortObject(failureRejection);
    generateDefectGraph(rejectedArr, potentialArr);
  }, [failureRejection]);

  return (
    <Div>
      <p>PPM REJECTIONS %</p>
      <Chart options={options} series={series} height={340} type="pie" />
    </Div>
  );
};

export default FailureWisePieGraph;
