import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  // InputAdornment,
  // Button,
} from "@material-ui/core";
import { useQuery } from "../../components/Control/useQuery";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
import { Link, useHistory, useParams } from "react-router-dom";

const getMonthYear = (currentDate, month) => {
  const Currentyear = new Date(currentDate).getFullYear();

  if (month === "january" || month === "february" || month === "march") {
    return `${month} - ${Currentyear + 1}`;
  }

  return `${month} - ${Currentyear}`;
};

const PlanStatusPage = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  const query = useQuery();
  const plan_id = query.get("plan_id");
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const history = useHistory();

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/monthlyCheckedStatusForProcessAudit/${id}?page=${page}&search=${search}&perPage=10&plan_id=${plan_id}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [page, search, render]);

  const getCurrentStatus = (status) => {
    const statusMap = {
      pending: [{ bgColor: "#fcc419", width: "300px", text: "AUDIT PENDING" }],
      overdue: [{ bgColor: "#e03131", width: "300px", text: "AUDIT OVERDUE" }],
      completed: [
        { bgColor: "#2f9e44", width: "300px", text: "AUDIT COMPLETED" },
      ],
    };

    const [{ bgColor, text, width }] = statusMap[status] || [];
    return bgColor ? (
      <TableCell align="center" style={{ backgroundColor: bgColor, width }}>
        {text}
      </TableCell>
    ) : null;
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          PROCESS AUDIT
        </div>
        <div className="subheading-container">
          <p className="subheading-primary">
            ACTIVE <span className="subheading-secondary">PLANS</span>
          </p>
        </div>
        <TableContainer component={Paper} style={{ paddingTop: "2rem" }}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Part Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Plan Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Status
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Checklist
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data?.checked_plan?.map((item, index) => (
                  <React.Fragment key={index + "log"}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {perPage * (page - 1) + index + 1}
                        {/* {index + 1} */}
                      </TableCell>
                      <TableCell align="center">{item?.part_name}</TableCell>
                      <TableCell align="center">
                        {getMonthYear(item?.date_range, item?.checked_month)}
                      </TableCell>

                      {/* STATUS */}
                      {getCurrentStatus(item?.isDoneChecklist)}

                      <TableCell align="center">
                        <Tooltip title="View Checklist" arrow>
                          <Link
                            to={`/listOfProcessAuditChecklist/${
                              item.audit_row_id
                            }?plan_id=${plan_id}&auditDate=${getMonthYear(
                              item?.date_range,
                              item?.checked_month
                            )}&part_name=${item?.part_name}&part_number=${
                              item?.part_number
                            }`}
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <VisibilityIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
      </main>
    </>
  );
};

export default PlanStatusPage;
