import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Tooltip, Typography } from "@material-ui/core";

import { Wrapper } from "../../components/Control/Flexbox";
import PDFViewer from "../../components/PdfViewer/PdfViewer";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Link } from "@material-ui/core";
import { useAuth } from "../../context/GlobalContext";

const ViewDocumentDataControl = () => {
  const [pdfUrl, setpdfUrl] = useState("");
  const { partId } = useParams();
  const { state } = useAuth();
  const userType = state.user.userType;

  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    axios(`/api/getOneDocumentDataControl/data/${partId}`)
      .then((res) => {
        setpdfUrl(res.data.documentDataControlFiles);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          DOCUMENT & DATA CONTROL
        </Typography>
        {userType === 2 && pdfUrl && pdfUrl.slice(-3) !== "pdf" && (
          <Wrapper>
            <Tooltip arrow title="Download" style={{ marginRight: "20px" }}>
              <Link href={pdfUrl} download>
                <GetAppIcon style={{ color: "#fff" }} />
              </Link>
            </Tooltip>
          </Wrapper>
        )}
      </div>
      <Wrapper justify="center" column>
        {pdfUrl && pdfUrl.slice(-3) === "pdf" ? (
          <>
            <PDFViewer url={pdfUrl} />
          </>
        ) : (
          <div className="img">
            <img src={pdfUrl} alt="" height="100%" width="100%" />
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default ViewDocumentDataControl;
