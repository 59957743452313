import { Grid, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TableInput from "../../../../common/TableInput";
import {
  AddIconButton,
  DeleteIconButton,
  EditIconButton,
  RemoveIconButton,
  ViewIconButton,
} from "../../../../common/CustomIconButton";
import DatePickerMui from "../../../../common/DatePickerMui";
import CustomSelect from "../../../../common/CustomSelect";
import { actionTakens, actionTakenTickMarks, reasons } from "../helpers/data";
import ReportsButton from "./ReportsButton";
import AutocompleteMuiCustom from "../../../../common/AutocompleteMuiCustom";
import axios from "axios";
import OperationSelect from "./OperationSelect";
import { useQuery } from "../../../../components/Control/useQuery";

function getReportValues(process, reportKey, field) {
  if (process.reason && process.action_taken) {
    if (
      actionTakenTickMarks[`${process?.reason}${process?.action_taken}`]?.[
        reportKey
      ]
    ) {
      return process[`${reportKey}_id`]?.[field];
    }
  }

  if (process.reason) {
    if (actionTakenTickMarks[`${process?.reason}`]?.[reportKey]) {
      return process[`${reportKey}_id`]?.[field];
    }
  }
}
const TBody = ({
  handelDeleteRows,
  moduleData,
  isView,
  setIsOpenPopup,
  onSubmit,
  setPartIdx,
  pictorial,
  setPictorial,
  setIsTrainingFileOpenPopup,
  triningFileIdRef,
}) => {
  const [render, setRender] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const history = useHistory();
  const query = useQuery();
  const annual_month = query.get("annual_date") || "";
  const redirect = async (process, reportId, documentType) => {
    await onSubmit();
    if (reportId) {
      if (process.part_id?._id) {
        history.push(
          `/common/create4msetup/${process.part_id?._id}/${process._id}/${
            reportId._id
          }?annual_date=${annual_month}&documentType=${documentType}&machine_name=${
            process.machine_id?.machine_name
          }&operation_name=${encodeURIComponent(
            process.operation_name,
          )}&summary_date=${process.date}`,
        );
        return;
      }
      history.push(
        `/common/create4msetup/${process.part_id}/${process._id}/${
          reportId._id
        }?annual_date=${annual_month}&documentType=${documentType}&machine_name=${
          process.machine_id?.machine_name
        }&operation_name=${encodeURIComponent(
          process.operation_name,
        )}&summary_date=${process.date}`,
      );
      return;
    }
    if (process.part_id?._id) {
      history.push(
        `/common/create4msetup/${process.part_id?._id}/${
          process._id
        }?annual_date=${annual_month}&documentType=${documentType}&machine_name=${
          process.machine_id?.machine_name
        }&operation_name=${encodeURIComponent(
          process.operation_name,
        )}&summary_date=${process.date}`,
      );
      return;
    }
    history.push(
      `/common/create4msetup/${process.part_id}/${
        process._id
      }?annual_date=${annual_month}&documentType=${documentType}&machine_name=${
        process.machine_id?.machine_name
      }&operation_name=${encodeURIComponent(
        process.operation_name,
      )}&summary_date=${process.date}`,
    );
  };

  useEffect(() => {
    axios
      .get("/api/getAllMachine")
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <tbody>
      {moduleData.processes.map((process, index) => (
        <tr>
          <td>{index + 1}</td>
          <td>
            <CustomSelect
              name="change_type"
              key="change_type"
              value={process?.change_type}
              onChange={(e) => {
                if (process.date) {
                  let prevDate = new Date(process.date).getDate();
                  let values = { ...pictorial };
                  if (values?.[process.change_type]?.[prevDate - 1]) {
                    values[process.change_type][prevDate - 1] = false;
                  }
                  setPictorial(values);
                }
                process.change_type = e.target.value;
                process.date = null;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="man">Man</MenuItem>
              <MenuItem value="machine">Machine</MenuItem>
              <MenuItem value="material">Material</MenuItem>
              <MenuItem value="method">Method</MenuItem>
            </CustomSelect>
          </td>
          <td>
            {process.change_type && (
              <DatePickerMui
                sx={{ width: "150px" }}
                onChange={(date) => {
                  if (process.change_type) {
                    let currentDate = new Date(date).getDate();
                    let prevDate = new Date(process.date).getDate();
                    let values = { ...pictorial };
                    if (values[process.change_type][prevDate - 1]) {
                      values[process.change_type][prevDate - 1] = false;
                    }
                    values[process.change_type][currentDate - 1] = true;
                    setPictorial(values);
                  }
                  process.date = date;
                  setRender((prev) => !prev);
                }}
                value={process.date}
              />
            )}
          </td>
          <td>
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={process.machine_id || ""}
              onChange={(e, value) => {
                process.machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td style={{ width: "250px", minWidth: "250px" }}>
            {isView ? (
              process?.part_id && process?.part_id?.part_name
            ) : (
              <Grid container xs={12} direction="row" alignItems={"center"}>
                <Grid item xs={10}>
                  <p
                    style={{
                      fontSize: "0.9rem",
                    }}
                  >
                    {typeof process?.part_id === "object" &&
                    process?.part_id !== null
                      ? process?.part_id?.part_name
                      : process?.partName || ""}
                    {!process?.part_id && "Pick Part Name"}
                  </p>
                </Grid>

                {process.part_id ? (
                  <Grid item xs={2}>
                    <RemoveIconButton
                      tooltipTitle="remove part"
                      onClick={() => {
                        process.part_id = null;
                        process.partNumber = null;
                        process.partName = null;
                        setRender((prev) => !prev);
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <AddIconButton
                      tooltipTitle="add part"
                      onClick={() => {
                        setIsOpenPopup(true);
                        setPartIdx(index);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </td>
          <td style={{ minWidth: "200px" }}>
            <OperationSelect
              {...{ process, isView, setRender }}
              partId={
                typeof process.part_id === "string"
                  ? process?.part_id
                  : process?.part_id?._id
              }
            />
          </td>
          <td>
            {process.reason && process.action_taken
              ? actionTakenTickMarks[
                  `${process?.reason}${process?.action_taken}`
                ]?.expected
              : actionTakenTickMarks[`${process?.reason}`]?.expected}
          </td>
          <td>
            {process.reason && process.action_taken
              ? actionTakenTickMarks[
                  `${process?.reason}${process?.action_taken}`
                ]?.unexpected
              : actionTakenTickMarks[`${process?.reason}`]?.unexpected}
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: "120px" }}
              name="current_level"
              type="text"
              value={process.current_level}
              onChange={(e) => {
                process.current_level = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: "120px" }}
              name="new_level"
              type="text"
              value={process.new_level}
              onChange={(e) => {
                process.new_level = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td style={{ minWidth: "150px" }}>
            <CustomSelect
              name="reason"
              value={process?.reason}
              onChange={(e) => {
                process.reason = e.target.value;
                process.action_taken = "";
                setRender((prev) => !prev);
              }}
            >
              {reasons[process.change_type] &&
                reasons[process.change_type].map((reason) => (
                  <MenuItem value={reason.value}>{reason.name}</MenuItem>
                ))}
            </CustomSelect>
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: "120px" }}
              name="suspected_qty"
              type="number"
              value={process.suspected_qty}
              onChange={(e) => {
                process.suspected_qty = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td style={{ minWidth: "150px" }}>
            {actionTakens[process?.reason] && (
              <CustomSelect
                name="reason"
                key="reason"
                value={process?.action_taken}
                onChange={(e) => {
                  process.action_taken = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                <MenuItem>None</MenuItem>
                {actionTakens[process.reason].map((action) => (
                  <MenuItem value={action.value}>{action.name}</MenuItem>
                ))}
              </CustomSelect>
            )}
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: "120px" }}
              name="ok"
              type="text"
              value={process.ok}
              onChange={(e) => {
                process.ok = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: "120px" }}
              name="ng"
              type="text"
              value={process.ng}
              onChange={(e) => {
                process.ng = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <CustomSelect
              name="remark_status"
              label=""
              value={process?.remark_status}
              onChange={(e) => {
                process.remark_status = e.target.value;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="">NONE</MenuItem>
              <MenuItem value="C">C</MenuItem>
              <MenuItem value="NC">NC</MenuItem>
            </CustomSelect>
          </td>
          <td style={{ minWidth: "100px" }}>
            {process.part_id && (
              <ReportsButton {...{ process, redirect }} reportKey="setup" />
            )}
          </td>
          <td style={{ minWidth: "100px" }}>
            {process.part_id && (
              <ReportsButton {...{ process, redirect }} reportKey="change4m" />
            )}
          </td>
          <td style={{ minWidth: "100px" }}>
            {process.part_id && (
              <ReportsButton {...{ process, redirect }} reportKey="retro" />
            )}
          </td>
          <td style={{ minWidth: "100px" }}>
            {process.part_id && (
              <ReportsButton {...{ process, redirect }} reportKey="suspected" />
            )}
          </td>
          <td>
            {process.reason && process.action_taken
              ? actionTakenTickMarks[
                  `${process?.reason}${process?.action_taken}`
                ]?.procedure
              : actionTakenTickMarks[`${process?.reason}`]?.procedure}
          </td>
          <td style={{ minWidth: "100px" }}>
            {process.part_id && (
              <AddIconButton
                tooltipTitle="Add"
                onClick={async () => {
                  // redirect(process, process[`$hold_card_id`], 'hold_card');
                  await onSubmit();
                  history.push(
                    `/common/createHoldCard/${process?.hold_card_id}?rowId=${
                      process._id
                    }&part_name=${
                      process?.part_id !== null
                        ? process?.part_id?.part_name
                        : process?.partName || ""
                    }&part_number=${
                      process?.part_id !== null
                        ? process?.part_id?.part_number
                        : process?.partNumnber || ""
                    }&issue_date=${process.date}&operation_name=${
                      process.operation_name
                    }&nature_of_change=${
                      (process.reason && process.action_taken
                        ? actionTakenTickMarks[
                            `${process?.reason}${process?.action_taken}`
                          ]?.expected
                        : actionTakenTickMarks[`${process?.reason}`]
                            ?.expected) ||
                      (process.reason && process.action_taken
                        ? actionTakenTickMarks[
                            `${process?.reason}${process?.action_taken}`
                          ]?.unexpected
                        : actionTakenTickMarks[`${process?.reason}`]
                            ?.unexpected) ||
                      ""
                    }&change_type=${process?.change_type}&detail_of_change=${
                      reasons?.[process?.change_type]?.[
                        reasons?.[process?.change_type]?.findIndex(
                          (item) => item?.value === process?.reason,
                        )
                      ]?.name || ""
                    }&retro_id=${
                      process?.retro_id?._id
                    }&annual_date=${annual_month}`,
                  );
                }}
              />
            )}
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: "120px" }}
              name="traceability"
              type="text"
              value={process.traceability}
              onChange={(e) => {
                process.traceability = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td style={{ minWidth: "100px" }}>
            {!isView && (
              <DeleteIconButton
                tooltipTitle={"delete"}
                onClick={() => handelDeleteRows(index)}
              />
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
