import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Modules from "./modules";
import "./modules/style/theme.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#c80202",
    },
    secondary: {
      main: "#212529",
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ff9800",
    },
    text: {
      // primary: '#111',
      // secondary: '#f1f3f5',
    },
    blueButton: {
      main: "#003566",
      contrastText: "#fff",
    },
    greenButton: {
      main: "#064a29",
      contrastText: "#fff",
    },
  },
  typography: {
    // fontFamily: 'Raleway, Arial',
    fontSize: 13,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Modules />
      </div>
    </ThemeProvider>
  );
}

export default App;
