import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Wrapper } from "../../../components/Control/Flexbox";
import { images, textInputs } from "../../../constants/constants";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../../components/Control/useConfirm";
import {
  ButtonStyled,
  TableContainerStyled,
} from "../../DailyProduction/styles";
import { useQuery } from "../../../components/Control/useQuery";
import formatDate from "../../../components/Control/formatDate";
import { processes } from "./checklistData";
import Tooltip from "@material-ui/core/Tooltip";
import TableToExcel from "@dayalk/table-to-excel";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        height: 50px;
        text-align:center;
    }

`;
const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const EditViewChecklist = () => {
  const classes = dropdownStyles();
  const { auditId, id } = useParams();
  const [render, setRender] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const auditDate = query.get("auditDate");
  const part_number = query.get("part_number");
  const part_name = query.get("part_name");
  const [employeeAutocomplete, setEmployeeAutocomplete] = useState([]);
  const [auditorValue, setAuditorValue] = useState([]);
  const [departmentAutocomplete, setDepartmentAutocomplete] = useState([]);
  const [departmentValue, setDepartmentValue] = useState([]);
  const [data, setData] = useState({
    // processes: new Array(6).fill().map((_) => []),
    processes: processes,
    isCompleted: false,
  });
  // console.log(processes);
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to Submit?"
  );
  const handleAllC_NCValue = (data) => {
    const isCChecked = data?.processes.every(
      (process) => process.status_c_nc === "c"
    );

    // console.log(isCChecked);
    return isCChecked;
  };

  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/getOneProcessAuditChecklist/${id}`)
      .then((res) => {
        const auditordata = {
          employee_name: res.data?.auditor?.employee_name,
          _id: res.data?.auditor?._id,
        };
        const departmentdata = {
          department_name: res.data?.department?.department_name,
          _id: res.data?.department?._id,
        };
        setAuditorValue(auditordata);
        setDepartmentValue(departmentdata);
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/getAllEmployeeData/`)
      .then((res) => {
        setEmployeeAutocomplete(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/getAllDepartments/`)
      .then((res) => {
        setDepartmentAutocomplete(res.data.result);
        // console.log(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmUpdate();
      if (ans) {
        data.isSubmitted = bool;
        axios
          .put(`/api/updateProcessAuditChecklist/${id}`, data)
          .then((res) => {
            if (res.data.status === "success") {
              alert("updated successfully");
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        data.isSubmitted = bool;
        axios
          .post(`/api/createProcessAuditChecklist/${auditId}`, data)
          .then((res) => {
            if (res.data.status === "success") {
              alert("created successfully");
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 80;
    } else {
      document.getElementById("machinetable").scrollLeft -= 80;
    }
  };
  const handleChangeChecklistAudit = (e, index) => {
    const { name, value } = e.target;
    data.processes[index][name] = value;
    setRender((prev) => !prev);
  };

  return (
    <section>
      <div
        style={{
          paddingBottom: "4rem",
        }}
      >
        <ButtonStyled
          left
          onClick={(e) => {
            handleScroll("left");
          }}
        >
          <div>
            <ArrowBackIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <ButtonStyled
          right
          onClick={(e) => {
            handleScroll("right");
          }}
        >
          <div>
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
              textTransform: "uppercase",
            }}
          >
            Process Audit Check Sheet
          </Typography>
          
          {!isView ? (
            <div style={{ display: "flex" }}>
              <Wrapper
                style={{
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  style={{
                    border: "1px solid black",
                    marginTop: "0.5rem",
                    width: "7rem",
                    backgroundColor: "#064a29",
                  }}
                  control={
                    <Checkbox
                      checked={data?.isCompleted}
                      // key="isCompleted"
                      name="isCompleted"
                      onChange={(e) => {
                        setData((prev) => ({
                          ...prev,
                          isCompleted: e.target.checked,
                        }));
                      }}
                      disabled={!handleAllC_NCValue(data)}
                      color="primary"
                    />
                  }
                  label="Done"
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#064a29",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    handleSubmitAndSave(true);
                  }}
                >
                  Save
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            </div>
          ):<Tooltip arrow title="Export as spreadsheet">
          <div>
            <Button
              style={{
                backgroundColor: "#161a1d",
                borderColor: "#161a1d",
                color: "white",
                width: "fit-content",
                borderRadius: "0",
                paddingTop: "9px",
              }}
              onClick={() => {
                TableToExcel.convert(
                  document.getElementById("table-to-xls"),
                  {
                    name: "Control Plan Report.xlsx",
                    sheet: {
                      name: "Control Plan Report",
                    },
                  }
                );
              }}
            >
              <Icon path={mdiFileExcel} size={1.2} />
            </Button>
          </div>
        </Tooltip> }
        </div>
        <TableContainerStyled id="machinetable">
          <Table
            cellSpacing="0"
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan="1"
                  rowSpan={2}
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <div>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                  </div>
                </th>
                <th
                  colSpan="3"
                  rowSpan={2}
                  align="center"
                  style={{
                    fontSize: "1rem",
                    fontWeight: 600,
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Process Audit Check Sheet
                </th>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  DOC NO:-ARC/F/QAD/20
                </th>
              </tr>
              <tr>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  REV NO/DATE:- 02/ 07-11-2021
                </th>
              </tr>
              <tr>
                <th colSpan={1} style={{ border: "1px solid black" }}>
                  Date of Audit:
                </th>
                <th colSpan={2}>{auditDate}</th>
                <th>Department/ Section: </th>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  {isView ? (
                    data?.department?.department_name
                  ) : (
                    <Autocomplete
                      id="training_topics"
                      options={departmentAutocomplete}
                      getOptionLabel={(option) => option?.department_name}
                      getOptionSelected={(option, value) =>
                        option?.department_name === value?.department_name
                      }
                      value={departmentValue}
                      onChange={(e, value) => {
                        data.department = value?._id || null;
                        setDepartmentValue(value);
                        setRender((prev) => !prev);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                        />
                      )}
                    />
                  )}
                </th>
              </tr>
              <tr>
                <th colSpan={1} style={{ border: "1px solid black" }}>
                  Shift:
                </th>
                <th colSpan={2}>
                  {isView ? (
                    data?.shift
                  ) : (
                    <input
                      type="text"
                      name="shift"
                      value={data?.shift}
                      autoComplete="off"
                      className="input"
                      style={{ border: "2px solid #f9e0e0" }}
                      onChange={(e) => {
                        setData((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                    />
                  )}
                </th>
                <th>Component: </th>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  {isView ? (
                    data?.component
                  ) : (
                    <input
                      type="text"
                      name="component"
                      value={data?.component}
                      autoComplete="off"
                      className="input"
                      style={{ border: "2px solid #f9e0e0" }}
                      onChange={(e) => {
                        setData((prev) => ({
                          ...prev,
                          [e.target.name]: e.target.value,
                        }));
                      }}
                    />
                  )}
                </th>
              </tr>
              <tr>
                <th colSpan={1} style={{ border: "1px solid black" }}>
                  Part Name :
                </th>
                <th colSpan={2}>{part_name}</th>
                <th>Auditor Name: </th>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  {isView ? (
                    data?.auditor?.employee_name
                  ) : (
                    <Autocomplete
                      id="training_topics"
                      options={employeeAutocomplete}
                      getOptionLabel={(option) => option.employee_name}
                      getOptionSelected={(option, value) =>
                        option.employee_name === value.employee_name
                      }
                      value={auditorValue}
                      onChange={(e, value) => {
                        data.auditor = value?._id || null;
                        setAuditorValue(value);
                        setRender((prev) => !prev);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                          }}
                        />
                      )}
                    />
                  )}
                </th>
              </tr>
              <tr>
                <th colSpan={1} style={{ border: "1px solid black" }}>
                  Part No.:
                </th>
                <th colSpan={2}>{part_number || ""}</th>
                <th> </th>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "S.No.",
                  "Look At",
                  "Look For",
                  "Observation",
                  "C / NC",
                  "Remarks",
                ].map((heading, index) => (
                  <th
                    key={index + 1.0001}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
              {data?.processes?.map((item, index) => (
                <tr key={index + "data"}>
                  <th style={{ textAlign: "center", width: "100px" }}>
                    {item?.s_no}
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      width: "25%",
                    }}
                  >
                    {item?.look_at}
                  </th>
                  <th
                    style={{
                      textAlign: "left",
                      width: "25%",
                      // minWidth: "300px",
                    }}
                  >
                    {/* {item?.look_for} */}
                    {item?.look_for &&
                      item.look_for.split(/\n/)?.map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      width: "150px",
                    }}
                  >
                    {isView ? (
                      item?.obs
                    ) : (
                      <input
                        type="text"
                        name="obs"
                        value={item?.obs}
                        autoComplete="off"
                        className="input"
                        style={{ border: "2px solid #f9e0e0" }}
                        onChange={(e) => {
                          handleChangeChecklistAudit(e, index);
                        }}
                      />
                    )}
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      width: "150px",
                      textTransform: "uppercase",
                    }}
                  >
                    {isView ? (
                      item?.status_c_nc
                    ) : (
                      <FormControl
                        style={{
                          width: "150px",
                          border: "none",
                        }}
                        fullWidth={true}
                        variant="filled"
                        classes={{
                          root: classes.root,
                        }}
                      >
                        <InputLabel>Select C / NC</InputLabel>
                        <Select
                          value={item?.status_c_nc || ""}
                          name="status_c_nc"
                          classes={{
                            root: classes.root,
                            select: classes.select,
                          }}
                          // key={autocompleteData.department_id}
                          style={{ fontSize: "0.8rem" }}
                          disableUnderline
                          onChange={(e) => {
                            handleChangeChecklistAudit(e, index);
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="c">C</MenuItem>
                          <MenuItem value="nc">NC</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                      width: "150px",
                    }}
                  >
                    {isView ? (
                      item?.remarks
                    ) : (
                      <input
                        type="text"
                        name="remarks"
                        value={item?.remarks}
                        autoComplete="off"
                        className="input"
                        style={{ border: "2px solid #f9e0e0" }}
                        onChange={(e) => {
                          handleChangeChecklistAudit(e, index);
                        }}
                      />
                    )}
                  </th>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={3}>Auditor Signature:</th>
                <th colSpan={3}>Auditee/s Signature:</th>
              </tr>
            </tfoot>
          </Table>
        </TableContainerStyled>
      </div>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </section>
  );
};

export default EditViewChecklist;
