import React from "react";
import { Wrapper } from "../../Control/Flexbox";
import Input from "../../Control/Input";

const DesignRecord = ({
  setImage1,
  setImage2,
  setImage3,
  setImage4,
  setImage5,
}) => {
  return (
    <Wrapper style={{ padding: "2rem 1rem" }}>
      <div>
        <Input
          label="Image 1"
          name="img_1"
          type="file"
          onChange={(e) => setImage1(e.target.files[0])}
        />
      </div>
      <div>
        <Input
          label="Image 2"
          name="img_2"
          type="file"
          onChange={(e) => setImage2(e.target.files[0])}
        />
      </div>
      <div>
        <Input
          label="Image 3"
          name="img_3"
          type="file"
          onChange={(e) => setImage3(e.target.files[0])}
        />
      </div>
      <div>
        <Input
          label="Image 4"
          name="img_4"
          type="file"
          onChange={(e) => setImage4(e.target.files[0])}
        />
      </div>
      <div>
        <Input
          label="Image 5"
          name="img_5"
          type="file"
          onChange={(e) => setImage5(e.target.files[0])}
        />
      </div>
    </Wrapper>
  );
};

export default DesignRecord;
