import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useHistory } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import formatDate from "../../../components/Control/formatDate";
import { useAuth } from "../../../context/GlobalContext";
import PrintIcon from "@material-ui/icons/Print";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";
import useConfirm from "../../../components/Control/useConfirm";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:98%;
  margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    p{
      margin:0;
    }
    span{
      font-weight:500;
    }

`;

function tConv24(time24) {
  if (time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }
}

function TablePir() {
  const { id } = useParams();
  const [pirData, setPirData] = useState("");

  // const dateForXlx = formatDate(pirData.pir_date);
  // console.log(pirData);

  useEffect(() => {
    async function fetchPir() {
      try {
        const res = await axios.get(`/api/getPirById/${id}`);
        let pirdata = res.data;
        let changedPir = pirdata.pir.filter((item) => item.list.length > 0);
        pirdata.pir = changedPir;
        setPirData(pirdata);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPir();
    // eslint-disable-next-line
  }, []);
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  return (
    <>
      <div style={{ paddingBottom: "4rem" }}>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            PROCESS INSPECTION REPORT
          </Typography>
          <div style={{ display: "flex" }}>
            <Wrapper>
              <Tooltip title="Print table" arrow>
                <PrintIcon
                  style={{
                    color: "white",
                    cursor: "pointer",
                    fontSize: "1.9rem",
                    marginRight: "0.6rem",
                  }}
                  onClick={printTable}
                />
              </Tooltip>
              <Tooltip title="Export as spreadsheet" arrow>
                <div>
                  <Button
                    style={{
                      backgroundColor: "#161a1d",
                      borderColor: "#161a1d",
                      color: "white",
                      width: "fit-content",
                      borderRadius: "0",
                      paddingTop: "9px",
                    }}
                    onClick={() => {
                      TableToExcel.convert(
                        document.getElementById("table-to-xls"),
                        {
                          name: `PIR ${pirData.part_id.part_name} ${formatDate(
                            pirData.pir_date
                          )}.xlsx`,
                          sheet: {
                            name: "PIR Sheet",
                          },
                        }
                      );
                    }}
                  >
                    <Icon path={mdiFileExcel} size={1.2} />
                  </Button>
                </div>
              </Tooltip>
            </Wrapper>
          </div>
        </div>
        <Table
          cellSpacing="0"
          style={{ marginTop: "0.6rem" }}
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="7, 14, 17, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8, 8"
        >
          <tbody>
            <tr data-height="100">
              <th
                colSpan={23}
                rowSpan="2"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  height: "100px",
                }}
              >
                <CenteredLogoImg>
                  <img src={images.arcLogo} alt={textInputs.companyName} />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                DOCUMENT NO:
              </th>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                ARC/F/QAD/08
              </th>
            </tr>

            <tr>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                Rev. No/Date:-
              </th>
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
              >
                00/01.10.2017
              </th>
            </tr>
          </tbody>
          {pirData?.pir?.map((pir, index) => (
            <tbody>
              <tr data-height="40">
                <td
                  colSpan={27}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                  data-fill-color="FF000000"
                >
                  INPROCESS INSPECTION REPORT - {pir.process_name}
                </td>
              </tr>
              <tr data-height="30">
                <th
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={7}
                >
                  Part Name:
                </th>
                <td
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={7}
                >
                  {pirData.part_number}
                </td>
                <th
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={7}
                >
                  Part No:
                </th>
                <td
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={6}
                >
                  {pirData.part_name}
                </td>
              </tr>
              <tr data-height="30">
                <th
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={7}
                >
                  Customer Name: {pirData.customer}
                </th>
                <td
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={7}
                ></td>
                <th
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={7}
                >
                  Date:
                </th>
                <td
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={6}
                >
                  {formatDate(pirData.pir_date)}
                </td>
              </tr>
              <tr data-height="30">
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={14}
                  align="left"
                ></th>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={13}
                  align="left"
                >
                  Lot no. {pir.prod_order_no}
                </th>
              </tr>
              <tr data-height="30">
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={14}
                  align="left"
                ></th>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={13}
                  align="left"
                >
                  Total Qty. {pir.producedQty}
                </th>
              </tr>
              <tr data-height="30">
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={14}
                  align="left"
                ></th>
                <th
                  style={{ border: "1px solid black" }}
                  colSpan={13}
                  align="left"
                >
                  Heat no. {pir.tool_no}
                </th>
              </tr>

              <tr data-height="30">
                <th
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={2}
                >
                  SFG P.O NO: <span>{pir.prod_order_no}</span>
                </th>
                <th colSpan={1}>
                  DATE: <span>{formatDate(pir.processDate)}</span>
                </th>
                <th colSpan={4}>
                  SHIFT:{" "}
                  <span>
                    {/* {tConv24(pir.start_time)} - {tConv24(pir.end_time)} */}
                    {pir.shift_time}
                  </span>
                </th>
                <th
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={20}
                >
                  REMARKS: <span>{pir.remarks}</span>
                </th>
              </tr>
              <tr data-height="50">
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={3}
                >
                  S.NO
                </th>

                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={3}
                >
                  PARAMETER
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  rowSpan={3}
                >
                  <p>SPECIFICATION</p>
                  <span>(REQUIRED VALUE)</span>
                </th>

                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  colSpan={24}
                >
                  OBSERVATIONS (IN RANGE)
                </th>
              </tr>
              <tr data-height="30">
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  colSpan={6}
                >
                  <p>INSPECTION #1</p>{" "}
                  <span>TIME - {tConv24(pir.first_check_time)}</span>
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  colSpan={6}
                >
                  <p>INSPECTION #2</p>{" "}
                  <span>TIME - {tConv24(pir.second_check_time)}</span>
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  colSpan={6}
                >
                  <p>INSPECTION #3</p>{" "}
                  <span>TIME - {tConv24(pir.third_check_time)}</span>
                </th>
                <th
                  style={{ border: "1px solid black", textAlign: "center" }}
                  align="center"
                  colSpan={6}
                >
                  <p>INSPECTION #4</p>{" "}
                  <span>TIME - {tConv24(pir.fourth_check_time)}</span>
                </th>
              </tr>
              <tr data-height="30">
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  1
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  2
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  3
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  4
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  5
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  Status
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  1
                </th>

                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  2
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  3
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  4
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  5
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  Status
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  1
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  2
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  3
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  4
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  5
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  Status
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  1
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  2
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  3
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  4
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  5
                </th>
                <th
                  style={{
                    width: "60px",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  align="center"
                >
                  Status
                </th>
              </tr>
              {pir.list?.map((spec, index) => (
                <tr data-height="35">
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {index + 1}
                  </td>

                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {spec.parameter}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                    }}
                    align="center"
                  >
                    {spec.specification}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.first_check_p1_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.first_check_p1} {spec.first_check_p1 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.first_check_p2_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.first_check_p2} {spec.first_check_p2 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.first_check_p3_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.first_check_p3} {spec.first_check_p3 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.first_check_p4_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.first_check_p4} {spec.first_check_p4 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.first_check_p5_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.first_check_p5} {spec.first_check_p5 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        (spec.firstcheck_result == "OK" && "#a4d1a4") ||
                        (spec.firstcheck_result == "NOT OK" && "#f7aeae"),
                    }}
                    align="center"
                  >
                    {spec.firstcheck_result}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.second_check_p1_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.second_check_p1} {spec.second_check_p1 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.second_check_p2_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.second_check_p2} {spec.second_check_p2 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.second_check_p3_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.second_check_p3} {spec.second_check_p3 && spec.unit}
                  </td>

                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.second_check_p4_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.second_check_p4} {spec.second_check_p4 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.second_check_p5_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.second_check_p5} {spec.second_check_p5 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        (spec.secondcheck_result == "OK" && "#a4d1a4") ||
                        (spec.secondcheck_result == "NOT OK" && "#f7aeae"),
                    }}
                    align="center"
                  >
                    {spec.secondcheck_result}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.third_check_p1_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.third_check_p1} {spec.third_check_p1 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.third_check_p2_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.third_check_p2} {spec.third_check_p2 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.third_check_p3_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.third_check_p3} {spec.third_check_p3 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.third_check_p4_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.third_check_p4} {spec.third_check_p4 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.third_check_p5_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.third_check_p5} {spec.third_check_p5 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        (spec.thirdcheck_result == "OK" && "#a4d1a4") ||
                        (spec.thirdcheck_result == "NOT OK" && "#f7aeae"),
                    }}
                    align="center"
                  >
                    {spec.thirdcheck_result}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.fourth_check_p1_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.fourth_check_p1} {spec.fourth_check_p1 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.fourth_check_p2_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.fourth_check_p2} {spec.fourth_check_p2 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.fourth_check_p3_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.fourth_check_p3} {spec.fourth_check_p3 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.fourth_check_p4_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.fourth_check_p4} {spec.fourth_check_p4 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        spec.fourth_check_p5_color === "#f7aeae"
                          ? "#fbfbac"
                          : "",
                    }}
                    align="center"
                  >
                    {spec.fourth_check_p5} {spec.fourth_check_p5 && spec.unit}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      backgroundColor:
                        (spec.fourthcheck_result == "OK" && "#a4d1a4") ||
                        (spec.fourthcheck_result == "NOT OK" && "#f7aeae"),
                    }}
                    align="center"
                  >
                    {spec.fourthcheck_result}
                  </td>
                </tr>
              ))}

              {/* <tr>
              <th
                style={{ border: "1px solid black" }}
                align="center"
                colSpan={maxCol / pir.list.length}
              >
                Inspection NO:
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="center"
                colSpan={maxCol / pir.list.length}
              >
                DATE & TIME:
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="center"
                colSpan={maxCol / pir.list.length}
              >
                SHIFT:
              </th>
              <th
                style={{ border: "1px solid black" }}
                align="center"
                colSpan={maxCol / pir.list.length}
              >
                SFG P.O NO:
              </th>
              {pir.list.map((spec) => (
                <th
                  style={{ border: "1px solid black" }}
                  align="center"
                  colSpan={maxCol / pir.list.length}
                >
                  {spec.parameter}
                </th>
              ))}
            </tr> */}
            </tbody>
          ))}
          <tr style={{ height: "60px" }} data-height="35">
            <td
              align="left"
              colSpan={2}
              style={{ fontWeight: 500, border: "1px solid black" }}
            >
              Prepared By:
            </td>
            <td
              align="left"
              colSpan={12}
              style={{ border: "1px solid black", textTransform: "capitalize" }}
            >
              {pirData.report_prepared_by}
            </td>

            <td
              align="left"
              colSpan={2}
              style={{ fontWeight: 500, border: "1px solid black" }}
            >
              Approved By:
            </td>
            <td
              align="left"
              style={{ border: "1px solid black", textTransform: "capitalize" }}
              colSpan={11}
            >
              {pirData.is_admin_request === "accepted" &&
                pirData.report_approved_by}
            </td>
          </tr>
        </Table>
      </div>
    </>
  );
}

export default TablePir;
