import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { useAuth } from "../../context/GlobalContext";
import formatDate from "../../components/Control/formatDate";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";
import Styled from "styled-components";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import TableToExcel from "@dayalk/table-to-excel";

const cellStyle = {
  border: "1px solid black",
  textAlign: "center",
  textTransform: "uppercase",
};

const Table = Styled.table`
	margin-bottom: 1rem;
  width: 100%;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

`;

// Function to calculate the duration between two moments, handling null cases
const calculateDuration = (start, end) => {
  if (!start || !end) {
    return moment.duration(0); // Return zero duration if either input is null
  }
  const date_1 = moment(start);
  const date_2 = moment(end);
  // Check if time2 is earlier than or equal to time1
  if (date_2.isSameOrBefore(date_1)) {
    date_2.add(1, "day"); // Add 1 day to time2 to make it later than time1
  }

  return moment.duration(moment(date_2).diff(date_1));
};

function calculateWorkingHour(time1, time2) {
  const newWorkingHours = calculateDuration(time1, time2);

  // Format the result as "XX hr XX min"
  const hours = newWorkingHours.hours();
  const minutes = newWorkingHours.minutes();
  const formattedResult = `${hours} hr ${minutes} min`;

  return formattedResult;
}

function formatDateForTime(date) {
  const newDate = new Date(date);
  if (isNaN(newDate)) {
    return "";
  }
  let hour = newDate.getHours();
  const min = newDate.getMinutes().toString().padEnd(2, 0);
  let meridian = "AM";
  if (hour > 12) {
    hour = hour - 12;
    meridian = "PM";
  }
  if (hour === 12) {
    meridian = "PM";
  }
  if (hour === 0) {
    hour = 12;
    meridian = "AM";
  }
  return `${hour}:${min} ${meridian}`;
}

const ViewDailyProductionTable = () => {
  const [dailyProductionData, setDailyProductionData] = useState({
    dailyProduction_date: new Date(),
    processes: new Array(50).fill().map((_) => ({
      breakdown_attended: "",
      breakdown_qty: "",
      breakdown_reason: "",
      breakdown_remarks: "",
      breakdown_work_done: "",
      spare_parts: "",
    })),
  });
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/getOneDailyProduction/${id}`)
      .then((res) => {
        setDailyProductionData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  const handleNewWorkingHours = (
    working_in_time,
    working_out_time,
    breakdown_time_from,
    breakdown_time_to,
    stopage_from_time,
    stopage_to_time,
    index,
  ) => {
    // Calculate working hours duration
    const workingHours = calculateDuration(working_in_time, working_out_time);

    // Calculate planned stoppage duration
    const stoppageHours = calculateDuration(stopage_from_time, stopage_to_time);

    // Calculate breakdown duration
    const breakdownHours = calculateDuration(
      breakdown_time_from,
      breakdown_time_to,
    );

    // Calculate net working hours by subtracting stoppage and breakdown time
    const newWorkingHours = moment.duration(
      workingHours - stoppageHours - breakdownHours,
    );

    dailyProductionData.processes[index].net_working_hours = newWorkingHours;

    // Format the result as "XX hr XX min"
    const hours = newWorkingHours.hours();
    const minutes = newWorkingHours.minutes();
    const formattedResult = `${hours} hr ${minutes} min`;

    return formattedResult;
  };

  const calculateWorkingEff = (net_working_hours, production, cycle_time) => {
    if (!net_working_hours || !production || !cycle_time) {
      return "";
    }
    const net_working_hours_in_sec = moment
      .duration(net_working_hours, "hours")
      .asSeconds();

    return (
      (100 * Number(production)) /
      (net_working_hours_in_sec / Number(cycle_time))
    ).toFixed(2);
  };

  return (
    <section>
      <div style={{ paddingBottom: "4rem" }}>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            DAILY PRODUCTION REPORT
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip arrow title="Print table">
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip>
            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: "Daily Production.xlsx",
                        sheet: {
                          name: "Daily Production",
                        },
                      },
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
        <div
          style={{
            margin: "0.1rem 0.5rem",
            paddingBottom: "4rem",
            overflowX: "scroll",
          }}
        >
          <Table
            cellSpacing="0"
            style={{ marginTop: "0.6rem", backgroundColor: "white" }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 15, 17, 19, 20, 8, 10, 10, 12, 10, 14, 12, 12, 15, 14, 12, 12, 12, 14, 14, 12, 14, 14, 12 ,12, 12"
          >
            <thead>
              <tr data-height="80" style={{ height: "80px" }}>
                <th
                  colSpan="28"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  colSpan="3"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                ></th>
              </tr>

              <tr>
                <th
                  colSpan="28"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  DAILY PRODUCTION REPORT
                </th>

                <th
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REV. NO.:- 00/01/07/2018
                </th>
              </tr>
              <tr data-height="25">
                <th
                  colSpan={8}
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  DATE: {formatDate(dailyProductionData?.dailyProduction_date)}
                </th>
                <th
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  SHIFT:
                </th>
                <th
                  colSpan={6}
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {dailyProductionData?.shift}
                </th>
                <th
                  colSpan={5}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  DEPARTMENT:
                </th>
                <th
                  colSpan={10}
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {dailyProductionData?.department}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "M/C NO.",
                  "OPERATOR NAME",
                  "PART NO.",
                  "PART NAME",
                  "OPERATION NO",
                  "LOT NO.",
                  "IN TIME",
                  "OUT TIME",
                  "WORKING HOUR",
                  "CYCLE TIME (IN SEC)",
                  "PRODUCTION",
                  "REJECTION",
                  "Reason of Failure",
                  "Planned Stoppage Reason",
                  "Planned Stoppage Time",
                  "Net Working Hours",
                  "BREAKDOWNS",
                  "BREAKDOWN REASON",
                ].map((heading, index) => (
                  <th
                    key={index}
                    rowSpan={heading === "Planned Stoppage Time" ? 1 : 2}
                    colSpan={heading === "Planned Stoppage Time" ? 3 : 1}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
                <th style={cellStyle} colSpan={2}>
                  BREAKDOWN TIME
                </th>
                <th style={cellStyle} rowSpan={2}>
                  M/C HELD UP TIME
                </th>
                <th style={cellStyle} rowSpan={2}>
                  BREAKDOWN ATTENDED BY
                </th>
                <th style={cellStyle} rowSpan={2}>
                  BREAKDOWN WORK DONE
                </th>
                <th style={cellStyle} rowSpan={2}>
                  SPARE PARTS USED
                </th>
                <th style={cellStyle} rowSpan={2}>
                  BREAKDOWN QTY
                </th>
                <th style={cellStyle} rowSpan={2}>
                  BREAKDOWN REMARKS
                </th>
                <th style={cellStyle} rowSpan={2}>
                  REWORK
                </th>
                <th style={cellStyle} rowSpan={2}>
                  WORK EFF %
                </th>
                <th style={cellStyle} rowSpan={2}>
                  REMARK
                </th>
              </tr>
              <tr data-height="40">
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  FROM
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  TO
                </th>
                <th></th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  FROM
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  TO
                </th>
              </tr>
            </tbody>
            {dailyProductionData &&
              dailyProductionData.processes
                .filter((doc) => doc.machine_no)
                .map((item, index) => (
                  <tr key={item._id || index} height="50px" data-height="60">
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.machine_no}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.operator_name}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.part_id?.part_number}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.part_name}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.process_name}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.lot_no}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black", minWidth: "90px" }}
                      data-f-sz="10"
                    >
                      {formatDateForTime(item.in_time)}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black", minWidth: "90px" }}
                      data-f-sz="10"
                    >
                      {formatDateForTime(item.out_time)}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black", minWidth: "120px" }}
                      data-f-sz="10"
                    >
                      {calculateWorkingHour(item.in_time, item.out_time)}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.cycle_time}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.production}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.rejection}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.reason}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.planned_stopage_reason}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black", minWidth: "100px" }}
                      data-f-sz="10"
                    >
                      {formatDateForTime(item.stopage_from_time)}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black", minWidth: "100px" }}
                      data-f-sz="10"
                    >
                      {formatDateForTime(item.stopage_to_time)}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black", minWidth: "100px" }}
                      data-f-sz="10"
                    >
                      {calculateWorkingHour(
                        item.stopage_from_time,
                        item.stopage_to_time,
                      )}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black", minWidth: "100px" }}
                      data-f-sz="10"
                    >
                      {handleNewWorkingHours(
                        item.in_time,
                        item.out_time,
                        item.breakdown_time_from,
                        item.breakdown_time_to,
                        item.stopage_from_time,
                        item.stopage_to_time,
                        index,
                      )}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.breakdown}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.breakdown_reason === "OTHER"
                        ? item.breakdown_reason_other
                        : item.breakdown_reason}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {formatDateForTime(item.breakdown_time_from)}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {formatDateForTime(item.breakdown_time_to)}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                        minWidth: "90px",
                      }}
                    >
                      {calculateWorkingHour(
                        item.breakdown_time_from,
                        item.breakdown_time_to,
                      )}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                        minWidth: "90px",
                      }}
                    >
                      {item.breakdown_attended}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                        minWidth: "90px",
                      }}
                    >
                      {item.breakdown_work_done}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                        minWidth: "90px",
                      }}
                    >
                      {item.spare_parts}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                        minWidth: "90px",
                      }}
                    >
                      {item.breakdown_qty}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                        minWidth: "90px",
                      }}
                    >
                      {item.breakdown_remarks}
                    </td>
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.rework}
                    </td>

                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {calculateWorkingEff(
                        item.net_working_hours,
                        item.production,
                        item.cycle_time,
                      )}
                    </td>
                    {/* <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.oee}
                  </td> */}
                    {/* <td
                    align="center"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    {item.operator_eff}
                  </td> */}
                    <td
                      align="center"
                      style={{ border: "1px solid black" }}
                      data-f-sz="10"
                    >
                      {item.remark}
                    </td>
                  </tr>
                ))}
            <tfoot>
              <tr style={{ height: "60px" }} data-height="35">
                <td
                  align="left"
                  colSpan={2}
                  style={{ fontWeight: 500, border: "1px solid black" }}
                >
                  Prepared By:
                </td>
                <td
                  align="left"
                  colSpan={12}
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                >
                  {dailyProductionData.report_prepared_by}
                </td>

                <td
                  align="left"
                  colSpan={2}
                  style={{ fontWeight: 500, border: "1px solid black" }}
                >
                  Approved By:
                </td>
                <td
                  align="left"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                  colSpan={16}
                >
                  {dailyProductionData.is_admin_request === "accepted" &&
                    dailyProductionData.report_approved_by}
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
    </section>
  );
};

export default ViewDailyProductionTable;
