import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import { Add, Check } from "@material-ui/icons";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

function ControlPlan({ ppapData }) {
  const [data, setData] = useState([]);
  const classes = useStyles();
  let partId = ppapData.part_id?._id;
  const [render, setRender] = useState(false);
  useEffect(() => {
    axios
      .get(`/api/get/revisedTable/controlplan/${partId}`)
      .then((data) => setData(data.data))
      .catch((err) => console.log(err.message));
  }, []);

  return (
    <div style={{ paddingBottom: "4rem", padding: "3rem 2rem" }}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Item Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Type
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Revision number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <React.Fragment key={item._id}>
              <TableRow className={classes.rootRow}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item.part_id.part_number}</TableCell>
                <TableCell align="center">
                  {item.revision_no > 0 ? "Revised" : "Original"}
                </TableCell>
                <TableCell align="center">{item.revision_no}</TableCell>
                <TableCell align="center">
                  {ppapData.control_id === item._id ? (
                    <Tooltip title="Status" arrow>
                      <IconButton size="small">
                        <Check style={{ fontSize: "1.4rem", color: "green" }} />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add" arrow>
                      <IconButton
                        size="small"
                        onClick={() => {
                          ppapData.control_id = item._id;
                          setRender((prev) => !prev);
                        }}
                      >
                        <Add style={{ fontSize: "1.4rem", color: "maroon" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default ControlPlan;
