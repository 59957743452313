import React from "react";
import { Link } from "react-router-dom";
import clausePage from "./ClausePage.module.css";
//icons
import NoteIcon from "@material-ui/icons/Note";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import ListIcon from '@material-ui/icons/List';
// end icons

const Clause6 = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        Quality Audit And Process Verification
      </div>
      <main className={`${clausePage.container} ${clausePage.main}`}>
        <div>
          <Link to="/masterProcedureList?category=clause-6" className="panel">
            <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Master <span className="card-btn-span">Procedure</span>
          </p>
        </div>
        <div>
          <Link to="/processProductData/table" className="panel">
            <NoteIcon style={{ fontSize: "3rem", color: "#003566" }} />
          </Link>
          <p className="card-btn-para">
            Process/Product
            <span className="card-btn-span">Validation</span>
          </p>
        </div>
        <div>
          <Link to="/internalAudit" className="panel">
            <SupervisedUserCircleIcon
              style={{ fontSize: "3rem", color: "#003566" }}
            />
          </Link>
          <p className="card-btn-para">
            Internal<span className="card-btn-span">Audit</span>
          </p>
        </div>
      </main>
    </>
  );
};

export default Clause6;
