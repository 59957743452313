const handleResult = (spec, obsNumber) => {
  const isNotOk = spec[`check_${obsNumber}_colors`].find(
    (item) => item === "#f7aeae",
  );
  const isOk = spec[`check_${obsNumber}_colors`].find(
    (item) => item === "#a4d1a4",
  );
  spec.status = (isNotOk && "ng") || (isOk && "ok") || "";
};

const handleChangeSpec = (e, spec, obsIndex, obsNumber) => {
  const value = e.target.value;
  spec[`check_${obsNumber}_values`][obsIndex] = value;
  if (e.target.name === "dropdown") {
    spec[`check_${obsNumber}_colors`][obsIndex] =
      (value === "ok" && "#a4d1a4") || (value === "ng" && "#f7aeae") || "";
  }

  let mean = spec.mean;
  let utolerance = spec.utolerance || 0;
  let ltolerance = spec.ltolerance || 0;
  let old_utl = spec.utl;
  let old_ltl = spec.ltl;
  let check4_symbol = spec.check4_symbol;
  let checkbox4_num = spec.checkbox4_num;
  let utl;
  let ltl;
  let uv;
  let lv;

  //max
  if (check4_symbol && checkbox4_num) {
    if (check4_symbol === "<=") {
      if (value <= checkbox4_num) {
        spec[`check_${obsNumber}_colors`][obsIndex] = "#a4d1a4";
      } else {
        spec[`check_${obsNumber}_colors`][obsIndex] = "#f7aeae";
      }
    }
    //min
    if (check4_symbol === ">=") {
      if (value >= checkbox4_num) {
        spec[`check_${obsNumber}_colors`][obsIndex] = "#a4d1a4";
      } else {
        spec[`check_${obsNumber}_colors`][obsIndex] = "#f7aeae";
      }
    }
  } else {
    if (old_utl || old_ltl) {
      utl = parseFloat(old_utl);
      ltl = parseFloat(old_ltl);
      const mean = (utl + ltl) / 2;
      uv = (mean + utl) / 2;
      lv = (mean + ltl) / 2;
    } else {
      utl = parseFloat(mean) + parseFloat(utolerance);
      ltl = parseFloat(mean) - parseFloat(ltolerance);

      uv = parseFloat(mean) + parseFloat(utolerance) / 2;
      lv = parseFloat(mean) - parseFloat(ltolerance) / 2;
    }
    if (value > utl || value < ltl) {
      spec[`check_${obsNumber}_colors`][obsIndex] = "#f7aeae";
    } else if ((uv < value && value <= utl) || (lv > value && value >= ltl)) {
      spec[`check_${obsNumber}_colors`][obsIndex] = "#a4d1a4";
    } else if (value >= lv && value <= uv) {
      spec[`check_${obsNumber}_colors`][obsIndex] = "#a4d1a4";
    }
  }

  if (value === "") {
    spec[`check_${obsNumber}_colors`][obsIndex] = "";
  }
  handleResult(spec, obsNumber);
};
export default handleChangeSpec;
