import PartTable from "./PartTable";

function ViewPartTable() {
  return (
    <>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        PART LIST
      </div>
      <PartTable />
    </>
  );
}

export default ViewPartTable;
