import { Checkbox, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Wrapper } from "../../Control/Flexbox";
import Input from "../../Control/Input";
import { Select, InputLabel, MenuItem, FormControl } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";

const Warrant = ({ ppapData }) => {
  const [render, setRender] = useState(false);
  const handleChange = (e) => {
    let value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    ppapData[e.target.name] = value;
  };
  return (
    <main style={{ paddingBottom: "2rem", marginTop: "1rem" }}>
      {/* mis. header */}
      <div>
        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="draw_no"
            label="Drawing No."
            onChange={handleChange}
            defaultValue={ppapData.draw_no}
            style={{ width: "30rem" }}
          />
          <Input
            type="text"
            name="org_part_no"
            label="Org. Part No."
            onChange={handleChange}
            defaultValue={ppapData.org_part_no}
            style={{ width: "30rem" }}
          />
        </Wrapper>

        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="change_level"
            label="Engineering Change Level"
            onChange={handleChange}
            defaultValue={ppapData.change_level}
            style={{ width: "30rem" }}
          />
          <DatePicker
            value={ppapData.psw_engg_change_date || null}
            label="Engg. Change Level Dated"
            className="date-field"
            inputVariant="filled"
            format="dd/MM/yyyy"
            onChange={(date) => {
              ppapData.psw_engg_change_date = date;
              setRender((prev) => !prev);
            }}
            style={{ width: "30rem" }}
          />
        </Wrapper>
        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="eng_change"
            label="Additional Engineering Changes"
            onChange={handleChange}
            defaultValue={ppapData.eng_change}
            style={{ width: "30rem" }}
          />
          <DatePicker
            value={ppapData.psw_additional_engg_date || null}
            label="Additional Engg. Change Dated"
            className="date-field"
            inputVariant="filled"
            format="dd/MM/yyyy"
            onChange={(date) => {
              ppapData.psw_additional_engg_date = date;
              setRender((prev) => !prev);
            }}
            style={{ width: "30rem" }}
          />
        </Wrapper>
        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <FormControl
            style={{
              width: "30rem",
              marginTop: "1rem",
            }}
            variant="filled"
            className="select-field"
          >
            <InputLabel>Safty and/or Government Regulation</InputLabel>
            <Select
              name="psw_sagr"
              defaultValue={ppapData.psw_sagr}
              onChange={handleChange}
              className="spec-select-character"
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>

          <Input
            type="text"
            name="purchase_order_no"
            label="Purchase Order No."
            onChange={handleChange}
            defaultValue={ppapData.purchase_order_no}
            style={{ width: "30rem" }}
          />
          {/* <Input
            type="text"
            name="imds_id"
            label="IMDS ID"
            onChange={handleChange}
            defaultValue={ppapData.imds_id}
          /> */}
        </Wrapper>
        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="warrant_weight"
            label="Weight"
            onChange={handleChange}
            defaultValue={ppapData.warrant_weight}
            style={{ width: "30rem" }}
          />
          <Input
            type="text"
            name="checking_aid_no"
            label="Checking Aid No."
            onChange={handleChange}
            defaultValue={ppapData.checking_aid_no}
            style={{ width: "30rem" }}
          />
        </Wrapper>
        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="checking_aid_engg"
            label="Checking Aid Engineering Change Level"
            onChange={handleChange}
            defaultValue={ppapData.checking_aid_engg}
            style={{ width: "30rem" }}
          />
          <DatePicker
            value={ppapData.psw_checking_aid_date || null}
            label="checking Aid Engg. Change Date"
            className="date-field"
            inputVariant="filled"
            format="dd/MM/yyyy"
            onChange={(date) => {
              ppapData.psw_checking_aid_date = date;
              setRender((prev) => !prev);
            }}
            style={{ width: "30rem" }}
          />
        </Wrapper>

        {/* <Wrapper style={{width:"70rem", columnGap:"10rem"}}>
          <Input
            type="text"
            name="supplier_name"
            label="Supplier Name"
            onChange={handleChange}
            defaultValue={ppapData.supplier_name}
          />
        </Wrapper> */}
      </div>

      <div>
        <p
          style={{
            margin: 0,
            marginTop: "2rem",
            marginLeft: "0.7rem",
            color: "red",
          }}
        >
          Organization Manufaturing Information
        </p>

        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="psw_org_name"
            label="Organization Name & Supplier"
            onChange={handleChange}
            defaultValue={ppapData.psw_org_name}
            style={{ width: "30rem" }}
          />
          <Input
            type="text"
            name="psw_vedor_code"
            label="Vendor Code"
            onChange={handleChange}
            defaultValue={ppapData.psw_vedor_code}
            style={{ width: "30rem" }}
          />
        </Wrapper>
        <Wrapper style={{ width: "70rem" }}>
          <Input
            type="text"
            name="street_address"
            label="Street Address"
            onChange={handleChange}
            defaultValue={ppapData.street_address}
            style={{ width: "70rem" }}
          />
        </Wrapper>
        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="psw_city"
            label="City"
            onChange={handleChange}
            defaultValue={ppapData.psw_city}
            style={{ width: "30rem" }}
          />
          <Input
            type="text"
            name="psw_region"
            label="Region"
            onChange={handleChange}
            defaultValue={ppapData.psw_region}
            style={{ width: "30rem" }}
          />
        </Wrapper>
        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="psw_postal_code"
            label="Postal Code"
            onChange={handleChange}
            defaultValue={ppapData.psw_postal_code}
            style={{ width: "30rem" }}
          />
          <Input
            type="text"
            name="psw_country"
            label="Country"
            onChange={handleChange}
            defaultValue={ppapData.psw_country}
            style={{ width: "30rem" }}
          />
        </Wrapper>
      </div>

      {/* {customer submittal information } */}
      <div>
        <p
          style={{
            margin: 0,
            marginTop: "2rem",
            marginLeft: "0.7rem",
            color: "red",
          }}
        >
          Customer Submittal Information
        </p>

        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="customer_name"
            label="Customer Name/Division"
            onChange={handleChange}
            defaultValue={ppapData.customer_name}
            style={{ width: "70rem" }}
          />
        </Wrapper>
        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="buyer"
            label="Buyer/Buyer Code"
            onChange={handleChange}
            defaultValue={ppapData.buyer}
            style={{ width: "30rem" }}
          />

          <Input
            type="text"
            name="application"
            label="Application"
            onChange={handleChange}
            defaultValue={ppapData.application}
            style={{ width: "30rem" }}
          />
        </Wrapper>
      </div>

      <div>
        <p
          style={{
            margin: 0,
            marginTop: "2rem",
            marginLeft: "0.7rem",
            color: "red",
          }}
        >
          Material Reporting
        </p>
        <Wrapper style={{ width: "70rem" }}>
          <FormControl
            style={{
              width: "70rem",
              marginRight: "0.5rem",
              marginTop: "1rem",
              marginLeft: "0.6rem",
            }}
            variant="filled"
            className="select-field"
          >
            <InputLabel>
              Has customer-required Subtances of Concern information been
              reported?
            </InputLabel>
            <Select
              name="psw_hcrsoci"
              defaultValue={ppapData.psw_hcrsoci}
              onChange={handleChange}
              className="spec-select-character"
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
              <MenuItem value="na">N/A</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
        <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
          <Input
            type="text"
            name="psw_isSubmitByImds"
            label="Submit by IMDS or other customer format:"
            onChange={handleChange}
            defaultValue={ppapData.psw_isSubmitByImds}
            style={{ width: "30rem" }}
          />
          <Input
            type="text"
            name="psw_moduleId"
            label="if submitted by IMDS, enter Module ID Number,Version and date transmitted"
            onChange={handleChange}
            defaultValue={ppapData.psw_moduleId}
            style={{ width: "30rem" }}
          />
        </Wrapper>

        <Wrapper style={{ width: "70rem" }}>
          <FormControl
            style={{
              width: "70rem",
              marginRight: "0.5rem",
              marginTop: "1rem",
              marginLeft: "0.6rem",
            }}
            variant="filled"
            className="select-field"
          >
            <InputLabel>
              Are polymeric parts identified with appropiate ISO marking codes?
            </InputLabel>
            <Select
              name="psw_appi"
              defaultValue={ppapData.psw_appi}
              onChange={handleChange}
              className="spec-select-character"
            >
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
              <MenuItem value="na">N/A</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
      </div>

      <p
        style={{
          margin: 0,
          marginTop: "2rem",
          marginLeft: "0.7rem",
          color: "red",
        }}
      >
        Reason for submission (check at least one)
      </p>

      <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
        <div style={{ width: "30rem" }}>
          <Checkbox
            defaultChecked={ppapData.wis}
            name="wis"
            color="primary"
            onChange={handleChange}
          />
          Initial submission
        </div>

        <div style={{ width: "30rem" }}>
          <Checkbox
            defaultChecked={ppapData.wcm}
            name="wcm"
            color="primary"
            onChange={handleChange}
          />
          Change to Optional Construction or Material
        </div>
      </Wrapper>
      <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
        <div style={{ width: "30rem" }}>
          <Checkbox
            defaultChecked={ppapData.wec}
            name="wec"
            color="primary"
            onChange={handleChange}
          />
          Engineering Change's
        </div>
        <div style={{ width: "30rem" }}>
          <Checkbox
            defaultChecked={ppapData.wsc}
            name="wsc"
            color="primary"
            onChange={handleChange}
          />
          Sub-Supplier or Material Source Change
        </div>
      </Wrapper>
      <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
        <div style={{ width: "30rem" }}>
          <Checkbox
            defaultChecked={ppapData.wt}
            name="wt"
            color="primary"
            onChange={handleChange}
          />
          Tooling: Transfer, Replacement, Refurbishment, or additional
        </div>
        <div style={{ width: "30rem" }}>
          <Checkbox
            defaultChecked={ppapData.wcp}
            name="wcp"
            color="primary"
            onChange={handleChange}
          />
          Change in Part Processing
        </div>
      </Wrapper>
      <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
        <div style={{ width: "30rem" }}>
          <Checkbox
            defaultChecked={ppapData.wcd}
            name="wcd"
            color="primary"
            onChange={handleChange}
          />
          Correction of Discrepancy
        </div>
        <div style={{ width: "30rem" }}>
          <Checkbox
            defaultChecked={ppapData.wpl}
            name="wpl"
            color="primary"
            onChange={handleChange}
          />
          Parts produced at Additional Location
        </div>
      </Wrapper>
      <Wrapper style={{ width: "70rem", columnGap: "10rem" }}>
        <div style={{ width: "30rem" }}>
          <Checkbox
            defaultChecked={ppapData.wty}
            name="wty"
            color="primary"
            onChange={handleChange}
          />
          {"Tooling Inactive > than 1 year"}
        </div>
        <div style={{ width: "30rem" }}>
          <Checkbox
            defaultChecked={ppapData.wos}
            name="wos"
            color="primary"
            onChange={handleChange}
          />
          Other - please specify
        </div>
      </Wrapper>
      <Wrapper style={{ width: "70rem" }}>
        <FormControl
          style={{
            width: "68rem",
            marginRight: "0.5rem",
            marginTop: "1rem",
            marginLeft: "0.6rem",
          }}
          variant="filled"
          className="select-field"
        >
          <InputLabel>REQUESTED SUBMISSION LEVEL</InputLabel>
          <Select
            name="rsl"
            defaultValue={ppapData.rsl}
            onChange={handleChange}
            className="spec-select-character"
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="Level 1">
              Level 1 - Warrant only (and for designated appearance items, an
              Appearance Approval Report) submitted to customer
            </MenuItem>
            <MenuItem value="Level 2">
              {" "}
              Level 2 - Warrant with product samples and limited supporting data
              submitted to customer
            </MenuItem>
            <MenuItem value="Level 3">
              Level 3 - Warrant with product samples and complete supporting
              data submitted to customer
            </MenuItem>
            <MenuItem value="Level 4">
              Level 4 - Warrant and complete supporting data submitted to
              customer
            </MenuItem>
            <MenuItem value="Level 5">
              Level 5 - Warrant with product samples and complete supporting
              data reviewed at supplier's manufacturing location
            </MenuItem>
          </Select>
        </FormControl>
      </Wrapper>
      <p
        style={{
          margin: 0,
          marginTop: "2rem",
          marginLeft: "0.7rem",
          color: "red",
        }}
      >
        Submission Results
      </p>
      <Wrapper style={{ width: "70rem" }}>
        <div>
          <Checkbox
            defaultChecked={ppapData.psw_dm}
            name="psw_dm"
            color="primary"
            onChange={handleChange}
          />
          Dimensional Measurements
        </div>

        <div>
          <Checkbox
            defaultChecked={ppapData.psw_mft}
            name="psw_mft"
            color="primary"
            onChange={handleChange}
          />
          Material and Functional Tests
        </div>
        <div>
          <Checkbox
            defaultChecked={ppapData.psw_ac}
            name="psw_ac"
            color="primary"
            onChange={handleChange}
          />
          Appearance Criteria
        </div>
        <div>
          <Checkbox
            defaultChecked={ppapData.psw_spp}
            name="psw_spp"
            color="primary"
            onChange={handleChange}
          />
          Statistical Process Package
        </div>
      </Wrapper>
      <Wrapper style={{ width: "70rem" }}>
        <FormControl
          style={{
            width: "100vw",
            marginRight: "0.5rem",
            marginTop: "1rem",
            marginLeft: "0.6rem",
          }}
          variant="filled"
          className="select-field"
        >
          <InputLabel>
            These results meet all drawing and specification requirements
          </InputLabel>
          <Select
            name="psw_rsl_req"
            defaultValue={ppapData.psw_rsl_req}
            onChange={handleChange}
            className="spec-select-character"
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>
      </Wrapper>
      <Wrapper style={{ width: "70rem" }}>
        <Input
          type="text"
          name="psw_pp"
          label="Mold / Cavity / Production Process"
          onChange={handleChange}
          defaultValue={ppapData.psw_pp}
          style={{
            width: "70rem",
            // margin: "1rem 0.7rem",
          }}
        />
      </Wrapper>
      <p
        style={{
          margin: 0,
          marginTop: "2rem",
          marginLeft: "0.7rem",
          color: "red",
        }}
      >
        Declaration
      </p>
      <Wrapper style={{ width: "70rem" }}>
        <TextField
          type="text"
          variant="filled"
          name="declaration"
          multiline
          className="textarea-field"
          label="Declaration"
          style={{
            width: "70rem",
            margin: "1rem 0.7rem",
          }}
          onChange={handleChange}
          defaultValue={ppapData.declaration}
        />
      </Wrapper>
      <Wrapper style={{ width: "70rem" }}>
        <TextField
          type="text"
          variant="filled"
          name="notes"
          multiline
          className="textarea-field"
          label="Comments"
          style={{
            width: "70rem",
            margin: "1rem 0.7rem",
          }}
          onChange={handleChange}
          defaultValue={ppapData.notes}
        />
      </Wrapper>
      <Wrapper style={{ width: "70rem" }}>
        <FormControl
          style={{
            width: "70rem",
            marginRight: "0.5rem",
            marginTop: "1rem",
            marginLeft: "0.6rem",
          }}
          variant="filled"
          className="select-field"
        >
          <InputLabel>
            Is each Customer Tool properly tagged and numbered?
          </InputLabel>
          <Select
            name="psw_ctptan"
            defaultValue={ppapData.psw_ctptan}
            onChange={handleChange}
            className="spec-select-character"
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
            <MenuItem value="na">N/A</MenuItem>
          </Select>
        </FormControl>
      </Wrapper>
      <Wrapper style={{ width: "70rem" }}>
        <Input
          type="text"
          name="tool_order"
          label="Tool Order No."
          onChange={handleChange}
          defaultValue={ppapData.tool_order}
        />
        <Input
          type="text"
          name="print_name"
          label="Print Name"
          onChange={handleChange}
          defaultValue={ppapData.print_name}
        />
        <Input
          type="text"
          name="title"
          label="Title"
          onChange={handleChange}
          defaultValue={ppapData.title}
        />
        <Input
          type="text"
          name="phone"
          label="Phone"
          onChange={handleChange}
          defaultValue={ppapData.phone}
        />
        <Input
          type="text"
          name="fax"
          label="Fax"
          onChange={handleChange}
          defaultValue={ppapData.fax}
        />
      </Wrapper>
      <Wrapper style={{ width: "70rem" }}>
        <Input
          type="text"
          name="email_address"
          label="E-mail"
          onChange={handleChange}
          defaultValue={ppapData.email_address}
          style={{ width: "20rem" }}
        />
        <DatePicker
          value={ppapData.approve_date || null}
          label="Date"
          className="date-field"
          inputVariant="filled"
          format="dd/MM/yyyy"
          onChange={(date) => {
            ppapData.approve_date = date;
            setRender((prev) => !prev);
          }}
        />
      </Wrapper>
    </main>
  );
};

export default Warrant;
