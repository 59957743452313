import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
import AddIcon from "@material-ui/icons/Add";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../components/Control/useDebounce";
import { useAuth } from "../../context/GlobalContext";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Input from "../../components/Control/Input";
import { Search } from "@material-ui/icons";

import axios from "axios";
const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});
const DuplicateTable = ({ setDuplicateOpenModal }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState([]);
  const [perPage, setPerPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("part_number");
  const { state } = useAuth();

  const fetchPartData = () => {
    axios
      .get(
        `api/getDuplicate/partData?page=${page}&sortBy=${filter}&search=${search}&perPage=10`,
        {
          headers: { isFullAdmin: state?.user?.isFullAdmin },
        }
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setPartData(res.data?.results);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);

  const handleDuplicatePart = (partId, index) => {
    axios
      .post(`api/duplicatePart/add/${partId}`)
      .then((res) => {
        alert("Part duplicated successfully");
        setDuplicateOpenModal(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <Wrapper>
        <div style={{ flex: "0.8" }}>
          <Input
            placeholder="Search item Number"
            variant="filled"
            style={{
              width: "95%",
            }}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <FormControl
          variant="filled"
          className="select-field"
          style={{ marginRight: "1.5rem", marginTop: "0.1rem", flex: "0.2" }}
        >
          <InputLabel>Filter</InputLabel>
          <Select
            name="part_type"
            onChange={(e) => setFilter(e.target.value)}
            defaultValue={filter}
          >
            <MenuItem value="part_number">A - Z</MenuItem>
            <MenuItem value="createdAt">Most Recent First</MenuItem>
          </Select>
        </FormControl>
      </Wrapper>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Item Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Item Name/Description
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Created At
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Status
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {partData && (
          <TableBody>
            {partData.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.part_number}</TableCell>
                  <TableCell align="center">{item.part_name}</TableCell>

                  <TableCell align="center">
                    {formatDate(item.createdAt)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "0.9rem",
                      outline: "none",
                      padding: "5px",
                      marginLeft: "0.5rem",
                      width: "150px",
                      textAlign: "center",
                      textTransform: "capitalize",
                      ...(item.isSubmitted === "draft" && {
                        backgroundColor: "#FDD835",
                        borderBottom: "1px solid #fff",
                      }),
                      ...(item.isSubmitted === "publish" && {
                        backgroundColor: "#2b8a3e",
                        color: "#fff",
                        borderBottom: "1px solid #fff",
                      }),
                    }}
                  >
                    {item.isSubmitted.toUpperCase()}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="Duplicate Part" arrow>
                      <IconButton
                        size="small"
                        onClick={() => handleDuplicatePart(item._id, index)}
                      >
                        <FileCopyIcon
                          style={{ fontSize: "1.4rem", color: "#003566" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "4rem",
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, number) => setPage(number)}
        />
      </div>
    </div>
  );
};

export default DuplicateTable;
