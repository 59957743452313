import React, { useState } from "react";
import { Wrapper } from "../../components/Control/Flexbox";
import Input from "../../components/Control/Input";
import IncomingChecks from "./IncomingChecks";

function checkResults(incoming) {
  if (
    incoming.appearance === "check1" ||
    incoming.appearance === "check2" ||
    incoming.appearance === "check4"
  ) {
    incoming.values.forEach((val, idx) => (incoming.values[idx] = val || 0));
    const result = incoming.color.includes("#f7aeae");
    if (result) {
      incoming.incoming_judgement = "NOT OK";
    } else {
      incoming.incoming_judgement = "OK";
    }
  }

  if (incoming.appearance === "check3") {
    const result = incoming.values.includes("not ok");
    if (result) {
      incoming.incoming_judgement = "NOT OK";
    } else {
      incoming.incoming_judgement = "OK";
    }
  }
}

const IncomingListForm = ({ index, incomingData, incoming }) => {
  const [render, setRender] = useState();

  function handleChangeincomingText({ target }, idx) {
    const value = target.type === "checkbox" ? target.checked : target.value;
    incomingData.incomingList[index].values[idx] = value;
    checkResults(incoming);
    setRender((prev) => !prev);
  }

  function handleChangeincomingList(
    { target },
    idx,
    mean,
    utolerance,
    ltolerance,
    old_utl,
    old_ltl,
    check4_symbol,
    checkbox4_num
  ) {
    const value = target.type === "checkbox" ? target.checked : +target.value;
    incomingData.incomingList[index].values[idx] = value;

    let utl;
    let ltl;
    let uv;
    let lv;

    //max
    if (check4_symbol && checkbox4_num) {
      if (check4_symbol === "<=") {
        if (value <= checkbox4_num) {
          incomingData.incomingList[index].color[idx] = "#a4d1a4";

          setRender((prev) => !prev);
        } else {
          incomingData.incomingList[index].color[idx] = "#f7aeae";

          setRender((prev) => !prev);
        }
      }
      //min
      if (check4_symbol === ">=") {
        if (value >= checkbox4_num) {
          incomingData.incomingList[index].color[idx] = "#a4d1a4";
          setRender((prev) => !prev);
        } else {
          incomingData.incomingList[index].color[idx] = "#f7aeae";
          setRender((prev) => !prev);
        }
      }
    }

    if (old_utl || old_ltl) {
      utl = parseFloat(old_utl);
      ltl = parseFloat(old_ltl);
      const mean = (utl + ltl) / 2;
      uv = (mean + utl) / 2;
      lv = (mean + ltl) / 2;
    } else {
      utl = parseFloat(mean) + parseFloat(utolerance);
      ltl = parseFloat(mean) - parseFloat(ltolerance);

      uv = parseFloat(mean) + parseFloat(utolerance) / 2;
      lv = parseFloat(mean) - parseFloat(ltolerance) / 2;
    }

    if (value > utl || value < ltl) {
      incomingData.incomingList[index].color[idx] = "#f7aeae";
      setRender((prev) => !prev);
    } else if ((uv < value && value <= utl) || (lv > value && value >= ltl)) {
      incomingData.incomingList[index].color[idx] = "#fbfbac";
      setRender((prev) => !prev);
    } else if (value >= lv && value <= uv) {
      incomingData.incomingList[index].color[idx] = "#a4d1a4";
      setRender((prev) => !prev);
    }

    if (value === 0) {
      incomingData.incomingList[index].color[idx] = "#ffffff";
    }

    checkResults(incoming);
  }

  return (
    <section>
      <IncomingChecks
        {...{
          index,
          incoming,
          incomingData,
          handleChangeincomingList,
          handleChangeincomingText,
        }}
      />
      <Wrapper>
        <Input
          name="remarks"
          label="Remarks"
          type="text"
          style={{ width: "416px" }}
          variant="filled"
          onChange={(e) => handleChangeincomingText(e, index)}
          defaultValue={incoming.remarks}
        />
        <p
          style={{
            backgroundColor: "#fafafa",
            padding: "1rem",
            width: "190px",
            margin: 0,
            marginLeft: "1.5rem",
          }}
        >
          Results = {incoming.incoming_judgement}
        </p>
      </Wrapper>
    </section>
  );
};

export default IncomingListForm;
