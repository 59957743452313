import { useState } from "react";
import TableInput from "../../../../../common/TableInput";
import { Checkbox } from "@mui/material";
import { useQuery } from "../../../../../components/Control/useQuery";
const TBody = ({ moduleData, isView }) => {
  const [render, setRender] = useState(false);
  const query = useQuery();
  const detail_of_change = query.get("detail_of_change");
  const change_type = query.get("change_type");
  const retro_id = query.get("retro_id");
  return (
    <tbody>
      <tr>
        <th colSpan={6} align="left">
          Details of changing point: {detail_of_change}
        </th>
      </tr>
      {change_type === "man" && (
        <tr>
          <th>If Man change:</th>
          <th>Level From--</th>
          <th>
            <TableInput
              isView={isView}
              name="level_from"
              type="text"
              value={moduleData?.level_from}
              onChange={(e) => {
                moduleData.level_from = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
          <th>Level To--</th>
          <th>
            <TableInput
              isView={isView}
              name="level_to"
              type="text"
              value={moduleData?.level_to}
              onChange={(e) => {
                moduleData.level_to = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
          <th>(enable only if man change)</th>
        </tr>
      )}
      <tr>
        <th colSpan={4}>Retroactive Check Required:</th>
        <th colSpan={1} align="left">
          Yes:
          <Checkbox
            name="retro_check"
            checked={retro_id && retro_id != "undefined" ? true : false}
            style={{ color: "#064a29" }}
          />
        </th>
        <th colSpan={1} align="left">
          No:
          <Checkbox
            name="retro_check"
            checked={retro_id && retro_id != "undefined" ? false : true}
            style={{ color: "#064a29" }}
          />
        </th>
      </tr>
      <tr>
        <th>Retroactive Check</th>
        <th>Result</th>
        <th>
          Qty:
          <TableInput
            isView={retro_id && retro_id != "undefined" ? false : true}
            name="qty"
            type="text"
            value={moduleData?.qty}
            onChange={(e) => {
              moduleData.qty = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th>
          Ok:
          <TableInput
            isView={retro_id && retro_id != "undefined" ? false : true}
            name="ok"
            type="text"
            value={moduleData?.ok}
            onChange={(e) => {
              moduleData.ok = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th>
          Ng:
          <TableInput
            isView={retro_id && retro_id != "undefined" ? false : true}
            name="ng"
            type="text"
            value={moduleData?.ng}
            onChange={(e) => {
              moduleData.ng = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th>
          Temporary action for defects:{" "}
          <TableInput
            isView={retro_id && retro_id != "undefined" ? false : true}
            name="defects"
            type="text"
            value={moduleData?.defects}
            onChange={(e) => {
              moduleData.defects = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
