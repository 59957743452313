
export const options = [
  {
    name: "Clause 1",
    value: "clause-1",
  },
  {
    name: "Clause 2",
    value: "clause-2",
  },
  {
    name: "Clause 3",
    value: "clause-3",
  },
  {
    name: "Clause 4",
    value: "clause-4",
  },
  {
    name: "Clause 5",
    value: "clause-5",
  },
  {
    name: "Clause 6",
    value: "clause-6",
  },
  {
    name: "Clause 7",
    value: "clause-7",
  },
  {
    name: "Clause 8",
    value: "clause-8",
  },
  {
    name: "Clause 9",
    value: "clause-9",
  },
  {
    name: "Clause 10",
    value: "clause-10",
  },
  {
    name: "Clause 11",
    value: "clause-11",
  },
  {
    name: "Clause 12",
    value: "clause-12",
  },
  {
    name: "Clause 13",
    value: "clause-13",
  },
  {
    name: "Clause 14",
    value: "clause-14",
  },
  {
    name: "Clause 15",
    value: "clause-15",
  },
  {
    name: "Clause 16",
    value: "clause-16",
  },
  {
    name: "Clause 17",
    value: "clause-17",
  },
  {
    name: "Clause 18",
    value: "clause-18",
  },
  {
    name: "Clause 19",
    value: "clause-19",
  },
];
