import React from "react";
import MaintenanceTableForm from "../htmlTableFormForMaintenance/MaintenanceTableForm";

const ChecklistEditTable = ({
  checkListMachineData,
  changeLanguage,
  handleChecklist,
  checklistType,
  isView,
}) => {
  return (
    <div style={{ paddingBottom: "4rem" }}>
      <MaintenanceTableForm
        {...{
          checkListMachineData,
          changeLanguage,
          handleChecklist,
          checklistType,
          isView,
        }}
      />
    </div>
  );
};

export default ChecklistEditTable;
