import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../../style/pcs.css";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import PCD from "./PCD";
import { useQuery } from "../../components/Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";

function PCDIndex() {
  const { partId } = useParams();
  const [preview, setPreview] = useState(null);
  const [render, setRender] = useState(false);
  const [data, setData] = useState("");
  const [ProcessName, setProcessName] = useState("");
  const [image, setImage] = useState({
    file: null,
  });
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");

  useEffect(() => {
    axios
      .get(`/api/get/controlPlanPart/${partId}`)
      .then((res) => setData(res.data))
      .catch((error) => console.error(error));
    // eslint-disable-next-line
  }, [render]);

  function selectProcessName(e) {
    const process_name = e.target.value;
    setProcessName(process_name);
    setPreview(null);
    setImage({
      file: null,
    });
  }

  let filterProcess;
  if (ProcessName) {
    filterProcess = data.processes.filter(
      (process) => process.process_name === ProcessName
    );
  }
  return (
    <>
      {data && (
        <div style={{ paddingBottom: "4rem" }}>
          <div className="divflex" style={{ fontSize: "24px" }}>
            PROCESS CONTROL DIAGRAM
          </div>
          <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />

          <div className="header">
            <FormControl
              style={{ width: "300px", marginRight: "2rem" }}
              variant="filled"
              className="select-field"
            >
              <InputLabel>Select Process</InputLabel>
              <Select
                value={ProcessName}
                onChange={(e) => selectProcessName(e)}
              >
                {data.processes.map((data, index) => (
                  <MenuItem
                    className="option"
                    key={index}
                    value={data.process_name}
                  >
                    {data.process_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {filterProcess && ProcessName && (
            <PCD
              filterProcess={filterProcess}
              processName={ProcessName}
              partId={partId}
              {...{ preview, setPreview, setRender, render, image, setImage }}
            />
          )}
        </div>
      )}
    </>
  );
}

export default PCDIndex;
