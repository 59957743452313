import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  // InputAdornment,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

// import { Search } from "@material-ui/icons";
// import DescriptionIcon from "@material-ui/icons/Description";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
// import Input from "../../../components/Control/Input";
// import { Wrapper } from "../../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";

import Modal from "../../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../../components/Control/useDebounce";
import { Link, useHistory, useParams } from "react-router-dom";
import useConfirm from "../../../components/Control/useConfirm";
import { useQuery } from "../../../components/Control/useQuery";
import formatDate from "../../../components/Control/formatDate";
import UploadFileModal from "./UploadFileModal";

const getCurrentStatus = (status) => {
  const statusMap = {
    false: [{ bgColor: "#fcc419", width: "300px", text: "PENDING" }],
    true: [{ bgColor: "#2f9e44", width: "300px", text: "COMPLETED" }],
  };

  const [{ bgColor, text, width }] = statusMap[status] || [];
  return bgColor ? (
    <TableCell align="center" style={{ backgroundColor: bgColor, width }}>
      {text}
    </TableCell>
  ) : (
    <TableCell></TableCell>
  );
};

const ListOfChecklist = () => {
  const [data, setData] = useState([]);
  // const [isSupplerAuditModal, setIsSupplierAuditModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [search, setSearch] = useState();
  const history = useHistory();
  const { auditId } = useParams();
  const [isUploadFileModal, setUploadFileModal] = useState(false);
  const [complaintId, setComplaintId] = useState(null);

  const query = useQuery();
  // const supplier = query.get("supplier");
  const auditDate = query.get("auditDate");
  const plan_id = query.get("plan_id");
  const part_number = query.get("part_number");
  const part_name = query.get("part_name");
  // const supplier_id = query.get("supplier_id");
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this report?"
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/getProcessAuditChecklist/${auditId}/?page=${page}&search=${search}&plan_id=${plan_id}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [page, search, render, isUploadFileModal]);

  async function deleteInternalAudit(id) {
    const isConfirm = await confirmDelete();
    if (isConfirm) {
      await axios.delete(`/api/deleteProcessAuditChecklist/${id}`);
      setRender((prev) => !prev);
    }
  }
  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          Checklist
          <div>
            <Link
              to={`/editViewChecklistProcessAudit/${auditId}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                style={{
                  background: "#064a29",
                  color: "#fafafa",
                }}
                startIcon={<Add />}
              >
                New Checklist
              </Button>
            </Link>
          </div>
        </div>
        <div className="subheading-container">
          <p className="subheading-primary">
            ACTIVE <span className="subheading-secondary">PLANS</span>
          </p>
        </div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  S.no
                </TableCell>
                <TableCell
                  style={{ fontWeight: "bold", width: "20%" }}
                  align="center"
                >
                  Part Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Upload Files
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Completed
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          maxWidth: "15rem",
                          width: "15rem",
                        }}
                      >
                        {part_name}
                      </TableCell>
                      <TableCell align="center">
                        {formatDate(item?.checklist_date)}
                      </TableCell>
                      <TableCell align="center">
                        {item?.department_files?.length > 0 ? (
                          <Tooltip title="Upload Form" arrow>
                            <Link
                              to={{
                                pathname: `/showAllTypesFiles/${item._id}`,
                                state: {
                                  delete_url: "deleteProcessAuditChecklistFile",
                                  getOne_url: "getOneProcessAuditChecklist",
                                  update_url: "updateProcessAuditChecklist",
                                  file_backend_name: "department_files",
                                },
                              }}
                            >
                              <IconButton size="small">
                                <VisibilityIcon
                                  style={{
                                    fontSize: "1.2rem",
                                    color: "#1864ab",
                                  }}
                                />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Upload Form" arrow>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setComplaintId(item._id);
                                setUploadFileModal(true);
                              }}
                            >
                              <AddIcon
                                style={{
                                  fontSize: "1.5rem",
                                  color: "#c92a2a",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                      {getCurrentStatus(item?.isCompleted)}
                      {/* <TableCell align="center">
                        {item?.isCompleted === true
                          ? "Completed"
                          : "Not Completed"}
                      </TableCell> */}
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <Tooltip title="View Checklist" arrow>
                          <Link
                            to={`/editViewChecklistProcessAudit/${auditId}/${item._id}?isView=true&auditDate=${auditDate}&part_name=${part_name}&part_number=${part_number}`}
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <VisibilityIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Edit Checklist" arrow>
                          <Link
                            to={`/editViewChecklistProcessAudit/${auditId}/${item._id}?auditDate=${auditDate}&part_name=${part_name}&part_number=${part_number}`}
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <EditIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Delete Checklist" arrow>
                          <IconButton
                            size="small"
                            onClick={() => deleteInternalAudit(item._id)}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <DialogDelete isSubmitContent={true} />

        <Modal
          title={"upload"}
          openPopup={isUploadFileModal}
          closeModal={() => {
            setUploadFileModal(false);
            setComplaintId(null);
          }}
          backgroundColor="white"
        >
          <UploadFileModal
            {...{ setUploadFileModal, setComplaintId }}
            id={complaintId}
          />
        </Modal>
      </main>
    </>
  );
};

export default ListOfChecklist;
