import React, { useState, useEffect } from "react";
import {
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    InputAdornment,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { Link, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { Edit, Lock, LockOpen, Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../components/Control/useDebounce";
import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import formatDate from "../../components/Control/formatDate";
import { useAuth } from "../../context/GlobalContext";
import TextField from "@material-ui/core/TextField";
import ConfirmDialogueForInput from "../../components/Control/ConfirmDialogueForInput";
import useConfirm from "../../components/Control/useConfirm";
import AddEditPart from "../AddEditDuplicatePart/AddEditPart";
import Modal from "../../components/Control/Modal";
import ViewFile from "./ViewFile";

const useStyles = makeStyles({
    table: {
        width: "100%",
        marginTop: "1.5rem",
    },
});

function PartTable() {
    const classes = useStyles();
    const [partData, setPartData] = useState([]);
    const { state } = useLocation();
    const globalState = useAuth();

    const [page, setPage] = useState(1);
    const [render, setRender] = useState(true);
    const [totalPages, setTotalPages] = useState(0);
    const [search, setSearch] = useState();
    const [perPage, setPerPage] = useState();
    const [filter, setFilter] = useState("");
    const [isNewPartModal, setIsNewPartModal] = useState(false);
    const [partIdModal, setPartIdModal] = useState(null);
    // custom hook for dialogue
    const [DialogUnlock, confirmUnlock] = useConfirm(
        "UNLOCK PART",
        "Are you sure you want to unlock this part?",
    );
    const [DialogLock, confirmLock] = useConfirm(
        "LOCK PART",
        "Are you sure you want to lock this part?",
    );

    // confirm dialogue for delete and if there is input field
    const [confirmOpenForDelete, setConfirmOpenForDelete] = useState(false);
    const [textFieldValue, setTextFieldValue] = useState("");
    const [partId, setPartId] = useState("");
    const [partNumber, setPartNumber] = useState("");
    const [id, setId] = useState(null);
    const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);

    // for setting filter label
    const [customerFilterList, setCustomerFilterList] = useState([]);

    useEffect(() => {
        axios.get(`/api/getCustomer/all`).then((res) => {
            setCustomerFilterList(res?.data?.results);
            setFilter(res?.data?.results[0]?._id);
        });
    }, []);

    const fetchData = async () => {
        axios
            .get(
                `/api/get/part/publish?page=${page}&sortBy=${encodeURIComponent(
                    filter,
                )}&search=${search}&customerFilterList=yes`,
                {
                    headers: { isFullAdmin: globalState.state?.user?.isFullAdmin },
                },
            )
            .then((res) => {
                setPartData(res?.data?.result);
                setPerPage(res?.data?.perPage);
                setTotalPages(res?.data?.pages);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useDebounce(fetchData, 500, [page, search, render, filter, isNewPartModal]);
    const deletePart = async (id) => {
        const res = await axios
            .delete(`/api/delete/part/${id}`)
            .catch((err) => console.log(err));

        if (res.data?.status === "success") {
            setRender((prev) => !prev);
        }
    };
    const lockPart = async (partName) => {
        const ans = await confirmLock();

        if (ans) {
            axios
                .put(`/api/update/lockstatus?partName=${partName}`, { isLocked: true })
                .catch((err) => console.log(err));
            setRender((prev) => !prev);
        }
    };
    const unlockPart = async (partName) => {
        const ans = await confirmUnlock();

        if (ans) {
            axios
                .put(`/api/update/lockstatus?partName=${partName}`, { isLocked: false })
                .catch((err) => console.log(err));
            setRender((prev) => !prev);
        }
    };
    // console.log(partData);

    return (
        <>
            <TableContainer component={Paper}>
                <Wrapper>
                    <div style={{ flex: "0.8" }}>
                        <Input
                            placeholder="Search Part Number"
                            variant="filled"
                            style={{
                                width: "96%",
                                marginLeft: "1.2rem",
                                marginTop: "1rem",
                            }}
                            onChange={(e) => setSearch(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <FormControl
                        variant="filled"
                        className="select-field"
                        style={{
                            marginRight: "1.5rem",
                            marginTop: "0.8rem",
                            flex: "0.2",
                            textTransform: "uppercase",
                        }}
                    >
                        <InputLabel style={{ textTransform: "capitalize" }}>
                            Select Customer
                        </InputLabel>
                        <Select
                            name="customer"
                            onChange={(e) => {
                                // setting page no. before setting filter value
                                setPage(1);
                                setFilter(e.target.value);
                            }}
                            value={filter}
                        >
                            {customerFilterList.map((customer, index) => (
                                <MenuItem
                                    value={customer._id}
                                    key={index + 200.0202}
                                    style={{ textTransform: "uppercase" }}
                                >
                                    {customer?.customer_name}
                                </MenuItem>
                            ))}
                            <MenuItem style={{ textTransform: "uppercase" }} value="other">
                                other
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Wrapper>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                #
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Item Number
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Item Description
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Customer
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Supplier
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Created At
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {partData && (
                        <TableBody>
                            {partData.map((item, index) => (
                                <React.Fragment key={item._id}>
                                    <TableRow>
                                        <TableCell align="center">
                                            {perPage * (page - 1) + index + 1}
                                        </TableCell>
                                        <TableCell align="center">{item.part_number}</TableCell>
                                        <TableCell align="center">{item.part_name}</TableCell>
                                        <TableCell align="center">
                                            {item?.customer_id?.customer_name}
                                        </TableCell>
                                        <TableCell align="center">{item.supplier}</TableCell>
                                        <TableCell align="center">
                                            {formatDate(item.createdAt)}
                                        </TableCell>
                                        {state?.selectPartForRejection ? (
                                            <TableCell align="center">
                                                <Tooltip title="View" arrow>
                                                    <Link
                                                        to={`/rejectionAnalysis/partwiseAnalysis/${item.part_name}`}
                                                    >
                                                        <IconButton size="small">
                                                            <VisibilityIcon
                                                                style={{ fontSize: "1.4rem", color: "#003566" }}
                                                            />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                            </TableCell>
                                        ) : state?.selectPartForFailureMode ? (
                                            <TableCell align="center">
                                                <Tooltip title="View" arrow>
                                                    <Link
                                                        to={`/rejectionAnalysis/failurewiseAnalysis/${item.part_name}`}
                                                    >
                                                        <IconButton size="small">
                                                            <VisibilityIcon
                                                                style={{ fontSize: "1.4rem", color: "#003566" }}
                                                            />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                            </TableCell>
                                        ) : (
                                            <TableCell align="center">
                                                {globalState?.state?.user?.isFullAdmin &&
                                                    (item.isLocked ? (
                                                        <Tooltip title="Lock" arrow>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => unlockPart(item.part_name)}
                                                                color="secondary"
                                                            >
                                                                <Lock style={{ fontSize: "1.4rem" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title="Unlock" arrow>
                                                            <IconButton
                                                                size="small"
                                                                color="primary"
                                                                onClick={() => lockPart(item.part_name)}
                                                            >
                                                                <LockOpen style={{ fontSize: "1.4rem" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    ))}
                                                <Tooltip title="FILE LIST" arrow>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setId(item._id || null);
                                                            setIsFileOpenPopup(true);
                                                        }}
                                                    >
                                                        <FormatListBulletedIcon
                                                            style={{
                                                                fontSize: "1.4rem",
                                                                color: "#003566",
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>

                                                <Tooltip title="View" arrow>
                                                    <Link
                                                        to={`/view_process/${item._id}?partnumber=${item.part_number}&partname=${item.part_name}`}
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            style={{ marginLeft: "0.5rem" }}
                                                        >
                                                            <VisibilityIcon
                                                                style={{ fontSize: "1.4rem", color: "#003566" }}
                                                            />
                                                        </IconButton>
                                                    </Link>
                                                </Tooltip>
                                                <Tooltip title="Edit" arrow>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setPartIdModal(item._id);
                                                            setIsNewPartModal(true);
                                                        }}
                                                        style={{ marginLeft: "0.5rem" }}
                                                    >
                                                        <Edit
                                                            style={{ fontSize: "1.4rem", color: "#003566" }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Delete" arrow>
                                                    <IconButton
                                                        size="small"
                                                        style={{ marginLeft: "0.5rem" }}
                                                        onClick={() => {
                                                            setPartId(item._id);
                                                            setPartNumber(item.part_number);
                                                            setConfirmOpenForDelete(true);
                                                        }}
                                                    >
                                                        <DeleteIcon
                                                            style={{
                                                                fontSize: "1.4rem",
                                                                color: "red",
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    )}
                </Table>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "1rem",
                        marginBottom: "4rem",
                    }}
                >
                    <Pagination
                        count={totalPages}
                        variant="outlined"
                        shape="rounded"
                        onChange={(e, number) => setPage(number)}
                    />
                </div>
            </TableContainer>
            <DialogUnlock isSubmitContent={false} />
            <DialogLock isSubmitContent={true} />
            <ConfirmDialogueForInput
                title="DELETE PART"
                open={confirmOpenForDelete}
                setOpen={setConfirmOpenForDelete}
                onConfirm={(partId) => deletePart(partId)}
                isSubmitContent={false}
                itemPartName={partNumber}
                itemId={partId}
                textFieldValue={textFieldValue}
                setTextFieldValue={setTextFieldValue}
            >
                <p style={{ margin: 0, fontSize: "1rem" }}>
                    Are you sure you want to delete this part?
                </p>
                <p style={{ margin: 0, fontSize: "1rem" }}>
                    Enter the <b>Item Number :</b> "{`${partNumber}`}" to confirm part
                    deletion:
                </p>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    autoComplete="false"
                    label="Item Number"
                    type="text"
                    fullWidth
                    onChange={(e) => setTextFieldValue(e.target.value)}
                    onPaste={(e) => {
                        e.preventDefault();
                        return false;
                    }}
                />
            </ConfirmDialogueForInput>
            <Modal
                title={partId ? "update new part" : "create new part"}
                openPopup={isNewPartModal}
                closeModal={() => {
                    setIsNewPartModal(false);
                    setPartIdModal(null);
                }}
                backgroundColor="white"
            >
                <AddEditPart id={partIdModal} {...{ setIsNewPartModal }} />
            </Modal>
            <Modal
                title={"FILE LIST"}
                openPopup={isFileOpenPopup}
                closeModal={() => {
                    setId(null);
                    setIsFileOpenPopup(false);
                }}
                backgroundColor="white"
            >
                <ViewFile id={id} />
            </Modal>
        </>
    );
}

export default PartTable;
