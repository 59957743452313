import PdirForm from "./PdirForm";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Loader from "../../../components/Control/Loader";

const Index = () => {
  const [data, setData] = useState(null);
  let pdirData = null;
  const { partId } = useParams();
  const history = useHistory();
  let storeArr = [];
  if (data) {
    if (data.isLocked) {
      history.push("/");
    }
    if (data?.processes.length) {
      function appendValue() {
        for (let j = 0; j < data.processes[0].specs.length; j++) {
          storeArr.push({
            parameter:
              data.processes[0].specs[j].char_product ||
              data.processes[0].specs[j].char_process,
            check4_symbol: data.processes[0].specs[j].check4_symbol,
            checkbox4_num: data.processes[0].specs[j].checkbox4_num,
            specification: data.processes[0].specs[j].control_product,
            methods: data.processes[0].specs[j].methods_measure,
            appearance: data.processes[0].specs[j].appearance,
            mean: data.processes[0].specs[j].mean,
            utolerance: data.processes[0].specs[j].utolerance,
            ltolerance: data.processes[0].specs[j].ltolerance,
            utl: data.processes[0].specs[j].utl,
            ltl: data.processes[0].specs[j].ltl,
          });
        }
        pdirData = {
          part_id: partId,
          revision_no: data.revision_no,
          revision_date: data.revision_date,
          pdir_date: new Date(),
          pdir_list: storeArr,
        };
      }
      appendValue();
    }
  }

  useEffect(() => {
    axios
      .get(`/api/get/pdir/from_controlplan_part/${partId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return pdirData ? <PdirForm {...{ pdirData }} /> : <Loader />;
};

export default Index;
