import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
const Div = styled.div`
  flex: 0.986;
  border: 1px solid black;
  p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    letter-spacing: 1.6px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #1d1d1d;
    color: white;
  }
`;
const sortObject = (obj) => {
  let sortedObj = Object.entries(obj).sort((a, b) => b[1] - a[1]);
  let percentageArr = [];
  let processLabels = [];
  sortedObj.forEach((value) => {
    processLabels.push(value[0]);
    percentageArr.push(value[1]);
  });
  return { percentageArr, processLabels };
};

const processRejectionPercentage = (processRejection, totalRejection) => {
  const ppmObj = {};
  let totalPpm = 0;
  for (let key in processRejection) {
    const ppm =
      (processRejection[key].rejected /
        (processRejection[key].producedQty || 1)) *
      1000000;

    ppmObj[key] = ppm;
    totalPpm += ppm;
  }
  const newObj = {};
  for (let key in ppmObj) {
    newObj[key] = Number(((ppmObj[key] / totalPpm) * 100).toFixed(2));
  }
  return newObj;
};

const ProcessWiseParetoGraph = ({
  totalProcessRejection,
  processRejection,
}) => {
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState([
    {
      name: "Rejection",
      type: "column",
      data: [],
    },
    {
      name: "Percentage %",
      type: "line",
      data: [],
    },
  ]);
  function generateDefectGraph(percentage, processLabels) {
    const cummulativePercentage = [];
    for (let i = 0; i < percentage.length; i++) {
      if (i === 0) {
        cummulativePercentage[i] = Number(percentage[i].toFixed(2));
      } else
        cummulativePercentage[i] = Number(
          (cummulativePercentage[i - 1] + percentage[i]).toFixed(2)
        );
    }
    setOptions({
      chart: {
        id: "partwise-wise",
        type: "line",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: [0, 3],
      },
      markers: {
        size: 4,
      },

      legend: {
        position: "top",
      },
      fill: {
        colors: ["#003566", "#003566", "#003566"],
      },
      plotOptions: {
        bar: {
          columnWidth: "45%",
        },
      },
      xaxis: { categories: processLabels },
      // title: { text: "% REJECTION", align: "left" },
      yaxis: [
        {
          title: {
            text: "PERCENTAGE",
          },
          max: 100,
        },
        {
          opposite: true,
          title: {
            text: "CUMMULATIVE PERCENTAGE",
          },
          max: 100,
        },
      ],
    });
    setSeries([
      {
        name: "Percentage %",
        type: "column",
        data: percentage,
      },
      {
        name: "Cummulative Percentage %",
        type: "line",
        data: cummulativePercentage,
      },
    ]);
  }

  useEffect(() => {
    const obtainedProcessRejectionPercentage = processRejectionPercentage(
      processRejection,
      totalProcessRejection
    );
    const { percentageArr, processLabels } = sortObject(
      obtainedProcessRejectionPercentage
    );
    generateDefectGraph(percentageArr, processLabels);
  }, [processRejection, totalProcessRejection]);
  return (
    <Div>
      <p>PPM REJECTIONS %</p>
      <Chart options={options} series={series} height={600} className="col" />
    </Div>
  );
};

export default ProcessWiseParetoGraph;
