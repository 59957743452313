import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";
const Div = styled.div`
  flex: 1;
  margin-top: 2rem;
  border: 1px solid black;
  p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    letter-spacing: 1.6px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #1d1d1d;
    color: white;
  }
`;

const RenderGraph = ({ data, title }) => {
  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);
  const [options, setOptions] = useState({});

  function generateGraph(labels, data) {
    setSeries([
      {
        data: data,
      },
    ]);
    setOptions({
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "Product Trends by Month",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: labels,
      },
    });
  }
  useEffect(() => {
    generateGraph(data.labels, data.data);
  }, [data]);

  return (
    <Div>
      <p>{title}</p>
      <Chart
        type="bar"
        options={options}
        series={series}
        height={550}
        className="col"
      />
    </Div>
  );
};

export default RenderGraph;
