export const body3Obj = [
  {
    control_item: "In-coming Inspection",
    target_plan: "As per Pre Launch Control Plan",
    m1: Array.from({ length: 5 }, () => ({ files: [] })),
    m2: Array.from({ length: 5 }, () => ({ files: [] })),
    m3: Array.from({ length: 5 }, () => ({ files: [] })),
  },
  {
    control_item: "In-process Inspection",
    target_plan: "As per Pre Launch Control Plan",
    m1: Array.from({ length: 5 }, () => ({ files: [] })),
    m2: Array.from({ length: 5 }, () => ({ files: [] })),
    m3: Array.from({ length: 5 }, () => ({ files: [] })),
  },
  {
    control_item: "Final Inspection",
    target_plan: "As per Pre Launch Control Plan",
    m1: Array.from({ length: 5 }, () => ({ files: [] })),
    m2: Array.from({ length: 5 }, () => ({ files: [] })),
    m3: Array.from({ length: 5 }, () => ({ files: [] })),
  },
  {
    control_item: "Product &Process Audit",
    target_plan: "Every Month",
    m1: Array.from({ length: 5 }, () => ({ files: [] })),
    m2: Array.from({ length: 5 }, () => ({ files: [] })),
    m3: Array.from({ length: 5 }, () => ({ files: [] })),
  },
  {
    control_item: "Cpk Study Result (˃ 1.67)",
    target_plan: "Cpk  of OD",
    m1: Array.from({ length: 5 }, () => ({ files: [] })),
    m2: Array.from({ length: 5 }, () => ({ files: [] })),
    m3: Array.from({ length: 5 }, () => ({ files: [] })),
  },
  {
    control_item: "",
    target_plan: "Cpk of  ID",
    m1: Array.from({ length: 5 }, () => ({ files: [] })),
    m2: Array.from({ length: 5 }, () => ({ files: [] })),
    m3: Array.from({ length: 5 }, () => ({ files: [] })),
  },
  {
    control_item: "",
    target_plan: "Cpk of",
    m1: Array.from({ length: 5 }, () => ({ files: [] })),
    m2: Array.from({ length: 5 }, () => ({ files: [] })),
    m3: Array.from({ length: 5 }, () => ({ files: [] })),
  },
];
export const body4Obj = [
  {
    control_item: "In-house Part PPM",
    input_type: "number",
    m1: Array.from({ length: 5 }, () => ({ files: [] })),
    m2: Array.from({ length: 5 }, () => ({ files: [] })),
    m3: Array.from({ length: 5 }, () => ({ files: [] })),
  },
  {
    control_item: "Customer Complaint",
    input_type: "number",
    m1: Array.from({ length: 5 }, () => ({ files: [] })),
    m2: Array.from({ length: 5 }, () => ({ files: [] })),
    m3: Array.from({ length: 5 }, () => ({ files: [] })),
  },
  {
    control_item: "Previous Audit NC Status",
    input_type: "text",
    m1: Array.from({ length: 5 }, () => ({ files: [] })),
    m2: Array.from({ length: 5 }, () => ({ files: [] })),
    m3: Array.from({ length: 5 }, () => ({ files: [] })),
  },
]
