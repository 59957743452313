import { IconButton } from "@material-ui/core";
import React from "react";
import { DatePicker } from "@material-ui/pickers";

import TextField from "@material-ui/core/TextField";

import Radio from "@material-ui/core/Radio";
import { useState } from "react";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import { Table } from "../../../components/Control/TabelStyles";
import { Autocomplete } from "@material-ui/lab";
import TableReadEditCell from "../../../components/TableEditCell";
import { useHistory } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import TableScroll from "../../../components/TableScroll";

function MouseOver(event) {
  event.target.style.transform = "scale(1.2)";
  event.target.style.transition = "200ms";
  event.target.style.border = "1px solid #ced4da";
  event.target.style.borderRadius = "5px";
  event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.2)";
}
function MouseOut(event) {
  event.target.style.transform = "scale(1)";
  event.target.style.border = "none";
  event.target.style.boxShadow = "none";
  event.target.style.transition = "200ms";
}
function MouseDown(event) {
  event.target.style.transform = "scale(1.1)";
  event.target.style.transition = "300ms";
  event.target.style.border = "1px solid #868e96";
  event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.3)";
}

const ApqpTimePlanTable = ({
  apqpData,
  isView,
  phase,
  title,
  onSubmit,
  employeeValue,
  setEmployeeValue,
  employeeAutocomplete,
  partId,
}) => {
  const [render, setRender] = useState(false);

  const history = useHistory();
  const handleRadioButton = (e) => {
    apqpData.product_details_radio = e.target.value;
    setRender((prev) => !prev);
  };

  const handleRemarks = (
    completeDate_p,
    completeDate_a,
    activity,
    parentIndex
  ) => {
    if (!completeDate_p || !completeDate_a) return;
    const planDate = new Date(new Date(completeDate_p).setHours(0, 0, 0, 0)).getTime();
    const actualDate = new Date(new Date(completeDate_a).setHours(0, 0, 0, 0)).getTime();

    if (!apqpData[`${phase}_remarks`]) apqpData[`${phase}_remarks`] = [];
    if (planDate < actualDate) {
      let apqpIndex = apqpData[`${phase}_remarks`].findIndex(
        (item) => item.s_no === parentIndex
      );
      apqpData[`${phase}`][parentIndex].remarks = "DELAYED";
      if (apqpIndex != -1) {
        apqpData[`${phase}_remarks`][apqpIndex].activity = activity;
        return;
      }
      apqpData[`${phase}_remarks`].push({
        s_no: parentIndex,
        activity: activity,
      });
    } else {
      let apqpIndex = apqpData[`${phase}_remarks`].findIndex(
        (item) => item.s_no === parentIndex
      );

      apqpData[`${phase}`][parentIndex].remarks = "ON TRACK";
      if (apqpIndex != -1) {
        apqpData[`${phase}_remarks`].splice(apqpIndex, 1);
        return;
      }
    }
  };

  const handleDoubleClick = async (event, rowIdx, bool) => {
    if (event.detail === 2) {
      apqpData[phase][rowIdx].isRequired = bool;
      setRender((prev) => !prev);
    }
  };

  const handleMonthsDoubleClick = (rowIdx, month, planType, monthIdx) => {
    return (
      <td
        style={{ minWidth: "80px", background: "#f1f3f5" }}
        onClick={(event) => {
          if (event.detail === 2) {
            if (!apqpData[phase][rowIdx][month]) {
              apqpData[phase][rowIdx][month] = [
                {
                  p: false,
                  a: false,
                },
              ];
            }
            if (!apqpData[phase][rowIdx][month][monthIdx]) {
              apqpData[phase][rowIdx][month][monthIdx] = {
                p: false,
                a: false,
              };
            }
            const bool = apqpData[phase][rowIdx][month][monthIdx][planType];
            apqpData[phase][rowIdx][month][monthIdx][planType] = !bool
              ? true
              : false;
            setRender((prev) => !prev);
          }
        }}
      >
        <span
          onMouseOver={MouseOver}
          onMouseOut={MouseOut}
          onMouseDown={MouseDown}
          style={{
            fontSize: "2rem",
            display: "flex",
            outline: "none",
            minHeight: "70px",
            alignItems: "center",
            justifyContent: "center",
            color: "#495057",
            textTransform: "uppercase",
            backgroundColor: "#f1f3f5",
            boxShadow: "0 30px 40px rgb(255, 227, 227,.2)",
            cursor: "pointer",
            textShadow: "1px 1px #b0b0b0",
          }}
        >
          {apqpData[phase][rowIdx]?.[month]?.[monthIdx]?.[planType] === true &&
            planType}
        </span>
      </td>
    );
  };

  return (
    <main
      style={{
        paddingBottom: "4rem",
      }}
    >
      <TableScroll>
        <Table>
          <thead>
            <tr>
              <th
                colSpan={27}
                rowSpan="2"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  height: "100px",
                  color: "#343a40",
                }}
              >
                <CenteredLogoImg>
                  <img src={images.arcLogo} alt={textInputs.companyName} />
                  <span>ADAVANCED PRODUCT QUALITY TIME PLAN</span>
                </CenteredLogoImg>
              </th>
              <th colSpan={4}>DOC NO:- ARC/F/NPD/05</th>
            </tr>
            <tr>
              <th colSpan={4}>REV NO./DATE - 01/20.11.2019</th>
            </tr>
            <tr>
              <th colSpan={8}>CUSTOMER NAME :</th>
              <th></th>
              <th colSpan={12}>PART NAME : {apqpData.part_id?.part_name}</th>
              <th colSpan={10}>
                PART NUMBER & MODEL : {apqpData.part_id?.part_number}
              </th>
            </tr>
            <tr>
              <th>A</th>
              <th colSpan={5}>
                Any Similar type of product has not been produced earlier .
              </th>
              <th colSpan={2}>
                <Radio
                  checked={apqpData.product_details_radio === "a"}
                  onChange={(e) => {
                    handleRadioButton(e);
                  }}
                  value={"a"}
                  name="product_details_radio"
                />
              </th>
              <th></th>
              <th colSpan={12}></th>
              <th colSpan={4}></th>
              <th
                colSpan={3}
                rowSpan={4}
                style={{ textAlign: "center", fontSize: "2rem" }}
              >
                🗸
              </th>
              <th colSpan={4} rowSpan={4}>
                ACTIVITY REQUIRED
              </th>
            </tr>
            <tr>
              <th>B</th>
              <th colSpan={5}>
                A Similar type of product has been produced earlier .
              </th>
              <th colSpan={2}>
                <Radio
                  checked={apqpData.product_details_radio === "b"}
                  onChange={(e) => {
                    handleRadioButton(e);
                  }}
                  value={"b"}
                  name="product_details_radio"
                />
              </th>
              <th></th>
              <th colSpan={12}></th>
              <th colSpan={4}></th>
            </tr>
            <tr>
              <th>C</th>
              <th colSpan={5}>Existing Product ECN </th>
              <th colSpan={2}>
                <Radio
                  checked={apqpData.product_details_radio === "c"}
                  onChange={(e) => {
                    handleRadioButton(e);
                  }}
                  value={"c"}
                  name="product_details_radio"
                />
              </th>
              <th></th>
              <th colSpan={12}></th>
              <th colSpan={4}></th>
            </tr>
            <tr>
              <th>D</th>
              <th colSpan={5}>Location Change/PCN </th>
              <th colSpan={2}>
                <Radio
                  checked={apqpData.product_details_radio === "d"}
                  onChange={(e) => {
                    handleRadioButton(e);
                  }}
                  value={"d"}
                  name="product_details_radio"
                />
              </th>
              <th></th>
              <th colSpan={12}></th>
              <th colSpan={4}></th>
            </tr>
            <tr>
              <th colSpan={6} style={{ textAlign: "center" }}>
                REVIEWED BY PROJECT LEADER (PL)
              </th>
              <th colSpan={2}>WEEKLY </th>
              <th></th>
              <th colSpan={12}></th>
              <th colSpan={4}></th>
              <th
                colSpan={3}
                rowSpan={3}
                style={{ textAlign: "center", fontSize: "2rem" }}
              >
                ⨯
              </th>
              <th colSpan={4} rowSpan={3}>
                ACTIVITY NOT REQUIRED
              </th>
            </tr>
            <tr>
              <th colSpan={6} style={{ textAlign: "center" }}>
                REVIEWED BY CFT
              </th>
              <th colSpan={2}>WEEKLY </th>
              <th></th>
              <th colSpan={12}></th>
              <th colSpan={4}></th>
            </tr>
            <tr>
              <th colSpan={6} style={{ textAlign: "center" }}>
                REVIEWED BY TOP MANAGEMENT(TM )
              </th>
              <th colSpan={2}>WEEKLY </th>
              <th></th>
              <th colSpan={12}></th>
              <th colSpan={4}></th>
            </tr>
            <tr>
              <th colSpan={2} rowSpan={3}>
                CUSTOMER EVENTS
              </th>
              <th colSpan={2}>Customer Drawing :-</th>
              <th colSpan={2}>
                <DatePicker
                  name="date_performance"
                  format="dd/MM/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f8f9fa",
                  }}
                  value={apqpData?.customer_drawing_date || null}
                  clearable
                  onChange={(date) => {
                    apqpData.customer_drawing_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={2}>Customer PO:-</th>
              <th colSpan={4}>
                <TableReadEditCell
                  name="customer_po"
                  value={apqpData.customer_po}
                  handleChange={(e) => {
                    apqpData.customer_po = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  {...{ isView }}
                />
              </th>
              <th colSpan={5}>
                <DatePicker
                  name="date_performance"
                  format="dd/MM/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f8f9fa",
                  }}
                  clearable
                  value={apqpData?.customer_po_date || null}
                  onChange={(date) => {
                    apqpData.customer_po_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={4}>T2 (IF REUIRED)</th>
              <th colSpan={2}>
                <DatePicker
                  name="date_performance"
                  format="dd/MM/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f8f9fa",
                  }}
                  value={apqpData?.t2_date || null}
                  clearable
                  onChange={(date) => {
                    apqpData.t2_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={1}>QTY:</th>
              <th colSpan={2}>
                <TableReadEditCell
                  name="t2_qty"
                  value={apqpData.t2_qty}
                  handleChange={(e) => {
                    apqpData.t2_qty = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  {...{ isView }}
                />
              </th>
              <th colSpan={5}></th>
            </tr>
            <tr>
              <th colSpan={2}>Quotation:-</th>
              <th colSpan={2}>
                <DatePicker
                  name="date_performance"
                  format="dd/MM/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f8f9fa",
                  }}
                  value={apqpData?.quatation_date || null}
                  clearable
                  onChange={(date) => {
                    apqpData.quatation_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={2}>T1</th>
              <th colSpan={4}>
                <DatePicker
                  name="date_performance"
                  format="dd/MM/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f8f9fa",
                  }}
                  value={apqpData?.t1_date || null}
                  clearable
                  onChange={(date) => {
                    apqpData.t1_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={2}>QTY</th>
              <th colSpan={3}>
                <TableReadEditCell
                  name="t1_qty"
                  value={apqpData.t1_qty || null}
                  clearable
                  handleChange={(e) => {
                    apqpData.t1_qty = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  {...{ isView }}
                />
              </th>
              <th colSpan={4}>T3 (IF REUIRED)</th>
              <th colSpan={2}>
                <DatePicker
                  name="date_performance"
                  format="dd/MM/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f8f9fa",
                  }}
                  value={apqpData?.t3_date || null}
                  clearable
                  onChange={(date) => {
                    apqpData.t3_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={1}>QTY:</th>
              <th colSpan={2}>
                {" "}
                <TableReadEditCell
                  name="t3_qty"
                  value={apqpData.t3_qty}
                  handleChange={(e) => {
                    apqpData.t3_qty = e.target.value;
                    setRender((prev) => !prev);
                  }}
                  {...{ isView }}
                />
              </th>
              <th style={{ backgroundColor: "yellow" }}></th>
              <th style={{ backgroundColor: "yellow", textAlign: "center" }}>
                PLAN
              </th>
              <th style={{ backgroundColor: "#2f9e44" }}></th>
              <th
                colSpan={2}
                style={{ backgroundColor: "#2f9e44", textAlign: "center" }}
              >
                DONE
              </th>
            </tr>
            <tr>
              <th colSpan={2}>PPAP Submission :-</th>
              <th colSpan={2}>
                <DatePicker
                  name="date_performance"
                  format="dd/MM/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f8f9fa",
                  }}
                  clearable
                  value={apqpData?.ppap_submission_date || null}
                  onChange={(date) => {
                    apqpData.ppap_submission_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={6}>SOP:-</th>
              <th colSpan={5}>
                <DatePicker
                  name="date_performance"
                  format="dd/MM/yyyy"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  style={{
                    backgroundColor: "#f8f9fa",
                  }}
                  value={apqpData?.sop_date || null}
                  clearable
                  onChange={(date) => {
                    apqpData.sop_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </th>
              <th colSpan={9}></th>
              <th style={{ backgroundColor: "yellow" }}></th>
              <th
                style={{ backgroundColor: "yellow", textAlign: "center" }}
              ></th>
              <th style={{ backgroundColor: "#2f9e44" }}></th>
              <th
                colSpan={2}
                style={{ backgroundColor: "#2f9e44", textAlign: "center" }}
              ></th>
            </tr>
            <tr>
              <th colSpan={26}></th>
              <th
                colSpan={2}
                style={{ backgroundColor: "yellow", textAlign: "center" }}
              >
                P- PLAN
              </th>
              <th
                colSpan={3}
                style={{ backgroundColor: "#2f9e44", textAlign: "center" }}
              >
                A- ACTUAL
              </th>
            </tr>

            <tr style={{ textAlign: "center" }}>
              {[
                "S.N.",
                "PHASE",
                "ACTIVITY",
                "REQUIRED",
                "NOT REQUIRED",
                "RESPONSIBILITY",
                "MONTHS",
                "",
                "START DATE",
                "COMPLETE DATE",
                "REMARKS",
                "UPLOAD PROOF",
              ].map((heading, index) => (
                <th
                  key={index + "heading"}
                  rowSpan={index === 6 ? 1 : 3}
                  colSpan={index === 6 ? 20 : 1}
                >
                  {heading}
                </th>
              ))}
            </tr>
            <tr className="center-table-content">
              {Array(4)
                .fill(0)
                .map((heading, index) => (
                  <th key={index + "month picker"} colSpan={5}>
                    <DatePicker
                      views={["month"]}
                      label="Month"
                      format="MM/yyyy"
                      className="date-field"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      style={{
                        backgroundColor: "#f8f9fa",
                      }}
                      clearable
                      value={apqpData?.phase_months?.[index] || null}
                      onChange={(date) => {
                        apqpData.phase_months[index] = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  </th>
                ))}
            </tr>
            <tr className="center-table-content">
              {[
                "W1",
                "W2",
                "W3",
                "W4",
                "W5",
                "W1",
                "W2",
                "W3",
                "W4",
                "W5",
                "W1",
                "W2",
                "W3",
                "W4",
                "W5",
                "W1",
                "W2",
                "W3",
                "W4",
                "W5",
              ].map((heading, index) => (
                <th key={index + "month p a"}>{heading}</th>
              ))}
            </tr>
          </thead>
          <tbody className="center-table-content">
            {apqpData[phase].map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td rowSpan={2}>{item.s_no}</td>
                  {index === 0 && (
                    <td rowSpan={apqpData[phase].length * 2}>{title}</td>
                  )}
                  <td rowSpan={2} style={{ minWidth: "500px" }}>
                    {item.activity}
                  </td>
                  <td
                    rowSpan={2}
                    style={{
                      padding: "0px",
                      margin: "0px",
                      backgroundColor: "#f1f3f5",
                    }}
                    onClick={(e) => handleDoubleClick(e, index, true)}
                  >
                    <span
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                      onMouseDown={MouseDown}
                      style={{
                        fontSize: "3rem",
                        display: "flex",
                        outline: "none",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#495057",
                        backgroundColor: "#f1f3f5",
                        boxShadow: "0 30px 40px rgb(255, 227, 227,.2)",
                        cursor: "pointer",
                        textShadow: "1px 1px #b0b0b0",
                      }}
                    >
                      {item.isRequired === true && "🗸"}
                    </span>
                  </td>
                  <td
                    rowSpan={2}
                    style={{
                      padding: "0px",
                      margin: "0px",
                      backgroundColor: "#f1f3f5",
                    }}
                    onClick={(e) => handleDoubleClick(e, index, false)}
                  >
                    <span
                      onMouseOver={MouseOver}
                      onMouseOut={MouseOut}
                      onMouseDown={MouseDown}
                      style={{
                        fontSize: "3rem",
                        display: "flex",
                        outline: "none",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#495057",
                        backgroundColor: "#f1f3f5",
                        boxShadow: "0 30px 40px rgb(255, 227, 227,.2)",
                        cursor: "pointer",
                        textShadow: "1px 1px #b0b0b0",
                      }}
                    >
                      {item.isRequired === false && "🗸"}
                    </span>
                  </td>
                  <td rowSpan={2} style={{ minWidth: "200px" }}>
                    {item.resp}
                  </td>

                  {/* months _ 1 */}
                  {Array.from({ length: 5 }).map((_, monthIdx) =>
                    handleMonthsDoubleClick(index, "month_1", "p", monthIdx)
                  )}
                  {/* months _ 2 */}
                  {Array.from({ length: 5 }).map((_, monthIdx) =>
                    handleMonthsDoubleClick(index, "month_2", "p", monthIdx)
                  )}
                  {/* months _ 3 */}
                  {Array.from({ length: 5 }).map((_, monthIdx) =>
                    handleMonthsDoubleClick(index, "month_3", "p", monthIdx)
                  )}
                  {/* months _ 4 */}
                  {Array.from({ length: 5 }).map((_, monthIdx) =>
                    handleMonthsDoubleClick(index, "month_4", "p", monthIdx)
                  )}
                  <td
                    style={{
                      backgroundColor: "yellow",
                      textAlign: "center",
                      minWidth: "50px",
                    }}
                  >
                    P
                  </td>
                  <td style={{ minWidth: "100px" }}>
                    {
                      <DatePicker
                        name="date_performance"
                        format="dd/MM/yyyy"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                        }}
                        value={item.start_date?.p || null}
                        clearable
                        onChange={(date) => {
                          item.start_date.p = date;
                          setRender((prev) => !prev);
                        }}
                      />
                    }
                  </td>
                  <td style={{ minWidth: "100px" }}>
                    {
                      <DatePicker
                        name="date_performance"
                        format="dd/MM/yyyy"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                        }}
                        value={item.complete_date?.p || null}
                        clearable
                        onChange={(date) => {
                          item.complete_date.p = date;
                          handleRemarks(
                            item?.complete_date?.p,
                            item?.complete_date?.a,
                            item.activity,
                            index
                          );
                          setRender((prev) => !prev);
                        }}
                      />
                    }
                  </td>
                  <td
                    rowSpan={2}
                    style={{
                      background:
                        item.remarks === "DELAYED"
                          ? "red"
                          : item.remarks === "ON TRACK"
                          ? "green"
                          : "white",
                      color: "white",
                    }}
                  >
                    {item.remarks}
                  </td>
                  <td rowSpan={2}>
                    {apqpData._id && (
                      <IconButton
                        size="small"
                        style={{ marginLeft: "0.5rem" }}
                        onClick={async () => {
                          await onSubmit();
                          history.push(`/viewFile/${partId}/${item._id}`);
                        }}
                      >
                        <Visibility
                          style={{
                            fontSize: "1.2rem",
                            color: "#003566",
                          }}
                        />
                      </IconButton>
                    )}
                  </td>
                </tr>

                <tr>
                  {/* months _ 1 */}
                  {Array.from({ length: 5 }).map((_, monthIdx) =>
                    handleMonthsDoubleClick(index, "month_1", "a", monthIdx)
                  )}
                  {/* months _ 2 */}
                  {Array.from({ length: 5 }).map((_, monthIdx) =>
                    handleMonthsDoubleClick(index, "month_2", "a", monthIdx)
                  )}
                  {/* months _ 3 */}
                  {Array.from({ length: 5 }).map((_, monthIdx) =>
                    handleMonthsDoubleClick(index, "month_3", "a", monthIdx)
                  )}
                  {/* months _ 4 */}
                  {Array.from({ length: 5 }).map((_, monthIdx) =>
                    handleMonthsDoubleClick(index, "month_4", "a", monthIdx)
                  )}

                  <td
                    style={{
                      backgroundColor: "#2f9e44",
                      textAlign: "center",
                      minWidth: "50px",
                    }}
                  >
                    A
                  </td>

                  <td style={{ minWidth: "100px" }}>
                    {
                      <DatePicker
                        name="date_performance"
                        format="dd/MM/yyyy"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                        }}
                        clearable
                        value={item.start_date?.a || null}
                        onChange={(date) => {
                          item.start_date.a = date;
                          setRender((prev) => !prev);
                        }}
                      />
                    }
                  </td>
                  <td style={{ minWidth: "100px" }}>
                    {
                      <DatePicker
                        name="date_performance"
                        format="dd/MM/yyyy"
                        InputProps={{
                          disableUnderline: true,
                        }}
                        style={{
                          backgroundColor: "#f8f9fa",
                        }}
                        clearable
                        value={item.complete_date?.a || null}
                        onChange={(date) => {
                          item.complete_date.a = date;
                          handleRemarks(
                            item?.complete_date?.p,
                            item?.complete_date?.a,
                            item.activity,
                            index
                          );
                          setRender((prev) => !prev);
                        }}
                      />
                    }
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
          <tbody className="center-table-content">
            <tr>
              <th colSpan={6} style={{ textAlign: "center" }}>
                PROJECT Leader Review(PL)
              </th>
              <th colSpan={25}>
                {" "}
                {isView ? (
                  apqpData[phase][0]
                ) : (
                  <Autocomplete
                    id="employee_id"
                    options={employeeAutocomplete}
                    getOptionLabel={(option) => option.employee_name}
                    getOptionSelected={(option, value) =>
                      option?.employee_name === value?.employee_name
                    }
                    style={{ width: "30%" }}
                    value={employeeValue[`${phase}_employees_id`][0]}
                    onChange={(e, value) => {
                      console.log(apqpData, phase);
                      apqpData[`${phase}_employees_id`][0] = value?._id || null;
                      let values = { ...employeeValue };
                      values[`${phase}_employees_id`][0] = value;
                      setEmployeeValue(values);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "100%" }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                )}
              </th>
            </tr>
            <tr>
              <th colSpan={6} style={{ textAlign: "center" }}>
                REVIEWED BY CFT
              </th>
              <th colSpan={25}>
                {isView ? (
                  apqpData[phase][1]
                ) : (
                  <Autocomplete
                    id="employee_id"
                    options={employeeAutocomplete}
                    getOptionLabel={(option) => option.employee_name}
                    getOptionSelected={(option, value) =>
                      option?.employee_name === value?.employee_name
                    }
                    style={{ width: "30%" }}
                    value={employeeValue[`${phase}_employees_id`][1]}
                    onChange={(e, value) => {
                      apqpData[`${phase}_employees_id`][1] = value?._id || null;
                      let values = { ...employeeValue };
                      values[`${phase}_employees_id`][1] = value;
                      setEmployeeValue(values);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "100%" }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                )}
              </th>
            </tr>
            <tr>
              <th colSpan={6} style={{ textAlign: "center" }}>
                Top Management Review (™)
              </th>
              <th colSpan={25}>
                {isView ? (
                  apqpData[phase][2]
                ) : (
                  <Autocomplete
                    id="employee_id"
                    options={employeeAutocomplete}
                    getOptionLabel={(option) => option.employee_name}
                    getOptionSelected={(option, value) =>
                      option?.employee_name === value?.employee_name
                    }
                    style={{ width: "30%" }}
                    value={employeeValue[`${phase}_employees_id`][2]}
                    onChange={(e, value) => {
                      apqpData[`${phase}_employees_id`][2] = value?._id || null;
                      let values = { ...employeeValue };
                      values[`${phase}_employees_id`][2] = value;
                      setEmployeeValue(values);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "100%" }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                )}
              </th>
            </tr>
            <tr style={{ height: "50px" }}>
              <th colSpan={6} style={{ textAlign: "center" }}>
                Management Review and Feedback :
              </th>
              <th colSpan={25}></th>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>SN</td>
              <td colSpan={3}>ACTIVITY</td>
              <td colSpan={13}>DETAIL OF ACTIVITY FOR DELAY/RESCHEDULING</td>
              <td colSpan={12}>ACTION PLAN</td>
              <td colSpan={5}>SIGN.</td>
            </tr>
            {apqpData?.[`${phase}_remarks`]?.length > 0 &&
              apqpData[`${phase}_remarks`].map((item, index) => (
                <React.Fragment key={index + "remakss"}>
                  <tr style={{ textAlign: "center" }}>
                    <th>{item.s_no}</th>
                    <th colSpan={3}>{item.activity}</th>
                    <th colSpan={13}></th>
                    <th colSpan={12}>
                      <TableReadEditCell
                        name="detail_of_activity"
                        value={item.detail_of_activity}
                        handleChange={(e, index) => {
                          apqpData[`${phase}_remarks`][
                            index
                          ].detail_of_activity = e.target.value;
                          setRender((prev) => !prev);
                        }}
                        {...{ isView, index }}
                      />
                    </th>
                    <th colSpan={5}></th>
                  </tr>
                </React.Fragment>
              ))}
          </tfoot>
        </Table>
      </TableScroll>
    </main>
  );
};

export default ApqpTimePlanTable;
