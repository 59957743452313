import React from "react";
import { Link } from "react-router-dom";
import clausePage from "./ClausePage.module.css";
//icons
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import ListIcon from '@material-ui/icons/List';
// end icons

const Clause5 = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        Education And Training
      </div>
      <main className={`${clausePage.container} ${clausePage.main}`}>
        <div>
          <Link to="/masterProcedureList?category=clause-5" className="panel">
            <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Master <span className="card-btn-span">Procedure</span>
          </p>
        </div>
        <div>
          <Link to="/educationAndTraining/create" className="panel">
            <GroupAddIcon style={{ fontSize: "3rem", color: "#003566" }} />
          </Link>
          <p className="card-btn-para">
            Master List of
            <span className="card-btn-span">Employee</span>
          </p>
        </div>
        <div>
          <Link to="/allDepartment" className="panel">
            <DeveloperBoardIcon
              style={{ fontSize: "3rem", color: "#135089" }}
            />
          </Link>
          <p className="card-btn-para">
            Master List Of<span className="card-btn-span">Department</span>
          </p>
        </div>
        <div>
          <Link to="/facultyList" className="panel">
            <SupervisedUserCircleIcon
              style={{ fontSize: "3rem", color: "#343a40" }}
            />
          </Link>
          <p className="card-btn-para">
            Master List of
            <span className="card-btn-span">Faculty</span>
          </p>
        </div>
        <div>
          <Link to="/educationAndTraining" className="panel">
            <CastForEducationIcon
              style={{ fontSize: "3rem", color: "#135089" }}
            />
          </Link>
          <p className="card-btn-para">
            Training
            {/* <span className="card-btn-span">Training</span> */}
          </p>
        </div>
        <div>
          <Link to="/tilesOfSkillEvaluation" className="panel">
            <ChromeReaderModeIcon
              style={{ fontSize: "3rem", color: "#135089" }}
            />
          </Link>
          <p className="card-btn-para">
            Skill<span className="card-btn-span">Evaluation</span>
          </p>
        </div>
      </main>
    </>
  );
};

export default Clause5;
