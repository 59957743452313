import axios from "axios";
import React, { useEffect, useState } from "react";
import { Wrapper } from "../../../../components/Control/Flexbox";
import RenderGraph from "./RenderGraph";
const supplierRejectionFormated = (data) => {
  const supplierRejectionObj = {
    labels: [],
    data: [],
  };
  data.supplierRejections.forEach((supplier) => {
    if (supplier.total_rej_qty > 0) {
      const ppm = (
        ((supplier.total_rej_qty || 0) / (supplier.total_rec_qty || 1)) *
        1000000
      ).toFixed(2);
      supplierRejectionObj.labels.push(supplier.supplier_id?.supplier_name);
      supplierRejectionObj.data.push(ppm);
    }
  });
  return supplierRejectionObj;
};
const deliveryReportFormated = (date) => {
  const deliveryReportObj = {
    labels: [],
    data: [],
  };
  date.deliveryReports.forEach((supplier) => {
    if (supplier.percentage > 0) {
      deliveryReportObj.labels.push(supplier.supplier_id?.supplier_name);
      deliveryReportObj.data.push(supplier.percentage || 0);
    }
  });
  return deliveryReportObj;
};
const Dashboard = ({ annualDate }) => {
  const [supplierRejection, setSupplierRejection] = useState({
    labels: [],
    data: [],
  });
  const [deliveryReport, setDeliveryReport] = useState({
    labels: [],
    data: [],
  });
  useEffect(() => {
    axios
      .get(`/api/getSupplierRatingByAnnualDate/${annualDate}`)
      .then((res) => {
        if (!res.data?.error) {
          const supplierRejectionObj = supplierRejectionFormated(res.data);
          const deliveryReportObj = deliveryReportFormated(res.data);
          setSupplierRejection(supplierRejectionObj);
          setDeliveryReport(deliveryReportObj);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <section>
      <Wrapper
        style={{
          width: "97%",
          margin: "auto",
          paddingBottom: "3rem",
        }}
      >
        <RenderGraph data={supplierRejection} title="supplier ppm" />
      </Wrapper>

      <Wrapper
        style={{
          width: "97%",
          margin: "auto",
          paddingBottom: "3rem",
        }}
      >
        <RenderGraph data={deliveryReport} title="Delivery performance" />
      </Wrapper>
    </section>
  );
};

export default Dashboard;
