import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";

import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";

const LastPiece = ({ pirData, spec, index, idx }) => {
  const [render, setRender] = useState(false);
  const useStyles = makeStyles({
    root: {
      background: `${
        pirData.pir[index].setups[idx].lastpiececheck_result === "OK"
          ? "#5cb660"
          : "#ff8787"
      }`,
    },
    select: {
      color: `${
        pirData.pir[index].setups[idx].lastpiececheck_result === "OK"
          ? "#111 !important"
          : "#111"
      }`,
    },
    label: {
      textTransform: "capitalize",
    },
  });
  const checkResultOkORNot = (index, idx) => {
    if (
      spec.last_piece_first_color === "#f7aeae" ||
      spec.last_piece_second_color === "#f7aeae" ||
      spec.last_piece_third_color === "#f7aeae" ||
      spec.last_piece_fourth_color === "#f7aeae" ||
      spec.last_piece_fifth_color === "#f7aeae"
    ) {
      pirData.pir[index].setups[idx].lastpiececheck_result = "NOT OK";
      return;
    }
    pirData.pir[index].setups[idx].lastpiececheck_result = "OK";
  };

  function handleChangePirSetup({ target }, index, idx) {
    const value = target.type === "checkbox" ? target.checked : target.value;
    pirData.pir[index].setups[idx][target.name] = value;
    setRender((prev) => !prev);
  }
  function handleChangePirListSetup(
    { target },
    index,
    idx,
    mean,
    utolerance,
    ltolerance,
    old_utl,
    old_ltl,
    check4_symbol,
    checkbox4_num
  ) {
    const value = target.type === "checkbox" ? target.checked : target.value;
    pirData.pir[index].setups[idx][target.name] = value;
    let utl;
    let ltl;
    let uv;
    let lv;

    //max
    if (check4_symbol && checkbox4_num) {
      if (check4_symbol === "<=") {
        if (value <= checkbox4_num) {
          const name = `${target.name}_color`;
          pirData.pir[index].setups[idx][name] = "#a4d1a4";
          setRender((prev) => !prev);
        } else {
          const name = `${target.name}_color`;
          pirData.pir[index].setups[idx][name] = "#f7aeae";
          setRender((prev) => !prev);
        }
      }
      //min
      if (check4_symbol === ">=") {
        if (value >= checkbox4_num) {
          const name = `${target.name}_color`;
          pirData.pir[index].setups[idx][name] = "#a4d1a4";
          setRender((prev) => !prev);
        } else {
          const name = `${target.name}_color`;
          pirData.pir[index].setups[idx][name] = "#f7aeae";
          setRender((prev) => !prev);
        }
      }
    }
    if (old_utl || old_ltl) {
      utl = parseFloat(old_utl);
      ltl = parseFloat(old_ltl);
      const mean = (utl + ltl) / 2;
      uv = (mean + utl) / 2;
      lv = (mean + ltl) / 2;
    } else {
      utl = parseFloat(mean) + parseFloat(utolerance);
      ltl = parseFloat(mean) - parseFloat(ltolerance);

      uv = parseFloat(mean) + parseFloat(utolerance) / 2;
      lv = parseFloat(mean) - parseFloat(ltolerance) / 2;
    }

    if (value > utl || value < ltl) {
      const name = `${target.name}_color`;
      pirData.pir[index].setups[idx][name] = "#f7aeae";
      setRender((prev) => !prev);
    } else if ((uv < value && value <= utl) || (lv > value && value >= ltl)) {
      const name = `${target.name}_color`;
      pirData.pir[index].setups[idx][name] = "#fbfbac";
      setRender((prev) => !prev);
    } else if (value >= lv && value <= uv) {
      const name = `${target.name}_color`;
      pirData.pir[index].setups[idx][name] = "#a4d1a4";
      setRender((prev) => !prev);
    }

    if (value === "") {
      const name = `${target.name}_color`;
      pirData.pir[index].setups[idx][name] = "#ffffff";
    }
    checkResultOkORNot(index, idx);
  }
  const classes = useStyles();

  return (
    <Wrapper className="pir-modal-container">
      <Input
        name="parameter"
        label="Parameter"
        style={{ width: "230px" }}
        type="text"
        variant="filled"
        autoComplete="off"
        readOnly={true}
        value={spec.parameter}
      />
      <Input
        name="specification"
        label="Specification"
        style={{ width: "230px" }}
        type="text"
        variant="filled"
        autoComplete="off"
        readOnly
        value={spec.specification}
      />
      {(spec.appearance === "check1" ||
        spec.appearance === "check2" ||
        spec.appearance === "check4") && (
        <Input
          name="last_piece_first"
          label="1"
          type="number"
          variant="filled"
          autoComplete="off"
          style={{ width: "100px" }}
          inputProps={{
            step: 0.01,
            style: {
              borderColor: spec.last_piece_first_color,
              backgroundColor: spec.last_piece_first_color,
            },
          }}
          onChange={(e) =>
            handleChangePirListSetup(
              e,
              index,
              idx,
              spec.mean,
              spec.utolerance,
              spec.ltolerance,
              spec.utl,
              spec.ltl,
              spec.check4_symbol,
              spec.checkbox4_num
            )
          }
          defaultValue={spec.last_piece_first}
        />
      )}
      {spec.appearance === "check3" && (
        <Input
          variant="filled"
          autoComplete="off"
          name="last_piece_first"
          label="1"
          style={{ width: "100px" }}
          defaultValue={spec.last_piece_first}
          onChange={(e) => handleChangePirSetup(e, index, idx)}
        />
      )}

      {/* second check */}

      {(spec.appearance === "check1" ||
        spec.appearance === "check2" ||
        spec.appearance === "check4") && (
        <Input
          name="last_piece_second"
          label="2"
          type="number"
          variant="filled"
          autoComplete="off"
          style={{ width: "100px" }}
          inputProps={{
            step: 0.01,
            style: {
              borderColor: spec.last_piece_second_color,
              backgroundColor: spec.last_piece_second_color,
            },
          }}
          onChange={(e) =>
            handleChangePirListSetup(
              e,
              index,
              idx,
              spec.mean,
              spec.utolerance,
              spec.ltolerance,
              spec.utl,
              spec.ltl,
              spec.check4_symbol,
              spec.checkbox4_num
            )
          }
          defaultValue={spec.last_piece_second}
        />
      )}
      {spec.appearance === "check3" && (
        <Input
          variant="filled"
          autoComplete="off"
          name="last_piece_second"
          style={{ width: "100px" }}
          label="2"
          defaultValue={spec.last_piece_second}
          onChange={(e) => handleChangePirSetup(e, index, idx)}
        />
      )}

      {/* Third Check */}
      {(spec.appearance === "check1" ||
        spec.appearance === "check2" ||
        spec.appearance === "check4") && (
        <Input
          name="last_piece_third"
          label="3"
          type="number"
          variant="filled"
          autoComplete="off"
          style={{ width: "100px" }}
          inputProps={{
            step: 0.01,
            style: {
              borderColor: spec.last_piece_third_color,
              backgroundColor: spec.last_piece_third_color,
            },
          }}
          onChange={(e) =>
            handleChangePirListSetup(
              e,
              index,
              idx,
              spec.mean,
              spec.utolerance,
              spec.ltolerance,
              spec.utl,
              spec.ltl,
              spec.check4_symbol,
              spec.checkbox4_num
            )
          }
          defaultValue={spec.last_piece_third}
        />
      )}
      {spec.appearance === "check3" && (
        <Input
          variant="filled"
          autoComplete="off"
          name="last_piece_third"
          style={{ width: "100px" }}
          label="3"
          defaultValue={spec.last_piece_third}
          onChange={(e) => handleChangePirSetup(e, index, idx)}
        />
      )}

      {/* Fourth Check */}

      {(spec.appearance === "check1" ||
        spec.appearance === "check2" ||
        spec.appearance === "check4") && (
        <Input
          name="last_piece_fourth"
          label="4"
          type="number"
          variant="filled"
          autoComplete="off"
          style={{ width: "100px" }}
          inputProps={{
            step: 0.01,
            style: {
              borderColor: spec.last_piece_fourth_color,
              backgroundColor: spec.last_piece_fourth_color,
            },
          }}
          onChange={(e) =>
            handleChangePirListSetup(
              e,
              index,
              idx,
              spec.mean,
              spec.utolerance,
              spec.ltolerance,
              spec.utl,
              spec.ltl,
              spec.check4_symbol,
              spec.checkbox4_num
            )
          }
          defaultValue={spec.last_piece_fourth}
        />
      )}
      {spec.appearance === "check3" && (
        <Input
          variant="filled"
          autoComplete="off"
          name="last_piece_fourth"
          style={{ width: "100px" }}
          label="4"
          defaultValue={spec.last_piece_fourth}
          onChange={(e) => handleChangePirSetup(e, index, idx)}
        />
      )}

      {/* Fifth Check */}

      {(spec.appearance === "check1" ||
        spec.appearance === "check2" ||
        spec.appearance === "check4") && (
        <Input
          name="last_piece_fifth"
          label="5"
          type="number"
          style={{ width: "100px" }}
          variant="filled"
          autoComplete="off"
          inputProps={{
            step: 0.01,
            style: {
              borderColor: spec.last_piece_fifth_color,
              backgroundColor: spec.last_piece_fifth_color,
            },
          }}
          onChange={(e) =>
            handleChangePirListSetup(
              e,
              index,
              idx,
              spec.mean,
              spec.utolerance,
              spec.ltolerance,
              spec.utl,
              spec.ltl,
              spec.check4_symbol,
              spec.checkbox4_num
            )
          }
          defaultValue={spec.last_piece_fifth}
        />
      )}
      {spec.appearance === "check3" && (
        <Input
          variant="filled"
          autoComplete="off"
          name="last_piece_fifth"
          style={{ width: "100px" }}
          label="5"
          defaultValue={spec.last_piece_fifth}
          onChange={(e) => handleChangePirSetup(e, index, idx)}
        />
      )}

      {/* unit */}

      <Input
        name="unit"
        style={{ width: "100px" }}
        variant="filled"
        autoComplete="off"
        onChange={(e) => handleChangePirSetup(e, index, idx)}
        defaultValue={spec.specUnit}
        type="text"
        label="Unit"
        inputProps={{ readOnly: true }}
      />
      <Input
        name="remarks"
        style={{ width: "100px" }}
        variant="filled"
        autoComplete="off"
        onChange={(e) => handleChangePirSetup(e, index, idx)}
        defaultValue={spec.remarks}
        type="text"
        label="Remarks"
      />

      {/* Results */}
      {spec.appearance === "check3" ? (
        <FormControl
          style={{
            width: "120px",
            marginLeft: "0.55rem",
          }}
          variant="filled"
          classes={{
            root: classes.root,
            label: classes.label,
          }}
        >
          <InputLabel>Status</InputLabel>
          <Select
            name="lastpiececheck_result"
            defaultValue={spec.lastpiececheck_result}
            onChange={(e) => handleChangePirSetup(e, index, idx)}
            classes={{
              select: classes.select,
            }}
          >
            <MenuItem value="OK">OK</MenuItem>
            <MenuItem value="NOT OK">NOT OK</MenuItem>
          </Select>
        </FormControl>
      ) : (
        <Paper
          name="lastpiececheck_result"
          elevation={2}
          style={{
            width: "122px",
            height: "55px",
            display: "flex",
            alignItems: "center",
            marginLeft: "8px",
            backgroundColor: `${
              spec.lastpiececheck_result === "OK" ? "#5cb660" : "#ff8787"
            }`,
          }}
        >
          <Typography style={{ marginLeft: "1rem" }}>
            {spec.lastpiececheck_result === "OK" ? "OK" : "NOT OK"}
          </Typography>
        </Paper>
      )}
    </Wrapper>
  );
};

export default LastPiece;
