import { React, useEffect, useRef, useState } from "react";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";
import axios from "axios";

import { DivStyled } from "../Container.styled";

const TnaCreateModal = ({ setIsTnaModal }) => {
  const [tniData, setTniData] = useState({
    created_year: new Date(),
  });

  const handleSumbit = () => {
    axios
      .post(`/api/createTniReport/yealy`, tniData)
      .then((res) => {
        window.alert("success");
        setIsTnaModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <section style={{ padding: "0.3rem 0rem 0.3rem 0rem" }}>
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handleSumbit}
        >
          SUBMIT
        </Button>
      </div>
      <DivStyled>
        <label for="title">Title:</label>
        <input
          type="text"
          id="title"
          name="title"
          defaultValue={tniData?.title}
          onBlur={(e) => {
            setTniData((prev) => ({ ...prev, title: e.target.value }));
          }}
        />
      </DivStyled>
      <DivStyled>
        <label>Select Year:</label>
        <DatePicker
          views={["year"]}
          className="date-field"
          InputProps={{
            disableUnderline: true,
          }}
          clearable
          style={{
            borderLeft: "2px solid #ced4da",
            backgroundColor: "#fff",
            paddingLeft: "0.5rem",
            width: "100%",
          }}
          value={tniData.created_year || null}
          onChange={(date) => {
            setTniData((prev) => ({ ...prev, created_year: date }));
          }}
        />
      </DivStyled>
    </section>
  );
};

export default TnaCreateModal;
