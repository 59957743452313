import React, { useState } from "react";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
const CheckTime = ({ pirData, process, index }) => {
  const [render, setRender] = useState(false);
  function handleChangePir(e, index) {
    pirData.pir[index][e.target.name] = e.target.value;
    setRender((prev) => !prev);
  }
  return (
    <Wrapper>
      <div className="checktime-input">
        <Input
          name="setup_piece_time"
          variant="filled"
          type="time"
          label="Setup Time"
          className="date-field"
          style={{ width: "98%" }}
          onBlur={(e) => handleChangePir(e, index)}
          defaultValue={process.setup_piece_time}
        />
      </div>
      <div className="checktime-input">
        <Input
          name="first_check_time"
          variant="filled"
          label="Time 1"
          defaultValue={process.first_check_time}
          type="time"
          className="date-field"
          style={{ width: "98%" }}
          onBlur={(e) => handleChangePir(e, index)}
        />
      </div>
      <div className="checktime-input">
        <Input
          name="second_check_time"
          variant="filled"
          type="time"
          label="Time 2"
          defaultValue={process.second_check_time}
          className="date-field"
          style={{ width: "98%" }}
          onBlur={(e) => handleChangePir(e, index)}
        />
      </div>
      <div className="checktime-input">
        <Input
          name="third_check_time"
          variant="filled"
          defaultValue={process.third_check_time}
          type="time"
          label="Time 3"
          style={{ width: "98%" }}
          className="date-field"
          onBlur={(e) => handleChangePir(e, index)}
        />
      </div>
      <div className="checktime-input">
        <Input
          name="fourth_check_time"
          defaultValue={process.fourth_check_time}
          variant="filled"
          type="time"
          label="Time 4"
          style={{ width: "98%" }}
          className="date-field "
          onBlur={(e) => handleChangePir(e, index)}
        />
      </div>
      {/* <div className="checktime-input">
        <Input
          name="fifth_check_time"
          defaultValue={process.fifth_check_time}
          variant="filled"
          type="time"
          label="Time 5"
          style={{ width: "98%" }}
          className="date-field "
          onBlur={(e) => handleChangePir(e, index)}
        />
      </div> */}
    </Wrapper>
  );
};

export default CheckTime;
