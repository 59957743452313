import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(0.3),
      padding: "7px",
    },
    "& .MuiFilledInput-root": {
      backgroundColor: "#fafafa",
      "&:hover": {
        backgroundColor: "#f5f5f5",
      },
    },

    "& .MuiInputBase-input": {
      "&:focus": {
        backgroundColor: "#fafafa",
      },
    },
    "& .MuiFilledInput-underline": {
      "&:after": {
        border: "1px solid #c80202",
      },
    },
    "& .MuiFormLabel-root": {
      marginLeft: "6px",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#c80202",
      marginLeft: "6px",
    },
  },
}));

function Input(props) {
  const {
    placeholder,
    value,
    _ref,
    type,
    name,
    defaultValue,
    onBlur,
    onChange,
    helperText,
    ...rest
  } = props;
  const classes = useStyles();

  // useEffect(() => {
  //
  // }, [defaultValue]);

  return (
    <div className={classes.root}>
      <TextField
        name={name}
        variant="filled"
        value={value}
        label={placeholder}
        type={type}
        onBlur={onBlur}
        onChange={onChange}
        onKeyPress={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
        inputRef={_ref}
        defaultValue={defaultValue}
        helperText={helperText}
        {...rest}
      ></TextField>
    </div>
  );
}

export default Input;
