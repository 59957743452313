import React, { useState, useEffect, useRef } from "react";
import { Button } from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import { Add, Remove } from "@material-ui/icons";
import PublishIcon from "@material-ui/icons/Publish";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import axios from "axios";

import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import { useParams, Link, useHistory } from "react-router-dom";
import useConfirm from "../../../components/Control/useConfirm";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { DivStyled, InputStyled, GridfourStyled } from "./questionPaperStyle";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";

const CreateEditQuestionPaper = () => {
  const history = useHistory();
  const { id } = useParams();
  const [render, setRender] = useState(false);
  const [currentLevelIndex, setCurrentLevelIndex] = useState(0);
  const [addQuestion, setAddQuestion] = useState("");
  const [expandState, setExpandState] = useState([]);

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogDeleteProcess, confirmDeleteProcess] = useConfirm(
    "DELETE QUESTION",
    "Are you sure you want to delete this Question?"
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getOneQuestionPaper/${id}`)
        .then((res) => {
          setAddQuestion(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handelSubmit = async () => {
    let ans = await confirmUpdate();
    if (!ans) return;
    axios
      .put(`/api/updateQuestionPaper/${id}`, addQuestion)
      .then((res) => {
        window.alert("success");
      })
      .catch((err) => console.log(err));
  };

  const sortQuestionPaper = (e, index) => {
    addQuestion.levels[currentLevelIndex].question[index].s_no =
      +e.target.value;
    addQuestion.levels[currentLevelIndex].question.sort(
      (a, b) => Number(a.s_no) - Number(b.s_no)
    );
    setRender((prev) => !prev);
  };

  const removeProcess = async (index) => {
    let ans = await confirmDeleteProcess();
    if (!ans) return;
    addQuestion.levels[currentLevelIndex].question.splice(index, 1);
    setExpandState((prev) => {
      prev[index] = false;
      return [...prev];
    });
  };

  return (
    <main style={{ paddingBottom: "4rem" }}>
      <div
        className="divflex"
        style={{
          fontSize: "24px",

          alignItems: "center",
        }}
      >
        ADD EDIT QUESTION PAPER
        <div style={{ display: "flex" }}>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            <Button
              type="submit"
              variant="outlined"
              color="white"
              size="large"
              startIcon={<PublishIcon />}
              onClick={handelSubmit}
              style={{
                backgroundColor: "#054A29",
                marginRight: "0.5rem",
                color: "#fff",
              }}
            >
              Save and continue
            </Button>
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </Wrapper>
        </div>
      </div>

      <section style={{ margin: "1rem", paddingBottom: "4rem" }}>
        <FormControl variant="outlined" style={{ width: "400px" }}>
          <InputLabel>Select Level</InputLabel>
          <Select
            variant="outlined"
            autoWidth="true"
            value={
              (addQuestion &&
                addQuestion?.levels[currentLevelIndex]?.levels_type) ||
              ""
            }
            style={{ fontSize: "0.8rem" }}
            disableUnderline
            onChange={(e) => {
              setCurrentLevelIndex(+e.target.value.split("_")[1] - 1);
            }}
          >
            <MenuItem value="Level_1">Level 1</MenuItem>
            <MenuItem value="Level_2">Level 2</MenuItem>
            <MenuItem value="Level_3">Level 3</MenuItem>
            <MenuItem value="Level_4">Level 4</MenuItem>
          </Select>
        </FormControl>

        <Wrapper
          justify="space-between"
          style={{ borderBottom: "1px solid #c80202", marginTop: "1rem" }}
        >
          <p className="card-btn-para" style={{ color: "#c80202" }}>
            • ADD{" "}
            <span style={{ fontWeight: 400, color: "black" }}>Question</span>
          </p>
          <Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "175px",
                backgroundColor: "#151a1d",
                color: "white",
                cursor: "pointer",
                display: "flex",
                gap: "0.5rem",
                marginRight: "0.2rem",
              }}
              onClick={() => {
                addQuestion &&
                  addQuestion?.levels[currentLevelIndex].question.push({
                    s_no:
                      addQuestion?.levels[currentLevelIndex].question.length +
                      1,
                    question: "",
                    option_1: "",
                    option_2: "",
                    option_3: "",
                    option_4: "",
                  });
                setExpandState((prev) => {
                  prev[
                    addQuestion?.levels[currentLevelIndex].question.length - 1
                  ] = true;
                  return [...prev];
                });
              }}
              justify="center"
            >
              <Add />
              <span>Add Question</span>
            </Wrapper>
          </Wrapper>
        </Wrapper>

        {addQuestion &&
          addQuestion?.levels[currentLevelIndex].question.map((item, index) => (
            <div
              key={index}
              style={{
                marginTop: "0.5rem",
              }}
            >
              <div
                key={process?.key}
                style={{
                  marginBottom: "0.3rem",
                  marginTop: "0.6rem",
                  padding: "0rem",
                  border: "1px solid #f0f2f0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    background: "#fafafa",
                    alignItems: "center",
                  }}
                  className="spec-header"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2rem",
                    }}
                  >
                    <Wrapper>
                      <Input
                        type="Number"
                        name="s_no"
                        value={item.s_no}
                        onChange={(e) => sortQuestionPaper(e, index)}
                        style={{
                          width: "70px",
                        }}
                      />
                    </Wrapper>

                    <div
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      {item?.question}
                    </div>
                  </div>

                  <Wrapper>
                    {expandState[index] ? (
                      <Wrapper
                        style={{
                          height: "45px",
                          width: "130px",
                          backgroundColor: "#003566",
                          cursor: "pointer",
                          color: "white",
                          alignItems: "center",
                          gap: "0.5rem",
                          // justifyContent: "center",
                        }}
                        onClick={() =>
                          setExpandState((prev) => {
                            prev[index] = false;
                            return [...prev];
                          })
                        }
                      >
                        <ExpandLess style={{ marginLeft: "0.5rem" }} />
                        <span>Collapse</span>
                      </Wrapper>
                    ) : (
                      <Wrapper
                        style={{
                          height: "45px",
                          width: "130px",
                          backgroundColor: "#003566",
                          cursor: "pointer",
                          color: "white",
                          gap: "0.5rem",
                          // justifyContent: "center",
                        }}
                        onClick={() =>
                          setExpandState((prev) => {
                            prev[index] = true;
                            return [...prev];
                          })
                        }
                      >
                        <ExpandMore style={{ marginLeft: "0.5rem" }} />
                        <span>Expand</span>
                      </Wrapper>
                    )}

                    <Wrapper
                      style={{
                        height: "45px",
                        width: "45px",
                        // backgroundColor: " #212529",
                        color: "#f1f3f5",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        gap: "0.5rem",
                        backgroundColor: "#D70012",
                      }}
                      justify="center"
                      onClick={() => removeProcess(index)}
                    >
                      <DeleteIcon style={{ color: "#f8f9fa" }} />
                    </Wrapper>
                  </Wrapper>
                </div>
              </div>
              {expandState[index] && (
                <DivStyled>
                  <span>
                    Q{item.s_no}:
                    <InputStyled
                      width="95%"
                      type="text"
                      name="question"
                      placeholder="Question"
                      defaultValue={item?.question}
                      onBlur={(e) => {
                        item[e.target.name] = e.target.value;
                      }}
                    />
                  </span>

                  <GridfourStyled>
                    <span>
                      A:
                      <InputStyled
                        width="30vw"
                        type="text"
                        name="option_1"
                        placeholder="Option 1"
                        defaultValue={item?.option_1}
                        onBlur={(e) => {
                          item[e.target.name] = e.target.value;
                        }}
                      />
                    </span>
                    <span>
                      B:
                      <InputStyled
                        width="30vw"
                        type="text"
                        name="option_2"
                        placeholder="Option 2"
                        defaultValue={item?.option_2}
                        onBlur={(e) => {
                          item[e.target.name] = e.target.value;
                        }}
                      />
                    </span>
                    <span>
                      C:
                      <InputStyled
                        width="30vw"
                        type="text"
                        name="option_3"
                        placeholder="Option 3"
                        defaultValue={item?.option_3}
                        onBlur={(e) => {
                          item[e.target.name] = e.target.value;
                        }}
                      />
                    </span>
                    <span>
                      D:
                      <InputStyled
                        width="30vw"
                        type="text"
                        name="option_4"
                        placeholder="Option 4"
                        defaultValue={item?.option_4}
                        onBlur={(e) => {
                          item[e.target.name] = e.target.value;
                        }}
                      />
                    </span>
                  </GridfourStyled>
                </DivStyled>
              )}
            </div>
          ))}
      </section>
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
      <DialogDeleteProcess isSubmitContent={false} />
    </main>
  );
};

export default CreateEditQuestionPaper;
