import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  // InputAdornment,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

// import { Search } from "@material-ui/icons";
// import DescriptionIcon from "@material-ui/icons/Description";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
// import Input from "../../../components/Control/Input";
// import { Wrapper } from "../../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";

import Modal from "../../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../../components/Control/useDebounce";
import { Link, useHistory, useParams } from "react-router-dom";
import useConfirm from "../../../components/Control/useConfirm";
import { useQuery } from "../../../components/Control/useQuery";
import formatDate from "../../../components/Control/formatDate";
// import UploadFileModal from "./UploadFileModal";

const getCurrentStatus = (status) => {
  const statusMap = {
    false: [{ bgColor: "#fcc419", width: "300px", text: "PENDING" }],
    true: [{ bgColor: "#2f9e44", width: "300px", text: "COMPLETED" }],
  };

  const [{ bgColor, text, width }] = statusMap[status] || [];
  return bgColor ? (
    <TableCell align="center" style={{ backgroundColor: bgColor, width }}>
      {text}
    </TableCell>
  ) : (
    <TableCell></TableCell>
  );
};

const SchedulePlanList = () => {
  const [data, setData] = useState([]);
  // const [isSupplerAuditModal, setIsSupplierAuditModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [search, setSearch] = useState();
  const history = useHistory();
  const { auditId } = useParams();
  const [isUploadFileModal, setUploadFileModal] = useState(false);
  const [complaintId, setComplaintId] = useState(null);

  const query = useQuery();
  const supplier = query.get("supplier");
  const plan_id = query.get("plan_id");
  const supplier_id = query.get("supplier_id");
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this report?"
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/getInterAuditSchedule/data/${auditId}/?page=${page}&search=${search}&plan_id=${plan_id}&supplier_id=${supplier_id}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [page, search, render]);

  async function deleteInternalAudit(id) {
    const isConfirm = await confirmDelete();
    if (isConfirm) {
      await axios.delete(`/api/deleteInternalAuditSchedule/${id}`);
      setRender((prev) => !prev);
    }
  }

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div
          className="divflex"
          style={{ fontSize: "24px", textTransform: "uppercase" }}
        >
          Internal Audit Schedule
          <div>
            <Link
              to={`/addEditViewSchedulePlan/${auditId}?supplier=${supplier}`}
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="contained"
                style={{
                  background: "#064a29",
                  color: "#fafafa",
                }}
                startIcon={<Add />}
              >
                New Schedule
              </Button>
            </Link>
          </div>
        </div>
        <div className="subheading-container">
          <p className="subheading-primary">
            ACTIVE <span className="subheading-secondary">PLANS</span>
          </p>
        </div>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  S.no
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Supplier
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Date
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Scope
                </TableCell>

                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          maxWidth: "15rem",
                          width: "15rem",
                        }}
                      >
                        {supplier}
                      </TableCell>
                      <TableCell align="center">
                        {formatDate(item?.plant_date)}
                      </TableCell>
                      <TableCell align="center">{item.scopes}</TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <Tooltip title="View Checklist" arrow>
                          <Link
                            to={`/addEditViewSchedulePlan/${auditId}/${item._id}?isView=true&supplier=${supplier}`}
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <VisibilityIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Edit Checklist" arrow>
                          <Link
                            to={`/addEditViewSchedulePlan/${auditId}/${item._id}?supplier=${supplier}`}
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <EditIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Delete Checklist" arrow>
                          <IconButton
                            size="small"
                            onClick={() => deleteInternalAudit(item._id)}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <DialogDelete isSubmitContent={true} />

        {/* <Modal
          title={"upload"}
          openPopup={isUploadFileModal}
          closeModal={() => {
            setUploadFileModal(false);
            setComplaintId(null);
          }}
          backgroundColor="white"
        >
          <UploadFileModal
            {...{ setUploadFileModal, setComplaintId }}
            id={complaintId}
          />
        </Modal> */}
      </main>
    </>
  );
};

export default SchedulePlanList;
