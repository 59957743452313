import "date-fns";
import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import ProcessPir from "../CreatePir/ProcessPir";
import axios from "axios";
import { useHistory } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import { DatePicker } from "@material-ui/pickers";
import useConfirm from "../../../components/Control/useConfirm";

function PirForm({ data, partId, id }) {
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE PIR",
    "Are you sure you want to update this PIR?"
  );
  const history = useHistory();
  const [pirData, setPirData] = useState(data);

  async function handleSubmit(e) {
    e.preventDefault();
    const ans = await confirmUpdate();
    if (!ans) {
      return;
    }
    axios
      .patch(`/api/update/pir/${id}`, pirData, {
        "Content-Type": "application/json",
      })
      .then((res) => {
        history.push(`/ViewPir/table/${id}`);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <main>
        {data && (
          <>
            <div
              className="divflex "
              style={{
                fontSize: "24px",
              }}
            >
              PROCESS INSPECTION REPORT
              <div className="text">
                <p style={{ marginBottom: "-0.5rem" }}>
                  {" "}
                  <span style={{ fontWeight: "600", fontSize: "14px" }}>
                    Item Number :{" "}
                  </span>
                  {pirData.part_id.part_name}
                </p>
                <p style={{ marginBottom: "0px" }}>
                  {" "}
                  <span style={{ fontWeight: "600", fontSize: "14px" }}>
                    Revision No. :{" "}
                  </span>
                  {pirData.part_id.revision_no}
                </p>
              </div>
            </div>
            <div
              className="divflex"
              style={{ height: "4rem", border: "none" }}
            ></div>
            <form
              style={{
                margin: "0.6rem",
              }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Wrapper>
                <div style={{ marginLeft: "0.7rem" }}>
                  <DatePicker
                    label="Date"
                    className="date-field"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    onChange={(date) =>
                      setPirData((prev) => ({ ...prev, pir_date: date }))
                    }
                    value={pirData.pir_date}
                  />
                </div>
              </Wrapper>

              <ProcessPir
                {...{
                  pirData,
                }}
              />
              <Wrapper
                style={{
                  position: "absolute",
                  top: "10rem",
                  right: "1.6rem",
                  gap: "1rem",
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ backgroundColor: "#054a29" }}
                  startIcon={<SaveIcon />}
                >
                  Update
                </Button>
              </Wrapper>
            </form>
          </>
        )}
      </main>
      <DialogUpdate isSubmitContent={false} />
    </>
  );
}

export default PirForm;
