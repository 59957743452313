import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import { useAuth } from "../../context/GlobalContext";
import formatDate from "../../components/Control/formatDate";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";
import Styled from "styled-components";
import { Tooltip, Button } from "@material-ui/core";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Wrapper } from "../../components/Control/Flexbox";

import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:100%;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

`;

const getTotalProductionPlan = (data) => {
  return data.processes.reduce(
    (acc, curr) => acc + (+curr.production_plan || 0),
    0
  );
};
const getTotalAcctualPlan = (data) => {
  return data.processes.reduce((acc, curr) => acc + (+curr.actual_qty || 0), 0);
};
const ViewDailyProductionTable = () => {
  const [productionPlanningData, setProductionPlanningData] = useState({
    processes: [],
  });
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/getOneProductionPlanning/${id}`)
      .then((res) => {
        setProductionPlanningData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <section>
      <div style={{ paddingBottom: "4rem" }}>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
              textTransform: "uppercase",
            }}
          >
            Production Planning & Control Report
          </Typography>
          <Wrapper>
            <Tooltip arrow title="Export as spreadsheet">
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  borderRadius: "0",
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: `PPC ${formatDate(
                        productionPlanningData?.productionPlanning_date
                      )}.xlsx`,
                      sheet: {
                        name: "production planning",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </Tooltip>
          </Wrapper>
        </div>
        <div
          style={{
            margin: "0.1rem 0.5rem",
            paddingBottom: "4rem",
            overflowX: "scroll",
          }}
        >
          <Table
            cellSpacing="0"
            style={{ marginTop: "0.6rem", backgroundColor: "white" }}
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="10, 18, 18, 18, 18, 18, 18, 18, 18 18 18 18"
          >
            <thead>
              <tr data-height="80" style={{ height: "70px" }}>
                <th
                  colSpan="12"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
              </tr>
              <tr>
                <th
                  colSpan="12"
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                  data-font-sz="18"
                  data-a-h="center"
                  data-f-bold="true"
                  data-fill-color="#1d1d1d"
                  data-f-color="FFFFFF"
                >
                  Production Planning & Control Report
                </th>
              </tr>
              <tr>
                <th
                  colSpan={4}
                  rowSpan={2}
                  align="center"
                  style={{ border: "1px solid black" }}
                >
                  Date:-{" "}
                  {formatDate(productionPlanningData?.productionPlanning_date)}
                </th>
                <th
                  colSpan={5}
                  rowSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  Shift : {productionPlanningData?.shift}
                </th>
                <th
                  colSpan={3}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  DOC NO:ARC/F/PPC/01
                </th>
              </tr>
              <tr>
                <th
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  REV.NO/DATE-02/07/2019
                </th>
              </tr>
              <tr>
                <th>Department:</th>
                <th colSpan={12}>
                  {productionPlanningData?.department_id?.department_name}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "Sr. No",
                  "Machine No.",
                  "Sch Qty",
                  "Lot Qty",
                  "Part No.",
                  "Production Plan",
                  "Actual Qty",
                  "Failure Qty",
                  "Reason for Failures",
                  "Production %",
                  "Heat No.",
                  "Remarks",
                ].map((heading, index) => (
                  <th
                    key={index}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
            </tbody>
            {productionPlanningData &&
              productionPlanningData.processes.map((item, index) => (
                <tr key={item._id || index}>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.sr_no}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.machine_no}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.sch_qty}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.lot_qty}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.part_number}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.production_plan}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.actual_qty}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.rejection_qty}
                  </td>
                  <td>
                    <div style={{ border: "none" }}>
                      {item?.reason_of_rejection?.map((d) => {
                        return d?.reason ? (
                          <span>
                            {d?.reason} - {d?.rej_qty} ,{" "}
                          </span>
                        ) : null;
                      })}
                    </div>
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {isNaN(+item.actual_qty / +item.production_plan)
                      ? ""
                      : (
                          (+item.actual_qty / +item.production_plan) *
                          100
                        ).toFixed(2)}
                  </td>
                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.heat_no}
                  </td>

                  <td align="center" style={{ border: "1px solid black" }}>
                    {item.remark}
                  </td>
                </tr>
              ))}
            <tr>
              <th colSpan={5}>TOTAL:</th>
              <th>{getTotalProductionPlan(productionPlanningData)}</th>
              <th>{getTotalAcctualPlan(productionPlanningData)}</th>
              <th>
                {getTotalProductionPlan(productionPlanningData) -
                  getTotalAcctualPlan(productionPlanningData)}
              </th>
              <th></th>
              <th>
                {isNaN(
                  getTotalAcctualPlan(productionPlanningData) /
                    getTotalProductionPlan(productionPlanningData)
                )
                  ? ""
                  : (
                      (getTotalAcctualPlan(productionPlanningData) /
                        getTotalProductionPlan(productionPlanningData)) *
                      100
                    ).toFixed(2)}
                {}
              </th>
              <th></th>
              <th></th>
            </tr>
            <tfoot>
              <tr style={{ height: "60px" }} data-height="35">
                <td
                  align="left"
                  colSpan={2}
                  style={{ fontWeight: 500, border: "1px solid black" }}
                >
                  Prepared By:
                </td>
                <td
                  align="left"
                  colSpan={4}
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                >
                  {productionPlanningData?.report_prepared_by}
                </td>

                <td
                  align="left"
                  colSpan={2}
                  style={{ fontWeight: 500, border: "1px solid black" }}
                >
                  Approved By:
                </td>
                <td
                  align="left"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                  colSpan={4}
                >
                  {productionPlanningData?.is_admin_request === "accepted" &&
                    productionPlanningData?.report_approved_by}
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
    </section>
  );
};

export default ViewDailyProductionTable;
