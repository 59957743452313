import React, { useEffect, useState } from "react";
import Styled from "styled-components";
import TableInput from "../../components/Control/TableInput";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import axios from "axios";
const Table = Styled.table`
margin-bottom: 1rem;
width: 100%;
   th{
        font-size: 12px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        text-align: center;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        text-align: center;
    }
`;

const rejQtyWillBeLessThanRowRejectionQty = (
  reason_of_rejection,
  rejection_qty
) => {
  if (!Array.isArray(reason_of_rejection) || reason_of_rejection.length === 0) {
    // Return 0 if the input is not valid
    return 0;
  }
  const totalRejectionQty = reason_of_rejection.reduce(
    (accumulator, currentValue) => {
      console.log(accumulator, currentValue);
      // Check if currentValue has rej_qty property
      if (currentValue.hasOwnProperty("rej_qty")) {
        // Parse rej_qty as integer and add it to accumulator
        return accumulator + parseInt(currentValue.rej_qty || 0);
      } else {
        // If rej_qty property is missing, return accumulator
        return accumulator;
      }
    },
    0
  );
  if (totalRejectionQty > rejection_qty) {
    alert("Failures Qty exceeded");
    return true;
  } else {
    return false;
  }
};
const RejectionTable = ({ process, isView, setDisableClose }) => {
  const [render, setRender] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([]);
  useEffect(() => {
    axios
      .get("/api/getAllDefect")
      .then((res) => {
        if (res?.data) {
          setRejectionReasons(res?.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Table>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Reasons</th>
          <th>Failure Qty</th>
        </tr>
      </thead>
      <tbody>
        {process?.reason_of_rejection?.map((item, index) => (
          <tr>
            <th>{index + 1}</th>
            <td style={{ minWidth: "300px", textAlign: "center" }}>
              {isView ? (
                item.reason
              ) : (
                <FormControl
                  style={{
                    width: "250px",
                    border: "none",
                  }}
                  fullWidth={true}
                  variant="filled"
                  //   classes={{
                  //     root: classes.root,
                  //   }}
                >
                  <InputLabel>Select Failure</InputLabel>
                  <Select
                    defaultValue={item.reason || ""}
                    // classes={{
                    //   // underline: classes.underline,
                    //   root: classes.root,
                    //   select: classes.select,
                    // }}
                    disableUnderline
                    onChange={(e) => {
                      item.reason = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="">None</MenuItem>
                    {rejectionReasons.map((reason) => (
                      <MenuItem value={reason.defect_name}>
                        {reason.defect_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                // <CustomSelect
                //   name="reason"
                //   value={item.reason}
                //   onChange={(e) => {
                //     item.reason = e.target.value;
                //     setRender((prev) => !prev);
                //   }}
                // >
                //   <MenuItem value="">None</MenuItem>
                //   {rejectionReasons.map((reason) => (
                //     <MenuItem value={reason.defect_name}>
                //       {reason.defect_name}
                //     </MenuItem>
                //   ))}
                // </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: "100px", textAlign: "center" }}>
              <TableInput
                isView={isView}
                name={`max_${index}`}
                type="number"
                value={item.rej_qty}
                onChange={(e) => {
                  item.rej_qty = e.target.value;
                  const getBool = rejQtyWillBeLessThanRowRejectionQty(
                    process?.reason_of_rejection,
                    process?.rejection_qty
                  );
                  setDisableClose(getBool);
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={2} align="center">
            Total Failures
          </th>
          <th>{process.rejection_qty}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default RejectionTable;
