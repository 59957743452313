import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import { DatePicker } from "@material-ui/pickers";
import React, { useState } from "react";
import formatDate from "../../../../components/Control/formatDate";

const TBody3 = ({
  data,
  setIsInitialModal,
  handleSubmitAndSave,
  fieldId,
  isView,
}) => {
  const [render, setRender] = useState(false);
  const openModal = async (field, bodyIdx, fieldIndex) => {
    try {
      let res = await handleSubmitAndSave();
      if (res) {
        console.log(res);
        fieldId.current = res.body3[bodyIdx][field][fieldIndex]._id;
        setIsInitialModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <tr>
        <th rowSpan={2}>SR. NO.</th>
        <th colSpan={2} rowSpan={2}>
          CONTROL ITEMS
        </th>
        <th rowSpan={2}>TARGET PLAN</th>
        <th colSpan={10}>M1</th>
        <th colSpan={10}>M2</th>
        <th colSpan={10}>M3</th>
      </tr>
      <tr>
        {Array.from({ length: 3 }, () => (
          <>
            <th colSpan={2}>L1</th>
            <th colSpan={2}>L2</th>
            <th colSpan={2}>L3</th>
            <th colSpan={2}>L4</th>
            <th colSpan={2}>L5</th>
          </>
        ))}
      </tr>
      {data.body3.map((body, index) => (
        <>
          <tr style={{ height: "50px" }}>
            <td rowSpan={2}>{index + 1}</td>
            <td rowSpan={2} colSpan={2}>
              {body.control_item}
            </td>
            <td rowSpan={2}>{body.target_plan}</td>
            {body.m1.map((m1) => (
              <td colSpan={2}>
                {isView ? (
                  formatDate(m1.date)
                ) : (
                  <DatePicker
                    clearable
                    style={{ width: "120px" }}
                    className="date-field"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    onChange={(date) => {
                      m1.date = date;
                      setRender((prev) => !prev);
                    }}
                    value={m1.date || null}
                  />
                )}
              </td>
            ))}
            {body.m2.map((m2) => (
              <td colSpan={2}>
                {isView ? (
                  formatDate(m2.date)
                ) : (
                  <DatePicker
                    clearable
                    style={{ width: "120px" }}
                    className="date-field"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    onChange={(date) => {
                      m2.date = date;
                      setRender((prev) => !prev);
                    }}
                    value={m2.date || null}
                  />
                )}
              </td>
            ))}
            {body.m3.map((m3) => (
              <td colSpan={2}>
                {isView ? (
                  formatDate(m3.date)
                ) : (
                  <DatePicker
                    style={{ width: "120px" }}
                    clearable
                    className="date-field"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    onChange={(date) => {
                      m3.date = date;
                      setRender((prev) => !prev);
                    }}
                    value={m3.date || null}
                  />
                )}
              </td>
            ))}
          </tr>
          <tr style={{ height: "50px" }}>
            {body.m1.map((_, m1Idx) => (
              <td colSpan={2}>
                <IconButton onClick={() => openModal("m1", index, m1Idx)}>
                  <Add />
                </IconButton>
              </td>
            ))}
            {body.m2.map((_, m2Idx) => (
              <td colSpan={2}>
                <IconButton onClick={() => openModal("m2", index, m2Idx)}>
                  <Add />
                </IconButton>
              </td>
            ))}
            {body.m3.map((_, m3Idx) => (
              <td colSpan={2}>
                <IconButton onClick={() => openModal("m3", index, m3Idx)}>
                  <Add />
                </IconButton>
              </td>
            ))}
          </tr>
        </>
      ))}
    </>
  );
};

export default TBody3;
