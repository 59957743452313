import { Button } from "@material-ui/core";
import { CloudUpload, Delete } from "@material-ui/icons";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import { Wrapper } from "../../components/Control/Flexbox";
import TableImagePdfViewer from "./TableImagePdfViewer";

const AllFileViewer = () => {
  const { id } = useParams();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [render, setRender] = useState(false);
  const [files, setFiles] = useState(null);
  const urlObj = location.state;

  useEffect(() => {
    axios
      .get(`/api/${urlObj?.getOne_url}/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id, render]);

  const handleDelete = (id, imageName, imgIndex) => {
    if (window.confirm("Are you sure you want to delete this file ?")) {
      axios
        .delete(`/api/${urlObj.delete_url}/${id}/${imgIndex}`)
        .then((res) => {
          if (res.data) {
            alert(`${imageName} deleted successfully`);
            setRender((prev) => !prev);
          }
        });
    }
  };
  const uploadFiles = async (id) => {
    if (!files) return;

    const formData = new FormData();
    for (const key in files) {
      formData.append("files", files[key]);
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to upload these files?"
    );
    if (!isConfirmed) return;

    if (files) {
      await axios
        .put(`/api/${urlObj.update_url}/${id}`, formData)
        .then((res) => {
          if (res.data) {
            alert("uploaded successfully");
            window.location.reload();
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <div>
      {data && (
        <Wrapper
          justify="space-between"
          style={{
            border: "2px solid #ced4da",
            margin: "1rem",
            padding: "1rem",
          }}
        >
          <input
            id="contained-button-file"
            type="file"
            multiple
            accept="image/png,image/jpeg,image/jpg/,application/pdf"
            onChange={(e) => setFiles(e.target.files)}
            hidden
          />
          <label
            htmlFor="contained-button-file"
            style={{
              marginBottom: "0",
            }}
          >
            <Button component="span">
              <CloudUpload style={{ marginRight: "1rem" }} />
              Upload Picture / Pdf
            </Button>
          </label>

          <Wrapper
            justify="center"
            style={{
              borderLeft: "2px solid #ced4da",
            }}
          >
            <div
              style={{
                paddingLeft: "0.5rem",
              }}
            >
              {files ? `${files[0]?.name}...` : ""}
            </div>
          </Wrapper>
          <Wrapper
            style={{ borderLeft: "2px solid #ced4da" }}
            justify="space-between"
          >
            {files && (
              <>
                <Button
                  component="span"
                  type="button"
                  style={{
                    marginLeft: "0.6rem",
                  }}
                  onClick={() => setFiles(null)}
                  startIcon={<Delete />}
                >
                  REMOVE
                </Button>
                <Button
                  component="span"
                  type="button"
                  style={{
                    marginLeft: "0.6rem",
                  }}
                  onClick={() => uploadFiles(data._id)}
                  startIcon={<CloudUpload />}
                >
                  Upload
                </Button>
              </>
            )}
          </Wrapper>
        </Wrapper>
      )}

      {data && (
        <TableImagePdfViewer
          data={data}
          handleDelete={handleDelete}
          file_backend_name={urlObj.file_backend_name}
        />
      )}
    </div>
  );
};

export default AllFileViewer;
