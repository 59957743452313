import { FeaturedPlayList, MenuBook } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";

import clausePage from "./ClausePage.module.css";

const Abnormality = () => {
    return (
        <>
            <div
                className="divflex"
                style={{ fontSize: "24px", textTransform: "uppercase" }}
            >
                ABNORMALITY MANAGEMENT
            </div>
            <main className={`${clausePage.container} ${clausePage.main}`}>
                <div>
                    <Link to="/abnormalityRequestForm" className="panel">
                        <MenuBook style={{ fontSize: "3rem", color: "#003566" }} />
                    </Link>
                    <p className="card-btn-para">
                        Request Form
                        <span className="card-btn-span">Reports</span>
                    </p>
                </div>
                <div>
                    <Link to="/abnormalityMonitoring" className="panel">
                        <FeaturedPlayList style={{ fontSize: "3rem", color: "#003566" }} />
                    </Link>
                    <p className="card-btn-para">
                        Moniterning <span className="card-btn-span">Reports</span>
                    </p>
                </div>
            </main>
        </>
    );
};

export default Abnormality;
