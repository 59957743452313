import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useHistory } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import formatDate from "../../../components/Control/formatDate";
import { useAuth } from "../../../context/GlobalContext";
import PrintIcon from "@material-ui/icons/Print";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:98%;
  margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    p{
      margin:0;
    }
    span{
      font-weight:500;
    }

`;

function tConv24(time24) {
  if (time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  }
}

function ViewPdirTable() {
  const { id } = useParams();
  const history = useHistory();
  const [pdirData, setPdirData] = useState({});
  const { state } = useAuth();

  console.log(pdirData);
  const DeletePir = (id) => {
    let isDeleted = window.confirm("Do you want to delete this Report?");
    if (isDeleted) {
      axios
        .delete(`/api/delete/pdir/${id}`)
        .then(() => {
          history.goBack();
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  useEffect(() => {
    async function fetchPir() {
      try {
        const res = await axios.get(`/api/get/pdir/${id}`);
        setPdirData(res.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchPir();
    // eslint-disable-next-line
  }, []);
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  const Showcheck2lower = (mean, low) => mean - low;
  const Showcheck2Upper = (mean, upr) => mean + upr;

  return (
    <div style={{ paddingBottom: "4rem" }}>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          PRE-DISPATCH INSPECTION REPORT
        </Typography>
        <div style={{ display: "flex" }}>
          {state.user.userType === 2 && (
            <>
              <Wrapper>
                <Tooltip title="Edit" arrow>
                  <Link to={`/pdir/edit/${pdirData._id}`}>
                    <IconButton
                      size="large"
                      style={{
                        marginRight: "1rem",
                        color: "white",
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <IconButton
                    size="large"
                    style={{
                      marginRight: "1rem",
                      color: "white",
                    }}
                    onClick={() => DeletePir(pdirData._id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Wrapper>
            </>
          )}
          <Wrapper>
            <Tooltip title="Print table" arrow>
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip>
            <Tooltip title="Export as spreadsheet" arrow>
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: `PDIR - ${
                          pdirData.part_id.part_name
                        } - ${formatDate(pdirData.pdir_date)}.xlsx`,
                        sheet: {
                          name: "PDIR Sheet",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </Wrapper>
        </div>
      </div>
      <Table
        cellSpacing="0"
        style={{ marginTop: "0.6rem" }}
        id="table-to-xls"
        data-default-wrap="true"
        data-cols-width="7, 14, 16, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 12, 12, 11, 12"
      >
        <tbody>
          <tr data-height="100">
            <th
              colSpan={17}
              rowSpan="2"
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
                height: "100px",
              }}
            >
              <CenteredLogoImg>
                <img src={images.arcLogo} alt={textInputs.companyName} />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              Doc.No:- ARC/F/QAD/06
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
              Rev.No./Date:00/01-10-17
            </th>
          </tr>
          <tr data-height="40">
            <td
              colSpan={19}
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                border: "1px solid black",
                textTransform: "uppercase",
              }}
              data-fill-color="FF000000"
            >
              PRE-DISPATCH INSPECTION REPORT
            </td>
          </tr>
          <tr data-height="30">
            <th
              align="left"
              colSpan={7}
              rowSpan="3"
              style={{ border: "1px solid black" }}
            >
              CUSTOMER:- <span>{pdirData?.part_id?.customer}</span>
            </th>

            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Invoice No.:- <span> {pdirData.invoice_no}</span>
            </th>

            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              MRIR NO.:- <span> {pdirData.mrir_no}</span>
            </th>
            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Lot NO.:- <span> {pdirData.lot_no}</span>
            </th>
            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Sheet NO.:- <span> {pdirData.sheet_no}</span>
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Invoice Date.:- <span> {formatDate(pdirData.invoice_date)}</span>
            </th>
            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              MRIR Date.:- <span> {formatDate(pdirData.mrir_date)}</span>
            </th>
            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Lot Qty.:- <span> {pdirData.quantity}</span>
            </th>
            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Heat NO.:- <span> {pdirData.heat_no}</span>
            </th>
          </tr>
          <tr>
            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Part No.:- <span> {pdirData?.part_id?.part_name}</span>
            </th>
            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Rev No.:- <span> {pdirData.revision_no}</span>
            </th>
            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Description.:- <span>{pdirData.desc}</span>
            </th>
            <th align="left" colSpan={3} style={{ border: "1px solid black" }}>
              Date.:- <span> {formatDate(pdirData.pdir_date)}</span>
            </th>
          </tr>

          <tr data-height="40">
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
            >
              S.NO
            </th>

            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
            >
              PARAMETER
            </th>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
            >
              specification
            </th>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
            >
              Lower Limit
            </th>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
            >
              Upper Limit
            </th>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
            >
              Inspection Method
            </th>

            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              colSpan={10}
            >
              OBSERVATIONS (IN RANGE)
            </th>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              colSpan={2}
            >
              Cutomer Observation
            </th>
            <th
              style={{ border: "1px solid black", textAlign: "center" }}
              align="center"
              rowSpan={2}
            >
              Judgement (OK/Ng)
            </th>
          </tr>
          <tr data-height="30">
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              1
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              2
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              3
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              4
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              5
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              6
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              7
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              8
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              9
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              10
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              1
            </th>
            <th
              style={{
                width: "100px",
                border: "1px solid black",
                textAlign: "center",
              }}
              align="center"
            >
              2
            </th>
          </tr>
          {pdirData.pdir_list?.map((pdir, index) => (
            <tr key={pdir._id} data-height="35">
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
              >
                {index + 1}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
              >
                {pdir.parameter}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
              >
                {pdir.specification}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
              >
                {pdir.appearance === "check1" && pdir.ltl}
                {pdir.appearance === "check2" &&
                  Showcheck2lower(pdir.mean, pdir.ltolerance)}
                {pdir.appearance === "check4" &&
                  (pdir.check4_symbol === ">=" ? pdir.checkbox4_num : "∞")}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
              >
                {pdir.appearance === "check1" && pdir.utl}
                {pdir.appearance === "check2" &&
                  Showcheck2Upper(pdir.mean, pdir.utolerance)}
                {pdir.appearance === "check4" &&
                  (pdir.check4_symbol === ">=" ? "∞" : pdir.checkbox4_num)}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
              >
                {pdir.methods}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
              >
                {pdir.obs_1}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
              >
                {pdir.obs_2}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
              >
                {pdir.obs_3}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
              >
                {pdir.obs_4}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
              >
                {pdir.obs_5}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
              >
                {pdir.obs_6}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
              >
                {pdir.obs_7}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
              >
                {pdir.obs_8}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
              >
                {pdir.obs_9}
              </td>
              <td
                style={{
                  textTransform: "uppercase",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                align="center"
              >
                {pdir.obs_10}
              </td>

              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
              >
                {1}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
              >
                {2}
              </td>
              <td
                style={{ border: "1px solid black", textAlign: "center" }}
                align="center"
              >
                {pdir.results === "NOT OK" ? "Ng" : "OK"}
              </td>
            </tr>
          ))}
        </tbody>
        <tr style={{ height: "40px" }} data-height="40">
          <td
            align="left"
            colSpan={19}
            style={{ fontWeight: 600, border: "1px solid black" }}
          >
            NOTE: {pdirData.notes}
          </td>
        </tr>

        <tr style={{ height: "40px" }} data-height="40">
          <td
            align="left"
            colSpan={3}
            rowSpan={2}
            style={{ border: "1px solid black" }}
          >
            Inspected By
          </td>
          <td align="left" colSpan={4} style={{ border: "1px solid black" }}>
            Signature
          </td>
          <td
            align="left"
            colSpan={3}
            rowSpan={2}
            style={{ border: "1px solid black" }}
          >
            Approved By
          </td>
          <td align="left" colSpan={4} style={{ border: "1px solid black" }}>
            Signature
          </td>
          <td
            align="left"
            colSpan={3}
            rowSpan={2}
            style={{ border: "1px solid black" }}
          >
            Final Status
          </td>
          <td
            align="left"
            colSpan={2}
            style={{ border: "1px solid black" }}
          ></td>
        </tr>
        <tr style={{ height: "40px" }} data-height="40">
          <td align="left" colSpan={4} style={{ border: "1px solid black" }}>
            Name
          </td>
          <td align="left" colSpan={4} style={{ border: "1px solid black" }}>
            Name
          </td>
          <td
            align="left"
            colSpan={2}
            style={{ border: "1px solid black" }}
          ></td>
        </tr>
      </Table>
    </div>
  );
}

export default ViewPdirTable;
