import React from "react";
import Container from "./container";
import GlobalContext from "./context/GlobalContext";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const theme = createTheme({
  palette: {
    primary: {
      main: "#c80202",
    },
    secondary: {
      main: "#054a29",
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ff9800",
    },
  },
});
function index() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalContext>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Container />
        </MuiPickersUtilsProvider>
      </GlobalContext>
    </ThemeProvider>
  );
}

export default index;
