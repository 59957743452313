import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Wrapper } from "../../../../components/Control/Flexbox";
import { CloseButton } from "../../../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../../../components/Control/useConfirm";
import {
  ButtonStyled,
  TableContainerStyled,
} from "../../../DailyProduction/styles";
import formatDate from "../../../../components/Control/formatDate";
import lodash from "lodash";
import { useQuery } from "../../../../components/Control/useQuery";
import { internalAudit } from "../CheckListData";
import RemaingingDepartment from "./RemaingingDepartment";
import CSR from "./CSR";

const departmentTypeData = lodash.cloneDeep(internalAudit);
// const departmentTypeData = lodash.cloneDeep(internalAudit);

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const AddEditView = () => {
  const classes = dropdownStyles();
  const { auditId, id } = useParams();
  const [render, setRender] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const shift = query.get("shift");
  const auditDate = query.get("auditDate");
  const [auditorValue, setAuditorValue] = useState([]);
  const [auditeeValue, setAudieeValue] = useState([]);
  // const supplier = query.get("supplier");
  const departmentType = query.get("departmentType");
  // console.log(departmentType);
  const [checklistData, setChecklistdata] = useState({
    // processs: new Array(6).fill().map((_) => []),
    department_name: departmentType,
    checklist_data: {
      [departmentType]: lodash.cloneDeep(departmentTypeData[departmentType]),
    },
    isCompleted: false,
  });
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to Submit?"
  );

  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/getOneInternalAuditChecklist/${id}`)
      .then((res) => {
        const auditordata = {
          employee_name: res.data?.auditor?.employee_name,
          _id: res.data?.auditor?._id,
        };
        setAuditorValue(auditordata);
        const auditeeData = {
          employee_name: res.data?.auditee?.employee_name,
          _id: res.data?.auditee?._id,
        };
        setAudieeValue(auditeeData);
        setChecklistdata(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmUpdate();
      if (ans) {
        checklistData.isSubmitted = bool;
        axios
          .put(`/api/updateInternalAuditChecklist/${id}`, checklistData)
          .then((res) => {
            if (res.data.status === "success") {
              alert("updated successfully");
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        checklistData.isSubmitted = bool;
        axios
          .post(`/api/createInternalAuditChecklist/${auditId}`, checklistData)
          .then((res) => {
            if (res.data.status === "success") {
              alert("created successfully");
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 80;
    } else {
      document.getElementById("machinetable").scrollLeft -= 80;
    }
  };
  const handleChangeChecklistAudit = (e, departmentType, index) => {
    const { name, value } = e.target;
    checklistData.checklist_data[departmentType][index][name] = value;
    setRender((prev) => !prev);
  };

  const handleAllC_NCValue = (checklistData) => {
    // console.log("call");
    const isC = checklistData?.checklist_data[departmentType].every(
      (item) => item?.status_c_nc === "c"
    );

    return isC;
  };

  return (
    <section>
      <div
        style={{
          paddingBottom: "4rem",
        }}
      >
        <ButtonStyled
          left
          onClick={(e) => {
            handleScroll("left");
          }}
        >
          <div>
            <ArrowBackIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <ButtonStyled
          right
          onClick={(e) => {
            handleScroll("right");
          }}
        >
          <div>
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
              textTransform: "uppercase",
            }}
          >
            Internal Audit Check Sheet
          </Typography>
          {!isView && (
            <div style={{ display: "flex" }}>
              <Wrapper
                style={{
                  gap: "1rem",
                }}
              >
                <FormControlLabel
                  style={{
                    border: "1px solid black",
                    marginTop: "0.5rem",
                    width: "7rem",
                    backgroundColor: "#064a29",
                  }}
                  control={
                    <Checkbox
                      checked={checklistData?.isCompleted}
                      // key="isCompleted"
                      name="isCompleted"
                      onChange={(e) => {
                        setChecklistdata((prev) => ({
                          ...prev,
                          isCompleted: e.target.checked,
                        }));
                      }}
                      disabled={
                        departmentType === "None"
                          ? true
                          : !handleAllC_NCValue(checklistData)
                      }
                      color="primary"
                    />
                  }
                  label="Done"
                />
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#064a29",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    handleSubmitAndSave(true);
                  }}
                >
                  Save
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            </div>
          )}
        </div>
        {/* {renderDepartmentTypeTable(departmentType)} */}
        {departmentType === "CSR" ? (
          <CSR
            {...{
              isView,
              checklistData,
              setChecklistdata,
              handleChangeChecklistAudit,
              setRender,
              departmentType,
              auditorValue,
              setAuditorValue,
              auditeeValue,
              setAudieeValue,
              shift,
              auditDate,
            }}
          />
        ) : (
          <RemaingingDepartment
            {...{
              isView,
              checklistData,
              setChecklistdata,
              handleChangeChecklistAudit,
              setRender,
              departmentType,
              auditorValue,
              setAuditorValue,
              auditeeValue,
              setAudieeValue,
              shift,
              auditDate,
            }}
          />
        )}
      </div>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </section>
  );
};

export default AddEditView;
