import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
const Observation = ({
  index,
  pdir,
  handleChangePdirList,
  handleChangePdirText,
}) => {
  return (
    <>
      <Wrapper>
        {(pdir.appearance === "check1" ||
          pdir.appearance === "check2" ||
          pdir.appearance === "check4") && (
          <Input
            name="obs_1"
            label="1"
            type="number"
            variant="filled"
            inputProps={{
              step: 0.0001,
              style: {
                borderColor: pdir.obs_1_color,
                backgroundColor: pdir.obs_1_color,
              },
            }}
            onChange={(e) =>
              handleChangePdirList(
                e,
                index,
                pdir.mean,
                pdir.utolerance,
                pdir.ltolerance,
                pdir.utl,
                pdir.ltl,
                pdir?.check4_symbol,
                pdir?.checkbox4_num
              )
            }
            defaultValue={pdir.obs_1}
          />
        )}
        {pdir.appearance === "check3" && (
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>1</InputLabel>
            <Select
              name="obs_1"
              defaultValue={pdir.obs_1}
              onChange={(e) => handleChangePdirText(e, index)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="not ok">NOT OK</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* second check */}
        {(pdir.appearance === "check1" ||
          pdir.appearance === "check2" ||
          pdir.appearance === "check4") && (
          <Input
            name="obs_2"
            label="2"
            type="number"
            variant="filled"
            inputProps={{
              step: 0.0001,
              style: {
                borderColor: pdir.obs_2_color,
                backgroundColor: pdir.obs_2_color,
              },
            }}
            onChange={(e) =>
              handleChangePdirList(
                e,
                index,
                pdir.mean,
                pdir.utolerance,
                pdir.ltolerance,
                pdir.utl,
                pdir.ltl,
                pdir?.check4_symbol,
                pdir?.checkbox4_num
              )
            }
            defaultValue={pdir.obs_2}
          />
        )}
        {pdir.appearance === "check3" && (
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>2</InputLabel>
            <Select
              name="obs_2"
              defaultValue={pdir.obs_2}
              onChange={(e) => handleChangePdirText(e, index)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="not ok">NOT OK</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* Third Check */}
        {(pdir.appearance === "check1" ||
          pdir.appearance === "check2" ||
          pdir.appearance === "check4") && (
          <Input
            name="obs_3"
            label="3"
            type="number"
            variant="filled"
            inputProps={{
              step: 0.0001,
              style: {
                borderColor: pdir.obs_3_color,
                backgroundColor: pdir.obs_3_color,
              },
            }}
            onChange={(e) =>
              handleChangePdirList(
                e,
                index,
                pdir.mean,
                pdir.utolerance,
                pdir.ltolerance,
                pdir.utl,
                pdir.ltl,
                pdir?.check4_symbol,
                pdir?.checkbox4_num
              )
            }
            defaultValue={pdir.obs_3}
          />
        )}
        {pdir.appearance === "check3" && (
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>3</InputLabel>
            <Select
              name="obs_3"
              defaultValue={pdir.obs_3}
              onChange={(e) => handleChangePdirText(e, index)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="not ok">NOT OK</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* Fourth Check */}
        {(pdir.appearance === "check1" ||
          pdir.appearance === "check2" ||
          pdir.appearance === "check4") && (
          <Input
            name="obs_4"
            label="4"
            type="number"
            variant="filled"
            inputProps={{
              step: 0.0001,
              style: {
                borderColor: pdir.obs_4_color,
                backgroundColor: pdir.obs_4_color,
              },
            }}
            onChange={(e) =>
              handleChangePdirList(
                e,
                index,
                pdir.mean,
                pdir.utolerance,
                pdir.ltolerance,
                pdir.utl,
                pdir.ltl,
                pdir?.check4_symbol,
                pdir?.checkbox4_num
              )
            }
            defaultValue={pdir.obs_4}
          />
        )}
        {pdir.appearance === "check3" && (
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>4</InputLabel>
            <Select
              name="obs_4"
              defaultValue={pdir.obs_4}
              onChange={(e) => handleChangePdirText(e, index)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="not ok">NOT OK</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* Fifth Check */}
        {(pdir.appearance === "check1" ||
          pdir.appearance === "check2" ||
          pdir.appearance === "check4") && (
          <Input
            name="obs_5"
            label="5"
            type="number"
            variant="filled"
            inputProps={{
              step: 0.0001,
              style: {
                borderColor: pdir.obs_5_color,
                backgroundColor: pdir.obs_5_color,
              },
            }}
            onChange={(e) =>
              handleChangePdirList(
                e,
                index,
                pdir.mean,
                pdir.utolerance,
                pdir.ltolerance,
                pdir.utl,
                pdir.ltl,
                pdir?.check4_symbol,
                pdir?.checkbox4_num
              )
            }
            defaultValue={pdir.obs_5}
          />
        )}
        {pdir.appearance === "check3" && (
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>5</InputLabel>
            <Select
              name="obs_5"
              defaultValue={pdir.obs_5}
              onChange={(e) => handleChangePdirText(e, index)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="not ok">NOT OK</MenuItem>
            </Select>
          </FormControl>
        )}
      </Wrapper>
      <Wrapper>
        {(pdir.appearance === "check1" ||
          pdir.appearance === "check2" ||
          pdir.appearance === "check4") && (
          <Input
            name="obs_6"
            label="6"
            type="number"
            variant="filled"
            inputProps={{
              step: 0.0001,
              style: {
                borderColor: pdir.obs_6_color,
                backgroundColor: pdir.obs_6_color,
              },
            }}
            onChange={(e) =>
              handleChangePdirList(
                e,
                index,
                pdir.mean,
                pdir.utolerance,
                pdir.ltolerance,
                pdir.utl,
                pdir.ltl,
                pdir?.check4_symbol,
                pdir?.checkbox4_num
              )
            }
            defaultValue={pdir.obs_6}
          />
        )}
        {pdir.appearance === "check3" && (
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>6</InputLabel>
            <Select
              name="obs_6"
              defaultValue={pdir.obs_6}
              onChange={(e) => handleChangePdirText(e, index)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="not ok">NOT OK</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* second check */}
        {(pdir.appearance === "check1" ||
          pdir.appearance === "check2" ||
          pdir.appearance === "check4") && (
          <Input
            name="obs_7"
            label="7"
            type="number"
            variant="filled"
            inputProps={{
              step: 0.0001,
              style: {
                borderColor: pdir.obs_7_color,
                backgroundColor: pdir.obs_7_color,
              },
            }}
            onChange={(e) =>
              handleChangePdirList(
                e,
                index,
                pdir.mean,
                pdir.utolerance,
                pdir.ltolerance,
                pdir.utl,
                pdir.ltl,
                pdir?.check4_symbol,
                pdir?.checkbox4_num
              )
            }
            defaultValue={pdir.obs_7}
          />
        )}
        {pdir.appearance === "check3" && (
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>7</InputLabel>
            <Select
              name="obs_7"
              defaultValue={pdir.obs_7}
              onChange={(e) => handleChangePdirText(e, index)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="not ok">NOT OK</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* Third Check */}
        {(pdir.appearance === "check1" ||
          pdir.appearance === "check2" ||
          pdir.appearance === "check4") && (
          <Input
            name="obs_8"
            label="8"
            type="number"
            variant="filled"
            inputProps={{
              step: 0.0001,
              style: {
                borderColor: pdir.obs_8_color,
                backgroundColor: pdir.obs_8_color,
              },
            }}
            onChange={(e) =>
              handleChangePdirList(
                e,
                index,
                pdir.mean,
                pdir.utolerance,
                pdir.ltolerance,
                pdir.utl,
                pdir.ltl,
                pdir?.check4_symbol,
                pdir?.checkbox4_num
              )
            }
            defaultValue={pdir.obs_8}
          />
        )}
        {pdir.appearance === "check3" && (
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>8</InputLabel>
            <Select
              name="obs_8"
              defaultValue={pdir.obs_8}
              onChange={(e) => handleChangePdirText(e, index)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="not ok">NOT OK</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* Fourth Check */}
        {(pdir.appearance === "check1" ||
          pdir.appearance === "check2" ||
          pdir.appearance === "check4") && (
          <Input
            name="obs_9"
            label="9"
            type="number"
            variant="filled"
            inputProps={{
              step: 0.0001,
              style: {
                borderColor: pdir.obs_9_color,
                backgroundColor: pdir.obs_9_color,
              },
            }}
            onChange={(e) =>
              handleChangePdirList(
                e,
                index,
                pdir.mean,
                pdir.utolerance,
                pdir.ltolerance,
                pdir.utl,
                pdir.ltl,
                pdir?.check4_symbol,
                pdir?.checkbox4_num
              )
            }
            defaultValue={pdir.obs_9}
          />
        )}
        {pdir.appearance === "check3" && (
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>9</InputLabel>
            <Select
              name="obs_9"
              defaultValue={pdir.obs_9}
              onChange={(e) => handleChangePdirText(e, index)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="not ok">NOT OK</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* Fifth Check */}
        {(pdir.appearance === "check1" ||
          pdir.appearance === "check2" ||
          pdir.appearance === "check4") && (
          <Input
            name="obs_10"
            label="10"
            type="number"
            variant="filled"
            inputProps={{
              step: 0.0001,
              style: {
                borderColor: pdir.obs_10_color,
                backgroundColor: pdir.obs_10_color,
              },
            }}
            onChange={(e) =>
              handleChangePdirList(
                e,
                index,
                pdir.mean,
                pdir.utolerance,
                pdir.ltolerance,
                pdir.utl,
                pdir.ltl,
                pdir?.check4_symbol,
                pdir?.checkbox4_num
              )
            }
            defaultValue={pdir.obs_10}
          />
        )}
        {pdir.appearance === "check3" && (
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
          >
            <InputLabel>10</InputLabel>
            <Select
              name="obs_10"
              defaultValue={pdir.obs_10}
              onChange={(e) => handleChangePdirText(e, index)}
            >
              <MenuItem>None</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="not ok">NOT OK</MenuItem>
            </Select>
          </FormControl>
        )}
      </Wrapper>
    </>
  );
};

export default Observation;
