import styled from "styled-components";

export const ContainerForNewMui = styled.html`
  font-size: 62.5%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  font-family: "Roboto", "Varela", sans-serif;
  line-height: 1;
  font-weight: 500;
  color: #212529;
`;
