import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";

import Modal from "../../../components/Control/Modal";
import AddEditCustomerModal from "./AddEditCustomerModal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../../components/Control/useDebounce";
import useConfirm from "../../../components/Control/useConfirm";
import { useAuth } from "../../../context/GlobalContext";

const SupplierLists = () => {
  const [data, setData] = useState([]);
  const { state } = useAuth();
  const [isNewCustomerModal, setIsNewCustomerModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("createdAt");
  const [customerId, setCustomerId] = useState(null);

  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this supplier ?"
  );

  const fetchData = async () => {
    axios
      .get(
        `/api/getCustomer/all?page=${page}&sortBy=${filter}&search=${search}&perPage=10`
      )
      .then((res) => {
        setData(res.data?.results);
        setPerPage(res.data?.perPage);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    filter,
    // duplicateOpenModal,
    isNewCustomerModal,
  ]);

  async function deleteCustomer(id) {
    const ans = await confirmDelete();
    if (ans) {
      await axios
        .delete(`/api/delete_customer/${id}`)
        .then((res) => {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          CUSTOMER LIST
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setCustomerId(null);
                setIsNewCustomerModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New CUSTOMER
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "0.8" }}>
              <Input
                placeholder="Search Customer Name"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="part_type"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem value="createdAt">A - Z</MenuItem>
                <MenuItem value="customer_name">Customer Name</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                {[
                  "S.No.",
                  "Customer Name",
                  "Item Supplied",
                  "Address",
                  "Contact Person",
                  "Contact Number",
                  "Action",
                ].map((item, index) => (
                  <TableCell
                    key={index}
                    style={{ fontWeight: "bold" }}
                    align="center"
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center">
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell align="center">{item.customer_name}</TableCell>
                      <TableCell align="center">{item.item_supplied}</TableCell>
                      <TableCell align="center">{item.address}</TableCell>
                      <TableCell align="center">
                        {item.contact_person}
                      </TableCell>
                      <TableCell align="center">
                        {item.contact_number}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "150px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "150px",
                            width: "150px",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="Edit Customer" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: ".5rem",
                              }}
                              onClick={() => {
                                setCustomerId(item._id);
                                setIsNewCustomerModal(true);
                              }}
                            >
                              <EditIcon
                                style={{ fontSize: "1.4rem", color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Delete Machine" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: "0.8rem",
                              }}
                              onClick={() => {
                                deleteCustomer(item._id);
                              }}
                            >
                              <DeleteIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#c80202",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <Modal
          title={customerId ? "update supplier" : "create new supplier"}
          openPopup={isNewCustomerModal}
          closeModal={() => {
            setIsNewCustomerModal(false);
            setCustomerId(null);
          }}
          backgroundColor="white"
        >
          <AddEditCustomerModal
            id={customerId}
            {...{ setIsNewCustomerModal }}
          />
        </Modal>
      </main>
      <DialogDelete />
    </>
  );
};

export default SupplierLists;
