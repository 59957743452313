import React, { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useAuth } from "../../context/GlobalContext";
import "../../style/controlPlan.css";
import Loader from "../Control/Loader";
import ControlPlanTableSheet from "../../Pages/AddEditDuplicatePart/quickView/partPlanQuickView/ControlPlanTableSheet";
import axios from "axios";

export default function ControlPlan(props) {
  const { partId, id } = useParams();
  const { location } = props;
  const [data, setData] = useState("");
  const { state } = useAuth();
  useEffect(() => {
    axios
      .get(`/api/get/controlPlanPart/${partId}/${id}`)
      .then((res) => setData(res.data))
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        {data ? (
          <ControlPlanTableSheet Data={data} controlPlanId={id} />
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
}
