import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import styled from "styled-components";

const Div = styled.div`
  flex: 0.6;
  border: 1px solid black;
  p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    letter-spacing: 1.6px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #1d1d1d;
    color: white;
  }
`;

const calculateProcessEfficiency = (processRejection) => {
  const processEfficiency = [];
  const processLabels = [];
  for (let key in processRejection) {
    const totalProduced = processRejection[key].producedQty;
    const finalProudced = totalProduced - processRejection[key].rejected;
    const efficiencyPercentage = Number(
      ((finalProudced * 100) / totalProduced).toFixed(2)
    );
    processLabels.push(key);
    processEfficiency.push(efficiencyPercentage);
  }
  return { processEfficiency, processLabels };
};

const ProcessWiseBarGraph = ({ processRejection }) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  function generateDefectGraph(processEfficiency, processLabels) {
    setOptions({
      chart: {
        type: "bar",
      },

      // title: { text: "PROCESS EFFICIENCY %", align: "left" },
      fill: {
        colors: ["#064a29", "#064a29", "#064a29"],
      },

      xaxis: {
        categories: processLabels,

        labels: {
          show: true,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },

        tooltip: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "top",
      },

      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
        },
      },
    });
    setSeries([
      {
        name: "Process Effieciency",
        data: processEfficiency,
      },
    ]);
  }

  useEffect(() => {
    const { processEfficiency, processLabels } =
      calculateProcessEfficiency(processRejection);
    generateDefectGraph(processEfficiency, processLabels);
  }, [processRejection]);

  return (
    <Div>
      <p>PROCESS EFFICIENCY %</p>
      <Chart options={options} series={series} height={300} type="bar" />
    </Div>
  );
};

export default ProcessWiseBarGraph;
