import React, { useRef, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search, Visibility } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import { Add } from "@material-ui/icons";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import ClearIcon from "@material-ui/icons/Clear";
import UpdateIcon from "@material-ui/icons/Update";
import AddIcon from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useParams, Link } from "react-router-dom";

import Input from "../../components/Control/Input";
import { Wrapper } from "../../components/Control/Flexbox";
import Modal from "../../components/Control/Modal";
import ComplainListInputsModal from "./ComplainListInputsModal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
import useConfirm from "../../components/Control/useConfirm";
import { useAuth } from "../../context/GlobalContext";
import formatDate from "../../components/Control/formatDate";
import DoneIcon from "@material-ui/icons/Done";
import UploadFileModal from "./UploadFileModal";
import { DatePicker } from "@material-ui/pickers";

const statusObj = {
  open: 1,
  close: 2,
};

const CreateEditCustomerComaplianTrackerList = () => {
  const { state } = useAuth();
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("createdAt");
  const [isNewCustomerComplaint, setIsNewCustomerComplaint] = useState(false);
  const [isUploadFileModal, setUploadFileModal] = useState(false);
  const [isModalCloseBtn, setModalCloseBtn] = useState(false);
  const [isOnlyViewModal, setIsOnlyViewModal] = useState(false);
  const [complaintId, setComplaintId] = useState(null);
  const [dropDownRender, setDropDownRender] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "UPDATE STATUS",
    "Are you sure you want to update this?",
  );
  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this supplier ?",
  );
  const fetchData = async () => {
    axios
      .get(
        `/api/getCustomerComplaintTracker/all/${selectedDate}?page=${page}&sortBy=${filter}&search=${search}&perPage=10`,
      )
      .then((res) => {
        setData(res.data?.results);
        setPerPage(res.data?.perPage);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    filter,
    isNewCustomerComplaint,
    isUploadFileModal,
    selectedDate,
  ]);

  const updateRequestForm = async (id, index) => {
    if (data[index]?.complaint_list_form_file.length < 1) {
      alert("Cant perform update please upload Evidence file first!");
      return;
    }
    let isConfirm = await confirmUpdate();
    if (isConfirm) {
      axios
        .patch(`/api/customerComplaintTracker/updateStatus/${id}`, {
          isAdminRequestNo: data[index].isAdminRequestNo,
        })
        .then((res) => {
          if (res.data.status === "success") {
            alert("Status Change successfully");
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  async function deleteCustomer(id) {
    const ans = await confirmDelete();
    if (ans) {
      await axios
        .delete(`/api/deleteCustomerComplaintTracker/${id}`)
        .then((res) => {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }

  const showStausIcons = (item) => {
    if (item?.status === "open") {
      return (
        <Tooltip title="Open" arrow>
          <IconButton size="small">
            <AccessTimeIcon style={{ fontSize: "1.4rem", color: "#e03131" }} />
          </IconButton>
        </Tooltip>
      );
    }

    if (item?.status === "close") {
      return (
        <Tooltip title="Close" arrow>
          <IconButton size="small">
            <DoneIcon style={{ fontSize: "1.4rem", color: "#2f9e44" }} />
          </IconButton>
        </Tooltip>
      );
    }
  };
  const handleChangeRequestStatus = (e, item) => {
    item.status = null;
    item.isAdminRequestNo = e.target.value;
    const value = data;

    setDropDownRender((prev) => !prev);
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          CUSTOMER COMPLAINT TRACKER
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setComplaintId(null);
                setIsNewCustomerComplaint(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Entry
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ display: "flex", margin: "1rem" }}>
              <DatePicker
                views={["month", "year"]}
                label="Month & Year"
                className="date-field"
                inputVariant="filled"
                value={selectedDate || null}
                onChange={setSelectedDate}
              />
            </div>
            <div style={{ flex: "0.8" }}>
              <Input
                placeholder="Search By Problem/Defect, Part, Customer"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(1);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="part_type"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem value="createdAt">Most Recent First</MenuItem>
                <MenuItem value="createdAtOld">Oldest First</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                {[
                  "S.No.",
                  "Date Of Complaint",
                  "Customer Name",
                  "Part Name/no.",
                  "Problem/Defect",
                  "Rej. Qty.",
                  "Root Cause",
                  "Upload Files",
                  "Status",
                  "Action",
                ].map((item, index) => (
                  <TableCell
                    key={index}
                    style={{ fontWeight: "bold" }}
                    align="center"
                  >
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center">
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell align="center">
                        {formatDate(item.date_of_complaint)}
                      </TableCell>
                      <TableCell align="center">
                        {item?.customer_id?.customer_name}
                      </TableCell>
                      <TableCell align="center">
                        {" "}
                        {item?.part_id?.part_number}
                      </TableCell>
                      <TableCell align="center">
                        {item.problem_Defect}
                      </TableCell>
                      <TableCell align="center">{item.rej_qty}</TableCell>
                      <TableCell align="center">{item.root_cause}</TableCell>
                      <TableCell align="center">
                        {item.complaint_list_form_file.length > 0 ? (
                          <Tooltip title="Upload Form" arrow>
                            <Link to={`/complaintFileList/${item._id}`}>
                              <IconButton size="small">
                                <VisibilityIcon
                                  style={{
                                    fontSize: "1.2rem",
                                    color: "#1864ab",
                                  }}
                                />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Upload Form" arrow>
                            <IconButton
                              size="small"
                              onClick={() => {
                                setComplaintId(item._id);
                                setUploadFileModal(true);
                              }}
                            >
                              <AddIcon
                                style={{
                                  fontSize: "1.5rem",
                                  color: "#c92a2a",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {showStausIcons(item)}
                        <select
                          style={{
                            fontSize: "0.9rem",
                            outline: "none",
                            border: "none",
                            padding: "5px",
                            marginLeft: "0.5rem",
                            width: "150px",
                            textAlign: "center",
                            textTransform: "capitalize",
                            ...(item?.status === "open" && {
                              backgroundColor: "#e03131",
                              color: "#fff",
                            }),
                            ...(item?.status === "close" && {
                              backgroundColor: "#2b8a3e",
                              color: "#fff",
                            }),
                          }}
                          name="status"
                          value={
                            statusObj[item?.status] || item?.isAdminRequestNo
                          }
                          onChange={(e) => handleChangeRequestStatus(e, item)}
                        >
                          <option value="1">OPEN</option>
                          <option value="2">CLOSED</option>
                        </select>
                      </TableCell>
                      {/* actions */}
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "150px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "250px",
                            width: "220px",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip
                            title="View Customer Compalint Details"
                            arrow
                          >
                            <IconButton
                              size="small"
                              style={{
                                marginRight: ".5rem",
                              }}
                              onClick={() => {
                                setComplaintId(item._id);
                                setIsOnlyViewModal(true);
                                setIsNewCustomerComplaint(true);
                              }}
                            >
                              <Visibility
                                style={{ fontSize: "1.4rem", color: "#013a70" }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Edit Compliant" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: ".5rem",
                              }}
                              onClick={() => {
                                setComplaintId(item._id);
                                setIsNewCustomerComplaint(true);
                              }}
                            >
                              <EditIcon
                                style={{ fontSize: "1.4rem", color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Delete Complaint" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: "0.8rem",
                              }}
                              onClick={() => {
                                deleteCustomer(item._id);
                              }}
                            >
                              <DeleteIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#c80202",
                                }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Button
                            variant="outlined"
                            color="white"
                            style={{
                              color: "#fafafa",
                              backgroundColor: "#161A1D",
                            }}
                            onClick={(e) => {
                              updateRequestForm(item._id, index);
                            }}
                            startIcon={
                              <UpdateIcon style={{ fontSize: "1.4rem" }} />
                            }
                          >
                            Update
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <Modal
          title={complaintId ? "update Complaint" : "create new complaint"}
          openPopup={isNewCustomerComplaint}
          isModalCloseBtn={isModalCloseBtn}
          closeModal={() => {
            setIsNewCustomerComplaint(false);
            setIsOnlyViewModal(false);
            setComplaintId(null);
          }}
          backgroundColor="white"
        >
          <ComplainListInputsModal
            id={complaintId}
            {...{
              setIsNewCustomerComplaint,
              setModalCloseBtn,
              isOnlyViewModal,
            }}
          />
        </Modal>

        <Modal
          title={"upload"}
          openPopup={isUploadFileModal}
          closeModal={() => {
            setUploadFileModal(false);
            setComplaintId(null);
          }}
          backgroundColor="white"
        >
          <UploadFileModal
            {...{ setUploadFileModal, setComplaintId }}
            id={complaintId}
          />
        </Modal>
      </main>
      <DialogDelete />
      <DialogUpdate />
    </>
  );
};

export default CreateEditCustomerComaplianTrackerList;
