import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import { Link, useParams } from "react-router-dom";
import Input from "../../components/Control/Input";
import { Add, Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../components/Control/useDebounce";
import { Wrapper } from "../../components/Control/Flexbox";
import formatDate from "../../components/Control/formatDate";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { useAuth } from "../../context/GlobalContext";
const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});
const SupervisorPartTable = () => {
  const classes = useStyles();
  const [partData, setPartData] = useState([]);
  const [page, setPage] = useState(1);
  const { featureName } = useParams();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("");
  const [perPage, setPerPage] = useState();
  const { state } = useAuth();

  // for setting filter label
  const [customerFilterList, setCustomerFilterList] = useState([]);

  useEffect(() => {
    axios.get(`/api/getCustomer/all`).then((res) => {
      setCustomerFilterList(res.data?.results);
      setFilter(res.data?.results[0]?._id);
    });
  }, []);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/get/part/publish?page=${page}&sortBy=${encodeURIComponent(
          filter
        )}&search=${search}&customerFilterList=yes`,
        {
          headers: { isFullAdmin: state?.user?.isFullAdmin },
        }
      )
      .catch((err) => {
        console.log(err);
      });
    setPartData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };
  useDebounce(fetchData, 500, [page, search, filter]);

  return (
    <TableContainer>
      <Wrapper>
        <div style={{ flex: "0.8" }}>
          <Input
            placeholder="Search Part Number"
            variant="filled"
            style={{
              width: "96%",
              marginLeft: "1.2rem",
              marginTop: "1rem",
            }}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <FormControl
          variant="filled"
          className="select-field"
          style={{
            marginRight: "1.5rem",
            marginTop: "0.8rem",
            flex: "0.2",
            textTransform: "uppercase",
          }}
        >
          <InputLabel style={{ textTransform: "capitalize" }}>
            Select Customer
          </InputLabel>
          <Select
            name="customer"
            onChange={(e) => {
              // setting page no. before setting filter value
              setPage(1);
              setFilter(e.target.value);
            }}
            value={filter}
          >
            {customerFilterList.map((customer, index) => (
              <MenuItem
                value={customer._id}
                key={index + 200.0202}
                style={{ textTransform: "uppercase" }}
              >
                {customer?.customer_name}
              </MenuItem>
            ))}
            <MenuItem style={{ textTransform: "uppercase" }} value="other">
              other
            </MenuItem>
          </Select>
        </FormControl>
      </Wrapper>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Part Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Part Description
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Supplier
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Created At
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {partData && (
          <TableBody>
            {partData.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">
                    {perPage * (page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.part_number}</TableCell>
                  <TableCell align="center">{item.part_name}</TableCell>
                  <TableCell align="center">{item.supplier}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {featureName === "4m" && (
                      <Tooltip title="Create" arrow>
                        <Link
                          to={`/create/request_form/${item._id}?partnumber=${item.part_number}`}
                        >
                          <IconButton size="small">
                            <Add
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}
                    {featureName === "controlplan" && (
                      <Tooltip title="View" arrow>
                        <Link
                          to={`/revisedTable/controlplan/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
                        >
                          <IconButton size="small">
                            <VisibilityIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}
                    {featureName === "msa" && (
                      <Tooltip title="View" arrow>
                        <Link
                          to={`/view/msaTable/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
                        >
                          <IconButton size="small">
                            <VisibilityIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}

                    {featureName === "inspection" && (
                      <Tooltip title="View" arrow>
                        <Link
                          to={`/view/incomingTable/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
                        >
                          <IconButton size="small">
                            <VisibilityIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}

                    {featureName === "pcd" && (
                      <Tooltip title="View" arrow>
                        <Link
                          to={`/pcd/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
                        >
                          <IconButton size="small">
                            <VisibilityIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}
                    {featureName === "customerDrawing" && (
                      <Tooltip title="View" arrow>
                        <Link
                          to={`/customerDrawing/table/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
                        >
                          <IconButton size="small">
                            <VisibilityIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}
                    {featureName === "pdir" && (
                      <Tooltip title="View" arrow>
                        <Link
                          to={`/viewpdir/monthly/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
                        >
                          <IconButton size="small">
                            <VisibilityIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    )}
                    {featureName === "pir" && (
                      <>
                        <Tooltip title="View" arrow>
                          <Link
                            to={`/supervisor/pir/${item._id}?partNumber=${item?.part_number}&partName=${item?.part_name}`}
                          >
                            <IconButton size="small">
                              <VisibilityIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="PIR in new tab" arrow>
                          <Link
                            to={`/supervisor/pir/${item._id}}?partnumber=${item.part_number}`}
                            target="_blank"
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <OpenInNewIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#c80202",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "4rem",
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, number) => setPage(number)}
        />
      </div>
    </TableContainer>
  );
};

export default SupervisorPartTable;
