import { Box } from "@mui/system";
import axios from "axios";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { SubmitButton } from "../../../common/CustomButtons";
import { useQuery } from "../../../components/Control/useQuery";
import ChildHeader from "../../../common/ChildHeader";
import TController from "./components/create/TController";
const heading = {
  setup: "SETTING APPROVAL RECORDS",
  retro: "RETROACTIVE CHECK INSPECTION RECORDS",
  suspected: "SUSPECTED REPORT",
};
const Create = () => {
  const history = useHistory();
  const { id } = useParams();
  const query = useQuery();
  const documentType = query.get("documentType");
  const rowId = query.get("rowId");
  const annual_month = query.get("annual_date");
  const isView = query.get("isView") === "true" ? true : false;
  const [operation, setOperation] = useState([]);
  const [moduleData, setModuleData] = useState({});

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/holdCardRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  const onSubmit = async () => {
    if (id !== "undefined") {
      await axios
        .put(`/api/holdCardRoute/${id}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert("updated successfully ");
            history.goBack();
          }
        })
        .catch((err) => console.log(err));
    } else {
      // moduleData.annual_month = annual_month;
      moduleData.summary_row_id = rowId;
      // moduleData.document_type = documentType;
      await axios
        .post(
          `/api/holdCardRoute/${rowId}/${annual_month}`, // ?documentType=${documentType}
          moduleData,
        )
        .then((res) => {
          alert("created successfully");
          history.goBack();
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <Box>
      <Box>
        <ChildHeader text={heading[documentType]}>
          {!isView && (
            <>
              <SubmitButton
                label={moduleData._id ? `UPDATE` : `SUBMIT`}
                onClick={() => onSubmit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          // getProcess,
          operation,
          isView,
        }}
      />
    </Box>
  );
};

export default Create;
