import { DatePicker } from "@material-ui/pickers";
import React, { useState } from "react";
import formatDate from "../../../../components/Control/formatDate";
import { images, textInputs } from "../../../../constants/constants";
import { CenteredLogoImg } from "../../../../golbalStyles/global.styled";
import {useQuery} from "../../../../components/Control/useQuery"

const THead = ({ handleIpcStartDate, data, isView }) => {
  const [render, setRender] = useState(false);
  const query = useQuery();
  const customerName = query.get("customer");
  const partName = query.get("partName");
  return (
    <thead>
      <tr>
        <th
          colSpan="26"
          align="left"
          rowSpan={2}
          style={{
            border: "1px solid black",
            fontSize: "1.6rem",
            textAlign: "center",
          }}
        >
          <CenteredLogoImg>
            <img
              src={images.arcLogo}
              alt={textInputs.companyName}
              height="46"
              width="120"
            />
            <span>{textInputs.companyName}</span>
          </CenteredLogoImg>
        </th>
        <th colSpan={4}>DOC NO:</th>
        <th colSpan={4}>ARC/F/QAD/51</th>
      </tr>
      <tr>
        <th colSpan={4}>Revision No./Date:</th>
        <th colSpan={4}>01/01-03-2018</th>
      </tr>
      <tr>
        <td
          colSpan="34"
          align="center"
          style={{
            fontSize: "1.2rem",
            fontWeight: 600,
            backgroundColor: "#1d1d1d",
            color: "white",
            border: "1px solid black",
          }}
        >
          INITIAL SUPPLY MONITORING SHEET
        </td>
      </tr>
      <tr>
        <th colSpan={24} style={{ textAlign: "left" }}>
          CUSTOMER NAME:
          {data.part_id?.customer_id?.customer_name || customerName}
        </th>
        <th colSpan={10} style={{ textAlign: "left" }}>
          PART NAME: {data.part_id?.part_name || partName}
        </th>
      </tr>
      <tr>
        <th colSpan={24} style={{ textAlign: "left" }}>
          IPC START DATE:
          {isView ? (
            formatDate(data.ipc_start_date)
          ) : (
            <DatePicker
              style={{ width: "120px" }}
              className="date-field"
              inputVariant="filled"
              format="dd/MM/yyyy"
              onChange={(date) => {
                handleIpcStartDate(date);
              }}
              value={data.ipc_start_date || null}
            />
          )}
        </th>
        <th colSpan={10} style={{ textAlign: "left" }}>
          TERMINATION DATE:
          {isView ? (
            formatDate(data.termination_date)
          ) : (
            <DatePicker
              clearable
              style={{ width: "120px" }}
              className="date-field"
              inputVariant="filled"
              format="dd/MM/yyyy"
              onChange={(date) => {
                data.termination_date = date;
                setRender((prev) => !prev);
              }}
              value={data.termination_date || null}
            />
          )}
        </th>
      </tr>
    </thead>
  );
};

export default THead;
