import React from "react";
import AccountInfo from "./AccountInfo";
import { useAuth } from "../../context/GlobalContext";
function Account() {
  const { state } = useAuth();

  return (
    <div>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        PROFILE SETTINGS
      </div>
      {state && <AccountInfo data={state.user} />}
    </div>
  );
}

export default Account;
