import React, { useState, useEffect, useRef } from "react";
import { ButtonStyled } from "./Container.styled";

const DragableButton = (props) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const buttonRef = useRef();

  useEffect(() => {
    setPosition({
      x: props.right
        ? (window.innerWidth / window.innerHeight) * 100 + 1270
        : 5,
      y: window.innerHeight - 400,
    });

    const handleResize = () => {
      setPosition({
        x: props.right ? window.innerWidth - 75 : 0,
        y: window.innerHeight - 400,
      });
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 60;
    } else {
      document.getElementById("machinetable").scrollLeft -= 60;
    }
  };

  const handleMouseDown = (e) => {
    const x = e.clientX - position.x;
    const y = e.clientY - position.y;

    const handleMouseMove = (e) => {
      const newX = e.clientX - x;
      const newY = e.clientY - y;

      setPosition({
        x: newX > 0 && newX + 50 < window.innerWidth - 15 ? newX : position.x,
        y: newY > 0 && newY + 50 < window.innerHeight ? newY : position.y,
      });
    };

    document.addEventListener("mousemove", handleMouseMove);

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      buttonRef?.current?.style &&
        (buttonRef.current.style.transition = "100ms");
    };

    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <ButtonStyled
      ref={buttonRef}
      style={{
        top: position.y + "px",
        left: position.x + "px",
      }}
      onClick={(e) => {
        handleScroll((props.left && "left") || (props.right && "right"));
      }}
      onMouseDown={handleMouseDown}
      left={props.left}
      right={props.right}
    >
      {props.children}
    </ButtonStyled>
  );
};

export default DragableButton;
