import Input from "../../Control/Input";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Wrapper } from "../../Control/Flexbox";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ExpandLess, ExpandMore, Remove, Add } from "@material-ui/icons";

const PackingStandard = (props) => {
  const { ppapData, setPackStandImg } = props;
  const [render, setRender] = useState(false);
  const [isSpecs, setIsSpecs] = useState([]);
  const [isSecondSpecs, setIsSecondSpecs] = useState([]);
  function handleSpec(e, index) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    ppapData.master_packstands[index][e.target.name] = value;
  }
  function handleSecondSpec(e, index) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    ppapData.secondary_packstands[index][e.target.name] = value;
  }

  function append(where, data) {
    ppapData[where].push(data);
    setRender((prev) => !prev);
  }

  function remove(where, index) {
    if (window.confirm("Are you sure you want to delete Packing Standard?")) {
      ppapData[where].splice(index, 1);
      setRender((prev) => !prev);
    }
  }

  const hideSpecs = (index) => {
    setIsSpecs((prev) => [...prev, index]);
  };

  const hideSecondSpecs = (index) => {
    setIsSecondSpecs((prev) => [...prev, index]);
  };

  const openSpecs = (index) => {
    const values = [...isSpecs];
    const idx = values.indexOf(index);
    values.splice(idx, 1);
    setIsSpecs(values);
  };

  const openSecondSpecs = (index) => {
    const values = [...isSecondSpecs];
    const idx = values.indexOf(index);
    values.splice(idx, 1);
    setIsSecondSpecs(values);
  };

  return (
    <div className="specs-container" style={{ marginBottom: "3rem" }}>
      <Wrapper>
        <Input
          type="text"
          name="supplier_location"
          label="Supplier Location"
          onChange={(e) => {
            ppapData.supplier_location = e.target.value;
          }}
          defaultValue={ppapData.supplier_location}
        />
        <Input
          type="text"
          name="type_container"
          label="Types of Container Use"
          onChange={(e) => {
            ppapData.type_container = e.target.value;
          }}
          defaultValue={ppapData.type_container}
        />
        <Input
          type="text"
          name="type_dev"
          label="Types of Delivary"
          onChange={(e) => {
            ppapData.type_dev = e.target.value;
          }}
          defaultValue={ppapData.type_dev}
        />
        <Input
          type="text"
          name="rate_feq"
          label="Rate/FEQ"
          onChange={(e) => {
            ppapData.rate_feq = e.target.value;
          }}
          defaultValue={ppapData.rate_feq}
        />
        <Input
          type="text"
          name="pack_prepared_by"
          label="Prepared By"
          onChange={(e) => {
            ppapData.pack_prepared_by = e.target.value;
          }}
          defaultValue={ppapData.pack_prepared_by}
        />
        <Input
          type="text"
          name="pack_approved_by"
          label="Approved By"
          onChange={(e) => {
            ppapData.pack_approved_by = e.target.value;
          }}
          defaultValue={ppapData.pack_approved_by}
        />
      </Wrapper>
      <Input
        label="Part Picture"
        name="pack_stand_img"
        type="file"
        onChange={(e) => setPackStandImg(e.target.files[0])}
      />

      <div>
        <Wrapper
          justify="space-between"
          style={{ borderBottom: "1px solid #c80202" }}
        >
          <p className="card-btn-para" style={{ color: "#c80202" }}>
            • ADD{" "}
            <span style={{ fontWeight: 400, color: "black" }}>
              MASTER PACKING
            </span>
          </p>
          <Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "50px",
                backgroundColor: "#c80202",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                append("master_packstands", {
                  s_no: ppapData.master_packstands.length + 1,
                });
              }}
              justify="center"
            >
              <Add />
            </Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "50px",
                backgroundColor: "#151a1d",
                color: "white",
                cursor: "pointer",
              }}
              justify="center"
              onClick={() => {
                ppapData?.master_packstands?.sort(
                  (a, b) => Number(a.s_no) - Number(b.s_no)
                );
                setRender((prev) => !prev);
              }}
            >
              <RefreshIcon />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </div>

      {ppapData.master_packstands.map((spec, index) => (
        <div
          key={uuidv4()}
          style={{
            marginBottom: "0.3rem",
            marginTop: "0.6rem",
            padding: "0rem",
            border: "1px solid #f0f2f0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#fafafa",
              alignItems: "center",
            }}
            className="spec-header"
          >
            <Wrapper>
              <Input
                type="Number"
                name="s_no"
                onBlur={(e) => handleSpec(e, index)}
                defaultValue={spec.s_no}
                style={{ width: "80px" }}
              />
              <Wrapper
                justify="center"
                style={{
                  marginLeft: "0.4rem",
                  fontSize: "0.9rem",
                  textTransform: "uppercase",
                }}
              >
                {spec.desc}
              </Wrapper>
            </Wrapper>
            <Wrapper>
              {isSpecs.includes(index) ? (
                <Wrapper
                  style={{
                    height: "45px",
                    width: "50px",
                    backgroundColor: "#3f51b5",
                    cursor: "pointer",
                    color: "white",
                  }}
                  justify="center"
                  onClick={() => openSpecs(index)}
                >
                  <ExpandLess />
                </Wrapper>
              ) : (
                <Wrapper
                  style={{
                    height: "45px",
                    width: "50px",
                    backgroundColor: "#3f51b5",
                    cursor: "pointer",
                    color: "white",
                  }}
                  justify="center"
                  onClick={() => hideSpecs(index)}
                >
                  <ExpandMore />
                </Wrapper>
              )}
              <Wrapper
                style={{
                  height: "45px",
                  width: "50px",
                  backgroundColor: "#151a1d",
                  color: "white",
                  cursor: "pointer",
                }}
                justify="center"
                onClick={() => remove("master_packstands", index)}
              >
                <Remove />
              </Wrapper>
            </Wrapper>
          </div>
          {isSpecs.includes(index) && (
            <div className="specs-field">
              <Wrapper>
                <Input
                  type="text"
                  name="desc"
                  placeholder="Description"
                  onBlur={(e) => handleSpec(e, index)}
                  defaultValue={spec.desc}
                />
                <Input
                  type="text"
                  name="size"
                  placeholder="Size(Inch)"
                  onBlur={(e) => handleSpec(e, index)}
                  defaultValue={spec.size}
                />
                <Input
                  type="text"
                  name="qty"
                  placeholder="Qty."
                  onBlur={(e) => handleSpec(e, index)}
                  defaultValue={spec.qty}
                />
                <Input
                  type="text"
                  name="remarks"
                  placeholder="Remarks"
                  onBlur={(e) => handleSpec(e, index)}
                  defaultValue={spec.remarks}
                />
              </Wrapper>
            </div>
          )}
        </div>
      ))}

      <div style={{ marginTop: "2rem" }}>
        <Wrapper
          justify="space-between"
          style={{ borderBottom: "1px solid #c80202" }}
        >
          <p className="card-btn-para" style={{ color: "#c80202" }}>
            • ADD{" "}
            <span style={{ fontWeight: 400, color: "black" }}>
              SECONDARY PACKING
            </span>
          </p>
          <Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "50px",
                backgroundColor: "#c80202",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                append("secondary_packstands", {
                  s_no: ppapData.secondary_packstands.length + 1,
                });
              }}
              justify="center"
            >
              <Add />
            </Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "50px",
                backgroundColor: "#151a1d",
                color: "white",
                cursor: "pointer",
              }}
              justify="center"
              onClick={() => {
                ppapData?.secondary_packstands?.sort(
                  (a, b) => Number(a.s_no) - Number(b.s_no)
                );
                setRender((prev) => !prev);
              }}
            >
              <RefreshIcon />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </div>
      {ppapData.secondary_packstands.map((spec, index) => (
        <div
          key={uuidv4()}
          style={{
            marginBottom: "0.3rem",
            marginTop: "0.6rem",
            padding: "0rem",
            border: "1px solid #f0f2f0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#fafafa",
              alignItems: "center",
            }}
            className="spec-header"
          >
            <Wrapper>
              <Input
                type="Number"
                name="s_no"
                onBlur={(e) => handleSecondSpec(e, index)}
                defaultValue={spec.s_no}
                style={{ width: "80px" }}
              />
              <Wrapper
                justify="center"
                style={{
                  marginLeft: "0.4rem",
                  fontSize: "0.9rem",
                  textTransform: "uppercase",
                }}
              >
                {spec.desc}
              </Wrapper>
            </Wrapper>
            <Wrapper>
              {isSecondSpecs.includes(index) ? (
                <Wrapper
                  style={{
                    height: "45px",
                    width: "50px",
                    backgroundColor: "#3f51b5",
                    cursor: "pointer",
                    color: "white",
                  }}
                  justify="center"
                  onClick={() => openSecondSpecs(index)}
                >
                  <ExpandLess />
                </Wrapper>
              ) : (
                <Wrapper
                  style={{
                    height: "45px",
                    width: "50px",
                    backgroundColor: "#3f51b5",
                    cursor: "pointer",
                    color: "white",
                  }}
                  justify="center"
                  onClick={() => hideSecondSpecs(index)}
                >
                  <ExpandMore />
                </Wrapper>
              )}
              <Wrapper
                style={{
                  height: "45px",
                  width: "50px",
                  backgroundColor: "#151a1d",
                  color: "white",
                  cursor: "pointer",
                }}
                justify="center"
                onClick={() => remove("packstands", index)}
              >
                <Remove />
              </Wrapper>
            </Wrapper>
          </div>
          {isSecondSpecs.includes(index) && (
            <div className="specs-field">
              <Wrapper>
                <Input
                  type="text"
                  name="desc"
                  placeholder="Description"
                  onBlur={(e) => handleSecondSpec(e, index)}
                  defaultValue={spec.desc}
                />
                <Input
                  type="text"
                  name="size"
                  placeholder="Size(Inch)"
                  onBlur={(e) => handleSecondSpec(e, index)}
                  defaultValue={spec.size}
                />
                <Input
                  type="text"
                  name="qty"
                  placeholder="Qty."
                  onBlur={(e) => handleSecondSpec(e, index)}
                  defaultValue={spec.qty}
                />
                <Input
                  type="text"
                  name="remarks"
                  placeholder="Remarks"
                  onBlur={(e) => handleSecondSpec(e, index)}
                  defaultValue={spec.remarks}
                />
              </Wrapper>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default PackingStandard;
