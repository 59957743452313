import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";

const ChecklistNotification = ({
  isChecklistNotification,
  setChecklistNotification,
  classes,
  checklistMsg,
  removeChecklistMsg,
}) => {
  const history = useHistory();
  return (
    <div>
      {" "}
      <Menu
        id="simple-menu"
        anchorEl={isChecklistNotification}
        keepMounted
        open={Boolean(isChecklistNotification)}
        onClose={() => setChecklistNotification(false)}
        className={classes.noti}
      >
        {checklistMsg.length === 0 ? (
          <MenuItem>No New Notification</MenuItem>
        ) : (
          <>
            {checklistMsg.length &&
              checklistMsg.map((m) => (
                <div
                  key={m._id}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "8px",
                  }}
                >
                  <MenuItem
                    key={m._id}
                    onClick={() => {
                      history.push(`/checklist_actions_list`);
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: "400" }}>
                        Maintenance due for{" "}
                        <span style={{ fontWeight: 500 }}>
                          {" "}
                          {m.machine_name}
                        </span>{" "}
                        in{" "}
                        <span
                          style={{
                            fontWeight: 500,
                            textTransform: "capitalize",
                          }}
                        >
                          {" "}
                          {m.preventive_time}
                        </span>
                      </span>
                    </div>
                  </MenuItem>
                  <IconButton
                    style={{
                      padding: "8px",
                      margin: "0",
                    }}
                    onClick={() => removeChecklistMsg(m._id)}
                  >
                    <Close style={{ color: "red" }} />
                  </IconButton>
                </div>
              ))}
          </>
        )}
      </Menu>
    </div>
  );
};

export default ChecklistNotification;
