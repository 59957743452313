import { React, useState } from "react";
import { DatePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PublishIcon from "@material-ui/icons/Publish";

import { DivStyled } from "../Container.styled";
import useConfirm from "../../../components/Control/useConfirm";

const UploadFileModal = ({ setUploadFileModal, id, setComplaintId }) => {
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "DRAFT PART",
    `Are you sure you want to UPLOAD this part?`
  );
  const [uploadFiles, setUploadFiles] = useState({
    file: null,
  });

  const handelSumbit = async (e) => {
    const formData = new FormData();
    if (!uploadFiles.file) return;
    for (const key of Object.keys(uploadFiles.file)) {
      formData.append("files", uploadFiles.file[key]);
    }
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }
    if (uploadFiles.file) {
      await axios
        .put(`/api/updateSupplierAuditChecklist/${id}`, formData)
        .then((res) => {
          setUploadFileModal(false);
          setComplaintId(null);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleImagePdf = (e) => {
    setUploadFiles({
      file: e.target.files,
    });
  };

  const removeFiles = () => {
    setUploadFiles({
      file: null,
    });
  };

  return (
    <section style={{ padding: "0.3rem 0rem 0.3rem 0rem" }}>
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handelSumbit}
        >
          {"UPLOAD"}
        </Button>
      </div>
      <DivStyled>
        <input
          id="contained-button-file"
          type="file"
          multiple
          accept="image/png,image/jpeg,image/jpg/,application/pdf"
          onChange={handleImagePdf}
          hidden
        />
        <label
          htmlFor="contained-button-file"
          style={{
            marginBottom: "0",
          }}
        >
          <Button component="span">
            <CloudUploadIcon style={{ marginRight: "1rem" }} />
            Upload Picture / Pdf
          </Button>
        </label>

        <div
          style={{
            borderLeft: "2px solid #ced4da",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "350px",
            marginRight: "-140px",
          }}
        >
          <div
            style={{
              paddingLeft: "0.5rem",
            }}
          >
            {uploadFiles.file ? `${uploadFiles.file[0]?.name}...` : ""}
          </div>
          {uploadFiles.file && (
            <Button
              component="span"
              type="button"
              style={{
                marginLeft: "0.6rem",
              }}
              onClick={removeFiles}
              startIcon={<DeleteIcon />}
            >
              REMOVE
            </Button>
          )}
        </div>
      </DivStyled>
      <DialogSubmit isSubmitContent={false} />
    </section>
  );
};

export default UploadFileModal;
