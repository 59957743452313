import React, { useState, useEffect } from "react";
import axios from "axios";
import PirForm from "./PirForm";
import "../../../style/pir.css";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Control/Loader";
import "./pir_style.css";
import { useHistory } from "react-router-dom";
const Pir = () => {
  const [data, setData] = useState(null);
  const { partId } = useParams();
  const history = useHistory();
  const StoreArr = [];
  if (data) {
    function appendValue() {
      if (data.isLocked) {
        history.push("/");
      }
      for (let i = 0; i < data.processes.length; i++) {
        StoreArr.push({
          process_name: data.processes[i].process_name,
          list: [],
          setups: [],
        });
        for (let j = 0; j < data.processes[i].specs.length; j++) {
          if (data.processes[i].specs[j].spec_to_pir) {
            StoreArr[i].list.push({
              parameter:
                data.processes[i].specs[j].char_product ||
                data.processes[i].specs[j].char_process,
              instrument_used: data.processes[i].methods_measure,
              specification:
                data.processes[i].specs[j].control_product ||
                data.processes[i].specs[j].control_product,
              methods: data.processes[i].specs[j].methods_measure,
              appearance: data.processes[i].specs[j].appearance,
              mean: data.processes[i].specs[j].mean,
              utolerance: data.processes[i].specs[j].utolerance,
              ltolerance: data.processes[i].specs[j].ltolerance,
              specUnit: data.processes[i].specs[j].unit,
              utl: data.processes[i].specs[j].utl,
              ltl: data.processes[i].specs[j].ltl,
              rejected: {},
              check4_symbol: data.processes[i].specs[j].check4_symbol,
              checkbox4_num: data.processes[i].specs[j].checkbox4_num,
            });
          }
          if (data.processes[i].specs[j].spec_to_setup) {
            StoreArr[i].setups.push({
              parameter:
                data.processes[i].specs[j].char_product ||
                data.processes[i].specs[j].char_process,
              instrument_used: data.processes[i].methods_measure,
              specification:
                data.processes[i].specs[j].control_product ||
                data.processes[i].specs[j].control_product,
              methods: data.processes[i].specs[j].methods_measure,
              appearance: data.processes[i].specs[j].appearance,
              mean: data.processes[i].specs[j].mean,
              utolerance: data.processes[i].specs[j].utolerance,
              ltolerance: data.processes[i].specs[j].ltolerance,
              specUnit: data.processes[i].specs[j].unit,
              utl: data.processes[i].specs[j].utl,
              ltl: data.processes[i].specs[j].ltl,
              rejected: {},
              check4_symbol: data.processes[i].specs[j].check4_symbol,
              checkbox4_num: data.processes[i].specs[j].checkbox4_num,
            });
          }
        }
      }
    }
    appendValue();
  }

  useEffect(() => {
    window.onbeforeunload = function () {
      return false;
    };
    axios.get(`/api/pir/add_pir_from_controlplanpart/${partId}`).then((res) => {
      setData(res.data);
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {StoreArr.length || data ? (
        <PirForm {...{ partId, data }} StoreArr={StoreArr} />
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Pir;
