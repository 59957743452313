import React, { useState, useEffect } from "react";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { Wrapper } from "../../../components/Control/Flexbox";
import Loader from "../../../components/Control/Loader";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import Delete from "@material-ui/icons/Delete";

const UploadDocument = ({ id, isView, fieldId, setIsInitialModal }) => {
  const [uploadFiles, setUploadFiles] = useState({
    file: null,
  });
  const [render, setRender] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [data, setData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/getInitialSupplyFiles/${id}/${fieldId.current}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render]);

  const handleImagePdf = (e) => {
    setUploadFiles({
      file: e.target.files,
    });
  };

  const removeFiles = () => {
    setUploadFiles({
      file: null,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!uploadFiles.file) {
      alert("Please First select files or images");
      return;
    }
    setIsButtonDisabled(true);
    setIsloading(true);
    const formData = new FormData();
    for (const key of Object.keys(uploadFiles.file)) {
      formData.append("files", uploadFiles.file[key]);
    }
    axios
      .patch(`/api/uploadInitialSupplyFiles/${id}/${fieldId.current}`, formData)
      .then((res) => {
        if (res.data) {
          setIsButtonDisabled(false);
          setIsInitialModal(false);
          setIsloading(false);
        }
      })
      .catch((err) => {
        setIsButtonDisabled(false);
        setIsloading(false);
      });
  };
  const handleDelete = (index) => {
    if (!window.confirm("Are you sure you want to delete this file?")) {
      return;
    }
    axios
      .delete(`/api/initialSupplyFilesDelete/${id}/${fieldId.current}/${index}`)
      .then((res) => {
        if (res.data) {
          alert("file deleted successfully");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsButtonDisabled(false);
      });
  };
  if (isLoading) {
    return <Loader height={"20vh"} />;
  }

  return (
    <div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Wrapper style={{ justifyContent: "center" }}>
          <div>
            {!isView && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1.2rem",
                }}
              >
                <input
                  id="contained-button-file"
                  type="file"
                  name="pcs_imageOrPdf"
                  accept="image/png,image/jpeg,image/jpg/,application/pdf"
                  onChange={handleImagePdf}
                  multiple
                  hidden
                />
                <label
                  htmlFor="contained-button-file"
                  style={{
                    marginBottom: "0",
                  }}
                >
                  <Button variant="outlined" component="span" color="secondary">
                    <CloudUploadIcon style={{ marginRight: "1rem" }} />
                    Upload Picture / Pdf
                  </Button>
                </label>
                {uploadFiles.file && (
                  <Button
                    variant="outlined"
                    type="button"
                    color="primary"
                    style={{
                      marginLeft: "0.6rem",
                    }}
                    onClick={removeFiles}
                    startIcon={<DeleteIcon />}
                  >
                    REMOVE
                  </Button>
                )}
              </div>
            )}
          </div>
        </Wrapper>
        <section style={{ paddingBottom: "2rem", marginTop: "1rem" }}>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                {["#", "File Name", "ACTION"].map((item) => (
                  <TableCell style={{ fontWeight: "bold" }} align="center">
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((file, index) => (
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{file.name}</TableCell>
                  <TableCell align="center">
                    <Tooltip title="View" arrow>
                      <a href={file.url} target="_blank">
                        <IconButton size="small">
                          <Visibility
                            style={{
                              fontSize: "1.4rem",
                              color: "#003566",
                            }}
                          />
                        </IconButton>
                      </a>
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        size="small"
                        style={{ marginLeft: "0.5rem" }}
                        onClick={() => handleDelete(index)}
                      >
                        <Delete
                          style={{
                            fontSize: "1.4rem",
                            color: "red",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </section>

        {!isView && (
          <div
            className="btn_save"
            style={{
              position: "absolute",
              right: "150px",
              top: "9px",
            }}
          >
            <Button
              type="submit"
              variant="outlined"
              color="white"
              size="large"
              disabled={isButtonDisabled}
              style={{ color: "#e9e9e9" }}
              startIcon={<SaveIcon />}
            >
              Submit
            </Button>
          </div>
        )}
      </form>
    </div>
  );
};

export default UploadDocument;
