import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import Styled from "styled-components";
import { Wrapper } from "../../Control/Flexbox";
import PrintIcon from "@material-ui/icons/Print";
import formatDate from "../../Control/formatDate";
import TableToExcel from "@dayalk/table-to-excel";

import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;
    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 13px;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
        font-weight: 400;
    }
`;
const WarrantReport = ({ ppapData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <section>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          PART SUBMISSION WARRANT
        </Typography>
        <Wrapper>
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  borderRadius: "0",
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: "Part Submission Warrant.xlsx",
                      sheet: {
                        name: "Part Submission Warrant",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      </div>
      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="20, 38, 35, 30, 23, 23"
          // style={{ border: "2px solid black" }}
        >
          <tr data-height="80" style={{ height: "100px" }}>
            <th
              colSpan="6"
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
              }}
              data-a-v="middle"
            >
              <CenteredLogoImg>
                <img
                  src={images.arcLogo}
                  alt={textInputs.companyName}
                  height="46"
                  width="120"
                />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
          </tr>

          <tr>
            <td
              colSpan="6"
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
              }}
              data-font-sz="18"
              data-a-h="center"
              data-f-bold="true"
              data-fill-color="#1d1d1d"
              data-f-color="FFFFFF"
            >
              PART SUBMISSION WARRANT
            </td>
          </tr>
          <tr>
            <td align="left" style={{}} colSpan={3}>
              Part Name: {ppapData.part_id.part_name}
            </td>
            <td align="left" colSpan={3} style={{}}>
              Cust. Part Number: {ppapData.part_id.part_number}
            </td>
          </tr>
          <tr>
            <td align="left" style={{}} colSpan={3}>
              Show on Drawing Number: {ppapData.draw_no}
            </td>
            <td align="left" colSpan={3} style={{}}>
              Org. Part Number: {ppapData.org_part_no}
            </td>
          </tr>
          <tr>
            <td align="left" style={{}} colSpan={3}>
              Engineering Change Level: {ppapData.change_level}
            </td>
            <td align="left" colSpan={3} style={{}}>
              Dated: {formatDate(ppapData.psw_engg_change_date)}
            </td>
          </tr>

          <tr>
            <td align="left" style={{}} colSpan={3}>
              Additional Engineering Change: {ppapData.eng_change}
            </td>
            <td align="left" colSpan={3} style={{}}>
              Dated: {formatDate(ppapData.psw_additional_engg_date)}
            </td>
          </tr>

          <tr>
            <td align="left" style={{}} colSpan={3}>
              <span>Safety and/or Government Regulation : </span>
              {ppapData.psw_sagr === "yes" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Yes
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_sagr === "no" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                No
              </span>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {ppapData.psw_sagr === "na" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span>n/a</span>
            </td>
            <td align="left" colSpan={2} style={{}}>
              Purchase Order No.: {ppapData.purchase_order_no}
            </td>
            <td align="left" style={{}}>
              Weight(Kg): {ppapData.warrant_weight}
            </td>
          </tr>

          <tr>
            <td align="left" style={{}} colSpan={3}>
              Checking Aid Number: {ppapData.checking_aid_no}
            </td>
            <td align="left" colSpan={2} style={{}}>
              Checking Aid Engineering Change: {ppapData.checking_aid_engg}
            </td>
            <td align="left" style={{}}>
              Dated: {formatDate(ppapData.psw_checking_aid_date)}
            </td>
          </tr>

          <tr>
            <th align="left" style={{}} colSpan={4}>
              ORGANIZATION MANUFACTURING INFORMATION
            </th>
            <th align="left" colSpan={2} style={{}}>
              CUSTOMER SUBMITTAL INFORMATION
            </th>
          </tr>

          <tr>
            <td align="left" style={{}} colSpan={4}>
              <span>Organization Name & Supplier/Vendor Code</span>
              {ppapData.psw_org_name} / {ppapData.psw_vedor_code}
            </td>
            <td align="left" colSpan={2} style={{}}>
              <span>Customer Name/Division</span>
              {ppapData.customer_name}
            </td>
          </tr>

          <tr>
            <td align="left" style={{}} colSpan={4}>
              Street Address: {ppapData.street_address}
            </td>
            <td align="left" colSpan={2} style={{}}>
              Buyer/Buyer Code: {ppapData.buyer}
            </td>
          </tr>

          <tr>
            <td align="left">City : {ppapData.psw_city}</td>
            <td align="left">Region: {ppapData.psw_region} </td>
            <td align="left">Postal Code: {ppapData.psw_postal_code}</td>
            <td align="left">Country: {ppapData.psw_country}</td>
            <td align="left">Application : {ppapData.application}</td>
            <td align="left"></td>
          </tr>
          <tr>
            <th align="left" colSpan={6}>
              MATERIAL REPORTING
            </th>
          </tr>
          <tr>
            <td align="left" colSpan={3}>
              Has customer-required Subtances of Concern information been
              reported?
            </td>
            <td align="left">
              {ppapData.psw_hcrsoci === "yes" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Yes
              </span>
            </td>
            <td>
              {ppapData.psw_hcrsoci === "no" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}

              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                No
              </span>
            </td>
            <td>
              {ppapData.psw_hcrsoci === "na" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span>n/a</span>
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={6}>
              Submit by IMDS or other customer format:{" "}
              {ppapData.psw_isSubmitByImds}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={6} style={{ fontWeight: "bold" }}>
              if submitted by IMDS, enter Module ID Number,Version and date
              transmitted
              <span style={{ marginLeft: "5rem" }}>
                {ppapData.psw_moduleId}
              </span>
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              Are polymeric parts identified with appropiate ISO marking codes?{" "}
            </td>
            <td align="left">
              {ppapData.psw_appi === "yes" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Yes
              </span>
            </td>
            <td>
              {ppapData.psw_appi === "no" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}

              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                No
              </span>
            </td>
            <td>
              {ppapData.psw_appi === "na" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span>n/a</span>
            </td>
            <td></td>
          </tr>
          <tr>
            <th align="left" colSpan={6}>
              REASON FOR SUBMISSION (Check at least one)
            </th>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.wis ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={2} align="left">
              Initial submission
            </td>
            <td align="left" colSpan={1}>
              {ppapData.wec ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={2} align="left">
              Change to Optional Construction or Material
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.wis ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={2}>Engineering Change's</td>
            <td align="left" colSpan={1}>
              {ppapData.wsc ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={2} align="left">
              Sub-Supplier or Material Source Change
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.wt ? (
                <span
                  style={{
                    fontSize: "1.2rem",

                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={2} align="left">
              Tooling: Transfer, Replacement, Refurbishment, or additional
            </td>
            <td align="left" colSpan={1}>
              {ppapData.wcp ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={2} align="left">
              Change in Part Processing
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.wcd ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={2} align="left">
              Correction of Discrepancy
            </td>

            <td align="left" colSpan={1}>
              {ppapData.wpl ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={2} align="left">
              {"Parts produced at Additional Location"}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              {ppapData.wty ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={2} align="left">
              {"Tooling Inactive > than 1 year"}
            </td>

            <td align="left" colSpan={1}>
              {ppapData.wos ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={2} align="left">
              Other - please specify
            </td>
          </tr>
          <tr>
            <th align="left" colSpan={6}>
              REQUESTED SUBMISSION LEVEL (Check one)
            </th>
          </tr>
          <tr>
            <td align="left" colSpan={1} style={{ width: "1px" }}>
              {ppapData.rsl === "LEVEL 1" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={5} align="left">
              Level 1 - Warrant only (and for designated appearance items, an
              Appearance Approval Report) submitted to customer.{" "}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1} style={{ width: "1px" }}>
              {ppapData.rsl === "Level 2" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={5} align="left">
              Level 2 - Warrant with product samples and limited supporting data
              submitted to customer.
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1} style={{ width: "1px" }}>
              {ppapData.rsl === "Level 3" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={5} align="left">
              Level 3 - Warrant with product samples and complete supporting
              data submitted to customer.
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1} style={{ width: "1px" }}>
              {ppapData.rsl === "Level 4" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={5} align="left">
              Level 4 - Warrant and complete supporting data submitted to
              customer.{" "}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={1} style={{ width: "1px" }}>
              {ppapData.rsl === "Level 5" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
            </td>
            <td colSpan={5} align="left">
              {" "}
              Level 5 - Warrant with product samples and complete supporting
              data reviewed at supplier's manufacturing location.{" "}
            </td>
          </tr>
          <tr>
            <th align="left" colSpan={6}>
              SUBMISSION RESULTS
            </th>
          </tr>
          <tr>
            <td align="left" style={{ width: "10rem" }}>
              The results for
            </td>
            <td align="left" style={{}}>
              {" "}
              {ppapData.psw_dm ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              dimensional measurements
            </td>
            <td align="left">
              {ppapData.psw_mft ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              material and functional tests
            </td>
            <td
              align="left"
              // style={{ width: "10rem" }}
            >
              {ppapData.psw_ac ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              appearance criteria
            </td>
            <td align="left" colSpan={2}>
              {ppapData.psw_ssp ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              statistical process package
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              These results meet all drawing and specification requirements
            </td>
            {/* {psw_rsl_req} */}
            <td align="left">
              {ppapData.psw_rsl_req === "yes" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Yes
              </span>
            </td>
            <td>
              {ppapData.psw_rsl_req === "no" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}

              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                No
              </span>
            </td>
            <td align="left" colSpan={2}>
              (If "NO" - Explanation Required)
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              Mold / Cavity / Production Process
            </td>
            <td align="left" colSpan={2}>
              {ppapData.psw_pp}
            </td>
            <td colSpan={2}></td>
          </tr>

          <tr
          // style={{ borderBottom: "1px solid black" }}
          >
            <th align="left" colSpan={6}>
              DECLARATION
            </th>
          </tr>
          <tr data-height="70">
            <td
              align="center"
              colSpan={6}
              style={{ border: "1px solid black" }}
            >
              {ppapData?.declaration}
            </td>
          </tr>
          <tr>
            <th align="left" colSpan={2} rowSpan={2}>
              EXPLANATION/COMMENTS:
            </th>
            <td colSpan={3}></td>
            <td colSpan={1}></td>
          </tr>
          <tr data-height="20">
            <td colSpan={3}></td>
            <td colSpan={1}></td>
          </tr>
          <tr>
            <td align="left" colSpan={6}>
              {ppapData.notes}
            </td>
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              Is each Customer Tool properly tagged and numbered?
            </td>
            {/* {psw_ctptan} */}
            <td align="left">
              {ppapData.psw_ctptan === "yes" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Yes
              </span>
            </td>
            <td>
              {ppapData.psw_ctptan === "no" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}

              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                No
              </span>
            </td>
            <td>
              {ppapData.psw_ctptan === "na" ? (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  ☑
                </span>
              ) : (
                <span
                  style={{
                    fontSize: "1.2rem",
                    marginRight: "0.6rem",
                  }}
                >
                  □
                </span>
              )}
              <span>n/a</span>
            </td>
            <td></td>
            {/* <td align="left" colSpan={2}>
              Tool Order No. {ppapData.tool_order}
            </td> */}
          </tr>
          <tr>
            <td align="left" colSpan={2}>
              Organization Authorized Signature
            </td>
            <td align="left" colSpan={1}></td>
            <td align="left" colSpan={1}>
              Date: {formatDate(ppapData.approve_date)}
            </td>
            <td align="left" colSpan={2}></td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              Print Name: {ppapData.print_name}
            </td>
            <td align="left" colSpan={1}></td>

            <td align="left" colSpan={1}>
              Phone No.: {ppapData.phone}
            </td>
            <td colSpan={1}></td>
            <td align="left" colSpan={1}>
              Fax No.: {ppapData.fax}
            </td>
            <td align="left" colSpan={1}></td>
          </tr>
          <tr style={{ height: "5rem" }}>
            <td align="left" colSpan={1}>
              Title: {ppapData.title}
            </td>
            <td align="left" colSpan={2}></td>
            <td align="left" colSpan={1}>
              E-mail: {ppapData.email_address}
            </td>
            <td colSpan={2}></td>
          </tr>
          <tr>
            <th
              align="center"
              colSpan={6}
              style={{
                textAlign: "center",
                borderTop: "1px solid black",
                borderLeft: "1px solid black",
              }}
            >
              FOR CUSTOMER USE ONLY (IF APPLICABLE)
            </th>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              Part Warrant Disposition:
            </td>
            <td>
              <span
                style={{
                  fontSize: "1.2rem",
                  marginRight: "0.6rem",
                }}
              >
                □
              </span>
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Approved
              </span>
            </td>
            <td>
              <span
                style={{
                  fontSize: "1.2rem",
                  marginRight: "0.6rem",
                }}
              >
                □
              </span>
              <span
                style={{
                  marginRight: "2rem",
                }}
              >
                Rejected
              </span>
            </td>
            <td>
              <span
                style={{
                  fontSize: "1.2rem",
                  marginRight: "0.6rem",
                }}
              >
                □
              </span>
              Other
            </td>
            <td></td>
            <td
              style={{
                borderRight: "1px solid black",
              }}
            ></td>
          </tr>
          <tr>
            <td align="left" colSpan={1}>
              Customer Signature:
            </td>
            <td colSpan={1}></td>
            <td align="left" colSpan={1}>
              Date:
            </td>
            <td colSpan={1}>{/* {ppapData.approve_date} */}</td>
            <td
              colSpan={2}
              style={{
                borderRight: "1px solid black",
              }}
            ></td>
          </tr>
          <tr>
            <td
              align="left"
              colSpan={1}
              style={{ borderBottom: "1px solid black" }}
            >
              {" "}
              Customer Name:
            </td>
            <td colSpan={1} style={{ borderBottom: "1px solid black" }}></td>

            <td
              align="left"
              colSpan={1}
              style={{ borderBottom: "1px solid black" }}
            >
              Customer Tracking Number(optional)
            </td>
            <td colSpan={1} style={{ borderBottom: "1px solid black" }}></td>
            <td
              colSpan={2}
              style={{
                borderBottom: "1px solid black",
                borderRight: "1px solid black",
              }}
            ></td>
          </tr>
        </Table>
      </div>
    </section>
  );
};

export default WarrantReport;
