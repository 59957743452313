export const internalAudit = {
  sales_and_marketing: [
    {
      clause_no: "5.2.2 & 7.3",
      audit_check_points:
        "Are the employees /  operators are aware of Quality Policy ? ",
      what_to_look_and_record:
        " Interview with any three operators /  employees and check the awareness ",
      actual_obs: "Interview of five operators found aware for Quality Policy ",
      status_c_nc: "",
    },
    {
      clause_no: 7.3,
      audit_check_points: "Awareness on QMS",
      what_to_look_and_record:
        "Relevent Quality objectives known to operators, verify with 3 peoples in department",
      actual_obs: "Operator aware regarding Quality Objectives ",
      status_c_nc: "",
    },
    {
      clause_no: "9.2.2 & 10.2",
      audit_check_points: "Last Internal audit NCR effectiveness",
      what_to_look_and_record: "Last audit NCR and effectiveness",
      actual_obs: "Last Internal Audit NC verify found effective ",
      status_c_nc: "",
    },
    {
      clause_no: 4.4,
      audit_check_points: "QMS planning",
      what_to_look_and_record:
        "Is the process in put and output for Markeing process is defined ?",
      actual_obs: "Marketing Procedure defined with Input and output ",
      status_c_nc: "",
    },
    {
      clause_no: 4.1,
      audit_check_points: "Organisation and its context",
      what_to_look_and_record:
        "Internal /  external Issues related to process determined and monitored.",
      actual_obs: "Internal and external issues are determind and monitored ",
      status_c_nc: "",
    },
    {
      clause_no: 4.2,
      audit_check_points: " Interested party requirements",
      what_to_look_and_record:
        "a) Customer as Interested party identifed\nb) Customer requirement determined\n",
      actual_obs:
        "Intrested parties are defined and customer reqiuirements defined in CSR Matrix ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.1",
      audit_check_points: "Risks and opportunties",
      what_to_look_and_record:
        "Risk related to process determined\nRisk related to customer requirements",
      actual_obs: "Risk and Opportunuty is defind related to customer ",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.2",
      audit_check_points: "Action plan defined for each objective",
      what_to_look_and_record:
        "Action plan included-\na) What will be done;\nb) What resources will be required;\nc) Who will be responsible;\nd) When it will be completed;\ne) How the results will be evaluated.",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.1 & 9.1.1",
      audit_check_points:
        "Are the Effectiveness and Efficiency parameters  are documented, monitored and actions are taken when targets are not achieved ?",
      what_to_look_and_record:
        "a) process parameters with targets \nb) Performance trends from last 6 months.\nC) Record actual results for last 6 months . \n",
      actual_obs:
        "Process Parameters with targets is defined \nLast one Year Performance records and Trends are available ",
      status_c_nc: "",
    },
    {
      clause_no: "5.3.1",
      audit_check_points: "Responsibilities and authorities ",
      what_to_look_and_record:
        "a) Doducment indicating the responsibilities and authorities of marketing personnel \nb) Interview with them about their roles and responsibility ",
      actual_obs: "Responsibility and Authority is clearly defined ",
      status_c_nc: "",
    },
    {
      clause_no: "7.4 & 8.2.1",
      audit_check_points:
        "Is the method of internal communication with respect to QMS is effective ",
      what_to_look_and_record:
        "a) Check communication ( internal and external )  \nb) Customer communication on cotract and its amendment.\nC) Use of customer -specified computer language and format if any.",
      actual_obs:
        "Internal and external communication through mails and WhatsApp Groups ",
      status_c_nc: "",
    },
    {
      clause_no: "8.2.2 & 8.2.3",
      audit_check_points:
        "Determination of requiremnts related to the product ",
      what_to_look_and_record:
        "a) Documented Process for Business development /  contract review for new products and its compliance \nb) Customer ennqiry register \nc) Determination of customer requirements including statutory and regulatory requirements \nd) contrct review record",
      actual_obs:
        "Documented process for Business Development and Customer Enquiry register and Contract Review are available ",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.2",
      audit_check_points: "Customer satisfaction ",
      what_to_look_and_record:
        "a) Internal process for Customer satisfaction and its compliance \nb) Customer satisfaction surveys which shall include delivered part quality performance, customer distuptions, including field returns, delivery schedule performance including premium freight \n",
      actual_obs:
        "Process is availble for Customer satishfaction, Quality and Delivery ratiing are monitoring Custmer Plant wise ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.3",
      audit_check_points: "Customer property",
      what_to_look_and_record:
        "a) Customer property permanantly identifed\nb) Care of customer property\nc) Any property damaged /  lost /  unusable and its record on what has occurred.",
      actual_obs: "Customer Property are not defined ",
      "Status C / NC": "NC",
    },
    {
      clause_no: "9.1.3",
      audit_check_points: "Analysis of data ",
      what_to_look_and_record:
        "a) Data analysis and its use for continual improvement with respect to Customer satisfaction .",
      actual_obs:
        "Data analysis and take action for improve customer satisfaction ",
      status_c_nc: "",
    },
    {
      clause_no: 10.2,
      audit_check_points: "Corrective and Preventive action \n",
      what_to_look_and_record:
        "a) Documented Process for Corrective and Preventive action\nb) Corrective action taken on Internal audit NCRs and their implementation \n ",
      actual_obs: "NC closing Criteria Defined in Procedure ",
      status_c_nc: "",
    },
  ],
  NPD: [
    {
      clause_no: "5.2.2 & 7.3",
      audit_check_points:
        "Are the employees /  operators are aware of Quality Policy ? ",
      what_to_look_and_record:
        " \nInterview with any three operators /  employees and check the awareness ",
      actual_obs: "Interview of five operators found aware for Quality Policy ",
      status_c_nc: "",
    },
    {
      clause_no: 7.3,
      audit_check_points: "Awareness on QMS",
      what_to_look_and_record:
        "Relevent Quality objectives known to operators, verify with 3 peoples in department",
      actual_obs: "Operator aware regarding Quality Objectives ",
      status_c_nc: "",
    },
    {
      clause_no: "9.2.2 & 10.2",
      audit_check_points: "Last Internal audit NCR effectiveness",
      what_to_look_and_record: "Last audit NCR and effectiveness\n",
      actual_obs: "Last Internal Audit NC verify found effective ",
      status_c_nc: "",
    },
    {
      clause_no: 4.4,
      audit_check_points: "QMS planning",
      what_to_look_and_record:
        "Is the manufacturing process flow with in put and output for Engineering is defined ?",
      actual_obs: "Procedure defined with Input and output ",
      status_c_nc: "",
    },
    {
      clause_no: 4.1,
      audit_check_points: "Organisation and its context",
      what_to_look_and_record:
        "Internal /  external Issues rlated to process determined and monitored.",
      actual_obs: "Internal and external issues are determind and monitored ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.1",
      audit_check_points: "Risks and opportunties",
      what_to_look_and_record: "Risk related to process determined",
      actual_obs:
        "Intrested parties are defined and customer reqiuirements defined in CSR Matrix ",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.2",
      audit_check_points: "Action plan defined for each objective",
      what_to_look_and_record:
        "Action plan included-\na) What will be done;\nb) What resources will be required;\nc) Who will be responsible;\nd) When it will be completed;\ne) How the results will be evaluated.",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.3.1",
      audit_check_points: "Plant, facility, Equipment planning",
      what_to_look_and_record: "Verify plant layout for new developemnt",
      actual_obs: "Layout of Plant is suitable for New Parts developments ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.2.2",
      audit_check_points: "Product Design Skills ",
      what_to_look_and_record:
        "a) Competency record of design personnel \nb) Identification of applicable tools and techniques to design personnel ",
      actual_obs: "Drawing provided by Customer ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.2 & 8.3.2.1",
      audit_check_points: "Design and development planning ",
      what_to_look_and_record:
        "a) Process for New product development and its  compliance \nb) Design and Development - Timing plan \nc) Approvded CFT with defined responsibilities and authorities \nc) Updation of planning ouput \nVerifiy for any one new products developed ",
      actual_obs: "New part developments activity done as per APQP ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.3.2",
      audit_check_points: "Manufacturing process design input ",
      what_to_look_and_record:
        "a) Internal Process for identifying manufacturing process design input and its compliance . Inputs shall include \nb) Product design output data \nc) Targets for productivity, process capability and cost \nd) Customer requirements, if any \ne) expereince from previous developments ",
      actual_obs:
        "Internal process defined for New parts development \npast trouble data also monitored to eliminate failures and customer Special requirements also monitor ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.3.3",
      audit_check_points: "Special characteristics",
      what_to_look_and_record:
        "a) Internal Process for identifying special characteristics \nb) Identification of special charactertiscs \nc) Inclusion of special charactetisitcs in control plan \nd) complying of customer specified definitions and symbols \ne) Identification of process control documents , FMEA, Control plans and OCP with customer's special characteistic symbol or the organization's equivalent symbols ",
      actual_obs:
        "Special Characteristics are identified mention in Control plan and Process FMEA ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.5.2",
      audit_check_points: "Manufacturing process design output ",
      what_to_look_and_record:
        "a) Internal Process for reviwing Manufacturing process design outputs and its compliance, which shall include \nb) Specificaitions and drawings \nc) Manufacturing processs flow chart /  layout \nd) Manufacturing Process FMEAs \ne) Control Plan \nf) Work insructions \ng) Process aproval acceptance criteria \nh) data for quality, reliability, maintainability and measurability \ni) results of error proofing activitis, as appropriate \nj) methos of rapid detection and feed back of products/  manufacturing proces non conformities. ",
      actual_obs:
        "All related Documents control Plan, Process FMEA Work instructions Process Approvals are verified and error proofing also implemented on required places ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.4 & 9.3.2.1",
      audit_check_points: "Design and Development reviews ",
      what_to_look_and_record:
        "a) Process for systemactic design reviews and identification of suitable stages for reviews \nb) Review record after each identified stage \nc) Action plan for problems encountered , if any during development \nd) design and development summary results reported in MRM\n",
      actual_obs:
        "Drawing review for Developments by CFT for Process design and action plan for identified issues ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.4.2",
      audit_check_points: "Design and development validation ",
      what_to_look_and_record:
        "Process reference for design and development validation . \nA) Programm timing to validate the design and development as per cust0mer requirements \nb) Prototype proramme , as required by the customer \n",
      actual_obs:
        "Process reference for design is validated and Time plan defined from developments as per customer requirements ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.4.4",
      audit_check_points: "Product approval process ",
      what_to_look_and_record:
        "a)PPAP record of the new products as recognized by the customer \nb) PPAP record & Approved PSW of new supply started",
      actual_obs: "PPAP Documsnte not available for All Developed Parts ",
      "Status C / NC": "NC",
    },
    {
      clause_no: "8.3.4.3",
      audit_check_points: "Prototype program",
      what_to_look_and_record:
        "a) Any proto sample submited\nb) Protosampe control plan\nc) Verification reports",
      actual_obs:
        "Proto sample Submitted to customer and Proto CP also recorded with Inspection reports ",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.1",
      audit_check_points:
        "Monitoring and measurement of manufacturing processes ",
      what_to_look_and_record:
        "a) Adherence to the control plan and process flow for all new products \nb) Action plan for non conformance noticed during monitoring and measurement of new products ",
      actual_obs: "New Part develop as pep control plan ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.3",
      audit_check_points: "Customer property ",
      what_to_look_and_record:
        "a) Process for handling customer property and its compliance \nb) List of customer property ( tooling ) \nc) List of customer drawings and their preservations ",
      actual_obs: "Customer Property Not identify ",
      "Status C / NC": "NC",
    },
    {
      clause_no: "8.5.1.1& 8.3.5.2",
      audit_check_points: "Control plans",
      what_to_look_and_record:
        "a) Pre launch & Prodcution control plans for new products \nb) Safety charecteristics (CC) and its reaction plan included in control plan\nc) Special charecteristics (SC) identifed in control plan with its control method",
      actual_obs:
        "Control Plan available for all new parts with identification of SC and CC ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1.2 & 8.3.5.2",
      audit_check_points: "Work instructions",
      what_to_look_and_record:
        "a) Work instructions for new products arrived from control plans and FMNEAs\nb) Work instructions are understandable to operators, verify with shop floor operators.",
      actual_obs: "Work instruction for new part developments is available ",
      status_c_nc: "",
    },
    {
      clause_no: "7.5.3.2 & 7.5.3.2.1",
      audit_check_points: "Document & record control control",
      what_to_look_and_record:
        "a) Check development documents controlled as per document control Process\nb) check any two records retained as per retention period\nc) Tool design data, PPAP record retention period defined - prodcut active plus one calender year.",
      actual_obs:
        "All related Documents control Plan, Process FMEA Work instructions Process Approvals are verified and found as per retaintion period ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1.6",
      audit_check_points: "Management of production tooling ",
      what_to_look_and_record:
        "a) Process for development of tooling at outsource and its compliance \nb) Tooling suppliers quality and delivery records \nc) Incoming tool insepction reports ",
      actual_obs:
        "No Tooling in using in New parts development only Insert, Drill Hex are using ",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.3",
      audit_check_points: "Analysis of data ",
      what_to_look_and_record:
        "a) Data analysis and its use for continual improvement with respect to  new product development ",
      actual_obs: "Data analysis for improvements in New part development ",
      status_c_nc: "",
    },
    {
      clause_no: "10.1 & 10.3",
      audit_check_points: "Continual improvements ",
      what_to_look_and_record:
        "a) Verify continual imporvement initiated /  completed in last 6 months.",
      actual_obs: "Past 06 months improvements recorded ",
      status_c_nc: "",
    },
    {
      clause_no: 10.2,
      audit_check_points: "Corrective and Preventive action \n",
      what_to_look_and_record:
        "a) Proedure for taking CAPA with non conformities assocated with new product deveoopment \nb) Evidence of implementation of corrective actions taken in previous audit Non conformities ",
      actual_obs: "CAPA submitted for non confirmity ",
      status_c_nc: "",
    },
  ],
  MR: [
    {
      clause_no: 4.1,
      audit_check_points: "Organisation and its context",
      what_to_look_and_record:
        "Internal /  external Issues related to process, culture determined and monitored.",
      actual_obs: "Internal and external issues are determind and monitored ",
      status_c_nc: "",
    },
    {
      clause_no: 4.2,
      audit_check_points: " Interested party requirements",
      what_to_look_and_record:
        "a) Interested party identifed\nb) Each interested party requirement determined\n",
      actual_obs: "Intrested part=ies are defined with its requirements ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.1",
      audit_check_points: "Risks and opportunties",
      what_to_look_and_record:
        "Risk related to process determined\nRisk related to customer requirements",
      actual_obs: "Risk and opportunities are defind ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.2.2",
      audit_check_points: "Preventive action",
      what_to_look_and_record:
        "a) Process for preventive action\nb) evidance of preventive action if any.",
      actual_obs: "Preventive actions are defined against risks ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.2.3",
      audit_check_points: "Contingency plan",
      what_to_look_and_record:
        "a) available of contingency plan\nb) contingency testing frequency and record.\nC) IT releted contingency plan",
      actual_obs:
        "Contingency plans are defined customer wise against risks, Cyber attack also mention ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.1",
      audit_check_points: "Resources",
      what_to_look_and_record: "What resource provided to implement IATF",
      actual_obs: "IATF 16949:2016 implemented from URS certification body ",
      status_c_nc: "",
    },
    {
      clause_no: "7.5.1 & 7.5.1.1",
      audit_check_points: "Quality Manual ",
      what_to_look_and_record:
        "a) Apex Manual \nb) Processs referance\nc) Master list of documents \nd) Manual shall include scope and justification of exclusions, ",
      actual_obs:
        "Quality Manual is available  with process reference as per IATF 16949 Requirements and Master list of Documents maintained with Rev. No. Date and retainsion period ",
      status_c_nc: "",
    },
    {
      clause_no: "7.5.2",
      audit_check_points: "Document creation and updation",
      what_to_look_and_record:
        "a) Processs, work instructions approved by authority.\nB) document numbering",
      actual_obs:
        "Work instruction are made in local langauge and documents number, rev, no, and rev. date defined on each document ",
      status_c_nc: "",
    },
    {
      clause_no: "7.5.3",
      audit_check_points: "Control of documents ",
      what_to_look_and_record:
        "a) Documented process and its implementation \nb) document change request for revised documents\nc) Control of electronic data",
      actual_obs:
        "Procedure available for all documented process and process as per procedure ",
      status_c_nc: "",
    },
    {
      clause_no: 7.3,
      audit_check_points: "Infrastructure ",
      what_to_look_and_record:
        "a) Process for maintenance of computers, hardware, networks and associated equipments and its compliance \nb) Maintenance records of hardware and software \nc) Valid license of AMC agencies ",
      actual_obs:
        "3rd Party AMC for all Computor, Hardware and Software for National Computer Solution ",
      status_c_nc: "",
    },
    {
      clause_no: "7.5.3",
      audit_check_points: "Control of documents ",
      what_to_look_and_record:
        "\na) Back up data management\nb) Soft record retantion as per retention period.",
      actual_obs:
        "soft and Hard records as per retaintion period and Data backup in Hard disk for soft data ",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.1 & 6.2.2",
      audit_check_points: "Quality objectives /  actions ",
      what_to_look_and_record:
        "Objectives for MR Process and its achievement plan",
      actual_obs: "quality objectives and action implimented ",
      status_c_nc: "",
    },
    {
      clause_no: 5.3,
      audit_check_points: "Responsibility & authority",
      what_to_look_and_record:
        "a) Communication of QMS performance to top management\n",
      actual_obs:
        "Responsibility and Authority are defined for comunication of QMS ",
      status_c_nc: "",
    },
    {
      clause_no: "5.1.2",
      audit_check_points: "Customer Focus",
      what_to_look_and_record:
        "CSR defined for each customer, verify any two customers CSR",
      actual_obs: "CSR matrix defined as per customer requirements ",
      status_c_nc: "",
    },
    {
      clause_no: "9.2.2",
      audit_check_points: "Internal Audit ",
      what_to_look_and_record:
        "A) Documented process for Internal Audits and its compliance \nb) Internal audit reports including complaince and Non conformities  as per the schedule \nc) Follow up audit to verify the actions taken on NCRS\nd) Status of closure of the NCRs. ",
      actual_obs:
        "procedure is available for Internal audit audit criteria, NC submition period also mention in procedure \nInternal Audit plan, Audit reports, NC summary and its closure are available within time frame ",
      status_c_nc: "",
    },
    {
      clause_no: "9.2.2.1",
      audit_check_points: "Internal Audit plans ",
      what_to_look_and_record:
        "a) Manufacturing process  and product audit plan covering all the shifts, all process, last audit nc verification?\nB) QMS Audit schedules as per the audit plan \n ",
      actual_obs:
        "Internal audit plan in Shift A , Process and Product audit also done as per Plan ",
      status_c_nc: "",
    },
    {
      clause_no: "7.3.2.3",
      audit_check_points: "Internal Auditor qualification ",
      what_to_look_and_record:
        "a) Process for Internal Auditor qualification  critieria and its complaince \nb) List of qualified internal auditors \nc) Auditors core tool training record\nd) Auditors training certificates ",
      actual_obs:
        "Internal Auditor criteria is afdhare\n3rd party training provided to intarnal Auditors \nInternal Aditor List not updated ",
      "Status C / NC": "NC",
    },
    {
      clause_no: "9.3.1",
      audit_check_points: "Management Review ",
      what_to_look_and_record:
        "a) Process for Management Review and its compliance \nb) Frequency of MRM and its compliance \nc) MRM records ",
      actual_obs:
        "Procedure for MRM is available\nMRM Frequency is defined in MRM Plan Next MRM in Aug-22 \nRecords and MOM of MRM are available ",
      status_c_nc: "",
    },
    {
      clause_no: "9.3.2 & 9.3.2.1",
      audit_check_points: "Review input ",
      what_to_look_and_record:
        "a) MRM agendain place\nb) verify all inputs covered in agenda as per IATF standard",
      actual_obs: "MRM agenda defined as per IATF 16949 ",
      status_c_nc: "",
    },
    {
      clause_no: 10.2,
      audit_check_points: "Corrective and preventive action",
      what_to_look_and_record:
        "a) Documented process for Corrective action and its implementation \nb) Implementation of Corrective actions taken on previous audit NCRs ( external /  internal ) ",
      actual_obs: "Corrective and Preventive action recorded for MRM ",
      status_c_nc: "",
    },
  ],
  MNT_machine: [
    {
      clause_no: "5.2.2 & 7.3",
      audit_check_points:
        "Are the employees /  operators are aware of Quality Policy ? ",
      what_to_look_and_record:
        "Interview with any three operators /  employees and check the awareness ",
      actual_obs: "Interview of five operators found aware for Quality Policy ",
      status_c_nc: "",
    },
    {
      clause_no: 7.3,
      audit_check_points: "Awareness on QMS",
      what_to_look_and_record:
        "Relevent Quality objectives known to operators, verify with 3 peoples in department",
      actual_obs: "Operator aware regarding Quality Objectives ",
      status_c_nc: "",
    },
    {
      clause_no: "9.2.2 & 10.2",
      audit_check_points: "Last Internal audit NCR effectiveness",
      what_to_look_and_record: "a) QMS audit NCR and effectiveness",
      actual_obs: "Last Internal Audit NC verify found effective ",
      status_c_nc: "",
    },
    {
      clause_no: 4.4,
      audit_check_points: "QMS planning",
      what_to_look_and_record:
        "Is the maintenance process input and output is defined ?",
      actual_obs: "Procedure defined with Input and output ",
      status_c_nc: "",
    },
    {
      clause_no: 4.1,
      audit_check_points: "Organisation and its context",
      what_to_look_and_record:
        "Internal /  external Issues related to process determined and monitored.",
      actual_obs: "Internal and external issues are determind and monitored ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.1",
      audit_check_points: "Risks and opportunties",
      what_to_look_and_record: "Risk related to process determined",
      actual_obs: "Risk and Opportunuty is defind related to customer ",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.2",
      audit_check_points: "Action plan defined for each objective",
      what_to_look_and_record:
        "Action plan included-\na) What will be done;\nb) What resources will be required;\nc) Who will be responsible;\nd) When it will be completed;\ne) How the results will be evaluated.",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.1 & 9.1.1",
      audit_check_points:
        "Are the Effectiveness and Efficiency parameters  are documented, monitored and actions are taken when targets are not achieved ?",
      what_to_look_and_record:
        "a) process performance parameters with targets \nb) Performance trends from last 6 months.\nC) Record actual results for last 6 months . \n",
      actual_obs:
        "Process Parameters with targets is defined \nLast one Year Performance records and Trends are available ",
      status_c_nc: "",
    },
    {
      clause_no: 5.4,
      audit_check_points: "Responsibilities and authorities ",
      what_to_look_and_record:
        "a) Doducment indicating the responsibilities and authorities of Maintenace personnel \nb) Interview with them about their roles and responsibility ",
      actual_obs: "Responsibility and Authority is clearly defined ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1.5",
      audit_check_points: "Preventive and predictive maintenance ",
      what_to_look_and_record:
        "a) Procedure for Planned Maintenance and its compliance \nb) List of key process equipments \nc) PM plan for key process equipments \nd) Record of maintenance of key process equipments ( Verify for any 6 key equipments )\ne) Method of ensuring replacement parts for key equipments ",
      actual_obs:
        "Documented Process for Planned Maintenance \nList of key process equipments \nPM Record also available as per Plan \nList of Critical Spare parts are available ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.5",
      audit_check_points: "Control of Monitoring and measuring equipments. ",
      what_to_look_and_record:
        "a) Procedure for calibration /  verificaiton of plant process equipments \nb) Calibration /  Verification plan for plant proess equipments ",
      actual_obs:
        "Documented process for calibration of Plant testing facilities ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.5.2.1",
      audit_check_points: "Calibration /  verification records ",
      what_to_look_and_record:
        "a) Equipment identfication including the measuremnet standard against which the equipment is calibrated \nb) satement of conformity to spefifications after calibration /  verification ",
      actual_obs: "All equipment identified included Measuring Standard ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.2.3",
      audit_check_points: "contingency plan related to utilities",
      what_to_look_and_record:
        "Check Contingency plan defined\nCheck its testing plan and record if tested",
      actual_obs: "contingency plan is available for checking and testings ",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.3",
      audit_check_points: "Analysis of data ",
      what_to_look_and_record:
        "a) Data analysis and its use for continual improvement with respect to  Plant Maintenance ",
      actual_obs: "data analysing for improvements ",
      status_c_nc: "",
    },
    {
      clause_no: "10.3",
      audit_check_points: "Continual improvements ",
      what_to_look_and_record:
        "a) Verify continual imporvement initiated /  completed in last 6 months. ",
      actual_obs: "parts 06 month  data availlable of improvements ",
      status_c_nc: "",
    },
    {
      clause_no: "10.2",
      audit_check_points: "Corrective and Preventive action \n",
      what_to_look_and_record:
        "a) Documented procedure for Corrective and Preventive action\nb) Corrective action taken on break down issues and their implementation \n ",
      actual_obs: "Documented process for currective and preventive action ",
      status_c_nc: "",
    },
  ],
  purch: [
    {
      clause_no: "5.2.2 & 7.3",
      audit_check_points:
        "Are the employees /  operators are aware of Quality Policy ? ",
      what_to_look_and_record:
        " Interview with any three operators /  employees and check the awareness ",
      actual_obs: "Interview of five operators found aware for Quality Policy ",
      status_c_nc: "",
    },
    {
      clause_no: 7.3,
      audit_check_points: "Awareness on QMS",
      what_to_look_and_record:
        "Relevent Quality objectives known to operators, verify with 3 peoples in department",
      actual_obs: "Operator aware regarding Quality Objectives ",
      status_c_nc: "",
    },
    {
      clause_no: "9.2.2 & 10.2",
      audit_check_points: "Last Internal audit NCR effectiveness",
      what_to_look_and_record: "Last audit NCR and effectiveness\n",
      actual_obs: "Last Internal Audit NC verify found effective ",
      status_c_nc: "",
    },
    {
      clause_no: "4.4",
      audit_check_points: "QMS planning",
      what_to_look_and_record:
        "Is the manufacturing process flow with in put and output for core shop is defined ?",
      actual_obs: "Procedure defined with Input and output ",
      status_c_nc: "",
    },
    {
      clause_no: "4.1",
      audit_check_points: "Organisation and its context",
      what_to_look_and_record:
        "Internal /  external Issues rlated to process determined and monitored.",
      actual_obs: "Internal and external issues are determind and monitored ",
      status_c_nc: "",
    },
    {
      clause_no: "4.2",
      audit_check_points: " Interested party requirements",
      what_to_look_and_record:
        "a) Supplier as Interested party identifed\nb) Supplier requirement determined\n",
      actual_obs:
        "Intrested parties are defined and customer reqiuirements defined in CSR Matrix ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.1",
      audit_check_points: "Risks and opportunties",
      what_to_look_and_record: "Risk related to process determined",
      actual_obs: "Risk and Opportunuty is defind related to customer ",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.2",
      audit_check_points: "Action plan defined for each objective",
      what_to_look_and_record:
        "Action plan included-\na) What will be done;\nb) What resources will be required;\nc) Who will be responsible;\nd) When it will be completed;\ne) How the results will be evaluated.",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.1 & 9.1.1",
      audit_check_points:
        "Are the Effectiveness and Efficiency parameters  are documented, monitored and actions are taken when targets are not achieved ?",
      what_to_look_and_record:
        "a) E & E parameters with targets \nb) Performance trends from last 6 months.\nC) Record actual results for last 6 months . \n",
      actual_obs:
        "Process Parameters with targets is defined \nLast one Year Performance records and Trends are available ",
      status_c_nc: "",
    },
    {
      clause_no: 5.3,
      audit_check_points: "Responsibilities and authorities ",
      what_to_look_and_record:
        "a) Doducment indicating the responsibilities and authorities of purchase personnel \nb) Interview with them about their roles and responsibility ",
      actual_obs: "Responsibility and Authority is clearly defined ",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.1",
      audit_check_points:
        "Is the selection and approval criteria is established for core making suppiers ",
      what_to_look_and_record:
        "Verify the Process for selection and approval critiera for core suppliers \nRecord the Process No with date . ",
      actual_obs: "Supplier Selection Criteria is defined ",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.1",
      audit_check_points:
        "Are the supplier evaluation records are maintained for core making suppliers ",
      what_to_look_and_record:
        "Supplier quality and delivery rating reports from last 6 months  as per defined Processs ",
      actual_obs:
        "Supplier Evalution done and recorded as per plan ( Yearly ) for Non Customer Approved Suppliers \nevaluation not applicable for Customer Approved Supplier as per Procedure ",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.2",
      audit_check_points:
        "Are the cores supplied by the vendor are inspected before giving for production ",
      what_to_look_and_record:
        "Inward insepction records of the cores received from the suppliers ",
      actual_obs:
        "Incoming inspection donme for all RM supplier and Supplier PDIR also received with Lot ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.4.4",
      audit_check_points: "Product approval process ",
      what_to_look_and_record:
        "a) Procedure for PPAP at suppliers \nb) PPAP records of new products as recognized by the customer . ",
      actual_obs: "PPAP documents are available for Supplier ",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.1",
      audit_check_points: "Purchasing process ",
      what_to_look_and_record:
        "a) List of approved suppliers \nb) Criteria for selecting new machining suppliers \nc) Supplier approval records ",
      actual_obs:
        "List of Approved supplier is available with Supplier Registration form and Selection  records, Selection Cretira also defined ",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.2.3",
      audit_check_points: "Is theer a plan for supplier QMS development ,",
      what_to_look_and_record:
        "a) List of ISO certified suppliers and ISO validity status  \nb) Contact of supplier quality agreement \nc) Implementation of supplier manual \nd) Supplier IATF 16949 third party certification plan /  target date\ne) supplier audit plan and record",
      actual_obs:
        "All approved Supplier is ISO 9001 Certified, Certificate also Recorded \nSupplier Quality agreements are signed\nSupplier IATF 16949 third party certification plan /  target date",
      "Status C / NC": "NC",
    },
    {
      clause_no: "8.4.2.4",
      audit_check_points: "Supplier monitoring ",
      what_to_look_and_record:
        "a) Procedure for supplier monitoring and its compliance \nb) Supplier Quality and delivery rating \nc) Communication to the supplier about SQR and SDR. ",
      actual_obs:
        "Supplier Monitoring proocedure is avalable\nSupplier Quality and Delkivery Rating are monitoring ",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.1",
      audit_check_points: "Purcasing process ",
      what_to_look_and_record:
        "a) Purchasing Process and its compliance \nb) List of approved suppliers \nc) Any new suppliers and evaluation records \nd) Corrective action records on NCR noticed in supplier audits. ",
      actual_obs:
        "Purchasing Procedure are available, List of Approved Supplier is available\nCorrective action recorded against NCR",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.3",
      audit_check_points: "Purchasing information",
      what_to_look_and_record:
        "a) PO /  Schedule release to supplier\nb) Statutory requirements informed to supplier",
      actual_obs:
        "PO and Schedule released to supplier through mails and delivery rating also monitoring ",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.2.3",
      audit_check_points: "Is theer a plan for supplier QMS development ,",
      what_to_look_and_record:
        "a) List of ISO certified suppliers and ISO validity status  \nb) Contact of supplier quality agreement ",
      actual_obs:
        "All approved Supplier is ISO 9001 Certified, Certificate also Recorded \nSupplier Quality agreements are signed",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.3",
      audit_check_points: "Purchasing information ",
      what_to_look_and_record:
        "a) Process /  instructions for executing purchase orders and its compliance \nb) Purchase orders issued to following catogories \n* Raw material suppliers \n* Servicing \nCheck whether latest quality parameters and specifications mentioned in purchase order or not ",
      actual_obs:
        "Fix and Open both types of PO released to the supplier as per purchase requirements ",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.2.4 & 8.4.2.4",
      audit_check_points: "Supplier monitoring ",
      what_to_look_and_record:
        "a) Process for supplier monitoring and its compliance \nb) Supplier Quality and delivery rating from last 6 months \nc) Communication to the supplier about SQR and SDR. \nD) Action plan on supplier performance ",
      actual_obs:
        "Procedure for supplier monitoring is available and Yearly wise performance is monitoring, \nAction plan on supplier performance if supplier in Red Category ",
      status_c_nc: "",
    },
    {
      clause_no: "8.4.2.4.1 & 7.2.4",
      audit_check_points: "Supplier audit",
      what_to_look_and_record:
        "a) Check supplier audit plan covered automotive parts supplier\nb) Two supplier Audit report maintained as plan\nc) Supplier auditor competency record",
      actual_obs:
        "Supplier Audit recorded as per Plan, Yearly Plan is available for supplier Audits and Audit Criteria is defined ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.4.4",
      audit_check_points: "Product approval process ",
      what_to_look_and_record:
        "a) Process for PPAP at suppliers \nb) PPAP records of new products as recognized by the customer . ",
      actual_obs: "PPAP documents are available for Supplier ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.2.3",
      audit_check_points: "contingency plan related to supplier",
      what_to_look_and_record:
        "Check Contingency plan defined\nCheck its testing plan and record if tested",
      actual_obs: "Contingency plan is recorded for testing agencies ",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.3",
      audit_check_points: "Analysis of data ",
      what_to_look_and_record:
        "a) Data analysis and its use for continual improvement with respect to  supplier quality .",
      actual_obs: "Data analysis for improvement in Quality Rating",
      status_c_nc: "",
    },
    {
      clause_no: 10.2,
      audit_check_points: "Corrective and Preventive action \n",
      what_to_look_and_record:
        "a) Documented Process for Corrective and Preventive action\nb) Corrective action taken on Internal audit NCRs and their implementation \n ",
      actual_obs: "Corrective and Preventive Action recorded against NRc ",
      status_c_nc: "",
    },
  ],
  stores_and_dispatch: [
    {
      clause_no: "5.2.2 & 7.3",
      audit_check_points:
        "Are the employees /  operators are aware of Quality Policy ? ",
      what_to_look_and_record:
        " nterview with any three operators /  employees and check the awareness ",
      actual_obs: "Interview of five operators found aware for Quality Policy ",
      status_c_nc: "",
    },
    {
      clause_no: 7.3,
      audit_check_points: "Awareness on QMS",
      what_to_look_and_record:
        "Relevent Quality objectives known to operators, verify with 3 peoples in department",
      actual_obs: "Operator aware regarding Quality Objectives ",
      status_c_nc: "",
    },
    {
      clause_no: "9.2.2 & 10.2",
      audit_check_points: "Last Internal audit NCR effectiveness",
      what_to_look_and_record: "Last audit NCR and effectiveness\n",
      actual_obs: "Last Internal Audit NC verify found effective ",
      status_c_nc: "",
    },
    {
      clause_no: 4.1,
      audit_check_points: "Organisation and its context",
      what_to_look_and_record:
        "Internal /  external Issues rlated to process determined and monitored.",
      actual_obs: " Procedure defined with Input and output ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.1",
      audit_check_points: "Risks and opportunties",
      what_to_look_and_record: "Risk related to process determined",
      actual_obs: "Internal and external issues are determind and monitored ",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.2",
      audit_check_points: "Action plan defined for each objective",
      what_to_look_and_record:
        "Action plan included-\na) What will be done;\nb) What resources will be required;\nc) Who will be responsible;\nd) When it will be completed;\ne) How the results will be evaluated.",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.1 ",
      audit_check_points: "Quality objectives /  actions ",
      what_to_look_and_record:
        "a) E & E Parameters \nb) Trend from last 6 months \n",
      actual_obs: "Risk and Opportunuty is defind related to customer ",
      status_c_nc: "",
    },
    {
      clause_no: 5.3,
      audit_check_points: "Responsibilities and authorities ",
      what_to_look_and_record:
        "a) Doducment indicating the responsibilities and authorities of stores  personnel \nb) Interview with them about their roles and responsibility ",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: 7.4,
      audit_check_points:
        "Is the method of internal communication with respect to QMS is effective ",
      what_to_look_and_record:
        "a) Check communication ( internal and external ) about its effectiveness ",
      actual_obs: "Internal and external communication through Mails ",
      status_c_nc: "",
    },
    {
      clause_no: "8.1.1",
      audit_check_points: "Planning of product realization process ",
      what_to_look_and_record:
        "a) Procurement plan for raw materials and consumables \n",
      actual_obs:
        "Procurement Planing is available for RM as per Customer Schedule ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.4.1",
      audit_check_points: "Cleanliness of premises ",
      what_to_look_and_record: "a) General housekeeping ",
      actual_obs: "Cleanness is found Satifactory ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.4",
      audit_check_points:
        "Personnel safety to achieve conformity to product requirements ",
      what_to_look_and_record:
        "a) Use of PPEs by the operators /  staff as required \nb) MSDS of the hazardous chemicals displayed near the usage ",
      actual_obs: "Operators are use PPE as per Applicable ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.2.1 & 8.5.4",
      audit_check_points: "Identification and traceability ",
      what_to_look_and_record:
        "a) Tags on the raw materials /  consumables indicating the acceptance /  rejection /  Under deviation /  Under insepction status and traceable to suppliers challan /  GRN ",
      actual_obs:
        "Tags and Traceability are available in Consumable and Rejected Items are identified ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.5.2",
      audit_check_points: "Calibration /  verification records",
      what_to_look_and_record:
        "a) Calibration records of weighing balances used in the stores . ",
      actual_obs: "3rd Party calibration done of weighing machine yearly ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.4 & 7.1.4.1",
      audit_check_points: "Is the work environment is maintained in the shop  ",
      what_to_look_and_record:
        "a) Check the cleanliness in the store required to prodcut and process\nb) Houskeeping audit reports \nc) Actions for the improvement \n",
      actual_obs:
        "Cleanness is good in store area and 5s Audit done and recorded ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.4.1",
      audit_check_points: "Storage and inventory",
      what_to_look_and_record:
        "a) Internal process for accessing the product which is under storage and invenoty",
      actual_obs: "Process available for Store and inventory ",
      status_c_nc: "",
    },
    {
      clause_no: 8.7,
      audit_check_points: "Control of non conforming product ",
      what_to_look_and_record:
        "a) Documented Process for Non conforming products \nb) Area for keeping rejected /  under deviated materials \nc) Deviation records of the materials accepted under deviation . ",
      actual_obs:
        "Non confirning parts are identified and Deviation form signed if required for any items ",
      status_c_nc: "",
    },
    {
      clause_no: 10.3,
      audit_check_points: "Continual Improvements ",
      what_to_look_and_record:
        "Continual improvements initated in last 6 months ",
      actual_obs: "past 06 Months improvements is avialable ",
      status_c_nc: "",
    },
    {
      clause_no: 10.2,
      audit_check_points: "Corrective and Preventive action \n",
      what_to_look_and_record:
        "a) Documented Process for Corrective and Preventive action\nb) Corrective action taken on Internal audit NCRs and their implementation \n ",
      actual_obs: "Action Plan available against NCs ",
      status_c_nc: "",
    },
  ],
  HR: [
    {
      clause_no: "5.2.2 & 7.3",
      audit_check_points:
        "Are the employees /  operators are aware of Quality Policy ? ",
      what_to_look_and_record:
        " Interview with any three operators /  employees and check the awareness ",
      actual_obs: "Interview of five operators found aware for Quality Policy ",
      status_c_nc: "",
    },
    {
      clause_no: "5.2.2",
      audit_check_points: "Policy communication",
      what_to_look_and_record: "How policy communicated internally",
      status_c_nc: "",
    },
    {
      clause_no: 7.3,
      audit_check_points: "Awareness on QMS",
      what_to_look_and_record:
        "Relevent Quality objectives known to operators, verify with 3 peoples in department",
      actual_obs: "Operator aware regarding Quality Objectives ",
      status_c_nc: "",
    },
    {
      clause_no: "9.2.2 & 10.2",
      audit_check_points: "Last Internal audit NCR effectiveness",
      what_to_look_and_record: "Last audit NCR and effectiveness\n",
      actual_obs: "Last Internal Audit NC verify found effective ",
      status_c_nc: "",
    },
    {
      clause_no: 4.4,
      audit_check_points: "QMS planning",
      what_to_look_and_record:
        "Is the manufacturing process flow with in put and output for core shop is defined ?",
      actual_obs: "Procedure defined with Input and output ",
      status_c_nc: "",
    },
    {
      clause_no: 4.1,
      audit_check_points: "Organisation and its context",
      what_to_look_and_record:
        "Internal /  external Issues rlated to process determined and monitored.",
      actual_obs: "Internal and external issues are determind and monitored ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.1",
      audit_check_points: "Risks and opportunties",
      what_to_look_and_record: "Risk related to process determined",
      actual_obs:
        "Intrested parties are defined and customer reqiuirements defined in CSR Matrix ",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.2",
      audit_check_points: "Action plan defined for each objective",
      what_to_look_and_record:
        "Action plan included-\na) What will be done;\nb) What resources will be required;\nc) Who will be responsible;\nd) When it will be completed;\ne) How the results will be evaluated.",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.1 & 9.1.1",
      audit_check_points:
        "Are the Effectiveness and Efficiency parameters  are documented, monitored and actions are taken when targets are not achieved ?",
      what_to_look_and_record:
        "a) E & E parameters with targets \nb) Performance trends from last 6 months.\nC) Record actual results for last 6 months . \n",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.3",
      audit_check_points: "perofrmance data anaysis and evaluation",
      what_to_look_and_record:
        "Verify process performance data analaysis done, action plan evaluation and related record maintained.",
      actual_obs:
        "Process Parameters with targets is defined \nLast one Year Performance records and Trends are available ",
      status_c_nc: "",
    },
    {
      clause_no: 5.3,
      audit_check_points: "Responsibilities and authorities ",
      what_to_look_and_record:
        "a) Doducment indicating the responsibilities and authorities of HR and training personnel \nb) Interview with them about their roles and responsibility ",
      actual_obs: "Role and Responsibility clearly defined ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.6",
      audit_check_points: "Organisational Knowledge",
      what_to_look_and_record:
        "a)Verify how organisational knowledge made available to employee\nb) check induction training of new employee for making aware organisation.",
      actual_obs:
        "Induction training for new employee is done as per requirements ",
      status_c_nc: "",
    },
    {
      clause_no: 7.4,
      audit_check_points:
        "Is the method of internal communication with respect to QMS is effective ",
      what_to_look_and_record:
        "a) Check communication ( internal and external ) about its effectiveness ",
      actual_obs:
        "Internal and external comunication thfrough mails and WhatsApp Groups ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.2",
      audit_check_points: "Human Resource",
      what_to_look_and_record: "a) Process for recrutiment and its compliance ",
      actual_obs: "Procedure defined for recutiments and adhere ",
      status_c_nc: "",
    },
    {
      clause_no: "7.2, & 7.2.1",
      audit_check_points: "Competene, training",
      what_to_look_and_record:
        "a) Process for determining competency for personnel performing work affecting conformity to product requirements and its compliance \nb) Process for determining skill levels of the operators \nc) Annual training calender for staff and operators \nd) Training records as per the plan including attendance sheet and training feed back . \ne) Record of the effectiveness of the training given",
      actual_obs:
        "Operator competency is defined and training also conducted accordingly ",
      status_c_nc: "",
    },
    {
      clause_no: "7.2.2",
      audit_check_points: "On job training",
      what_to_look_and_record:
        "a) Verify On job training given to contract employee\nb) Induction training record of new joined person",
      actual_obs: "On job tarining provided to operators ",
      status_c_nc: "",
    },
    {
      clause_no: "7.3.2",
      audit_check_points: "Employee motivation and empowerment ",
      what_to_look_and_record:
        "a) Process for employee motivation and its compliance ",
      actual_obs:
        "Kaizen scheme is inplace for employee motivation and Employee of the month and Employee of the Year is Awarded ",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.3",
      audit_check_points: "Analysis of data ",
      what_to_look_and_record:
        "a) Data analysis and its use for continual improvement with respect to  Training feed back /  evaluation.",
      actual_obs: "Data analysis for improvements ",
      status_c_nc: "",
    },
    {
      clause_no: 10.2,
      audit_check_points: "Corrective and Preventive action \n",
      what_to_look_and_record:
        "a) Documented Process for Corrective and Preventive action\nb) Corrective action taken on Internal audit NCRs and their implementation .\n ",
      actual_obs: "corrective and preventive action recorded against NCr",
      status_c_nc: "",
    },
  ],
  production: [
    {
      clause_no: "5.2.2 & 7.3",
      audit_check_points:
        "Are the employees /  operators are aware of Quality Policy ? ",
      what_to_look_and_record:
        " Interview with any three operators /  employees and check the awareness ",
      actual_obs: "Interview of five operators found aware for Quality Policy ",
      status_c_nc: "",
    },
    {
      clause_no: 7.3,
      audit_check_points: "Awareness on QMS",
      what_to_look_and_record:
        "Relevent Quality objectives known to operators, verify with 3 peoples in department",
      actual_obs: "Operator aware regarding Quality Objectives ",
      status_c_nc: "",
    },
    {
      clause_no: "9.2.2",
      audit_check_points: "Last Internal audit NCR effectiveness",
      what_to_look_and_record: "Last audit NCR and effectiveness\n",
      actual_obs: "Last Internal Audit NC verify found effective ",
      status_c_nc: "",
    },
    {
      clause_no: "4.4",
      audit_check_points: "QMS planning",
      what_to_look_and_record:
        "Is the manufacturing process flow with in put and output for core shop is defined ?",
      actual_obs: "Procedure defined with Input and output ",
      status_c_nc: "",
    },
    {
      clause_no: "4.1",
      audit_check_points: "Organisation and its context",
      what_to_look_and_record:
        "Internal /  external Issues rlated to process determined and monitored.",
      actual_obs: "Internal and external issues are determind and monitored ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.1",
      audit_check_points: "Risks and opportunties",
      what_to_look_and_record: "Risk related to process determined",
      actual_obs:
        "Intrested parties are defined and customer reqiuirements defined in CSR Matrix ",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.2",
      audit_check_points: "Action plan defined for each objective",
      what_to_look_and_record:
        "Action plan included-\na) What will be done;\nb) What resources will be required;\nc) Who will be responsible;\nd) When it will be completed;\ne) How the results will be evaluated.",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.1 & 9.1.1",
      audit_check_points:
        "Are the Effectiveness and Efficiency parameters  are documented, monitored and actions are taken when targets are not achieved ?",
      what_to_look_and_record:
        "a) E & E parameters with targets \nb) Performance trends from last 6 months.\nC) Record actual results for last 6 months . \n",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.3",
      audit_check_points: "perofrmance data anaysis and evaluation",
      what_to_look_and_record:
        "Verify process performance data analaysis done, action plan evaluation and related record maintained.",
      actual_obs:
        "Process Parameters with targets is defined \nLast one Year Performance records and Trends are available ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.2.3",
      audit_check_points: "contingency plan related to key equipment failuer",
      what_to_look_and_record:
        "Check Contingency plan defined\nCheck its testing plan and record if tested",
      actual_obs: "Contingengy Plan available for production related issues ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.4 & 7.1.4.1",
      audit_check_points: "Is the work environment is maintained in the shop  ",
      what_to_look_and_record:
        "a) Check the cleanliness in the shop required to prodcut and process\nb) Houskeeping audit reports \nc) Actions for the improvement \n",
      actual_obs:
        "Cleanness of shop floor found satisfactory and 5s Audit and its closure is recorded ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1.7",
      audit_check_points: "Production scheduling",
      what_to_look_and_record:
        "a) Documented procedure for Planning and scheduling the production activities and its compliance \nb) Sales Plan vs production qty (verify for atleast from last 6 months ). ",
      actual_obs:
        "Procedure available for Production planing, Plan vs Actual production also recorded with failure action plans and past 06 mont data of sale achievemnt is recorded for improvements ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1, 8.5.1.7",
      audit_check_points: "Production control",
      what_to_look_and_record:
        "a) Is prodcution plan available and known to operator\nb) Is operator using calibrated monitoring and measuring equipments\nc) Prodcution plan and actul record maintained.",
      actual_obs:
        "Daily production Plan is available for each section and plan vs actual production also recorded .\nCalibrated instruments are using during production and inspection ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1, 7.1.2, 7.2, ",
      audit_check_points: "Competetnt manapower in prodcution",
      what_to_look_and_record:
        "a) check competent operator working in production\nb) Check competency level to requirement\nc) verify record of competency.",
      actual_obs:
        "Competency level of operators are defined and Skill matrix is displayed in each section with Skill level ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1.2 & 7.5.3.1",
      audit_check_points:
        "Are the work instructions which effect process /  product quality are accessible at  all work station ",
      what_to_look_and_record:
        "Check the accessibiulity  of following documents - Record the doc No with date  \n ",
      actual_obs:
        "Work Instruction displayed at every station and its Document no. defined ",
      status_c_nc: "",
    },
    {
      clause_no: "7.5.3.2",
      audit_check_points: "Document & record control control",
      what_to_look_and_record:
        "a) Check Work instruction controlled as per document control procedure\nb) check any two records retained as per retention period",
      actual_obs:
        "Work instruction retain as per retaintion period \nControlled Copy Stamp not Available on some Work Instructions ",
      "Status C / NC": "NC",
    },
    {
      clause_no: "8.5.1.3",
      audit_check_points:
        "Is set up is performed as per the work instructions and records maintained ",
      what_to_look_and_record:
        "Check the records of set up parameters mentioned in the instructions during pdc process \n\n",
      actual_obs:
        "Setup Approval done and recorded at the time of setting and Shift starting ",
      status_c_nc: "",
    },
    {
      clause_no: "10.2.1",
      audit_check_points:
        "Are the quality alerts displayed in the shop with respect to Customer complaints ?",
      what_to_look_and_record:
        "Verify the quality alerts in the shop about the customer complaints relating core making process ",
      actual_obs:
        "Internal and external issues related Quality Alert displayed in Shop Floor ",
      status_c_nc: "",
    },
    {
      clause_no: 7.4,
      audit_check_points: "Are the internal communication is found effective ",
      what_to_look_and_record:
        "Verify the shift log books and communication method during the shift change hours ",
      actual_obs:
        "Internal and external communication through mails and WhatsApp groups ",
      status_c_nc: "",
    },
    {
      clause_no: "7.4.1",
      audit_check_points:
        "Are the operators wear PPEs as defined in the procedure ?",
      what_to_look_and_record:
        "Check wether Operators /  staff are using Helmets, nose masks, safety shoes, handgloves etc….as specified in the instructions . ",
      actual_obs: "Operator using PPE as per requirements in PPE matrix ",
      status_c_nc: "",
    },
    {
      clause_no: 7.2,
      audit_check_points:
        "Is the updated skill matrix is available for the operators. Are the training needs identified based on the gaps in the skill levels ",
      what_to_look_and_record:
        "Min Skill and actual skill levels of the operators . \nVerify for any three operators\nIf the gap between specified and actual, verify whether training needs identified ",
      actual_obs:
        "Actual and Min. Skill level of operator is defined \nTNI defined as per requirements ",
      status_c_nc: "",
    },
    {
      clause_no: "7.2.1, 7.2.2",
      audit_check_points: " Training need for current year",
      what_to_look_and_record:
        "a) Verify training need determined for current year\nb) What action defined taken to achive competency \nc) On job training provided to contract employee",
      actual_obs:
        "TNI Defined for Operators as well Staff \non Job training also recorded ",
      status_c_nc: "",
    },
    {
      clause_no: 7.3,
      audit_check_points: "Awareness of employee on product quality",
      what_to_look_and_record:
        "Verify three employees for aware\na) of their work impact on product quality\nb) risk involved on NC product shipped to customer",
      actual_obs:
        "Employee aware regarding Product quality and Risk with impact of failure ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.2",
      audit_check_points: "Indentification and traceability ",
      what_to_look_and_record:
        "Are the suitbale indentification and traceability is maintained in the shop  for parts and WIP material during production. ",
      actual_obs:
        "Route card implemented for Traceabilty and Traceability sheet also monitoring ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1.4",
      audit_check_points: "Planned /  unplanned shut down record",
      what_to_look_and_record:
        "Is ther any records when restert of process after Planned /  unplanned shut down.",
      actual_obs: "Plant shutdown is recorded ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.6",
      audit_check_points:
        "Is there a process of change management and control . Are the changes caused by any supplier are assessed and verified, validated to ensure compliance with customer requirements ",
      what_to_look_and_record:
        "a) Change management process Process and its compliance witth respect process /  material /  supplier changes \nb) Validation report of the changes . ",
      actual_obs:
        "Change managements is inplaced \nas per defined criteria and abnormal situation ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.6.1.1",
      audit_check_points: "Temperary change control",
      what_to_look_and_record:
        "a) Process for Temperary change control defined \nb) list of the process controls and the approved\nback-up or alternate methods",
      actual_obs:
        "Planned and Unplanned change is montoring and backup plan also available for temperory changes ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1",
      audit_check_points:
        "Validation of processes for production and service provision ",
      what_to_look_and_record:
        "Internal Process for process validation and its compliance \n1) Process Validation plan \n2) Process validation reports ",
      actual_obs:
        "Yearly Process Validation Plan and Record also avalable as per plan ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1",
      audit_check_points: "Prodcution control",
      what_to_look_and_record:
        "Are the production ouput is meeting planning requirements . Are reasons /  corrective actions recorded when ouput is not meeting input requirements ",
      actual_obs:
        "Production output meeting planning requirements record also available ",
      status_c_nc: "",
    },
    {
      clause_no: "5.3.2",
      audit_check_points:
        "Are the employees are authorized to stop the production to correct the quality problmes ",
      what_to_look_and_record:
        "Interview with the any three operators /  staff whether they have authoritiy to stop the production on any quality issues. ",
      actual_obs: "Employee and Staff also aware regarding Quality issue ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1.1 ",
      audit_check_points:
        "Are the control plans are available and impelemented for the products manufactured . ",
      what_to_look_and_record:
        "Check the availiblity of control plans for products which are under production.\nCheck that review frequency is defined & accordingly review is carried out.",
      actual_obs: "Process Stanmdards and Process Sheets as per Control plan ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1 ",
      audit_check_points:
        "Are the PFMEA's are available for the products manufactured.",
      what_to_look_and_record:
        "Check the availiblity of PFMEA for products which are under production.\nCheck that review frequency is defined & accordingly review is carried out.",
      actual_obs:
        "Process FMEA available for all peocess defined in contol plan ",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.1",
      audit_check_points:
        "Are the process parameters are monitored and measured and actions initated when the results are not meeting the specifications ",
      what_to_look_and_record:
        "Min samples : 2\nVerify and record Set up parameters  mentioned in work instructions and actual values verified for above products ",
      actual_obs: "05 Sample verify at time of setup Approval ",
      status_c_nc: "",
    },
    {
      clause_no: "8.6 & 8.6.1",
      audit_check_points:
        "Are the inprocess inspection is done as per the work instructions /  control plans and record of evidence of conformity with acceptance critiera is maintained . ",
      what_to_look_and_record:
        "\nVerify following records against specificaton\na) Dimensional record of the parts \nb) Process parameters\nc) Visual inspection record ",
      actual_obs:
        "Hourly Dimensional, Process Parameters and visual inspection of parts during production is recorded ",
      status_c_nc: "",
    },
    {
      clause_no: "10.1 & 10.3",
      audit_check_points:
        "Is the evidence of continual improvements /  manufacturing process improvements . ",
      what_to_look_and_record:
        "Continual improvements done in last 6 months . \nRecord the improvements ",
      actual_obs: "Past 06 Months improvements is recorded ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.6.1",
      audit_check_points:
        "Are the process changes /  document changes are tracked and moniotored . ",
      what_to_look_and_record:
        "Changes in procxess /  documents from last 6 months. \na) Process change note approval \nb) Document change note approval ",
      actual_obs:
        "Process change only after customer approval ( Recetly No Process is Change ) \nRevision history is available for documents ",
      status_c_nc: "",
    },
    {
      clause_no: "8.7.1, 8.7.1.2 & 8.7.1.3",
      audit_check_points: "Non conformity Management",
      what_to_look_and_record:
        "a) Is non conforming product identified and kept saperatly.\nb) record of non conforming product and decision taken on NC available\nc) any customer specified control required on Nc product and followed\nd) wherify any supeted product and ask to move it to ensure treatment as NC part.",
      actual_obs:
        "Rejected  part located in different lacation from process and Identify \ndaily Red Bin Meeting is conduction for improvement in inhouse rejection ",
      status_c_nc: "",
    },
    {
      clause_no: "8.7.1.4 & 8.7.1.5",
      audit_check_points: "Control over rework & repair product",
      what_to_look_and_record:
        "a) verify rework /  repair done and its work instruction\nb) Record of rework /  repair and reinspection as per control plan\nc) traceability of reworked /  repaired product",
      actual_obs:
        "Rework part located in different lacation from process and Identify \ndaily Red Bin Meeting is conduction for improvement in inhouse rrework",
      status_c_nc: "",
    },
    {
      clause_no: "10.2.2",
      audit_check_points:
        "Are the corrective actions records available , if quality /  delivery performance of the core vendor is not meeting the requirements ",
      what_to_look_and_record:
        "Corrective action record from the suppliers when quality and delivery rating is not meeting the specified requirements ",
      actual_obs:
        "Corrective and Preventive action recorded for TOP contributor on Monthly Basis as per Pareto Chats ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.3",
      audit_check_points:
        "Are the customer Tooling  are permenantly marked ti enable the ownership status ",
      what_to_look_and_record:
        "\nCheck physically on the shop , whether customer supplied tools  are permeanently marked enablihing the ownership of the customer . \n",
      actual_obs: "Customer Property not identified ",
      "Status C / NC": "NC",
    },
    {
      clause_no: "7.1.5.2",
      audit_check_points:
        "Are the gauges /  measuring equipments are identified with calibration status ",
      what_to_look_and_record:
        "Calibration stickers on gauges, scratch hardness testers, temp indicators , pressure gauges etc…. \nRecord the validity date and last calibrated date. ",
      actual_obs:
        "Calibration stickers and colour code are available on all measuring Equepments ",
      status_c_nc: "",
    },
    {
      clause_no: "CSR",
      audit_check_points: "4M data validation:",
      what_to_look_and_record:
        "Daily 4M change and summary sheet to be validate.",
      actual_obs: "Daily 4M summary updated and Disply on 4M change Board",
      status_c_nc: "",
    },
  ],
  QA: [
    {
      clause_no: "5.2.2 & 7.3",
      audit_check_points:
        "Are the employees /  operators are aware of Quality Policy ? ",
      what_to_look_and_record:
        " Interview with any three operators /  employees and check the awareness ",
      actual_obs: "Interview of five operators found aware for Quality Policy ",
      status_c_nc: "",
    },
    {
      clause_no: 7.3,
      audit_check_points: "Awareness on QMS",
      what_to_look_and_record:
        "Relevent Quality objectives known to operators, verify with 3 peoples in department",
      actual_obs: "Operator aware regarding Quality Objectives ",
      status_c_nc: "",
    },
    {
      clause_no: "8.2.2",
      audit_check_points: "Last Internal audit NCR effectiveness",
      what_to_look_and_record:
        "b) Manufacturing process audit NCR and effectiveness\nc) Product audit NCR and effectiveness",
      actual_obs: "Last Internal Audit NC verify found effective ",
      status_c_nc: "",
    },
    {
      clause_no: 4.4,
      audit_check_points: "QMS planning",
      what_to_look_and_record:
        "Is the manufacturing process flow with in put and output for core shop is defined ?",
      actual_obs: "Procedure defined with Input and output ",
      status_c_nc: "",
    },
    {
      clause_no: 4.1,
      audit_check_points: "Organisation and its context",
      what_to_look_and_record:
        "Internal /  external Issues rlated to process determined and monitored.",
      actual_obs: "Internal and external issues are determind and monitored ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.1",
      audit_check_points: "Risks and opportunties",
      what_to_look_and_record: "Risk related to process determined",
      actual_obs:
        "Intrested parties are defined and customer reqiuirements defined in CSR Matrix ",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.2",
      audit_check_points: "Action plan defined for each objective",
      what_to_look_and_record:
        "Action plan included-\na) What will be done;\nb) What resources will be required;\nc) Who will be responsible;\nd) When it will be completed;\ne) How the results will be evaluated.",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "6.2.1 & 9.1.1",
      audit_check_points:
        "Are the Effectiveness and Efficiency parameters  are documented, monitored and actions are taken when targets are not achieved ?",
      what_to_look_and_record:
        "a) E & E parameters with targets \nb) Performance trends from last 6 months.\nC) Record actual results for last 6 months . \n",
      actual_obs:
        "Action done;\nb) What resources c) Who will be responsible;\nd) When it will be completed;\ne) How the results will  are determind plan evaluated",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.3",
      audit_check_points: "perofrmance data anaysis and evaluation",
      what_to_look_and_record:
        "Verify process performance data analaysis done, action plan evaluation and related record maintained.",
      actual_obs:
        "Process Parameters with targets is defined \nLast one Year Performance records and Trends are available ",
      status_c_nc: "",
    },
    {
      clause_no: "6.1.2.3",
      audit_check_points: "contingency plan related to",
      what_to_look_and_record:
        "Check Contingency plan defined\nCheck its testing plan and record if tested",
      actual_obs: "Contingengy Plan available for production related issues ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.4 & 7.1.4.1",
      audit_check_points: "Is the work environment is maintained in the shop  ",
      what_to_look_and_record:
        "a) Check the cleanliness in the shop required to prodcut and process\nb) Houskeeping audit reports \nc) Actions for the improvement \n",
      actual_obs:
        "Cleanness of shop floor is found satisfactory \n5s Audit done as per plan ",
      status_c_nc: "",
    },
    {
      clause_no: "8.5.1.2",
      audit_check_points:
        "Are the work instructions which effect process /  product quality are accessible  at work station ",
      what_to_look_and_record:
        "Check the accessibiulity  of following documents - \nLaboratory work instruction, \nLayout inspection \n",
      actual_obs:
        "3rd Party Laboratory using for testings \nLayout Reports available as per Plan ",
      status_c_nc: "",
    },
    {
      clause_no: "8.6.4 & 8.6.5",
      audit_check_points:
        "Does the process to assure the quality of purchased product is established, implemented and maintained ",
      what_to_look_and_record:
        "a) Incoming raw material inspection procedure and its implementation \nb) Raw material inspection reports \nc) Supplier TC - and results of the material as per the specifications \nd) Statutory & regulatory compliance of incoming parts",
      actual_obs:
        "Procedure available for incoming inspection \nIncoming inspection done for all purchased item and MTC and Mill TC received from supplier with material ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.5.1.1",
      audit_check_points: "Measurement System Anaylysis ",
      what_to_look_and_record:
        "a) MSA Plan \nb) Check whether all the equipments /  guages mentioned in control plan are taken in MSA plan \nc) Verify compliance to the plan \nd) Verify the analysis results whether complying to MSA 4th edtion \nd) Work instructions /  procedure to carry out MSA ",
      actual_obs:
        "Documented Process for MSA \nMSA plan available for Attribute and Variaval also \nMSA study available for all measuring instruments ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.5",
      audit_check_points: "Control of monitoring and measuring equipments ",
      what_to_look_and_record:
        "a) Calibration procedure and its compliance \nb) Annual calibration plan \nc) Calibration reports \nd) Calibration status on the equipments ",
      actual_obs:
        "Calibration procedure available\nYearly calibration plan is in placed \nreports also recorded as per Plan \nCalibration through NABL certified Laboratory ",
      status_c_nc: "",
    },
    {
      clause_no: "7.1.5.1.2",
      audit_check_points: "Calibration /  verification records ",
      what_to_look_and_record:
        "a) Calibration records indicating following\n* Identiication of the equipments \n* Standard used calibating the equipments \n* revisions following the engineering changes \n* Statement of conformity of the calibration results ",
      actual_obs:
        "\nYearly calibration plan is in placed \nreports also recorded as per Plan \nCalibration through NABL certified Laboratory ",
      status_c_nc: "",
    },
    {
      clause_no: "7.5.1.3.1",
      audit_check_points: "Laboratory scope ",
      what_to_look_and_record:
        "a) Lab Scope and its complaince \nb) Record of Laboratory personnel \n",
      actual_obs:
        "3rd Party Laboratory using for testings \nScope are available ",
      status_c_nc: "",
    },
    {
      clause_no: "7.5.1.3.2",
      audit_check_points: "External Laboratory ",
      what_to_look_and_record:
        "a) Scope of external laboratory \nb) If certified by NABL, copy of valid certificate \nc) Verify whether tests /  calibration carried out are covered in NABL scope or not. ",
      actual_obs:
        "NABL certified Laboratory used for 3rd party testing Copy of valid NABL certificate is available ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.5.2",
      audit_check_points: "Statistical Process control",
      what_to_look_and_record:
        "Statistical concepts, such as variation, control (stability), process capability, and the consequences of over-adjustment, are used precisely  by employees involved in the collection, analysis, and management of statistical data.",
      actual_obs:
        "Documented Process for SPC \nSPC plan available for all Critical Dimension defined in customer drawing \n",
      status_c_nc: "",
    },
    {
      clause_no: "9.2.2.4",
      audit_check_points: "Process & product Audits ",
      what_to_look_and_record:
        "a) Procedure for conducting process / product audits \nb) process / product audits plan for casting and machining \nc) Process / product audits reports for running process\nd) Corrective action - verification of the NCRs noticed in Product audit \ne) Verify whether product audit report includes product dimensions, funcationality, packaging, labelling ",
      actual_obs:
        "Process and Product Audit Procedure is available \nAudit records as per Plan",
      status_c_nc: "",
    },
    {
      clause_no: "8.6.2",
      audit_check_points: "Layout Inspection ",
      what_to_look_and_record:
        "a) Procedure reference for Layout inspection \nb) Layout inspection of casting and machined components \nc) Layout insepction reports \nd) Corrective action when parameters are not met . ",
      actual_obs:
        "Procedure for Layout inspection is available \ninspection reports also available as per Plan ",
      status_c_nc: "",
    },
    {
      clause_no: "9.1.3",
      audit_check_points: "Analysis of data ",
      what_to_look_and_record:
        "a) Data analysis and its use for continual improvement with respect to customer complaints, returns, supplier quality.",
      actual_obs:
        "Data analysing Customer Complaint, Inhouse rejection, Rework and Sale returns for continual Improvements ",
      status_c_nc: "",
    },
    {
      clause_no: 10.2,
      audit_check_points: "Customer complaint and corrective action",
      what_to_look_and_record:
        "a) Action plan on customer comaplint\nb) record of action result.\nC) use of error profing /  pokayoke if any.",
      actual_obs:
        "Customer Complaint Logbook Available and action plan also evidenced as per specified period ",
      status_c_nc: "",
    },
    {
      clause_no: "10.2.3",
      audit_check_points: "Problem solving",
      what_to_look_and_record:
        "a) Documented process\nb) Use of customer format to solve problem\nc) Updation of control plan & FMEA",
      actual_obs:
        "Coustomer format used for Action plan and other related documents ",
      status_c_nc: "",
    },
    {
      clause_no: "10.1 & 10.3",
      audit_check_points: "Continual improvements ",
      what_to_look_and_record:
        "a) Verify continual imporvement initiated /  completed in last 6 months. ",
      actual_obs: "Past 06 months improvements is recorded ",
      status_c_nc: "",
    },
    {
      clause_no: "10.2 & 6.1.2.2",
      audit_check_points: "Corrective and Preventive action \n",
      what_to_look_and_record:
        "a) Documented procedure for Corrective and Preventive action\nb) Procedure for customer complaint handling and its complinace \nc) Customer complaints log and CAPA \nd) Corrective action taken on Internal audit NCRs and their implementation \ne) Procedure for Problem solving and its compliance ",
      actual_obs:
        "Documented process for action plan against non confirmities ",
      status_c_nc: "",
    },
    {
      clause_no: "10.2.6",
      audit_check_points: "Rejected product test /  analysis ",
      what_to_look_and_record:
        "a) Documented procedure for handling customer returns and its complaince \nb) CAPA action record on customer returned material ",
      actual_obs:
        "Documented process for sale returns \nand action plan also evidenced ",
      status_c_nc: "",
    },
    {
      clause_no: "8.7.1.4",
      audit_check_points: "Control of rework product",
      what_to_look_and_record:
        "Documented procedure of Rework, disposition of reworked product including quantity, disposition, disposition date, analysis against PPM beyond the defined target ",
      actual_obs:
        "Documented process for Rework Handling \nseparate area defined for rework material with identification ",
      status_c_nc: "",
    },
    {
      clause_no: "8.3.4.4",
      audit_check_points: "Product Approval Process ",
      what_to_look_and_record:
        "a) Documented procedure for PPAP and its implementation evidenace\nb) approved PSW",
      actual_obs:
        "Documented process for PPAP documents \nApproved PSW also in place ",
      status_c_nc: "",
    },
  ],
  CSR: [
    {
      std_req: "Control of Documents",
      gabriel: "As per IATF 16949",
      tenneco: "As per IATF 16949",
      mark_exhaust: "As per IATF 16949",
      audit_obs: "Found OK as per IATF ",
      status_c_nc: "",
    },
    {
      std_req: "Control of Records",
      gabriel: "As per IATF 16949",
      tenneco:
        "PO- 15 years\nDrawings- 15 years\nPPAP- 15 years\nProd/ quality records- 15 years",
      mark_exhaust: "As per IATF 16949",
      audit_obs: "Found OK as per IATF ",
      status_c_nc: "",
    },
    {
      std_req: "Customer Representative",
      gabriel: "Yes (Yogendra, M.M. Mishra)",
      tenneco: "Yes (Yogendra, M.M. Mishra)",
      mark_exhaust: "Yes (Yogendra, M.M. Mishra)",
      audit_obs: "Yogendra and M.M. Mishra are Customer Representative ",
      status_c_nc: "",
      Column10: "rev",
      Column11: "dated",
      Column12: "detail",
    },
    {
      std_req: "Management Review",
      gabriel: "As defined in system",
      tenneco: "As defined in system",
      mark_exhaust: "As defined in system",
      audit_obs: "MRM conduction Trice in Year as per Plan ",
      status_c_nc: "",
      Column10: 1,
      Column11: "10.2.21",
      Column12: "amendments as per quality manual rcvd from tenneco",
    },
    {
      std_req: "Training",
      gabriel: "As per requirement",
      tenneco: "As per requirement",
      mark_exhaust: "As per requirement",
      audit_obs: "Training record as per Plan found OK ",
      status_c_nc: "",
    },
    {
      std_req: "Plant Facility and Equipment Planning",
      gabriel: "Follow Best Manufacturing practices",
      tenneco: "Follow Best Manufacturing practices",
      mark_exhaust: "Follow Best Manufacturing practices",
      audit_obs: "Follow the contingence plan ",
      status_c_nc: "",
    },
    {
      std_req: "Contingency Plans",
      gabriel: "As per IATF 16949",
      tenneco: "As per IATF 16949",
      mark_exhaust: "As per IATF 16949",
      audit_obs: "Contingency plan is available as per IATF 16949",
      status_c_nc: "",
    },
    {
      std_req: "Cleanliness of Premises",
      gabriel: "Maintain Good house keeping",
      tenneco: "Maintain Good house keeping",
      mark_exhaust: "Maintain Good house keeping",
      audit_obs: "Cleanness of shop Floor is found satisfactory ",
      status_c_nc: "",
    },
    {
      std_req: "Planning of Product Realization",
      gabriel: "As per PFD and approved process in PPAP",
      tenneco: "As per PFD and approved process in PPAP",
      mark_exhaust: "As per PFD and approved process in PPAP",
      audit_obs: "Follow PPAP during part menufacturing ",
      status_c_nc: "",
    },
    {
      std_req: "Customer related Process",
      gabriel: "Customer Complaint Closer as per 8D format given by Customer",
      tenneco: "Customer Complaint Closer as per 8D format given by Customer",
      mark_exhaust:
        "Customer Complaint Closer as per 8D format given by Customer",
      audit_obs:
        "Customer Complaint logbook available and 8D closed in Specified time ",
      status_c_nc: "",
    },
    {
      std_req: "Manufacturing Feasibility",
      gabriel: "As per IATF 16949",
      tenneco: "As per IATF 16949",
      mark_exhaust: "As per IATF 16949",
      audit_obs: "Found OK as per IATF ",
      status_c_nc: "",
    },
    {
      std_req: "Multidisciplinary Approach",
      gabriel: "As per IATF 16949",
      tenneco: "As per IATF 16949",
      mark_exhaust: "As per IATF 16949",
      audit_obs: "Found OK as per IATF ",
      status_c_nc: "",
    },
    {
      std_req: "FMEA",
      gabriel: "As per IATF 16949",
      tenneco: "As per IATF 16949",
      mark_exhaust: "As per IATF 16949",
      audit_obs: "Found OK as per IATF ",
      status_c_nc: "",
    },
    {
      std_req: "Control Plan",
      gabriel: "As per IATF 16949",
      tenneco: "As per IATF 16949",
      mark_exhaust: "As per IATF 16949",
      audit_obs: "Found OK as per IATF ",
      status_c_nc: "",
    },
    {
      std_req: "Monitoring of Special Characters",
      gabriel: "As marked in drawings  \nPPK&gt;1.67 min\nCpk&gt;1.33\n",
      tenneco: "As marked in drawings  (Cpk&gt;1.67)\n",
      mark_exhaust: "As marked in drawings \n",
      audit_obs: "CP and Cpk available for all dimension mention drawing ",
      status_c_nc: "",
    },
    {
      std_req: "Traceability",
      gabriel: "Tag on Packing box",
      tenneco: "Tag on Packing box",
      mark_exhaust: "Tag on Packing box",
      audit_obs: "Tag on Box With Invoice No. and related Trceability ",
      status_c_nc: "",
    },
    {
      std_req: "Product  Development",
      gabriel: "As per APQP",
      tenneco: "As per APQP",
      mark_exhaust: "As per APQP",
      audit_obs: "Follow APQP ",
      status_c_nc: "",
    },
    {
      std_req: "Prototype Program",
      gabriel:
        "Follow Prototype program if required by Customer, Develop Prototype Control Plan if required by Customer",
      tenneco:
        "Follow Prototype program if required by Customer, Develop Prototype Control Plan if required by Customer",
      mark_exhaust:
        "Follow Prototype program if required by Customer, Develop Prototype Control Plan if required by Customer",
      audit_obs:
        "Follow Prototype program if required by Customer, Develop Prototype Control Plan if required by Customer",
      status_c_nc: "",
    },
    {
      std_req: "Production Part Approval Process",
      gabriel: "Follow PPAP",
      tenneco: "Follow PPAP",
      mark_exhaust: "Follow PPAP",
      audit_obs: "Follow PPAP during part menufacturing ",
      status_c_nc: "",
    },
    {
      std_req: "Sub Contractor Development",
      gabriel: "Monitor Supplier performance through score card/  feedback",
      tenneco: "Monitor Supplier performance through score card/  feedback",
      mark_exhaust:
        "Monitor Supplier performance through score card/  feedback",
      audit_obs: "Supplier performance monitoring ",
      status_c_nc: "",
    },
    {
      std_req: "Customer approved Sources",
      gabriel: "C Lal, Bhushan Steel, Omega",
      tenneco: "Omega, C Lal",
      mark_exhaust: "Nil",
      audit_obs: "Raw Material purchased only from Approved Supplier ",
      status_c_nc: "",
    },
    {
      std_req: "Material",
      gabriel: " Standard G136-0 to 9 and mentioned in customer drawings",
      tenneco: "Standard 18.07.00.58 and mentioned in customer drawings",
      mark_exhaust: "As required and mentioned in  customer drawings",
      audit_obs: "Customer Standard used for Raw material ",
      status_c_nc: "",
    },
    {
      std_req: "Incoming Product Quality",
      gabriel: "MTC/  Mill TC",
      tenneco: "MTC/  Mill TC",
      mark_exhaust: "MTC/  Mill TC",
      audit_obs: "Mill TC and PDIR received from supplier with each lot ",
      status_c_nc: "",
    },
    {
      std_req: "Job Set up verification",
      gabriel: "At the time of Process Audit",
      tenneco: "At the time of Process Audit",
      mark_exhaust: "At the time of Process Audit",
      audit_obs:
        "Setup Verify at the time of setting and at the time of shift start ",
      status_c_nc: "",
    },
    {
      std_req: "Preventive Maintenance",
      gabriel: "Nil",
      tenneco: "Nil",
      mark_exhaust: "Nil",
      audit_obs: "Follow internal Procedure for Preventive Maintenance ",
      status_c_nc: "",
    },
    {
      std_req:
        "Identification & traceability, preservation, packaging, storage & inventory",
      gabriel: "Sticker with Part No. Date, Qty and as per Packing Standard",
      tenneco: "Sticker with Part No. Date, Qty and as per Packing Standard",
      mark_exhaust:
        "Sticker with Part No. Date, Qty and as per Packing Standard",
      audit_obs: "Identification on Box or in Bin as per applicable ",
      status_c_nc: "",
    },
    {
      std_req: "MSA",
      gabriel: "As per IATF 16949",
      tenneco: "As per IATF 16949",
      mark_exhaust: "As per IATF 16949",
      audit_obs: "MSA as per IATF 16949 follow MSA 4th edition ",
      status_c_nc: "",
    },
    {
      std_req: "Monitoring & Measurement of Processes",
      gabriel: "Process audit as per their Need ",
      tenneco: "Process audit as per their Need",
      mark_exhaust: "Process audit as per their Need",
      audit_obs: "Process audit is done as per plan ",
      status_c_nc: "",
    },
    {
      std_req: "Monitoring & Measurement of Products",
      gabriel: "PVIS",
      tenneco: "As per requirements",
      mark_exhaust: "As per requirements",
      audit_obs: "product audit is done as per plan ",
      status_c_nc: "",
    },
    {
      std_req: "Packing",
      gabriel: "Packing in specifix corrugated Boxes",
      tenneco: "As per their requirement - Packing in specifix Bins",
      mark_exhaust: "Packing in specifix corrugated Boxes/ Bins",
      audit_obs: "Packing standard used as per customer guidelines ",
      status_c_nc: "",
    },
    {
      std_req: "Lay out Inspection",
      gabriel: "Once in a year",
      tenneco: "Once in a year",
      mark_exhaust: "Once in a year",
      audit_obs: "Layout inspection as per plan yearly ",
      status_c_nc: "",
    },
    {
      std_req: "Control of Non Conformances, Returned Products Test & Analysis",
      gabriel: "As per Their 8D Format with in 7 days",
      tenneco:
        "As per Their 8D Format with in 5 days effectively closure with in  21 days\n30 days monitoring ",
      mark_exhaust: "As per Their 8D Format",
      audit_obs:
        "Non confirmity in customer provided format in time line specified by Customer ",
      status_c_nc: "",
    },
    {
      std_req: "Invoice",
      gabriel:
        "mention this purchase order no, item no, item description and supplier code on all invoice and correspondence.",
      tenneco:
        "mention this purchase order no, item no, item description and supplier code on all invoice and correspondence.",
      mark_exhaust:
        "mention this purchase order no, item no, item description and supplier code on all invoice and correspondence.",
      audit_obs:
        "mention this purchase order no, item no, item description and supplier code on all invoice and correspondence.",
      status_c_nc: "",
    },
    {
      std_req: "Shipment & Freight Terms",
      gabriel: "Only For GIL- Khandsa\n \n",
      tenneco: "Freight terms settled",
      mark_exhaust: "Freight terms settled",
      audit_obs: "Shipment and Freight as per satled terms ",
      status_c_nc: "",
    },
    {
      std_req: "Transportation",
      gabriel:
        "GIL- Khandsa - ARC transport\nGIL other location- GIL Transport",
      tenneco: "ARC Transport",
      mark_exhaust: "ARC Transport",
      audit_obs: "Transport as per satled with customer ",
      status_c_nc: "",
    },
    {
      std_req: "PDIR",
      gabriel:
        "PDIR must be sent alongwith each consignment as per given format with MTC of RM",
      tenneco: "PDIR must be sent alongwith each consignment with MTC of RM",
      mark_exhaust:
        "PDIR must be sent alongwith each consignment with MTC of RM",
      audit_obs: "PDIR send to customer with each lot along with MTC ",
      status_c_nc: "",
    },
    {
      std_req: "Inspection and Testing",
      gabriel: "Industry practice\nTesting-As per Drawings\n",
      tenneco:
        "Industry practice\nTesting- Refer to standard mentioned in drwaings\n",
      mark_exhaust: "Industry practice",
      audit_obs: "as per Standard provided by customer ",
      status_c_nc: "",
    },
    {
      std_req: "Payment Terms",
      gabriel: "30 days",
      tenneco: "45 Days",
      mark_exhaust: "45 Days",
      audit_obs: "as per satled norms ",
      status_c_nc: "",
    },
    {
      std_req: "SST/ Noduler Test",
      gabriel: "Once in a year",
      tenneco: "Once in a year",
      mark_exhaust: "-----------",
      audit_obs: "SST/ Noduler Test as per Plan yearly ",
      status_c_nc: "",
    },
    {
      std_req: "Handling Field failure/ Warranty",
      gabriel:
        "Jointly analyze the fail sample with GIL and develop necessary documents shall be updated and submit to GIL to reduce warranty in future",
      tenneco:
        "Cost debit up to 50%\nBuyer and supplier will cooperate fully with each other to ientify the cause of NC and to develop a plan for the prompt remediation of it",
      mark_exhaust: "-----------",
      audit_obs: "No FF and WF in Last two Years ",
      status_c_nc: "",
    },
  ],
};
