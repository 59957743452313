import React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";

const SelectProcess = ({
  processList,
  from,
  to,
  processName,
  setProcessName,
}) => {
  return (
    <div
      className="head"
      style={{
        display: "flex",
        fontWeight: "600",
        marginLeft: "1rem",
      }}
    >
      <FormControl
        label="Process Name"
        className="select-field"
        variant="filled"
        style={{ width: "400px" }}
      >
        <InputLabel>Select process</InputLabel>
        <Select
          value={processName}
          disabled={!(from && to)}
          onChange={(e) => setProcessName(e.target.value)}
        >
          <MenuItem>None</MenuItem>
          {Object.keys(processList).map((key) => (
            <MenuItem value={key} key={key}>
              {key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectProcess;
