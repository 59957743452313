import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& .MuiDialog-paper": {
      margin: "5px",
    },
    "& .MuiTypography-root ": {
      paddingBottom: "20px",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "1500px",
      minWidth: "50vh",
      minHeight: "30vh",
      backgroundColor: "#f0f2f0",
      fontSize: "1.2rem",
    },
  },
}));

const AlertDialog = (props) => {
  const classes = useStyles();

  const { title, children, open, setOpen } = props;
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      className={classes.root}
    >
      <DialogTitle
        id="confirm-dialog"
        style={{
          backgroundColor: "#161a1d",
          fontSize: "1.5rem",
          fontFamily: "Nunito",
          color: "white",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent style={{ marginTop: "1rem" }}>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#c80202",
            color: "#fafafa",
          }}
          onClick={() => {
            setOpen(false);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
