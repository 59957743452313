const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const getDailyProductionYearly = (year) => {
  return months.map((month) => ({
    month_year: `${month}-${new Date(year).getFullYear()}`,
  }));
};

const MoniteringSheetTableHead = [
  {
    name: "क्रमांक",
    rowspan: "2",
  },
  {
    name: "दिनांक",
    rowspan: "2",
  },
  {
    name: "असामान्यता का वर्णन",
    rowspan: "2",
  },
  {
    name: "सेट-अप अप्रूवल",
    rowspan: "2",
  },
  {
    name: "रेट्रोएक्टिवे चेक",
    colspan: "2",
  },
  {
    name: "संदिग्ध लोट",
    colspan: "2",
  },
  {
    name: "Invoice No.",
    rowspan: "2",
  },
  {
    name: "Remark",
    rowspan: "2",
  },
];

export { getDailyProductionYearly, MoniteringSheetTableHead };
