import { TextField } from "@material-ui/core";
import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import { DatePicker } from "@material-ui/pickers";
import pdirFormGrid from "./PdirHeadForm";

const PdirHeadForm = ({ pdirData, image, setImage }) => {
  const [pdirDate, setPdirDate] = useState({
    mrir_date: "",
    invoice_date: "",
  });

  const handleChangePdir = ({ target }) => {
    pdirData[target.name] = target.value;
  };
  return (
    <section>
      <Wrapper style={{ marginTop: "1rem" }}>
        <div>
          <Input
            label="Invoice No."
            name="invoice_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.invoice_no}
          />
        </div>
        <div>
          <Input
            label="MRIR No"
            name="mrir_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.mrir_no}
          />
        </div>
        <div>
          <Input
            label="Lot No."
            name="lot_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.lot_no}
          />
        </div>
        <div>
          <Input
            label="Sheet No"
            name="sheet_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.sheet_no}
          />
        </div>
      </Wrapper>

      <Wrapper style={{ marginTop: "1rem" }}>
        <div style={{ marginLeft: "8px" }}>
          <DatePicker
            label="Invoice Date"
            name="invoice_date"
            inputVariant="filled"
            value={pdirDate.invoice_date || pdirData.invoice_date || null}
            onChange={(date) => {
              pdirData.invoice_date = date;
              setPdirDate((prev) => ({
                ...prev,
                invoice_date: date,
              }));
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
        </div>
        <div style={{ marginLeft: "1%" }}>
          <DatePicker
            inputVariant="filled"
            name="mrir_date"
            label="MRIR Date"
            value={pdirDate.mrir_date || pdirData.mrir_date || null}
            onChange={(date) => {
              pdirData.mrir_date = date;
              setPdirDate((prev) => ({
                ...prev,
                mrir_date: date,
              }));
            }}
            format="dd/MM/yyyy"
            className="date-field"
          />
        </div>
        <div>
          <Input
            label="Lot Qty"
            name="quantity"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.quantity}
          />
        </div>
        <div>
          <Input
            label="Heat NO"
            name="heat_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.heat_no}
          />
        </div>
        <div>
          <Input
            label="Rev No"
            name="rev_no"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.rev_no}
          />
        </div>
        <div>
          <Input
            label="Description"
            name="desc"
            type="text"
            onBlur={(e) => handleChangePdir(e)}
            defaultValue={pdirData.desc}
          />
        </div>
      </Wrapper>

      <TextField
        type="text"
        variant="filled"
        name="notes"
        multiline
        className="textarea-field"
        label="Notes"
        style={{
          width: "98.8%",
          margin: "1rem 0.7rem",
        }}
        onBlur={(e) => handleChangePdir(e)}
        defaultValue={pdirData.notes}
      />
    </section>
  );
};

export default PdirHeadForm;
