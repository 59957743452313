export const areaOfWork = [
  "Overall Incharge ",
  "Comercial /purchase,mkt",
  "Technical Director",
  "Sales/purchase",
  "Overall Plant Responsbility",
  "Mr /qms",
  "Product Head",
  "Mkt.",
  "Accounts",
  "Maint. /electrician",
  "Accounts",
  "Npd Head/qa Head",
  "Qms Engineer",
  "Development",
  "Instrument Handling /standard Room",
  "Incoming And Pdi",
  "Inprocess",
  "Inprocess",
  "Inprocess",
  "Qms Engineer",
  "Cnc Supervisor",
  "Vmc Incharge",
  "Vmc Incharge",
  "Cnc Asst. Supervisor",
  "Cnc Asst. Supervisor",
  "Grinding Supervisor",
  "Maint. /electrician",
  "Cri",
  "Grindin",
  "Parting",
  "Traub",
  "Vmc",
  "Cnc",
  "Ball Press",
];

export const TrainingTopics = [
  "Saftey",
  "M/C Operation",
  "Filling M/c Check Sheet ",
  "Fill Set up Approval , Hourly Inspection Report, Dressing frequency Sheet ",
  "Knowledge Of Defects During proocess And Parts",
  "Can Help Setter ",
  "Rework & Reject Part Handling ",
  "1.Redressing ",
  "2. Knowledge of Setting ",
  "3. Can Help in PM ",
  "5S",
  "Tag Identification",
  "FIFO",
  "WORK INSTRUCTION",
  "ABNORMAL SITUATIONS",
  "4M CHANGE",
  "POKA YOKE",
  "Rework rule",
  "KAIZEN",
  "Material Handling",
];
