import { DatePicker } from "@material-ui/pickers";

export default function DatePickerMui({
  label,
  onChange,
  value,
  views,
  format,
  ...rest
}) {
  return (
    <DatePicker
      inputVariant="filled"
      style={{ paddingLeft: "0.6rem" }}
      label={label}
      value={value || null}
      onChange={(e) => onChange(e)}
      format={format || "dd-MM-yyyy"}
      views={views || ["date", "month", "year"]}
      className="date-field"
      {...rest}
    />
  );
}
