export const calcRej = (produced, actual) => {
  if (
    produced === null ||
    produced === undefined ||
    produced === "" ||
    actual === null ||
    actual === undefined ||
    actual === ""
  ) {
    return;
  }
  return ((+produced || 0) - (+actual || 0)).toFixed(2);
};
