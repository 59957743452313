import React from "react";
import { Link } from "react-router-dom";
import clausePage from "./ClausePage.module.css";
//icons
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DockIcon from "@material-ui/icons/Dock";
import ListIcon from '@material-ui/icons/List';
// end icons

const Clause16 = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        Process Audit
      </div>
      <main className={`${clausePage.container} ${clausePage.main}`}>
        <div>
          <Link to="/masterProcedureList?category=clause-16" className="panel">
             <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Master <span className="card-btn-span">Procedure</span>
          </p>
        </div>
        <div>
          <Link to="/processAudit" className="panel">
            <AccountTreeIcon style={{ fontSize: "3rem", color: "#003566" }} />
          </Link>
          <p className="card-btn-para">
            Process<span className="card-btn-span">Audit</span>
          </p>
        </div>
        {/* <div>
          <Link to="/productAudit" className="panel">
            <DockIcon style={{ fontSize: "3rem", color: "#003566" }} />
          </Link>
          <p className="card-btn-para">
            Product<span className="card-btn-span">Audit</span>
          </p>
        </div> */}
      </main>
    </>
  );
};

export default Clause16;
