import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
const Div = styled.div`
  flex: 0.986;
  border: 1px solid black;
  p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    letter-spacing: 1.6px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #1d1d1d;
    color: white;
  }
`;
export default function MonthlyRejection({ monthlyRejection }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});

  useEffect(() => {
    let options = {
      chart: {
        type: "line",

        zoom: {
          enabled: true,
        },
      },
      colors: [
        "#2E93fA",
        "#66DA26",
        "#546E7A",
        "#E91E63",
        "#FF9800",
        "red",
        "teal",
        "green",
      ],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      markers: {
        size: 4,
      },
      title: {
        text: "",
        align: "left",
      },
      legend: {
        position: "top",
      },
      grid: {
        row: {
          colors: ["#fafafa", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [],
        title: {
          text: "DATE",
        },
      },
      yaxis: {
        title: {
          text: "NUMBER OF REJECTIONS",
        },
      },
    };
    setOptions(options);
    setSeries(monthlyRejection);
  }, [monthlyRejection]);

  return (
    <Div>
      <p>NUMBER OF REJECTION PER DAY</p>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={600}
      />
    </Div>
  );
}
