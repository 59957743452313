import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CoverForm from "./CoverForm";
import axios from "axios";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const prefillMaster = [
  {
    s_no: 1,
    name: "VARDHMAN SPECIAL STEELS LTD C-58 PHASE 2 (PB)",
    supplies: "Raw Material",
  },
  {
    s_no: 2,
    name: "AARTI STEELS LTD G.T.ROAD (P.B.)",
    supplies: "Raw Material",
  },
  {
    s_no: 3,
    name: "Malik Sons",
    supplies: "Raw Material",
  },
  {
    s_no: 4,
    name: "Mehta Bright Shaft",
    supplies: "Raw Material",
  },
  {
    s_no: 5,
    name: "C Lal",
    supplies: "Raw Material",
  },
  {
    s_no: 6,
    name: "Omega Bright",
    supplies: "Raw Material",
  },
  {
    s_no: 7,
    name: "Arjas Steel LTD",
    supplies: "Raw Material",
  },
];

const prefillInspectionStandard = [
  {
    s_no: 1,
    parameter: "Dimension",
    specification: "65.0 ± 0.20",
    ins_method: "Bridge Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "2 to 8",
    sample: "2",
    accept: "0",
  },
  {
    s_no: 2,
    parameter: "Diameter",
    specification: "80 + 0.0",
    ins_method: "Snap Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "9 to 15",
    sample: "2",
    accept: "0",
  },
  {
    s_no: 3,
    parameter: "Diameter",
    specification: "61.0 - 0.045",
    ins_method: "Dial Bore Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "16 to 25",
    sample: "3",
    accept: "0",
  },
  {
    s_no: 4,
    parameter: "Diameter",
    specification: "60.3 + 0.046",
    ins_method: "Dial Bore Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "26 to 50",
    sample: "5",
    accept: "0",
  },
  {
    s_no: 5,
    parameter: "Diameter",
    specification: "71.0 - 0.045",
    ins_method: "Dial Bore Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "51 to 90",
    sample: "5",
    accept: "0",
  },
  {
    s_no: 6,
    parameter: "Diameter",
    specification: "63.0 - 0.045",
    ins_method: "Dial Bore Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "91 to 150",
    sample: "8",
    accept: "0",
  },
  {
    s_no: 7,
    parameter: "Thread",
    specification: "4xM8x1.25-6H",
    ins_method: "Thread Plug Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "151 to 280",
    sample: "13",
    accept: "0",
  },
  {
    s_no: 8,
    parameter: "Hole C.D",
    specification: "61.0 ± 0.20",
    ins_method: "Receiving Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "281 to 500",
    sample: "20",
    accept: "0",
  },
  {
    s_no: 9,
    parameter: "Hole C.D",
    specification: "79.2 ± 0.20",
    ins_method: "Receiving Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "501 to 1200",
    sample: "32",
    accept: "0",
  },
  {
    s_no: 10,
    parameter: "Thread",
    specification: "M6x1.0-6H",
    ins_method: "Thread Plug Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "1201 to 3200",
    sample: "50",
    accept: "0",
  },
  {
    s_no: 11,
    parameter: "Position of Hole",
    specification: "0.2 Max",
    ins_method: "Receiving Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
    lot_size: "3201 to 10000",
    sample: "80",
    accept: "0",
  },
  {
    s_no: 12,
    parameter: "Diameter",
    specification: "10.30 + 0.10",
    ins_method: "PPG",
    freq: "EACH LOT",
    sample_size: "100%",
  },
  {
    s_no: 13,
    parameter: "Diameter",
    specification: "10.0 ± 0.20",
    ins_method: "PPG",
    freq: "EACH LOT",
    sample_size: "100%",
  },
  {
    s_no: 14,
    parameter: "Dimension",
    specification: "24.5 ± 0.10",
    ins_method: "Receiving Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
  },
  {
    s_no: 15,
    parameter: "Dimension",
    specification: "14.0 ± 0.15",
    ins_method: "Receiving Gauge",
    freq: "EACH LOT",
    sample_size: "100%",
  },
  {
    s_no: 16,
    parameter: "Engraving",
    specification: "RH/LH",
    ins_method: "Visual",
    freq: "EACH LOT",
    sample_size: "100%",
  },
  {
    s_no: 17,
    parameter: "Appearance",
    specification: "No Burr, No Dent, No Rust",
    ins_method: "Visual",
    freq: "EACH LOT",
    sample_size: "100%",
  },
];

function GeneratePPAP() {
  const { partId } = useParams();
  const [ppapData, setPpapData] = useState({ part_id: partId });
  const history = useHistory();
  const handeSubmit = () => {
    axios
      .post("/api/add/ppap", ppapData)
      .then((res) => {
        if (res.data?.status === "success") {
          history.push(`/select/ppap/${partId}`);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    ppapData.las_master = [...prefillMaster];
    ppapData.ins_standard = [...prefillInspectionStandard];
  }, []);
  return (
    <main style={{ paddingBottom: "3rem" }}>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        PRODUCTION PART APPROVAL PROCESS
        <div style={{ marginLeft: "0.7rem" }}>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={handeSubmit}
            style={{ color: "#e9e9e9", backgroundColor: "#003566" }}
            startIcon={<SaveIcon />}
          >
            Save & Continue
          </Button>
        </div>
      </div>
      <section style={{ padding: "3rem 1rem" }}>
        <CoverForm {...{ handeSubmit, setPpapData, ppapData }} />
      </section>
    </main>
  );
}

export default GeneratePPAP;
