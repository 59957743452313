import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Delete, Visibility } from "@material-ui/icons";
import React from "react";

const TableImagePdfViewer = ({ data, objectKeyName, handleDelete }) => {
  return (
    <div style={{ paddingBottom: "4rem", padding: "3rem 2rem" }}>
      <Table size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              File Name
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data[objectKeyName].map((item, index) => (
            <React.Fragment key={item._id}>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{item.name}</TableCell>
                <TableCell align="center">
                  <Tooltip title="View" arrow>
                    <IconButton
                      size="small"
                      onClick={() => {
                        window.open(item?.url, "_blank");
                      }}
                    >
                      <Visibility
                        style={{
                          fontSize: "1.2rem",
                          color: "#1864ab",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete" arrow>
                    <IconButton
                      style={{ marginLeft: "0.5rem" }}
                      size="small"
                      onClick={() => {
                        handleDelete(data._id, objectKeyName, item.name, index);
                      }}
                    >
                      <Delete
                        style={{
                          fontSize: "1.2rem",
                          color: "red",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableImagePdfViewer;
