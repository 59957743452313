import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import React, { useState } from "react";
import TableReadEditCell from "../../../../components/TableEditCell";

const TBody4 = ({
  data,
  setIsInitialModal,
  handleSubmitAndSave,
  fieldId,
  isView,
}) => {
  const [render, setRender] = useState(false);
  const openModal = async (field, bodyIdx, fieldIndex) => {
    try {
      let res = await handleSubmitAndSave();
      if (res) {
        console.log(res);
        fieldId.current = res.body3[bodyIdx][field][fieldIndex]._id;
        setIsInitialModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {data.body4.map((body, index) => (
        <>
          <tr style={{ height: "50px" }}>
            <td rowSpan={2}>{index + 1}</td>
            <td rowSpan={2} colSpan={2}>
              {body.control_item}
            </td>
            <td rowSpan={2}>
              <TableReadEditCell
                value={body.target_plan}
                isView={isView}
                type={body.input_type}
                handleChange={(e) => {
                  body.target_plan = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            {body.m1.map((m1) => (
              <td
                colSpan={2}
                style={{
                  background: m1.value > body.target_plan ? "yellow" : "white",
                }}
              >
                <TableReadEditCell
                  value={m1.value}
                  isView={isView}
                  type={body.input_type}
                  handleChange={(e) => {
                    m1.value = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            ))}
            {body.m2.map((m2) => (
              <td
                colSpan={2}
                style={{
                  background: m2.value > body.target_plan ? "yellow" : "white",
                }}
              >
                <TableReadEditCell
                  value={m2.value}
                  isView={isView}
                  type={body.input_type}
                  handleChange={(e) => {
                    m2.value = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            ))}
            {body.m3.map((m3) => (
              <td
                colSpan={2}
                style={{
                  background: m3.value > body.target_plan ? "yellow" : "white",
                }}
              >
                <TableReadEditCell
                  value={m3.value}
                  isView={isView}
                  type={body.type}
                  handleChange={(e) => {
                    m3.value = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            ))}
          </tr>
          <tr style={{ height: "50px" }}>
            {body.m1.map((_, m1Idx) => (
              <td colSpan={2}>
                <IconButton onClick={() => openModal("m1", index, m1Idx)}>
                  <Add />
                </IconButton>
              </td>
            ))}
            {body.m2.map((_, m2Idx) => (
              <td colSpan={2}>
                <IconButton onClick={() => openModal("m2", index, m2Idx)}>
                  <Add />
                </IconButton>
              </td>
            ))}
            {body.m3.map((_, m3Idx) => (
              <td colSpan={2}>
                <IconButton onClick={() => openModal("m3", index, m3Idx)}>
                  <Add />
                </IconButton>
              </td>
            ))}
          </tr>
        </>
      ))}
    </>
  );
};

export default TBody4;
