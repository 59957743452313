import { MenuItem, Select } from "@material-ui/core";
import TableReadEditCell from "../../../../components/TableEditCell";
import React, { useState } from "react";

const TBody1 = ({ data, handleChangeMonth, isView }) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tr>
        <th></th>
        <th style={{ textAlign: "center" }} colSpan={31}>
          DISPATCH DATE
        </th>
        <th colSpan={2}></th>
      </tr>
      <tr>
        <th>MONTH</th>
        {Array.from({ length: 31 }, (_, index) => (
          <th>{index + 1}</th>
        ))}
        <th colSpan={2}>REMARKS</th>
      </tr>
      {data.months.map((month, monthIdx) => (
        <tr>
          <td>{month.name}</td>
          {Array.from({ length: 31 }, (_, dayIdx) => (
            <td
              style={{
                width: "120px",
              }}
            >
              {isView ? (
                <div
                  style={{
                    width: "130px",
                    color: "white",
                    background:
                      month.days[dayIdx] &&
                      (month.days[dayIdx] === "DISPATCH" ? "green" : "red"),
                  }}
                >
                  {month.days[dayIdx]}
                </div>
              ) : (
                <Select
                  variant="outlined"
                  style={{
                    width: "130px",
                    color: "white",
                    background:
                      month.days[dayIdx] &&
                      (month.days[dayIdx] === "DISPATCH" ? "green" : "red"),
                  }}
                  disabled={!data.ipc_start_date}
                  className="Select-field"
                  onChange={(e) => {
                    handleChangeMonth(e, dayIdx, monthIdx);
                  }}
                  value={month.days[dayIdx]}
                >
                  <MenuItem value="">NONE</MenuItem>
                  <MenuItem value="DISPATCH" style={{ color: "green" }}>
                    DISPATCH
                  </MenuItem>
                  <MenuItem value="NO DISPATCH" style={{ color: "red" }}>
                    NO DISPATCH
                  </MenuItem>
                </Select>
              )}
            </td>
          ))}
          <td colSpan={2}>
            <TableReadEditCell
              value={month.remarks}
              isView={isView}
              type="text"
              handleChange={(e) => {
                month.remarks = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
      ))}
      <tr>
        <td colSpan={2}>LEGENDS:</td>
        <td colSpan={3}>DISPATCH </td>
        <td style={{ color: "green", fontSize: "1.1rem" }}>DISPATCH</td>
        <td colSpan={3}>NO DISPATCH</td>
        <td style={{ color: "red", fontSize: "1.1rem" }}>NO DISPATCH</td>
        <td colSpan={24}></td>
      </tr>
    </>
  );
};

export default TBody1;
