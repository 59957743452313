import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker } from "@material-ui/pickers";

import { useAuth } from "../../context/GlobalContext";
import formatDate from "../../components/Control/formatDate";
import useConfirm from "../../components/Control/useConfirm";
import SelectPartForModule from "../../components/SelectPartForModule";
import Modal from "../../components/Control/Modal";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const InitialSupplyList = () => {
  const classes = useStyles();
  const { state } = useAuth();
  const [irmResisterData, setIRMResistersData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [render, setRender] = useState(false);
  const [isPartModal, setIsPartModal] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    "SAVE",
    "Are you sure you want to Delete this report?"
  );

  useEffect(() => {
    axios
      .get(`/api/getInitialSupplyMonthly/${selectedDate}`)
      .then((res) => setIRMResistersData(res.data));
  }, [selectedDate, render]);

  const handleDelete = async (id) => {
    const isConfirm = await confirmDelete();
    if (isConfirm) {
      axios
        .delete(`/api/deleteInitialSupply/${id}`)
        .then((res) => {
          alert("success deleted");
          setRender((prev) => !prev);
        })
        .catch((err) => console.error(err));
    }
  };

  return (
    <section>
      <div style={{}}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
          }}
        >
          INITIAL SUPPLY MONITORING REPORT
          <div>
            {(state.user.userType === 3 || state.user.userType === 2) && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#054a29",
                }}
                startIcon={<AddCircleIcon />}
                onClick={() => setIsPartModal(true)}
              >
                New Initial Supply
              </Button>
            )}
          </div>
        </div>

        <div className="subheading-container">
          <p className="subheading-primary">
            ACTIVE <span className="subheading-secondary">REPORTS</span>
          </p>
        </div>
      </div>
      <div style={{ display: "flex", margin: "1rem" }}>
        <DatePicker
          views={["month", "year"]}
          label="Month & Year"
          className="date-field"
          inputVariant="filled"
          value={selectedDate || null}
          onChange={setSelectedDate}
        />
      </div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            {["#", "IPC START DATE", "PART NAME", "CUSTOMER", "ACTION"].map(
              (item, index) => (
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  {item}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        {irmResisterData && (
          <TableBody>
            {irmResisterData.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.ipc_start_date)}
                  </TableCell>
                  <TableCell align="center">
                    {item.part_id?.part_name}
                  </TableCell>
                  <TableCell align="center">
                    {item.part_id?.customer_id?.customer_name}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="View Incoming RM Resister" arrow>
                      <Link to={`/initialSupplyAddEdit/${item.part_id?._id}/${item._id}?isView=true`}>
                        <IconButton size="small">
                          <VisibilityIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "#003566",
                            }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Edit" arrow>
                      <Link to={`/initialSupplyAddEdit/${item.part_id?._id}/${item._id}?isView=false`}>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <EditIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "#003566",
                            }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(item._id)}
                        style={{ marginLeft: "0.5rem" }}
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "1.4rem",
                            color: "#c80202",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <Modal
        openPopup={isPartModal}
        title="PART LIST"
        closeModal={() => setIsPartModal(false)}
      >
        <SelectPartForModule link="/initialSupplyAddEdit" />
      </Modal>
      <DialogDelete isSubmitContent={true} />
    </section>
  );
};

export default InitialSupplyList;
