import React, { ReactNode, forwardRef } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { CloseButton } from "./CustomButtons";
import Slide from "@mui/material/Slide";

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

Transition.displayName = "Transition";

const DialogCustom = styled(Dialog)(({ theme }) => ({
  // width: 'calc(100vw * (70 / 100))',
  "& .MuiPaper-root": {
    borderRadius: "8px",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
  },

  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    backgroundColor: "#fff",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const DialogTitleCustom = styled(DialogTitle)(
  ({ theme, background, color }) => ({
    background: background || theme.palette.secondary.main, // Use prop or fallback to theme
    color: color || "#f1f3f5",
    fontWeight: "600",
    padding: theme.spacing(2),
  }),
);

const ModalCustom = (props) => {
  const {
    title,
    children,
    openModal,
    closeModal,
    isModalCloseBtn,
    sxDialogContent,
    dialogTitleCustomBackground,
    dialogTitleCustomColor,
    isRequireCloseButton = true,
  } = props;

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    closeModal();
  };

  return (
    <DialogCustom
      onClose={handleClose}
      open={openModal}
      disableEscapeKeyDown={true}
      maxWidth={false}
      disableScrollLock
      TransitionComponent={Transition}
      disableRestoreFocus
    >
      <DialogTitleCustom
        background={dialogTitleCustomBackground || false}
        color={dialogTitleCustomColor || false}
      >
        {title?.toUpperCase()}
      </DialogTitleCustom>
      <DialogContent
        dividers
        sx={{
          ...sxDialogContent,
        }}
      >
        {children}
      </DialogContent>
      {isRequireCloseButton ? (
        <div
          style={{
            position: "absolute",
            right: "10px",
            top: "-6px",
          }}
        >
          <CloseButton label="close" onClick={closeModal} />
        </div>
      ) : (
        ""
      )}
    </DialogCustom>
  );
};

export default ModalCustom;
