import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Search } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";

import useDebounce from "../../components/Control/useDebounce";
import { useAuth } from "../../context/GlobalContext";
import Modal from "../../components/Control/Modal";
import CutomerDrawingInputs from "./CutomerDrawingInputs";
import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
import Input from "../../components/Control/Input";
import { useQuery } from "../../components/Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";
import useConfirm from "../../components/Control/useConfirm";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const ViewCustomerDrawing = () => {
  const { partId } = useParams();
  const { state } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [drawingData, setDrawingData] = useState();
  const userType = state.user.userType;
  const [currentId, setCurrentId] = useState(null);
  const [render, setRender] = useState(false);
  const [partDesc, setPartDesc] = useState();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("part_number");

  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this Document?"
  );

  const fetchDrawingData = () => {
    axios
      .get(
        `/api/getCutomerDrawing/data/${partId}?page=${page}&sortBy=${filter}&search=${search}&perPage=10`
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setDrawingData(res.data?.results);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };
  useDebounce(fetchDrawingData, 300, [page, filter, search, render, openModal]);

  useEffect(() => {
    axios
      .get(`/api/getPartName/partDesc/${partId}`)
      .then((res) => setPartDesc(res.data))
      .catch((err) => console.log(err));
  }, [render]);

  console.log(drawingData);
  const handleDelete = async (id) => {
    const confirm = await confirmDelete();
    if (confirm) {
      axios
        .delete(`/api/deleteCutomerDrawing/data/${id}`)
        .then((res) => {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  };

  const classes = useStyles();
  return (
    <section>
      <div style={{ paddingBottom: "3rem" }}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
          }}
        >
          CUSTOMER DRAWING
        </div>

        <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
        <div className="subheading-container">
          <p className="subheading-primary">
            ACTIVE <span className="subheading-secondary">DOCUMENTS</span>
          </p>
        </div>
        <Wrapper>
          <div style={{ flex: "0.8" }}>
            <Input
              placeholder="Search By Drawing Desc"
              variant="filled"
              style={{
                width: "96%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginRight: "1.5rem", marginTop: "0.8rem", flex: "0.2" }}
          >
            <InputLabel>Filter</InputLabel>
            <Select
              name="part_type"
              onChange={(e) => setFilter(e.target.value)}
              defaultValue={filter}
            >
              <MenuItem value="customer_name">A - Z</MenuItem>
              <MenuItem value="createdAt">Most Recent First</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow style={{ padding: "6px" }}>
              {[
                "#",
                "Drawing Description",
                " Customer Name",
                "Date",
                "Action",
              ].map((item, index) => (
                <TableCell
                  style={{ fontWeight: "bold", textTransform: "capitalize" }}
                  align="center"
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {drawingData && (
            <TableBody>
              {drawingData.map((item, index) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item.drawing_desc}</TableCell>
                    <TableCell align="center">{item.customer_name}</TableCell>
                    <TableCell align="center">
                      {formatDate(item.createdAt)}
                    </TableCell>

                    <TableCell align="center">
                      {userType === 2 && (
                        <Tooltip title="Edit" arrow>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: "0.5rem",
                            }}
                            onClick={() => {
                              setCurrentId(item._id);
                              setOpenModal(true);
                            }}
                          >
                            <EditIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="drawing" arrow>
                        <Link to={`/viewCustomerDrawing/${item._id}`}>
                          <IconButton
                            size="small"
                            style={{
                              marginRight: "0.5rem",
                            }}
                          >
                            <VisibilityIcon
                              style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      {userType === 2 && (
                        <Tooltip title="Delete" arrow>
                          <IconButton
                            size="small"
                            onClick={() => handleDelete(item._id)}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "red",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </Table>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "4rem",
          }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, number) => setPage(number)}
          />
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          top: "4.7rem",
          right: "1.7rem",
        }}
      >
        {userType === 2 && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginBottom: "2rem", backgroundColor: "#054a29" }}
            startIcon={<AddCircleIcon />}
            onClick={() => {
              setOpenModal(true);
              setCurrentId(null);
            }}
          >
            Upload New File
          </Button>
        )}
      </div>
      <Modal
        title="Add Customer Drawing"
        openPopup={openModal}
        closeModal={() => {
          setOpenModal(false);
        }}
      >
        <CutomerDrawingInputs
          {...{ partId, currentId, setOpenModal, partDesc }}
        />
      </Modal>
      <DialogDelete isSubmitContent={true} />
    </section>
  );
};

export default ViewCustomerDrawing;
