import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import BlockIcon from "@material-ui/icons/Block";
import IconButton from "@material-ui/core/IconButton";
import { Link, useParams } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { Add } from "@material-ui/icons";
import CoverForm from "./CoverForm";
import axios from "axios";
import Modal from "../Control/Modal";
import DesignRecord from "./modules/DesignRecord";
import Pfd from "./modules/Pfd";
import Fmea from "./modules/Fmea";
import DimensionInspection from "./modules/DimensionInspection";
import MaterialPerformance from "./modules/MaterialPerformance";
import ControlPlan from "./modules/ControlPlan";
import QualifiedLab from "./modules/QualifiedLab";
import CheckingAids from "./modules/CheckingAids";
import Warrant from "./modules/Warrant";
import PackingStandard from "./modules/PackingStandard";
import ApprovedSupplier from "./modules/ApprovedSupplier";
// import DownloadExcel from "./DownloadExcel";
import { Wrapper } from "../Control/Flexbox";
import MSATable from "./modules/MSATable";
import InspectionStandard from "./modules/InspectionStandard";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    "& .MuiTableRow-root:hover": {
      background: "#efefef",
    },
  },
});

const ppapobj = [
  {
    sr_no: "1",
    title: "Part Submission Warrant",
    isPpap: true,
    modalState: "setIsPSW",
    remark: "✡",
    value: "psw",
  },
  {
    sr_no: "2",
    title: "Design Records of Saleable Product (Part Drawing)",
    isPpap: true,
    modalState: "setIsDesignRecord",
    remark: "-",
    value: "design_record",
  },
  {
    sr_no: "3",
    title: "Engineering Change Documents (If applicable)",
    isPpap: false,
    remark: "☠",
  },
  {
    sr_no: "4",
    title: "Control plan",
    value: "control_plan",
    remark: "✡",
    isPpap: true,
    modalState: "setIsControlPlan",
  },
  {
    sr_no: "5",
    title: "Process Flow Diagram",
    remark: "✡",
    value: "process_flow_diagram",
    isPpap: true,
    modalState: "setIsPfd",
  },
  {
    sr_no: "6",
    title: "Process FMEA",
    value: "pfmea",
    remark: "✡",
    isPpap: true,
    modalState: "setIsFmea",
  },
  {
    sr_no: "7",
    title: "Inspection Standard",
    remark: "✡",
    isPpap: true,
    value: "inspection_standard",
    modalState: "setIsInspectionStandard",
  },
  {
    sr_no: "8",
    title: "Dimensional Results",
    value: "pfmea",
    remark: "✡",
    value: "dimension_report",
    isPpap: true,
    modalState: "setIsDimensionInspection",
  },
  {
    sr_no: "9",
    title: "Packing Details",
    remark: "✡",
    isPpap: true,
    value: "packing_standard",
    modalState: "setIsStand",
  },
  {
    sr_no: "10",
    title: "Material, Performance Test Results",
    value: "material_performance",
    remark: "✡",
    isPpap: true,
    modalState: "setIsMatPer",
  },
  {
    sr_no: "11",
    title: "Initial Process Study/Process Capability",
    remark: "-",
    isPpap: true,
    spc: true,
  },
  {
    sr_no: "12",
    title: "Measurement System Analysis (Variable/Attribute)",
    remark: "-",
    isPpap: true,
    modalState: "setIsMSA",
    msa: true,
  },
  {
    sr_no: "13",
    title: "Qualified Laboratory Document",
    remark: "✡",
    value: "qualified_lab",
    isPpap: true,
    modalState: "setIsQualified",
  },
  {
    sr_no: "14",
    title: "PAPP Lot (300 Nos.)",
    remark: "✡",
    isPpap: false,
  },
  {
    sr_no: "15",
    title: "Checking Aids",
    remark: "✡",
    isPpap: true,
    modalState: "setIsAids",
    value: "checking_aid",
  },
  {
    sr_no: "16",
    title: "List of Approved Suppliers",
    remark: "✡",
    isPpap: true,
    modalState: "setIsApprovedSupplier",
    value: "approved_supplier",
  },
  {
    sr_no: "17",
    title: "Capacity Confirmation Letter",
    remark: "☠",
    isPpap: false,
  },
];

function EditPPAP() {
  const classes = useStyles();
  const { partId, id } = useParams();
  const [ppapData, setPpapData] = useState(null);
  const [isPSW, setIsPSW] = useState(false);
  const [isDesignRecord, setIsDesignRecord] = useState(false);
  const [isPfd, setIsPfd] = useState(false);
  const [isFmea, setIsFmea] = useState(false);
  const [isDimensionInspection, setIsDimensionInspection] = useState(false);
  const [isMatPer, setIsMatPer] = useState(false);
  const [isControlPlan, setIsControlPlan] = useState(false);
  const [isQualified, setIsQualified] = useState(false);
  const [isAids, setIsAids] = useState(false);
  const [isPackStand, setIsStand] = useState(false);
  const [isApprovedSupplier, setIsApprovedSupplier] = useState(false);
  const [isMSA, setIsMSA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);
  const [image5, setImage5] = useState(null);
  const [packStandImg, setPackStandImg] = useState(null);
  const [isInspectionStandard, setIsInspectionStandard] = useState(false);
  const [render, setRender] = useState(false);
  useEffect(() => {
    axios.get(`/api/get/ppap/${id}`).then((res) => {
      setPpapData(res.data);
    });
  }, [render]);
  const handeSubmit = async () => {
    setIsLoading(true);

    if (image1 || image2 || image3 || image4 || image5 || packStandImg) {
      let form_data = new FormData();

      if (image1) {
        form_data.append("img_1", image1);
      }
      if (image2) {
        form_data.append("img_2", image2);
      }
      if (image3) {
        form_data.append("img_3", image3);
      }
      if (image4) {
        form_data.append("img_4", image4);
      }
      if (image5) {
        form_data.append("img_5", image5);
      }
      if (packStandImg) {
        form_data.append("pack_stand_img", packStandImg);
      }
      await axios
        .patch(`/api/edit/ppap/${id}`, form_data)
        .catch((err) => setIsLoading(false));
      setImage1(null);
      setImage2(null);
      setImage3(null);
      setImage4(null);
      setImage5(null);
      setPackStandImg(null);
    }
    delete ppapData.img_1;
    delete ppapData.img_2;
    delete ppapData.img_3;
    delete ppapData.img_4;
    delete ppapData.img_5;
    delete ppapData.pack_stand_img;
    await axios
      .patch(`/api/edit/ppap/${id}`, ppapData)
      .catch((err) => setIsLoading(false));
    setIsLoading(false);
    setRender((prev) => !prev);
  };
  const closeModal = () => {
    setIsDesignRecord(false);
    setIsPfd(false);
    setIsFmea(false);
    setIsDimensionInspection(false);
    setIsMatPer(false);
    setIsControlPlan(false);
    setIsQualified(false);
    setIsAids(false);
    setIsPSW(false);
    setIsStand(false);
    setIsApprovedSupplier(false);
    setIsMSA(false);
    setIsInspectionStandard(false);
    handeSubmit();
  };
  const openModal = (value) => {
    switch (value) {
      case "setIsPSW":
        setIsPSW(true);
        break;
      case "setIsDesignRecord":
        setIsDesignRecord(true);
        break;
      case "setIsPfd":
        setIsPfd(true);
        break;
      case "setIsFmea":
        setIsFmea(true);
        break;
      case "setIsDimensionInspection":
        setIsDimensionInspection(true);
        break;
      case "setIsMatPer":
        setIsMatPer(true);
        break;
      case "setIsControlPlan":
        setIsControlPlan(true);
        break;
      case "setIsQualified":
        setIsQualified(true);
        break;
      case "setIsAids":
        setIsAids(true);
        break;
      case "setIsStand":
        setIsStand(true);
        break;
      case "setIsApprovedSupplier":
        setIsApprovedSupplier(true);
        break;
      case "setIsMSA":
        setIsMSA(true);
        break;
      case "setIsInspectionStandard":
        setIsInspectionStandard(true);
    }
  };
  if (isLoading) {
    return (
      <Wrapper style={{ height: "90vh" }} justify="center">
        <h3>Saving...</h3>
      </Wrapper>
    );
  }
  return (
    <main style={{ paddingBottom: "3rem" }}>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        PRODUCTION PART APPROVAL PROCESS
        <div>
          <Button
            type="button"
            variant="outlined"
            color="white"
            size="large"
            onClick={handeSubmit}
            style={{
              color: "#e9e9e9",
              backgroundColor: "#003566",
              marginLeft: "0.7rem",
            }}
            startIcon={<SaveIcon />}
          >
            Save & Continue
          </Button>
        </div>
      </div>
      <section style={{ padding: "1rem" }}>
        {ppapData && <CoverForm {...{ setPpapData, ppapData }} />}
      </section>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Sr. No.
            </TableCell>
            <TableCell
              style={{ fontWeight: "bold", width: "500px" }}
              align="center"
            >
              Document Title
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Remarks
            </TableCell>
            <TableCell
              style={{ fontWeight: "bold", width: "150px" }}
              align="center"
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ppapobj.map((item, index) => (
            <TableRow key={index}>
              <TableCell align="center">{item.sr_no}</TableCell>
              <TableCell align="center">{item.title}</TableCell>
              <TableCell align="center" style={{ fontSize: "2rem" }}>
                {item.remark}
              </TableCell>
              <TableCell align="center">
                {item.isPpap ? (
                  item.spc ? (
                    <>
                      <Tooltip title="Block" arrow>
                        <IconButton size="small">
                          <BlockIcon
                            style={{ fontSize: "1.4rem", color: "red" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View" arrow>
                        <Link
                          target="_blank"
                          to={`/spc/${ppapData?.part_name}`}
                        >
                          <IconButton
                            size="small"
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <VisibilityIcon
                              style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </>
                  ) : item.msa ? (
                    <>
                      <Tooltip title="Create" arrow>
                        <IconButton
                          size="small"
                          onClick={() => openModal(item.modalState)}
                        >
                          <Add
                            style={{ fontSize: "1.4rem", color: "#003566" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View" arrow>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() => {
                            axios
                              .get(`/api/view/msa/${ppapData.msa_id}`)
                              .then((response) => {
                                if (response.status === 200)
                                  window.open(
                                    "https://docs.google.com/spreadsheets/d/1rFljqRf1dNcQkG0QZUFibX7jEos2jVjYrtEtrveD0xU/edit#gid=1844475717",
                                    "_blank"
                                  );
                              });
                          }}
                        >
                          <VisibilityIcon
                            style={{ fontSize: "1.4rem", color: "#003566" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title="Create" arrow>
                        <IconButton
                          size="small"
                          onClick={() => openModal(item.modalState)}
                        >
                          <Add
                            style={{ fontSize: "1.4rem", color: "#003566" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View" arrow>
                        <Link
                          to={{
                            pathname: "/view/ppap",
                            state: {
                              ppapData,
                              isEqual: item.value,
                              partId,
                            },
                          }}
                        >
                          <IconButton
                            size="small"
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <VisibilityIcon
                              style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                    </>
                  )
                ) : (
                  <>
                    <Tooltip title="Block" arrow>
                      <IconButton size="small">
                        <BlockIcon
                          style={{ fontSize: "1.4rem", color: "red" }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Block" arrow>
                      <Link>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <BlockIcon
                            style={{ fontSize: "1.4rem", color: "red" }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                  </>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {ppapData && (
        <section>
          <Modal
            openPopup={isPSW}
            title="PART SUBMISSION WARRANT"
            closeModal={closeModal}
          >
            <Warrant {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isDesignRecord}
            title="FIRST PIECE APPROVAL"
            closeModal={closeModal}
          >
            <DesignRecord
              {...{
                setImage1,
                setImage2,
                setImage3,
                setImage4,
                setImage5,
              }}
            />
          </Modal>
          <Modal
            openPopup={isPfd}
            title="ADD PFD TO PPAP"
            closeModal={closeModal}
          >
            <Pfd {...{ ppapData }} />
          </Modal>
          <Modal openPopup={isFmea} title="FMEA" closeModal={closeModal}>
            <Fmea {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isDimensionInspection}
            title="DIMENSIONS"
            closeModal={closeModal}
          >
            <DimensionInspection {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isMatPer}
            title="MATERIAL PERFORMANCE"
            closeModal={closeModal}
          >
            <MaterialPerformance {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isQualified}
            title="QUALIFIED LAB"
            closeModal={closeModal}
          >
            <QualifiedLab {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isControlPlan}
            title="ADD CONTROL PLAN TO PPAP"
            closeModal={closeModal}
          >
            <ControlPlan {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isMSA}
            title="ADD MSA TO PPAP"
            closeModal={closeModal}
          >
            <MSATable {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isAids}
            title="CHECKING AIDS"
            closeModal={closeModal}
          >
            <CheckingAids {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isApprovedSupplier}
            title="LIST OF APPROVED SUPPLIERS"
            closeModal={closeModal}
          >
            <ApprovedSupplier {...{ ppapData }} />
          </Modal>

          <Modal
            openPopup={isPackStand}
            title="PACKING STANDARD"
            closeModal={closeModal}
          >
            <PackingStandard {...{ ppapData }} />
          </Modal>
          <Modal
            openPopup={isInspectionStandard}
            title="INSPECTION STANDARD"
            closeModal={closeModal}
          >
            <InspectionStandard {...{ ppapData }} />
          </Modal>
        </section>
      )}
      {/* <DownloadExcel {...{ ppapData, ppapobj }} /> */}
    </main>
  );
}

export default EditPPAP;
