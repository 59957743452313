import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Loader from "../../../components/Control/Loader";
import formatDate from "../../../components/Control/formatDate";
import { DatePicker } from "@material-ui/pickers";
import useConfirm from "../../../components/Control/useConfirm";
import { useAuth } from "../../../context/GlobalContext";
import { useQuery } from "../../../components/Control/useQuery";
import ItemsDetailsHeader from "../../../GlobalUtils/ItemsDetailsHeader";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});
const Editable = ({ item, DeletePir, partId }) => {
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  return (
    <TableCell align="center">
      <Tooltip title="PIR" arrow>
        <Link to={`/ViewPir/table/${item._id}`}>
          <IconButton size="small">
            <VisibilityIcon style={{ fontSize: "1.4rem", color: "#003566" }} />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title="Setup Approval Report" arrow>
        <Link to={`/ViewSetupPiece/table/${item._id}`}>
          <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
            <VisibilityIcon style={{ fontSize: "1.4rem", color: "#c80202" }} />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title="Edit" arrow>
        <Link
          to={`/pir/save_later/${partId}/${item._id}?partNumber=${partNumber}&partName=${partName}`}
        >
          <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
            <EditIcon style={{ fontSize: "1.4rem", color: "#003566" }} />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title="Delete" arrow>
        <IconButton
          size="small"
          style={{ marginLeft: "0.5rem" }}
          onClick={() => DeletePir(item._id)}
        >
          <DeleteIcon
            style={{
              fontSize: "1.4rem",
              color: "red",
            }}
          />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};
const NotEditable = ({ item }) => {
  return (
    <TableCell align="center">
      <Tooltip title="PIR" arrow>
        <Link to={`/ViewPir/table/${item._id}`}>
          <IconButton size="small">
            <VisibilityIcon style={{ fontSize: "1.4rem", color: "#003566" }} />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title="Setup Approval Report" arrow>
        <Link to={`/ViewSetupPiece/table/${item._id}`}>
          <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
            <VisibilityIcon style={{ fontSize: "1.4rem", color: "#c80202" }} />
          </IconButton>
        </Link>
      </Tooltip>
      <Tooltip title="Block" arrow>
        <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
          <BlockIcon
            style={{
              fontSize: "1.4rem",
              color: "red",
            }}
          />
        </IconButton>
      </Tooltip>
      <Tooltip title="Block" arrow>
        <IconButton size="small" style={{ marginLeft: "0.5rem" }}>
          <BlockIcon
            style={{
              fontSize: "1.4rem",
              color: "red",
            }}
          />
        </IconButton>
      </Tooltip>
    </TableCell>
  );
};

const superviserStatus = (
  partId,
  item,
  isSubmit,
  adminRequestStatus,
  DeletePir,
  DialogDelete
) => {
  //notsubmitted
  if (!isSubmit) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#1864ab",
            color: "white",
          }}
        >
          <span>SAVED</span>
        </TableCell>
        <Editable item={item} DeletePir={DeletePir} partId={partId} />
        <DialogDelete isSubmitContent={false} />
      </>
    );
  }

  if (adminRequestStatus === "pending" && isSubmit) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#fdd835",
            color: "black",
            maxWidth: "200px",
          }}
        >
          <span
            style={{
              display: "flex",
              width: "100%",
              alignItems: "Center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            PENDING FOR APPROVAL
          </span>
        </TableCell>
        <NotEditable item={item} />
      </>
    );
  }
  if (adminRequestStatus === "accepted" && isSubmit) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#2b8a3e",
            color: "#fff",
          }}
        >
          <span>APPROVED</span>
        </TableCell>
        <NotEditable item={item} />
      </>
    );
  }
  if (adminRequestStatus === "rejected" && isSubmit) {
    return (
      <>
        <TableCell
          align="center"
          style={{
            backgroundColor: "#f03e3e",
            color: "#fff",
          }}
        >
          <span>SENT BACK FOR RECHECKING</span>
        </TableCell>
        <Editable item={item} DeletePir={DeletePir} partId={partId} />
      </>
    );
  }
};

function SupervisorPirList() {
  const { partId } = useParams();
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [render, setRender] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { state } = useAuth();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const [isLoading, setIsLoading] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE PIR",
    "Are you sure you want to delete this PIR?"
  );

  useEffect(() => {
    if (selectedDate) {
      setIsLoading(true);
      axios
        .get(`/api/get/pirbymonth/supervisor/${partId}/${selectedDate}`)
        .then((res) => {
          setData(
            res.data.sort((a, b) => a.pir_date.localeCompare(b.pir_date))
          );
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [render, selectedDate]);

  const DeletePir = async (id) => {
    let isDeleted = await confirmDelete();
    if (isDeleted) {
      axios
        .delete(`/api/deleteOnePir/${id}`)
        .then(() => {
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ paddingBottom: "3rem" }}>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        PROCESS INSPECTION REPORT
      </div>
      <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />

      <div className="subheading-container">
        <p className="subheading-primary">
          ACTIVE <span className="subheading-secondary">REPORTS</span>
        </p>
      </div>
      <div style={{ marginTop: "2rem", marginLeft: "1rem" }}>
        <DatePicker
          views={["month", "year"]}
          label="Month & Year"
          className="date-field"
          inputVariant="filled"
          value={selectedDate}
          onChange={setSelectedDate}
        />
      </div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Item Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Username
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Date
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Status
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            {data.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {item.part_id?.part_number}
                  </TableCell>
                  <TableCell
                    style={{ textTransform: "capitalize" }}
                    align="center"
                  >
                    {item.report_prepared_by}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.pir_date)}
                  </TableCell>
                  {superviserStatus(
                    partId,
                    item,
                    item.isSubmitted,
                    item.is_admin_request,
                    DeletePir,
                    DialogDelete
                  )}
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      <div
        style={{
          position: "absolute",
          top: "4.7rem",
          right: "1.8rem",
        }}
      >
        <Link
          to={`/supervisor/newpir/${partId}?partNumber=${partNumber}&partName=${partName}`}
          style={{ color: "white", textDecoration: "none" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginBottom: "2rem", backgroundColor: "#054a29" }}
            startIcon={<AddCircleIcon />}
          >
            New REPORT
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default SupervisorPirList;
