import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import lodash from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import { useQuery } from "../../../components/Control/useQuery";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import useConfirm from "../../../components/Control/useConfirm";
import { TableContainerStyled, Table } from "../Container.styled";
import formatDate from "../../../components/Control/formatDate";
import DragableButton from "../../../components/Control/Dragable";

function formatDateForTime(date) {
  if (!date) return;
  const newDate = new Date(date);
  if (isNaN(newDate)) {
    return "";
  }
  let hour = newDate.getHours();
  const min = newDate.getMinutes().toString().padEnd(2, 0);
  let meridian = "AM";
  if (hour > 12) {
    hour = hour - 12;
    meridian = "PM";
  }
  if (hour === 12) {
    meridian = "PM";
  }
  if (hour === 0) {
    hour = 12;
    meridian = "AM";
  }
  return `${hour}:${min} ${meridian}`;
}

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const AddEditViewSchedulePlan = () => {
  const classes = dropdownStyles();
  const history = useHistory();
  const { id, auditId } = useParams();
  const [render, setRender] = useState(false);
  const [auditScheduleData, setAuditScheduleData] = useState({
    days: [],
  });

  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const [auditorValue, setAuditorValue] = useState([]);
  const [auditeeValue, setAudieeValue] = useState([]);
  const [expandTable, setExpandTable] = useState([]);
  const [employeeAutocomplete, setEmployeeAutocomplete] = useState([]);
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "CLOSE",
    "Are you sure you want to save and modify this document?"
  );

  const [DialogAddTask, confirmDialogAddTask] = useConfirm(
    "Add Task",
    "Are you sure you want to add a Task?"
  );
  const [DialogRemoveTask, confirmDialogRemoveTask] = useConfirm(
    "Remove Task",
    "Are you sure you want to remove a Task?"
  );
  const [DialogRemoveRow, confirmDialogRemoveRow] = useConfirm(
    "Remove Row",
    "Are you sure you want to remove this row"
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getOneInternalAuditSchedule/${id}`)
        .then((res) => {
          let auditorData = [];

          res.data.days.forEach((day, dayIdx) => {
            day.processes.forEach((process, prIdx) => {
              if (process.employee_id_auditor) {
                if (!auditorData[dayIdx]) auditorData[dayIdx] = [];
                const obj = {
                  employee_name: process.employee_id_auditor.employee_name,
                  _id: process.employee_id_auditor._id,
                };

                auditorData[dayIdx][prIdx] = obj;
              }
            });
          });
          setAuditorValue(auditorData);
          let auditeeData = [];
          res.data.days.forEach((day, dayIdx) => {
            day.processes.forEach((process, prIdx) => {
              if (process.employee_id_auditee) {
                if (!auditeeData[dayIdx]) auditeeData[dayIdx] = [];
                const obj = {
                  employee_name: process.employee_id_auditee.employee_name,
                  _id: process.employee_id_auditee._id,
                };

                auditeeData[dayIdx][prIdx] = obj;
              }
            });
          });
          setAudieeValue(auditeeData);
          setAuditScheduleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/getAllEmployeeData/`)
      .then((res) => {
        setEmployeeAutocomplete(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmSave();
      if (ans) {
        auditScheduleData.isSubmitted = bool;
        axios
          .put(`/api/updateInternalAuditScheldule/${id}`, auditScheduleData)
          .then((res) => {
            if (res.data.status === "success") {
              alert("updated successfully");
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        auditScheduleData.isSubmitted = bool;
        axios
          .post(
            `/api/creareEditInternalAuditSchedule/${auditId}`,
            auditScheduleData
          )
          .then((res) => {
            if (res.data.status === "success") {
              alert("created successfully");
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleDeleteRow = async (idx) => {
    const ans = await confirmDialogRemoveRow();
    if (!ans) return;
    let data = { ...auditScheduleData };
    data.days.splice(idx, 1);
    setAuditScheduleData(data);
  };

  const handleChangeSchedulePlan = async (e, daysIndex, insideDaysindex) => {
    const { name, value } = e.target;
    auditScheduleData.days[daysIndex].processes[insideDaysindex][name] = value;
    setRender((prev) => !prev);
  };

  const ChecklistAuditInput = ({
    type,
    name,
    value,
    disabled,
    onChange,
    onBlur,
  }) => {
    return (
      <input
        type={type}
        name={name}
        defaultValue={value || ""}
        disabled={disabled}
        autoComplete="off"
        className="input"
        style={{ border: "2px solid #f9e0e0" }}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  };

  return (
    <div
      style={{
        paddingBottom: "4rem",
        position: "relative",
      }}
    >
      <DragableButton left>
        <ArrowBackIosIcon style={{ color: "#fff" }} />
      </DragableButton>
      <DragableButton right>
        <ArrowForwardIosIcon style={{ color: "#fff" }} />
      </DragableButton>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
            textTransform: "uppercase",
          }}
        >
          Internal Audit Schedule Report
        </Typography>
        <div style={{ display: "flex" }}>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            <Wrapper>
              <Wrapper
                style={{
                  height: "45px",
                  width: "175px",
                  backgroundColor: "#1864ab",
                  color: "white",
                  cursor: "pointer",
                  display: "flex",
                  gap: "0.5rem",
                  marginRight: "0.2rem",
                }}
                onClick={() => {
                  auditScheduleData &&
                    auditScheduleData.days.push({
                      day_date: new Date(),
                      processes: Array.from({ length: 10 }, (_) => ({
                        process_name: "",
                        time_from: "",
                        time_to: "",
                        employee_id_auditor: null,
                        employee_id_auditee: null,
                        standard_clause_ref: "",
                      })),
                    });
                  setExpandTable((prev) => {
                    return [
                      ...prev,
                      (prev[auditScheduleData.days.length - 1] = true),
                    ];
                  });
                  setRender((prev) => !prev);
                }}
                justify="center"
              >
                <Add />
                <span>ADD ROWS</span>
              </Wrapper>
            </Wrapper>

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#064a29",
                marginRight: "0.5rem",
              }}
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                handleSubmitAndSave(true);
              }}
            >
              Save And Continue
            </Button>
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </Wrapper>
        </div>
      </div>
      <TableContainerStyled id="machinetable">
        <Table>
          <thead>
            <tr data-height="80" style={{ height: "70px" }}>
              <th
                colSpan="7"
                rowSpan={3}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  position: "relative",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="120"
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th
                style={{
                  border: "1px solid black",
                }}
              >
                Format:- Doc./F/IA/02
              </th>
            </tr>
            <tr>
              <th style={{ bo5er: "1px solid black" }} data-f-sz="10">
                Rev. 00
              </th>
            </tr>
            <tr>
              <th style={{ bo5er: "1px solid black" }} data-f-sz="10">
                Date:- 02.07.2021
              </th>
            </tr>
            <tr>
              <th
                colSpan="8"
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="18"
                data-a-h="center"
                data-f-bold="true"
                data-fill-color="#1d1d1d"
                data-f-color="FFFFFF"
              >
                INTERNAL AUDIT SCHEDULE
              </th>
            </tr>

            <tr>
              <th colSpan="1">Plan Date:-</th>
              <th colSpan="3">
                {isView ? (
                  formatDate(auditScheduleData?.plant_date)
                ) : (
                  <DatePicker
                    name="date_performance"
                    format="dd/MM/yyyy"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    style={{
                      backgroundColor: "#fae8a5",
                      paddingLeft: "25px",
                      width: "150px",
                    }}
                    value={auditScheduleData?.plant_date}
                    onChange={(date) => {
                      auditScheduleData.plant_date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </th>

              <th colSpan="1">Scope:-</th>
              <th colSpan="3">
                {isView ? (
                  auditScheduleData?.scopes
                ) : (
                  <ChecklistAuditInput
                    type="text"
                    name="scopes"
                    value={auditScheduleData?.scopes}
                    onBlur={(e) => {
                      auditScheduleData.scopes = e.target.value;
                    }}
                  />
                )}
              </th>
            </tr>

            <tr>
              {[
                "Sr. No.",
                "Process Name",
                "Time",
                "Auditor",
                "Auditee",
                "Standard/ Clause Ref.",
              ].map((el, index) => (
                <th
                  colSpan={
                    el === "Time" || el === "Standard/ Clause Ref." ? 2 : 1
                  }
                  rowSpan={el === "Time" ? 1 : 2}
                  align="center"
                  style={{ textAlign: "center" }}
                >
                  {el}
                </th>
              ))}
            </tr>
            <tr>
              <th colSpan="1" align="center">
                From
              </th>
              <th colSpan="1" align="center">
                To
              </th>
            </tr>
          </thead>
          <tbody>
            {auditScheduleData.days.length > 0 &&
              auditScheduleData.days.map((day, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <th style={{ textAlign: "center" }}>Day-{index + 1}</th>
                    <th colSpan={5} style={{ background: "#fcefbf" }}>
                      {isView ? (
                        formatDate(day?.day_date)
                      ) : (
                        <DatePicker
                          name="date_performance"
                          format="dd/MM/yyyy"
                          InputProps={{
                            disableUnderline: true,
                          }}
                          style={{
                            backgroundColor: "#fae8a5",
                            paddingLeft: "25px",
                            width: "150px",
                          }}
                          value={day?.day_date}
                          onChange={(date) => {
                            day.day_date = date;
                            setRender((prev) => !prev);
                          }}
                        />
                      )}
                    </th>
                    <td>
                      {
                        <Wrapper
                          style={{
                            height: "45px",
                            backgroundColor: "#495057",
                            cursor: "pointer",
                            color: "white",
                            gap: "0.5rem",
                          }}
                          onClick={() =>
                            setExpandTable((prev) => {
                              prev[index] = !prev[index];
                              return [...prev];
                            })
                          }
                        >
                          {expandTable[index] ? (
                            <>
                              <ExpandLess style={{ marginLeft: "0.5rem" }} />
                              <span>Collapse</span>
                            </>
                          ) : (
                            <>
                              <ExpandMore style={{ marginLeft: "0.5rem" }} />
                              <span>Expand</span>
                            </>
                          )}
                        </Wrapper>
                      }
                    </td>
                    <td
                      style={{
                        cursor: "pointer",
                        minWidth: "30px",
                      }}
                    >
                      {!isView && (
                        <Wrapper
                          justify="center"
                          onClick={(e) => {
                            handleDeleteRow(index);
                          }}
                        >
                          <Tooltip title="Delete this row" arrow>
                            <DeleteIcon style={{ color: " #f03e3e" }} />
                          </Tooltip>
                        </Wrapper>
                      )}
                    </td>
                  </tr>
                  {expandTable[index] &&
                    day.processes.map((item, prcIdx) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>{prcIdx + 1}</td>
                        <td style={{ textAlign: "center" }}>
                          {isView ? (
                            item?.process_name
                          ) : (
                            <ChecklistAuditInput
                              type="text"
                              name="process_name"
                              value={item?.process_name}
                              onBlur={(e) => {
                                handleChangeSchedulePlan(e, index, prcIdx);
                              }}
                            />
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            width: "100px",
                            cursor: "pointer",
                          }}
                        >
                          {isView ? (
                            formatDateForTime(item?.time_from)
                          ) : (
                            <TimePicker
                              name="in_time"
                              style={{
                                backgroundColor: "#f8f9fa",
                                padding: "8px 5px",
                              }}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              value={item.time_from || null}
                              onChange={(date) => {
                                item.time_from = date;
                                setRender((prev) => !prev);
                              }}
                            />
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            width: "100px",
                            cursor: "pointer",
                          }}
                        >
                          {isView ? (
                            formatDateForTime(item?.time_to)
                          ) : (
                            <TimePicker
                              name="in_time"
                              style={{
                                backgroundColor: "#f8f9fa",
                                padding: "8px 5px",
                              }}
                              InputProps={{
                                disableUnderline: true,
                              }}
                              value={item.time_to || null}
                              onChange={(date) => {
                                item.time_to = date;
                                setRender((prev) => !prev);
                              }}
                            />
                          )}
                        </td>

                        <td style={{ textAlign: "center" }}>
                          {isView ? (
                            item?.employee_id_auditor?.employee_name
                          ) : (
                            <Autocomplete
                              id="training_topics"
                              options={employeeAutocomplete}
                              getOptionLabel={(option) => option.employee_name}
                              getOptionSelected={(option, value) =>
                                option.employee_name === value.employee_name
                              }
                              value={auditorValue?.[index]?.[prcIdx]}
                              onChange={(e, value) => {
                                item.employee_id_auditor = value?._id || null;
                                if (!auditorValue[index])
                                  auditorValue[index] = [];
                                auditorValue[index][prcIdx] = value;
                                setRender((prev) => !prev);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                />
                              )}
                            />
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {isView ? (
                            item?.employee_id_auditee?.employee_name
                          ) : (
                            <Autocomplete
                              id="training_topics"
                              options={employeeAutocomplete}
                              getOptionLabel={(option) => option.employee_name}
                              getOptionSelected={(option, value) =>
                                option.employee_name === value.employee_name
                              }
                              value={auditeeValue?.[index]?.[prcIdx]}
                              onChange={(e, value) => {
                                if (!auditeeValue?.[index]?.[prcIdx])
                                  auditeeValue[index] = [];
                                item.employee_id_auditee = value?._id || null;
                                auditeeValue[index][prcIdx] = value;
                                setRender((prev) => !prev);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                  }}
                                />
                              )}
                            />
                          )}
                        </td>
                        <td style={{ textAlign: "center" }} colSpan={2}>
                          {isView ? (
                            item?.standard_clause_ref
                          ) : (
                            <ChecklistAuditInput
                              type="text"
                              name="standard_clause_ref"
                              value={item?.standard_clause_ref}
                              onBlur={(e) => {
                                handleChangeSchedulePlan(e, index, prcIdx);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                </React.Fragment>
              ))}
          </tbody>
        </Table>
        <DialogAddTask isSubmitContent={false} />
        <DialogRemoveTask isSubmitContent={false} />
        <DialogClose isSubmitContent={false} />
        <DialogSave isSubmitContent={false} />
        <DialogRemoveRow isSubmitContent={false} />
      </TableContainerStyled>
    </div>
  );
};

export default AddEditViewSchedulePlan;
