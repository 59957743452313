import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";

const CommonNotification = ({
  isNotification,
  setIsNotification,
  classes,
  msg,
  removeMsg,
}) => {
  const history = useHistory();
  return (
    <div>
      {" "}
      <Menu
        id="simple-menu"
        anchorEl={isNotification}
        keepMounted
        open={Boolean(isNotification)}
        onClose={() => setIsNotification(false)}
        className={classes.noti}
      >
        {msg.length === 0 ? (
          <MenuItem>No New Notification</MenuItem>
        ) : (
          <>
            {msg.length &&
              msg.map((m, index) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "8px",
                  }}
                >
                  <MenuItem
                    key={m._id}
                    onClick={(e) => {
                      history.push(m.link);
                    }}
                  >
                    <div>
                      <span style={{ fontWeight: "600" }}>{m.message}</span>
                    </div>
                  </MenuItem>
                  <IconButton
                    style={{
                      padding: "8px",
                      margin: "0",
                    }}
                    onClick={() => removeMsg(m._id)}
                  >
                    <Close style={{ color: "red" }} />
                  </IconButton>
                </div>
              ))}
          </>
        )}
      </Menu>
    </div>
  );
};

export default CommonNotification;
