import React from "react";
import Styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Button from "@material-ui/core/Button";
import ReactHtmlTableToExcel from "react-html-table-to-excel";
import { Tooltip } from "@material-ui/core";
const Table = Styled.table`
	margin-bottom: 1rem;
    width:100%;
    min-height:450px;
    
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }

`;
const ProcesstWiseTable = ({ processRejection }) => {
  return (
    <div
      style={{
        marginTop: "2.5rem",
        marginLeft: "1rem",
        flex: "0.986",
        position: "relative",
      }}
    >
      <Tooltip title="Export as spreadsheet" placement="left">
        <div style={{ position: "absolute", top: "-3rem", right: 0 }}>
          <ReactHtmlTableToExcel
            id="test-table-xls-button"
            className="btn1"
            table="table-to-xls"
            filename="partWiseReport"
            sheet="partWiseReport"
            buttonText={
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  color: "white",

                  borderRadius: "0",
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            }
          />
        </div>
      </Tooltip>
      <Table>
        <thead>
          <tr>
            <td
              style={{
                fontWeight: "600",
                fontSize: "0.9rem",
                width: "30px",
                border: "1px solid black",
              }}
              align="center"
            >
              #
            </td>
            <td
              style={{
                fontWeight: "600",
                fontSize: "0.9rem",
                border: "1px solid black",
              }}
              align="center"
            >
              PROCESS/OPERATION
            </td>
            <td
              style={{
                fontWeight: "600",
                fontSize: "0.9rem",
                border: "1px solid black",
              }}
              align="center"
            >
              PRODUCED QTY
            </td>
            <td
              style={{
                fontWeight: "600",
                fontSize: "0.9rem",
                border: "1px solid black",
              }}
              align="center"
            >
              OK QTY
            </td>
            <td
              style={{
                fontWeight: "600",
                fontSize: "0.9rem",
                border: "1px solid black",
              }}
              align="center"
            >
              REJECTED QTY
            </td>

            <td
              style={{
                fontWeight: "600",
                fontSize: "0.9rem",
                border: "1px solid black",
              }}
              align="center"
            >
              % REJECTION
            </td>
            <td
              style={{
                fontWeight: "600",
                fontSize: "0.9rem",
                border: "1px solid black",
              }}
              align="center"
            >
              PPM REJECTIONS
            </td>
            <td
              style={{
                fontWeight: "600",
                fontSize: "0.9rem",
                border: "1px solid black",
              }}
              align="center"
            >
              PROCESS EFFICIENCY %
            </td>
          </tr>
        </thead>
        <tbody>
          {Object.keys(processRejection).map((key, index) => (
            <tr key={uuidv4()}>
              <td align="center" style={{ border: "1px solid black" }}>
                {index + 1}
              </td>
              <td align="center" style={{ border: "1px solid black" }}>
                {key}
              </td>
              <td align="center" style={{ border: "1px solid black" }}>
                {processRejection[key].producedQty}
              </td>
              <td align="center" style={{ border: "1px solid black" }}>
                {processRejection[key].producedQty -
                  processRejection[key].rejected}
              </td>
              <td align="center" style={{ border: "1px solid black" }}>
                {processRejection[key].rejected}
              </td>

              <td align="center" style={{ border: "1px solid black" }}>
                {(
                  (processRejection[key].rejected /
                    (processRejection[key].producedQty || 1)) *
                  100
                ).toFixed(2)}
                %
              </td>
              <td align="center" style={{ border: "1px solid black" }}>
                {(
                  (processRejection[key].rejected /
                    (processRejection[key].producedQty || 1)) *
                  1000000
                ).toFixed(2)}
              </td>
              <td align="center" style={{ border: "1px solid black" }}>
                {(
                  ((processRejection[key].producedQty -
                    processRejection[key].rejected) *
                    100) /
                  (processRejection[key].producedQty || 1)
                ).toFixed(2)}
                %
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ProcesstWiseTable;
