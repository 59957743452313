import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SelectChecklist = ({ checkListCurrentID }) => {
  const [checkMachine, setCheckMachine] = useState("None");
  const history = useHistory();
  const selectChecklistValue = [
    "None",
    "Air_Compressor",
    "Bend_Shaw",
    "Centr_Grinding",
    "Centreless_Gr",
    "CNC",
    "CTR",
    "Drill_Machine",
    "Drill_Machine_Manual",
    "Generator",
    "HP",
    "Hydrau",
    "IH",
    "VMC",
    "Traub_Machine",
    "PM_MILLING",
    "PM_Buffing_Machine",
    "PM_Straighting_Machine",
    "PM_Shearing_Machine",
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <FormControl
        style={{
          width: "250px",
          marginRight: "0.5rem",
          marginTop: "0.6rem",
          marginLeft: "0.6rem",
        }}
        variant="filled"
        className="select-field"
      >
        <InputLabel>Select Machine</InputLabel>
        <Select
          name="checkMachine"
          key={checkMachine}
          defaultValue={checkMachine}
          onChange={(e) => {
            setCheckMachine(e.target.value);
          }}
          className="spec-select-character"
        >
          {selectChecklistValue.map((machine, index) => {
            return (
              <MenuItem key={index} value={machine}>
                {machine.replaceAll("_", " ")}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Button
        type="submit"
        variant="outlined"
        // color="white"
        size="large"
        style={{
          backgroundColor: "green",
          height: "50px",
          marginTop: "0.5rem",
        }}
        // startIcon={<PublishIcon />}
        onClick={() => {
          history.push(
            `/maintenanceForCheckedMachineForm/${checkListCurrentID}/${checkMachine}`
          );
        }}
      >
        OK
      </Button>
    </div>
  );
};

export default SelectChecklist;
