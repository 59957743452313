import React from "react";
import { Link } from "react-router-dom";
import clausePage from "./ClausePage.module.css";
//icons
import CreateIcon from "@material-ui/icons/Create";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import TimelineIcon from "@material-ui/icons/Timeline";
import ListIcon from '@material-ui/icons/List';
// end icons

const Clause1 = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        PRODUCTION PREPARATION / NEW PRODUCT DEVELOPEMENT
      </div>
      <main className={`${clausePage.container} ${clausePage.main}`}>
        <div>
          <Link to="/masterProcedureList?category=clause-1" className="panel">
             <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Master <span className="card-btn-span">Procedure</span>
          </p>
        </div>
        <div>
          <Link to="/npd/part_list" className="panel">
            <CreateIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Add <span className="card-btn-span">Part</span>
          </p>
        </div>
        <div>
          <Link to="/apqpMatrixList" className="panel">
            <FormatAlignCenterIcon
              style={{ fontSize: "3rem", color: "#1864ab" }}
            />
          </Link>
          <p className="card-btn-para">
            APQP <span className="card-btn-span">MATRIX</span>
          </p>
        </div>
        <div>
          <Link to="/apqpTimePlanList" className="panel">
            <TimelineIcon style={{ fontSize: "3rem", color: "#d9480f" }} />
          </Link>
          <p className="card-btn-para">
            APQP TIME<span className="card-btn-span">PLAN</span>
          </p>
        </div>
      </main>
    </>
  );
};

export default Clause1;
