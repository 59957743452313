import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Wrapper } from "../../../components/Control/Flexbox";
import { images, textInputs } from "../../../constants/constants";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../../components/Control/useConfirm";
import {
  ButtonStyled,
  TableContainerStyled,
} from "../../DailyProduction/styles";
import { useQuery } from "../../../components/Control/useQuery";
import formatDate from "../../../components/Control/formatDate";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        height: 50px;
        text-align:center;
    }

`;
// const dropdownStyles = makeStyles({
//   root: {
//     backgroundColor: "#fff",
//   },
//   select: {
//     "&:hover": {
//       backgroundColor: "#fff",
//     },
//     "&:focus": {
//       backgroundColor: "#fff",
//     },
//   },
//   underline: {
//     borderBottom: "0px solid red !important",
//     "&:hover": {
//       borderBottom: "0px solid rgba(0,0,0,0)",
//     },
//   },
// });

// symbol for current level
const selectSymbolForLevel = {
  Level_1: "◔",
  Level_2: "◑",
  Level_3: "◕",
  Level_4: "⬤",
};

const levelText = (text) => {
  return text.replace("_", " ");
};
const AddEditViewSkillMatrix = () => {
  // const classes = dropdownStyles();
  const { id } = useParams();
  const [render, setRender] = useState(false);
  const history = useHistory();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const [skillMatrixData, setSkillMatrixData] = useState({
    remarks: {},
    employee_details: [],
  });

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to Submit?"
  );

  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/getSkillMatrix/${id}`)
      .then((res) => {
        setSkillMatrixData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmUpdate();
      if (ans) {
        axios
          .put(`/api/updateSkillMatrix/${id}`, skillMatrixData)
          .then((res) => {
            alert("updated successfully");
          })
          .then(() => {
            if (bool) {
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };

  // console.log(skillMatrixData);
  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 80;
    } else {
      document.getElementById("machinetable").scrollLeft -= 80;
    }
  };

  return (
    <section>
      <div
        style={{
          paddingBottom: "4rem",
        }}
      >
        <ButtonStyled
          left
          onClick={(e) => {
            handleScroll("left");
          }}
        >
          <div>
            <ArrowBackIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <ButtonStyled
          right
          onClick={(e) => {
            handleScroll("right");
          }}
        >
          <div>
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            SKILL MATRIX
          </Typography>
          {!isView && (
            <div style={{ display: "flex" }}>
              <Wrapper
                style={{
                  gap: "1rem",
                }}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#003566",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    handleSubmitAndSave(false);
                  }}
                >
                  Save & Continue
                </Button>

                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{
                    backgroundColor: "#064a29",
                    marginRight: "0.5rem",
                  }}
                  startIcon={<CloudUploadIcon />}
                  onClick={() => {
                    handleSubmitAndSave(true);
                  }}
                >
                  Submit
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            </div>
          )}
        </div>
        <TableContainerStyled id="machinetable">
          <Table
            cellSpacing="0"
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan="2"
                  rowSpan={3}
                  align="left"
                  style={{
                    border: "1px solid black",
                    // fontSize: "1.6rem",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="80"
                  />
                </th>
                <th
                  colSpan="4"
                  rowSpan={3}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  SKILL MATRIX
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  DOC NO :
                </th>
                <th
                  style={{
                    border: "1px solid black",
                  }}
                >
                  ARC/F/HRD/06
                </th>
              </tr>
              <tr>
                <th style={{ border: "1px solid black" }}>REV NO.</th>
                <th style={{ border: "1px solid black" }}>02</th>
              </tr>
              <tr>
                <th style={{ border: "1px solid black" }}>REV DATE:</th>
                <th style={{ border: "1px solid black" }}>01.12.2021</th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                  colSpan={8}
                >
                  Department : {skillMatrixData?.department_id?.department_name}
                </th>
              </tr>
              <tr>
                <th
                  style={{
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                  colSpan="2"
                >
                  REVIEWED ON :
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                  colSpan="2"
                >
                  {formatDate(skillMatrixData?.reviewed_on_date)}
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                  colSpan="2"
                >
                  NEXT REVIEW ON :
                </th>
                <th
                  style={{
                    border: "1px solid black",
                    textTransform: "uppercase",
                  }}
                  colSpan="2"
                >
                  {formatDate(skillMatrixData?.next_reviewed_on_date)}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "S. NO.",
                  "NAME",
                  "PHOTO",
                  "DESIGNATION",
                  "SECTION",
                  "MIN. REQUIRED SKILL (L-2):",
                  "◑",
                  "REMARKS",
                ].map((heading, index) => (
                  <th
                    key={index + 10.0002}
                    colSpan={1}
                    rowSpan={index === 5 || index === 6 ? 1 : 2}
                    style={{
                      fontSize: `${index === 6 ? "1.2rem" : ""}`,
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
              <tr>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  CURRENT SKILL LEVEL
                </th>
              </tr>
              {skillMatrixData &&
                skillMatrixData?.employee_details?.map((employee, index) => (
                  <tr key={employee._id || index + 30.0003}>
                    <th
                      style={{
                        width: "100px",
                        border: "1px solid black",
                        textAlign: "center",
                        padding: "0 0.4rem",
                        backgroundColor: "#f8f9fa",
                      }}
                    >
                      {index + 1}
                    </th>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {employee?.employee_name}
                    </td>

                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {employee?.photo}

                      {/* {formatDate(skillMatrixData.irmResister_date)} */}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {employee?.designation}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {skillMatrixData?.department_id?.department_name}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {levelText(employee?.current_level)}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                        fontSize: "1.5rem",
                      }}
                    >
                      {selectSymbolForLevel[employee?.current_level]}
                    </td>
                    <td
                      align="center"
                      style={{
                        border: "1px solid black",
                      }}
                    >
                      {isView ? (
                        skillMatrixData?.remarks &&
                        skillMatrixData?.remarks[employee?._id]
                      ) : (
                        <input
                          autoComplete="off"
                          className="input"
                          style={{
                            border: "2px solid #f9e0e0",
                            height: "30px",
                            textAlign: "left",
                          }}
                          name="remarks"
                          type="text"
                          value={
                            skillMatrixData?.remarks &&
                            skillMatrixData?.remarks[employee?._id]
                          }
                          onChange={(e) => {
                            setSkillMatrixData((value) => ({
                              ...value,
                              remarks: {
                                ...value.remarks,
                                [employee._id]: e.target.value,
                              },
                            }));
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
            <tfoot>
              <tr>
                <th
                  colSpan={8}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  DEFINED SKILL LEVELS
                </th>
              </tr>
              <tr>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  LEVEL-01
                </th>
                <th
                  colSpan={3}
                  rowSpan="2"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  No Training/ Awarenss
                </th>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  LEVEL-02
                </th>
                <th
                  colSpan={3}
                  rowSpan="2"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Can Work Under Supervision
                </th>
              </tr>
              <tr>
                <td
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    fontSize: "1.5rem",
                  }}
                >
                  ◔
                </td>
                <td
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    fontSize: "1.5rem",
                  }}
                >
                  ◑
                </td>
              </tr>

              <tr>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  LEVEL-03
                </th>
                <th
                  colSpan={3}
                  rowSpan="2"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Can Work Independetly+Troubleshooting
                </th>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  LEVEL-04
                </th>
                <th
                  colSpan={3}
                  rowSpan="2"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Can Work I ndependetly+Troubleshooting+Can Train Others
                </th>
              </tr>
              <tr>
                <td
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    fontSize: "1.5rem",
                  }}
                >
                  ◕
                </td>
                <td
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    fontSize: "1.5rem",
                  }}
                >
                  ⬤
                </td>
              </tr>
              <tr>
                <th
                  colSpan={4}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  PRERARED BY :
                </th>
                <th
                  colSpan={4}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  APPROVED BY :
                </th>
              </tr>
            </tfoot>
          </Table>
        </TableContainerStyled>
      </div>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </section>
  );
};

export default AddEditViewSkillMatrix;
