import { Box } from "@mui/system";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { CustomButton, SubmitButton } from "../../../common/CustomButtons";
import useConfirm from "../../../common/useConfirm";
import ChildHeader from "../../../common/ChildHeader";
import TController from "./components/TController";
import lodash from "lodash";
import ModalCustom from "../../../common/Modal";
import { Paper, Tab, Tabs } from "@mui/material";
import PictorialTable from "./components/PictorialTable";
import ViewFile from "./ViewFile";
import { useQuery } from "../../../components/Control/useQuery";
import PickPart from "../../../common/PickPart";
const Create = () => {
  const history = useHistory();
  const [tabValue, setTabValue] = useState(1);
  const [moduleData, setModuleData] = useState({
    processes: [],
  });
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const annual_month = query.get("annual_date") || "";
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [pictorial, setPictorial] = useState({
    man: Array.from({ length: 31 }, () => false),
    machine: Array.from({ length: 31 }, () => false),
    material: Array.from({ length: 31 }, () => false),
    method: Array.from({ length: 31 }, () => false),
  });
  const [partIdx, setPartIdx] = useState(0);
  const [useEffectRender, setUseEffectRender] = useState(false);
  const [DialogAddRow, confirmAddRow] = useConfirm(
    "Add Row",
    "Are you sure you want to Add Row?",
  );
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    "DELETE",
    "Are you sure you want to delete Row?",
  );
  const [render, setRender] = useState(false);
  const [isTrainingFileOpenPopup, setIsTrainingFileOpenPopup] = useState(false);
  const triningFileIdRef = useRef("");

  useEffect(() => {
    axios
      .get(`/api/forMSummaryRoute/${annual_month}`, moduleData)
      .then((res) => {
        if (res.data) {
          res.data.processes.forEach((process) => {
            if (process.change_type && process.date) {
              let date = new Date(process.date).getDate();
              let values = { ...pictorial };
              values[process.change_type][date - 1] = true;
              setPictorial(values);
            }
          });
          setModuleData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [useEffectRender]);
  const onSubmit = async () => {
    return new Promise(async (resolve, reject) => {
      if (moduleData._id) {
        await axios
          .put(`/api/forMSummaryRoute/${moduleData._id}`, moduleData)
          .then((res) => {
            if (res.data) {
              alert("updated successfully ");
              setUseEffectRender((prev) => !prev);
              resolve();
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        if (!annual_month) {
          return alert("Please pick annual year?");
        }
        moduleData.annual_month = annual_month;
        await axios
          .post(`/api/forMSummaryRoute`, moduleData)
          .then((res) => {
            alert("created successfully");
            setUseEffectRender((prev) => !prev);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }
    });
  };
  const handelDeleteRows = async (rowIdx) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="4M SUMMARY SHEET">
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: "#1c7ed6",
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.processes.push(lodash.cloneDeep({}));
                  await onSubmit();
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton
                label={moduleData._id ? `UPDATE` : `SUBMIT`}
                onClick={() => onSubmit()}
              />
            </>
          )}
        </ChildHeader>
        <Paper elevation={2}>
          <Tabs
            value={tabValue}
            onChange={(_, newValue) => setTabValue(+newValue)}
            indicatorColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            textColor="secondary"
          >
            <Tab label={"Pictorial Table"} sx={{ fontSize: "1rem" }} />
            <Tab label={"4m Summary"} sx={{ fontSize: "1rem" }} />
          </Tabs>
        </Paper>
        {tabValue === 0 && (
          <PictorialTable {...{ moduleData, isView, pictorial }} />
        )}
        {tabValue === 1 && (
          <TController
            {...{
              moduleData,
              pictorial,
              setPictorial,
              handelDeleteRows,
              isView,
              setIsOpenPopup,
              setPartIdx,
              onSubmit,
              setIsTrainingFileOpenPopup,
              triningFileIdRef,
            }}
          />
        )}
      </Box>
      <DialogAddRow />
      <DialogDeleteRow />
      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickPart moduleData={moduleData.processes[partIdx]} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isTrainingFileOpenPopup}
        closeModal={() => {
          setIsTrainingFileOpenPopup(false);
        }}
      >
        <ViewFile id={triningFileIdRef.current} />
      </ModalCustom>
    </Box>
  );
};

export default Create;
