import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import SyncIcon from "@material-ui/icons/Sync";
import Loader from "../../components/Control/Loader";
import { CloseButton } from "../../GlobalUtils/ButtonsGlobal";
import { Wrapper } from "../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import { images, textInputs } from "../../constants/constants";
import useConfirm from "../../components/Control/useConfirm";
import { TableContainerStyled, Table } from "./Container.styled";
import DragableButton from "../../components/Control/Dragable";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import TableToExcel from "@dayalk/table-to-excel";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const ProcessAuditEditView = () => {
  const classes = dropdownStyles();
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsloading] = useState(false);
  const [renderSync, setRenderSync] = useState(false);
  const [render, setRender] = useState(false);
  const [data, setData] = useState({
    processes: [],
  });
  const [getformatedYear, setFromatedYear] = useState({
    currentYear: "",
    nextYear: "",
  });

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save and modify this document?"
  );

  const [DialogAddTask, confirmDialogAddTask] = useConfirm(
    "Add Task",
    "Are you sure you want to add a Task?"
  );
  const [DialogRemoveTask, confirmDialogRemoveTask] = useConfirm(
    "Remove Task",
    "Are you sure you want to remove a Task?"
  );
  const [DialogRemoveRow, confirmDialogRemoveRow] = useConfirm(
    "Remove Row",
    "Are you sure you want to remove this row"
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getProcessAuditById/${id}`)
        .then((res) => {
          setData(res?.data);
          const year = getYearRange(res?.data?.date_range);
          setFromatedYear((prev) => ({
            ...prev,
            currentYear: year.split("-")[0].trim().slice(2),
            nextYear: year.split("-")[1].trim().slice(2),
          }));
        })
        .catch((err) => console.log(err));
    }
  }, [id, renderSync]);

  const handleDoubleClick = async (event, month, idx) => {
    if (event.detail === 2) {
      if (data.processes[idx][month].isChecked) {
        let ans = await confirmDialogRemoveTask();
        if (ans) {
          data.processes[idx][month].isChecked = false;
          setRender((prev) => !prev);
        }
      } else {
        let ans = await confirmDialogAddTask();
        if (ans) {
          data.processes[idx][month].isChecked = true;
          setRender((prev) => !prev);
        }
      }
    }
  };

  function MouseOver(event) {
    event.target.style.transform = "scale(1.2)";
    event.target.style.transition = "200ms";
    event.target.style.border = "1px solid #ced4da";
    event.target.style.borderRadius = "5px";
    event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.2)";
  }
  function MouseOut(event) {
    event.target.style.transform = "scale(1)";
    event.target.style.border = "none";
    event.target.style.boxShadow = "none";
    event.target.style.transition = "200ms";
  }
  function MouseDown(event) {
    event.target.style.transform = "scale(1.1)";
    event.target.style.transition = "300ms";
    event.target.style.border = "1px solid #868e96";
    event.target.style.boxShadow = "0 5px 15px rgba(0, 0, 0, 0.3)";
  }

  const handleSaveAndContinue = async () => {
    const ans = await confirmSave();
    if (!ans) return;
    axios
      .put(`/api/updateProcessAudit/${id}`, data)
      .then((res) => {
        window.alert("success");
        history.goBack();
      })
      .catch((err) => console.log(err));
  };

  const handleSync = async () => {
    setIsloading(true);
    axios
      .get(`/api/syncProcessForProcessAudit/${id}`)
      .then((res) => {
        // setData((prev) => ({ ...prev, ...res.data.processes }));
        setRenderSync((prev) => !prev);
        const timer = setTimeout(() => {
          setIsloading(false);
          window.alert("successfully updated");
        }, 1000);
      })
      .catch((err) => {
        const timer = setTimeout(() => {
          setIsloading(false);
        }, 1000);
        console.log(err);
      });
  };

  return (
    <div
      style={{
        paddingBottom: "4rem",
        position: "relative",
      }}
    >
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            zIndex: "100",
            top: "50%",
            right: "50%",
            transform: "translate(50%,-50%)",
          }}
        >
          {<Loader height={"100vh"} />}
        </div>
      ) : (
        ""
      )}
      <DragableButton left>
        <ArrowBackIosIcon style={{ color: "#fff" }} />
      </DragableButton>
      <DragableButton right>
        <ArrowForwardIosIcon style={{ color: "#fff" }} />
      </DragableButton>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          PROCESS AUDIT PLAN
        </Typography>
        <div style={{ display: "flex" }}>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#1864ab",
                marginRight: "0.5rem",
              }}
              startIcon={<SyncIcon />}
              onClick={() => {
                handleSync(true);
              }}
            >
              Sync Process
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#064a29",
                marginRight: "0.5rem",
              }}
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                handleSaveAndContinue();
              }}
            >
              Save And Continue
            </Button>
            <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  color: "white",
                  width: "fit-content",
                  borderRadius: "0",
                  paddingTop: "9px",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("machinetable"),
                    {
                      name: "Process Audit Plan Report.xlsx",
                      sheet: {
                        name: "Process Audit Plan Report",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </Wrapper>
        </div>
      </div>
      <TableContainerStyled >
        <Table style={{ padding: "2rem" }} 
        data-default-wrap="true"
        id="machinetable"
        data-cols-width="10, 10, 10, 20, 10, 18, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 10"
        >
          <thead>
            <tr>
              <th
                colSpan="3"
                rowSpan={2}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <div>
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="44"
                    width="120"
                  />
                </div>
              </th>
              <th
                colSpan={12}
                rowSpan={2}
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  textAlign: "center",
                }}
              >
                PROCESS AUDIT PLAN
              </th>
              <th
                colSpan={4}
                style={{
                  border: "1px solid black",
                }}
              >
                Doc.No.-ARC/F/QAD/01
              </th>
            </tr>
            <tr>
              <th colSpan={4}>Rev.No./Date-01/06.05.21</th>
            </tr>
            <tr>
              <th colSpan={5}></th>
              <th
                colSpan={1}
                style={{
                  textAlign: "center",
                }}
              >
                DUE
              </th>
              <th
                colSpan={1}
                style={{
                  fontSize: "2rem",
                  textAlign: "center",
                }}
              >
                ⬭
              </th>
              <th colSpan={2}></th>
              <th
                colSpan={1}
                style={{
                  textAlign: "center",
                }}
              >
                DONE
              </th>
              <th
                colSpan={1}
                style={{
                  fontSize: "2rem",
                  textAlign: "center",
                }}
              >
                ⬬
              </th>
              <th colSpan={2}></th>
              <th colSpan={2}>Reschedule </th>
              <th
                colSpan={4}
                style={{
                  fontSize: "2rem",
                  textAlign: "center",
                }}
              >
                ▲
              </th>
            </tr>
            <tr>
              <th
                colSpan={19}
                style={{ textAlign: "center", fontSize: "1.2rem" }}
              >
                Year : {getYearRange(data?.date_range)}{" "}
              </th>
            </tr>
            <tr>
              {[
                "S.NO.",
                "Part Name",
                "Part No.",
                "Process Names",
                "Dia.",
                "Customer Name",
                `APR`,
                `MAY`,
                `JUN`,
                `JUL`,
                `AUG`,
                `SEP`,
                `OCT`,
                `NOV`,
                `DEC`,
                `JAN`,
                `FEB`,
                `MAR`,
                "Remarks",
              ].map((heading, index) => (
                <th
                  key={index + "heading"}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {heading}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.processes &&
              data?.processes?.map((item, index) => (
                <React.Fragment key={item?.control_id?._id}>
                  <tr style={{ height: "80px", maxWidth: "80px" }}>
                    <td align="center">{index + 1}</td>
                    <td
                      align="center"
                      style={{ width: "150px", minWidth: "150px" }}
                    >
                      {item?.control_id?.part_id?.part_name}
                    </td>
                    <td
                      align="center"
                      style={{ width: "150px", minWidth: "150px" }}
                    >
                      {item?.control_id?.part_id?.part_number}
                    </td>
                    <td
                      align="center"
                      style={{ width: "350px", minWidth: "350px" }}
                    >
                      {item?.process_name}
                    </td>
                    <td style={{ width: "150px", minWidth: "150px" }}>
                      <input
                        autoComplete="off"
                        className="input"
                        name="dia"
                        style={{
                          border: "1px solid #f9e0e0",
                          height: "50px",
                        }}
                        type="textfield"
                        defaultValue={item?.dia}
                        onChange={(e) => {
                          item.dia = e.target.value;
                        }}
                      />
                    </td>
                    <td
                      align="center"
                      style={{ width: "150px", minWidth: "150px" }}
                    >
                      {item?.control_id?.part_id?.customer_id?.customer_name}
                    </td>

                    {[
                      "april",
                      "may",
                      "june",
                      "july",
                      "august",
                      "september",
                      "october",
                      "november",
                      "december",
                      "january",
                      "february",
                      "march",
                    ].map((month, idx) => (
                      <td
                        key={idx + "monthIndex"}
                        style={{
                          padding: "0px",
                          margin: "0px",
                          backgroundColor: "#f1f3f5",
                          minWidth: "100px",
                          width: "100px",
                        }}
                        onClick={(e) => handleDoubleClick(e, month, index)}
                      >
                        <span
                          onMouseOver={MouseOver}
                          onMouseOut={MouseOut}
                          onMouseDown={MouseDown}
                          style={{
                            fontSize: "3rem",
                            display: "flex",
                            outline: "none",
                            height: "50px",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#495057",

                            backgroundColor: "#f1f3f5",
                            boxShadow: "0 30px 40px rgb(255, 227, 227,.2)",
                            cursor: "pointer",
                            textShadow: "1px 1px #b0b0b0",
                          }}
                        >
                          {(item[month]?.isDoneChecklist === "completed" &&
                            " ⬬") ||
                            (item[month]?.isChecked && "⬭")}
                        </span>
                      </td>
                    ))}
                    <td>
                      <input
                        autoComplete="off"
                        className="input"
                        name="remarks"
                        style={{
                          border: "1px solid #f9e0e0",
                          height: "50px",
                          width: "150px",
                          minWidth: "150px",
                        }}
                        type="textfield"
                        defaultValue={item?.remarks}
                        onChange={(e) => {
                          item.remarks = e.target.value;
                        }}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={9}>Prepared By:- </th>
              <th colSpan={10}>Approved By:- </th>
            </tr>
          </tfoot>
        </Table>
        <DialogAddTask isSubmitContent={false} />
        <DialogRemoveTask isSubmitContent={false} />
        <DialogClose isSubmitContent={false} />
        <DialogSave isSubmitContent={false} />
        <DialogRemoveRow isSubmitContent={false} />
      </TableContainerStyled>
    </div>
  );
};

export default ProcessAuditEditView;
