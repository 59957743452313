import React from "react";
import Styled from "styled-components";

const Input = Styled.input`
    outline: none;
    width: 100%;
    border: none;
    height: 25px;
`;
const TableInput = ({
  defaultValue,
  isView,
  name,
  value,
  disabled,
  type,
  label,
  onChange,
  style,
}) => {
  const handleWheel = (event) => {
    event.currentTarget.blur();
  };
  return (
    <div
      style={{
        border: isView ? "none" : "1px solid #f9e0e0",
        padding: "0.1rem",
        display: isView ? "inline-block" : "block",
        textAlign: "center",
      }}
    >
      {isView ? (
        value || defaultValue
      ) : (
        <Input
          type={type}
          placeholder={label}
          disabled={disabled}
          name={name}
          value={value || ""}
          autoComplete="off"
          style={style}
          defaultValue={defaultValue}
          onChange={onChange}
          onWheel={handleWheel}
        />
      )}
    </div>
  );
};

export default TableInput;
