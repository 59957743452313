import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../../../context/GlobalContext";
import formatDate from "../../../../components/Control/formatDate";
import { Wrapper } from "../../../../components/Control/Flexbox";
import "../../../../style/controlPlan.css";
import Loader from "../../../../components/Control/Loader";
import Pagination from "../../../../components/Control/Pagination";
import { CenteredLogoImg } from "../../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../../constants/constants";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import Tooltip from "@material-ui/core/Tooltip";
import Styled from "styled-components";
import PrintIcon from "@material-ui/icons/Print";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
  margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0
        }
        b{
		    font-family:"Roboto",sans-serif;
          margin:0,
        }
    }
`;

export default function ControlPlanTableSheet({ Data, controlPlanId }) {
  const { state } = useAuth();
  // console.log(Data, "control");

  return (
    <>
      {Data ? (
        <SpanningTable ControlPlanData={Data} controlPlanId={controlPlanId} />
      ) : (
        <Loader />
      )}
    </>
  );
}

function SpanningTable({ ControlPlanData, controlPlanId }) {
  const controlData = ControlPlanData;
  console.log(controlData);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [processesPerPage] = useState(3);
  // Get current processes
  const indexOfLastProcess = currentPage * processesPerPage;
  const indexOfFirstProcess = indexOfLastProcess - processesPerPage;
  const currentProcesses = controlData?.processes?.slice(
    indexOfFirstProcess,
    indexOfLastProcess
  );
  const lastpage = Math.ceil(controlData?.processes?.length / processesPerPage);
  // Change Page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  return (
    <>
      {controlPlanId ? (
        <div className="divflex">
          <Typography
            variant="inherit"
            style={{
              fontSize: "24px",
            }}
          >
            CONTROL PLAN
          </Typography>

          <Wrapper justify="space-evenly">
            <Tooltip arrow title="Print table">
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip>

            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    color: "white",
                    width: "fit-content",
                    borderRadius: "0",
                    paddingTop: "9px",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: `Control Plan - ${controlData?.part_id?.part_number}.xlsx`,
                        sheet: {
                          name: "Process Control Method",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </Wrapper>
        </div>
      ) : (
        <Wrapper
          style={{
            position: "absolute",
            top: "0.2rem",
            right: "1rem",
            zIndex: "100000",
          }}
        >
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: `Control Plan - ${controlData?.part_id?.part_number}.xlsx`,
                      sheet: {
                        name: "Process Control Method",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      )}

      <div
        className="card_table"
        style={{ padding: "0.6rem", overflowX: "scroll" }}
      >
        {controlData && (
          <Table style={{ width: "100%" }}>
            <thead>
              <tr
                style={{
                  height: "100px",
                }}
              >
                <th
                  colSpan="16"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                    height: "100px",
                  }}
                >
                  <CenteredLogoImg>
                    <img
                      src={images?.arcLogo}
                      alt={textInputs?.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs?.companyName}</span>
                  </CenteredLogoImg>
                </th>
              </tr>
              {/* 2nd row */}
              <tr data-height="40">
                <td
                  colSpan={16}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                  }}
                  data-fill-color="FF000000"
                >
                  PROCESS CONTROL STANDARD
                </td>
              </tr>
              {/* row 3 */}
              <tr>
                {controlData?.part_type === "prototype" ? (
                  <td
                    colSpan="2"
                    style={{
                      border: "1px solid black",
                    }}
                    align="left"
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      ☑
                    </span>
                    <span>Proto Type</span>
                  </td>
                ) : (
                  <td
                    colSpan="2"
                    align="left"
                    style={{ border: "1px solid black" }}
                  >
                    <span
                      style={{
                        fontSize: "1.3rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      □
                    </span>
                    <span>Proto Type</span>
                  </td>
                )}
                {controlData?.part_type === "pre-Launch" ? (
                  <td
                    colSpan="2"
                    style={{
                      border: "1px solid black",
                    }}
                    align="left"
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      ☑
                    </span>
                    <span>Pre-Launch</span>
                  </td>
                ) : (
                  <td
                    colSpan="2"
                    align="left"
                    style={{ border: "1px solid black" }}
                  >
                    <span
                      style={{
                        fontSize: "1.3rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      □
                    </span>
                    <span>Pre-Launch</span>
                  </td>
                )}

                {controlData?.part_type === "production" ? (
                  <td
                    colSpan="2"
                    align="left"
                    style={{
                      border: "1px solid black",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      ☑
                    </span>
                    <span>Production</span>
                  </td>
                ) : (
                  <td
                    colSpan="2"
                    align="left"
                    style={{ border: "1px solid black" }}
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      □
                    </span>
                    <span>Production</span>
                  </td>
                )}
                <td
                  align="left"
                  colSpan="4"
                  style={{ border: "1px solid black" }}
                >
                  <b>Control Plan Number:</b>
                  {controlData?.control_plan_no}
                </td>

                <th
                  colSpan={5}
                  align="left"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                >
                  Doc No.
                </th>
                <th align="left" style={{ border: "1px solid black" }}>
                  ARC/F/QAD/14
                </th>
              </tr>

              <tr>
                <th
                  colSpan="2"
                  rowSpan={2}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  Customer
                </th>
                <td
                  colSpan="4"
                  rowSpan={2}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  {controlData?.part_id?.customer}
                </td>
                <td
                  colSpan={4}
                  // rowSpan={2}
                  align="left"
                  style={{ border: "1px solid black" }}
                >
                  <b>Contact Details</b> : {controlData?.part_id?.key_contact}
                </td>
                {/* hardcoded */}
                <td
                  style={{ border: "1px solid black" }}
                  colSpan={5}
                  align="left"
                >
                  <b>Date (Original)</b>:{" "}
                  {formatDate(controlData?.part_id?.date_orig)}
                </td>
                {/* hardcoded */}
                <td style={{ border: "1px solid black" }}>
                  <b>Rev.No./Date:-</b> 00/01-10-2017
                </td>
              </tr>
              <tr>
                <td
                  style={{ border: "1px solid black" }}
                  colSpan={4}
                  align="left"
                >
                  <b>Core Team</b>: {controlData?.part_id?.core_team}
                </td>

                <th
                  colSpan={5}
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Customer Engg Approval date:
                </th>
                <th
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Rev No.
                </th>
              </tr>

              <tr>
                <th
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={2}
                >
                  CUSTOMER PART NO
                </th>
                <td
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={4}
                >
                  {controlData?.part_id?.part_number}
                </td>

                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={4}
                >
                  <b>Supplier:</b> {controlData?.part_id?.supplier}
                </td>
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={5}
                >
                  <b>Customer Quality Approval date:</b>
                  {formatDate(controlData?.part_id?.Other_approval_date)}
                </td>
                <th align="left" style={{ border: "1px solid black" }}>
                  Date
                </th>
              </tr>

              <tr>
                <th
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={2}
                >
                  Part Description
                </th>
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={8}
                >
                  {controlData.part_id && controlData.part_id.part_name}
                </td>
                {/* hardcoded */}
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={5}
                >
                  <b>Drg / Std.:</b> {controlData?.drg_std_status}
                </td>
                <td align="left" style={{ border: "1px solid black" }}></td>
              </tr>

              {/* ---main heading for mapping--- */}
            </thead>
            <tbody>
              <tr style={{ fontWeight: "bold" }}>
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={2}
                >
                  Part / Process
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Machine
                </td>
                <td
                  colSpan="3"
                  align="center"
                  style={{ border: "1px solid black" }}
                >
                  Characteristics
                </td>
                <td
                  rowSpan="2"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Class
                </td>
                <td
                  colSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Methods
                </td>
                <td
                  align="center"
                  colSpan={4}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Sample
                </td>
                <td
                  align="center"
                  rowSpan="2"
                  style={{
                    border: "1px solid black",
                    width: "150px",
                    minWidth: "150px",
                  }}
                >
                  Control Method
                </td>
                <td
                  rowSpan="2"
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "150px",
                    minWidth: "150px",
                  }}
                >
                  Reactions if out of control conditions are encountered
                </td>
              </tr>

              <tr style={{ fontWeight: "bold" }} data-height="40">
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "50px",
                    minWidth: "50px",
                  }}
                >
                  Opn. No.
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Opn Description
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Device
                </td>

                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  NO.
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Product
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Process
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                    width: "150px",
                    minWidth: "150px",
                  }}
                >
                  Product Specification
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "150px",
                    minWidth: "150px",
                  }}
                >
                  Process Specifications
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "150px",
                    minWidth: "150px",
                  }}
                >
                  Evaluation
                </td>

                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Sample Size
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Freq'cy
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Resp'ty
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Record
                </td>
              </tr>

              {currentProcesses?.map((process, index) => (
                <React.Fragment key={index}>
                  {process?.specs.map((spec, indx) => (
                    <React.Fragment key={indx}>
                      <tr
                      // data-height="45"
                      >
                        {indx === 0 && (
                          <>
                            <td
                              rowSpan={process.specs.length}
                              align="center"
                              style={{ border: "1px solid black" }}
                            >
                              {process?.process_no}
                            </td>
                            <td
                              rowSpan={process.specs.length}
                              align="center"
                              style={{ border: "1px solid black" }}
                            >
                              {process?.process_name}
                            </td>
                          </>
                        )}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.machine}
                          {spec?.machine && spec?.tool_no ? "/" : ""}
                          {spec?.tool_no}
                        </td>

                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {indx + 1}
                        </td>
                        {/* product or process */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.char_product}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.char_process}
                        </td>

                        {/* skipping */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.special_character}
                        </td>
                        {/* PRODUCT SPECIFICATION */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.char_product && spec?.control_product}
                          <span style={{ display: "block", color: "red" }}>
                            {spec?.char_product &&
                              spec?.control_product &&
                              spec?.control_product_remark &&
                              `* ${spec?.control_product_remark}`}
                          </span>
                        </td>
                        {/* Process Specifications	 */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.char_process && spec?.control_product}
                          <span style={{ display: "block", color: "red" }}>
                            {spec?.char_process &&
                              spec?.control_product &&
                              spec?.control_product_remark &&
                              `* ${spec?.control_product_remark}`}
                          </span>
                        </td>
                        {/* Process Evaluation */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.methods_measure}
                        </td>
                        {/* Sample Size	 */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.methods_size}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.methods_by}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.responsibility}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.recording}
                        </td>
                        {/* CONTROL METHOD	 */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.methods_process}
                        </td>
                        {/* Reactions  */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                        >
                          {spec?.reaction_plan}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
            <tbody>
              <tr style={{ fontWeight: "bold" }} data-height="35">
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                >
                  Revision No.
                </td>
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                >
                  Revision Date
                </td>
                <td
                  colSpan="8"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                >
                  Revision Summary
                </td>
                <td
                  colSpan="4"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                >
                  Revision Reason
                </td>
              </tr>
              <tr style={{ height: "60px" }}></tr>
              {controlData?.revisionData?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td
                        colSpan="2"
                        style={{ border: "1px solid black" }}
                        align="center"
                      >
                        {item?.rev_no}
                      </td>
                      <td
                        colSpan="2"
                        style={{ border: "1px solid black" }}
                        align="center"
                      >
                        {formatDate(item?.rev_date)}
                      </td>
                      <td
                        colSpan="8"
                        style={{ border: "1px solid black" }}
                        align="center"
                      >
                        {item?.summary}
                      </td>
                      <td
                        colSpan="4"
                        style={{ border: "1px solid black" }}
                        align="center"
                      >
                        {item?.reason}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>

            <tbody>
              <tr style={{ fontWeight: "bold" }} data-height="35">
                <td
                  colSpan="2"
                  rowSpan="2"
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Legends:
                </td>
                <td
                  colSpan="4"
                  rowSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>M</span> = Major
                </td>
                <td
                  colSpan="5"
                  rowSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>m</span> =Minor
                </td>
                <td
                  colSpan="5"
                  rowSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>c</span> = Critical
                </td>
              </tr>
              <tr style={{ height: "60px" }}></tr>
            </tbody>
          </Table>
        )}
      </div>

      {/* for downloading excel */}

      <div className="card_table" style={{ display: "none" }}>
        {controlData && (
          <Table
            cellSpacing="0"
            data-default-wrap="true"
            id="table-to-xls"
            data-cols-width="5, 10, 8, 5, 12, 12, 5, 20, 20, 12, 15, 15, 10, 7, 24, 27"
          >
            <thead>
              <tr data-height="100">
                <th
                  colSpan="16"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                  }}
                  data-a-v="middle"
                >
                  <CenteredLogoImg>
                    <img
                      src={images?.arcLogo}
                      alt={textInputs?.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs?.companyName}</span>
                  </CenteredLogoImg>
                </th>
              </tr>
              {/* 2nd row */}
              <tr data-height="40">
                <td
                  colSpan={16}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                  }}
                  data-fill-color="FF000000"
                  // data-f-sz="10"
                >
                  PROCESS CONTROL STANDARD
                </td>
              </tr>
              {/* row 3 */}
              <tr data-height="35">
                {controlData?.part_type === "prototype" ? (
                  <td
                    colSpan="2"
                    style={{
                      border: "1px solid black",
                    }}
                    align="left"
                    data-f-sz="10"
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      ☑
                    </span>
                    <span>Proto Type</span>
                  </td>
                ) : (
                  <td
                    colSpan="2"
                    align="left"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    <span
                      style={{
                        fontSize: "1.3rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      □
                    </span>
                    <span>Proto Type</span>
                  </td>
                )}
                {controlData?.part_type === "pre-Launch" ? (
                  <td
                    colSpan="2"
                    style={{
                      border: "1px solid black",
                    }}
                    align="left"
                    data-f-sz="10"
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      ☑
                    </span>
                    <span>Pre-Launch</span>
                  </td>
                ) : (
                  <td
                    colSpan="2"
                    align="left"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    <span
                      style={{
                        fontSize: "1.3rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      □
                    </span>
                    <span>Pre-Launch</span>
                  </td>
                )}

                {controlData?.part_type === "production" ? (
                  <td
                    colSpan="2"
                    align="left"
                    style={{
                      border: "1px solid black",
                    }}
                    data-f-sz="10"
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      ☑
                    </span>
                    <span>Production</span>
                  </td>
                ) : (
                  <td
                    colSpan="2"
                    align="left"
                    style={{ border: "1px solid black" }}
                    data-f-sz="10"
                  >
                    <span
                      style={{
                        fontSize: "1rem",
                        marginRight: "0.6rem",
                      }}
                    >
                      □
                    </span>
                    <span>Production</span>
                  </td>
                )}
                <th
                  align="left"
                  colSpan={4}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <b>Control Plan Number</b> {controlData?.control_plan_no}
                </th>

                <th
                  colSpan={5}
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Doc No.
                </th>
                <th
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  ARC/F/QAD/14
                </th>
              </tr>

              <tr data-height="35">
                <th
                  colSpan="2"
                  rowSpan={2}
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Customer
                </th>
                <td
                  colSpan="4"
                  rowSpan={2}
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  {controlData?.part_id?.customer}
                </td>
                <td
                  colSpan={4}
                  // rowSpan={2}
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Contact Details : {controlData?.part_id?.key_contact}
                </td>
                {/* hardcoded */}
                <td
                  style={{ border: "1px solid black" }}
                  colSpan={5}
                  align="left"
                  data-f-sz="10"
                >
                  <b>Date (Original)</b>:{" "}
                  {formatDate(controlData?.part_id?.date_orig)}
                </td>
                {/* hardcoded */}
                <td style={{ border: "1px solid black" }} data-f-sz="10">
                  <b>Rev.No./Date:-</b> 00/01-10-2017
                </td>
              </tr>
              <tr data-height="35">
                <td
                  style={{ border: "1px solid black" }}
                  colSpan={4}
                  align="left"
                  data-f-sz="10"
                >
                  <b>Core Team</b>: {controlData?.part_id?.core_team}
                </td>

                <th
                  colSpan={5}
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Customer Engg Approval date:
                </th>
                <th
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Rev No.
                </th>
              </tr>

              <tr data-height="35">
                <td
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={2}
                  data-f-sz="10"
                >
                  CUSTOMER PART NO
                </td>
                <td
                  style={{ border: "1px solid black" }}
                  align="left"
                  colSpan={4}
                  data-f-sz="10"
                >
                  {controlData?.part_id?.part_number}
                </td>

                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={4}
                  data-f-sz="10"
                >
                  Supplier: {controlData?.part_id?.supplier}
                </td>
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={5}
                  data-f-sz="10"
                >
                  Customer Quality Approval date:{" "}
                  {formatDate(controlData?.part_id?.Other_approval_date)}
                </td>
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Date
                </td>
              </tr>

              <tr data-height="35">
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={2}
                  data-f-sz="10"
                >
                  Part Description
                </td>
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={8}
                  data-f-sz="10"
                >
                  {controlData?.part_id?.part_name}
                </td>
                {/* hardcoded */}
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={5}
                  data-f-sz="10"
                >
                  <b>Drg / Std.: </b> {controlData?.drg_std_status}
                </td>
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                ></td>
              </tr>

              {/* ---main heading for mapping--- */}
              {/* <tr>
              <td
                align="left"
                style={{ border: "1px solid black" }}
                colSpan={2}
              >
                Part / Process
              </td>
              <td
                align="left"
                style={{ border: "1px solid black" }}
                colSpan={1}
              >
                Machine
              </td>
              <td
                align="left"
                style={{ border: "1px solid black" }}
                colSpan={4}
              >
                Characteristics
              </td>
            </tr> */}
            </thead>
            <tbody>
              <tr style={{ fontWeight: "bold" }} data-height="35">
                <td
                  align="left"
                  style={{ border: "1px solid black" }}
                  colSpan={2}
                  data-f-sz="10"
                >
                  Part / Process
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Machine
                </td>
                <td
                  colSpan="3"
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Characteristics
                </td>
                <td
                  rowSpan="2"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Class
                </td>
                <td
                  colSpan="3"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Methods
                </td>
                <td
                  align="center"
                  colSpan={4}
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Sample
                </td>
                <td
                  align="center"
                  rowSpan="2"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  CONTROL METHOD
                </td>
                <td
                  rowSpan="2"
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Reactions if out of control conditions are encountered
                </td>
              </tr>

              <tr style={{ fontWeight: "bold" }} data-height="40">
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "50px",
                  }}
                  data-f-sz="10"
                >
                  Opn. No.
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "30px",
                  }}
                  data-f-sz="10"
                >
                  Opn Description
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Device
                </td>

                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  NO.
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Product
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Process
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                  data-f-sz="10"
                >
                  Product Specification
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Process Specifications
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Evaluation
                </td>

                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Sample Size
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Freq'cy
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Resp'ty
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                  data-f-sz="10"
                >
                  Record
                </td>
              </tr>
              {controlData?.processes.map((process, index) => (
                <React.Fragment key={index}>
                  {process?.specs.map((spec, indx) => (
                    <React.Fragment key={indx}>
                      <tr data-height="50" data-a-wrap="true">
                        {indx === 0 && (
                          <>
                            <td
                              rowSpan={process.specs.length}
                              align="center"
                              style={{ border: "1px solid black" }}
                              data-f-sz="10"
                            >
                              {process?.process_no}
                            </td>
                            <td
                              rowSpan={process?.specs.length}
                              align="center"
                              style={{ border: "1px solid black" }}
                              data-f-sz="10"
                            >
                              {process?.process_name}
                            </td>
                          </>
                        )}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.machine}
                          {spec?.machine && spec?.tool_no ? "/" : ""}
                          {spec?.tool_no}
                        </td>

                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {indx + 1}
                        </td>
                        {/* product or process */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.char_product}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.char_process}
                        </td>

                        {/* skipping */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.special_character}
                        </td>
                        {/* PRODUCT SPECIFICATION */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.char_product && spec?.control_product}
                          <span style={{ display: "block", color: "red" }}>
                            {spec?.char_product &&
                              spec?.control_product &&
                              spec?.control_product_remark &&
                              `* ${spec?.control_product_remark}`}
                          </span>
                        </td>
                        {/* Process Specifications	 */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.char_process && spec?.control_product}
                          <span style={{ display: "block", color: "red" }}>
                            {spec?.char_process &&
                              spec?.control_product &&
                              spec?.control_product_remark &&
                              `* ${spec?.control_product_remark}`}
                          </span>
                        </td>
                        {/* Process Evaluation */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.methods_measure}
                        </td>
                        {/* Sample Size	 */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.methods_size}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.methods_by}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.responsibility}
                        </td>
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.recording}
                        </td>
                        {/* CONTROL METHOD	 */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.methods_process}
                        </td>
                        {/* Reactions  */}
                        <td
                          align="center"
                          style={{ border: "1px solid black" }}
                          data-f-sz="8"
                        >
                          {spec?.reaction_plan}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
            <tbody>
              <tr style={{ fontWeight: "bold" }} data-height="35">
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="10"
                >
                  Revision No.
                </td>
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="10"
                >
                  Revision Date
                </td>
                <td
                  colSpan="8"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="10"
                >
                  Revision Summary
                </td>
                <td
                  colSpan="4"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="10"
                >
                  Revision Reason
                </td>
              </tr>
              <tr style={{ height: "60px" }}></tr>
              {controlData?.revisionData.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td
                        colSpan="2"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="8"
                      >
                        {item?.rev_no}
                      </td>
                      <td
                        colSpan="2"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="8"
                      >
                        {formatDate(item?.rev_date)}
                      </td>
                      <td
                        colSpan="8"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="8"
                      >
                        {item?.summary}
                      </td>
                      <td
                        colSpan="4"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="8"
                      >
                        {item?.reason}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
            <tbody>
              <tr>
                <td
                  colSpan="3"
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Customer Approval
                </td>
                <td
                  colSpan="6"
                  align="left"
                  style={{ border: "1px solid black" }}
                ></td>
                <td
                  colSpan="2"
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  ARC Approval
                </td>
                <td
                  colSpan="5"
                  align="left"
                  style={{ border: "1px solid black" }}
                ></td>
              </tr>
              <tr>
                <td
                  colSpan={8}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Name & Signature
                </td>
                <td
                  colSpan={8}
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                ></td>
              </tr>
              <tr style={{ fontWeight: "bold" }} data-height="35">
                <td
                  colSpan="2"
                  rowSpan="2"
                  align="left"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  Legends:
                </td>
                <td
                  colSpan="4"
                  rowSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>M</span> = Major
                </td>
                <td
                  colSpan="5"
                  rowSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>m</span> =Minor
                </td>
                <td
                  colSpan="5"
                  rowSpan="2"
                  align="center"
                  style={{ border: "1px solid black" }}
                  data-f-sz="10"
                >
                  <span>c</span> = Critical
                </td>
              </tr>
              <tr style={{ height: "60px" }}></tr>
            </tbody>
          </Table>
        )}
      </div>

      <Pagination
        page={currentPage}
        processesPerPage={processesPerPage}
        totalProcesses={controlData?.processes.length}
        paginate={(number) => paginate(number)}
      />
    </>
  );
}
