const proxy_url = "https://proxy.aqua.mushinlabs.com/proxy/";
const PdfViewer = ({ url }) => {
  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <object
        aria-label="pdf-viwer"
        data={`${proxy_url}${url}`}
        type="application/pdf"
        width="100%"
        height="100%"
      ></object>
    </div>
  );
};

export default PdfViewer;
