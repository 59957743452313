import { Link, useHistory } from "react-router-dom";

import { useAuth } from "../../context/GlobalContext";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import useConfirm from "../Control/useConfirm";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";

import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ListIcon from "@material-ui/icons/List";
import homePage from "./HomePage.module.css";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SyncProblemIcon from "@material-ui/icons/SyncProblem";

const Admin = () => {
  const { state, logout } = useAuth();
  const history = useHistory();
  const [Dialog, confirmLogoutUser] = useConfirm(
    "LOG OUT",
    "Are you sure you want to log out?"
  );
  const logoutUser = async () => {
    const ans = await confirmLogoutUser();
    if (ans) {
      logout();
      history.push("/");
    }
  };
  if (!state.user) {
    logout();
    history.push("/");
  }

  if (state.user?.userType == 2) {
    return (
      <>
        <main className={`${homePage.container} ${homePage.main}`}>
          <div>
            <Link to="/masterProcedureList?category=clause-1" className="panel">
              <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
            </Link>
            <p className="card-btn-para">
              Master List of
              <span className="card-btn-span">Procedure</span>
            </p>
          </div>
          <div>
            <Link to="/clause1Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>1</span>
            </Link>
            <p className="card-btn-para">
              Production Preparation /
              <span className="card-btn-span">New Product Developement</span>
            </p>
          </div>
          <div>
            <Link to="/clause2Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>2</span>
            </Link>
            <p className="card-btn-para">
              Regulation For Initial
              <span className="card-btn-span">Production Developement</span>
            </p>
          </div>
          <div>
            <Link to="/clause3Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>3</span>
            </Link>
            <p className="card-btn-para">
              Changing
              <span className="card-btn-span">Management</span>
            </p>
          </div>
          <div>
            <Link to="/clause4Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>4</span>
            </Link>
            <p className="card-btn-para">
              Standards
              <span className="card-btn-span">Management</span>
            </p>
          </div>
          <div>
            <Link to="/clause5Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>5</span>
            </Link>
            <p className="card-btn-para">
              Education And
              <span className="card-btn-span">Training</span>
            </p>
          </div>
          <div>
            <Link to="/clause6Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>6</span>
            </Link>
            <p className="card-btn-para">
              Quality Audit And
              <span className="card-btn-span">Process Verification</span>
            </p>
          </div>
          <div>
            <Link to="/clause7Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>7</span>
            </Link>
            <p className="card-btn-para">
              Supplier
              <span className="card-btn-span">Control</span>
            </p>
          </div>
          <div>
            <Link to="/clause8Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>8</span>
            </Link>
            <p className="card-btn-para">
              Handling Abnormility
              <span className="card-btn-span">In Quality</span>
            </p>
          </div>
          <div>
            <Link to="/clause9Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>9</span>
            </Link>
            <p className="card-btn-para">
              5S<span className="card-btn-span">Management</span>
            </p>
          </div>
          <div>
            <Link to="/clause10Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>10</span>
            </Link>
            <p className="card-btn-para">
              Equipment / Inspection
              <span className="card-btn-span"> Equipment's Management</span>
            </p>
          </div>
          <div>
            <Link to="/clause11Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>11</span>
            </Link>
            <p className="card-btn-para">
              Implementation Of
              <span className="card-btn-span">Standards</span>
            </p>
          </div>
          <div>
            <Link to="/clause12Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>12</span>
            </Link>
            <p className="card-btn-para">
              Products
              <span className="card-btn-span">Management</span>
            </p>
          </div>
          <div>
            <Link to="/clause13Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>13</span>
            </Link>
            <p className="card-btn-para">
              Handling
              <span className="card-btn-span">Management</span>
            </p>
          </div>
          <div>
            <Link to="/clause14Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>14</span>
            </Link>
            <p className="card-btn-para">
              Critical Parts
              <span className="card-btn-span">Management</span>
            </p>
          </div>
          <div>
            <Link to="/clause15Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>15</span>
            </Link>
            <p className="card-btn-para">
              Adequate Testing
              <span className="card-btn-span">Facility</span>
            </p>
          </div>
          <div>
            <Link to="/clause16Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>16</span>
            </Link>
            <p className="card-btn-para">
              Process
              <span className="card-btn-span">Audit</span>
            </p>
          </div>
          <div>
            <Link to="/clause17Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>17</span>
            </Link>
            <p className="card-btn-para">
              Scale And Top
              <span className="card-btn-span"> Management Bandwidth</span>
            </p>
          </div>
          <div>
            <Link to="/clause18Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>18</span>
            </Link>
            <p className="card-btn-para">
              Safety
              {/* <span className="card-btn-span"></span> */}
            </p>
          </div>
          <div>
            <Link to="/clause19Page" className="panel">
              <span className={`${homePage.numberingIcon} `}>19</span>
            </Link>
            <p className="card-btn-para">
              Legal Compliance
              <span className="card-btn-span">And Environment</span>
            </p>
          </div>

          <div>
            <Link to="/viewDailyProduction" className="panel">
              <FeaturedPlayListIcon
                style={{ fontSize: "3rem", color: "#003566" }}
              />
            </Link>
            <p className="card-btn-para">
              Daily Production <span className="card-btn-span">Reports</span>
            </p>
          </div>

          <div>
            <Link to="/productionPlanningcontrol/create" className="panel">
              <MenuBookIcon style={{ fontSize: "3rem", color: "#003566" }} />
            </Link>
            <p className="card-btn-para">
              PPC
              <span className="card-btn-span">Reports</span>
            </p>
          </div>
          <div>
            <Link to="/defects" className="panel">
              <SyncProblemIcon style={{ fontSize: "3rem", color: "#003566" }} />
            </Link>
            <p className="card-btn-para">
              Master List Of
              <span className="card-btn-span">Defects</span>
            </p>
          </div>
          {/* <div>
            <Link to="/incomingRMResisterList" className="panel">
              <FeaturedPlayListIcon
                style={{ fontSize: "3rem", color: "#003566" }}
              />
            </Link>
            <p className="card-btn-para">
              Incoming RM <span className="card-btn-span">Resister</span>
            </p>
          </div> */}
          <div>
            <Link to="/viewNotebooksList" className="panel">
              <LibraryBooksIcon
                style={{ fontSize: "3rem", color: "#003566" }}
              />
            </Link>
            <p className="card-btn-para">
              My<span className="card-btn-span">Notebook</span>
            </p>
          </div>
          <div>
            <Link
              to="/dashboard"
              className="panel"
              style={{ color: "#3d3e40", textDecoration: "none" }}
            >
              <DashboardIcon style={{ fontSize: "3rem", color: "#064a29" }} />
            </Link>
            <p className="card-btn-para">
              View <span className="card-btn-span">Dashboard</span>
            </p>
          </div>
          <div>
            <Link to="/userManagement" className="panel">
              <SupervisorAccountIcon
                style={{ fontSize: "3rem", color: "#003566" }}
              />
            </Link>
            <p className="card-btn-para">
              User<span className="card-btn-span"> Management</span>
            </p>
          </div>
          <div>
            <Link to="/rmTiles" className="panel">
              <FeaturedPlayListIcon
                style={{ fontSize: "3rem", color: "#003566" }}
              />
            </Link>
            <p className="card-btn-para">
              RM<span className="card-btn-span">Department</span>
            </p>
          </div>
          <div>
            <Link to="/mushinHelpDesk/" className="panel">
              <LiveHelpIcon style={{ fontSize: "3rem", color: "#003566" }} />
            </Link>
            <p className="card-btn-para">
              MUSHIN HELP<span className="card-btn-span">DESK</span>
            </p>
          </div>

          <div>
            <div
              style={{ cursor: "pointer" }}
              to="/add_data"
              className="panel"
              onClick={logoutUser}
            >
              <ExitToAppIcon style={{ fontSize: "3rem", color: "maroon" }} />
            </div>
            <p className="card-btn-para">
              Logout <span className="card-btn-span">Aqua</span>
            </p>
          </div>
        </main>

        <Dialog isSubmitContent={false} />
      </>
    );
  } else {
    return (
      <div>
        <h1>Page Not Found</h1>
      </div>
    );
  }
};

export default Admin;
