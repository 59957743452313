import "date-fns";
import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import ProcessPir from "./ProcessPir";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import { useAuth } from "../../../context/GlobalContext";
import { DatePicker } from "@material-ui/pickers";
import useConfirm from "../../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";

import {
  SaveAndContinueButton,
  CloseButton,
  SubmitButton,
} from "../../../GlobalUtils/ButtonsGlobal";
import ItemsDetailsHeader from "../../../GlobalUtils/ItemsDetailsHeader";
import { useQuery } from "../../../components/Control/useQuery";
import formatDate from "../../../components/Control/formatDate";

function PirForm({ data, StoreArr, partId }) {
  const history = useHistory();
  const { state } = useAuth();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const [pirData, setPirData] = useState({
    part_id: partId,
    pir_date: new Date(),
    revision_no: data.revision_no,
    revision_date: data.revision_date,
    isSubmitted: false,
    pir: StoreArr,
  });

  const [DialogSubmit, confirmSubmit] = useConfirm(
    "SUBMIT PIR",
    "Are you sure you want to submit this PIR?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE PIR",
    "Are you sure you want to save this PIR?"
  );
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  async function saveLater(e) {
    e.preventDefault();
    const isSubmit = await confirmSave();
    if (isSubmit) {
      pirData.username = state.user.username;
      pirData.report_prepared_by = state.user.name;
      axios
        .post("/api/add/pir", pirData, {
          "Content-Type": "application/json",
        })
        .then((res) => {
          history.push(
            `/supervisor/pir/${partId}?partNumber=${partNumber}&partName=${partName}`
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const isSubmit = await confirmSubmit();

    if (isSubmit) {
      pirData.username = state.user.username;
      pirData.isSubmitted = true;
      pirData.is_admin_request = "pending";
      pirData.report_prepared_by = state.user.name;
      axios
        .post("/api/add/pir", pirData, {
          "Content-Type": "application/json",
        })
        .then((res) => {
          history.push(
            `/supervisor/pir/${partId}?partNumber=${partNumber}&partName=${partName}`
          );
        })
        .catch((error) => {
          console.log(error);
        });

      const messageData = {
        messageType: "pir",
        part_name: `${partName}`,
        part_id: partId,
        message: `PIR of ${partName} submitted by ${
          state.user.name
        } on ${formatDate(new Date())}`,
        link: `/ViewPir/${partId}?partNumber=${partNumber}&partName=${partName}`,
      };
      await axios
        .post(`/api/add/message`, messageData)
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <main>
        {data && (
          <>
            <div
              className="divflex "
              style={{
                fontSize: "24px",
              }}
            >
              PROCESS INSPECTION REPORT
            </div>
            <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
            <form
              style={{
                margin: "0.6rem",
              }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Wrapper>
                <div style={{ marginLeft: "0.7rem" }}>
                  <DatePicker
                    label="Date"
                    className="date-field"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    onChange={(date) =>
                      setPirData((prev) => ({ ...prev, pir_date: date }))
                    }
                    value={pirData.pir_date}
                  />
                </div>
              </Wrapper>

              <ProcessPir
                {...{
                  pirData,
                }}
              />
              <Wrapper
                style={{
                  position: "absolute",
                  top: "4.7rem",
                  right: "1.6rem",
                  gap: "1rem",
                }}
              >
                <SaveAndContinueButton onClick={saveLater} />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ backgroundColor: "#054a29", marginRight: "0.5rem" }}
                  startIcon={<PublishIcon />}
                >
                  Submit
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            </form>
          </>
        )}
      </main>
      <DialogSave isSubmitContent={true} />
      <DialogSubmit isSubmitContent={false} />
      <DialogClose isSubmitContent={false} />
    </>
  );
}

export default PirForm;
