import React, { useEffect, useRef, useState } from "react";
import Button from "@material-ui/core/Button";
import { FormControl, Select, MenuItem } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import { TextField, makeStyles } from "@material-ui/core";
import axios from "axios";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { useAuth } from "../../context/GlobalContext";
import moment from "moment";
import { DivStyled } from "../EducationTraining/Container.styled";
const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});
const AddEditPart = ({ id, setIsModal, disable }) => {
  const [files, setFiles] = useState(null);

  const [render, setRender] = useState(false);
  const [data, setData] = useState({});
  const { state } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const inputElement = useRef(null);
  const classes = dropdownStyles();

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? "Update Vendor" : "Create Ventor"}`,
    `Are you sure you want to ${id ? "update" : "save"} this?`,
  );

  function handleCustomerData(e) {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/mushinHelpDesk/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      data.user_id = state?.user?.username;
      data.date_of_complaint = moment().format("DD-MM-YYYY");
      data.status = "OPEN";
    }
  }, [id]);

  // console.log(customerData);
  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    const formData = new FormData();

    for (const key in data) {
      if (data[key]) {
        if (key !== "files") formData.append(key, data[key]);
      }
    }
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append("files", files[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/mushinHelpDesk/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/mushinHelpDesk", formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <section className="root-admin">
      <div
        className="btn_save"
        style={{
          position: "absolute",
          right: "150px",
          top: "9px",
        }}
      >
        <Button
          type="submit"
          variant="outlined"
          color="white"
          size="large"
          style={{ color: "#e9e9e9" }}
          startIcon={<PublishIcon />}
          onClick={handleSumbit}
        >
          {id ? "UPDATE" : "SUBMIT"}
        </Button>
      </div>

      <DivStyled>
        <label htmlFor="user_id">USER ID: :</label>
        <input
          type="text"
          id="user_id"
          name="user_id"
          ref={inputElement}
          // required
          defaultValue={data?.user_id || ""}
          onBlur={handleCustomerData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="date_of_complaint">Date Of Complaint:</label>
        <input
          type="text"
          id="date_of_complaint"
          name="date_of_complaint"
          defaultValue={data?.date_of_complaint}
          onBlur={handleCustomerData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="problem">Problem Claue/Document: </label>
        <input
          type="text"
          id="problem"
          name="problem"
          defaultValue={data?.problem || ""}
          onBlur={handleCustomerData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="url">URL Address:</label>
        <input
          type="url"
          id="url"
          name="url"
          defaultValue={data?.url || ""}
          onBlur={handleCustomerData}
        />
      </DivStyled>
      <div>
        <TextField
          type="text"
          variant="filled"
          name="description"
          multiline={true}
          rows={18}
          maxRows={20}
          className="textarea-field"
          label="Complaint Description"
          style={{
            margin: "1.3rem 0.7rem 0rem 0.5rem",
            paddingRight: "1.2rem",
            width: "100%",
          }}
          onChange={handleCustomerData}
          value={data.description || ""}
        />
      </div>
      <div>
        <TextField
          type="text"
          variant="filled"
          name="mushin_remarks"
          multiline={true}
          rows={18}
          maxRows={20}
          className="textarea-field"
          label="Responsible Person Remarks"
          style={{
            margin: "1.3rem 0.7rem 0rem 0.5rem",
            paddingRight: "1.2rem",
            width: "100%",
          }}
          onChange={handleCustomerData}
          value={data.mushin_remarks || ""}
        />
      </div>
      <DivStyled>
        <label htmlFor="status">STATUS (OPEN/WORK IN PROGRESS/CLOSED):</label>
        <FormControl
          style={{
            borderLeft: "2px solid #ced4da",
            paddingLeft: "0.5rem",
            width: "100%",
            marginRight: "6rem",
          }}
          fullWidth={true}
          variant="filled"
          classes={{
            root: classes.root,
          }}
        >
          <Select
            defaultValue={data.status || ""}
            name="agency"
            key={data.status || ""}
            id="agency"
            classes={{
              root: classes.root,
              select: classes.select,
            }}
            style={{ fontSize: "0.8rem" }}
            disableUnderline
            onChange={(e) => {
              data.status = e.target.value;
              if (data.status === "CLOSED") {
                data.closure_date = moment().format("DD-MM-YYYY");
                data.closed_by = state?.user?.username;
              } else {
                data.closure_date = "";
                data.closed_by = "";
              }
              setRender((prev) => !prev);
            }}
            disabled={disable}
          >
            <MenuItem disabled={disable} value="OPEN">
              OPEN
            </MenuItem>
            <MenuItem disabled={disable} value="WORK IN PROGRESS">
              WORK IN PROGRESS
            </MenuItem>
            <MenuItem disabled={disable} value="UNDER REVIEW">
              UNDER REVIEW
            </MenuItem>
            <MenuItem disabled={disable} value="CLOSED">
              CLOSED
            </MenuItem>
          </Select>
        </FormControl>
      </DivStyled>
      <DivStyled>
        <label htmlFor="closed_by">CLOSED BY: </label>
        <input
          type="text"
          id="closed_by"
          name="closed_by"
          defaultValue={data?.closed_by || ""}
          // onBlur={handleCustomerData}
        />
      </DivStyled>
      <DivStyled>
        <label htmlFor="closure_date">Closure Date:</label>
        <input
          type="text"
          id="closure_date"
          name="closure_date"
          defaultValue={data?.closure_date}
          // onBlur={handleCustomerData}
        />
      </DivStyled>
      <DivStyled>
        <input
          id="contained-button-file"
          type="file"
          multiple
          accept="image/png,image/jpeg,image/jpg/,application/pdf"
          onChange={(e) => setFiles(e.target.files)}
          hidden
        />
        <label
          htmlFor="contained-button-file"
          style={{
            marginBottom: "0",
          }}
        >
          <Button component="span">
            <CloudUploadIcon style={{ marginRight: "1rem" }} />
            Upload Picture / Pdf
          </Button>
        </label>
      </DivStyled>

      <DialogSubmit isSubmitContent={false} />
    </section>
  );
};

export default AddEditPart;
