import React from "react";
import { useAuth } from "../../../context/GlobalContext";
import AdminView4MTable from "./AdminView4MTable";
import SupervisorView4MTable from "./SupervisorView4MTable";

const RequestForm = () => {
    const { state } = useAuth();
    return (
        <div>
            {state.user.userType === 2 && <AdminView4MTable />}
            {state.user.userType === 3 && <SupervisorView4MTable />}
        </div>
    );
};

export default RequestForm;
