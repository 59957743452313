import { Box } from "@mui/material";
import RenderTable from "../../../../common/RenderTable";
import TBody from "./TBody";
import THead from "./THead";

const TController = ({
  handelDeleteRows,
  moduleData,
  onSubmit,
  isView,
  pictorial,
  setIsOpenPopup,
  setPictorial,
  setPartIdx,
  setIsTrainingFileOpenPopup,
  triningFileIdRef,
}) => {
  return (
    <Box>
      <div>
        <RenderTable>
          <THead
            {...{
              moduleData,
              isView,
            }}
          />
          <TBody
            {...{
              onSubmit,
              setPartIdx,
              pictorial,
              setPictorial,
              setIsOpenPopup,
              handelDeleteRows,
              moduleData,
              isView,
              setIsTrainingFileOpenPopup,
              triningFileIdRef,
            }}
          />
        </RenderTable>
      </div>
    </Box>
  );
};

export default TController;
