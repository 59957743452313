import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import axios from "axios";
import { Wrapper } from "../../../components/Control/Flexbox";
import { DatePicker } from "@material-ui/pickers";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SpecsPdir from "../CreatePdir/SpecsPdir";
import PdirHeadForm from "../CreatePdir/PdirHeadForm";
import Loader from "../../../components/Control/Loader";
import { useHistory, useParams } from "react-router-dom";
import { useAuth } from "../../../context/GlobalContext";
import { useQuery } from "../../../components/Control/useQuery";
import ItemsDetailsHeader from "../../../GlobalUtils/ItemsDetailsHeader";
import useConfirm from "../../../components/Control/useConfirm";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const EditPdir = () => {
  const [pdirData, setPdirData] = useState(null);
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");

  const [image, setImage] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const { state } = useAuth();
  const history = useHistory();
  const { id } = useParams();
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save this PDIR?"
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "SUBMIT",
    "Are you sure you want to submit this PDIR?"
  );
  useEffect(() => {
    axios
      .get(`/api/get/pdir/${id}`)
      .then((res) => {
        setPdirData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let ans = await confirmSubmit();
    if (ans) {
      setIsloading(true);
      pdirData.isSubmitted = true;
      pdirData.report_created_by = state.user.name;
      let formdata = new FormData();
      if (image) {
        formdata.append("sketch", image);
      }
      formdata.append("data", JSON.stringify(pdirData));
      await axios
        .put(`/api/edit/pdir/${id}`, formdata)
        .then((res) => {
          if (res.data?.status === "success") {
            if (state.user.userType === 3) {
              history.push(
                `/viewpdir/monthly/${pdirData.part_id._id}?partNumber=${partNumber}&partName=${partName}`
              );
            } else {
              history.push(
                `/viewpdir/monthly/${pdirData.part_id._id}?partNumber=${partNumber}&partName=${partName}`
              );
            }
          }
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
        });
    }
  };

  const saveLater = async () => {
    let ans = await confirmSave();
    if (ans) {
      setIsloading(true);
      pdirData.isSubmitted = false;
      pdirData.username = state.user.username;
      let formdata = new FormData();
      if (image) {
        formdata.append("sketch", image);
      }
      formdata.append("data", JSON.stringify(pdirData));
      await axios
        .put(`/api/edit/pdir/${id}`, formdata)
        .then((res) => {
          if (state.user.userType === 3) {
            history.push(
              `/viewpdir/monthly/${pdirData.part_id._id}?partNumber=${partNumber}&partName=${partName}`
            );
          } else {
            history.push(
              `/viewpdir/monthly/${pdirData.part_id._id}?partNumber=${partNumber}&partName=${partName}`
            );
          }
          setIsloading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
        });
    }
  };
  if (isLoading) {
    return <Loader />;
  }

  return (
    <main>
      {pdirData && (
        <>
          <div
            className="divflex "
            style={{
              fontSize: "24px",
            }}
          >
            PRE-DISPATCH INSPECTION REPORT
          </div>
          <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
          <form
            style={{
              margin: "0.6rem",
              marginTop: "1rem",
            }}
            onSubmit={(e) => handleSubmit(e)}
          >
            <Wrapper>
              <div style={{ marginLeft: "0.7rem" }}>
                <DatePicker
                  label="Date"
                  className="date-field"
                  inputVariant="filled"
                  format="dd/MM/yyyy"
                  onChange={(date) => {
                    setPdirData((prev) => ({ ...prev, pdir_date: date }));
                  }}
                  value={pdirData.pdir_date}
                />
              </div>
            </Wrapper>
            <PdirHeadForm {...{ pdirData, image, setImage }} />
            <SpecsPdir {...{ pdirData }} />
            <Wrapper
              style={{
                position: "absolute",
                top: "4.7rem",
                right: "1.6rem",
                gap: "1rem",
              }}
            >
              {state.user.userType === 3 && (
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={saveLater}
                  style={{ backgroundColor: "#003566" }}
                  startIcon={<DoubleArrowIcon />}
                >
                  Save & Continue
                </Button>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                style={{ backgroundColor: "#054a29" }}
                startIcon={<CloudUploadIcon />}
              >
                Submit
              </Button>
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (ans) {
                    history.goBack();
                  }
                }}
              />
            </Wrapper>
          </form>
          <DialogSave isSubmitContent={true} />
          <DialogSubmit isSubmitContent={true} />
          <DialogClose isSubmitContent={false} />
        </>
      )}
    </main>
  );
};

export default EditPdir;
