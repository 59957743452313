import React, { useState, useEffect } from "react";
import axios from "axios";
import PirForm from "./PirForm";
import "../../../style/pir.css";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Control/Loader";
const UpdatePir = () => {
  const [data, setData] = useState(null);
  const { id, partId } = useParams();
  useEffect(() => {
    async function fetchPir() {
      try {

        const res = await axios.get(`/api/getPirById/${id}`);
        setData(res.data);

      } catch (error) {
        console.log(error);
      }
    }
    fetchPir();
    // eslint-disable-next-line
  }, []);

  return <>{data ? <PirForm {...{ data, partId, id }} /> : <Loader />}</>;
};

export default UpdatePir;
