import React, { useState, useRef } from "react";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
import { DatePicker } from "@material-ui/pickers";
import { Add, Remove } from "@material-ui/icons";
import Modal from "../../../components/Control/Modal";
import CustomForMTable from "../../../common/CustomForMTable";

const PirProcessDetail = ({
  pirData,
  handleChangePir,
  handleDate,
  process,
  index,
}) => {
  const [is4mModal, setIs4mModal] = useState(false);
  const [render, setRender] = useState(false);
  const documentTypeRef = useRef(null);
  const closeModal = () => {
    setIs4mModal(false);
  };
  const handle4m = (value, documentType) => {
    if (documentType === "operator") {
      pirData.pir[index].operator_name = value;
    }
    if (documentType === "machine") {
      pirData.pir[index].machine_no = value;
    }
    setRender((prev) => !prev);
  };
  const reset4m = (documentType) => {
    if (documentType === "operator") {
      pirData.pir[index].operator_name = null;
    }
    if (documentType === "machine") {
      pirData.pir[index].machine_no = null;
    }
    setRender((prev) => !prev);
  };
  return (
    <Wrapper>
      <div>
        <Input
          label="Shift Time"
          name="shift_time"
          type="text"
          autoComplete="off"
          onBlur={(e) => handleChangePir(e, index)}
          defaultValue={process.shift_time}
        />
      </div>
      <div className="pir-process-detail">
        <DatePicker
          label="Date"
          name="processDate"
          className="date-field"
          inputVariant="filled"
          format="dd/MM/yyyy"
          style={{ width: "100%", marginLeft: "0.4rem" }}
          value={process.processDate || null}
          onChange={(date) => {
            handleDate(date, index);
          }}
        />
      </div>

      <Wrapper
        justify="space-between"
        style={{
          marginLeft: "1rem",
        }}
        className="add_button_container"
      >
        <p>{process.operator_name || "Pick Operator"}</p>
        {process.operator_name ? (
          <Wrapper
            className="pir_add_modal"
            justify="center"
            style={{ backgroundColor: "#003566" }}
            onClick={() => {
              pirData.pir[index].operator_name = null;
              setRender((prev) => !prev);
            }}
          >
            <Remove />
          </Wrapper>
        ) : (
          <Wrapper
            className="pir_add_modal"
            justify="center"
            style={{ backgroundColor: "#c80202" }}
            onClick={() => {
              documentTypeRef.current = "operator";
              setIs4mModal(true);
            }}
          >
            <Add />
          </Wrapper>
        )}
      </Wrapper>
      <Wrapper
        justify="space-between"
        className="add_button_container"
        style={{ marginLeft: "1rem" }}
      >
        <p>{process.machine_no || "Pick Machine"}</p>
        {process.machine_no ? (
          <Wrapper
            className="pir_add_modal"
            style={{ backgroundColor: "#003566" }}
            justify="center"
            onClick={() => {
              pirData.pir[index].machine_no = null;
              setRender((prev) => !prev);
            }}
          >
            <Remove />
          </Wrapper>
        ) : (
          <Wrapper
            className="pir_add_modal"
            justify="center"
            style={{ backgroundColor: "#c80202" }}
            onClick={() => {
              documentTypeRef.current = "machine";
              setIs4mModal(true);
            }}
          >
            <Add />
          </Wrapper>
        )}
      </Wrapper>
      <section>
        <Modal
          openPopup={is4mModal}
          title={`${
            documentTypeRef.current === "operator"
              ? "OPERATOR LIST"
              : "MACHINE LIST"
          }`}
          closeModal={closeModal}
        >
          <CustomForMTable
            documentType={documentTypeRef.current}
            processes={process}
            {...{
              handle4m,
              reset4m,
            }}
          />
        </Modal>
      </section>
    </Wrapper>
  );
};

export default PirProcessDetail;
