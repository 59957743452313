import { Button } from "@material-ui/core";
import UpdateIcon from "@material-ui/icons/Update";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";

export function SaveAndContinueButton({ onClick }) {
  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      size="large"
      style={{
        backgroundColor: "#003566",
        marginRight: "0.5rem",
      }}
      startIcon={<SaveIcon />}
      onClick={onClick}
    >
      Save & Continue
    </Button>
  );
}

export function CloseButton({ onClick }) {
  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      size="large"
      style={{
        backgroundColor: "red",
        marginRight: "0.5rem",
      }}
      startIcon={<CloseIcon />}
      onClick={onClick}
    >
      Close
    </Button>
  );
}

export function UpdateButton({ onClick }) {
  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      size="large"
      style={{
        backgroundColor: "#064a29",
        marginRight: "0.5rem",
      }}
      startIcon={<UpdateIcon />}
      onClick={onClick}
    >
      Update
    </Button>
  );
}

export function SubmitButton({ onClick }) {
  return (
    <Button
      type="button"
      variant="contained"
      color="primary"
      size="large"
      style={{
        backgroundColor: "#064a29",
        marginRight: "0.5rem",
      }}
      startIcon={<CloudUploadIcon />}
      onClick={onClick}
    >
      Submit
    </Button>
  );
}
