import React, { useState, useEffect } from "react";
import axios from "axios";
import "./User.css";
import CheckIcon from "@material-ui/icons/Check";

function Users() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get("api/get/user").then((response) => {
      setUsers(response.data);
    });
  }, []);

  return (
    <>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        USER LIST
      </div>

      <div style={{ background: "white", paddingBottom: "4rem" }}>
        <table id="customers">
          <thead>
            <tr>
              <th>#</th>
              <th>NAME</th>

              <th>USERNAME</th>
              <th>TYPE</th>
              <th>STATUS</th>
            </tr>
            {users.map((user, index) => (
              <tr key={user.username}>
                <td style={{ background: "white" }}>{index + 1} </td>
                <td style={{ background: "white" }}>{user.name}</td>

                <td style={{ background: "white" }}>{user.username}</td>
                <td style={{ background: "white" }}>
                  {user.userType === 3
                    ? "Supervisor"
                    : user.isFullAdmin
                    ? "Super Admin"
                    : "Admin"}
                </td>

                <td style={{ background: "white" }}>
                  {<CheckIcon style={{ color: "green" }} />}
                </td>
              </tr>
            ))}
          </thead>
        </table>
      </div>
    </>
  );
}

export default Users;
