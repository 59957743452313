import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Search } from "@material-ui/icons";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../components/Control/useDebounce";
import Input from "../components/Control/Input";
import { Wrapper } from "../components/Control/Flexbox";
import formatDate from "../components/Control/formatDate";
import { CustomIconButton } from "./CustomIconButton";
import { Add, Remove } from "@mui/icons-material";
const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});

const PickPart = ({ moduleData }) => {
  const classes = useStyles();
  const [filter, setFilter] = useState("desc_4m");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [perPage, setPerPage] = useState();
  const [render, setRender] = useState(false);

  const fetchData = async () => {
    axios
      .get(
        `/api/getPartDetails?page=${page}&search=${search}&sortBy=${filter}&perPage=10`,
      )
      .then((res) => {
        setData(res.data?.results);
        setPerPage(res.data?.perPage);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useDebounce(fetchData, 300, [page, search, filter]);
  return (
    <main>
      <Wrapper>
        <div style={{ flex: "0.8" }}>
          <Input
            placeholder="Search Item Number	"
            variant="filled"
            style={{
              width: "95%",
              marginLeft: "1.2rem",
            }}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <FormControl
          variant="filled"
          className="select-field"
          style={{ marginRight: "1.5rem", marginTop: "0.1rem", flex: "0.2" }}
        >
          <InputLabel>Filter</InputLabel>
          <Select
            name="part_type"
            onChange={(e) => setFilter(e.target.value)}
            defaultValue={filter}
          >
            <MenuItem value="desc_4m">A - Z</MenuItem>
            <MenuItem value="createdAt">Most Recent First</MenuItem>
          </Select>
        </FormControl>
      </Wrapper>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            {[
              "#",
              "Item Number",
              "Item Description",
              "Created At",
              "Action",
            ].map((item, index) => (
              <TableCell
                style={{ fontWeight: "bold", textTransform: "capitalize" }}
                align="center"
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {data && (
          <TableBody>
            {data.map((item, idx) => (
              <React.Fragment key={item._id}>
                <TableRow className={classes.rootRow}>
                  <TableCell align="center">
                    {perPage * (page - 1) + idx + 1}
                  </TableCell>
                  <TableCell align="center">{item?.part_number}</TableCell>
                  <TableCell align="center">{item?.part_name}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {moduleData.part_id === item._id ? (
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={"remove"}
                        onClick={() => {
                          moduleData.part_id = null;
                          moduleData.partName = null;
                          moduleData.partNumber = null;
                          setRender((prev) => !prev);
                        }}
                      >
                        <Remove style={{ color: "#c92a2a" }} />
                      </CustomIconButton>
                    ) : (
                      <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={"add"}
                        onClick={() => {
                          moduleData.part_id = item?._id;
                          moduleData.partName = item?.part_name;
                          moduleData.partNumber = item?.part_number;
                          setRender((prev) => !prev);
                        }}
                      >
                        <Add style={{ color: "#343a40" }} />
                      </CustomIconButton>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "1rem",
          marginBottom: "4rem",
        }}
      >
        <Pagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          onChange={(e, number) => setPage(number)}
        />
      </div>
    </main>
  );
};

export default PickPart;
