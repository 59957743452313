import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Button,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import axios from "axios";
import DeleteIcon from "@material-ui/icons/Delete";

import EditIcon from "@material-ui/icons/Edit";
import formatDate from "../Control/formatDate";
import { useHistory } from "react-router-dom";
import { Add } from "@material-ui/icons";
import { useQuery } from "../Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

function SelectPPAP() {
  const { partId } = useParams();
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");

  useEffect(() => {
    axios
      .get(`/api/get/all/ppap/${partId}`)
      .then((res) => setData(res.data))
      .catch((err) => console.log(err.message));
  }, [render]);

  return (
    <div>
      <TableComp {...{ partId, data, setRender }} />
    </div>
  );
}

const THead = (
  <TableHead>
    <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        #
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Item Number
      </TableCell>

      <TableCell style={{ fontWeight: "bold" }} align="center">
        Customer Name
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Created At
      </TableCell>
      <TableCell style={{ fontWeight: "bold" }} align="center">
        Action
      </TableCell>
    </TableRow>
  </TableHead>
);

function TableComp({ partId, data, setRender }) {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");

  const handleDeletePpap = (id) => {
    let isDelete = window.confirm("Do you want to delete this Report?");
    if (isDelete) {
      axios
        .delete(`/api/delete/ppap/${id}`)
        .then(() => {
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  return (
    <div>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        LIST OF PPAP
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          style={{
            backgroundColor: "#064a29",
          }}
          onClick={() =>
            history.push(`/generate/ppap/${partId}?partnumber=${partNumber}`)
          }
          startIcon={<Add />}
        >
          NEW PPAP
        </Button>
      </div>
      <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
      <Table
        style={{ marginTop: "3rem" }}
        className={classes.table}
        size="small"
      >
        {THead}
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={item._id}>
              <TableCell align="center">{index + 1}</TableCell>
              <TableCell align="center">{item.part_id.part_number}</TableCell>
              <TableCell align="center">{item.customer_name}</TableCell>
              <TableCell align="center">{formatDate(item.createdAt)}</TableCell>
              <TableCell align="center">
                <Tooltip title="Edit" arrow>
                  <Link to={`/edit/ppap/${partId}/${item._id}`}>
                    <IconButton
                      size="small"
                      style={{
                        marginLeft: "0.6rem",
                        color: "#161a1d",
                        fontSize: "1.4rem",
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Link>
                </Tooltip>
                <Tooltip title="Delete" arrow>
                  <IconButton
                    size="small"
                    style={{ marginLeft: "0.5rem" }}
                    onClick={() => handleDeletePpap(item._id)}
                  >
                    <DeleteIcon
                      style={{
                        fontSize: "1.4rem",
                        color: "red",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

export default SelectPPAP;
