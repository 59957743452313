import React, { useState, useEffect } from "react";
import SelectDate from "./SelectDate";
import SelectProcess from "./SelectProcess";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Wrapper } from "../Control/Flexbox";
import FailureWiseTable from "./FailureWiseTable";
import FailureCardDetails from "./FailureCardDetails";
import FailureWiseParetoGraph from "./FailureWiseParetoGraph";
import FailureWisePieGraph from "./FailureWisePieGraph";
import MonthlyRejection from "./MonthlyRejection";
import SelectProcessForMonthlyRejection from "./SelectProcessForMonthlyRejection";
import SelectMonth from "./SelectMonth";

const FailureModeWiseRejection = () => {
  const [month, setMonth] = useState(null);

  const [monthlyProcessName, setMonthlyProcessName] = useState(null);
  const [monthlyProcessList, setMonthlyProcessList] = useState({});
  const [monthlyRejection, setMonthlyRejection] = useState(null);
  const [processName, setProcessName] = useState(null);
  const [processList, setProcessList] = useState({});
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const [failureRejection, setFailureRejection] = useState(null);
  const [totalFailureRejection, setTotalFailureRejection] = useState(0);
  const [producedQty, setProducedQty] = useState(0);
  const { partName } = useParams();
  useEffect(() => {
    if (from && to) {
      axios
        .get(
          `/api/get/failureWiseRejection/${partName}/${processName}/${from}/${to}`
        )
        .then((res) => {
          console.log(res);
          if (res.data?.error) {
            setFailureRejection(null);
            return;
          }
          setFailureRejection(res.data?.failureRejected);
          setTotalFailureRejection(res.data?.totalFailureRejected);
          setProcessList(res.data?.processList);
          setProducedQty(res.data?.producedQty);
        });
    }
  }, [processName, from, partName, to]);
  useEffect(() => {
    if (month) {
      axios
        .get(`/api/get/pir/${partName}/${monthlyProcessName}/${month}`)
        .then((res) => {
          if (res.data?.error) {
            setMonthlyRejection(null);
            return;
          }
          setMonthlyRejection(res.data?.obtainData);
          setMonthlyProcessList(res.data?.processList);
        });
    }
  }, [partName, monthlyProcessName, month]);
  return (
    <div style={{ paddingBottom: "5rem" }}>
      <div className="divflex">
        <p
          style={{
            fontSize: "24px",
          }}
        >
          FAILURE MODE ANALYSIS
        </p>
        <div className="text">
          <p style={{ marginBottom: "0px" }}>
            <span style={{ fontWeight: "600" }}> Part Number : </span>
            {partName}
          </p>
        </div>
      </div>
      <p
        className="card-btn-para"
        style={{
          color: "#c80202",
          textAlign: "left",
          marginLeft: "1rem",
          marginTop: "2rem",
        }}
      >
        • VIEW{" "}
        <span style={{ fontWeight: 400, color: "black" }}>
          REJECTIONS & PPM
        </span>
      </p>
      <Wrapper style={{ marginTop: "2rem", marginLeft: "1rem" }}>
        <SelectDate {...{ from, setFrom, to, setTo }} />
        <SelectProcess
          {...{ processList, from, to, processName, setProcessName }}
        />
      </Wrapper>
      {failureRejection && (
        <Wrapper style={{ gap: "2rem" }}>
          <FailureWiseTable
            {...{ failureRejection, totalFailureRejection, producedQty }}
          />
        </Wrapper>
      )}
      {failureRejection && (
        <Wrapper style={{ marginTop: "2rem", marginLeft: "1rem", gap: "1rem" }}>
          <FailureWisePieGraph {...{ failureRejection }} />
          <FailureCardDetails {...{ producedQty, totalFailureRejection }} />
        </Wrapper>
      )}

      {failureRejection && (
        <Wrapper style={{ marginTop: "2rem", marginLeft: "1rem" }}>
          <FailureWiseParetoGraph {...{ failureRejection, producedQty }} />
        </Wrapper>
      )}

      <p
        className="card-btn-para"
        style={{
          color: "#c80202",
          textAlign: "left",
          marginLeft: "1rem",
          marginTop: "3rem",
        }}
      >
        • VIEW{" "}
        <span style={{ fontWeight: 400, color: "black" }}>
          MONTHLY PER DAY REJECTIONS
        </span>
      </p>

      <Wrapper style={{ marginTop: "2rem", marginLeft: "1rem" }}>
        <SelectMonth {...{ month, setMonth }} />
        <SelectProcessForMonthlyRejection
          {...{
            monthlyProcessList,
            month,
            monthlyProcessName,
            setMonthlyProcessName,
          }}
        />
      </Wrapper>

      {monthlyRejection && (
        <Wrapper style={{ marginLeft: "1rem", marginTop: "2rem" }}>
          <MonthlyRejection {...{ monthlyRejection }} />
        </Wrapper>
      )}
    </div>
  );
};

export default FailureModeWiseRejection;
