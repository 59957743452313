import React, { useEffect, useState } from "react";
import "../../style/product.css";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Input from "../../components/Control/Input";
import useConfirm from "../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import { DatePicker } from "@material-ui/pickers";

const auditObj = {
  april: {
    isChecked: false,
  },
  may: {
    isChecked: false,
  },
  june: {
    isChecked: false,
  },
  july: {
    isChecked: false,
  },
  august: {
    isChecked: false,
  },
  september: {
    isChecked: false,
  },
  october: {
    isChecked: false,
  },
  november: {
    isChecked: false,
  },
  december: {
    isChecked: false,
  },
  january: {
    isChecked: false,
  },
  february: {
    isChecked: false,
  },
  march: {
    isChecked: false,
  },
};

const CreateAnnualPlan = ({ id, setIsAuditModal }) => {
  const [auditData, setAuditData] = useState({
    date_range: new Date(),
  });

  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    "PROCESS AUDIT",
    `Are you sure you want to ${id ? "update" : "save"} this plan?`
  );

  useEffect(() => {
    // if (!id) {
    //   axios
    //     .get(`/api/controlPlanProcessForProcessAudit`)
    //     .then((res) => {
    //       const response = res?.data;
    //       console.log(response);
    //       auditData.processes = response?.map((item) => {
    //         return {
    //           control_id: item._id,
    //           ...auditObj,
    //         };
    //       });
    //       setRender((prev) => !prev);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }

    if (id) {
      axios
        .get(`/api/getProcessAuditById/${id}`)
        .then((res) => {
          setAuditData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleAuditData(e) {
    setAuditData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) {
      return;
    }

    if (id) {
      await axios
        .put(`/api/updateProcessAudit/${id}`, auditData)
        .then((res) => {
          setIsAuditModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post("/api/createProcessAuditData", auditData)
        .then((res) => {
          setIsAuditModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  // console.log({ auditData });

  return (
    <div className="root-admin">
      <form
        style={{
          margin: "1.8rem",
        }}
        onSubmit={(e) => onSubmit(e)}
      >
        <div className="supplier-section">
          <section className="supplier-section-part">
            <Input
              label="Title"
              name="title"
              type="text"
              onChange={handleAuditData}
              value={auditData.title}
              required
            />
            <DatePicker
              label="Date Range"
              views={["year"]}
              className="date-field"
              inputVariant="filled"
              clearable
              onChange={(date) => {
                setAuditData((prev) => ({ ...prev, date_range: date }));
              }}
              style={{ marginTop: "0.6rem" }}
              value={auditData.date_range}
            />
          </section>
          <div
            className="btn_save"
            style={{
              position: "absolute",
              right: "150px",
              top: "9px",
            }}
          >
            <Button
              type="submit"
              variant="outlined"
              color="white"
              size="large"
              style={{ color: "#e9e9e9" }}
              startIcon={<PublishIcon />}
            >
              {id ? "UPDATE" : "SUBMIT"}
            </Button>
          </div>
        </div>
      </form>
      <DialogSubmit isSubmitContent={false} />
    </div>
  );
};

export default CreateAnnualPlan;
