import React from "react";
import Input from "../../../components/Control/Input";
import { Wrapper } from "../../../components/Control/Flexbox";
const ProcessSecondHeader = ({
  pirData,
  process,
  index,
  totalProcessRejection,
}) => {
  return (
    <>
      <Wrapper>
        <div>
          <Input
            autoComplete="off"
            label="Lot no."
            name="lot_no"
            type="text"
            style={{ width: "100%" }}
            onBlur={(e) => {
              pirData.pir[index][e.target.name] = e.target.value;
            }}
            defaultValue={process.lot_no}
          />
        </div>
        <div style={{ flexBasis: "20%" }}>
          <Input
            label="Heat No."
            type="filled"
            autoComplete="off"
            name="heat_no"
            style={{ width: "293px" }}
            onBlur={(e) => {
              pirData.pir[index][e.target.name] = e.target.value;
            }}
            defaultValue={process.heat_no}
          />
        </div>
        <div style={{ flexBasis: "20%" }}>
          <Input
            label="Remarks"
            readOnly
            type="text"
            style={{ width: "100%" }}
            variant="filled"
            autoComplete="off"
            defaultValue={process.remarks}
            name="remarks"
            onBlur={(e) => {
              pirData.pir[index][e.target.name] = e.target.value;
            }}
          />
        </div>
      </Wrapper>
      <Wrapper>
        {/* <div style={{ flexBasis: "20%" }}>
          <Input
            label="Total Qty."
            variant="filled"
            autoComplete="off"
            type="number"
            name="producedQty"
            style={{ width: "100%" }}
            onBlur={(e) => {
              pirData.pir[index][e.target.name] = e.target.value;
            }}
            defaultValue={process.producedQty}
          />
        </div>
        <div style={{ flexBasis: "20%" }}>
          <Input
            label="Total Rejected Qty."
            readOnly
            style={{ width: "100%" }}
            type="filled"
            autoComplete="off"
            value={totalProcessRejection}
            name="machine"
          />
        </div>
        <div style={{ flexBasis: "20%" }}>
          <Input
            label="Total Rework Qty."
            variant="filled"
            autoComplete="off"
            name="reworkQty"
            type="number"
            style={{ width: "100%" }}
            onBlur={(e) => {
              pirData.pir[index][e.target.name] = e.target.value;
            }}
            defaultValue={process.reworkQty}
          />
        </div> */}
      </Wrapper>
    </>
  );
};

export default ProcessSecondHeader;
