import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";

import { useAuth } from "../../../context/GlobalContext";
import formatDate from "../../../components/Control/formatDate";
import useConfirm from "../../../components/Control/useConfirm";
import { calculateWorkingHour } from "../../../components/Control/CalculateHoursMinutes";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const BreakdownLogs = () => {
  const classes = useStyles();
  const { state } = useAuth();
  const [breakdownData, setBreakdownData] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [render, setRender] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this Log?"
  );

  useEffect(() => {
    axios
      .get(`/api/getBreakdownData/byMonth/${selectedDate}`)
      .then((res) => setBreakdownData(res.data));
  }, [selectedDate, render]);

  const handleDelete = async (id) => {
    const isConfirm = await confirmDelete();
    if (isConfirm) {
      axios
        .delete(`/api/deleteBreakdownLogs/${id}`)
        .then((res) => {
          window.alert("success");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <section>
      <div style={{}}>
        <div
          className="divflex"
          style={{
            fontSize: "24px",
          }}
        >
          BREAKDOWN LOGS
          <div
          // className="text"
          ></div>
        </div>

        <div className="subheading-container">
          <p className="subheading-primary">
            ACTIVE <span className="subheading-secondary">Logs</span>
          </p>
        </div>
      </div>
      <div style={{ display: "flex", margin: "1rem" }}>
        <DatePicker
          views={["month", "year"]}
          label="Month & Year"
          className="date-field"
          inputVariant="filled"
          value={selectedDate || null}
          onChange={setSelectedDate}
        />
      </div>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow style={{ padding: "6px", verticalAlign: "top" }}>
            {["S.NO.", "Date", "Description Of M/c", "Nature Of Fault "].map(
              (item, index) => (
                <TableCell
                  style={{ fontWeight: "bold" }}
                  rowSpan={2}
                  align="center"
                >
                  {item}
                </TableCell>
              )
            )}
            <TableCell
              style={{
                fontWeight: "bold",
                // border: "none",
              }}
              colSpan={2}
              align="center"
            >
              TIME
            </TableCell>
            {[
              "M/c Held Up Time ",
              "Breakdown Attended By",
              "Breakdown Work Done",
              "Spare Parts Used",
              "Breakdown Qty",
              "Breakdown Remarks",
              "Action",
            ].map((item, index) => (
              <TableCell
                style={{ fontWeight: "bold" }}
                rowSpan={2}
                align="center"
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell align="center">From</TableCell>
            <TableCell align="center">To</TableCell>
          </TableRow>
        </TableHead>
        {breakdownData && (
          <TableBody>
            {breakdownData.map((item, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.dailyProduction_date)}
                  </TableCell>
                  <TableCell align="center">
                    {item.description_machine}
                  </TableCell>
                  <TableCell align="center">{item.Nature_of_fault}</TableCell>

                  <TableCell
                    align="center"
                    style={{
                      minWidth: "68px",
                      padding: "6px 0 6px 0",
                    }}
                  >
                    {moment(item.breakdown_time_from).format("h A")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: "68px",
                      padding: "6px 0 6px 0",
                    }}
                  >
                    {moment(item.breakdown_time_to).format("h A")}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      minWidth: "68px",
                      padding: "6px 0 6px 0",
                    }}
                  >
                    {calculateWorkingHour(
                      item.breakdown_time_from,
                      item.breakdown_time_to
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {item.breakdown_attended}
                  </TableCell>
                  <TableCell align="center">
                    {item.breakdown_work_done}
                  </TableCell>
                  <TableCell align="center">{item.spare_parts}</TableCell>
                  <TableCell align="center">{item.breakdown_qty}</TableCell>
                  <TableCell align="center">{item.breakdown_remarks}</TableCell>
                  <TableCell>
                    <Tooltip title="Delete Logs" arrow>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(item._id)}
                        style={{ marginLeft: "0.5rem" }}
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "1.4rem",
                            color: "#c80202",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
      <DialogDelete isSubmitContent={true} />
    </section>
  );
};

export default BreakdownLogs;
