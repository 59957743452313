import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CloseButton } from "../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../components/Control/useConfirm";
import { Wrapper } from "../../components/Control/Flexbox";
import { Button, Paper, Tab, Tabs, Typography } from "@material-ui/core";
import { useQuery } from "../../components/Control/useQuery";
import SupplierRejection from "./components/SupplierRejection/SupplierRejection";
import DeliveryReport from "./components/DeliveryReport/DeliveryReport";
import Dashboard from "./components/Dashboard/Dashboard";
import CloudUpload from "@material-ui/icons/CloudUpload";
const SupplierRatingTab = () => {
  const history = useHistory();
  const [tabValue, setTabValue] = useState(0);
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [renderEffect, setRenderEffect] = useState(false);
  const query = useQuery();
  const [supplierRatingData, setSupplierRatingData] = useState({
    supplierRejections: [],
    deliveryReports: [],
  });
  const annualDate = query.get("annual_date");
  const isView = query.get("isView") === "true" ? true : false;

  useEffect(() => {
    axios
      .get(`/api/getSupplierRatingByAnnualDate/${annualDate}`)
      .then((res) => {
        if (!res.data?.error) {
          setSupplierRatingData(res.data);
          return;
        }
        axios.get(`/api/getSupplierForSupplierRating`).then((supplierRes) => {
          if (supplierRes.data) {
            setSupplierRatingData({
              supplierRejections: supplierRes.data,
              deliveryReports: supplierRes.data,
            });
          }
        });
      })
      .catch((err) => console.log(err));
  }, [renderEffect]);
  const onSubmit = () => {
    supplierRatingData.annual_date = annualDate;
    if (
      !window.confirm(
        `Are you sure you want to ${
          supplierRatingData._id ? "update" : "submit"
        } this document`
      )
    )
      return;
    if (supplierRatingData._id) {
      axios
        .put(
          `/api/updateSupplierRatingById/${supplierRatingData._id}`,
          supplierRatingData
        )
        .then((res) => {
          if (res.data) {
            alert("document updated successfully");
          }
        })
        .catch((err) => console.log(err));
    } else {
      axios
        .post("/api/createSupplierRating", supplierRatingData)
        .then((res) => {
          if (res.data) {
            alert("document submitted successfully");
            setRenderEffect((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <div>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          DAILY PRODUCTION REPORT
        </Typography>

        <div style={{ display: "flex" }}>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
            {!isView && (
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#064a29",
                  marginRight: "0.5rem",
                }}
                startIcon={<CloudUpload />}
                onClick={() => onSubmit()}
              >
                {supplierRatingData._id ? "Update" : "Submit"}
              </Button>
            )}
          </Wrapper>
        </div>
      </div>

      <Paper elevation={2} style={{ margin: "1rem 1.4rem" }}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="Supplier Rejection" />
          <Tab label="DeliveryReport" />
          <Tab label="Dashboard" />
        </Tabs>
      </Paper>
      <section>
        {tabValue === 0 && (
          <SupplierRejection {...{ isView, supplierRatingData, annualDate }} />
        )}
        {tabValue === 1 && (
          <DeliveryReport {...{ isView, annualDate, supplierRatingData }} />
        )}
        {tabValue === 2 && <Dashboard {...{ annualDate }} />}
      </section>
      <DialogClose isSubmitContent={false} />
    </div>
  );
};

export default SupplierRatingTab;
