import React from "react";
import { DatePicker } from "@material-ui/pickers";
const SelectMonth = ({ month, setMonth }) => {
  return (
    <div>
      <DatePicker
        views={["month", "year"]}
        label="Month & Year"
        className="date-field"
        inputVariant="filled"
        value={month || null}
        onChange={setMonth}
      />
    </div>
  );
};

export default SelectMonth;
