import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import formatDate from "../../components/Control/formatDate";
import axios from "axios";
import { DatePicker } from "@material-ui/pickers";
import { Link, useHistory } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import { useParams } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import AddFmea from "./AddFmea";
import { Wrapper } from "../../components/Control/Flexbox";
import ControlPointDuplicateIcon from "@material-ui/icons/ControlPointDuplicate";
import { useQuery } from "../../components/Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";

const FmeaAllList = () => {
  const [fmea, setFmea] = useState([]);
  const history = useHistory();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const fmeaId = useRef(null);
  const [render, setRender] = useState(false);
  const { partId } = useParams();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isFmeaModal, setIsFmeaModal] = useState(false);
  useEffect(() => {
    axios
      .get(`/api/get/allfmea/${partId}/${selectedDate}`)
      .then((res) => {
        setFmea(res.data);
      })
      .catch((err) => console.log(err));
  }, [selectedDate, render]);

  const deleteFmea = (id) => {
    let isDelete = window.confirm(`Are you sure you want to delete this Fmea?`);
    if (isDelete) {
      axios
        .delete(`/api/remove/fmea/${id}`)
        .then(() => {
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <main style={{ paddingBottom: "4rem" }}>
      <div className="divflex" style={{ fontSize: "24px" }}>
        FAILURE MODE & EFFECT ANALYSIS
        <Wrapper style={{ gap: "1rem" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            style={{
              backgroundColor: "#064a29",
            }}
            onClick={() => {
              fmeaId.current = null;
              setIsFmeaModal(true);
            }}
            startIcon={<Add />}
          >
            New Report
          </Button>
        </Wrapper>
      </div>
      <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
      <div className="subheading-container">
        <p className="subheading-primary">
          ACTIVE <span className="subheading-secondary">REPORTS</span>
        </p>
      </div>
      <div style={{ marginTop: "2rem", marginLeft: "1rem" }}>
        <DatePicker
          views={["month", "year"]}
          label="Month & Year"
          className="date-field"
          inputVariant="filled"
          value={selectedDate}
          onChange={setSelectedDate}
        />
      </div>
      <Table size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Item Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              From
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              To
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Revision Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Created At
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {fmea && (
          <TableBody>
            {fmea.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {item.part_id.part_number}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {formatDate(item.from)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {formatDate(item.to)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.revision_no}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="View" arrow>
                      <Link to={`/view/fmea/${item._id}`}>
                        <IconButton size="small">
                          <VisibilityIcon
                            style={{ fontSize: "1.4rem", color: "#003566" }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>

                    <Tooltip title="Edit" arrow>
                      <Link to={`/generatefmea/report/${item._id}`}>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <EditIcon
                            style={{ fontSize: "1.4rem", color: "#003566" }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>

                    <Tooltip title="Delete" arrow>
                      <IconButton
                        size="small"
                        style={{ marginLeft: "0.5rem" }}
                        onClick={() => deleteFmea(item._id)}
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "1.4rem",

                            color: "red",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Button
                      variant="outlined"
                      color="white"
                      style={{
                        color: "#fafafa",
                        backgroundColor: "#161A1D",
                        marginLeft: "0.5rem",
                      }}
                      onClick={(e) => {
                        // updateRequestForm(item._id, index);
                        fmeaId.current = item._id;
                        setIsFmeaModal(true);
                      }}
                      startIcon={
                        <ControlPointDuplicateIcon
                          style={{ fontSize: "1.4rem" }}
                        />
                      }
                    >
                      Duplicate
                    </Button>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <Modal
        openPopup={isFmeaModal}
        title="ADD FMEA"
        closeModal={() => setIsFmeaModal(false)}
      >
        <AddFmea {...{ partId }} fmeaId={fmeaId.current} />
      </Modal>
    </main>
  );
};

export default FmeaAllList;
