const ApprovalSelect = ({ handleChangeStatus, item, index, statusObj }) => {
  return (
    <>
      {item.isSubmitted ? (
        <select
          style={{
            fontSize: "1rem",
            outline: "none",
            border: "none",
            padding: "5px",
            marginLeft: "0.5rem",
            width: "150px",
            textAlign: "center",
            textTransform: "capitalize",
            ...(item.is_admin_request === "pending" && {
              backgroundColor: "#FDD835",
            }),
            ...(item.is_admin_request === "accepted" && {
              backgroundColor: "#2b8a3e",
              color: "#fff",
            }),
            ...(item.is_admin_request === "rejected" && {
              backgroundColor: "#f03e3e",
              color: "#fff",
            }),
          }}
          name="is_admin_request"
          value={statusObj[item?.is_admin_request] || item?.is_admin_request_no}
          onChange={(e) => handleChangeStatus(e, index)}
        >
          <option value="1">PENDING</option>
          <option value="2">APPROVE</option>
          {/* <option value="3">SEND BACK</option> */}
        </select>
      ) : (
        <div
          style={{
            backgroundColor: "#FDD835",
            fontSize: "1rem",
            outline: "none",
            padding: "6px",
            textAlign: "center",
            width: "200px",
            margin: "auto",
          }}
        >
          {item.isSubmitted ? "SUBMITTED" : "DRAFT"}
        </div>
      )}
    </>
  );
};

export default ApprovalSelect;
