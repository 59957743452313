import { Link, useHistory } from "react-router-dom";
import "../../style/admin.css";
import "../../style/supervisor.css";
import { useAuth } from "../../context/GlobalContext";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";
import PhotoSizeSelectActualIcon from "@material-ui/icons/PhotoSizeSelectActual";
import DescriptionIcon from "@material-ui/icons/Description";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed";
import LibraryAddIcon from "@material-ui/icons/LibraryAdd";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import TableChartIcon from "@material-ui/icons/TableChart";
import NoteIcon from "@material-ui/icons/Note";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import DomainDisabledIcon from "@material-ui/icons/DomainDisabled";
import BallotIcon from "@material-ui/icons/Ballot";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import EmojiTransportationIcon from "@material-ui/icons/EmojiTransportation";
import TuneIcon from "@material-ui/icons/Tune";

import homePage from "./HomePage.module.css";
import useConfirm from "../Control/useConfirm";
import FeaturedPlayList from "@material-ui/icons/FeaturedPlayList";
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle";

const Supervisor = () => {
    const { state, logout } = useAuth();
    const history = useHistory();
    const [Dialog, confirmLogoutUser] = useConfirm(
        "LOG OUT",
        "Are you sure you want to log out?"
    );
    const logoutUser = async () => {
        const ans = await confirmLogoutUser();
        if (ans) {
            logout();
            history.push("/");
        }
    };
    if (state.user.userType == 3) {
        return (
            <>
                <main className={`${homePage.container} ${homePage.main}`}>
                    <div>
                        <Link to="/supervisor/viewparttable/controlplan" className="panel">
                            <DescriptionIcon style={{ fontSize: "3rem", color: "maroon" }} />
                        </Link>
                        <p className="card-btn-para">
                            Control <span className="card-btn-span">Plan</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/supervisor/viewparttable/pcd" className="panel">
                            <PhotoSizeSelectActualIcon
                                style={{ fontSize: "3rem", color: "maroon" }}
                            />
                        </Link>
                        <p className="card-btn-para">
                            Process Control<span className="card-btn-span">Drawing</span>
                        </p>
                    </div>
                    <div>
                        <Link
                            to="/supervisor/viewparttable/pir"
                            className="panel"
                            style={{ marginLeft: "2rem" }}
                        >
                            <ListAltIcon style={{ fontSize: "3rem", color: "#003566" }} />
                        </Link>
                        <p className="card-btn-para">
                            Inspection & Setup
                            <span className="card-btn-span">Reports</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/scrapNoteList" className="panel">
                            <FeaturedPlayListIcon
                                style={{ fontSize: "3rem", color: "#003566" }}
                            />
                        </Link>
                        <p className="card-btn-para">
                            Scrap Note <span className="card-btn-span">Reports</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/supervisor/viewparttable/pdir" className="panel">
                            <DynamicFeedIcon style={{ fontSize: "3rem", color: "#003566" }} />
                        </Link>
                        <p className="card-btn-para">
                            Pre-dispatch
                            <span className="card-btn-span">Inspection Reports</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/supervisor/viewparttable/inspection" className="panel">
                            <LibraryAddIcon style={{ fontSize: "3rem", color: "#003566" }} />
                        </Link>{" "}
                        <p className="card-btn-para">
                            Incoming<span className="card-btn-span">Reports</span>
                        </p>
                    </div>

                    <div>
                        <Link
                            to="/viewDailyProduction"
                            className="panel"
                            style={{ color: "#3d3e40", textDecoration: "none" }}
                        >
                            <FeaturedPlayListIcon
                                style={{ fontSize: "3rem", color: "#003566" }}
                            />
                        </Link>
                        <p className="card-btn-para">
                            Daily Production <span className="card-btn-span">Reports</span>
                        </p>
                    </div>
                    <div>
                        <Link
                            to="/productionPlanningcontrol/create"
                            className="panel"
                            style={{ color: "#3d3e40", textDecoration: "none" }}
                        >
                            <MenuBookIcon style={{ fontSize: "3rem", color: "#003566" }} />
                        </Link>
                        <p className="card-btn-para">
                            PPC
                            <span className="card-btn-span">Reports</span>
                        </p>
                    </div>
                    <div>
                        <Link
                            to="/supervisor/viewparttable/customerDrawing"
                            className="panel"
                            style={{ color: "#3d3e40", textDecoration: "none" }}
                        >
                            <PhotoAlbumIcon style={{ fontSize: "3rem", color: "#003566" }} />
                        </Link>
                        <p className="card-btn-para">
                            Customer <span className="card-btn-span">Drawing</span>
                        </p>
                    </div>

                    <div>
                        <Link to="/materialTc/table" className="panel">
                            <TableChartIcon style={{ fontSize: "3rem", color: "#003566" }} />
                        </Link>
                        <p className="card-btn-para">
                            Material
                            <span className="card-btn-span"> TC</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/processProductData/table" className="panel">
                            <NoteIcon style={{ fontSize: "3rem", color: "#003566" }} />
                        </Link>
                        <p className="card-btn-para">
                            Process/Product
                            <span className="card-btn-span">Validation</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/documentDataControl/table" className="panel">
                            <PostAddIcon style={{ fontSize: "3rem", color: "#003566" }} />
                        </Link>
                        <p className="card-btn-para">
                            {"Document & Data"}
                            <span className="card-btn-span">Control</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/supervisor/viewparttable/msa" className="panel">
                            <AssessmentIcon style={{ fontSize: "3rem", color: "#003566" }} />
                        </Link>{" "}
                        <p className="card-btn-para">
                            MSA<span className="card-btn-span">Report</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/machine_list" className="panel">
                            <BallotIcon style={{ fontSize: "3rem", color: "maroon" }} />
                        </Link>{" "}
                        <p className="card-btn-para">
                            Master List of <span className="card-btn-span">Machine</span>
                        </p>
                    </div>
                    {/* <div>
            <Link to="/educationAndTraining/create" className="panel">
              <GroupAddIcon style={{ fontSize: "3rem", color: "#003566" }} />
            </Link>
            <p className="card-btn-para">
              Master List of
              <span className="card-btn-span">Employee</span>
            </p>
          </div> */}
                    <div>
                        <Link to="/supplier/create" className="panel">
                            <EmojiTransportationIcon
                                style={{ fontSize: "3rem", color: "#003566" }}
                            />
                        </Link>
                        <p className="card-btn-para">
                            Master List of
                            <span className="card-btn-span">Supplier</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/instrumentsList" className="panel">
                            <TuneIcon style={{ fontSize: "3rem", color: "#003566" }} />
                        </Link>
                        <p className="card-btn-para">
                            Master List of
                            <span className="card-btn-span">Instruments</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/maintenance_breakdown" className="panel">
                            <DomainDisabledIcon
                                style={{ fontSize: "3rem", color: "maroon" }}
                            />
                        </Link>{" "}
                        <p className="card-btn-para">
                            Maintenance <span className="card-btn-span">Module</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/incomingRMResisterList" className="panel">
                            <FeaturedPlayListIcon
                                style={{ fontSize: "3rem", color: "#003566" }}
                            />
                        </Link>
                        <p className="card-btn-para">
                            Incoming RM <span className="card-btn-span">Resister</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/miscDocumentsDrawings/table" className="panel">
                            <AddPhotoAlternateIcon
                                style={{ fontSize: "3rem", color: "#003566" }}
                            />
                        </Link>
                        <p className="card-btn-para">
                            Misc. Documents
                            <span className="card-btn-span">{"& Drawings"}</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/viewNotebooksList" className="panel">
                            <LibraryBooksIcon
                                style={{ fontSize: "3rem", color: "#003566" }}
                            />
                        </Link>{" "}
                        <p className="card-btn-para">
                            My<span className="card-btn-span">Notebook</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/supervisor/view/4mTable" className="panel">
                            <SupervisedUserCircleIcon
                                style={{ fontSize: "3rem", color: "#003566" }}
                            />
                        </Link>{" "}
                        <p className="card-btn-para">
                            4M<span className="card-btn-span">Management</span>
                        </p>
                    </div>

                    <div>
                        <Link to="/viewMonitoringList" className="panel">
                            <FeaturedPlayList
                                style={{ fontSize: "3rem", color: "#003566" }}
                            />
                        </Link>
                        <p className="card-btn-para">
                            Moniterning <span className="card-btn-span">Reports</span>
                        </p>
                    </div>
                    <div>
                        <Link to="/abnormality" className="panel">
                            <SupervisedUserCircle
                                style={{ fontSize: "3rem", color: "#003566" }}
                            />
                        </Link>{" "}
                        <p className="card-btn-para">
                            Abnormality<span className="card-btn-span">Management</span>
                        </p>
                    </div>
                    <div>
                        <Link
                            to="/supervisor/dashboard"
                            className="panel"
                            style={{ color: "#3d3e40", textDecoration: "none" }}
                        >
                            <DashboardIcon style={{ fontSize: "3rem", color: "#064a29" }} />
                        </Link>
                        <p className="card-btn-para">
                            View <span className="card-btn-span">Dashboard</span>
                        </p>
                    </div>
                    <div>
                        <Link
                            style={{ cursor: "pointer" }}
                            to="/"
                            className="panel"
                            onClick={logoutUser}
                        >
                            <ExitToAppIcon style={{ fontSize: "3rem", color: "maroon" }} />
                        </Link>{" "}
                        <p className="card-btn-para">
                            Logout <span className="card-btn-span">Aqua</span>
                        </p>
                    </div>
                </main>
                <Dialog />
            </>
        );
    } else {
        return (
            <div>
                <h1>Page Not Found</h1>
            </div>
        );
    }
};

export default Supervisor;
