import { Icon, IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import React, { useState } from "react";
import { useQuery } from "../../../../components/Control/useQuery";
import TableReadEditCell from "../../../../components/TableEditCell";

const TBody2 = ({
  data,
  setIsInitialModal,
  handleSubmitAndSave,
  fieldId,
  isView,
}) => {
  const [render, setRender] = useState(false);
  const query = useQuery();
  const partName = query.get("partName");
  const openModal = async (fieldIndex) => {
    try {
      let res = await handleSubmitAndSave();
      if (res) {
        fieldId.current = res.initialSupply[fieldIndex]._id;
        setIsInitialModal(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <tr>
        <th>S.NO</th>
        <th colSpan={4}>DATE</th>
        <th colSpan={3}>LOT NO.</th>
        <th colSpan={4}>PART DESCRIPTION</th>
        <th colSpan={3}>DISPATCH QUANTITY</th>
        <th colSpan={3}>NO. OF SAMPLES</th>
        <th colSpan={3}>INV. NO.</th>
        <th colSpan={3}>CHECKED BY</th>
        <th colSpan={3}>APPROVED BY</th>
        <th colSpan={4}>QA REMARKS</th>
        <th colSpan={3}>UPLOAD</th>
      </tr>
      {data.initialSupply.map((item, index) => (
        <tr>
          <td>{index + 1}</td>
          <td colSpan={4}>{item.date}</td>
          <td colSpan={3}>
            <TableReadEditCell
              value={item.lot_no}
              type="text"
              isView={isView}
              handleChange={(e) => {
                item.lot_no = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={4}>{data.part_id?.part_name || partName}</td>
          <td colSpan={3}>
            <TableReadEditCell
              value={item.dispatch_qty}
              type="number"
              isView={isView}
              handleChange={(e) => {
                item.dispatch_qty = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={3}>
            <TableReadEditCell
              value={item.no_of_sample}
              type="number"
              isView={isView}
              handleChange={(e) => {
                item.no_of_sample = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={3}>
            <TableReadEditCell
              value={item.inv_no}
              type="number"
              isView={isView}
              handleChange={(e) => {
                item.inv_no = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={3}>
            <TableReadEditCell
              value={item.checked_by}
              type="text"
              isView={isView}
              handleChange={(e) => {
                item.checked_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={3}>
            <TableReadEditCell
              value={item.approved_by}
              type="text"
              isView={isView}
              handleChange={(e) => {
                item.approved_by = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={4}>
            <TableReadEditCell
              value={item.qa_remarks}
              type="text"
              isView={isView}
              handleChange={(e) => {
                item.qa_remarks = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={3}>
            <IconButton
              onClick={() => {
                openModal(index);
              }}
            >
              <Add />
            </IconButton>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TBody2;
