import React from "react";
import { Link } from "react-router-dom";
import clausePage from "./ClausePage.module.css";
//icons
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TableChartIcon from "@material-ui/icons/TableChart";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";

import ListIcon from '@material-ui/icons/List';
// end icons

const Clause4 = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        Standards Management
      </div>
      <main className={`${clausePage.container} ${clausePage.main}`}>
        <div>
          <Link to="/masterProcedureList?category=clause-4" className="panel">
            <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Master <span className="card-btn-span">Procedure</span>
          </p>
        </div>

        <div>
          <Link to="/part_list" className="panel">
            <CreateIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Add <span className="card-btn-span">Part</span>
          </p>
        </div>
        <div>
          <Link to="/viewPartTable" className="panel">
            <VisibilityIcon style={{ fontSize: "3rem", color: "#054a29" }} />
          </Link>
          <p className="card-btn-para">
            View <span className="card-btn-span">Documents</span>
          </p>
        </div>
        <div>
          <Link to="/materialTc/table" className="panel">
            <TableChartIcon style={{ fontSize: "3rem", color: "#003566" }} />
          </Link>
          <p className="card-btn-para">
            Material
            <span className="card-btn-span"> TC</span>
          </p>
        </div>
        <div>
          <Link to="/documentDataControl/table" className="panel">
            <PostAddIcon style={{ fontSize: "3rem", color: "#003566" }} />
          </Link>
          <p className="card-btn-para">
            {"Document & Data"}
            <span className="card-btn-span">Control</span>
          </p>
        </div>
        {/* <div>
          <Link to="/miscDocumentsDrawings/table" className="panel">
            <AddPhotoAlternateIcon
              style={{ fontSize: "3rem", color: "#003566" }}
            />
          </Link>
          <p className="card-btn-para">
            Misc. Documents
            <span className="card-btn-span"> & Drawings</span>
          </p>
        </div> */}
        <div>
          <Link to="/customerList" className="panel">
            <PeopleAltIcon style={{ fontSize: "3rem", color: "#343a40" }} />
          </Link>
          <p className="card-btn-para">
            Master List of
            <span className="card-btn-span">Customer</span>
          </p>
        </div>
      </main>
    </>
  );
};

export default Clause4;
