import Input from "../../Control/Input";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Wrapper } from "../../Control/Flexbox";
import RefreshIcon from "@material-ui/icons/Refresh";
import { ExpandLess, ExpandMore, Remove, Add } from "@material-ui/icons";

const CheckingAids = (props) => {
  const { ppapData } = props;
  const [render, setRender] = useState(false);
  const [isSpecs, setIsSpecs] = useState([]);
  function handleSpec(e, index) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    ppapData.checking_aids[index][e.target.name] = value;
  }

  function append(where, data) {
    ppapData[where].push(data);
    setRender((prev) => !prev);
  }

  function remove(where, index) {
    if (window.confirm("Are you sure you want to delete Checking Aid?")) {
      ppapData[where].splice(index, 1);
      setRender((prev) => !prev);
    }
  }

  const hideSpecs = (index) => {
    setIsSpecs((prev) => [...prev, index]);
  };

  const openSpecs = (index) => {
    const values = [...isSpecs];
    const idx = values.indexOf(index);
    values.splice(idx, 1);
    setIsSpecs(values);
  };

  return (
    <div className="specs-container" style={{ marginBottom: "3rem" }}>
      <Wrapper>
        <Input
          type="text"
          name="check_issue_no"
          label="Issue No."
          onChange={(e) => {
            ppapData.check_issue_no = e.target.value;
          }}
          defaultValue={ppapData.check_issue_no}
        />
        <Input
          type="text"
          name="check_prepared"
          label="Prepared by"
          onChange={(e) => {
            ppapData.check_prepared = e.target.value;
          }}
          defaultValue={ppapData.check_prepared}
        />
        <Input
          type="text"
          name="check_approved"
          label="Approved By"
          onChange={(e) => {
            ppapData.check_approved = e.target.value;
          }}
          defaultValue={ppapData.check_approved}
        />
      </Wrapper>
      <div>
        <Wrapper
          justify="space-between"
          style={{ borderBottom: "1px solid #c80202" }}
        >
          <p className="card-btn-para" style={{ color: "#c80202" }}>
            • ADD{" "}
            <span style={{ fontWeight: 400, color: "black" }}>
              CHECKING AIDS
            </span>
          </p>
          <Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "50px",
                backgroundColor: "#c80202",
                color: "white",
                cursor: "pointer",
              }}
              onClick={() => {
                append("checking_aids", {
                  s_no: ppapData.checking_aids.length + 1,
                });
              }}
              justify="center"
            >
              <Add />
            </Wrapper>
            <Wrapper
              style={{
                height: "45px",
                width: "50px",
                backgroundColor: "#151a1d",
                color: "white",
                cursor: "pointer",
              }}
              justify="center"
              onClick={() => {
                ppapData?.checking_aids?.sort(
                  (a, b) => Number(a.s_no) - Number(b.s_no)
                );
                setRender((prev) => !prev);
              }}
            >
              <RefreshIcon />
            </Wrapper>
          </Wrapper>
        </Wrapper>
      </div>

      {ppapData.checking_aids.map((spec, index) => (
        <div
          key={uuidv4()}
          style={{
            marginBottom: "0.3rem",
            marginTop: "0.6rem",
            padding: "0rem",
            border: "1px solid #f0f2f0",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              background: "#fafafa",
              alignItems: "center",
            }}
            className="spec-header"
          >
            <Wrapper>
              <Input
                type="Number"
                name="s_no"
                onBlur={(e) => handleSpec(e, index)}
                defaultValue={spec.s_no}
                style={{ width: "80px" }}
              />
              <Wrapper
                justify="center"
                style={{
                  marginLeft: "0.4rem",
                  fontSize: "0.9rem",
                  textTransform: "uppercase",
                }}
              >
                {spec.checking_aid}
              </Wrapper>
            </Wrapper>
            <Wrapper>
              {isSpecs.includes(index) ? (
                <Wrapper
                  style={{
                    height: "45px",
                    width: "50px",
                    backgroundColor: "#3f51b5",
                    cursor: "pointer",
                    color: "white",
                  }}
                  justify="center"
                  onClick={() => openSpecs(index)}
                >
                  <ExpandLess />
                </Wrapper>
              ) : (
                <Wrapper
                  style={{
                    height: "45px",
                    width: "50px",
                    backgroundColor: "#3f51b5",
                    cursor: "pointer",
                    color: "white",
                  }}
                  justify="center"
                  onClick={() => hideSpecs(index)}
                >
                  <ExpandMore />
                </Wrapper>
              )}
              <Wrapper
                style={{
                  height: "45px",
                  width: "50px",
                  backgroundColor: "#151a1d",
                  color: "white",
                  cursor: "pointer",
                }}
                justify="center"
                onClick={() => remove("checking_aids", index)}
              >
                <Remove />
              </Wrapper>
            </Wrapper>
          </div>
          {isSpecs.includes(index) && (
            <div className="specs-field">
              <Wrapper>
                <Input
                  type="text"
                  name="checking_aid"
                  placeholder="Checking Aid Used"
                  onBlur={(e) => handleSpec(e, index)}
                  defaultValue={spec.checking_aid}
                />
                <Input
                  type="text"
                  name="range"
                  placeholder="Range"
                  onBlur={(e) => handleSpec(e, index)}
                  defaultValue={spec.range}
                />
                <Input
                  type="text"
                  name="least_count"
                  placeholder="Least Count"
                  onBlur={(e) => handleSpec(e, index)}
                  defaultValue={spec.least_count}
                />
                <Input
                  type="text"
                  name="cali_freq"
                  placeholder="Calibration Freq."
                  onBlur={(e) => handleSpec(e, index)}
                  defaultValue={spec.cali_freq}
                />
                <Input
                  type="text"
                  name="remarks"
                  placeholder="Remarks"
                  onBlur={(e) => handleSpec(e, index)}
                  defaultValue={spec.remarks}
                />
              </Wrapper>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};
export default CheckingAids;
