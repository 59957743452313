import React from "react";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import Styled from "styled-components";
import { Wrapper } from "../../Control/Flexbox";
import PrintIcon from "@material-ui/icons/Print";
import formatDate from "../../Control/formatDate";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;


    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
        font-weight: 400;
    }
`;
const ApprovedSupplier = ({ ppapData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <section>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          LIST OF APPROVED SUPPLIER
        </Typography>
        <Wrapper>
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  backgroundColor: "#161a1d",
                  borderColor: "#161a1d",
                  borderRadius: "0",
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: "List of Approved Supplier.xlsx",
                      sheet: {
                        name: "List Of Approved Supplier",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      </div>
      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          data-default-wrap="true"
          data-cols-width="10, 40, 40, 40"
        >
          <tr data-height="100">
            <th
              colSpan="4"
              align="left"
              style={{
                border: "1px solid black",
                fontSize: "1.6rem",
                textAlign: "center",
                height: "100px",
              }}
            >
              <CenteredLogoImg>
                <img src={images.arcLogo} alt={textInputs.companyName} />
                <span>{textInputs.companyName}</span>
              </CenteredLogoImg>
            </th>
          </tr>

          <tr>
            <td
              colSpan="4"
              align="center"
              style={{
                fontSize: "1.2rem",
                fontWeight: 600,
                backgroundColor: "#1d1d1d",
                color: "white",
                border: "1px solid black",
              }}
              data-font-sz="18"
              data-a-h="center"
              data-f-bold="true"
              data-fill-color="#1d1d1d"
              data-f-color="FFFFFF"
            >
              LIST OF APPROVED SUPPLIER
            </td>
          </tr>
          <tr style={{ height: "40px" }}>
            <td
              align="left"
              colSpan={4}
              style={{
                border: "1px solid black",
              }}
            ></td>
          </tr>

          <tr>
            <th
              align="left"
              style={{
                border: "1px solid black",
              }}
            >
              Part:
            </th>
            <td
              align="left"
              style={{
                border: "1px solid black",
              }}
            >
              {ppapData.part_id.part_number}
            </td>
            <th
              align="left"
              style={{
                border: "1px solid black",
              }}
            >
              Part Description:
            </th>
            <td
              align="left"
              style={{
                border: "1px solid black",
              }}
            >
              {ppapData.part_id.part_name}
            </td>
          </tr>
          <tr style={{ height: "40px" }}>
            <td
              align="left"
              colSpan={4}
              style={{
                border: "1px solid black",
              }}
            ></td>
          </tr>
          <tbody>
            <tr>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                S NO.
              </th>
              <th
                align="center"
                colSpan={2}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                NAME & ADDRESS OF RAW MATERIAL SUPPLIERS
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                SUPPLIES
              </th>
            </tr>
            {ppapData.las_master.map((item, index) => (
              <tr key={item._id}>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.s_no}
                </td>
                <td
                  align="center"
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.name}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.supplies}
                </td>
              </tr>
            ))}
            <tr>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                S NO.
              </th>
              <th
                align="center"
                colSpan={2}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                Name & Address of Sub-Contractors
              </th>
              <th
                align="center"
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                SUPPLIES
              </th>
            </tr>
            {ppapData.las_secondary.map((item, index) => (
              <tr key={item._id}>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.s_no}
                </td>
                <td
                  align="center"
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.name}
                </td>
                <td
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {item.type_of_work}
                </td>
              </tr>
            ))}
          </tbody>
          <tr>
            <td
              colSpan={3}
              style={{
                border: "1px solid black",
              }}
            >
              Reviewed: <span>{ppapData.las_reviewed}</span>
            </td>
            <td
              colSpan={1}
              style={{
                border: "1px solid black",
              }}
            >
              Approved: <span>{formatDate(ppapData.approved)}</span>
            </td>
          </tr>
        </Table>
      </div>
    </section>
  );
};

export default ApprovedSupplier;
