import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { Wrapper } from "../../components/Control/Flexbox";
import Input from "../../components/Control/Input";

const IncomingChecks = ({
  incoming,
  handleChangeincomingList,
  handleChangeincomingText,
}) => {
  return (
    <>
      <Wrapper style={{ flexWrap: "wrap" }}>
        {(incoming.appearance === "check1" ||
          incoming.appearance === "check2" ||
          incoming.appearance === "check4") &&
          [...Array(10).fill(0)].map((item, index) => (
            <Input
              key={index}
              name="obs_1"
              label={index + 1}
              type="number"
              variant="filled"
              inputProps={{
                step: 0.0001,
                style: {
                  borderColor: incoming.color[index] || "#fff",
                  backgroundColor: incoming.color[index] || "#fff",
                },
              }}
              onChange={(e) =>
                handleChangeincomingList(
                  e,
                  index,
                  incoming.mean,
                  incoming.utolerance,
                  incoming.ltolerance,
                  incoming.ult,
                  incoming.ltl,
                  incoming?.check4_symbol,
                  incoming?.checkbox4_num
                )
              }
              defaultValue={incoming.values[index]}
            />
          ))}
        {incoming.appearance === "check3" &&
          [...Array(10).fill(0)].map((item, index) => (
            <FormControl
              variant="filled"
              className="select-field"
              style={{ marginTop: "1rem", margin: "0.6rem", width: "192px" }}
            >
              <InputLabel>{index + 1}</InputLabel>
              <Select
                name="obs_1"
                defaultValue={incoming.values[index]}
                onChange={(e) => handleChangeincomingText(e, index)}
              >
                <MenuItem>None</MenuItem>
                <MenuItem value="ok">OK</MenuItem>
                <MenuItem value="not ok">NOT OK</MenuItem>
              </Select>
            </FormControl>
          ))}
      </Wrapper>
    </>
  );
};

export default IncomingChecks;
