export const apqpConstant = {
  phase_1: [
    {
      s_no: 1,
      activity:
        "Receive customer enquiry / documents / sample / drawing. ECN & PCN",
    },
    {
      s_no: 2,
      activity: "Enter details in New Development Log Book & ECN Log Book ",
    },
    {
      s_no: 3,
      activity:
        "Project matches to company’s manufacturing scope & Prepare Specification of Product & Process.",
    },
    {
      s_no: 4,
      activity:
        "Reviews the same & in case of any doubt, calls a Specifications Review Meeting with the customer.",
    },
    {
      s_no: 5,
      activity:
        "Use best practices & take benefit of TGR / TGW and Past Defect History Records.",
    },
    {
      s_no: 6,
      activity:
        "Along with CFT, Study Customer Enquiry /Document /Sample /Drawing ,Specifications,& Special Characteristics .Customer & other Requirements ,Need And expectations are also considered & make Feasibility report.",
    },
    {
      s_no: 7,
      activity:
        "In case any change is required from the customer to make the development feasible, communicates the same to the customer for making the necessary changes.",
    },
    {
      s_no: 8,
      activity:
        "Prepare Quotation, submit to customer & resolve commercial issues.",
    },
    {
      s_no: 9,
      activity: "Follow up with customer if no response within a week ",
    },
  ],
  phase_2: [
    {
      s_no: 1,
      activity:
        "Receive Development Order / Purchase Order from the customer. ",
    },
    {
      s_no: 2,
      activity:
        "Prepares APQP Timing Plan containing plan of the development activities & control items should be based on APQP Responsibility Matrix & get approval from Top Management  ",
    },
    {
      s_no: 3,
      activity: "Prepare Micro Level Tool Development Plan.",
    },
    {
      s_no: 4,
      activity: "Prepare Gauge & Fixture Development Plan.",
    },
  ],
  phase_3: [
    {
      s_no: 1,
      activity: "Prepare Process Flow Chart.",
    },
    {
      s_no: 2,
      activity: "CFT will Prepare PFMEA Matrix.",
    },
    {
      s_no: 3,
      activity: "CFT will Prepare PFMEA.",
    },
    {
      s_no: 4,
      activity: "Prepare Pre-launch Control Plan.",
    },

    {
      s_no: 5,
      activity:
        "Prepare All process instruction providing instructions providing sufficient understanding and details for all personnel who have direct responsibility for the operation of the processes on the basis of pre- launch control plan & other Supporting Document .",
    },
    {
      s_no: 6,
      activity: "Prepare Packaging Standard as per customer requirement",
    },
    {
      s_no: 7,
      activity:
        "Training shall be provided to all persons of Concerned departments before Start Sample Trial.",
    },
    {
      s_no: 8,
      activity: "T – 1 Trial",
    },
    {
      s_no: "8a",
      activity: "T1 Trial Run",
    },
    {
      s_no: "8b",
      activity: "Sample Submission to customer ",
    },
    {
      s_no: "8c",
      activity: "Sample Approval from customer",
    },
    {
      s_no: "8d",
      activity: "Follow up with customer if no response within a week",
    },
    {
      s_no: "9",
      activity: "T – 2 Trial (If required)",
    },
    {
      s_no: "9a",
      activity: "T2 Trial Run",
    },
    {
      s_no: "9b",
      activity: "Sample Submission to Customer",
    },
    {
      s_no: "9c",
      activity: "Sample Approval from customer",
    },
    {
      s_no: "9d",
      activity: "Follow up with customer if no response within a week",
    },
    {
      s_no: 10,
      activity: "T – 3 Trial (If required)",
    },
    {
      s_no: "10a",
      activity: "T3Trial Run",
    },
    {
      s_no: "10b",
      activity: "Sample Submission to Customer",
    },
    {
      s_no: "10c",
      activity: "Sample Approval from customer",
    },
    {
      s_no: "10d",
      activity: "Follow up with customer if no response within a week.",
    },
  ],

  phase_4: [
    {
      s_no: 1,
      activity:
        "Training shall be provided to all persons of Concerned departments before Start of manufacturing the Pilot lot ",
    },
    {
      s_no: 2,
      activity:
        "Starts manufacturing the Pilot Lot under close monitoring of the CFT as defined in the Pre-launch Control Plan.",
    },
    {
      s_no: 3,
      activity:
        "Perform Measurement System Analysis & Process Capability Study as required.",
    },
    {
      s_no: 4,
      activity: "Conduct Product & Process Audit.",
    },
    {
      s_no: 5,
      activity:
        "Check pilot lot as per the Pre-launch Control Plan & prepare all PPAP documents, including PSW.",
    },
    {
      s_no: 6,
      activity:
        "Submit PPAP documents to the customer along with   Pilot Lot for approval.",
    },
    {
      s_no: 7,
      activity: "Follow up with customer if no response within a week.",
    },
  ],
  phase_5: [
    {
      s_no: 1,
      activity:
        "Receive Pilot Lot Approval Documents from customer & hand over to Quality.",
    },
    {
      s_no: 2,
      activity:
        "Record all quality issues in List of Quality Issues / Defects Faced In-house & at Customer End during Trials.",
    },
    {
      s_no: 3,
      activity:
        "Analyse & initiate preventive measures through PDCA Analysis Sheet / CAPA Report.",
    },
    {
      s_no: 4,
      activity:
        "Prepare TGR / TGW sheet which they faced during the development period.",
    },
    {
      s_no: 5,
      activity: "Prepare APQP Sign Off  after SOP at Customer end.",
    },
    {
      s_no: 6,
      activity: "Follow up with customer if no response within a week.",
    },
    {
      s_no: 7,
      activity:
        "Start Production under IPC & monitoring the production activities closely for each production lot.",
    },
  ],
};
