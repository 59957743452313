import React, { useState, useEffect } from "react";
import axios from "axios";
import MSAForm from "./MSAForm";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "../../components/Control/useQuery";

const CreateMSA = () => {
  const { instrumentId } = useParams();
  const history = useHistory();
  const query = useQuery();
  const instrumentName = query.get("instrumentName");
  const idNo = query.get("idNo");
  const lc = query.get("lc");
  const submit = () => {
    axios.post(`/api/add/msa`, MSAData).then((res) => {
      history.push(
        `/view/msaTable/${instrumentId}?instrumentName=${instrumentName}&idNo=${idNo}&lc=${lc}`
      );
    });
  };
  const [MSAData, setMSAData] = useState({
    instrument_id: instrumentId,
    characteristic: "",
    spec: "",
    gage_no: "",
    gage_name: "",
    gage_type: "",
    date_performance: Date.now(),
    data: [...Array(3)].map((item) => ({
      name: "",
      values: Array.from(Array(3), () => Array.from(Array(10), () => null)),
    })),
    least_count: "",
    isSubmitted: null,
  });

  return (
    <>
      <MSAForm {...{ MSAData, submit, setMSAData }} />
    </>
  );
};

export default CreateMSA;
