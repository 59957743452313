import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import formatDate from "../../../components/Control/formatDate";
import axios from "axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker } from "@material-ui/pickers";
import { Link } from "react-router-dom";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { Wrapper } from "../../../components/Control/Flexbox";
import { useAuth } from "../../../context/GlobalContext";
import useConfirm from "../../../components/Control/useConfirm";
import { Delete, Edit } from "@material-ui/icons";

function adminRequestForm(item) {
    if (item.is_admin_request === "accepted") {
        return (
            <Tooltip title="Approved" arrow>
                <IconButton size="small">
                    <CheckIcon style={{ fontSize: "1.4rem", color: "green" }} />
                </IconButton>
            </Tooltip>
        );
    }
    if (item.is_admin_request === "pending") {
        return (
            <Tooltip title="Pending" arrow>
                <IconButton size="small">
                    <AccessTimeIcon style={{ fontSize: "1.4rem", color: "maroon" }} />
                </IconButton>
            </Tooltip>
        );
    }
    if (item.is_admin_request === "rejected") {
        return (
            <Tooltip title="Rejected" arrow>
                <IconButton size="small">
                    <ClearIcon style={{ fontSize: "1.4rem", color: "red" }} />
                </IconButton>
            </Tooltip>
        );
    }
}

const AdminView4MTable = () => {
    const [requestData, setRequestData] = useState([]);
    const [render, setRender] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const { state } = useAuth();
    //dialog for update
    const [DialogUpdate, confirmUpdate] = useConfirm(
        "UPDATE 4M TABLE",
        "Are you sure you want to update this table?"
    );

    useEffect(() => {
        axios
            .get(`/api/get/ab/all/admin/request_form/${selectedDate}`)
            .then((res) => {
                let result = [...res.data];
                setRequestData([...result]);
            })
            .catch((err) => console.log(err));
    }, [selectedDate, render]);

    const updateStatus = async (formId, index) => {
        let isUpdate = await confirmUpdate();

        if (isUpdate) {
            axios
                .patch(`/api/ab/update/form/${formId}`, {
                    admin_request_no: requestData[index].admin_request_no,
                    request_approved_by: state.user.name,
                })
                .then((res) => {
                    if (res.data.status === "success") {
                        alert("Status Change successfully");
                    }
                    setRender((prev) => !prev);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const handleChangeRequestStatus = (e, index) => {
        const values = [...requestData];
        if (e.target.value === "2") {
            values[index].admin_request_no = e.target.value;
            values[index].is_admin_setup = "admin-confirmed";
        } else {
            values[index].admin_request_no = e.target.value;
        }
        setRequestData(values);
    };

    const deleteForm = (id) => {
        if (!window.confirm("Are you sure you want to delete this document?")) {
            return;
        }
        axios
            .delete(`/api/ab/remove/request_form/${id}`)
            .then(() => {
                setRender((prev) => !prev);
            })
            .catch((err) => {
                console.log(err);
            });
        // }
    };

    return (
        <>
            <main style={{ paddingBottom: "4rem" }}>
                <div className="divflex" style={{ fontSize: "24px" }}>
                    ABNORMALITY REQUEST FORM
                </div>
                <div className="subheading-container">
                    <p className="subheading-primary">
                        ACTIVE <span className="subheading-secondary">FORM</span>
                    </p>
                </div>
                <div style={{ marginTop: "2rem", marginLeft: "1rem" }}>
                    <DatePicker
                        views={["month", "year"]}
                        label="Month & Year"
                        className="date-field"
                        inputVariant="filled"
                        value={selectedDate}
                        onChange={setSelectedDate}
                    />
                </div>
                <Table size="small">
                    <TableHead>
                        <TableRow style={{ padding: "6px" }}>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                #
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Item Number
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Type
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Description
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Request Date
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Request Form
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="center">
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {requestData && (
                        <TableBody>
                            {requestData.map((item, index) => (
                                <React.Fragment key={item._id}>
                                    <TableRow>
                                        <TableCell align="center">{index + 1}</TableCell>
                                        <TableCell align="center">
                                            {item.part_id?.part_number}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            {item.change_item}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            {item.change_details}
                                        </TableCell>
                                        <TableCell align="center">
                                            {formatDate(item.request_date)}
                                        </TableCell>
                                        <TableCell align="center">
                                            {adminRequestForm(item)}
                                            <Tooltip title="View" arrow>
                                                <Link
                                                    to={`/abnormalityReportEditRequestForm/${item._id}?isView=true`}
                                                >
                                                    <IconButton
                                                        size="small"
                                                        style={{ marginLeft: "0.5rem" }}
                                                    >
                                                        <VisibilityIcon
                                                            style={{ fontSize: "1.4rem", color: "#003566" }}
                                                        />
                                                    </IconButton>
                                                </Link>
                                            </Tooltip>
                                            <Tooltip title="Edit" arrow>
                                                <Link
                                                    to={`/abnormalityReportEditRequestForm/${item._id}?isView=false`}
                                                >
                                                    <IconButton
                                                        size="small"
                                                        style={{ marginLeft: "0.5rem" }}
                                                    >
                                                        <Edit
                                                            style={{ fontSize: "1.4rem", color: "#003566" }}
                                                        />
                                                    </IconButton>
                                                </Link>
                                            </Tooltip>
                                            <select
                                                style={{
                                                    fontSize: "0.9rem",
                                                    outline: "none",
                                                    border: "none",
                                                    padding: "5px",
                                                    marginLeft: "0.5rem",
                                                    width: "150px",
                                                    textAlign: "center",
                                                    textTransform: "capitalize",
                                                    ...(item.is_admin_request === "pending" && {
                                                        backgroundColor: "#FDD835",
                                                    }),
                                                    ...(item.is_admin_request === "accepted" && {
                                                        backgroundColor: "green",
                                                        color: "white",
                                                    }),
                                                    ...(item.is_admin_request === "rejected" && {
                                                        backgroundColor: "red",
                                                        color: "white",
                                                    }),
                                                }}
                                                name="is_admin_request"
                                                value={item.admin_request_no}
                                                onChange={(e) => handleChangeRequestStatus(e, index)}
                                            >
                                                {item.is_admin_request === "pending" && (
                                                    <>
                                                        <option value="1">PENDING</option>
                                                        <option value="2">APPROVE</option>
                                                        <option value="3">REJECT</option>
                                                    </>
                                                )}
                                                {item.is_admin_request === "accepted" && (
                                                    <>
                                                        <option value="2">APPROVE</option>
                                                    </>
                                                )}
                                                {item.is_admin_request === "rejected" && (
                                                    <>
                                                        <option value="2">APPROVE</option>
                                                        <option value="3">REJECT</option>
                                                    </>
                                                )}
                                            </select>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Wrapper style={{ gap: "0.6rem" }} justify="center">
                                                <Tooltip title="Delete" arrow>
                                                    <IconButton
                                                        size="small"
                                                        style={{ marginLeft: "0.5rem" }}
                                                        onClick={() => deleteForm(item._id)}
                                                    >
                                                        <Delete
                                                            style={{ fontSize: "1.4rem", color: "red" }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    size="small"
                                                    onClick={() => updateStatus(item._id, index)}
                                                >
                                                    UPDATE
                                                </Button>
                                            </Wrapper>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    )}
                </Table>
            </main>
            <DialogUpdate isSubmitContent={true} />
        </>
    );
};

export default AdminView4MTable;
