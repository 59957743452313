import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import formatDate from "../../components/Control/formatDate";
import { DatePicker } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import { useQuery } from "../../components/Control/useQuery";
import ItemsDetailsHeader from "../../GlobalUtils/ItemsDetailsHeader";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const MSATable = () => {
  const classes = useStyles();
  const query = useQuery();
  const instrumentName = query.get("instrumentName");
  const idNo = query.get("idNo");
  const lc = query.get("lc");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [MSAList, setMSAList] = useState([]);
  const [render, setRender] = useState(false);
  const { instrumentId } = useParams();
  const deleteMSA = (id, part_no, date_performance) => {
    let confirm = window.confirm(
      `Are you sure you want to delete MSA of Part ${part_no} performed on ${formatDate(
        date_performance
      )}?`
    );
    if (confirm) {
      axios.delete(`/api/deleteMsa/data/${id}`).then((res) => {
        setRender((prev) => !prev);
      });
    }
  };

  useEffect(() => {
    axios
      .get(`/api/get/msalist/${instrumentId}/${selectedDate}`)
      .then((res) => setMSAList(res.data));
  }, [selectedDate, instrumentId, render]);

  return (
    <>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        MEASUREMENT SYSTEMS ANALYSIS (MSA) REPORT
      </div>

      <div className="subheading-container">
        <p className="subheading-primary">
          ACTIVE<span className="subheading-secondary"> REPORTS </span>
        </p>
      </div>
      <div>
        <div style={{ display: "flex", margin: "1rem" }}>
          <DatePicker
            views={["month", "year"]}
            label="Month & Year"
            className="date-field"
            inputVariant="filled"
            value={selectedDate || null}
            onChange={setSelectedDate}
          />
        </div>
        <div
          style={{
            position: "absolute",
            top: "4.6rem",
            right: "1.8rem",
          }}
        >
          <Link
            to={`/create/msa/${instrumentId}?instrumentName=${instrumentName}&idNo=${idNo}&lc=${lc}`}
            style={{ color: "white", textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              style={{
                marginBottom: "2rem",
                backgroundColor: "#054a29",
              }}
              startIcon={<AddCircleIcon />}
            >
              New Report
            </Button>
          </Link>
        </div>
        <TableContainer component={Paper} className={classes.container}>
          <Table
            stickyHeader
            aria-label="collapsible table"
            style={{ marginBottom: "5rem" }}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  #
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Part Number
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Part Name
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Instrument Name
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Status
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Date of Performance
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Created At
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {MSAList.map((MSA, index) => (
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {MSA.part_id?.part_number}
                  </TableCell>
                  <TableCell align="center">{MSA.part_id?.part_name}</TableCell>
                  <TableCell align="center">
                    {MSA.instrument_id?.instrument_type}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: MSA.isSubmitted ? "green" : "yellow",
                      color: MSA.isSubmitted ? "white" : "black",
                      width: "250px",
                    }}
                  >
                    {MSA.isSubmitted ? "SUBMITTED" : "DRAFT"}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(MSA.date_performance)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(MSA.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title="View MSA" arrow>
                      <IconButton
                        size="small"
                        target="_blank"
                        style={{
                          marginRight: ".5rem",
                        }}
                        onClick={() => {
                          axios
                            .get(`/api/view/msa/${MSA._id}`)
                            .then((response) => {
                              if (response.status === 200)
                                window.open(
                                  "https://docs.google.com/spreadsheets/d/1rFljqRf1dNcQkG0QZUFibX7jEos2jVjYrtEtrveD0xU/edit#gid=1844475717",
                                  "_blank"
                                );
                            });
                        }}
                      >
                        <VisibilityIcon
                          style={{
                            fontSize: "1.4rem",
                            color: "#003566",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit MSA" arrow>
                      <Link
                        to={`/edit/msa/${MSA._id}?instrumentName=${instrumentName}&idNo=${idNo}&lc=${lc}`}
                      >
                        <IconButton
                          size="small"
                          style={{
                            marginRight: ".5rem",
                          }}
                        >
                          <EditIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "#003566",
                            }}
                          />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Delete MSA" arrow>
                      <IconButton
                        size="small"
                        onClick={() =>
                          deleteMSA(MSA._id, MSA.part_no, MSA.date_performance)
                        }
                      >
                        <DeleteIcon
                          style={{
                            fontSize: "1.4rem",
                            color: "#c80202",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default MSATable;
