import React from "react";
import { Link } from "react-router-dom";
import clausePage from "./ClausePage.module.css";
//icons
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import { FeaturedPlayList } from "@material-ui/icons";
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle";
import ListIcon from "@material-ui/icons/List";
// end icons

const Clause9 = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        5S MANAGEMENT
      </div>
      <main className={`${clausePage.container} ${clausePage.main}`}>
        <div>
          <Link to="/masterProcedureList?category=clause-9" className="panel">
            <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Master <span className="card-btn-span">Procedure</span>
          </p>
        </div>
        <div>
          <Link to="/goodAreaList" className="panel">
            <SupervisedUserCircleIcon
              style={{ fontSize: "3rem", color: "#003566" }}
            />
          </Link>
          <p className="card-btn-para">
            Good<span className="card-btn-span">Area</span>
          </p>
        </div>
        <div>
          <Link to="/badAreaList" className="panel">
            <FeaturedPlayList style={{ fontSize: "3rem", color: "#003566" }} />
          </Link>
          <p className="card-btn-para">
            Bad <span className="card-btn-span">Area</span>
          </p>
        </div>
      </main>
    </>
  );
};

export default Clause9;
