import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Visibility from "@material-ui/icons/Visibility";
import axios from "axios";
import React, { useState, useEffect } from "react";

const ViewFile = ({ id, fileType, setIsOpenFiles }) => {
  const [files, setFiles] = useState([]);
  useEffect(() => {
    axios
      .get(`/api/get_instrumentById/${id}`)
      .then((res) => {
        if (res.data?.[fileType]) setFiles(res.data[fileType]);
      })
      .catch((err) => console.log(err));
  }, []);
  const deleteFile = async (index) => {
    if (!window.confirm("Are you sure you want to delete this file?")) {
      return;
    }
    axios
      .delete(`/api/instrumentDeleteFile/${id}/${fileType}/${index}`)
      .then((res) => {
        if (res.data) {
          alert("document deleted successfully");
          setIsOpenFiles(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <Table>
        <TableHead>
          <TableCell align="center">#</TableCell>
          <TableCell align="center">NAME</TableCell>
          <TableCell align="center">ACTION</TableCell>
        </TableHead>
        <TableBody>
          {files.map((file, index) => (
            <React.Fragment key={`${index}_files`}>
              <TableRow>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell align="center">{file.name}</TableCell>
                <TableCell align="center">
                  <Tooltip title="View File" arrow>
                    <a target={"_blank"} href={file.url}>
                      <IconButton
                        size="small"
                        style={{
                          marginRight: "0.8rem",
                          color: "#003566",
                        }}
                      >
                        <Visibility
                          style={{
                            fontSize: "1.4rem",
                          }}
                        />
                      </IconButton>
                    </a>
                  </Tooltip>

                  <Tooltip title="Delete File" arrow>
                    <IconButton
                      size="small"
                      style={{
                        marginRight: "0.8rem",
                      }}
                      onClick={() => deleteFile(index)}
                    >
                      <Delete
                        style={{
                          fontSize: "1.4rem",
                          color: "#c80202",
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default ViewFile;
