import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context/GlobalContext";
import Button from "@material-ui/core/Button";
import Form from "react-bootstrap/Form";
import "../../style/Login.css";
import logo from "../../images/DP Mushin Labs.png";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { Wrapper } from "../Control/Flexbox";
import { images, textInputs } from "../../constants/constants";

function Login(props) {
  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();
  const [error, setError] = useState(null);
  const { state, login } = useAuth();

  console.log();
  function validateForm() {
    return username.length > 0 && password.length > 0;
  }
  const handleLogin = (event) => {
    event.preventDefault();
    if (validateForm()) {
      axios
        .post("/api/user/login", { username: username, password: password })
        .then((res) => {
          login();
        })
        .catch((error) => {
          setError("Invalid Credentials");
        });
    }
  };

  return (
    <>
      <div className="login-container" style={{}}>
        <div className="login" style={{ flex: "0.6" }}>
          <div>
            <h1 className="login-container-heading">Welcome to Mushin™ AQUA</h1>
            <div className="content">
              <Form onSubmit={handleLogin}>
                <Form.Group size="lg" controlId="username">
                  <Form.Label>Email/Username</Form.Label>
                  <Form.Control
                    autoFocus
                    type="text"
                    value={username}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                {error && (
                  <>
                    <small
                      style={{
                        color: "red",
                        textAlign: "center",
                        display: "block",
                        fontSize: "1rem",
                        marginBottom: "0.8rem",
                      }}
                    >
                      {error}
                    </small>
                  </>
                )}{" "}
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#c80202",
                    color: "white",
                    width: "100%",
                    height: "50px",
                  }}
                  type="submit"
                  disabled={!validateForm()}
                  value={state.loading ? "Loading..." : "Login"}
                  startIcon={<LockOpenIcon />}
                >
                  Login
                </Button>
              </Form>
            </div>
          </div>
        </div>
        <Wrapper
          column
          style={{
            backgroundColor: "white",
            flex: "0.4",
            display: "flex",
          }}
        >
          <img
            src={images.arcLogo}
            alt={textInputs.companyName}
            height={100}
            width={200}
          />
          <img width="300px" height="300px" src={logo} alt="logo" />
        </Wrapper>
      </div>
    </>
  );
}

export default Login;
