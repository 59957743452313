import React from "react";
import { Wrapper } from "../Control/Flexbox";
import { DatePicker } from "@material-ui/pickers";
import Input from "../Control/Input";

const SelectDate = ({ from, setFrom, to, setTo }) => {
  return (
    <Wrapper style={{ gap: "1rem" }}>
      <div>
        <DatePicker
          value={from || null}
          label="From"
          className="date-field"
          inputVariant="filled"
          format="dd/MM/yyyy"
          onChange={setFrom}
        />
      </div>
      <div>
        <DatePicker
          value={to || null}
          label="To"
          className="date-field"
          inputVariant="filled"
          format="dd/MM/yyyy"
          onChange={setTo}
        />
      </div>
    </Wrapper>
  );
};

export default SelectDate;
