import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import formatDate from "../../../components/Control/formatDate";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import TableReadEditCell from "../../../components/TableEditCell";
import { useQuery } from "../../../components/Control/useQuery";
import { Save } from "@material-ui/icons";
import { useAuth } from "../../../context/GlobalContext";
import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

const Table = Styled.table`
	margin-bottom: 1rem;
  width:98%;
  margin:auto;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        font-weight:bold;
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    .input {
        outline: none;
        width: 100px;
        border: none;
        height: 50px;
        text-align:center;
    }
    .middleText  > th {
      text-align: center;

    }
    .tablebody-row td{
      text-align:center;
    }
`;

const requestForm = [
    { change_item: "man", change_details: "Change of Operator" },
    { change_item: "man", change_details: "Skill level change" },
    { change_item: "man", change_details: "New joinee" },
    { change_item: "machine", change_details: "Different Spec / grade" },
    { change_item: "machine", change_details: "Specified RM Source Change" },
    { change_item: "method", change_details: "Process sequences change" },
    { change_item: "method", change_details: "Additional process added" },
    { change_item: "method", change_details: "Packaging Method Change" },
    { change_item: "method", change_details: "Inspection method change" },
    { change_item: "method", change_details: "M/C parameter change" },
    { change_item: "method", change_details: "Inspection equipment change" },
    { change_item: "material", change_details: "Tool/Die Design change" },
    { change_item: "material", change_details: "Machine change" },
    { change_item: "material", change_details: "Jig/Fixture Design change" },
    {
        change_item: "material",
        change_details: "Removal / Additions of pokayoke",
    },
];

function ChangeItem({ index, item }) {
    let rowSpan = 1;
    if (index === 0) rowSpan = 3;
    if (index === 3) rowSpan = 2;
    if (index === 5) rowSpan = 6;
    if (index === 11) rowSpan = 4;
    if (rowSpan > 1) {
        return (
            <td style={{ textTransform: "uppercase" }} rowSpan={rowSpan}>
                {item.change_item}
            </td>
        );
    }
    return null;
}

function EditRequestForm() {
    const { id } = useParams();
    const [data, setData] = useState({ requestForms: [] });
    const query = useQuery();
    const history = useHistory();
    const { state } = useAuth();
    const [machineData, setMachineData] = useState([]);
    const [autoCompleteValue, setAutoCompleteValue] = useState({});
    const [processList, setProcessList] = useState([]);
    const isView = query.get("isView") === "true" ? true : false;
    useEffect(() => {
        async function fetchPir() {
            try {
                const res = await axios
                    .get(`/api/get/ab/request_form/${id}`)
                    .catch((err) => console.log(err));

                setData(res.data);
                setAutoCompleteValue({
                    machine_name: res.data?.machine_id?.machine_name,
                    id: res.data?.machine_id?._id,
                });
                if (res.data?.part_id) {
                    const processRes = await axios
                        .get(`/api/getAllProcess/${res.data.part_id._id}`)
                        .catch((err) => console.log(err));
                    console.log(processRes);
                    setProcessList(processRes.data);
                }
            } catch (error) {
                console.log(error);
            }
        }
        fetchPir();
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        axios
            .get(`/api/getAllMachine`)
            .then((res) => {
                const data = [];
                res.data?.forEach((machine) => {
                    data.push({
                        id: machine._id,
                        machine_name: machine.machine_name,
                    });
                    setMachineData(data);
                });
            })
            .catch((err) => console.log(err));
    }, []);

    const handleChange = (e, index) => {
        const value =
            e.target.type === "checkbox" ? e.target.checked : e.target.value;
        const name = e.target.name;
        if (index === 0 || index) {
            const values = { ...data };
            values.requestForms[index][name] = value;
            setData(values);
        }
        setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    };

    const onSubmit = (isSubmitted) => {
        const isSubmit = window.confirm(
            "Are you sure you want to submit this Request Form?"
        );
        if (!isSubmit) {
            return;
        }
        data.is_request_submitted = isSubmitted;
        if (isSubmitted) {
            data.is_admin_request = "pending";
            data.admin_request_no = 1;
        }
        if (state.user.userType == 3) {
            data.request_prepared_by = state.user.name;
        }
        axios
            .put(`/api/ab/edit/request_form/${id}`, data)
            .then((res) => {
                if (res.data.status === "success") {
                    history.push("/abnormalityRequestForm");
                }
            })
            .catch((err) => console.log(err));
    };
    return (
        <div style={{ paddingBottom: "4rem" }}>
            <div className="divflex">
                <Typography
                    style={{
                        fontSize: "24px",
                        fontFamily: "Nunito",
                    }}
                >
                    ABNORMALITY REQUEST FORM
                </Typography>
                <Wrapper>
                    <Button
                        type="button"
                        variant="outlined"
                        color="white"
                        size="large"
                        onClick={() => {
                            onSubmit(false);
                        }}
                        style={{
                            color: "#e9e9e9",
                            backgroundColor: "#003566",
                            marginLeft: "0.7rem",
                        }}
                        startIcon={<Save />}
                    >
                        Save & Continue
                    </Button>
                    <Button
                        type="button"
                        variant="outlined"
                        color="white"
                        size="large"
                        onClick={() => {
                            onSubmit(true);
                        }}
                        style={{
                            color: "#e9e9e9",
                            backgroundColor: "#064a29",
                            marginLeft: "0.7rem",
                        }}
                        startIcon={<Save />}
                    >
                        Submit
                    </Button>
                </Wrapper>
            </div>
            <Table cellSpacing="0" style={{ marginTop: "0.6rem" }} id="table-to-xls">
                <tr>
                    <th
                        colSpan={3}
                        rowSpan="2"
                        align="left"
                        style={{
                            border: "1px solid black",
                            fontSize: "1.6rem",
                            textAlign: "center",
                            height: "100px",
                        }}
                    >
                        <CenteredLogoImg>
                            <img src={images.arcLogo} alt={textInputs.companyName} />
                            <span>{textInputs.companyName}</span>
                        </CenteredLogoImg>
                    </th>
                    <th align="left" style={{ border: "1px solid black" }}>
                        ARC/CM/07/00
                    </th>
                </tr>
                <tr>
                    <th align="left" style={{ border: "1px solid black" }}>
                        Rev.Date:01/06/21
                    </th>
                </tr>
                <tr>
                    <th
                        colSpan={4}
                        align="center"
                        style={{
                            border: "1px solid black",
                            fontSize: "1.2rem",
                            fontWeight: 600,
                            backgroundColor: "#1d1d1d",
                            color: "white",
                            textTransform: "uppercase",
                            textAlign: "center",
                        }}
                    >
                        ABNORMALITY REQUEST FORM
                    </th>
                </tr>
                <tr>
                    <th colSpan={2} align="left" style={{ border: "1px solid black" }}>
                        REQUEST DEPTT./NAME:- {data.request_deptt}
                    </th>
                    <th align="left" style={{ border: "1px solid black" }}>
                        DATE:- {formatDate(data.request_date)}
                    </th>
                    <th align="left" style={{ border: "1px solid black" }}>
                        SHIFT:-
                        <TableReadEditCell
                            name="shift"
                            type="text"
                            key="shift"
                            style={{ width: "100%" }}
                            value={data.shift}
                            {...{ handleChange, isView }}
                        />
                    </th>
                </tr>
                <tr>
                    <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
                        PART NAME:- {data.part_id?.part_name}
                    </th>
                    <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
                        PART NO:- {data.part_id?.part_number}
                    </th>
                </tr>
                <tr>
                    <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
                        DRAWING NO:-{" "}
                        <TableReadEditCell
                            name="drawing_no"
                            type="text"
                            key="drawing_no"
                            style={{ width: "100%" }}
                            value={data.drawing_no}
                            {...{ handleChange, isView }}
                        />
                    </th>
                    <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
                        PROCESS:-{" "}
                        {isView ? (
                            data.process
                        ) : (
                            <FormControl
                                variant="filled"
                                className="select-field"
                                style={{ width: "100%" }}
                            >
                                <InputLabel>Select Process</InputLabel>
                                <Select
                                    key="process_name"
                                    onChange={(e) => {
                                        const values = { ...data };
                                        values.process = e.target.value;
                                        setData(values);
                                    }}
                                    value={data.process || ""}
                                >
                                    {processList.map((item) => (
                                        <MenuItem value={item.process_name}>
                                            {item.process_name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    </th>
                </tr>
                <tr>
                    <th align="left" colSpan={2} style={{ border: "1px solid black" }}>
                        MACHINE NAME:-{" "}
                        {isView ? (
                            data.machine_id?.machine_name
                        ) : (
                            <Autocomplete
                                options={machineData || []}
                                getOptionLabel={(option) => option.machine_name}
                                value={autoCompleteValue}
                                style={{
                                    paddingLeft: "0.5rem",
                                    width: "100%",
                                    marginRight: "6rem",
                                }}
                                onChange={(e, value) => {
                                    data.machine_id = value?.id;
                                    if (!value?.id) data.machine_id = null;
                                    setAutoCompleteValue(value);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        )}
                    </th>
                    <th
                        align="left"
                        colSpan={2}
                        style={{ border: "1px solid black" }}
                    ></th>
                </tr>
                <tr>
                    <th colSpan={5}>
                        CHANGE DETAILS:
                        <TableReadEditCell
                            name="change_details"
                            type="text"
                            key="change_details"
                            style={{ width: "100%" }}
                            value={data.change_details}
                            {...{ handleChange, isView }}
                        />{" "}
                    </th>
                </tr>
                <tbody className="tablebody-row">
                    <tr>
                        <th
                            style={{
                                border: "1px solid black",
                                textAlign: "center",
                            }}
                            align="center"
                        >
                            CHANGE ITEM
                        </th>
                        <th
                            style={{
                                border: "1px solid black",
                                textAlign: "center",
                            }}
                            align="center"
                        >
                            CHANGE DETAILS
                        </th>
                        <th
                            style={{
                                border: "1px solid black",
                                textAlign: "center",
                            }}
                            align="center"
                        >
                            CHANGE REQUIRED
                        </th>
                        <th
                            style={{
                                border: "1px solid black",
                                textAlign: "center",
                            }}
                            align="center"
                        >
                            REASON OF CHANGE
                        </th>
                    </tr>
                    {requestForm.map((item, index) => (
                        <tr key={item.change_details}>
                            <ChangeItem {...{ index, item }} />
                            <td style={{ textTransform: "capitalize" }}>
                                {item.change_details}
                            </td>
                            <td style={{ fontSize: "1.3rem" }}>
                                {isView ? (
                                    item.change_details === data.reason_of_change && "✓"
                                ) : (
                                    <Checkbox
                                        name="change_required"
                                        checked={item.change_details === data.reason_of_change}
                                        onChange={(e) => {
                                            let values = { ...data };
                                            values.reason_of_change = item.change_details;
                                            values.change_item = item.change_item;
                                            setData(values);
                                        }}
                                    />
                                )}
                            </td>
                            <td>
                                {item.change_details === data.reason_of_change &&
                                    (isView ? (
                                        data.reason_of_change_remark
                                    ) : (
                                        <TableReadEditCell
                                            name="reason_of_change_remark"
                                            type="text"
                                            key="reason_of_change_remark"
                                            style={{ width: "100%" }}
                                            value={data.reason_of_change_remark}
                                            {...{ handleChange, isView }}
                                        />
                                    ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tr>
                    <td
                        style={{
                            border: "1px solid black",
                        }}
                        colSpan="2"
                        align="left"
                    >
                        CHANGE REQUESTED BY:{" "}
                        <TableReadEditCell
                            name="change_requested_by"
                            type="text"
                            key="change_requested_by"
                            style={{ width: "100%" }}
                            value={data.change_requested_by}
                            {...{ handleChange, isView }}
                        />
                    </td>
                    <td
                        style={{
                            border: "1px solid black",
                        }}
                        colSpan="2"
                        align="left"
                    >
                        REQUEST DATE: {formatDate(data.request_date)}
                    </td>
                </tr>
                <tr>
                    <td
                        style={{
                            border: "1px solid black",
                        }}
                        colSpan="2"
                        align="left"
                    >
                        CHANGE INPUT:{" "}
                        <TableReadEditCell
                            name="change_input"
                            type="text"
                            key="change_input"
                            style={{ width: "100%" }}
                            value={data.change_input}
                            {...{ handleChange, isView }}
                        />
                    </td>
                    <td
                        style={{
                            border: "1px solid black",
                        }}
                        colSpan="2"
                        align="left"
                    >
                        CHANGE OUTPUT:{" "}
                        <TableReadEditCell
                            name="change_output"
                            style={{ width: "100%" }}
                            type="text"
                            key="change_output"
                            value={data.change_output}
                            {...{ handleChange, isView }}
                        />
                    </td>
                </tr>

                <tr>
                    <td
                        style={{
                            border: "1px solid black",
                            position: "relative",
                            height: "60px",
                        }}
                        colSpan="5"
                        align="left"
                    >
                        Change Request Approved ( YES / NO ):{" "}
                        {data.is_admin_request === "accepted" && (
                            <>
                                <span
                                    style={{
                                        fontSize: "2rem",
                                        position: "absolute",
                                        bottom: "0.3rem",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    ☑
                                </span>
                                <span style={{ marginLeft: "3.5rem" }}>YES</span>{" "}
                                <span
                                    style={{
                                        fontSize: "2rem",
                                        position: "absolute",
                                        bottom: "0.3rem",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    □
                                </span>{" "}
                                <span style={{ marginLeft: "3.5rem" }}>NO</span>
                            </>
                        )}
                        {data.is_admin_request === "rejected" && (
                            <>
                                <span
                                    style={{
                                        fontSize: "2rem",
                                        position: "absolute",
                                        bottom: "0.3rem",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    □
                                </span>{" "}
                                <span style={{ marginLeft: "3.5rem" }}>YES</span>{" "}
                                <span
                                    style={{
                                        fontSize: "2rem",
                                        position: "absolute",
                                        bottom: "0.3rem",
                                        marginLeft: "1rem",
                                    }}
                                >
                                    ☑
                                </span>
                                <span style={{ marginLeft: "3.5rem" }}>NO</span>
                            </>
                        )}
                    </td>
                </tr>
                <tr style={{ height: "60px" }}>
                    <td align="left" colSpan={1}>
                        Prepared by:
                    </td>
                    <td align="left" colSpan={1} style={{ textTransform: "capitalize" }}>
                        {data.request_prepared_by}
                    </td>
                    <td align="left" colSpan={1}>
                        Approved By:
                    </td>
                    <td align="left" style={{ textTransform: "capitalize" }} colSpan={1}>
                        {data.is_admin_request === "accepted" && data.request_approved_by}
                    </td>
                </tr>
            </Table>
        </div>
    );
}

export default EditRequestForm;
