import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
  },
}));

const Loader = (props) => {
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={{
        height: `${props?.height ? props?.height : "90vh"}`,
      }}
    >
      <CircularProgress
        style={{ color: "teal" }}
        disableShrink
        size="5rem"
        thickness="2"
      />
    </div>
  );
};

export default Loader;
export { Loader };
