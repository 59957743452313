import Styled from "styled-components";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import { Add, Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../../components/Control/useDebounce";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import formatDate from "../../../components/Control/formatDate";
import Button from "@material-ui/core/Button";
import NoteModal from "./NoteModal";
import EditIcon from "@material-ui/icons/Edit";
import { Lock, LockOpen } from "@material-ui/icons";
import { useAuth } from "../../../context/GlobalContext";
import useConfirm from "../../../components/Control/useConfirm.jsx";
import AlertDialog from "../../../components/Control/AlertDialog";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});

const Div = Styled.div`
    margin-top: 1rem;
    h3{
        margin-left:2.5rem;
    }
`;

function ViewNoteTable() {
  const [openNoteModal, setOpenNoteModal] = useState(false);
  const [isView, setIsView] = useState(false);
  const classes = useStyles();
  const [noteData, setNoteData] = useState([]);
  const [filter, setFilter] = useState("note_name");
  const [page, setPage] = useState(1);
  const [render, setRender] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [perPage, setPerPage] = useState();
  const { id } = useParams();
  const [idForModal, setIdForModal] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const { state } = useAuth();
  const userId = state.user._id;
  const username = state.user.username;

  const [DialogUnlock, confirmUnlock] = useConfirm(
    "UNLOCK NOTES",
    "Are you sure you want to unlock this notes?"
  );
  const [DialogLock, confirmLock] = useConfirm(
    "LOCK NOTES",
    "Are you sure you want to lock this notes?"
  );
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this Document?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [confirmOpen, setConfirmOpen] = useState(false);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/get/notes?notebook_id=${id}&page=${page}&sortBy=${filter}&search=${search}&userId=${userId}`
      )
      .catch((err) => {
        console.log(err);
      });
    setNoteData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };
  useDebounce(fetchData, 1000, [page, search, render, filter]);

  const deleteNote = async (noteName, noteId) => {
    const confirm = await confirmDelete();
    if (confirm) {
      const res = await axios
        //TODO create endpoint for deleting
        .delete(
          `/api/delete/note?notebook_id=${id}&noteId=${noteId}&userId=${userId}`
        )
        .catch((err) => console.log(err));

      if (res.data?.msg === "you have not permission") {
        setConfirmOpen(true);
        return;
      }
      if (res.data?.status === "success") {
        setRender((prev) => !prev);
      }
    }
  };

  async function submitNote(note) {
    let ans = await confirmSave();
    if (ans) {
      if (!isEdit) {
        note.userId = userId;
        note.username = username;
        axios
          .post(`/api/post/note?notebook_id=${id}`, note)
          .then(() => {
            console.log("Successfully created note");
            setRender((prev) => !prev);
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .put(`/api/put/note?id=${idForModal}&notebook_id=${id}`, note)
          .then(() => {
            console.log("Successfully edited note");
            setIdForModal(null);
            setIsEdit(false);
            setRender((prev) => !prev);
          })
          .catch((err) => console.log(err));
      }
    }

    setOpenNoteModal(false);
  }

  //lock unlock for specified user

  const lockNote = async (id) => {
    const ans = await confirmLock();

    if (ans) {
      axios
        .patch(`/api/patch/note?id=${id}`, {
          isPrivate: true,
        })
        .catch((err) => console.log(err));
      setRender((prev) => !prev);
    }
  };
  const unlockNote = async (id) => {
    const ans = await confirmUnlock();
    if (ans) {
      axios
        .patch(`/api/patch/note?id=${id}`, {
          isPrivate: false,
        })
        .catch((err) => console.log(err));
      setRender((prev) => !prev);
    }
  };

  return (
    <>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        NOTES LIST
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          style={{
            display: "inline-flex",
            marginBottom: "0rem",
            backgroundColor: "#054a29",
          }}
          startIcon={<Add />}
          onClick={() => {
            setOpenNoteModal(true);
            setIdForModal(null);
            setIsView(false);
            setIsEdit(false);
          }}
        >
          New Note
        </Button>
      </div>
      <NoteModal
        openModal={openNoteModal}
        closeModal={() => setOpenNoteModal(false)}
        passNote={submitNote}
        id={idForModal}
        isView={isView}
      />
      <TableContainer component={Paper}>
        <Wrapper>
          <div style={{ flex: "0.8" }}>
            <Input
              placeholder="Search Note Name"
              variant="filled"
              style={{
                width: "96%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginRight: "1.5rem", marginTop: "0.8rem", flex: "0.2" }}
          >
            <InputLabel>Filter</InputLabel>
            <Select
              name="note_type"
              onChange={(e) => setFilter(e.target.value)}
              defaultValue={filter}
            >
              <MenuItem value="note_name">A - Z</MenuItem>
              <MenuItem value="createdAt">Most Recent First</MenuItem>
              <MenuItem value="createdAt_old">Oldest First</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                #
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Note ID
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Note Name
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Created By
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Created At
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Last Updated At
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {noteData && (
            <TableBody>
              {noteData.map((item, index) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item.note_number}</TableCell>
                    <TableCell align="center">{item.note_name}</TableCell>
                    <TableCell align="center">{item.username}</TableCell>
                    <TableCell align="center">
                      {formatDate(item.created_at)}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(item.last_updated_at)}
                    </TableCell>
                    <TableCell align="center">
                      {item.userId === userId ? (
                        item.isPrivate ? (
                          <Tooltip title="Lock" arrow>
                            <IconButton
                              size="small"
                              onClick={() => unlockNote(item._id)}
                              color="secondary"
                            >
                              <Lock
                                style={{
                                  fontSize: "1.4rem",
                                  marginRight: "0.5rem",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Unlock" arrow>
                            <IconButton
                              size="small"
                              color="primary"
                              onClick={() => lockNote(item._id)}
                            >
                              <LockOpen
                                style={{
                                  fontSize: "1.4rem",
                                  marginRight: "0.5rem",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )
                      ) : (
                        <div
                          style={{
                            marginRight: "0.5rem",
                            width: "1.8rem",
                            display: "inline-block",
                          }}
                        ></div>
                      )}
                      <Tooltip title="View" arrow>
                        <IconButton
                          size="small"
                          onClick={() => {
                            setIdForModal(item._id);
                            setIsView(true);
                            setIsEdit(false);
                            setOpenNoteModal(true);
                          }}
                        >
                          <VisibilityIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "#003566",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() => {
                            setIdForModal(item._id);
                            setIsEdit(true);
                            setIsView(false);
                            setOpenNoteModal(true);
                          }}
                        >
                          <EditIcon
                            style={{ fontSize: "1.4rem", color: "#003566" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() => deleteNote(item.note_name, item._id)}
                        >
                          <DeleteIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </Table>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "4rem",
          }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, number) => setPage(number)}
          />
        </div>
      </TableContainer>
      <DialogLock isSubmitContent={true} />
      <DialogUnlock isSubmitContent={false} />
      <AlertDialog
        title="Private Delete Notes"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        // onConfirm={(e) => handleSubmit(e)}
      >
        You are deleting private notes.You have not the permission to delete
        this notes.
      </AlertDialog>
      <DialogDelete isSubmitContent={false} />
      <DialogSave isSubmitContent={true} />
    </>
  );
}
export default ViewNoteTable;
