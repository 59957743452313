import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import formatDate from "../../Control/formatDate";
import { DatePicker } from "@material-ui/pickers";
import Paper from "@material-ui/core/Paper";
import { Add, Check } from "@material-ui/icons";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
    backgroundColor: "white",
  },
});

const MSATable = ({ ppapData }) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [MSAList, setMSAList] = useState([]);
  const [render, setRender] = useState(false);
  const { partName } = useParams();

  const deleteMSA = (id, part_no, date_performance) => {
    let confirm = window.confirm(
      `Are you sure you want to delete MSA of Part ${part_no} performed on ${formatDate(
        date_performance
      )}?`
    );
    if (confirm) {
      axios.delete(`/api/deleteMsa/data/${id}`).then((res) => {
        setRender((prev) => !prev);
      });
    }
  };

  useEffect(() => {
    axios
      .get(`/api/get/msalist/${partName}/${selectedDate}`)
      .then((res) => setMSAList(res.data));
  }, [selectedDate, partName, render]);

  return (
    <>
      <div>
        <div style={{ display: "flex", margin: "1rem" }}>
          <DatePicker
            views={["month", "year"]}
            label="Month & Year"
            className="date-field"
            inputVariant="filled"
            value={selectedDate || null}
            onChange={setSelectedDate}
          />
        </div>
        <TableContainer component={Paper} className={classes.container}>
          <Table
            stickyHeader
            aria-label="collapsible table"
            style={{ marginBottom: "5rem" }}
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  #
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Part Number
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Part Name
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Instrument Name
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Status
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Date of Performance
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Created At
                </TableCell>
                <TableCell align="center" style={{ fontWeight: "600" }}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {MSAList.map((MSA, index) => (
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{MSA.part_no}</TableCell>
                  <TableCell align="center">{MSA.part_name}</TableCell>
                  <TableCell align="center">{MSA.instrument_name}</TableCell>
                  <TableCell
                    align="center"
                    style={{
                      backgroundColor: MSA.isSubmitted ? "green" : "yellow",
                      color: MSA.isSubmitted ? "white" : "black",
                      width: "250px",
                    }}
                  >
                    {MSA.isSubmitted ? "SUBMITTED" : "DRAFT"}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(MSA.date_performance)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(MSA.created_at)}
                  </TableCell>
                  <TableCell align="center">
                    {ppapData.msa_id === MSA._id ? (
                      <Tooltip title="Status" arrow>
                        <IconButton size="small">
                          <Check
                            style={{ fontSize: "1.4rem", color: "green" }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add" arrow>
                        <IconButton
                          size="small"
                          onClick={() => {
                            ppapData.msa_id = MSA._id;
                            setRender((prev) => !prev);
                          }}
                        >
                          <Add
                            style={{ fontSize: "1.4rem", color: "maroon" }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default MSATable;
