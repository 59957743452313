import React from "react";
import TableToExcel from "@dayalk/table-to-excel";
import {
  ButtonStyled,
  TableContainerStyled,
  Table,
} from "../../../components/Control/TabelStyles";
import { mdiFileExcel } from "@mdi/js";
import PrintIcon from "@material-ui/icons/Print";

import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import { Wrapper } from "../../../components/Control/Flexbox";

const QuestionPaperHtmlTable = ({ questionPaperData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  return (
    <main
      style={{
        paddingBottom: "4rem",
      }}
    >
      <Wrapper
        style={{
          position: "absolute",
          top: "0.2rem",
          right: "1rem",
          zIndex: "100000",
        }}
      >
        <Tooltip arrow title="Print table">
          <PrintIcon
            style={{
              color: "white",
              cursor: "pointer",
              fontSize: "1.9rem",
              marginRight: "0.6rem",
            }}
            onClick={printTable}
          />
        </Tooltip>
        <Tooltip arrow title="Export as spreadsheet">
          <div>
            <Button
              style={{
                color: "white",
                width: "fit-content",
              }}
              onClick={() => {
                TableToExcel.convert(document.getElementById("table-to-xls"), {
                  name: `tests.xlsx`,
                  sheet: {
                    name: "pfd_diagram",
                  },
                });
              }}
            >
              <Icon path={mdiFileExcel} size={1.2} />
            </Button>
          </div>
        </Tooltip>
      </Wrapper>
      <TableContainerStyled
        style={{ marginTop: "0.6rem", backgroundColor: "white" }}
      >
        <Table
          id="table-to-xls"
          cellSpacing="0"
          style={{
            borderCollapse: "collapse",
          }}
          data-default-wrap="true"
          data-cols-width="16, 9, 30, 9, 30, 20, 30"
        >
          <thead>
            <tr data-height="100">
              <th
                colSpan={6}
                rowSpan="2"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  height: "100px",
                  color: "#343a40",
                }}
              >
                <CenteredLogoImg>
                  <img src={images.arcLogo} alt={textInputs.companyName} />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th align="left" style={{ border: "1px solid black" }}>
                Doc.No.:- ARC/F/HRD/04
              </th>
            </tr>
            <tr>
              <th align="left" style={{ border: "1px solid black" }}>
                Rev.No./Date:- 00/01-10-2017
              </th>
            </tr>
            <tr>
              <th
                align="left"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                Level: {questionPaperData?.levels_type.split("_").join(" ")}
              </th>
              <th
                align="left"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                Operator Name -
              </th>
            </tr>
            <tr>
              <th
                align="left"
                colSpan={3}
                style={{ border: "1px solid black" }}
              >
                Date -
              </th>
              <th
                align="left"
                colSpan={4}
                style={{ border: "1px solid black" }}
              >
                Examiner Name -
              </th>
            </tr>
          </thead>
          <thead>
            <tr>
              {["Q.No.", "Questions", "Total Marks", "Marks Obtained"].map(
                (item) => (
                  <th
                    align="center"
                    colSpan={item === "Questions" ? 4 : 1}
                    width={item === "Questions" && "200px"}
                    height="50px"
                    style={{ border: "1px solid black", textAlign: "center" }}
                  >
                    {item}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {questionPaperData &&
              questionPaperData.question.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{item.s_no}</td>
                    <td height="50px" colSpan={4}>
                      {item.question}
                    </td>
                    <td height="50px" rowSpan={3}></td>
                    <td height="50px" rowSpan={3}></td>
                  </tr>
                  <tr>
                    <td height="50px" rowSpan={2}></td>
                    <td height="50px" width="30px" align="center">
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        a
                      </span>
                    </td>
                    <td height="50px">{item.option_1}</td>
                    <td height="50px" width="30px" align="center">
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        b
                      </span>
                    </td>
                    <td height="50px">{item.option_2}</td>
                  </tr>
                  <tr>
                    <td height="50px" width="30px" align="center">
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        c
                      </span>
                    </td>
                    <td height="50px">{item.option_3}</td>
                    <td height="50px" width="30px" align="center">
                      <span
                        style={{
                          fontSize: "1rem",
                          fontWeight: "500",
                        }}
                      >
                        d
                      </span>
                    </td>
                    <td height="50px">{item.option_4}</td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
          <tbody></tbody>
        </Table>
      </TableContainerStyled>
    </main>
  );
};

export default QuestionPaperHtmlTable;
