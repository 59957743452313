import { useState } from "react";
import { Add, ExpandLess, ExpandMore, Remove } from "@material-ui/icons";
import { Wrapper } from "./Control/Flexbox";
import Input from "./Control/Input";
import { Typography } from "@material-ui/core";

const convArrayToString = (arr) => {
  return arr.map((item) => item.trim()).join(", ");
};
function MultipleInput({ data, title, listTitle }) {
  const [isDrop, setIsDrop] = useState(false);
  const [value, setValue] = useState("");
  const [render, setRender] = useState(false);
  function append(item) {
    data.push(item);
    setIsDrop(true);
    setRender((prev) => !prev);
  }
  function remove(index) {
    data.splice(index, 1);
    setRender((prev) => !prev);
  }
  return (
    <div
      className="m-section-main"
      style={{
        position: "relative",
        fontFamily: "roboto",
        color: "gray",
      }}
    >
      <div>
        <Wrapper style={{ border: "1px solid #f0f2f0" }}>
          <Input
            type="text"
            name="name"
            key={value ? "notLoadedYet" : "loaded"}
            placeholder={title}
            onBlur={(e) => setValue(e.target.value)}
            defaultValue={value}
          />
          <Wrapper
            style={{
              width: "55px",
              height: "55px",
              backgroundColor: "#c80202",
              color: "white",
              cursor: "pointer",
            }}
            justify="center"
            onClick={() => {
              append(value);
              setValue("");
            }}
          >
            <Add />
          </Wrapper>
        </Wrapper>
        <Wrapper
          style={{ backgroundColor: "#fafafa", height: "55px" }}
          justify="space-between"
        >
          <p
            style={{
              marginBottom: 0,
              marginLeft: "1.3rem",
              fontSize: "0.9rem",
              color: "gray",
            }}
          >
            {data && data.length
              ? convArrayToString(data).length > 20
                ? `${convArrayToString(data).substr(0, 20)} ...`
                : convArrayToString(data)
              : listTitle}
          </p>
          <Wrapper justify="center">
            {isDrop ? (
              <Wrapper
                style={{
                  height: "55px",
                  width: "55px",
                  backgroundColor: "#3f51b5",
                  cursor: "pointer",
                  color: "white",
                }}
                justify="center"
                onClick={() => setIsDrop(false)}
              >
                <ExpandLess />
              </Wrapper>
            ) : (
              <Wrapper
                style={{
                  height: "55px",
                  width: "55px",
                  backgroundColor: "#3f51b5",
                  cursor: "pointer",
                  color: "white",
                }}
                justify="center"
                onClick={() => setIsDrop(true)}
              >
                <ExpandMore />
              </Wrapper>
            )}
          </Wrapper>
        </Wrapper>
        <div
          style={{
            position: "absolute",
            maxHeight: "180px",
            width: "330px",
            overflowX: "scroll",

            zIndex: 1000,
            backgroundColor: "white",
            border: "1px solid #f0f2f0",
          }}
        >
          {isDrop &&
            data?.map((item, index) => (
              <Wrapper
                key={index}
                justify="space-between"
                style={{
                  border: "1px solid #f0f2f0",
                  padding: "0.4rem",
                  width: "100%",
                }}
              >
                <span>{item}</span>
                <Remove
                  onClick={() => remove(index)}
                  style={{ color: "red", cursor: "pointer" }}
                />
              </Wrapper>
            ))}
        </div>
      </div>
    </div>
  );
}

export default MultipleInput;
