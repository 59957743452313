import { Checkbox, MenuItem } from "@mui/material";
import { useState } from "react";
import TableInput from "../../../../../common/TableInput";
import TableCompHead from "../../../../../common/TableCompHead";
import formatDate from "../../../../../components/Control/formatDate";
import { useQuery } from "../../../../../components/Control/useQuery";

const tableHead = {
  setup: ["FRM-CMT-04", "00", "01-04-2023"],
  retro: ["", "00", "01-04-2023"],
  suspected: ["", "00", "01-04-2023"],
};

const heading = {
  setup: "SETTING APPROVAL RECORDS",
  retro: "RETROACTIVE CHECK INSPECTION RECORDS",
  suspected: "SUSPECTED CHECK RECORDS",
};
const THead = ({ /*getProcess,*/ moduleData, isView, operation }) => {
  const [render, setRender] = useState(false);
  const query = useQuery();
  const operation_name = query.get("operation_name");
  const part_name = query.get("part_name");
  const part_number = query.get("part_number");
  const issue_date = query.get("issue_date");
  const nature_of_change = query.get("nature_of_change");
  const change_type = query.get("change_type");
  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={3}
        labels={["Format No.: ", "Rev. No.: ", "Eff. Date: "]}
        values={["FRM-CMT-03", "00", "01-04-2023"]}
        heading={"4M Change Hold Card"}
      />
      <tr>
        <th colSpan={3} align="left">
          PART NAME :
          {/* {moduleData.part_name || moduleData.part_id?.part_name} */}
          {part_name}
        </th>
        <th colSpan={3} align="left">
          PART NO. :{" "}
          {/* {moduleData?.part_number || moduleData.part_id?.part_number} */}
          {part_number}
        </th>
      </tr>
      <tr>
        <th colSpan={6} align="left">
          Stage / Operation Name : {operation_name}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Issue Date :{formatDate(issue_date)}
        </th>
        <th colSpan={3} align="left">
          Name :
          <TableInput
            isView={isView}
            name="name"
            type="text"
            value={moduleData?.name}
            onChange={(e) => {
              moduleData.name = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Tick For:</th>
        <th>Planned Change</th>
        <th>
          <Checkbox
            name="nature_of_change"
            checked={nature_of_change === "प्लांड"}
            style={{ color: "#064a29" }}
          />
        </th>
        <th>UnPlanned Change</th>
        <th>
          <Checkbox
            name="nature_of_change"
            checked={nature_of_change === "अनप्लांड"}
            style={{ color: "#064a29" }}
          />
        </th>
      </tr>
      <tr>
        <th rowSpan={2} colSpan={2}>
          Changing Point:{" "}
        </th>
        <th>Method</th>
        <th>
          <Checkbox
            name="change_type"
            checked={change_type === "method"}
            style={{ color: "#064a29" }}
          />
        </th>
        <th>Man</th>
        <th>
          <Checkbox
            name="change_type"
            checked={change_type === "man"}
            style={{ color: "#064a29" }}
          />
        </th>
      </tr>
      <tr>
        <th>Material</th>
        <th>
          <Checkbox
            name="change_type"
            checked={change_type === "material"}
            style={{ color: "#064a29" }}
          />
        </th>
        <th>Machine</th>
        <th>
          <Checkbox
            name="change_type"
            checked={change_type === "machine"}
            style={{ color: "#064a29" }}
          />
        </th>
      </tr>
    </thead>
  );
};

export default THead;
