import { MenuItem } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import CustomSelect from "../../../../common/CustomSelect";

const OperationSelect = ({ process, partId, isView, setRender }) => {
  const [operation, setOperation] = useState([]);
  useEffect(() => {
    if (partId) {
      axios
        .get(`/api/forMSummaryRoute/controlPlan/processes/${partId}`)
        .then((res) => {
          if (res.data) {
            setOperation(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [partId]);
  return (
    <>
      {isView ? (
        process.operation_name
      ) : (
        <CustomSelect
          name="operation_name"
          value={process.operation_name || ""}
          onChange={(e) => {
            process.operation_name = e.target.value;
            setRender((prev) => !prev);
          }}
        >
          <MenuItem value="">None</MenuItem>
          {operation.map((name) => (
            <MenuItem value={name}>{name}</MenuItem>
          ))}
        </CustomSelect>
      )}
    </>
  );
};

export default OperationSelect;
