import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    "& .MuiDialog-paper": {
      margin: "5px",
    },
    "& .MuiTypography-root ": {
      paddingBottom: "20px",
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "1500px",
      minWidth: "50vh",
      minHeight: "30vh",
      backgroundColor: "#fff",
      fontSize: "1.5rem",
    },
  },
}));

const useConfirm = (title, message) => {
  const classes = useStyles();
  const [promise, setPromise] = useState(null);

  const confirm = () =>
    new Promise((resolve, reject) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };
  // Dialogue Box
  const ConfirmationDialog = (props) => (
    <Dialog open={promise !== null} className={classes.root}>
      <DialogTitle
        style={{
          backgroundColor: "#212529",
          height: "60px",
          padding: "0.6rem 1rem",
          textTransform: "uppercase",
        }}
      >
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: 400,
            fontFamily: "Nunito",
            letterSpacing: "0.1em",
            color: "#fff",
            marginLeft: "0.6rem",
          }}
        >
          {title}
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText style={{ marginTop: "1rem", color: "black" }}>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          style={{
            backgroundColor: props.isSubmitContent ? "#003566" : "#c80202",
            color: "#fafafa",
          }}
        >
          Yes
        </Button>
        <Button onClick={handleCancel} style={{ backgroundColor: "#fafafa" }}>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
  return [ConfirmationDialog, confirm];
};

export default useConfirm;
