import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link, useHistory } from "react-router-dom";
import Styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";

import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import { useAuth } from "../../../context/GlobalContext";
import { Wrapper } from "../../../components/Control/Flexbox";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import useConfirm from "../../../components/Control/useConfirm";
import { useQuery } from "../../../components/Control/useQuery";
import SyncIcon from "@material-ui/icons/Sync";
import Loader from "../../../components/Control/Loader";
import { ButtonStyled, TableContainerStyled, Table } from "../Container.styled";
import formatDate from "../../../components/Control/formatDate";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});

const CreateEditTNI = () => {
  const classes = dropdownStyles();
  const [isLoading, setIsloading] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const [render, setRender] = useState(false);
  const [allDepartment, setAllDepartment] = useState([]);
  const [autocompleteData, setAutocompleteData] = useState("");

  const [tniData, setTniData] = useState("");

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );

  useEffect(() => {
    axios
      .get(`/api/getAllDepartments`)
      .then((res) => {
        const data = res.data.result.map((item) => {
          return {
            department_id: item._id,
            department_name: item.department_name,
          };
        });
        setAllDepartment(data);
        data.length > 0 && setAutocompleteData(data[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getOneTni/report/${id}/${autocompleteData.department_id}`)
        .then((res) => {
          setTniData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id, autocompleteData, isLoading]);

  const handleSaveAndContinue = (deparmentId) => {
    axios
      .put(
        `/api/updateTniReport/yealy/${id}/${deparmentId}`,
        tniData.departments
      )
      .then((res) => {
        window.alert("success");
      })
      .catch((err) => console.log(err));
  };

  const handleSyncTniReport = async () => {
    setIsloading(true);
    axios
      .get(`/api/syncTniReport/${tniData._id}`)
      .then((res) => {
        const timer = setTimeout(() => {
          setIsloading(false);
          window.alert("successfully updated");
        }, 1000);
      })
      .catch((err) => {
        const timer = setTimeout(() => {
          setIsloading(false);
        }, 1000);
        console.log(err);
      });
  };

  const selectFieldLevels = (item, fieldName) => {
    return (
      <FormControl
        style={{
          width: "210px",
          border: "none",
        }}
        fullWidth={true}
        variant="filled"
        classes={{
          root: classes.root,
        }}
      >
        <InputLabel>Select Level</InputLabel>
        <Select
          value={item[fieldName] || ""}
          classes={{
            root: classes.root,
            select: classes.select,
          }}
          key={autocompleteData.department_id}
          style={{ fontSize: "0.8rem" }}
          disableUnderline
          onChange={(e) => {
            item[fieldName] = e.target.value;
            setRender((prev) => !prev);
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="Level_1">Level 1</MenuItem>
          <MenuItem value="Level_2">Level 2</MenuItem>
          <MenuItem value="Level_3">Level 3</MenuItem>
          <MenuItem value="Level_4">Level 4</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const menuItemStyles = {
    display: "flex",
    width: "80px",
    height: "40px",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    marging: 0,
  };
  const TickCrossSelectField = (item, fieldName) => {
    return (
      <Select
        value={item[fieldName] || ""}
        classes={{
          root: classes.root,
          select: classes.select,
        }}
        inputProps={{ IconComponent: () => null }}
        key={autocompleteData.department_id}
        style={{
          fontSize: "0.8rem",
          ...menuItemStyles,
        }}
        disableUnderline
        onChange={(e) => {
          item[fieldName] = e.target.value;
          setRender((prev) => !prev);
        }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>

        <MenuItem value="tick">
          <span
            style={{
              fontSize: "1.3rem",
              color: "#40c057",
              ...menuItemStyles,
            }}
          >
            ✓
          </span>
        </MenuItem>
        <MenuItem value="cross">
          <span
            style={{
              fontSize: "1.3rem",
              color: "#f03e3e",
              ...menuItemStyles,
            }}
          >
            ✖
          </span>
        </MenuItem>
      </Select>
    );
  };

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 60;
    } else {
      document.getElementById("machinetable").scrollLeft -= 60;
    }
  };

  return (
    <div
      style={{
        paddingBottom: "4rem",
        position: "relative",
      }}
    >
      <ButtonStyled
        left
        onClick={(e) => {
          handleScroll("left");
        }}
      >
        <div>
          <ArrowBackIosIcon style={{ color: "#fff" }} />
        </div>
      </ButtonStyled>
      <ButtonStyled
        right
        onClick={(e) => {
          handleScroll("right");
        }}
      >
        <div>
          <ArrowForwardIosIcon style={{ color: "#fff" }} />
        </div>
      </ButtonStyled>
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            zIndex: "100",
            top: "50%",
            right: "50%",
            transform: "translate(50%,-50%)",
          }}
        >
          {<Loader height={"100vh"} />}
        </div>
      ) : (
        ""
      )}
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          TRAINING NEED IDENTIFICATION REPORT
        </Typography>
        <div style={{ display: "flex" }}>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#1864ab",
                marginRight: "0.5rem",
              }}
              startIcon={<SyncIcon />}
              onClick={() => {
                handleSyncTniReport(true);
              }}
            >
              Synch Employee
            </Button>

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#064a29",
                marginRight: "0.5rem",
              }}
              startIcon={<CloudUploadIcon />}
              onClick={() => {
                handleSaveAndContinue(tniData?.departments[0]._id);
              }}
            >
              Save And Continue
            </Button>
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </Wrapper>
        </div>
      </div>

      <TableContainerStyled id="machinetable">
        <Table>
          <thead>
            <tr data-height="80" style={{ height: "70px" }}>
              <th
                colSpan="14"
                rowSpan={2}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  position: "relative",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="120"
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th
                colSpan={3}
                style={{
                  border: "1px solid black",
                }}
              >
                Doc.No. ARC/F/HRD/11
              </th>
            </tr>
            <tr>
              <th
                colSpan={3}
                style={{ bo5er: "1px solid black" }}
                data-f-sz="10"
              >
                REV. NO.:- 2
              </th>
            </tr>
            <tr>
              <th
                colSpan="14"
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                  textAlign: "center",
                }}
                data-font-sz="18"
                data-a-h="center"
                data-f-bold="true"
                data-fill-color="#1d1d1d"
                data-f-color="FFFFFF"
              >
                {`TRAINING NEED IDENTIFICATION (ASSOCIATE) ${getYearRange(
                  tniData.created_year
                )}`}
              </th>

              <th
                colSpan={3}
                style={{ bo5er: "1px solid black" }}
                data-f-sz="10"
              >
                REV DATE : 20.03.2020
              </th>
            </tr>

            <tr>
              <th colSpan="2">DEPARTMENT</th>
              <th colSpan="4">
                <Autocomplete
                  id="area_of_work"
                  options={allDepartment}
                  getOptionLabel={(option) => option.department_name}
                  getOptionSelected={(option, value) =>
                    option.department_name === value.department_name
                  }
                  value={autocompleteData}
                  onChange={(e, value) => {
                    // questionPaper.area_of_work = value.department_id;
                    setAutocompleteData(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                    />
                  )}
                />
              </th>
              <th colSpan="2">YEAR:</th>
              <th colSpan="9">{formatDate(tniData.createdAt)}</th>
            </tr>
          </thead>
          <thead>
            {[
              "S.no.",
              "Employee Name",
              "Currentlevel",
              "Required Level",
              "Training Topic",
              "GENERAL TRAINING ",
              "Remarks",
            ].map((el, index) => (
              <th
                rowSpan={index === 5 ? 1 : 2}
                colSpan={index === 5 ? 11 : 1}
                align="center"
                style={{ textAlign: "center" }}
              >
                {el}
              </th>
            ))}
            <tr>
              {[
                "5S",
                "Tag Identification",
                "Fifo",
                "Safety",
                "Work Instruction",
                "Abnormal Situations",
                "4m Change",
                "Poka Yoke",
                "Rework Rule",
                "Kaizen",
                "Material",
              ].map((el, index) => (
                <th align="center" style={{ textAlign: "center" }}>
                  {el}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tniData?.departments?.length > 0 &&
              tniData.departments[0].employeelist.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td>{index + 1}</td>
                    <td
                      style={{
                        minWidth: "200px",
                      }}
                    >
                      {item?.employee_name}
                    </td>
                    <td style={{ padding: 0 }}>
                      {selectFieldLevels(item, "current_level")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {selectFieldLevels(item, "required_level")}
                    </td>
                    <td align="center">
                      <input
                        autoComplete="off"
                        className="input"
                        name="training_topic"
                        style={{
                          border: "1px solid #f9e0e0",
                          minWidth: "150px",
                          height: "45px",
                        }}
                        type="textfield"
                        key={autocompleteData.department_id}
                        defaultValue={item.training_topic}
                        onChange={(e) => {
                          item.training_topic = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "five_s")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "tag_identification")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "fifo")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "safety")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "work_Instruction")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "abnormal_situations")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "four_m_Change")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "poka_yoke")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "rework_rule")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "kaizen")}
                    </td>
                    <td style={{ padding: 0 }}>
                      {TickCrossSelectField(item, "material")}
                    </td>
                    <td align="center">
                      <input
                        autoComplete="off"
                        className="input"
                        name="remarks"
                        style={{
                          border: "1px solid #f9e0e0",
                          minWidth: "150px",
                          height: "45px",
                        }}
                        key={autocompleteData.department_id}
                        type="textfield"
                        value={item.remarks}
                        onChange={(e) => {
                          item.remarks = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              ))}
          </tbody>
        </Table>
      </TableContainerStyled>
      <DialogClose isSubmitContent={false} />
    </div>
  );
};

export default CreateEditTNI;
