import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";

import { IconButton, Table, TableHead, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";

const Header = [
  [
    {
      text: "#",
      colspan: 1,
      rowspan: 1,
    },
    {
      text: "NAME",
      colspan: 1,
      rowspan: 1,
    },
    {
      text: "ACTION",
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const ViewFile = ({ id }) => {
  const [render, setRender] = useState(false);
  const [files, setFiles] = useState(null);
  useEffect(() => {
    axios
      .get(`/api/mushinHelpDesk/${id}`)
      .then((res) => {
        if (res.data) {
          setFiles(res.data.files);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [render]);
  const deleteFile = async (index) => {
    if (!window.confirm("Are you sure you want to delete this file?")) {
      return;
    }
    axios
      .delete(`/api/mushinHelpDesk/deleteFile/${id}/${index}`)
      .then((res) => {
        if (res.data) {
          alert("document deleted successfully");
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Box sx={{ width: 500 }}>
      <Table>
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            {Header[0].map((item, index) => (
              <TableCell
                key={`help${index}`}
                style={{ fontWeight: "bold" }}
                align="center"
              >
                {item?.text}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {files && (
          <TableBody>
            {files.map((file, index) => (
              <React.Fragment key={file._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{file.name}</TableCell>
                  <TableCell align="center">
                    <>
                      <a target={"_blank"} href={file.url}>
                        <Tooltip title="VIEW" arrow>
                          <IconButton size="small">
                            <VisibilityIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </a>

                      <Tooltip title="delete" arrow>
                        <IconButton
                          size="small"
                          onClick={() => deleteFile(index)}
                          style={{ marginLeft: "0.5rem" }}
                        >
                          <DeleteIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "#c80202",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
    </Box>
  );
};

export default ViewFile;
