import React, { useState, useEffect } from "react";
import { Wrapper } from "../Control/Flexbox";
import styled from "styled-components";
import Input from "../Control/Input";
import { Tooltip, IconButton, Button } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import axios from "axios";
import { useHistory } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { useAuth } from "../../context/GlobalContext";
const Div = styled.div`
  padding-top: 3rem;
  background-color: white;

  .admin_img {
    height: 275px;
    width: 275px;
  }
`;

const Label = styled.div`
  font-size: 1rem;
  width: 170px;
`;

function AccountInfo({ data }) {
  const [userData, setUserData] = useState(data);
  const [profile, setProfile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const { login } = useAuth();
  const [isUsername, setIsUsername] = useState(true);
  const [isName, setIsName] = useState(true);
  const [isPassword, setIsPassword] = useState(true);
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState();
  const history = useHistory();

  useEffect(() => {
    if (!profile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(profile);
    setPreview(objectUrl);
    return () => {
      URL.revokeObjectURL(objectUrl);
    };
  }, [profile]);

  function handleImage(e) {
    setProfile(e.target.files[0]);
  }

  const handleChange = ({ target }) => {
    setUserData((prev) => ({ ...prev, [target.name]: target.value }));
    setError("");
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function onSubmit(e) {
    e.preventDefault();
    let formIsValid = true;
    if (newPassword.length) {
      let regex = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      if (!regex.test(newPassword)) {
        formIsValid = false;
        setError("password must includes capital letter, number and symbol");
      }
    }

    if (profile && formIsValid) {
      let idxDot = profile.name.lastIndexOf(".") + 1;
      let extFile = profile.name
        .substr(idxDot, profile.name.length)
        .toLowerCase();

      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        userData.profile = profile;
        const formData = new FormData();
        formData.append("name", userData.name);
        formData.append("username", userData.username);
        formData.append("newpassword", newPassword);
        formData.append("profile", profile);
        axios
          .patch(`/api/user/update/${data._id}`, formData)
          .then(() => {
            login();
            setError("");
            history.push("/");
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else {
        alert("Only jpg/jpeg and png files are allowed!");
      }
    }
    if (formIsValid) {
      const formData = new FormData();
      formData.append("name", userData.name);
      formData.append("username", userData.username);
      formData.append("newpassword", newPassword);
      formData.append("profile", profile);
      axios
        .patch(`/api/user/update/${data._id}`, formData)
        .then(() => {
          login();
          setError("");
          history.push("/");
        })
        .catch((error) => {
          console.log(error.message);
        });
    }
  }
  return (
    <Div>
      <form encType="multipart/form-data" onSubmit={onSubmit}>
        <Wrapper justify="space-around">
          <div className="user-detail">
            <Wrapper justify="space-between" style={{ marginBottom: "1rem" }}>
              <Label>
                <strong>Name:</strong>
              </Label>
              <Input
                name="name"
                type="text"
                defaultValue={userData.name}
                variant={isName ? "standard" : "outlined"}
                InputProps={{
                  readOnly: isName,
                }}
                onChange={handleChange}
              />{" "}
              <Tooltip title="Edit Name" placement="right" arrow>
                <IconButton
                  size="large"
                  style={{
                    color: "maroon",
                  }}
                  onClick={() => setIsName(!isName)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Wrapper>
            <Wrapper justify="space-between" style={{ marginBottom: "1rem" }}>
              <Label>
                <strong>Email/Username:</strong>
              </Label>
              <Input
                name="username"
                type="text"
                variant={isUsername ? "standard" : "outlined"}
                defaultValue={userData.username}
                InputProps={{
                  readOnly: isUsername,
                }}
                onChange={handleChange}
              />{" "}
              <Tooltip title="Edit Email/Username" placement="right" arrow>
                <IconButton
                  size="large"
                  style={{
                    color: "maroon",
                  }}
                  onClick={() => setIsUsername(!isUsername)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Wrapper>
            <Wrapper justify="space-between" style={{ marginBottom: "1rem" }}>
              <Label>
                <strong>New Password:</strong>
              </Label>
              <div style={{ position: "relative" }}>
                <Input
                  type={values.showPassword ? "text" : "password"}
                  variant={isPassword ? "standard" : "outlined"}
                  name="newpassword"
                  value={newPassword}
                  InputProps={{
                    readOnly: isPassword,
                  }}
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                    setError("");
                  }}
                />
                {!isPassword && (
                  <IconButton
                    size="small"
                    style={{ position: "absolute", right: "14px", top: "15px" }}
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                )}
                <p
                  style={{
                    color: "red",
                    fontSize: "10px",
                    position: "absolute",
                    left: "0.6rem",
                    bottom: "-2.2rem",
                  }}
                >
                  {error}
                </p>
              </div>
              <Tooltip title="Edit Password" placement="right" arrow>
                <IconButton
                  size="large"
                  style={{
                    color: "maroon",
                  }}
                  onClick={() => setIsPassword(!isPassword)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Wrapper>
          </div>
          <Wrapper className="profile" column>
            <Avatar
              alt="profile"
              className="admin_img"
              src={preview ? preview : userData.profile}
            />
            <input
              id="contained-button-file"
              type="file"
              name="profile"
              accept="image/png,image/jpeg,image/jpg"
              onChange={handleImage}
              hidden
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="outlined"
                component="span"
                color="secondary"
                style={{ marginTop: "1rem" }}
              >
                <PhotoCameraIcon style={{ marginRight: "1rem" }} />
                Change Picture
              </Button>
            </label>
          </Wrapper>
        </Wrapper>
        <Wrapper
          justify="space-evenly"
          style={{
            marginTop: "1.5rem",
            paddingTop: "3rem",
            backgroundColor: "#f0f2f0",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            style={{ marginBottom: "2rem", backgroundColor: "#054a29" }}
            startIcon={<SaveIcon />}
          >
            Save
          </Button>
        </Wrapper>
      </form>
    </Div>
  );
}

export default AccountInfo;
