import EqualizerIcon from "@material-ui/icons/Equalizer";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Wrapper } from "../Control/Flexbox";

function Dashboard() {
  const history = useHistory();
  return (
    <div>
      <div className="divflex" style={{ fontSize: "24px" }}>
        DASHBOARD
      </div>
      <Wrapper
        justify="center"
        style={{
          height: "86vh",
          gap: "10rem",
          backgroundColor: "#fafafa",
        }}
      >
        <div>
          <div
            className="btnshadow"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push("/viewPartTable", {
                selectPartForRejection: true,
              })
            }
          >
            <EqualizerIcon
              style={{
                fontSize: "3rem",
                color: "#003566",
              }}
            />
          </div>

          <p className="card-btn-para">
            Part <span className="card-btn-span">Analysis</span>
          </p>
        </div>

        <div>
          <div
            className="btnshadow"
            style={{ cursor: "pointer" }}
            onClick={() =>
              history.push("/viewPartTable", {
                selectPartForFailureMode: true,
              })
            }
          >
            <EqualizerIcon
              style={{
                fontSize: "3rem",
                color: "maroon",
              }}
            />
          </div>

          <p className="card-btn-para">
            Failure Mode <span className="card-btn-span">Analysis</span>
          </p>
        </div>
      </Wrapper>
    </div>
  );
}

export default Dashboard;
