import React, {
  createContext,
  useReducer,
  useEffect,
  useState,
  useContext,
} from "react";
import authReducer from "./authReducer";
import axios from "axios";
const Global = createContext();

const initialState = {
  user: null,
  error: null,
  loading: true,
};

export const useAuth = () => useContext(Global);

const GlobalContext = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  function login() {
    axios
      .get("/api/user/get", {
        withCredentials: true,
      })
      .then((res) => {
        if (res.data.error) {
          dispatch({ type: "ERROR", error: "Not Authorized" });
          return;
        }
        console.log(res);
        dispatch({ type: "LOGIN", user: res.data });
      })
      .catch((error) => {
        dispatch({ type: "ERROR", error: error.message });
      });
  }

  const logout = async () => {
    try {
      const res = await axios.delete("/api/user/logout", null, {
        withCredentials: true,
      });
      if (res.data.error) {
        dispatch({ type: "ERROR", error: res.data.error });
        return;
      }
      dispatch({ type: "LOGOUT" });
    } catch (error) {
      dispatch({ type: "ERROR", error: error.message });
    }
  };

  useEffect(() => {
    login();
  }, []);

  const value = { login, logout, state };
  return <Global.Provider value={value}>{children}</Global.Provider>;
};

export default GlobalContext;
