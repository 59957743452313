import React, { useMemo, useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../../components/Control/Modal";
import SetupPiece from "./SetupPiece";
import Check1 from "./Check1";
import Check2 from "./Check2";
import Check3 from "./Check3";
import Check4 from "./Check4";
import CheckTime from "./CheckTime";
import { TextField } from "@material-ui/core";
import ProcessSecondHeader from "./ProcessSecondHeader";
import LastPiece from "./LastPiece";

const SpecsList = ({ process, pirData, index }) => {
  const [isSetupModal, setIsSetupModal] = useState(false);
  const [isCheck1Modal, setIsCheck1Modal] = useState(false);
  const [isCheck2Modal, setIsCheck2Modal] = useState(false);
  const [isCheck3Modal, setIsCheck3Modal] = useState(false);
  const [isCheck4Modal, setIsCheck4Modal] = useState(false);
  const [isRejectionModal, setIsRejectionModal] = useState(false);
  const [isFirstModal, setIsFirstModal] = useState(false);
  const closeModal = () => {
    setIsSetupModal(false);
    setIsCheck1Modal(false);
    setIsCheck2Modal(false);
    setIsCheck3Modal(false);
    setIsCheck4Modal(false);
    setIsRejectionModal(false);
    setIsFirstModal(false);
  };

  return (
    <section disabled={!(process.shift_time && process.processDate)}>
      <ProcessSecondHeader {...{ pirData, process, index }} />
      <CheckTime {...{ pirData, process, index }} />
      <section style={{ margin: "0.6rem", display: "flex", gap: "1rem" }}>
        <Wrapper justify="space-between" className="add_button_container">
          <p>First Piece</p>
          <Wrapper
            className="pir_add_modal"
            justify="center"
            style={{ backgroundColor: "#3f51b5" }}
            onClick={() => setIsSetupModal(true)}
          >
            <Add />
          </Wrapper>
        </Wrapper>
        <Wrapper justify="space-between" className="add_button_container">
          <p>Check 1</p>
          <Wrapper
            className="pir_add_modal"
            justify="center"
            style={{ backgroundColor: "#3f51b5" }}
            onClick={() => setIsCheck1Modal(true)}
          >
            <Add />
          </Wrapper>
        </Wrapper>
        <Wrapper justify="space-between" className="add_button_container">
          <p>Check 2</p>
          <Wrapper
            className="pir_add_modal"
            justify="center"
            style={{ backgroundColor: "#3f51b5" }}
            onClick={() => setIsCheck2Modal(true)}
          >
            <Add />
          </Wrapper>
        </Wrapper>
        <Wrapper justify="space-between" className="add_button_container">
          <p>Check 3</p>
          <Wrapper
            className="pir_add_modal"
            justify="center"
            style={{ backgroundColor: "#3f51b5" }}
            onClick={() => setIsCheck3Modal(true)}
          >
            <Add />
          </Wrapper>
        </Wrapper>
        <Wrapper justify="space-between" className="add_button_container">
          <p>Check 4</p>
          <Wrapper
            className="pir_add_modal"
            justify="center"
            style={{ backgroundColor: "#3f51b5" }}
            onClick={() => setIsCheck4Modal(true)}
          >
            <Add />
          </Wrapper>
        </Wrapper>
      </section>
      {/* Modal Section */}
      <section>
        <Modal
          openPopup={isSetupModal}
          title="FIRST PIECE APPROVAL"
          closeModal={closeModal}
        >
          {process.setups.map((spec, idx) => (
            <SetupPiece {...{ pirData, spec, index, idx }} />
          ))}
        </Modal>
      </section>
      <section>
        <Modal
          openPopup={isFirstModal}
          title="LAST PIECE APPROVAL"
          closeModal={closeModal}
        >
          {process.setups.map((spec, idx) => (
            <LastPiece {...{ pirData, spec, index, idx }} />
          ))}
        </Modal>
      </section>
      <section>
        <Modal
          openPopup={isCheck1Modal}
          title="CHECK 1"
          closeModal={closeModal}
        >
          {process.list.map((spec, idx) => (
            <Check1 {...{ pirData, spec, index, idx }} />
          ))}
        </Modal>
      </section>
      <section>
        <Modal
          openPopup={isCheck2Modal}
          title="CHECK 2"
          closeModal={closeModal}
        >
          {process.list.map((spec, idx) => (
            <Check2 {...{ pirData, spec, index, idx }} />
          ))}
        </Modal>
      </section>
      <section>
        <Modal
          openPopup={isCheck3Modal}
          title="CHECK 3"
          closeModal={closeModal}
        >
          {process.list.map((spec, idx) => (
            <Check3 {...{ pirData, spec, index, idx }} />
          ))}
        </Modal>
      </section>
      <section>
        <Modal
          openPopup={isCheck4Modal}
          title="CHECK 4"
          closeModal={closeModal}
        >
          {process.list.map((spec, idx) => (
            <Check4 {...{ pirData, spec, index, idx }} />
          ))}
        </Modal>
      </section>
      {/* <section>
        <Modal
          openPopup={isCheck5Modal}
          title="CHECK 5"
          closeModal={closeModal}
        >
          {process.list.map((spec, idx) => (
            <Check5 {...{ pirData, spec, index, idx }} />
          ))}
        </Modal>
      </section> */}
      {/* <section>
        {isRejectionModal && (
          <RejectionModal
            openPopup={isRejectionModal}
            title={"ADD REJECTION"}
            closeModal={closeModal}
            {...{ partProcess, process, pirData, index }}
          />
        )}
      </section> */}
      {/* potential mode and total rejected */}
      <section style={{ display: "flex" }}>
        <Wrapper
          justify="space-between"
          style={{
            flexBasis: "18.8%",
            marginTop: "0.9rem",
            marginLeft: "0.6rem",
            height: "45px",
          }}
          className="add_button_container"
        >
          <p>LAST PIECE</p>
          <Wrapper
            className="pir_add_modal"
            justify="center"
            style={{ height: "45px", backgroundColor: "#3f51b5" }}
            onClick={() => setIsFirstModal(true)}
          >
            <Add />
          </Wrapper>
        </Wrapper>
        {/* <Wrapper
          justify="space-between"
          style={{
            flexBasis: "18.8%",
            marginTop: "0.9rem",
            marginLeft: "0.6rem",
            height: "45px",
          }}
          className="add_button_container"
        >
          <p>Add Rejection</p>
          <Wrapper
            className="pir_add_modal"
            justify="center"
            style={{ height: "45px" }}
            onClick={() => setIsRejectionModal(true)}
          >
            <Add />
          </Wrapper>
        </Wrapper> */}
      </section>
      <TextField
        type="text"
        variant="filled"
        name="notes"
        multiline
        className="textarea-field"
        label="Notes"
        style={{
          width: "98.8%",
          margin: "0.5rem 0.7rem",
        }}
        onBlur={(e) => {
          pirData.pir[index][e.target.name] = e.target.value;
        }}
        defaultValue={process.notes}
      />
    </section>
  );
};

export default SpecsList;
