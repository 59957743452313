export const processs = [
  {
    department_name: "HR -Department",
    department_wise_data: [
      {
        check_point: "Is training plan made and executed accordingly?",
      },

      {
        check_point: "Is system made to check effectiveness of training?",
      },
      {
        check_point: "Are training execution records available and documented?",
      },
      {
        check_point: "Is the 5S condition of office and Shop floor clean?",
      },
      {
        check_point:
          "Are safety display boards displayed at required locations and operators wearing safety guards\n(Gloves, glasses, safety kits etc.) while working on m/c. or tool?",
      },
    ],
  },

  {
    department_name: "Purchase - Department",
    department_wise_data: [
      {
        check_point: "Is Purchasing Procedure has been established?",
      },
      {
        check_point: "Is approved supplier list available?",
      },
      {
        check_point:
          "Is any system has been established on receiving of\nwrong item?",
      },
    ],
  },

  {
    department_name: "Parts Storage Incoming & Outgoing",
    department_wise_data: [
      {
        check_point:
          "Does raw material storage ensures no damage from rain, humidity and any other atmospheric condition?",
      },
      {
        check_point: "Is 5S map for FG area made and displayed?",
      },
      {
        check_point:
          "Is identification and traceability of parts / raw material done?",
      },
      {
        check_point:
          "Is FIFO implemented and the material / product stock\nkept properly with proper identification and QA - Pass sticker pasted on every lot?",
      },
    ],
  },
  {
    department_name: "Production",
    department_wise_data: [
      {
        check_point: "Working guide available and controlled?",
      },
      {
        check_point:
          "Self / Sequential Inspection sheet displayed at all stages?",
      },
      {
        check_point:
          "Is process audit done and observations monitored & improvement done against line rejections?",
      },
      {
        check_point:
          "Line rejection PPM trend monitored and necessary corrective action taken against problem?",
      },
      {
        check_point:
          "Are in-house rejection targets defined / fixed & is there regular review of in house rejection and countermeasure taken for non-conformance product /  material?	 ",
      },
      {
        check_point: "Does 4M management system exists, Check History?",
      },
    ],
  },
  {
    department_name: "Maintenance-Department",
    department_wise_data: [
      {
        check_point:
          "Preventive maintenance plan available and executed as per plan?",
      },
      {
        check_point:
          "Is preventive maintenance record of weekly / monthly / quarterly / six monthly maintained?",
      },
      {
        check_point: "Is Machine Break Down Records maintained?",
      },
      {
        check_point:
          "Is Machine Break Down Analysis done and action taken accordingly?",
      },
    ],
  },
  {
    department_name: "QC-Department",
    department_wise_data: [
      {
        check_point:
          "Is QC dept. well established also check QC Flowchart availability?",
      },
      {
        check_point:
          "Are part / raw material inspection norms made and inspection done as per plan?",
      },
      {
        check_point:
          "Is incoming raw material report verified & test reports\npreserved?",
      },
      {
        check_point: "Does the vendor have an approved supplier list?",
      },
      {
        check_point:
          "Are the suppliers informed in the event of a non- conformance?",
      },
      {
        check_point:
          "Are Operational Quality Check inspection standards\ndefined in work instruction / procedures and displayed near inspection stage?",
      },
      {
        check_point:
          "Are Operational Quality Check report filled and standards defined in work instruction / procedures\nmatching with reports?",
      },
      {
        check_point:
          "Is there a system defined for traceability of rejected lot and proper traceability till reoffered for inspection?",
      },
      {
        check_point:
          "Is history of customer end problems maintained and counter measure against problem taken and recorded and submitted to customer?",
      },
      {
        check_point:
          "Is Calibration management system exist with plan and revised from time to time?",
      },
      {
        check_point:
          "Check calibration certificates are available with their traceability certificates?",
      },
      {
        check_point:
          "Check measuring instruments / tools / gauges / equipment's used on line for inspection are calibrated\n& calibration sticker is available?",
      },
      {
        check_point:
          "Is process audit done and observations monitored & improvement done against line rejections?",
      },
    ],
  },
];
