import { React, useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker } from "@material-ui/pickers";
import formatDate from "../../../../components/Control/formatDate";
import { CenteredLogoImg } from "../../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../../constants/constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import axios from "axios";

import {
  ButtonStyled,
  TableContainerStyled,
} from "../../../DailyProduction/styles";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        height: 50px;
        text-align:center;
    }

`;

const auditProcess = {
  sales_and_marketing: "Sales & Marketing",
  NPD: "New Producct Development (NPD)",
  MR: "MR",
  MNT_machine: "Maintenance (Machine)",
  purch: "Purchasing",
  stores_and_dispatch: "Stores",
  HR: "HR",
  production: "Production",
  QA: "QA",
  CSR: "CSR",
};
const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});
const RemaingingDepartment = ({
  isView,
  checklistData,
  setChecklistdata,
  handleChangeChecklistAudit,
  setRender,
  departmentType,
  auditorValue,
  setAuditorValue,
  auditeeValue,
  setAudieeValue,
  shift,
  auditDate,
}) => {
  const classes = dropdownStyles();
  const [employeeAutocomplete, setEmployeeAutocomplete] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/getAllEmployeeData/`)
      .then((res) => {
        setEmployeeAutocomplete(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <TableContainerStyled id="machinetable">
      {departmentType === "None" ? (
        <div
          style={{ fontSize: "1.5rem", textAlign: "center", marginTop: "5rem" }}
        >
          NOT Available
        </div>
      ) : (
        <Table
          cellSpacing="0"
          style={{
            marginTop: "0.6rem",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th
                colSpan="4"
                rowSpan={3}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  height: "100px",
                }}
              >
                <CenteredLogoImg>
                  <img
                    src={images?.arcLogo}
                    alt={textInputs?.companyName}
                    height="46"
                    width="120"
                  />
                  <span>{textInputs?.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                Doc No.- ARC/F/IA/04
              </th>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                Rev- 01
              </th>
            </tr>
            <tr>
              <th
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                Dated- 01.01.21
              </th>
            </tr>
            <tr>
              <th
                colSpan="6"
                align="center"
                style={{
                  fontSize: "1rem",
                  fontWeight: 600,
                  border: "1px solid black",
                  textAlign: "center",
                }}
              >
                IQA (System) Audit Checksheet
              </th>
            </tr>
            <tr>
              <th
                colSpan={1}
                style={{
                  border: "1px solid black",
                }}
              >
                Audit Process :
              </th>
              <th
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                {auditProcess[departmentType] || ""}
              </th>
              <th
                colSpan={1}
                style={{
                  border: "1px solid black",
                }}
              >
                Name of the Auditors :
              </th>
              <th
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                {isView ? (
                  checklistData?.auditor?.employee_name
                ) : (
                  <Autocomplete
                    id="training_topics"
                    options={employeeAutocomplete}
                    getOptionLabel={(option) => option.employee_name}
                    getOptionSelected={(option, value) =>
                      option.employee_name === value.employee_name
                    }
                    value={auditorValue}
                    onChange={(e, value) => {
                      checklistData.auditor = value?._id || null;
                      setAuditorValue(value);
                      setRender((prev) => !prev);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                )}
              </th>
            </tr>
            <tr>
              <th
                colSpan={1}
                style={{
                  border: "1px solid black",
                }}
              >
                Date of Audit / Shift:-
              </th>
              <th
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                {auditDate} {shift && `/ ${shift}`}
              </th>
              <th
                colSpan={1}
                style={{
                  border: "1px solid black",
                }}
              >
                Name of the Auditees :
              </th>
              <th
                colSpan={2}
                style={{
                  border: "1px solid black",
                }}
              >
                {isView ? (
                  checklistData?.auditee?.employee_name
                ) : (
                  <Autocomplete
                    id="training_topics"
                    options={employeeAutocomplete}
                    getOptionLabel={(option) => option.employee_name}
                    getOptionSelected={(option, value) =>
                      option.employee_name === value.employee_name
                    }
                    value={auditeeValue}
                    onChange={(e, value) => {
                      checklistData.auditee = value?._id || null;
                      setAudieeValue(value);
                      setRender((prev) => !prev);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                  />
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {[
                "Clause No (IATF 16949)",
                "Audit check points",
                "What to look and record",
                "Actual observations",
                "Status C / NC ",
                "Remarks",
              ].map((heading, index) => (
                <th
                  key={index + 1.0001}
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                    textTransform: "uppercase",
                  }}
                >
                  {heading}
                </th>
              ))}
            </tr>
            {checklistData &&
              checklistData?.checklist_data[departmentType].map(
                (item, index) => (
                  <tr key={index + "department type "}>
                    <td style={{ textAlign: "center", width: "8rem" }}>
                      {item?.clause_no}
                    </td>
                    <td style={{ width: "10rem" }}>
                      {item?.audit_check_points}
                    </td>
                    <td>{item?.what_to_look_and_record}</td>
                    <td>{item?.actual_obs}</td>
                    <td
                      style={{
                        width: "5rem",
                        textTransform: "uppercase",
                        textAlign: "center",
                      }}
                    >
                      {isView ? (
                        item?.status_c_nc
                      ) : (
                        <FormControl
                          style={{
                            width: "150px",
                            border: "none",
                          }}
                          fullWidth={true}
                          variant="filled"
                          classes={{
                            root: classes.root,
                          }}
                        >
                          <InputLabel>Select C / NC</InputLabel>
                          <Select
                            value={item?.status_c_nc || ""}
                            name="status_c_nc"
                            classes={{
                              root: classes.root,
                              select: classes.select,
                            }}
                            // key={autocompleteData.department_id}
                            style={{ fontSize: "0.8rem" }}
                            disableUnderline
                            onChange={(e) => {
                              handleChangeChecklistAudit(
                                e,
                                departmentType,
                                index
                              );
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="c">C</MenuItem>
                            <MenuItem value="nc">NC</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                    </td>
                    <td style={{ width: "10rem", textAlign: "center" }}>
                      {isView ? (
                        item?.remarks
                      ) : (
                        <input
                          type="text"
                          name="remarks"
                          value={item?.remarks}
                          autoComplete="off"
                          className="input"
                          style={{ border: "2px solid #f9e0e0" }}
                          onChange={(e) => {
                            handleChangeChecklistAudit(
                              e,
                              departmentType,
                              index
                            );
                          }}
                        />
                      )}
                    </td>
                  </tr>
                )
              )}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={1}>Legends</th>
              <th colSpan={5}> C: Conformity , NC: Non Conformity</th>
            </tr>
            <tr>
              <th colSpan={3}>Auditor Sign</th>
              <th colSpan={3}> Auditee Sign</th>
            </tr>
          </tfoot>
        </Table>
      )}
    </TableContainerStyled>
  );
};

export default RemaingingDepartment;
