export const ncSummary = [
  {
    nonconforomances_obs_no: "List of Internal Auditor Not Updated ",
    action_plan_against_ncs: "Update the internal Auditor List ",
    reponsibility: "Avinash ",
    target: "10.09.2022",
    status: "",
  },
  {
    nonconforomances_obs_no: "Customer Property not defined ",
    action_plan_against_ncs:
      "Customer Property Defined and Make a list for Customer Property ",
    reponsibility: "D.B. Bakshi ",
    target: "10.09.2022",
    status: "",
  },
  {
    nonconforomances_obs_no:
      "Supplier IATF 16949 third party certification plan /  target date",
    action_plan_against_ncs:
      "Make a Plan for IATF 16949 for Suppliers with Target Dates ",
    reponsibility: "Pankaj Tomar ",
    target: "10.09.2022",
    status: "",
  },
  {
    nonconforomances_obs_no:
      "Controlled Copy Stamp not Available on some Work Instructions ",
    action_plan_against_ncs:
      "Collect the all uncontrolled Works Instruction and Re-issue with Controlled Copy Stamp ",
    reponsibility: "Avinash ",
    target: "10.09.2022",
    status: "",
  },
  {
    nonconforomances_obs_no:
      "PPAP Documsnte not available for All Developed Parts ",
    action_plan_against_ncs:
      "Make a Plan for PPAP documents preparation and Prepare as per plan ",
    reponsibility: "Avinash ",
    target: "10.09.2022",
    status: "",
  },
];
