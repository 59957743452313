import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import Input from "../../../components/Control/Input";
import formatDate from "../../../components/Control/formatDate";

import { Wrapper } from "../../../components/Control/Flexbox";

import { Paper } from "@material-ui/core";
import useDebounce from "../../../components/Control/useDebounce";
import { useAuth } from "../../../context/GlobalContext";
import Visibility from "@material-ui/icons/Visibility";

// all api are same as AddPart, which are used in clause4

const ApqpMatrixList = () => {
  const [data, setData] = useState([]);
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  const { state } = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("part_number");
  // delete modal

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/getAllNpdParts?page=${page}&sortBy=${filter}&search=${search}`,
        {
          headers: { isFullAdmin: state?.user?.isFullAdmin },
        }
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  useDebounce(fetchData, 1000, [page, search, filter, isNewPartModal]);

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          APQP MATRIX
          <div></div>
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "0.8" }}>
              <Input
                placeholder="Search Part Number"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="part_type"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem value="part_number">A - Z</MenuItem>
                <MenuItem value="createdAt">Most Recent First</MenuItem>
                <MenuItem value="createdAt_old">Oldest First</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Item Number
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Item Name/Description
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Created At
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        // width={20}
                        style={{
                          overflow: "auto",
                          maxWidth: "15rem",
                          width: "15rem",
                        }}
                      >
                        {item.part_number}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          width: "20rem",
                          maxWidth: "20rem",
                        }}
                      >
                        {item.part_name}
                      </TableCell>
                      <TableCell align="center">
                        {formatDate(item.createdAt)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <Tooltip title="Create" arrow>
                          <Link
                            to={`/createEditApqpMatrix/${item._id}?isView=true`}
                          >
                            <IconButton size="small">
                              <Visibility
                                style={{
                                  fontSize: "1.2rem",
                                  color: "#003566",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Create" arrow>
                          <Link
                            to={`/createEditApqpMatrix/${item._id}?isView=false`}
                          >
                            <IconButton size="small">
                              <EditIcon
                                style={{
                                  fontSize: "1.2rem",
                                  color: "#003566",
                                  marginLeft: "0.5rem",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(_, number) => setPage(number)}
            />
          </div>
        </TableContainer>
      </main>
    </>
  );
};

export default ApqpMatrixList;
