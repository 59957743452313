import React from "react";
import Styled from "styled-components";
import { mdiFileExcel } from "@mdi/js";
import Icon from "@mdi/react";
import { Tooltip, Typography, Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import formatDate from "../../../../components/Control/formatDate";
import { Wrapper } from "../../../../components/Control/Flexbox";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { CenteredLogoImg } from "../../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../../constants/constants";
import TableToExcel from "@dayalk/table-to-excel";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;


    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
        
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
        b{
          font-size: 13px;
		      font-family:"Roboto",sans-serif;
        }
        strong{
          font-size: 13px;
		      font-family:"Roboto",sans-serif;
        }
    }
    .icon td{
    	font-size:1.3rem;
    }
`;

const style = { border: "1px solid black", textAlign: "center" };

const PfdTableSheet = ({ data, partId, pfdId }) => {
  const history = useHistory();

  const getProcessFlow = (item) => {
    let value = null;
    if (item.symbol === 1) {
      value = (
        <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
          ◯
        </td>
      );
    } else if (item.symbol === 2) {
      value = (
        <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
          ▭
        </td>
      );
    } else if (item.symbol === 3) {
      value = (
        <td
          style={{ fontSize: "5rem", ...style }}
          data-f-sz="60"
          data-a-v="top"
        >
          ⌼
        </td>
      );
    } else if (item.symbol === 4) {
      value = (
        <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
          △
        </td>
      );
    } else if (item.symbol === 5) {
      value = (
        <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
          📦
        </td>
      );
    } else if (item.symbol === 6) {
      value = (
        <td style={{ fontSize: "5rem", ...style }} data-f-sz="60">
          ⇨
        </td>
      );
    } else {
      value = <td style={style}></td>;
    }
    return value;
  };

  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }

  function deletePfd(id) {
    if (window.confirm("Are you sure you want to delete this PFD?")) {
      axios
        .delete(`/api/delete/pfd/${id}`)
        .then(() => {
          history.goBack();
        })
        .catch((err) => console.log(err));
    }
  }
  return (
    <>
      {pfdId ? (
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
            }}
          >
            PROCESS FLOW DIAGRAM
          </Typography>
          <Wrapper>
            <Tooltip arrow title="Print table">
              <PrintIcon
                style={{
                  color: "white",
                  cursor: "pointer",
                  fontSize: "1.9rem",
                  marginRight: "0.6rem",
                }}
                onClick={printTable}
              />
            </Tooltip>
            <Tooltip arrow title="Export as spreadsheet">
              <div>
                <Button
                  style={{
                    backgroundColor: "#161a1d",
                    borderColor: "#161a1d",
                    borderRadius: "0",
                    color: "white",
                    width: "fit-content",
                  }}
                  onClick={() => {
                    TableToExcel.convert(
                      document.getElementById("table-to-xls"),
                      {
                        name: `PFD - ${data?.part_id?.part_number}.xlsx`,
                        sheet: {
                          name: "pfd_diagram",
                        },
                      }
                    );
                  }}
                >
                  <Icon path={mdiFileExcel} size={1.2} />
                </Button>
              </div>
            </Tooltip>
          </Wrapper>
        </div>
      ) : (
        <Wrapper
          style={{
            position: "absolute",
            top: "0.2rem",
            right: "1rem",
            zIndex: "100000",
          }}
        >
          <Tooltip arrow title="Print table">
            <PrintIcon
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={printTable}
            />
          </Tooltip>
          <Tooltip arrow title="Export as spreadsheet">
            <div>
              <Button
                style={{
                  color: "white",
                  width: "fit-content",
                }}
                onClick={() => {
                  TableToExcel.convert(
                    document.getElementById("table-to-xls"),
                    {
                      name: `PFD - ${data?.part_id?.part_number}.xlsx`,
                      sheet: {
                        name: "pfd_diagram",
                      },
                    }
                  );
                }}
              >
                <Icon path={mdiFileExcel} size={1.2} />
              </Button>
            </div>
          </Tooltip>
        </Wrapper>
      )}

      {/* for qucik view */}

      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          style={{
            borderCollapse: "collapse",
          }}
          data-default-wrap="true"
          data-cols-width="10, 15, 20, 16, 25, 25, 30"
        >
          <thead>
            <tr
              data-height="100"
              style={{
                height: "100px",
              }}
            >
              <th
                colSpan="7"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="120"
                    style={{}}
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
            </tr>
            <tr data-height="40">
              <td
                colSpan="7"
                align="center"
                style={{
                  fontSize: "1.2rem",
                  fontWeight: 600,
                  backgroundColor: "#1d1d1d",
                  color: "white",
                  border: "1px solid black",
                }}
                data-fill-color="FF000000"
                data-f-sz="12"
              >
                PROCESS FLOW CHART
              </td>
            </tr>

            {/* headers */}
            <tr data-height="30">
              <td
                colSpan="4"
                align="left"
                style={{ border: "none" }}
                data-f-sz="11"
              >
                <strong>Customer Name</strong> : {data?.part_id?.customer}
              </td>
              <td
                align="left"
                style={{
                  border: "1px solid black",
                  textTransform: "capitalize",
                }}
                data-f-sz="11"
              >
                {data?.part_type}
              </td>
              <td
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="14"
              >
                ✓
              </td>
              <td
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="11"
              >
                <b>Doc.No.</b> ARC/F/QAD/12
              </td>
            </tr>
            <tr data-height="30">
              <td
                colSpan="4"
                align="left"
                style={{ border: "none" }}
                data-f-sz="11"
              >
                <b>Part Name:</b> {data?.part_id?.part_name}
              </td>
              <td
                align="left"
                colSpan="2"
                style={{ border: "1px solid black" }}
                data-f-sz="11"
              >
                <b>Part No :</b> {data?.part_id?.part_number}
              </td>

              <td
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="11"
              >
                <b>Rev.No./Date:</b> 00/01-10-2017
              </td>
            </tr>
            <tr data-height="30">
              <td
                align="left"
                colSpan="4"
                style={{ border: "none" }}
                data-f-sz="11"
              >
                <b>Rev.No./Date:</b> 26.09.2003
              </td>

              <td
                colSpan="3"
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="11"
              >
                <b>Orig. Date:</b> {formatDate(data?.part_id?.date_orig)}
              </td>
            </tr>
            <tr data-height="30">
              <td
                colSpan="4"
                align="left"
                style={{ border: "none" }}
                data-f-sz="11"
              >
                <b>Process Flow No:</b> {data?.pfd_no}
              </td>
              <td
                colSpan="2"
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="11"
              >
                <b>Rev.:</b>
              </td>
              <td
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="11"
              >
                <b>Rev. Date :</b>
              </td>
            </tr>
            <tr data-height="30">
              <td
                colSpan="7"
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="11"
              >
                <b>Core Team :</b> {data?.part_id?.core_team}
              </td>
            </tr>
            {/* main mapping  */}

            <tr data-height="40">
              <th style={style} align="center" data-f-sz="11">
                OPERATION NO.
              </th>
              <th style={style} align="center" data-f-sz="11">
                Opration Description
              </th>
              <th style={style} align="center" data-f-sz="11">
                Flow Chart
              </th>
              <th style={style} align="center" data-f-sz="11">
                Machine
              </th>
              <th style={style} align="center" data-f-sz="11">
                Specification to achieve
              </th>
              <th style={style} align="center" data-f-sz="11">
                Key Control Characterstics
              </th>
              <th style={style} align="center" data-f-sz="11">
                Process Control Parameters
              </th>
            </tr>
          </thead>
          <tbody>
            {data.processes.map((item) =>
              item?.movement ? (
                <>
                  <tr data-height="100">
                    <td style={style} data-f-sz="11">
                      {item?.process_no}
                    </td>
                    <td style={style} data-f-sz="11">
                      {item?.process_name}
                    </td>
                    {getProcessFlow(item)}
                    <td style={style} data-f-sz="11">
                      {item?.machine_pfd}
                    </td>
                    <td style={style} data-f-sz="11">
                      {item?.spec_to_achieve_pfd}
                    </td>
                    <td style={style} data-f-sz="11">
                      {item?.key_control}
                    </td>
                    <td style={style} data-f-sz="11">
                      {item?.variation}
                    </td>
                  </tr>
                  <tr data-height="50" maxHeight="20px">
                    <td style={style} data-f-sz="11"></td>
                    <td style={style} data-f-sz="11"></td>
                    <td style={{ fontSize: "3.5rem", ...style }} data-f-sz="36">
                      ↓
                    </td>
                    <td style={style} data-f-sz="11"></td>
                    <td style={style} data-f-sz="11"></td>
                    <td style={style} data-f-sz="11"></td>
                    <td style={style} data-f-sz="11"></td>
                  </tr>
                </>
              ) : (
                <tr data-height="100">
                  <td style={style} data-f-sz="11">
                    {item.process_no}
                  </td>
                  <td style={style} data-f-sz="11">
                    {item.process_name}
                  </td>
                  {getProcessFlow(item)}
                  <td style={style} data-f-sz="11">
                    {item.machine_pfd}
                  </td>
                  <td style={style} data-f-sz="11">
                    {item.spec_to_achieve_pfd}
                  </td>
                  <td style={style} data-f-sz="11">
                    {item.key_control}
                  </td>
                  <td style={style} data-f-sz="11">
                    {item.variation}
                  </td>
                </tr>
              )
            )}
          </tbody>

          <tbody>
            <tr style={{ fontWeight: "bold" }}>
              <td
                colSpan="1"
                style={{ border: "1px solid black" }}
                align="center"
                rowSpan="2"
                data-f-sz="11"
              >
                Revision No.
              </td>
              <td
                colSpan="1"
                style={{ border: "1px solid black" }}
                align="center"
                rowSpan="2"
                data-f-sz="11"
              >
                Revision Date
              </td>
              <td
                colSpan="3"
                style={{ border: "1px solid black" }}
                align="center"
                rowSpan="2"
                data-f-sz="11"
              >
                Revision Summary
              </td>
              <td
                colSpan="2"
                style={{ border: "1px solid black" }}
                align="center"
                rowSpan="2"
                data-f-sz="11"
              >
                Revision Reason
              </td>
            </tr>
            <tr style={{ height: "60px" }}></tr>
            {data?.revisionData.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <tr data-height="150">
                    <td
                      colSpan="1"
                      style={{ border: "1px solid black" }}
                      align="center"
                      data-f-sz="11"
                    >
                      {item.rev_no}
                    </td>
                    <td
                      colSpan="1"
                      style={{ border: "1px solid black" }}
                      align="center"
                      data-f-sz="11"
                    >
                      {formatDate(item.rev_date)}
                    </td>
                    <td
                      colSpan="3"
                      style={{ border: "1px solid black" }}
                      align="center"
                      data-f-sz="11"
                    >
                      {item.summary}
                    </td>
                    <td
                      colSpan="2"
                      style={{ border: "1px solid black" }}
                      align="center"
                      data-f-sz="11"
                    >
                      {item.reason}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>

          <tbody>
            <tr data-height="60">
              <th
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
                data-f-sz="12"
              >
                Operation
              </th>
              <td style={{ fontSize: "1.3rem", ...style }}> ◯</td>

              <th
                align="left"
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="12"
              >
                Transportaion
              </th>
              <td style={{ fontSize: "3rem", ...style }}>⇨</td>
            </tr>
            <tr data-height="60">
              <th
                colSpan={2}
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="12"
              >
                Assy.
              </th>
              <th style={{ fontSize: "2rem", ...style }}></th>
              <th
                colSpan={3}
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="12"
              >
                Storage
              </th>
              <th style={{ fontSize: "2rem", ...style }}>△</th>
            </tr>
            <tr data-height="60">
              <th
                colSpan={2}
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="12"
              >
                Inspection
              </th>
              <th style={{ fontSize: "2rem", ...style }}>▭</th>
              <th
                align="left"
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="12"
              >
                Operation & Inspection
              </th>
              <th style={{ fontSize: "2rem", ...style }} data-f-sz="20">
                <div>⌼</div>
              </th>
            </tr>
            <tr data-height="60">
              <th
                colSpan={2}
                align="left"
                style={{ border: "1px solid black" }}
                data-f-sz="12"
              >
                Decision
              </th>
              <th style={{ fontSize: "2rem", ...style }}></th>
              <th
                align="left"
                colSpan={3}
                style={{ border: "1px solid black" }}
                data-f-sz="12"
              >
                Packing
              </th>
              <th style={{ fontSize: "2rem", ...style }}>📦</th>
            </tr>
            <tr style={{ height: "60px" }} data-height="40">
              <td
                align="left"
                colSpan="2"
                style={{ fontWeight: 500, border: "1px solid black" }}
                data-f-sz="12"
              >
                Prepared By:
              </td>
              <td
                align="left"
                colSpan={2}
                style={{ border: "1px solid black" }}
                data-f-sz="11"
              ></td>

              <td
                align="left"
                colSpan="2"
                style={{ fontWeight: 500, border: "1px solid black" }}
                data-f-sz="12"
              >
                Approved By:
              </td>
              <td align="left" style={{ border: "1px solid black" }}></td>
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default PfdTableSheet;
