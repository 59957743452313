import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from "@mui/icons-material/Update";
import BackupIcon from "@mui/icons-material/Backup";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { BsStars } from "react-icons/bs";

export function CustomButton(props) {
  const {
    children,
    color,
    variant,
    disabled,
    onClick,
    size,
    sx,
    icon,
    type,
    ...rest
  } = props;
  return (
    <Button
      disabled={disabled ? true : false}
      color={color ? color : "primary"}
      size={size ? size : "large"}
      variant={variant ? variant : "contained"}
      sx={{ ...sx }}
      startIcon={icon ? icon : <AddIcon />} // icons
      onClick={onClick}
      type={type || "button"}
      {...rest}
    >
      {children}
    </Button>
  );
}

export const SaveButton = ({ onClick, label }) => {
  return (
    <CustomButton
      color="blueButton"
      sx={{ margin: "10px" }}
      icon={<SaveIcon />}
      disabled={false}
      size="large"
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export const UpdateButton = ({ onClick, label, sx, size, ...rest }) => {
  return (
    <CustomButton
      color="greenButton"
      sx={sx}
      icon={<UpdateIcon />}
      disabled={false}
      onClick={onClick}
      size={size ? size : "medium"}
      {...rest}
    >
      {label}
    </CustomButton>
  );
};

export const SubmitButton = ({ onClick, label, type, ...rest }) => {
  return (
    <CustomButton
      color="greenButton"
      sx={{ margin: "10px" }}
      icon={<BackupIcon />}
      disabled={false}
      size="large"
      onClick={onClick}
      type={type}
      {...rest}
    >
      {label}
    </CustomButton>
  );
};

export const CloseButton = ({ onClick, label }) => {
  return (
    <CustomButton
      color="primary"
      sx={{ margin: "10px" }}
      icon={<CloseIcon />}
      disabled={false}
      size="large"
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export const AddButton = ({ onClick, label, disabled = false }) => {
  return (
    <CustomButton
      sx={{ margin: "10px", backgroundColor: "#1864ab" }}
      icon={<AddIcon />}
      disabled={disabled || false}
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export const AIButton = ({ onClick, label, sx, iconStyle, disabled }) => {
  return (
    <CustomButton
      size="medium"
      sx={{ margin: "2px", background: "#212529", ...sx }}
      icon={<BsStars style={{ ...iconStyle }} />}
      disabled={false || disabled}
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

// create cp button
export const AddProcessButton = ({ onClick, label, sx, ...rest }) => {
  return (
    <CustomButton
      color="secondary"
      sx={{ margin: "2px", ...sx }}
      icon={<AddIcon />}
      disabled={false}
      onClick={onClick}
      {...rest}
    >
      {label}
    </CustomButton>
  );
};

export const CopyProcessButton = ({ onClick, label, sx }) => {
  return (
    <CustomButton
      color="primary"
      sx={{ margin: "2px", ...sx }}
      icon={<ContentCopyIcon />}
      disabled={false}
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export const AddSpecsButton = ({ onClick, label }) => {
  return (
    <CustomButton
      color="blueButton"
      // size="medium"
      sx={{ margin: "2px" }}
      icon={<AddIcon />}
      disabled={false}
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};

export const ExpandButton = ({ onClick, label }) => {
  return (
    <CustomButton
      color="blueButton"
      sx={{ margin: "2px" }}
      icon={<ExpandMoreIcon />}
      disabled={false}
      onClick={onClick}
      // size="medium"
    >
      {label}
    </CustomButton>
  );
};

export const CollapseButton = ({ onClick, label }) => {
  return (
    <CustomButton
      color="blueButton"
      sx={{ margin: "2px" }}
      icon={<ExpandLessIcon />}
      disabled={false}
      // size="medium"
      onClick={onClick}
    >
      {label}
    </CustomButton>
  );
};
// end of create cp button
