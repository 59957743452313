import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  Button,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DatePicker } from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";

import { Add } from "@material-ui/icons";
import Modal from "../../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import { useRef } from "react";
import EvaluationPlanCreateModal from "./EvaluationPlanCreateModal";
import { useParams, Link, useHistory } from "react-router-dom";
import useConfirm from "../../../components/Control/useConfirm";

const getYearRange = (currentDate) => {
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};

const SkillEvaluationPlanList = () => {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isEvaluationModal, setIsEvaluationModal] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    "Delete",
    "Are you sure you want to Delete this report?"
  );
  const setCurrentEditId = useRef("");
  useEffect(() => {
    axios
      .get(`/api/getAllSkillEvaluationPlan/yearly/${selectedDate}`)
      .then((res) => {
        setData(res.data.getAllSkillEvaluationPlan);
      })
      .catch((err) => console.log(err));
  }, [selectedDate, render, isEvaluationModal]);

  const deletePlan = async (id) => {
    const ans = await confirmDelete();
    if (!ans) {
      return;
    }
    axios
      .delete(`/api/deleteSkillEvaluationPlan/${id}`)
      .then((res) => {
        window.alert("success");
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          SKILL EVALUAITION PLAN LIST
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setIsEvaluationModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Plan
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <div style={{ display: "flex", margin: "1rem" }}>
            <DatePicker
              views={["year"]}
              label="Year"
              className="date-field"
              inputVariant="filled"
              value={selectedDate || null}
              onChange={setSelectedDate}
            />
          </div>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  #
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Title
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Date Range{" "}
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data.length > 0 && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center" width={10}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{
                          overflow: "auto",
                          maxWidth: "15rem",
                          width: "15rem",
                        }}
                      >
                        {item.title}
                      </TableCell>
                      <TableCell align="center">
                        {getYearRange(item.created_year)}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "220px" }}
                      >
                        <Tooltip title="Edit evaluation plan List" arrow>
                          <IconButton
                            size="small"
                            style={{ marginLeft: "0.5rem" }}
                            onClick={() => {
                              setIsEvaluationModal(true);
                              setCurrentEditId.current = item._id;
                            }}
                          >
                            <EditIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit evaluation plan List" arrow>
                          <Link
                            to={`/createEditSkillEvaluationpPlan/${item._id}`}
                          >
                            <IconButton
                              size="small"
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <VisibilityIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#343a40",
                                }}
                              />
                            </IconButton>
                          </Link>
                        </Tooltip>
                        <Tooltip title="Delete evaluation plan" arrow>
                          <IconButton
                            size="small"
                            onClick={() => deletePlan(item._id)}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Modal
          title={"create new tna"}
          openPopup={isEvaluationModal}
          closeModal={() => {
            setIsEvaluationModal(false);
            setCurrentEditId.current = "";
          }}
          backgroundColor="white"
        >
          <EvaluationPlanCreateModal
            setIsEvaluationModal={setIsEvaluationModal}
            id={setCurrentEditId.current}
          />
        </Modal>
        <DialogDelete isSubmitContent={true} />
      </main>
    </>
  );
};

export default SkillEvaluationPlanList;
