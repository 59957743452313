import React, { useEffect, useState } from "react";
import FmeaFailures from "./FmeaFailures";
import formatDate from "../../components/Control/formatDate";
import Styled from "styled-components";
import { Wrapper } from "../../components/Control/Flexbox";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import axios from "axios";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAuth } from "../../context/GlobalContext";
import { images, textInputs } from "../../constants/constants";
import { CenteredLogoImg } from "../../golbalStyles/global.styled";
import SaveIcon from "@material-ui/icons/Save";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;

        p{
          margin:0;
        }
    }
    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }
    .input {
        outline: none;
        border: none;
        height: 50px;
        text-align:center;
    }
    .center-heading{
      text-align:center;
    }

`;
const getTopRpn = (processes) => {
  let arrs = [];
  processes?.forEach((process) => {
    process.failures?.forEach((failure) => {
      arrs.push(failure.rpn);
    });
  });
  let topRpn = [0, 0, 0];
  for (let i = 0; i < 4; i++) {
    arrs.forEach((arr) => {
      if (!topRpn.includes(arr)) {
        if (+topRpn[i] < +arr) {
          topRpn[i] = arr;
        }
      }
    });
  }
  return topRpn;
};

const FmeaTable = () => {
  let { state } = useLocation();
  const [fmeaData, setFmeaData] = useState({});
  const [topRpn, setTopRpn] = useState([]);
  const { state: states } = useAuth();
  const { id } = useParams();
  const history = useHistory();
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  const handleChangeFmea = (e) => {
    const value = e.target.innerText;
    const name = e.target.dataset.name;
    fmeaData[name] = value;
  };
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/get/fmea/${id}`)
        .then((res) => {
          if (res.data) {
            setFmeaData(res.data);
            let obtainedRpn = getTopRpn(res.data?.processes);
            setTopRpn(obtainedRpn);
          }
        })
        .catch((err) => console.log(err));
    } else {
      setFmeaData(state.data?.fmeaData);
      let obtainedRpn = getTopRpn(state.data?.fmeaData?.processes);
      setTopRpn(obtainedRpn);
    }
  }, []);
  const updateFmea = async () => {
    let isUpdate = window.confirm(
      "Are you sure you want to update this document?"
    );
    if (isUpdate) {
      let res = await axios
        .put(`/api/update/fmea/${id}`, fmeaData)
        .catch((err) => console.log(err));
      if (res.data?.status === "success") {
        history.push(
          `/viewfmeaTable/fmea/${fmeaData.part_id._id}?partNumber=${fmeaData.part_id?.part_number}&partName=${fmeaData.part_id?.part_name}`
        );
      }
    }
  };

  const submitFmea = async () => {
    let isSubmit = window.confirm(
      "Are you sure you want to save this document?"
    );
    if (isSubmit) {
      let res = await axios
        .post("/api/create/fmea", fmeaData)
        .catch((err) => console.log(err));
      if (res.data?.status === "success") {
        history.push(
          `/viewfmeaTable/fmea/${fmeaData.part_id}?partNumber=${fmeaData.part_number}&partName=${fmeaData.part_name}`
        );
      }
    }
  };

  return (
    <>
      <div className="divflex">
        <Typography
          variant="inherit"
          style={{
            fontSize: "24px",
          }}
        >
          FAILURE MODE & EFFECT ANALYSIS
        </Typography>
        <Wrapper>
          {id ? (
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={updateFmea}
              style={{ backgroundColor: "#003566" }}
              startIcon={<DoubleArrowIcon />}
            >
              Update
            </Button>
          ) : (
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={submitFmea}
              style={{ backgroundColor: "#003566" }}
              startIcon={<SaveIcon />}
            >
              Save & Continue
            </Button>
          )}
        </Wrapper>
      </div>
      <div
        style={{
          margin: "0.1rem 0.5rem",
          paddingBottom: "4rem",
        }}
      >
        <section style={{ overflow: "scroll" }}>
          <Table
            cellSpacing="0"
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "100%",
            }}
            id="table-to-xls"
          >
            <thead>
              <tr style={{ height: "100px" }}>
                <th
                  colSpan="19"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                    position: "relative",
                  }}
                >
                  <CenteredLogoImg>
                    <img
                      src={images.arcLogo}
                      alt={textInputs.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs.companyName}</span>
                  </CenteredLogoImg>
                </th>
              </tr>
              <tr>
                <th
                  colSpan={14}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    backgroundColor: "#1d1d1d",
                    color: "white",
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  POTENTIAL FAILURE MODE AND EFFECTS ANALYSIS
                </th>
                <td colSpan={5} style={{ border: "1px solid black" }}>
                  ARC/F/QAD/13
                </td>
              </tr>
              <tr>
                <th
                  colSpan={14}
                  rowSpan={2}
                  align="center"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: 600,
                    textAlign: "center",
                  }}
                >
                  PROCESS FMEA
                </th>
                <th colSpan={2} style={{ border: "1px solid black" }}>
                  Rev.No./Date:
                </th>
                <td colSpan={3} style={{ border: "1px solid black" }}>
                  00/01-10-2017
                </td>
              </tr>
              <tr>
                <th colSpan={2} style={{ border: "1px solid black" }}>
                  PFMEA No.
                </th>
                <td
                  colSpan={3}
                  contentEditable={true}
                  data-name="pfmea_no"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {fmeaData.pfmea_no}
                </td>
              </tr>
              <tr>
                <th colSpan={3} style={{ border: "1px solid black" }}>
                  Item :-
                </th>
                <td colSpan={4} style={{ border: "1px solid black" }}>
                  {fmeaData.part_name || fmeaData?.part_id?.part_name}
                </td>

                <th colSpan={4} style={{ border: "1px solid black" }}>
                  Process Responsibility -
                </th>
                <td
                  colSpan={3}
                  contentEditable={true}
                  data-name="process_resp"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  {fmeaData.process_resp}
                </td>
                <th colSpan={2} style={{ border: "1px solid black" }}>
                  Rev. No.:
                </th>
                <td colSpan={3} style={{ border: "1px solid black" }}>
                  {fmeaData.revision_no}
                </td>
              </tr>
              <tr>
                <th colSpan={3} style={{ border: "1px solid black" }}>
                  Operation :-
                </th>
                <td
                  colSpan={4}
                  contentEditable={true}
                  data-name="fmea_operation"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.fmea_operation}</span>
                </td>
                <th colSpan={4} style={{ border: "1px solid black" }}>
                  Part No.:
                </th>
                <td colSpan={3} style={{ border: "1px solid black" }}>
                  {fmeaData.part_number || fmeaData.part_id?.part_number}
                </td>

                <th colSpan={2} style={{ border: "1px solid black" }}>
                  Prep. By :
                </th>
                <td colSpan={3} style={{ border: "1px solid black" }}></td>
              </tr>

              <tr>
                <th colSpan={3} style={{ border: "1px solid black" }}>
                  Core Team :-
                </th>
                <td colSpan={4} style={{ border: "1px solid black" }}>
                  <span>
                    {fmeaData.core_team || fmeaData.part_id?.core_team}
                  </span>
                </td>
                <th colSpan={4} style={{ border: "1px solid black" }}>
                  Key Date -
                </th>
                <td
                  colSpan={3}
                  contentEditable={true}
                  data-name="key_date"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.key_date}</span>
                </td>
                <th colSpan={2} style={{ border: "1px solid black" }}>
                  FMEA Rev.Date:
                </th>
                <td
                  colSpan={3}
                  contentEditable={true}
                  data-name="rev_and_date"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.rev_and_date}</span>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr style={{ fontWeight: "500" }} className="center-heading">
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  PROCESS STEP / FUNCTION
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  REQUIREMENT
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", minWidth: "10rem" }}
                >
                  POTENTIAL FAILURE MODE
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", minWidth: "12rem" }}
                >
                  POTENTIAL EFFECT(s) OF FAILURE
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  Severity
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  Classification
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", minWidth: "12rem" }}
                >
                  POTENTIAL CAUSE(S) OF FAILURE
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", minWidth: "12rem" }}
                >
                  CURRENT PROCESS CONTROLS (PREVENTION)
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  Occurance
                </th>

                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", minWidth: "12rem" }}
                >
                  CURRENT PROCESS CONTROLS (DETECTION)
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  Detection
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{
                    border: "1px solid black",
                    transform: "rotate(-90deg)",
                  }}
                >
                  RPN
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  RECOMMENDED ACTION(S)
                </th>
                <th
                  align="center"
                  rowSpan={2}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  RESP. & TARGET COMPLETION DATE
                </th>
                <th
                  align="center"
                  colSpan={5}
                  style={{ border: "1px solid black", textAlign: "center" }}
                >
                  ACTION RESULTS
                </th>
              </tr>
              <tr className="center-heading">
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    width: "100px",
                    minWidth: "100px",
                  }}
                >
                  Action(s) Taken & completion date
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Severity
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Occurance
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Detection
                </th>
                <th
                  align="center"
                  style={{
                    border: "1px solid black",
                  }}
                >
                  RPN
                </th>
              </tr>
              {fmeaData.processes?.map((process, index) => (
                <FmeaFailures
                  key={process._id}
                  {...{ process, index, topRpn }}
                />
              ))}
              <tr>
                <th
                  align="center"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  Criteria
                </th>
                <th
                  align="center"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  Symbol
                </th>
                <th
                  align="center"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  Rev.#
                </th>
                <th
                  align="center"
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                >
                  Description
                </th>
                <th
                  align="center"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  Action
                </th>
                <th
                  align="center"
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                >
                  Root Cause
                </th>
                <th
                  align="center"
                  colSpan={3}
                  style={{ border: "1px solid black" }}
                >
                  Rev. By.
                </th>
                <th
                  align="center"
                  colSpan={2}
                  style={{ border: "1px solid black" }}
                >
                  Date
                </th>
              </tr>
              <tr>
                <td colSpan={2} style={{ border: "1px solid black" }}>
                  Critical
                </td>
                <td colSpan={2} style={{ border: "1px solid black" }}>
                  C
                </td>
                <td
                  colSpan={2}
                  contentEditable={true}
                  data-name="rev_critical"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.rev_critical}</span>
                </td>
                <td
                  colSpan={8}
                  contentEditable={true}
                  data-name="desc_action_rootcause_critical"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.desc_action_rootcause_critical}</span>
                </td>
                <td
                  colSpan={3}
                  contentEditable={true}
                  data-name="rev_by_critical"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.rev_by_critical}</span>
                </td>
                <td
                  colSpan={3}
                  contentEditable={true}
                  data-name="date_critical"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{formatDate(fmeaData.date_critical)}</span>
                </td>
              </tr>
              {/* Major */}
              <tr>
                <td colSpan={2} style={{ border: "1px solid black" }}>
                  Major
                </td>
                <td colSpan={2} style={{ border: "1px solid black" }}>
                  M
                </td>
                <td
                  colSpan={2}
                  contentEditable={true}
                  data-name="rev_major"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.rev_major}</span>
                </td>
                <td
                  colSpan={8}
                  contentEditable={true}
                  data-name="desc_action_rootcause_major"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.desc_action_rootcause_major}</span>
                </td>
                <td
                  colSpan={3}
                  contentEditable={true}
                  data-name="rev_by_major"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.rev_by_major}</span>
                </td>
                <td
                  colSpan={3}
                  contentEditable={true}
                  data-name="date_major"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{formatDate(fmeaData.date_major)}</span>
                </td>
              </tr>
              {/* minor */}
              <tr>
                <td colSpan={2} style={{ border: "1px solid black" }}>
                  Minor
                </td>
                <td colSpan={2} style={{ border: "1px solid black" }}>
                  m
                </td>
                <td
                  colSpan={2}
                  contentEditable={true}
                  data-name="rev_minor"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.rev_minor}</span>
                </td>
                <td
                  colSpan={8}
                  contentEditable={true}
                  data-name="desc_action_rootcause_minor"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.desc_action_rootcause_minor}</span>
                </td>
                <td
                  colSpan={3}
                  contentEditable={true}
                  data-name="rev_by_minor"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{fmeaData.rev_by_minor}</span>
                </td>
                <td
                  colSpan={3}
                  contentEditable={true}
                  data-name="date_minor"
                  onKeyUp={(e) => handleChangeFmea(e)}
                  style={{
                    border: "1px solid black",
                    backgroundColor: "#f9e0e0",
                  }}
                >
                  <span>{formatDate(fmeaData.date_minor)}</span>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr style={{ fontWeight: "bold" }}>
                <td
                  colSpan="2"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  REV NO.
                </td>
                <td
                  colSpan="3"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  REVISION DATE
                </td>
                <td
                  colSpan="9"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  REVISION DESCRIPTION
                </td>
                <td
                  colSpan="5"
                  style={{ border: "1px solid black" }}
                  align="center"
                  rowSpan="2"
                  data-f-sz="11"
                >
                  REMARKS
                </td>
              </tr>
              <tr style={{ height: "60px" }}></tr>
              {fmeaData?.revisionData?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr data-height="150">
                      <td
                        colSpan="2"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.rev_no}
                      </td>
                      <td
                        colSpan="3"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {formatDate(item.rev_date)}
                      </td>
                      <td
                        colSpan="9"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.summary}
                      </td>
                      <td
                        colSpan="5"
                        style={{ border: "1px solid black" }}
                        align="center"
                        data-f-sz="11"
                      >
                        {item.reason}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
            <tbody>
              <tr style={{ height: "60px" }}>
                <td
                  colSpan="4"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                >
                  Prepared By:
                </td>
                <td
                  colSpan="5"
                  align="left"
                  style={{
                    border: "1px solid black",
                    textTransform: "capitalize",
                  }}
                >
                  {states.user.name}
                </td>

                <td
                  colSpan="4"
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontWeight: "bold",
                  }}
                >
                  Approved By:
                </td>
                <td
                  colSpan="6"
                  align="left"
                  style={{ border: "1px solid black" }}
                ></td>
              </tr>
            </tbody>
          </Table>
        </section>
      </div>
    </>
  );
};

export default FmeaTable;
