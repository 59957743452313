import { DatePicker } from "@material-ui/pickers";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import axios from "axios";
import formatDate from "../../Control/formatDate";
import { Add, Check } from "@material-ui/icons";
function Fmea({ ppapData }) {
  const [fmea, setFmea] = useState([]);
  const [render, setRender] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  useEffect(() => {
    axios
      .get(`/api/get/allfmea/${ppapData.part_id._id}/${selectedDate}`)
      .then((res) => {
        setFmea(res.data);
      })
      .catch((err) => console.log(err));
  }, [selectedDate]);

  return (
    <div style={{ marginBottom: "2rem" }}>
      <div style={{ marginTop: "2rem", marginLeft: "1rem" }}>
        <DatePicker
          views={["month", "year"]}
          label="Month & Year"
          className="date-field"
          inputVariant="filled"
          value={selectedDate}
          onChange={setSelectedDate}
        />
      </div>
      <Table size="small">
        <TableHead>
          <TableRow style={{ padding: "6px" }}>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              #
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Item Number
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              From
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              To
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Created At
            </TableCell>
            <TableCell style={{ fontWeight: "bold" }} align="center">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        {fmea && (
          <TableBody>
            {fmea.map((item, index) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {item.part_id?.part_number}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {formatDate(item.from)}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {formatDate(item.to)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.createdAt)}
                  </TableCell>
                  <TableCell align="center">
                    {ppapData.fmea_id === item._id ? (
                      <Tooltip title="Status" arrow>
                        <IconButton size="small">
                          <Check
                            style={{ fontSize: "1.4rem", color: "green" }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Add" arrow>
                        <IconButton
                          size="small"
                          onClick={() => {
                            ppapData.fmea_id = item._id;
                            setRender((prev) => !prev);
                          }}
                        >
                          <Add
                            style={{ fontSize: "1.4rem", color: "maroon" }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>
    </div>
  );
}

export default Fmea;
