import React from "react";
import Styled from "styled-components";
import { Typography, Button, Tooltip } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiFileExcel } from "@mdi/js";
import { Wrapper } from "../../Control/Flexbox";
import TableToExcel from "@dayalk/table-to-excel";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import formatDate from "../../Control/formatDate";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;


    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
        font-weight: 400;
    }
`;
const DesignRecordReport = ({ ppapData }) => {
  function printTable() {
    var divToPrint = document.getElementById("table-to-xls");
    let newWin = window.open("");
    newWin.document.write(divToPrint.outerHTML);
    newWin.print();
    newWin.close();
  }
  return (
    <>
      <div className="divflex">
        <Typography
          variant="inherit"
          style={{
            fontSize: "24px",
          }}
        >
          DESIGN RECORD REPORT
        </Typography>
        <Wrapper justify="space-evenly">
          <div>
            {/* <Tooltip arrow title="Edit">
                  <Link>
                    <IconButton
                      size="large"
                      style={{
                        backgroundColor: "#161a1d",
                        marginRight: "1rem",
                        color: "white",
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Link>
                </Tooltip>

                <Tooltip arrow title="Delete">
                  <IconButton
                    size="large"
                    style={{
                      backgroundColor: "#161a1d",
                      marginRight: "1rem",
                      color: "white",
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip> */}
          </div>

          <Tooltip arrow title="Download DRR excel Table">
            <Button
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.9rem",
                marginRight: "0.6rem",
              }}
              onClick={() => {
                TableToExcel.convert(document.getElementById("table-to-xls"), {
                  name: "Design Record Report.xlsx",
                  sheet: {
                    name: "DESIGN RECORD REPORT",
                  },
                });
              }}
            >
              <Icon path={mdiFileExcel} size={1.3} />
            </Button>
          </Tooltip>
        </Wrapper>
      </div>
      <section>
        <div style={{ paddingBottom: "4rem" }}>
          <Table
            cellSpacing="0"
            id="table-to-xls"
            data-default-wrap="true"
            data-cols-width="80, 80"
          >
            <tr data-height="80" style={{ height: "80px" }}>
              <th
                colSpan="2"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                }}
                data-a-v="middle"
              >
                <CenteredLogoImg>
                  <img
                    src={images.arcLogo}
                    alt={textInputs.companyName}
                    height="46"
                    width="120"
                  />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
            </tr>
            <tr>
              <th
                colSpan="2"
                align="center"
                style={{
                  border: "1px solid black",
                  fontSize: "1.2rem",
                  textAlign: "center",
                }}
              >
                DESIGN RECORD REPORT
              </th>
            </tr>
            <tr>
              <td
                align="left"
                style={{
                  border: "1px solid black",
                  width: "500px",
                }}
              >
                Part Name: {ppapData.part_id.part_name}
              </td>
              <td
                align="left"
                style={{
                  border: "1px solid black",
                  width: "500px",
                }}
              >
                Part No.: {ppapData.part_id.part_number}
              </td>
            </tr>
            <tr>
              <td
                align="left"
                style={{
                  border: "1px solid black",
                }}
              >
                <img
                  src={ppapData.img_1}
                  alt="image 1"
                  height={400}
                  width="100%"
                />
              </td>
              <td
                align="left"
                style={{
                  border: "1px solid black",
                }}
              >
                <img
                  src={ppapData.img_2}
                  alt="image 2"
                  height={400}
                  width="100%"
                />
              </td>
            </tr>
            <tr>
              <td
                align="left"
                style={{
                  border: "1px solid black",
                }}
              >
                <img
                  src={ppapData.img_3}
                  alt="image 3"
                  height={400}
                  width="100%"
                />
              </td>
              <td
                align="left"
                style={{
                  border: "1px solid black",
                }}
              >
                <img
                  src={ppapData.img_4}
                  alt="image 4"
                  height={400}
                  width="100%"
                />
              </td>
            </tr>
            <tr>
              <td
                align="left"
                style={{
                  border: "1px solid black",
                }}
              >
                <img
                  src={ppapData.img_5}
                  alt="image 5"
                  height={400}
                  width="100%"
                />
              </td>
              <td
                align="left"
                style={{
                  border: "1px solid black",
                }}
              ></td>
            </tr>
          </Table>
        </div>
      </section>
    </>
  );
};

export default DesignRecordReport;
