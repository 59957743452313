import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import Input from "../../components/Control/Input";
// import formatDate from "../../components/Control/formatDate";
import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import AddEditSupplier from "./AddEditSupplier";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
// import { useAuth } from "../../context/GlobalContext";
import useConfirm from "../../components/Control/useConfirm";
import "../style.css";
// import formatDate from "../../components/Control/formatDate";

const SupplierLists = () => {
  const [data, setData] = useState([]);
  const [isNewSupplierModal, setIsNewSupplierModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  // const { state } = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  // const [filter, setFilter] = useState("supplier_name");
  const [supplierId, setSupplierId] = useState(null);

  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this supplier ?"
  );

  const fetchData = async () => {
    const res = await axios
      .get(`/api/get/supplier?page=${page}&search=${search}`)
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    // filter,
    // duplicateOpenModal,
    isNewSupplierModal,
  ]);

  async function deleteSupplier(id) {
    // console.log(id, "in delte");
    const ans = await confirmDelete();
    if (ans) {
      await axios
        .delete(`/api/delete_supplier/${id}`)
        .then((res) => {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }

  // console.log(data);

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          MASTER LIST OF SUPPLIER 
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setSupplierId(null);
                setIsNewSupplierModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Supplier
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "0.4" }}>
              <Input
                placeholder="Search Supplier Name"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            {/* <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="supplier_type"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem value="supplier_name">A - Z</MenuItem>
                <MenuItem value="department">Raw Material</MenuItem>
              </Select>
            </FormControl> */}
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  S.No.
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Supplier Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Item Supplied
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  RM Grade
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Address
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Contact Person
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Contact Number
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  ISO Certified/Trader
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Remarks
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell
                        align="center"
                        //  width={10}
                      >
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell align="center">{item.supplier_name}</TableCell>
                      <TableCell align="center">{item.item_supplied}</TableCell>
                      <TableCell align="center">{item.rm_grade}</TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.adress}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.person}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.contact}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.iso_certified_trader}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.remark}
                      </TableCell>
                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "150px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "150px",
                            width: "150px",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="Edit Machine" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: ".5rem",
                              }}
                              onClick={() => {
                                setSupplierId(item._id);
                                setIsNewSupplierModal(true);
                              }}
                            >
                              <EditIcon
                                style={{ fontSize: "1.4rem", color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Delete Machine" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: "0.8rem",
                              }}
                              onClick={() => {
                                deleteSupplier(item._id);
                              }}
                            >
                              <DeleteIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#c80202",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <Modal
          title={supplierId ? "update supplier" : "create new supplier"}
          openPopup={isNewSupplierModal}
          closeModal={() => {
            setIsNewSupplierModal(false);
            setSupplierId(null);
          }}
          backgroundColor="white"
        >
          <AddEditSupplier id={supplierId} {...{ setIsNewSupplierModal }} />
        </Modal>
      </main>
      <DialogDelete />
    </>
  );
};

export default SupplierLists;
