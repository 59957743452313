import { Box } from "@mui/material";
import RenderTable from "../../../../../common/RenderTable";
import TBody from "./TBody";
import TFoot from "./TFoot";
import THead from "./THead";
import TheadChange4m from "../../component4mChange/create/TheadChange4m";
import TbodyChange4m from "../../component4mChange/create/TbodyChange4m";
import TfootChange4m from "../../component4mChange/create/TfootChange4m";
import { useQuery } from "../../../../../components/Control/useQuery";

const TController = ({ moduleData, isView, operation, getProcess }) => {
  const query = useQuery();
  const documentType = query.get("documentType");
  return (
    <Box>
      <RenderTable>
        {documentType === "change4m" ? (
          <TheadChange4m
            {...{
              moduleData,
              getProcess,
              isView,
              operation,
            }}
          />
        ) : (
          <THead
            {...{
              moduleData,
              getProcess,
              isView,
              operation,
            }}
          />
        )}
        {documentType === "change4m" ? (
          <TbodyChange4m
            {...{
              moduleData,
              isView,
            }}
          />
        ) : (
          <TBody
            {...{
              moduleData,
              isView,
            }}
          />
        )}

        {documentType === "change4m" ? (
          <TfootChange4m
            {...{
              moduleData,
              isView,
            }}
          />
        ) : (
          <TFoot
            {...{
              moduleData,
              isView,
            }}
          />
        )}
      </RenderTable>
    </Box>
  );
};

export default TController;
