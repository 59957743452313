const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const getDailyProductionYearly = (year) => {
  return months.map((month) => ({
    month_year: `${month}-${new Date(year).getFullYear()}`,
  }));
};

const MoniteringSheetTableHead = [
  {
    name: "s.no",
    rowspan: "2",
  },
  {
    name: "Date",
    rowspan: "2",
  },
  {
    name: "Time/Shift",
    rowspan: "2",
  },
  {
    name: "Change type (Man/Machine/Method/Material)",
    rowspan: "2",
  },
  {
    name: "Change Description",
    colspan: "2",
  },
  {
    name: "Setup Approval",
    rowspan: "2",
  },
  {
    name: "Retroactive Check",
    colspan: "3",
  },
  {
    name: "Suspected Lot",
    colspan: "3",
  },
  {
    name: "Quality Incharge Sign",
    rowspan: "2",
  },
  {
    name: "Production Incharge Sign",
    rowspan: "2",
  },
  {
    name: "Invoice No.",
    rowspan: "2",
  },
  {
    name: "Remark",
    rowspan: "2",
  },
];

export { getDailyProductionYearly, MoniteringSheetTableHead };
