import React, { useState, useEffect } from "react";
import axios from "axios";
import IncomingForm from "./IncomingForm";
import { useParams, useHistory } from "react-router-dom";

const CreateIncoming = () => {
  const { partId } = useParams();
  const history = useHistory();

  const submit = () => {
    axios.post(`/api/add/incomingReport/`, incomingData).then((res) => {
      history.push(`/view/incomingReport/${partId}`);
    });
  };

  const [incomingData, setincomingData] = useState({
    incoming_supplier: "",
    incoming_invoice_no: "",
    incoming_mrir_no: "",
    incoming_mrir_date: "",
    incoming_lot_no: "",
    incoming_lot_qty: "",
    incoming_sheet_no: "",
    incoming_invoice_date: "",
    incoming_heat_no: "",
    incoming_part_no: "",
    incoming_revision_no: "",
    incoming_description: "",
    incoming_date: "",
    spec_len: 0,
    incomingList: [],
    least_count: "",
    date_performance: "",
    isSubmitted: null,
  });

  useEffect(() => {
    axios.get(`/api/get/incoming/form_part/${partId}`).then((response) => {
      setincomingData((item) => {
        item.part_id = response.data.part_id;
        item.incoming_supplier = response.data.supplier;
        item.incoming_revision_no = response.data.revision_no;
        item.incoming_date = new Date().toISOString().slice(0, 10);
        item.incomingList = response.data.processes[0].specs.map((item) => ({
          appearance: item.appearance,
          check4_symbol: item?.check4_symbol,
          checkbox4_num: item?.checkbox4_num,
          mean: item?.mean,
          utolerance: +item?.utolerance,
          ltolerance: +item?.ltolerance,
          ult: +item?.utl,
          ltl: +item?.ltl,

          incoming_sno: item.s_no,
          incoming_parameter: item.char_product || item.char_process,
          incoming_specification: item.control_product,
          incoming_method: item.methods_measure,
          incoming_lowerlimit: "",
          incoming_upperlimit: "",
          incoming_judgement: "",
          values: Array.from(Array(10)),
          color: Array.from(Array(10)),
        }));
        return { ...item };
      });
    });
  }, []);

  return (
    <>
      <IncomingForm {...{ incomingData, submit, setincomingData }} />
    </>
  );
};

export default CreateIncoming;
