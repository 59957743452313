import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  Button,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import { Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import Input from "../../components/Control/Input";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
import useConfirm from "../../components/Control/useConfirm";
import "../style.css";
import AddEditDefect from "./AddEditDefect";

const DefectsList = () => {
  const [data, setData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState();
  const [page, setPage] = useState(1);
  // const { state } = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  // const [filter, setFilter] = useState("supplier_name");
  const [moduleId, setModuleId] = useState(null);

  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this Defect ?"
  );

  const fetchData = async () => {
    const res = await axios
      .get(`/api/getAllDefects?page=${page}&search=${search}`)
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    render,
    // filter,
    // duplicateOpenModal,
    isModal,
  ]);

  async function deleteDepartment(id) {
    const ans = await confirmDelete();
    if (ans) {
      await axios
        .delete(`/api/deleteDefect/${id}`)
        .then((res) => {
          alert("Document deleted successfully");
          setRender((prev) => !prev);
        })
        .catch((err) => console.log(err));
    }
  }

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          DEPARTMENT LIST
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setModuleId(null);
                setIsModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              New Defect
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <div>
            <Input
              placeholder="Search Defect Name"
              variant="filled"
              style={{
                width: "96%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  S.No.
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Defect Name
                </TableCell>
                <TableCell style={{ fontWeight: "bold" }} align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell
                        align="center"
                        //  width={10}
                      >
                        {perPage * (page - 1) + index + 1}
                      </TableCell>
                      <TableCell align="center">{item.defect_name}</TableCell>

                      <TableCell
                        align="center"
                        style={{ whiteSpace: "nowrap", width: "150px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            minWidth: "150px",
                            width: "150px",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="Edit Defect" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: ".5rem",
                              }}
                              onClick={() => {
                                setModuleId(item._id);
                                setIsModal(true);
                              }}
                            >
                              <EditIcon
                                style={{ fontSize: "1.4rem", color: "black" }}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete Defect" arrow>
                            <IconButton
                              size="small"
                              style={{
                                marginRight: "0.8rem",
                              }}
                              onClick={() => {
                                deleteDepartment(item._id);
                              }}
                            >
                              <DeleteIcon
                                style={{
                                  fontSize: "1.4rem",
                                  color: "#c80202",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, number) => setPage(number)}
            />
          </div>
        </TableContainer>
        <Modal
          title={moduleId ? "update Defect" : "create new Defect"}
          openPopup={isModal}
          closeModal={() => {
            setIsModal(false);
            setModuleId(null);
          }}
          backgroundColor="white"
        >
          <AddEditDefect id={moduleId} {...{ setIsModal }} />
        </Modal>
      </main>
      <DialogDelete />
    </>
  );
};

export default DefectsList;
