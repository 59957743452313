import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { TextField } from "@material-ui/core";
import { Wrapper } from "../../../components/Control/Flexbox";
import { images, textInputs } from "../../../constants/constants";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../../components/Control/useConfirm";
import { useQuery } from "../../../components/Control/useQuery";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        height: 50px;
        text-align:center;
    }

`;

// function replaceWithBr(tdData) {
//   console.log(tdData);
//   return tdData?.replace(/\n/g, "<br />");
// }
const AddEditIRMResister = () => {
  // const classes = dropdownStyles();
  const { id } = useParams();
  // const [partDetails, setPartDetails] = useState([]);
  // const [SupplierDetails, setSuuplierDetails] = useState([]);
  const history = useHistory();
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;
  const [skillEvaluationData, setSkillEvaluationData] = useState({
    levels: [],
  });
  const [render, setRender] = useState(false);

  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );
  const [DialogUpdate, confirmUpdate] = useConfirm(
    "SAVE",
    "Are you sure you want to Submit?"
  );

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    skillEvaluationData.levels[index] = value;
  };

  useEffect(() => {
    if (!id) return;
    axios
      .get(`/api/getSkillEvaluation/${id}`)
      .then((res) => {
        // console.log(res.data);
        setSkillEvaluationData(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  const handleSubmitAndSave = async (bool) => {
    if (id) {
      let ans = await confirmUpdate();
      if (ans) {
        axios
          .put(`/api/updateSkillEvaluation/${id}`, skillEvaluationData)
          .then((res) => {
            alert("updated successfully");
          })
          .then(() => {
            if (bool) {
              history.goBack();
            }
          })
          .catch((err) => console.log(err));
      }
    }
  };
  // console.log(skillEvaluationData);
  return (
    <div>
      <div className="divflex">
        <Typography
          style={{
            fontSize: "24px",
            fontFamily: "Nunito",
          }}
        >
          SKILL EVALUATION CRITERIA
        </Typography>
        {!isView && (
          <div style={{ display: "flex" }}>
            <Wrapper
              style={{
                gap: "1rem",
              }}
            >
              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#003566",
                  marginRight: "0.5rem",
                }}
                startIcon={<SaveIcon />}
                onClick={() => {
                  handleSubmitAndSave(false);
                }}
              >
                Save & Continue
              </Button>

              <Button
                type="button"
                variant="contained"
                color="primary"
                size="large"
                style={{
                  backgroundColor: "#064a29",
                  marginRight: "0.5rem",
                }}
                startIcon={<CloudUploadIcon />}
                onClick={() => {
                  handleSubmitAndSave(true);
                }}
              >
                Submit
              </Button>
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (ans) {
                    history.goBack();
                  }
                }}
              />
            </Wrapper>
          </div>
        )}
      </div>
      <div style={{ paddingBottom: "4rem" }}>
        <Table
          cellSpacing="0"
          style={{
            marginTop: "0.6rem",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <thead>
            <tr>
              <th
                colSpan="1"
                rowSpan={2}
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  position: "relative",
                  minWidth: "200px",
                }}
              >
                {/* <CenteredLogoImg> */}
                {/* <img
                  src={images.arcLogo}
                  alt={textInputs.companyName}
                  height="36"
                  width="110"
                  // style={{ marginBottom: "40px" }}
                /> */}
                {/* <span style={{ marginRight: "2rem" }}>ARC</span> */}
                {/* </CenteredLogoImg> */}
                ARC
              </th>
              <th
                colSpan={1}
                rowSpan={2}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                Skill Evaluation Criterion
              </th>
              <th colSpan={1} style={{ border: "1px solid black" }}>
                Doc.No.:-ARC/F/HRD/03
              </th>
            </tr>
            <tr>
              <th colSpan={1} style={{ border: "1px solid black" }}>
                Rev.No./Date:- 00/1-10-2017
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                colSpan={1}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  // textTransform: "uppercase",
                }}
              >
                Process Name
              </th>
              <th
                colSpan={2}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {skillEvaluationData?.department_id?.department_name}
              </th>
            </tr>
            <tr>
              <th
                colSpan={1}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  // textTransform: "uppercase",
                }}
              >
                Min Level Required
              </th>
              <th
                colSpan={2}
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {isView ? (
                  skillEvaluationData?.minimum_level_required
                ) : (
                  <input
                    type="text"
                    name="minimum_level_required"
                    value={skillEvaluationData?.minimum_level_required}
                    autoComplete="off"
                    className="input"
                    style={{ backgroundColor: "#fff9db" }}
                    onChange={(e) => {
                      skillEvaluationData.minimum_level_required =
                        e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </th>
            </tr>
            {[
              "Level 1(Basic Training Provided)",
              "Level 2(Can Work Under Guidance)",
              "Level 3 (Can Work Independently)",
              "Level 4(Can Trained Others)",
            ].map((level, levelIndex) => (
              <tr key={levelIndex + "skillEval"}>
                <th
                  style={{
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  {level}
                </th>
                <th
                  colSpan="2"
                  style={{
                    border: "1px solid black",
                    // textAlign: "",
                  }}
                >
                  {isView ? (
                    <div>
                      {skillEvaluationData?.levels[levelIndex] &&
                        skillEvaluationData?.levels[levelIndex]
                          .split(/\n/)
                          .map((line) => (
                            <React.Fragment key={line}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))}
                    </div>
                  ) : (
                    <TextField
                      type="text"
                      variant="filled"
                      name="levels"
                      multiline={true}
                      minRows={10}
                      maxRows={20}
                      className="textarea-field"
                      InputProps={{ disableUnderline: true }}
                      label={"Level" + " " + (levelIndex + 1)}
                      style={{
                        width: "100%",
                        border: "none",
                        paddingTop: "10px",
                      }}
                      onChange={(e) => {
                        handleChange(e, levelIndex);
                      }}
                      defaultValue={skillEvaluationData.levels[levelIndex]}
                    />
                  )}
                </th>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={2}>Prepared By :</th>
              <th>Approved By : </th>
            </tr>
          </tfoot>
        </Table>
      </div>
      <DialogSave isSubmitContent={true} />
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </div>
  );
};

export default AddEditIRMResister;
