import React from "react";

const ItemsDetailsHeader = ({ itemNum, itemDesc }) => {
  return (
    <div className="divflex">
      <div>
        <b>Item No. : </b>
        {itemNum}
      </div>
      <div>
        <b>Item Name : </b>
        {itemDesc}
      </div>
    </div>
  );
};

export default ItemsDetailsHeader;
