import { ReactNode } from "react";
import Table from "@mui/material/Table";
import { useMemo } from "react";
import { styled as muiStyled } from "@mui/system";

import TableHeaderCustom from "./TableHeaderCustom";
import { Box } from "@mui/material";
import TableScroll from "./TableScroll";

export const TableStyled = muiStyled(Table)({
  width: "100%",
  marginTop: "1rem",
  margin: "0 10px",
  ".MuiTableCell-root": {
    padding: "5px 8px",
    fontSize: "1rem",
    borderCollapse: "collapse",
  },
});

const TableCustom = ({ size, tableHeaderObj, children, overflow }) => {
  return (
    <Box sx={{ ...(overflow && { overflowX: "auto" }) }}>
      <TableScroll>
        <TableStyled size={size ? size : "small"}>
          {useMemo(
            () => (
              <TableHeaderCustom tableHeaderObj={tableHeaderObj} />
            ),
            [tableHeaderObj],
          )}
          {children}
        </TableStyled>
      </TableScroll>
    </Box>
  );
};

export default TableCustom;
