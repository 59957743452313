import React from "react";
const style = {
  textAlign: "left",
};
const TFoot = () => {
  return (
    <tfoot>
      <tr>
        <td colSpan={12} style={style}>
          Please tick on appropriate box
        </td>
        <td colSpan={5} style={style}>
          TERMINATED
        </td>
        <td colSpan={2} style={style}></td>
        <td colSpan={5} style={style}>
          EXTENDED
        </td>
        <td colSpan={10} style={style}>
          Duration if extended:
        </td>
      </tr>
      <tr>
        <td colSpan={34} style={style}>
          NOTE : IPC is terminated for Regular Production on the basis of
          achieving the above mentioned target / plan for specified duration
        </td>
      </tr>
      <tr>
        <td colSpan={34} style={style}>
          QA Head Sign:-
        </td>
      </tr>
      <tr>
        <td colSpan={34} style={style}>
          Legend:- M - Month & L - Lot
        </td>
      </tr>
    </tfoot>
  );
};

export default TFoot;
