export const calculateWorkingHour = (time1, time2) => {
  if (!time1 && !time2) return "";
  if (new Date(time2).getTime() < new Date(time1).getTime()) {
    return "Choose valid time period";
  }
  const msInHour = 1000 * 60 * 60;
  let workingHour = (new Date(time2) - new Date(time1)) / msInHour;
  if (isNaN(workingHour)) {
    return "";
  }
  let timeArr = workingHour.toString().split(".");
  let hour = timeArr[0];
  let min = 0;
  if (timeArr[1]) {
    min = `${Math.ceil((+timeArr[1].slice(0, 2) / 100) * 60)}`.padEnd(2, 0);
  }
  if (min == 60) {
    min = 0;
  }
  return `${hour} hr ${min} min`;
};
