import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import ProcessPir from "../CreatePir/ProcessPir";
import axios from "axios";
import { useHistory } from "react-router-dom";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { useAuth } from "../../../context/GlobalContext";
import { DatePicker } from "@material-ui/pickers";
import useConfirm from "../../../components/Control/useConfirm";
import PublishIcon from "@material-ui/icons/Publish";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

import {
  SaveAndContinueButton,
  CloseButton,
} from "../../../GlobalUtils/ButtonsGlobal";
import { useQuery } from "../../../components/Control/useQuery";
import ItemsDetailsHeader from "../../../GlobalUtils/ItemsDetailsHeader";
import formatDate from "../../../components/Control/formatDate";

function PirForm({ data, partId, id }) {
  const history = useHistory();
  const [pirData, setPirData] = useState(data);
  const { state } = useAuth();
  const query = useQuery();
  const partNumber = query.get("partNumber");
  const partName = query.get("partName");
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE PIR",
    "Are you sure you want to save this PIR?"
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    "SUBMIT PIR",
    "Are you sure you want to submit this PIR?"
  );
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );

  async function saveLater(e) {
    e.preventDefault();
    const isSubmit = await confirmSave();
    if (isSubmit) {
      pirData.username = state.user.username;
      pirData.report_prepared_by = state.user.name;
      if (
        pirData.is_admin_request === "rejected" &&
        pirData.isSubmitted === true
      ) {
        pirData.isSubmitted = false;
      }
      axios
        .patch(`/api/update/pir/${id}?`, pirData, {
          "Content-Type": "application/json",
        })
        .then((res) => {
          history.push(
            `/supervisor/pir/${partId}?partNumber=${partNumber}&partName=${partName}`
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const isSubmit = await confirmSubmit();
    if (isSubmit) {
      pirData.username = state.user.username;
      pirData.isSubmitted = true;
      pirData.is_admin_request = "pending";
      pirData.report_prepared_by = state.user.name;
      axios
        .patch(`/api/update/pir/${id}`, pirData, {
          "Content-Type": "application/json",
        })
        .then((res) => {
          history.push(
            `/supervisor/pir/${partId}?partNumber=${partNumber}&partName=${partName}`
          );
        })
        .catch((error) => {
          console.log(error);
        });
      const messageData = {
        messageType: "pir",
        part_name: `${partName}`,
        prepared_by: `${state.user.name}`,
        message: `PIR of ${partName} submitted for approval on ${formatDate(
          new Date()
        )} by ${state.user.name}`,
        link: `/ViewPir/${partId}?partNumber=${partNumber}&partName=${partName}`,
      };
      await axios
        .post(`/api/add/message`, messageData)
        .catch((err) => console.log(err));
    }
  }
  return (
    <>
      <main>
        {data && (
          <>
            <div
              className="divflex "
              style={{
                fontSize: "24px",
              }}
            >
              PROCESS INSPECTION REPORT
            </div>
            <ItemsDetailsHeader itemNum={partNumber} itemDesc={partName} />
            <form
              style={{
                margin: "0.6rem",
              }}
              onSubmit={(e) => handleSubmit(e)}
            >
              <Wrapper>
                <div style={{ marginLeft: "0.7rem" }}>
                  <DatePicker
                    label="Date"
                    className="date-field"
                    inputVariant="filled"
                    format="dd/MM/yyyy"
                    onChange={(date) =>
                      setPirData((prev) => ({ ...prev, pir_date: date }))
                    }
                    value={pirData.pir_date}
                  />
                </div>
              </Wrapper>
              <ProcessPir
                {...{
                  pirData,
                }}
              />
              <Wrapper
                style={{
                  position: "absolute",
                  top: "4.7rem",
                  right: "1.6rem",
                  gap: "1rem",
                }}
              >
                {/* <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={saveLater}
                  style={{ backgroundColor: "#003566" }}
                  startIcon={<DoubleArrowIcon />}
                >
                  Save & Continue
                </Button> */}
                <SaveAndContinueButton onClick={saveLater} />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ backgroundColor: "#054a29", marginRight: "0.5rem" }}
                  startIcon={<CloudUploadIcon />}
                >
                  Submit
                </Button>
                <CloseButton
                  onClick={async () => {
                    let ans = await confirmClose();
                    if (ans) {
                      history.goBack();
                    }
                  }}
                />
              </Wrapper>
            </form>
          </>
        )}
      </main>
      <DialogSave isSubmitContent={true} />
      <DialogSubmit isSubmitContent={false} />
      <DialogClose isSubmitContent={false} />
    </>
  );
}

export default PirForm;
