import React from "react";
import { Link } from "react-router-dom";
import clausePage from "./ClausePage.module.css";
//icons
import ListIcon from "@material-ui/icons/List";
// end icons

const Clause11 = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        IMPLEMENTATION OF STANDARDS
      </div>
      <main className={`${clausePage.container} ${clausePage.main}`}>
        <div>
          <Link to="/masterProcedureList?category=clause-11" className="panel">
            <ListIcon style={{ fontSize: "3rem", color: "maroon" }} />
          </Link>
          <p className="card-btn-para">
            Master <span className="card-btn-span">Procedure</span>
          </p>
        </div>
      </main>
    </>
  );
};

export default Clause11;
