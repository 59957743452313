import React from "react";
import styled from "styled-components";
const CardSection = styled.div`
  height: 400px;
  flex: 0.486;
  border: 1px solid black;

  > p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    letter-spacing: 1.6px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #1d1d1d;
    color: white;
  }
  > div {
    margin-top: 6rem;
    margin-left: 6rem;
  }

  h5 {
    font-size: 1.3rem;
    flex: 1;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    margin-top: 2rem;
  }
  span {
    color: gray;
  }
`;
const FailureCardDetails = ({ producedQty, totalFailureRejection }) => {
  return (
    <CardSection>
      <p>QUICK VIEW</p>
      <div>
        <div>
          <h5>
            Total Parts Produced - <span>{producedQty}</span>
          </h5>
          <h5>
            Total Rejections - <span>{totalFailureRejection}</span>
          </h5>
          <h5>
            Overall Process Efficiency -{" "}
            <span>
              {Number(
                (
                  ((producedQty - totalFailureRejection) / (producedQty || 1)) *
                  100
                ).toFixed(2)
              )}
              %
            </span>
          </h5>
        </div>
      </div>
    </CardSection>
  );
};

export default FailureCardDetails;
