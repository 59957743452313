import React from "react";

import { Link } from "react-router-dom";
import ListAltIcon from "@material-ui/icons/ListAlt";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import AppsIcon from "@material-ui/icons/Apps";
import tilesStyle from "./tilesStyle.module.css";

const TilesInsideSkillEvaluation = () => {
  return (
    <>
      <div
        className="divflex"
        style={{ fontSize: "24px", textTransform: "uppercase" }}
      >
        Skill Evaluation
      </div>
      <main className={`${tilesStyle.container} ${tilesStyle.main}`}>
        <div>
          <Link to="/SkillEvaluationList" className="panel">
            <ListAltIcon style={{ fontSize: "3rem", color: "#1864ab" }} />
          </Link>
          <p className="card-btn-para">
            Skill Evaluation <span className="card-btn-span">Criteria</span>
          </p>
        </div>
        <div>
          <div className="panel">
            <Link to="/skill_evaluation/list" className="panel">
              <FeaturedPlayListIcon
                style={{ fontSize: "3rem", color: "#1864ab" }}
              />
            </Link>
          </div>
          <p className="card-btn-para">
            Skill Evaluation <span className="card-btn-span">Plan</span>
          </p>
        </div>
        <div>
          <Link to="skillMatrixList" className="panel">
            <AppsIcon style={{ fontSize: "3rem", color: "#3A4F7A" }} />
          </Link>
          <p className="card-btn-para">
            Skill<span className="card-btn-span">Matrix</span>
          </p>
        </div>
        {/* <div>
      <Link to="/allDepartment" className="panel">
        <DeveloperBoardIcon
          style={{ fontSize: "3rem", color: "#135089" }}
        />
      </Link>
      <p className="card-btn-para">
        Master List Of<span className="card-btn-span">Department</span>
      </p>
    </div> */}
      </main>
    </>
  );
};

export default TilesInsideSkillEvaluation;
