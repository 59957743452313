import Styled from "styled-components";
import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import { Link, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { Add, Search } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";
import useDebounce from "../../components/Control/useDebounce";
import { Wrapper } from "../../components/Control/Flexbox";
import Input from "../../components/Control/Input";
import formatDate from "../../components/Control/formatDate";
import Button from "@material-ui/core/Button";
import Modal from "../../components/Control/Modal";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import useConfirm from "../../components/Control/useConfirm";
import ConfirmDialogueForInput from "../../components/Control/ConfirmDialogueForInput";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  table: {
    width: "100%",
    marginTop: "1.5rem",
  },
});

const Div = Styled.div`
    margin-top: 1rem;
    h3{
        margin-left:2.5rem;
    }
`;

function ViewNotebookTable() {
  const classes = useStyles();
  const [isEdit, setIsEdit] = useState(false);
  const [idForEdit, setIdForEdit] = useState(0);
  const [notebooks, setNotebooks] = useState([]);
  const [notebook, setNotebook] = useState({
    notebook_name: "",
    notebook_description: "",
  });
  const { state } = useLocation();
  const [filter, setFilter] = useState("notebook_name");
  const [page, setPage] = useState(1);
  const [render, setRender] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [perPage, setPerPage] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  // delete modal
  const [notebookName, setNotebookName] = useState("");
  const [confirmOpenForDelete, setConfirmOpenForDelete] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  // save dialog
  const [DialogSave, confirmSave] = useConfirm(
    "SAVE",
    "Are you sure you want to save?"
  );

  function handleNotebook(e) {
    let value = e.target.value;
    setNotebook({ ...notebook, [e.target.name]: value });
  }

  function handlePopup() {
    setOpenPopup(false);
  }

  async function submitNotebook() {
    let ans = await confirmSave();
    if (ans) {
      if (!isEdit) {
        axios
          .post(`/api/post/notebook`, notebook)
          .then(() => {
            console.log("Successfully created notebook");
            setNotebook({
              notebook_name: "",
              notebook_description: "",
            });
            setRender((prev) => !prev);
          })
          .catch((err) => console.log(err));
      } else {
        axios
          .put(`/api/put/notebook?id=${idForEdit}`, notebook)
          .then(() => {
            console.log("Successfully edited notebook");
            setNotebook({
              notebook_name: "",
              notebook_description: "",
            });
            setIdForEdit(0);
            setIsEdit(false);
            setRender((prev) => !prev);
          })
          .catch((err) => console.log(err));
      }
    }

    setOpenPopup(false);
  }

  const fetchData = async () => {
    const res = await axios
      .get(`/api/get/notebooks?page=${page}&sortBy=${filter}&search=${search}`)
      .catch((err) => {
        console.log(err);
      });
    setNotebooks(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };
  useDebounce(fetchData, 1000, [page, search, render, filter]);

  const deleteNotebook = async (notebookName) => {
    const res = await axios
      .delete(`/api/delete/notebook?notebook_name=${notebookName}`)
      .catch((err) => console.log(err));

    if (res.data?.status === "success") {
      setRender((prev) => !prev);
    }
  };

  return (
    <>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        NOTEBOOK LIST
        <Button
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          style={{
            display: "inline-flex",
            marginBottom: "0rem",
            backgroundColor: "#054a29",
          }}
          onClick={() => {
            setOpenPopup(true);
            setIsEdit(false);
          }}
          startIcon={<Add />}
        >
          NEW Notebook
        </Button>
        <Modal
          closeModal={handlePopup}
          openPopup={openPopup}
          title="ADD NEW NOTEBOOK"
        >
          <section style={{ marginBottom: "2rem" }}>
            <Input
              type="text"
              name="notebook_name"
              placeholder="Notebook Name"
              value={notebook.notebook_name}
              onChange={handleNotebook}
              fullWidth={true}
              required
            />

            <Input
              type="text"
              name="notebook_description"
              placeholder="Notebook Description"
              fullWidth={true}
              value={notebook.notebook_description}
              onChange={handleNotebook}
              required
            />

            <div
              className="btn_save"
              style={{
                position: "absolute",
                right: "150px",
                top: "9px",
              }}
            >
              <Button
                type="submit"
                variant="outlined"
                color="white"
                size="large"
                style={{ color: "#e9e9e9" }}
                onClick={submitNotebook}
                startIcon={<SaveIcon />}
              >
                SAVE
              </Button>
            </div>
          </section>
        </Modal>
      </div>
      <TableContainer component={Paper}>
        <Wrapper>
          <div style={{ flex: "0.8" }}>
            <Input
              placeholder="Search Notebook Number"
              variant="filled"
              style={{
                width: "96%",
                marginLeft: "1.2rem",
                marginTop: "1rem",
              }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl
            variant="filled"
            className="select-field"
            style={{ marginRight: "1.5rem", marginTop: "0.8rem", flex: "0.2" }}
          >
            <InputLabel>Filter</InputLabel>
            <Select
              name="notebook_type"
              onChange={(e) => setFilter(e.target.value)}
              defaultValue={filter}
            >
              <MenuItem value="notebook_name">A - Z</MenuItem>
              <MenuItem value="createdAt">Most Recent First</MenuItem>
              <MenuItem value="createdAt_old">Oldest First</MenuItem>
            </Select>
          </FormControl>
        </Wrapper>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow style={{ backgroundColor: "white", padding: "6px" }}>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                #
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Notebook Name
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Notebook Description
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Created At
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Last Updated At
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {notebooks && (
            <TableBody>
              {notebooks.map((item, index) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item.notebook_name}</TableCell>
                    <TableCell align="center">
                      {item.notebook_description}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(item.created_at)}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(item.last_updated_at)}
                    </TableCell>

                    <TableCell align="center">
                      <Tooltip title="View" arrow>
                        <Link to={`/view_noteslist/${item._id}`}>
                          <IconButton size="small">
                            <VisibilityIcon
                              style={{ fontSize: "1.4rem", color: "#003566" }}
                            />
                          </IconButton>
                        </Link>
                      </Tooltip>
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                          onClick={() => {
                            setNotebook({
                              notebook_name: item.notebook_name,
                              notebook_description: item.notebook_description,
                            });
                            setOpenPopup(true);
                            setIdForEdit(item._id);
                            setIsEdit(true);
                          }}
                        >
                          <EditIcon
                            style={{ fontSize: "1.4rem", color: "#003566" }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete" arrow>
                        <IconButton
                          size="small"
                          style={{ marginLeft: "0.5rem" }}
                          // onClick={() => deleteNotebook(item.notebook_name)}
                          onClick={() => {
                            setConfirmOpenForDelete(true);
                            setNotebookName(item.notebook_name);
                            // deleteNotebook(item.notebook_name);
                          }}
                        >
                          <DeleteIcon
                            style={{
                              fontSize: "1.4rem",
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          )}
        </Table>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
            marginBottom: "4rem",
          }}
        >
          <Pagination
            count={totalPages}
            variant="outlined"
            shape="rounded"
            onChange={(e, number) => setPage(number)}
          />
        </div>
      </TableContainer>
      <ConfirmDialogueForInput
        title="DELETE PART"
        open={confirmOpenForDelete}
        setOpen={setConfirmOpenForDelete}
        onConfirm={(notebookName) => deleteNotebook(notebookName)}
        isSubmitContent={false}
        itemPartName={notebookName}
        textFieldValue={textFieldValue}
        setTextFieldValue={setTextFieldValue}
      >
        <p style={{ margin: 0, fontSize: "1rem" }}>
          Are you sure you want to delete this notebook?
        </p>
        <p style={{ margin: 0, fontSize: "1rem" }}>
          Enter the <b>Notebook Name :</b> "{`${notebookName}`}" to confirm
          notebook deletion:
        </p>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          autoComplete="false"
          label="Item Number"
          type="text"
          fullWidth
          onChange={(e) => setTextFieldValue(e.target.value)}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
        />
      </ConfirmDialogueForInput>
      <DialogSave isSubmitContent={true} />
    </>
  );
}
export default ViewNotebookTable;
