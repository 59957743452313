import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { images, textInputs } from "../../../constants/constants";
import {
  TableContainerStyled,
  Table,
} from "../../../components/Control/TabelStyles";
import TableScroll from "../../../components/TableScroll";

const dropdownStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  select: {
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&:focus": {
      backgroundColor: "#fff",
    },
  },
  underline: {
    borderBottom: "0px solid red !important",
    "&:hover": {
      borderBottom: "0px solid rgba(0,0,0,0)",
    },
  },
});
const selectCheckUncheck = (
  classes,
  name,
  apqpData,
  handleChange,
  phase,
  index,
  isView
) => {
  return isView ? (
    apqpData[phase][index][name]
  ) : (
    <FormControl
      style={{
        width: "75px",
        border: "none",
      }}
      variant="filled"
      classes={{
        root: classes.root,
      }}
    >
      <Select
        name={name}
        classes={{
          root: classes.root,
          select: classes.select,
        }}
        key={phase}
        style={{ fontSize: "1rem" }}
        defaultValue={apqpData[phase][index][name]}
        disableUnderline
        onChange={(e) => handleChange(e, phase, index)}
      >
        <MenuItem>None</MenuItem>
        <MenuItem value="true">🗸</MenuItem>
        <MenuItem value="false">⨯</MenuItem>
      </Select>
    </FormControl>
  );
};

const selectResponsibleDepartment = (
  classes,
  name,
  apqpData,
  handleChange,
  phase,
  index,
  isView
) => {
  return isView ? (
    apqpData[phase][index][name]
  ) : (
    <FormControl
      style={{
        width: "75px",
        border: "none",
      }}
      variant="filled"
      classes={{
        root: classes.root,
      }}
    >
      {/* <InputLabel>P / S</InputLabel> */}
      <Select
        name={name}
        classes={{
          root: classes.root,
          select: classes.select,
        }}
        key={phase}
        style={{ fontSize: "1rem" }}
        defaultValue={apqpData[phase][index][name]}
        disableUnderline
        onChange={(e) => handleChange(e, phase, index)}
      >
        <MenuItem>None</MenuItem>
        <MenuItem value="p">P</MenuItem>
        <MenuItem value="s">S</MenuItem>
      </Select>
    </FormControl>
  );
};
const ApqpMatrixTable = ({ handleChange, isView, apqpData, phase, title }) => {
  const classes = dropdownStyles();

  return (
    <main
      style={{
        paddingBottom: "4rem",
      }}
    >
      <TableScroll>
        <Table>
          <thead>
            <tr data-height="100">
              <th
                colSpan={13}
                rowSpan="2"
                align="left"
                style={{
                  border: "1px solid black",
                  fontSize: "1.6rem",
                  textAlign: "center",
                  height: "100px",
                  color: "#343a40",
                }}
              >
                <CenteredLogoImg>
                  <img src={images.arcLogo} alt={textInputs.companyName} />
                  <span>{textInputs.companyName}</span>
                </CenteredLogoImg>
              </th>
              <th>Doc. No.:- ARC/F/NPD/05</th>
            </tr>
            <tr>
              <th>Rev. No./Date:-03/04.12.2020</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th colSpan={10}>
                Criteria for identifying category of product development has
                been defined as below :
              </th>
              <th colSpan={5}>Notes:</th>
            </tr>
            <tr>
              <th rowSpan={2} colSpan={1}>
                S No.
              </th>
              <th rowSpan={2} colSpan={6}>
                Type of Development
              </th>
              <th rowSpan={2} colSpan={3}>
                Category
              </th>
              <th style={{ borderTop: "0", borderBottom: "none" }} colSpan={5}>
                Department Codes :
              </th>
            </tr>
            <tr>
              <th style={{ borderTop: "0", borderBottom: "none" }} colSpan={5}>
                NPD Head- New Product Development{" "}
              </th>
            </tr>
            <tr>
              <th rowSpan={2} colSpan={1}>
                1
              </th>
              <th rowSpan={2} colSpan={6}>
                Any similar type of product has not been produced earlier
              </th>
              <th rowSpan={2} colSpan={3}>
                A
              </th>
              <th style={{ borderTop: "0", borderBottom: "none" }} colSpan={5}>
                QA-Quality Appraiser ,
              </th>
            </tr>
            <tr>
              <th style={{ borderTop: "0", borderBottom: "none" }} colSpan={5}>
                CFT-Cross Functional Team,
              </th>
            </tr>

            <tr>
              <th rowSpan={2} colSpan={1}>
                2
              </th>
              <th rowSpan={2} colSpan={6}>
                A similar type of product has been produced earlier.
              </th>
              <th rowSpan={2} colSpan={3}>
                B
              </th>
              <th style={{ borderTop: "0", borderBottom: "none" }} colSpan={5}>
                PR-Production,
              </th>
            </tr>
            <tr>
              <th style={{ borderTop: "0", borderBottom: "none" }} colSpan={5}>
                MN-Maintenance
              </th>
            </tr>

            <tr>
              <th colSpan={1}>3</th>
              <th colSpan={6}>Existing product ECN</th>
              <th colSpan={3}>C</th>
              <th style={{ borderTop: "0", borderBottom: "none" }} colSpan={5}>
                MKT & PUR:- Marketing & Purchase
              </th>
            </tr>
            <tr>
              <th colSpan={1}>4</th>
              <th colSpan={6}>Location Change /PCN </th>
              <th colSpan={3}>D</th>
              <th style={{ borderTop: "0" }} colSpan={5}>
                HR HEAD:- Human resources
              </th>
            </tr>

            <tr>
              <th rowSpan={2} colSpan={1}>
                S No
              </th>
              <th rowSpan={2} colSpan={2} style={{ minWidth: "500px" }}>
                Activity
              </th>
              <th colSpan={4}>Category of Development</th>
              <th colSpan={8}>
                Responsible Departments (P-Primary, S-Secondary)
              </th>
            </tr>
            <tr>
              <th>A</th>
              <th>B</th>
              <th>C</th>
              <th>D</th>

              <th>NPD Head</th>
              <th>QA</th>
              <th>CFT PR</th>
              <th> PR</th>
              <th>MN</th>
              <th>MKT & PUR</th>
              <th>HR HEAD</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th
                style={{
                  textAlign: "center",
                }}
                colSpan={15}
              >
                {title}
              </th>
            </tr>
            {apqpData[phase].map((item, index) => (
              <React.Fragment key={index}>
                <tr>
                  <td>{item.s_no}</td>
                  <td colSpan={2}>{item.activity}</td>
                  <td>
                    {selectCheckUncheck(
                      classes,
                      "development_a",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>
                  <td>
                    {selectCheckUncheck(
                      classes,
                      "development_b",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>
                  <td>
                    {selectCheckUncheck(
                      classes,
                      "development_c",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>
                  <td>
                    {selectCheckUncheck(
                      classes,
                      "development_d",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>

                  <td>
                    {selectResponsibleDepartment(
                      classes,
                      "npd_head",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>
                  <td>
                    {selectResponsibleDepartment(
                      classes,
                      "qa",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>
                  <td>
                    {selectResponsibleDepartment(
                      classes,
                      "cft",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>
                  <td>
                    {selectResponsibleDepartment(
                      classes,
                      "pr",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>
                  <td>
                    {selectResponsibleDepartment(
                      classes,
                      "mn",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>
                  <td>
                    {selectResponsibleDepartment(
                      classes,
                      "mkt",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>
                  <td>
                    {selectResponsibleDepartment(
                      classes,
                      "hr_head",
                      apqpData,
                      handleChange,
                      phase,
                      index,
                      isView
                    )}
                  </td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
      </TableScroll>
    </main>
  );
};

export default ApqpMatrixTable;
