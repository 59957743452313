import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.column ? "column" : "row")};
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "center"};
  height: ${(props) => `${props.height}vh` || "0px"};

  & .input_field_width {
    width: ${(props) => (props.wid ? props.wid : "20rem")};
    // width: 20rem;
    // background-color: #fafafa !important;
  }
`;
