import React, { useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  InputAdornment,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import { Search } from "@material-ui/icons";
import axios from "axios";
import Pagination from "@material-ui/lab/Pagination";

import Input from "../../components/Control/Input";

import { Wrapper } from "../../components/Control/Flexbox";
import { Add } from "@material-ui/icons";
import Modal from "../../components/Control/Modal";
import AddEditPart from "./AddEditPart";
import { Paper } from "@material-ui/core";
import useDebounce from "../../components/Control/useDebounce";
import { useAuth } from "../../context/GlobalContext";
import useConfirm from "../../components/Control/useConfirm";
import ViewFile from "./components/ViewFile";

// all api are same as AddPart, which are used in clause4

const tableHeaderObj = [
  [
    { text: "S.NO.", rowspan: 1, colspan: 1 },
    { text: "USER ID", rowspan: 1, colspan: 1 },
    { text: "DATE OF COMPLAINT", rowspan: 1, colspan: 1 },
    { text: "PROBLEM CLAUSE/DOCUMENT", rowspan: 1, colspan: 1 },
    // { text: 'URL ADDRESS', rowspan: 1, colspan: 1 },
    { text: "COMPLAINT DESCRIPTION", rowspan: 1, colspan: 1 },
    { text: "MUSHIN REMARKS", rowspan: 1, colspan: 1 },
    { text: "CLOSED BY", rowspan: 1, colspan: 1 },

    { text: "CLOSURE DATE", rowspan: 1, colspan: 1 },
    { text: "CLOSURE STATUS", rowspan: 1, colspan: 1 },
    { text: "ACTION", rowspan: 1, colspan: 1 },
  ],
];

const PartList = () => {
  const [data, setData] = useState([]);
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { state } = useAuth();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState();
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("createdAt");
  const [partId, setPartId] = useState(null);
  const [render, setRender] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [id, setId] = useState(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [complaintId, setComplaintId] = useState("");
  const [isDisable, setDisable] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    "DELETE",
    "Are you sure you want to delete this?",
  );

  const deleteCustomer = async (id) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/mushinHelpDesk/${id}`)
      .then((res) => {
        alert("Document deleted successfully");
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  // delete modal

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/mushinHelpDesk/?page=${page}&sortBy=${sort}&filterBy=${filter}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, sort, filter]);

  return (
    <>
      <main style={{ paddingBottom: "4rem" }}>
        <div className="divflex" style={{ fontSize: "24px" }}>
          MUSHIN HELP DESK
          <div>
            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                setIsModal(true);
              }}
              style={{
                background: "#064a29",
                color: "#fafafa",
                marginRight: "1rem",
              }}
              startIcon={<Add />}
            >
              ADD COMPLAINT
            </Button>
          </div>
        </div>
        <TableContainer component={Paper}>
          <Wrapper>
            <div style={{ flex: "0.8" }}>
              <Input
                placeholder="Search Problem Clause/Document"
                variant="filled"
                style={{
                  width: "96%",
                  marginLeft: "1.2rem",
                  marginTop: "1rem",
                }}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Sort By</InputLabel>
              <Select
                name="sort"
                onChange={(e) => setSort(e.target.value)}
                defaultValue={sort}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="user_id">A - Z</MenuItem>
                <MenuItem value="createdAt">Most Recent First</MenuItem>
                <MenuItem value="createdAt_old">Oldest First</MenuItem>
              </Select>
            </FormControl>
            <FormControl
              variant="filled"
              className="select-field"
              style={{
                marginRight: "1.5rem",
                marginTop: "0.8rem",
                flex: "0.2",
              }}
            >
              <InputLabel>Filter</InputLabel>
              <Select
                name="filter"
                onChange={(e) => setFilter(e.target.value)}
                defaultValue={filter}
              >
                <MenuItem value="">None</MenuItem>

                <MenuItem value="OPEN">OPEN</MenuItem>
                <MenuItem value="WORK IN PROGRESS">WORK IN PROGRESS</MenuItem>
                <MenuItem value="CLOSED">CLOSED</MenuItem>
              </Select>
            </FormControl>
          </Wrapper>
          <Table size="small">
            <TableHead>
              <TableRow style={{ padding: "6px" }}>
                {tableHeaderObj[0].map((item, index) => (
                  <TableCell
                    key={`help${index}`}
                    style={{ fontWeight: "bold" }}
                    align="center"
                  >
                    {item?.text}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((item, index) => (
                  <React.Fragment key={item._id}>
                    <TableRow>
                      <TableCell align="center">
                        {perPage * (+page - 1) + index + 1}
                      </TableCell>
                      <TableCell align="center">{item?.user_id}</TableCell>
                      <TableCell align="center">
                        {item?.date_of_complaint}
                      </TableCell>
                      <TableCell align="center">{item?.problem}</TableCell>
                      {/* <TableCell align="center">{item?.url}</TableCell> */}
                      <TableCell align="center">
                        <textarea disabled>{item?.description}</textarea>
                      </TableCell>
                      <TableCell align="center">
                        <textarea disabled>{item?.mushin_remarks}</textarea>
                      </TableCell>
                      <TableCell align="center">{item?.closed_by}</TableCell>

                      <TableCell align="center">{item?.closure_date}</TableCell>
                      <TableCell
                        style={{
                          background:
                            item?.status === "CLOSED"
                              ? "green"
                              : item?.status === "OPEN"
                                ? "red"
                                : item?.status === "UNDER REVIEW"
                                  ? "orange"
                                  : "yellow",
                        }}
                        align="center"
                      >
                        {item?.status}
                      </TableCell>

                      <TableCell align="center">
                        <Tooltip title="FILE LIST" arrow>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setId(item._id || null);
                              setIsFileOpenPopup(true);
                            }}
                          >
                            <FormatListBulletedIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="VIEW" arrow>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setComplaintId(item?._id || "");
                              setDisable(true);
                              setIsModal(true);
                            }}
                          >
                            <VisibilityIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="EDIT" arrow>
                          <IconButton
                            size="small"
                            style={{ marginLeft: "0.5rem" }}
                            onClick={() => {
                              setComplaintId(item?._id || "");
                              setDisable(false);
                              setIsModal(true);
                            }}
                          >
                            <EditIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#003566",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="DELETE" arrow>
                          <IconButton
                            size="small"
                            onClick={() => {
                              deleteCustomer(item?._id || "");
                            }}
                            style={{ marginLeft: "0.5rem" }}
                          >
                            <DeleteIcon
                              style={{
                                fontSize: "1.4rem",
                                color: "#c80202",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
              marginBottom: "4rem",
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(_, number) => setPage(number)}
            />
          </div>
        </TableContainer>

        <Modal
          title={"COMPLAINT REGISTER"}
          openPopup={isModal}
          closeModal={() => {
            setComplaintId(null);
            setIsModal(false);
          }}
          backgroundColor="white"
        >
          <AddEditPart
            id={complaintId}
            setIsModal={setIsModal}
            disable={isDisable}
          />
        </Modal>
        <Modal
          title={"FILE LIST"}
          openPopup={isFileOpenPopup}
          closeModal={() => {
            setId(null);
            setIsFileOpenPopup(false);
          }}
          backgroundColor="white"
        >
          <ViewFile id={id} />
        </Modal>
        <DialogDelete isSubmitContent={false} />
      </main>
    </>
  );
};

export default PartList;
