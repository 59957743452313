import React from "react";

const TableReadEditCell = ({
  isView,
  name,
  value,
  type,
  handleChange,
  index,
  style,
  inputColor,
}) => {
  return (
    <div
      style={{
        border: isView ? "none" : "2px solid #f9e0e0",
        padding: "0.1rem",
        display: isView ? "inline-block" : "block",
      }}
    >
      {isView ? (
        value
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          autoComplete="off"
          className="input"
          style={{ backgroundColor: inputColor, ...style }}
          onChange={(e) => handleChange(e, index)}
          onWheel={() => document.activeElement.blur()}
        />
      )}
    </div>
  );
};

export default TableReadEditCell;
