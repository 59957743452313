const months = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const monthsWith31Days = ["JAN", "MAR", "MAY", "JUL", "AUG", "OCT", "DEC"];

const monthsWith30Days = ["APR", "JUN", "SEP", "NOV"];

const getDailyProductionYearly = (year) => {
  return months.map((month) => ({
    month_year: `${month}-${new Date(year).getFullYear()}`,
  }));
};

const getMachineRowForDailyProduction = (monthAndYear) => {
  const newMonthYear = monthAndYear.split("-");
  const month = newMonthYear[0];
  const year = newMonthYear[1];
  const monthInNumber = months.indexOf(month) + 1;
  let daysInMonth;
  if (monthsWith30Days.includes(month)) {
    daysInMonth = 30;
  }
  if (monthsWith31Days.includes(month)) {
    daysInMonth = 31;
  }
  if (month === "FEB") {
    if (+year % 4 === 0) {
      daysInMonth = 29;
    } else {
      daysInMonth = 28;
    }
  }

  return new Array(daysInMonth).fill("0").map((_, index) => ({
    date: `${index + 1}/${monthInNumber}/${year}`,
    total_available: 24,
    mc: {
      value: 0,
    },
    mld: {
      value: 0,
    },
    man: {
      value: 0,
    },
    pwr: {
      value: 0,
    },
    maint: {
      value: 0,
    },
    mat: {
      value: 0,
    },
  }));
};

export { getMachineRowForDailyProduction, getDailyProductionYearly };
