import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Styled from "styled-components";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Wrapper } from "../../../components/Control/Flexbox";
import { images, textInputs } from "../../../constants/constants";
import { CloseButton } from "../../../GlobalUtils/ButtonsGlobal";
import useConfirm from "../../../components/Control/useConfirm";
import {
  ButtonStyled,
  TableContainerStyled,
} from "../../DailyProduction/styles";
import { useQuery } from "../../../components/Control/useQuery";
import formatDate from "../../../components/Control/formatDate";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { ncSummary } from "./ncSummaryData";
import { CenteredLogoImg } from "../../../golbalStyles/global.styled";
import { tr } from "date-fns/locale";

const Table = Styled.table`
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        height: 50px;
        text-align:center;
    }

`;

const NCSummary = () => {
  const query = useQuery();
  const isView = query.get("isView") === "true" ? true : false;

  const handleScroll = (scrolltype) => {
    if (scrolltype === "right") {
      document.getElementById("machinetable").scrollLeft += 80;
    } else {
      document.getElementById("machinetable").scrollLeft -= 80;
    }
  };
  return (
    <section>
      <div
        style={{
          paddingBottom: "4rem",
        }}
      >
        <ButtonStyled
          left
          onClick={(e) => {
            handleScroll("left");
          }}
        >
          <div>
            <ArrowBackIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <ButtonStyled
          right
          onClick={(e) => {
            handleScroll("right");
          }}
        >
          <div>
            <ArrowForwardIosIcon style={{ color: "#fff" }} />
          </div>
        </ButtonStyled>
        <div className="divflex">
          <Typography
            style={{
              fontSize: "24px",
              fontFamily: "Nunito",
              textTransform: "uppercase",
            }}
          >
            Internal Audit Check Sheet Summary
          </Typography>
        </div>
        <TableContainerStyled id="machinetable">
          <Table
            cellSpacing="0"
            style={{
              marginTop: "0.6rem",
              backgroundColor: "white",
              width: "100%",
            }}
          >
            <thead>
              <tr>
                <th
                  colSpan="5"
                  rowSpan={3}
                  align="left"
                  style={{
                    border: "1px solid black",
                    fontSize: "1.6rem",
                    textAlign: "center",
                    height: "100px",
                  }}
                >
                  <CenteredLogoImg>
                    <img
                      src={images?.arcLogo}
                      alt={textInputs?.companyName}
                      height="46"
                      width="120"
                    />
                    <span>{textInputs?.companyName}</span>
                  </CenteredLogoImg>
                </th>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Doc No.- ARC/F/IA/05
                </th>
              </tr>
              <tr>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Rev- 00
                </th>
              </tr>
              <tr>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Dated- 02.07.19
                </th>
              </tr>
              <tr>
                <th
                  colSpan="7"
                  align="center"
                  style={{
                    fontSize: "1rem",
                    fontWeight: 600,
                    border: "1px solid black",
                    textAlign: "center",
                  }}
                >
                  Audit Summary - Audit NC's & their Status
                </th>
              </tr>
              <tr>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Result
                </th>
                <th
                  colSpan={1}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Total Non-Conformities Raised :{" "}
                </th>
                <th
                  colSpan={2}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Total Non-Conformities Closed :
                </th>
                <th
                  colSpan={3}
                  style={{
                    border: "1px solid black",
                  }}
                >
                  Total Non-Conformities Pending :{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                {[
                  "S.No.",
                  "Nonconformances Observed/ No.",
                  "Action Plan against NC's",
                  "Resp.",
                  "Target",
                  "Status",
                  "Remarks",
                ].map((heading, index) => (
                  <th
                    key={index + 1.0001}
                    style={{
                      border: "1px solid black",
                      textAlign: "center",
                      textTransform: "uppercase",
                    }}
                  >
                    {heading}
                  </th>
                ))}
              </tr>
              {ncSummary?.map((item, index) => (
                <tr key={index + "nc "} style={{ textAlign: "center" }}>
                  <td>{index + 1}</td>
                  <td>{item?.nonconforomances_obs_no}</td>
                  <td>{item?.action_plan_against_ncs}</td>
                  <td>{item?.reponsibility}</td>
                  <td>{item?.target}</td>
                  <td>{item?.status}</td>
                  <td>{item?.remarks}</td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={1}>Legends</th>
                <th colSpan={3}> Pending-⭘ </th>
                <th colSpan={3}> Closed-⬤</th>
              </tr>
            </tfoot>
          </Table>
        </TableContainerStyled>
      </div>
    </section>
  );
};

export default NCSummary;
