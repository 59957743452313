import React, { useState } from "react";
import { Wrapper } from "../../../components/Control/Flexbox";
import Input from "../../../components/Control/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import useConfirm from "../../../components/Control/useConfirm";

import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Checkbox,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const PfdProcess = ({
  process,
  index,
  pfdData,
  setExpandState,
  setPfdData,
  pfdDataHandler,
  expandState,
}) => {
  const [DialogDeleteProcess, confirmDeleteProcess] = useConfirm(
    "DELETE PROCESS",
    "Are you sure you want to delete this Processs?"
  );

  async function removeProcess(index) {
    const isDelete = await confirmDeleteProcess();
    if (isDelete) {
      const values = { ...pfdData };
      values.processes.splice(index, 1);
      setPfdData(values);
      setExpandState((prev) => {
        prev[index] = false;
        return [...prev];
      });
    }
  }

  const findMovement = (mov) => {
    switch (mov) {
      case 1:
        return "◯ - Operation";
      case 2:
        return "▭ - Inspection";
      case 3:
        return "⌼ - Operation Inspection";
      case 4:
        return "△ - Storage";
      case 5:
        return "📦- Packing";
      case 6:
        return "⇨ - Transportation";

      default:
        break;
    }
  };
  // console.log(process);
  return (
    <section>
      <div
        key={process?.key}
        style={{
          marginBottom: "0.3rem",
          marginTop: "0.6rem",
          padding: "0rem",
          border: "1px solid #f0f2f0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            background: "#fafafa",
            alignItems: "center",
          }}
          className="spec-header"
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Wrapper>
              <Input
                type="Number"
                name="s_no"
                onBlur={(e) => pfdDataHandler(e, index)}
                defaultValue={process.s_no}
                style={{
                  width: "70px",
                }}
              />
            </Wrapper>

            <div
              style={{
                fontSize: "1rem",

                width: "100px",
              }}
            >
              {process?.process_no}
            </div>
            <div
              style={{
                fontSize: "1rem",

                textTransform: "uppercase",
                width: "200px",
              }}
            >
              {process?.process_name}
            </div>
            <div
              style={{
                fontSize: "1rem",
                // fontWeight: "bold",
                textTransform: "uppercase",
                width: "10rem",
              }}
            >
              {findMovement(+process?.symbol)}
            </div>
            <div
              style={{
                marginLeft: "1rem",
                fontSize: "1.5rem",
                fontWeight: "bold",
                textTransform: "uppercase",
                color: "#111",
                width: "5rem",
              }}
            >
              {process?.movement ? "⇩" : ""}
            </div>
          </div>
          <Wrapper>
            <div
              style={{
                marginRight: "2rem",
                minWidth: "150px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Checkbox
                name="movement"
                color="primary"
                onChange={(e) => {
                  pfdDataHandler(e, index);
                }}
                defaultChecked={process?.movement}
              />
              <div style={{ minWidth: "116px" }}>Add Movement</div>
            </div>
            {expandState[index] ? (
              <Wrapper
                style={{
                  height: "45px",
                  width: "130px",
                  backgroundColor: "#003566",
                  cursor: "pointer",
                  color: "white",
                  alignItems: "center",
                  gap: "0.5rem",
                  // justifyContent: "center",
                }}
                onClick={() =>
                  setExpandState((prev) => {
                    prev[index] = false;
                    return [...prev];
                  })
                }
              >
                <ExpandLess style={{ marginLeft: "0.5rem" }} />
                <span>Collapse</span>
              </Wrapper>
            ) : (
              <Wrapper
                style={{
                  height: "45px",
                  width: "130px",
                  backgroundColor: "#003566",
                  cursor: "pointer",
                  color: "white",
                  gap: "0.5rem",
                  // justifyContent: "center",
                }}
                onClick={() =>
                  setExpandState((prev) => {
                    prev[index] = true;
                    return [...prev];
                  })
                }
              >
                <ExpandMore style={{ marginLeft: "0.5rem" }} />
                <span>Expand</span>
              </Wrapper>
            )}
            <Wrapper
              style={{
                height: "45px",
                width: "45px",
                // backgroundColor: " #212529",
                color: "#f1f3f5",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                gap: "0.5rem",
                backgroundColor: "#D70012",
              }}
              justify="center"
              onClick={() => removeProcess(index)}
            >
              <DeleteIcon style={{ color: "#f8f9fa" }} />
            </Wrapper>
          </Wrapper>
        </div>
        {expandState[index] && (
          <div className="process-section">
            <Wrapper
            // className="param-field"
            >
              <Input
                className="input_field_width"
                type="text"
                name="process_no"
                placeholder="Operation Number"
                onBlur={(e) => pfdDataHandler(e, index)}
                required
                defaultValue={process?.process_no}
              />

              <Input
                className="input_field_width"
                type="text"
                name="process_name"
                placeholder="Process Description"
                onBlur={(e) => pfdDataHandler(e, index)}
                required
                defaultValue={process?.process_name}
              />
              <FormControl
                // className="input_field_width"
                style={{
                  marginRight: "0.5rem",
                  // flexBasis: "24%",
                  width: "20rem",
                  // marginTop: "0.6rem",
                }}
                variant="filled"
                className="select-field"
              >
                <InputLabel>Flow Chart </InputLabel>
                <Select
                  name="symbol"
                  defaultValue={process?.symbol}
                  onChange={(e) => pfdDataHandler(e, index)}
                  placeholder="Symbol"
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="1">◯ - Operation</MenuItem>
                  <MenuItem value="2">▭ - Inspection</MenuItem>
                  <MenuItem value="3">⌼ - Operation Inspection</MenuItem>
                  <MenuItem value="4">△ - Storage</MenuItem>
                  <MenuItem value="5">📦- Packing</MenuItem>
                  <MenuItem value="6">⇨ - Transportation</MenuItem>
                </Select>
              </FormControl>
            </Wrapper>
            <Wrapper>
              <Input
                className="input_field_width"
                type="text"
                name="machine_pfd"
                placeholder="Machine"
                onBlur={(e) => pfdDataHandler(e, index)}
                defaultValue={process?.machine_pfd}
              />
              <Input
                className="input_field_width"
                type="text"
                name="spec_to_achieve_pfd"
                placeholder="Specification to Achieve"
                onBlur={(e) => pfdDataHandler(e, index)}
                defaultValue={process?.spec_to_achieve_pfd}
              />
              <Input
                className="input_field_width"
                type="text"
                name="key_control"
                placeholder="Key Control Characteristics."
                onBlur={(e) => pfdDataHandler(e, index)}
                defaultValue={process?.key_control}
              />
              <Input
                className="input_field_width"
                type="text"
                name="variation"
                placeholder="Process Control Parameters"
                onBlur={(e) => pfdDataHandler(e, index)}
                defaultValue={process?.variation}
              />
            </Wrapper>
            <div className="flexcenter"></div>
          </div>
        )}
      </div>

      <DialogDeleteProcess isSubmitContent={false} />
    </section>
  );
};

export default PfdProcess;
