import React from "react";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    width: "100%",
    marginBottom: "2rem",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    "& .MuiPagination-ul": {
      margin: "0.7rem 0.4rem",
      padding: "0.8rem",
      justifyContent: "center",
    },
  },
}));

function PageBar({ processesPerPage, paginate, totalProcesses, page }) {
  const pageNumber = [];
  const classes = useStyles();
  // const [page] = React.useState(1);
  const handleChange = (event, value) => {
    paginate(value);
  };

  for (let i = 1; i <= Math.ceil(totalProcesses / processesPerPage); i++) {
    pageNumber.push(i);
  }
  return (
    <div className={classes.root}>
      <Pagination
        variant="outlined"
        shape="rounded"
        count={pageNumber.length}
        page={page}
        onChange={handleChange}
      />
    </div>
  );
}

export default PageBar;
