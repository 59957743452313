import Styled from "styled-components";

export const ButtonStyled = Styled.button`
  position: fixed;
    top: 55%;
    left: ${(props) => (props.left ? "5px" : "none")};
    right: ${(props) => (props.right ? "5px" : "none")};
    background-color: #343a40;
    width: 50px;
    height: 50px;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    Z-Index: 1000;
    opacity: 0.2;
  :hover {
    opacity: 1;
    transform: scale(1.2);
    transition: 200ms;

  }
    div { 
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
`;

export const TableContainerStyled = Styled.div`
          margin:0.1rem 0 0.1rem 0.1rem;
          paddingBottom: 4rem;
          max-height: 100vh;
          max-width: 100vw;
          overflow-x: scroll;
          overflow-y: auto;
          position: relative;
          scroll-behavior: smooth;
          -ms-overflow-style: none;
          scrollbar-width: none; 
          ::-webkit-scrollbar
          {
            width: 20px;
            height: 0;
          }

          
          ::-webkit-scrollbar-track {
  background-color: transparent;
}
        

          ::-webkit-scrollbar-thumb {
          background-color: #d6dee1;
          border-radius: 20px;
          border: 6px solid transparent;
          background-clip: content-box;
        }

          ::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

`;

export const Table = Styled.table`
  width:100%;
	margin-bottom: 1rem;
    th{
        font-size: 12px;
		    font-family:"Roboto",sans-serif;
        border: 1px solid #495057;
        border-collapse: collapse;
        padding: 0.4rem;
        p{
          margin:0;
        }
    }

    td{
        font-size: 12px;
        border: 1px solid black;
        padding: 0.3rem;
        p{
          margin:0;
        }
    }
    span{
      font-weight:500;
    }

    .input {
        outline: none;
        width: 100%;
        border: none;
        height: 50px;
        text-align:center;
    }
    .listDataCenter td {
      text-align : center;
    }

`;
