import { Button } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Save from "@material-ui/icons/Save";
import React, { useEffect, useRef, useState } from "react";
import Styled from "styled-components";
import axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { Wrapper } from "../../components/Control/Flexbox";
import useConfirm from "../../components/Control/useConfirm";
import { CloseButton } from "../../GlobalUtils/ButtonsGlobal";
import THead from "./components/create/THead";
import TBody1 from "./components/create/TBody1";
import TBody2 from "./components/create/TBody2";
import { body3Obj, body4Obj } from "./helper";
import TBody3 from "./components/create/TBody3";
import TableScroll from "../../components/TableScroll";
import TBody4 from "./components/create/TBody4";
import TFoot from "./components/create/TFoot";
import Modal from "../../components/Control/Modal";
import UploadDocument from "./components/UploadDocument";
import { useQuery } from "../../components/Control/useQuery";

const Table = Styled.table`
	width:98%;
	margin:0.7rem auto;
	 border: 1px solid black;


    th{
        font-size: 13px;
		font-family:"Roboto",sans-serif;
        border: 1px solid black;
        padding: 0.4rem;
        p{
          margin:0;
        }
        
    }
    td{
        font-size: 13px;
        border: 1px solid black;
        padding: 0.3rem;
    }
    .input {
        outline: none;
        border: none;
        height: 50px;
        text-align:center;
    }
   .circle{
        display:block;
        height: 40px;
        width: 50px;
        margin:auto;
        border:1px solid black;
        background: green;
        border-radius: 50%;
   } 
    td,th{
        text-align: center;
    }
`;
const createBody3Obj = () => {
  return body3Obj;
};
const createBody4Obj = () => {
  return body4Obj;
};
const monthArr = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const InitialSupplyAddEdit = () => {
  const history = useHistory();
  const { partId, id } = useParams();
  const [isInitialModal, setIsInitialModal] = useState(false);
  const [data, setData] = useState(null);
  const fieldId = useRef(null);
  const query = useQuery();
  const isView = query.get("isView") === "false" ? false : true;
  const [DialogClose, confirmClose] = useConfirm(
    "CLOSE",
    "Are you sure you want to leave this page?"
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/getInitialSupplyById/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      const values = { ...data };
      values.months = Array.from({ length: 5 }, () => ({ days: [] }));
      values.initialSupply = [];
      values.body3 = createBody3Obj();
      values.body4 = createBody4Obj();
      setData(values);
    }
  }, [isInitialModal]);
  const handleChangeMonth = (e, dayIndex, monthIndex) => {
    const { value } = e.target;
    const values = { ...data };
    if (!values.ipc_start_date) {
      return alert("Please first select IPC Start Date");
    }
    if (value === "DISPATCH") {
      const year = new Date(values.ipc_start_date).getFullYear();
      const monthNum = monthArr.indexOf(values.months[monthIndex].name);
      values.initialSupply.push({
        key: `${dayIndex}-${monthIndex}`,
        part_id: partId,
        date: `${dayIndex + 1}/${monthNum + 1}/${year}`,
      });
    }
    if (value === "NO DISPTACH" || !value) {
      const supplyIndex = values.initialSupply.findIndex(
        (supply) => supply.key === `${dayIndex}-${monthIndex}`
      );
      if (supplyIndex !== -1) {
        values.initialSupply.splice(supplyIndex, 1);
      }
    }
    values.months[monthIndex].days[dayIndex] = value;
    setData(values);
  };
  const handleIpcStartDate = (date) => {
    const values = { ...data };
    const monthNum = new Date(date).getMonth();

    values.ipc_start_date = date;
    values.months.map((month, idx) => {
      const incrementedMonthNum = monthNum + idx;
      month.name = monthArr[incrementedMonthNum];
    });
    setData(values);
  };
  const handleSubmitAndSave = () => {
    return new Promise((resolve, reject) => {
      if (!data.ipc_start_date) {
        reject("ipc date not selected");
        return alert("Please first select IPC Start Date");
      }
      data.part_id = partId;
      if (data._id) {
        axios
          .put(`/api/updateInitialSupply/${id}`, data)
          .then((res) => {
            if (res.data) {
              if (!isView) {
                alert("Document Update Successfully");
              }
              resolve(res.data);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        axios
          .post(`/api/createInitialSupply`, data)
          .then((res) => {
            if (res.data) {
              if (!isView) {
                alert("Document submitted Successfully");
              }
              history.push(
                `/initialSupplyAddEdit/${partId}/${res.data?._id}?isView=false`
              );
              resolve(res.data);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }
    });
  };
  return (
    <main>
      <div
        className="divflex"
        style={{
          fontSize: "24px",
        }}
      >
        INITIAL SUPPLY MONITORING REPORT
        <div>
          <Wrapper
            style={{
              gap: "1rem",
            }}
          >
            {/* <Button */}
            {/*   type="button" */}
            {/*   variant="contained" */}
            {/*   color="primary" */}
            {/*   size="large" */}
            {/*   style={{ */}
            {/*     backgroundColor: "#003566", */}
            {/*     marginRight: "0.5rem", */}
            {/*   }} */}
            {/*   startIcon={<Save />} */}
            {/*   onClick={() => { */}
            {/*     handleSubmitAndSave(false); */}
            {/*   }} */}
            {/* > */}
            {/*   Save & Continue */}
            {/* </Button> */}

            <Button
              type="button"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#064a29",
                marginRight: "0.5rem",
              }}
              startIcon={<CloudUpload />}
              onClick={() => {
                handleSubmitAndSave();
              }}
            >
              Submit
            </Button>
            <CloseButton
              onClick={async () => {
                let ans = await confirmClose();
                if (ans) {
                  history.goBack();
                }
              }}
            />
          </Wrapper>
        </div>
      </div>
      <TableScroll>
        <Table
          cellSpacing="0"
          id="table-to-xls"
          style={{
            borderCollapse: "collapse",
          }}
        >
          {data && (
            <>
              <THead {...{ handleIpcStartDate, data, isView }} />
              <TBody1 {...{ handleChangeMonth, data, isView }} />
              <tr style={{ height: "40px" }}>
                <td colSpan={34}></td>
              </tr>
              <TBody2
                {...{
                  data,
                  setIsInitialModal,
                  isView,
                  handleSubmitAndSave,
                  fieldId,
                }}
              />
              <tr style={{ height: "40px" }}>
                <td colSpan={34}></td>
              </tr>
              <TBody3
                {...{
                  data,
                  setIsInitialModal,
                  isView,
                  handleSubmitAndSave,
                  fieldId,
                }}
              />
              <TBody4
                {...{
                  data,
                  setIsInitialModal,
                  isView,
                  handleSubmitAndSave,
                  fieldId,
                }}
              />
              <TFoot />
            </>
          )}
        </Table>
      </TableScroll>
      <Modal
        title="Files"
        openPopup={isInitialModal}
        closeModal={() => {
          setIsInitialModal(false);
        }}
      >
        <UploadDocument {...{ id, isView, setIsInitialModal, fieldId }} />
      </Modal>
      <DialogClose />
    </main>
  );
};

export default InitialSupplyAddEdit;
