import React, { useState, useEffect } from "react";
import styled from "styled-components";
const CardSection = styled.div`
  height: 375px;
  flex: 0.386;
  border: 1px solid black;

  > p {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    letter-spacing: 1.6px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #1d1d1d;
    color: white;
  }
  > div {
    margin-top: 6rem;
    margin-left: 4rem;
  }

  h5 {
    font-size: 1.3rem;
    flex: 1;
    text-transform: uppercase;
    letter-spacing: 1.8px;
    margin-top: 1.8rem;
  }
  span {
    color: gray;
  }
`;
const ProcessCardDetails = ({ processRejection, totalProcessRejection }) => {
  const [finalProducedQty, setFinalProducedQty] = useState(0);
  useEffect(() => {
    let finalProduced = 0;
    Object.keys(processRejection).forEach((key, index, arr) => {
      if (index === arr.length - 1)
        finalProduced =
          processRejection[key]?.producedQty - processRejection[key]?.rejected;
    });
    setFinalProducedQty(finalProduced);
  }, [processRejection]);
  console.log(totalProcessRejection, finalProducedQty);
  return (
    <CardSection>
      <p>QUICK VIEW</p>
      <div>
        <div>
          <h5>
            Total Parts Produced - <span>{finalProducedQty}</span>
          </h5>
          <h5>
            Total Parts Rejected - <span>{totalProcessRejection}</span>
          </h5>
          <h5>
            Overall Parts Efficiency -{" "}
            <span>
              {finalProducedQty === 0
                ? 0
                : (
                    ((finalProducedQty - totalProcessRejection) /
                      (finalProducedQty || 1)) *
                    100
                  ).toFixed(2)}
              %
            </span>
          </h5>
        </div>
      </div>
    </CardSection>
  );
};

export default ProcessCardDetails;
